import { NgModule } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import {InputSwitchModule} from 'primeng/inputswitch';


@NgModule({
    declarations: [
    ],
    imports: [
    ],
    exports: [
        CalendarModule,
        InputTextModule,
        TooltipModule,
        InputSwitchModule
    ]
  })
  export class PrimengModule { }
