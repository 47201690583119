import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
import * as i2 from "../loader.service";
var FraudService = /** @class */ (function () {
    function FraudService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.server = "" + environment.server;
        this.getFraudDetailsUrl = this.server + '/api/fraudEngine/order-fraud-tags/';
        this.updateFraudDetialsUrl = this.server + '/api/fraudEngine/updateFraudOrderDetails';
        this.checkFraudOrdersUrl = this.server + '/api/fraudEngine/check/fraudOrder';
    }
    FraudService.prototype.getFraudDetails = function (orderId) {
        return this.http.get(this.getFraudDetailsUrl + orderId).pipe(catchError(this.handleError));
    };
    FraudService.prototype.updateFraudDetails = function (data) {
        var _this = this;
        this.loaderService.openLoading();
        return this.http.post(this.updateFraudDetialsUrl, data)
            .pipe(catchError(function (error) {
            _this.loaderService.closeLoading();
            return _this.handleError(error);
        })).map(function (res) {
            _this.loaderService.closeLoading();
            return res;
        });
    };
    FraudService.prototype.checkFraudOrders = function (data) {
        return this.http.post(this.checkFraudOrdersUrl, data).pipe(catchError(this.handleError));
    };
    FraudService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    FraudService.ngInjectableDef = i0.defineInjectable({ factory: function FraudService_Factory() { return new FraudService(i0.inject(i1.MyHttpClient), i0.inject(i2.LoaderService)); }, token: FraudService, providedIn: "root" });
    return FraudService;
}());
export { FraudService };
