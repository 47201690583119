import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerTransactionDetailsService } from './customer-transaction-details.service';
import { Table, TableData, TableDataTypes, Pagination } from 'src/app/shared/types';
import { Router } from '@angular/router';
import { FilterService, TextFilterInput, SingleSearchableDropdown, QuickFilters} from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';

const currentDate = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
export const DEFAULT_DATE = {
  FROM_DATE: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
  TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};

function defaultFiters() {
  let startDuration = HelperService.getDateOnlyV1(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  let endDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)));
  return {
    startDuration,
    endDuration,
    orderId: '',
    title: '',
    type: '',
    uniqueId: ''
  };
}

@Component({
  selector: 'app-customer-transaction-details',
  templateUrl: './customer-transaction-details.component.html',
  styleUrls: ['./customer-transaction-details.component.css']
})
export class CustomerTransactionDetailsComponent implements OnInit {
  @Input() customerId: any;
  @Input() customerDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  transactionsTable: Table;
  transactions: any = [];
  pageNumber: Number = 0;
  offset: Number = 0;
  filtersApplied = defaultFiters();
  titles: any = [
    'addMoney',
    'debitCoinExpired',
    'tip',
    'order',
    'orderRefund',
    'customerCashBack',
    'customerRefund',
    'customerReferral',
    'recharge',
    'customerCancellation',
    'adjustment'
  ];
  types: any = [
    'credit',
    'debit' 
  ];
  filterCount: number = 0;
  perPage: Number = 10;
  dataLoaded = false;
  userDetails = this.accessCookie('user');
  constructor(
    private router: Router,
    private customerTransactionDetailsService: CustomerTransactionDetailsService,
    public filterService: FilterService,
  ) { }

  ngOnInit() {
    this.initializeTable();
    this.fillDataToTable();
  }

  onPaginationChange(event) {
    this.pageNumber = event.pageNo;
    this.offset = (event.pageNo * event.perPage);
    this.perPage = event.perPage;
    this.fillDataToTable();
  }


  fillDataToTable(filters?: any) {
    if (this.customerId) {
      this.dataLoaded = false;
      this.customerTransactionDetailsService
      .getCustomerPassbookDetails(this.customerId, this.perPage, this.offset, this.userDetails['_id'], filters).subscribe(result => {
        const self = this;
        this.dataLoaded = true;
        const createTableData = (value, className?, info?, hover?, onClick?) => {
          return new TableData({
            data: value,
            type: TableDataTypes.DATA,
            information: info,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
            stopHover: hover ? true : false,
            onClick: onClick,
          });
        }
        const customerPassbook = result && result['data'] && result['data']['data'] || [];
        this.transactions = customerPassbook.map((transaction) => {
          let paymentSplit = null;
          if (transaction.paymentSplit) {
            const keys = Object.keys(transaction.paymentSplit);
            const values = Object.values(transaction.paymentSplit);
            if (keys.length > 1) {
              const paymentSplitOnHover = [];
              for (const split in values) {
                if (values[split] && keys[split]) {
                  paymentSplitOnHover.push(keys[split] + ' : ₹' + values[split]);
                }
              }
              if (paymentSplitOnHover.length) {
                paymentSplit = paymentSplitOnHover.join('\n');
              }
            }
          }
          const goToOrderPage = (transaction) => {
            const orderId = transaction.orderId;
            if (orderId) {
              this.router.navigate([`/order/${orderId.data}`]);
            }
          }
          return {
            date: createTableData(transaction.date,null, null, null, goToOrderPage),
            paidFor: createTableData(transaction.description, null, null, null, goToOrderPage),
            txnId: createTableData(transaction.shortCode, null, null, null, goToOrderPage),
            txnType: createTableData(transaction.type, null, null, null, goToOrderPage),
            agent: createTableData(transaction.actorEmail, null, null, null, goToOrderPage),
            reason: createTableData(transaction.remarks, null, null, null, goToOrderPage),
            paymentMode: createTableData(transaction.paymentMode, null, paymentSplit, true, goToOrderPage),
            couponCode: createTableData(transaction.couponCode, null, null, null, goToOrderPage),
            total: createTableData(transaction.amount, null, null, null, goToOrderPage),
            transactionStatus: createTableData(transaction.transactionStatus, null, null, null, goToOrderPage),
            orderId: createTableData(transaction.orderId, null, null, null, goToOrderPage),
          };
        });
        this.transactionsTable['data'] = this.transactions;
        this.transactionsTable.filterCount = this.filterCount;
        this.transactionsTable['pagination'].count = result['data']['count'];
      });
    } else {
      this.dataLoaded = true;
    }
  }

  initializeTable(pageNumber = 0) {
    this.filterService.appliedFilters = {};
    this.filterService.showFilters = true;
    this.filtersApplied = defaultFiters();

    this.transactionsTable = new Table({
      pagination: new Pagination(pageNumber, 10),
      defaultError: 'No transactions found',
      data: [],
      headers: {
        date: 'Date',
        paidFor: 'Paid For',
        txnId: 'Transaction ID',
        txnType: 'Transaction Type',
        agent: 'Agent',
        reason: 'Reason',
        paymentMode: 'Payment Mode',
        couponCode: 'Coupon Code',
        transactionStatus: "Txn Status",
        total: 'Total'
      },
      toggleableHeaders: this.customerDetailsToggleableHeaders,
      selectedHeader: 1,
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.transactionsTable = null;
        this.ngOnInit();
      },
      appliedFilters: this.filtersApplied,
      filterComponents: [
        new QuickFilters({
          key: 'duration',
          default: '',
          title: 'Duration',
        }),
        new SingleSearchableDropdown({
          key : 'type',
          title: 'Type',
          values: this.types,
        }),
        new TextFilterInput({
          key: 'orderId',
          title: 'Order ID',
        }),
        new TextFilterInput({
          key: 'uniqueId',
          title: 'Unique ID',
        }),
        new SingleSearchableDropdown({
          key : 'title',
          title: 'Title',
          values: this.titles,
        }),
      ],
      onFilter: () => {
        const timeSlot = this.filterService.appliedFilters.duration || ['', ''];
        let duration = [];
        if (!Array.isArray(timeSlot)) {
          duration.push(timeSlot['start']);
          duration.push(timeSlot['end']);
        } else {
          duration = timeSlot;
        }
        let startDuration = duration[0];
        let endDuration = duration[1];
        const orderId = this.filterService.appliedFilters.orderId;
        const title = this.filterService.appliedFilters.title;
        const type = this.filterService.appliedFilters.type;
        const uniqueId = this.filterService.appliedFilters.uniqueId;
        const filtersApplied = {
          duration: {
            start: startDuration,
            end: endDuration,
          },
          startDuration,
          endDuration,
          orderId,
          title,
          type,
          uniqueId
        };

        this.filterCount = 0;
        Object.keys(filtersApplied).forEach(key => {
          if(key != 'duration' && key != 'endDuration' && filtersApplied[key]) {
            this.filterCount++;            
          } 
        })
        Object.assign(this.filtersApplied, filtersApplied);

        const isValidNumber = (value) => {
          return ((parseInt(value, 10) === 0) || value);
        };
        if ((startDuration && endDuration) || title || type || orderId || uniqueId) {
          startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
          endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
          return this.fillDataToTable(this.filtersApplied);
        }
        return this.fillDataToTable(this.filtersApplied);
      },
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

}
