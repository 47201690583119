var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { UserTicketingService } from '../user-tickets.service';
import { CustActivityHistoryService } from './cust-activity-history.service';
import { CUSTOMER_ACTIVITY_HISTORY } from '../roles.constants';
import { FRESH_DESK_TICKETS_URL, TAGS, USER_TYPES } from 'src/app/shared/constants';
var CustActivityHistoryComponent = /** @class */ (function () {
    function CustActivityHistoryComponent(custActHistoryService, ticketService) {
        this.custActHistoryService = custActHistoryService;
        this.ticketService = ticketService;
        this.monthMap = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
            7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        this.dataRows = [];
        this.accessDenied = true;
        this.classStatus = {
            'New': 'status-blue',
            'Open': 'status-yellow',
            'Pending': 'status-yellow',
            'Hold': 'status-yellow',
            'Solved': 'status-green',
            'Resolved': 'status-green',
            'Closed': 'status-grey'
        };
        this.ticketStatus = {
            2: 'Open',
            3: 'Pending',
            4: 'Resolved',
            5: 'Closed'
        };
        this.reomveExtraWhiteSpaces = function (string) { return string.replace(/\s+/g, ' ').trim(); };
        this.getFreshDeskRedirectUrl = function (ticketId) { return FRESH_DESK_TICKETS_URL + "/" + ticketId; };
    }
    CustActivityHistoryComponent.prototype.ngOnInit = function () {
    };
    CustActivityHistoryComponent.prototype.loadRequestCard = function () {
        var cookie = this.accessCookie('Roles');
        var roles = cookie.split(',');
        if ((roles.some(function (roleValid) { return CUSTOMER_ACTIVITY_HISTORY.indexOf(roleValid) >= 0; }))) {
            this.accessDenied = false;
        }
        this.tableData = {
            tableHeader: 'User Activity History',
            data: null,
            headers: null,
            accessDenied: this.accessDenied,
            loadOnRequest: true,
            dataFetched: true
        };
    };
    CustActivityHistoryComponent.prototype.toggleState = function (status) {
        if (status === 'sl') {
            this.tableData = __assign({}, this.tableData, { data: this.dataRows });
        }
        else if (status === 'vm') {
            this.tableData = __assign({}, this.tableData, { data: [this.dataRows[0]] });
        }
    };
    CustActivityHistoryComponent.prototype.constructCard = function () {
        this.tableData = {
            tableHeader: 'User Activity History',
            headers: { createdOn: 'Created On', description: 'Description', subject: 'Subject', id: '#ID', status: 'Status', userType: 'User Type' },
            data: [this.dataRows[0]],
            title: ['subject', 'description'],
            dataFetched: true,
            accessDenied: this.accessDenied,
            tableHeight: 'max-height-177',
            expandTable: this.dataRows.length > 1 ? true : false,
            loadOnRequest: false
        };
    };
    CustActivityHistoryComponent.prototype.emptyCard = function (message) {
        this.tableData = {
            tableHeader: 'User Activity History',
            headers: null,
            data: null,
            emptyMessage: message || 'No tickets found',
            dataFetched: false,
            accessDenied: false,
            loadOnRequest: false
        };
    };
    CustActivityHistoryComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.custActHistory && changes.custActHistory.currentValue) {
            if (changes.custActHistory.currentValue.dataFetched && changes.custActHistory.currentValue.id) {
                this.getTickets();
                this.loadRequestCard();
            }
            else {
                this.emptyCard('Cannot load data');
            }
        }
    };
    CustActivityHistoryComponent.prototype.getUserType = function (ticket) {
        var tags = ticket.tags;
        if (tags && tags.length > 0) {
            if (tags.includes(TAGS.CUSTOMER_TAG)) {
                return USER_TYPES.CUSTOMER;
            }
            else if (tags.includes(TAGS.CAPTAIN_TAG)) {
                return USER_TYPES.CAPTAIN;
            }
        }
    };
    CustActivityHistoryComponent.prototype.getTickets = function () {
        var _this = this;
        if (this.custActHistory && this.custActHistory.userId && this.custActHistory.id) {
            var query = JSON.stringify({
                "booking_id": this.custActHistory.id
            });
            var userType = 'customer';
            this.ticketService.fetchUserTicketsV2(this.custActHistory.userId, userType, query, 1).subscribe(function (result) {
                if (result && result['tickets'] && result['tickets'].length > 0) {
                    var tickets = result['tickets'];
                    tickets.map(function (ticket) {
                        var userType = _this.getUserType(ticket);
                        if (!(userType === USER_TYPES.CAPTAIN || userType === USER_TYPES.CUSTOMER)) {
                            return;
                        }
                        var ticketStatus = ticket.status && _this.ticketStatus[ticket.status] || 'N/A';
                        var description = ticket.description_text && _this.reomveExtraWhiteSpaces(ticket.description_text) || 'N/A';
                        _this.dataRows.push({
                            id: {
                                data: ticket.ticket_id ? ticket.ticket_id : 'N/A', redirectLink: ticket.ticket_id ?
                                    _this.getFreshDeskRedirectUrl(ticket.ticket_id) : '', className: ticket.ticket_id ? 'web-link' : ''
                            },
                            createdOn: {
                                data: ticket.updated_at && _this.getProperDate(ticket.updated_at) || 'N/A',
                                className: 'preserve-whitespace'
                            },
                            subject: { data: ticket.subject && ticket.subject || 'N/A' },
                            userType: { data: userType },
                            description: { data: description },
                            status: {
                                data: ticketStatus, className: _this.classStatus[ticketStatus] ? _this.classStatus[ticketStatus] : 'status-yellow'
                            }
                        });
                    });
                    if (_this.dataRows.length === 0) {
                        _this.emptyCard();
                    }
                    else {
                        _this.constructCard();
                    }
                }
                else {
                    _this.emptyCard();
                }
            });
        }
        else {
            this.emptyCard();
        }
    };
    CustActivityHistoryComponent.prototype.getProperDate = function (date) {
        var timeInfo = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
        var today = new Date(date);
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear().toString().substr(-2);
        if (dd < 10) {
            dd = '0' + dd;
        }
        var dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
        return (dateInfo + '\n' + timeInfo);
    };
    CustActivityHistoryComponent.prototype.getProperUrl = function (id) {
        var newUrl = 'https://rapidodesk.zendesk.com/agent/tickets/' + id;
        return newUrl;
    };
    CustActivityHistoryComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    return CustActivityHistoryComponent;
}());
export { CustActivityHistoryComponent };
