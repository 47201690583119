<app-table-scrollable *ngIf="dataLoaded" [filterNotRequired]="true" (headerToggled)="changeInHeader($event)"
        [table]="captainAccessFeeTransactions" (paginationChange)="onPaginationChange($event)"></app-table-scrollable>
<div class="row" *ngIf="!dataLoaded">
        <div class="col-md-6">
                <ng-container>
                        <content-loader>
                                <svg:rect x="0" y="0" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="20" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="40" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="60" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="80" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="100" rx="3" ry="3" width="1000" height="10" />
                        </content-loader>
                </ng-container>
        </div>
        <div class="col-md-6">
                <ng-container>
                        <content-loader>
                                <svg:rect x="0" y="0" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="20" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="40" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="60" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="80" rx="3" ry="3" width="1000" height="10" />
                                <svg:rect x="0" y="100" rx="3" ry="3" width="1000" height="10" />
                        </content-loader>
                </ng-container>
        </div>
</div>
