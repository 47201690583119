import * as moment from 'moment';

const WeekDayMap = {
    "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6, "Sunday": 7
};

export function getCurrentStatus(incentive, status, card) {
    if (status !== 'NOT_STARTED') {
        return status;
    }
    const currentTime = moment();

    const timeSlot = card.timeSlot[0] || { fromTime: "00:00" };
    const startTime = moment(`${incentive.startDate} ${timeSlot.fromTime}`, "YYYY-MM-DD HH:mm");
    if (currentTime.isBefore(startTime)) {
        return status;
    }

    const daysApplicable: number[] = card.field
        .split(',')
        .map((day: string) => day.trim())
        .map((day: string) => WeekDayMap[day]);

    const isApplicable = daysApplicable.includes(currentTime.isoWeekday());

    return isApplicable ? "STARTED" : status;
}
