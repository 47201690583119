<div class="boxed-border">
    <div class="title" [ngClass]="info.headerClass">
      <div class="color-card-header padding-0">
        {{info.header}}
      </div>
    </div>
    <div class="line"></div>
    <div class="padding-top-16 padding-left-4" *ngIf="!info.accessDenied && info.dataFetched">
      <div class="row" *ngFor="let row of info.rows">
        <div class="{{component.className ? component.className : 'col-md-3'}}" *ngFor="let component of row">
          <single-select-dropdown
            [selectedValues]="component.default"
            [singleDropdownData]="component"
            (selectedDropDown)="updateData($event, component.key)"
            *ngIf="component.type === 'single-searchable-dropdown'">
          </single-select-dropdown>

          <span class="input-euro left" *ngIf="component.type === 'text-input' && component.title === 'Amount'"></span>
          <input-box [selectedValues]="component?.value"
            *ngIf="component.type === 'text-input'"
            [inputBoxData]="component"
            (selectedText)="updateData($event, component.key)"> 
          </input-box>
        </div>
      </div>
      <div class="row">
          <button class="{{disableSubmit ? 'submit-form-disabled' : 'submit-form'}}" (click)=submitDetails() [disabled]="disableSubmit">Submit</button>
      </div>
    </div>
    <div *ngIf="info.accessDenied" class="loadDataWrapper">
        <div class="svgIcon lock-icon position-absolute lock-icon-positioning"></div>
        <span>
          Oops! you dont have access for this adjustment ¯\_(ツ)_/¯
        </span>
    </div>
    <div *ngIf="!info.dataFetched" class="noDataWrapper">
      <span>{{info.emptyMessage || 'No data available'}} ¯\_(ツ)_/¯</span>
    </div>
</div>