import { Component, OnInit, Inject, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SERVICE_GROUPS, NON_P0_SERVICE_GROUPS} from 'src/app/shared/constants';
import { AppConfigService } from 'src/app/appConfig.service';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-captain-suspension-dailog',
  templateUrl: './captain-suspension-dailog.component.html',
  styleUrls: ['./captain-suspension-dailog.component.css']
})
export class CaptainSuspensionDailogComponent implements OnInit {

    public serviceGroups = SERVICE_GROUPS;
    public nonP0ServiceGroups = NON_P0_SERVICE_GROUPS;
    public suspensionReasons: [];
    public p0SuspensionReasons: string[];
    public p0PriorityMapping;
    public showP0 = false;
    public showP1 = false;
    public p0Issues = "p0Issues";
    public nonP0Issues = "nonP0Issues";
    poSuspensionAction : string;
    p0SuspensionReason: string;
    selectedSuspensionType : string;
    suspensionConfig : any;
    showReasonAlert: boolean;
    selectedServiceGroup: string;
    suspensionReason: string;
    suspensionOtherReason: string
    suspendHours: string;
    p0SuspensionActions: any;
    suspensionActionList: any;
    timeUnits: string[]; 
    enteredPeriod: string;
    selectedUnit: string;
    minuteConversionFactor: {};
    selectedSuspensionAction: string;
    p0SuspensionDuration: any;

    constructor(
        public dialog: MatDialog,
        public appConfigService: AppConfigService,
        public dialogRef: MatDialogRef<CaptainSuspensionDailogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    async ngOnInit() {
        this.suspensionConfig = await this.appConfigService.getSuspensionConfig();
        this.suspensionReasons = JSON.parse(this.suspensionConfig.suspensionReasons) as [];
        this.p0SuspensionReasons = JSON.parse(this.suspensionConfig.p0SuspensionReasons) as [];
        this.p0PriorityMapping = JSON.parse(this.suspensionConfig.p0PriorityMapping);
        this.timeUnits = ["Hours", "Days", "Weeks", "Months", "Years"];
        this.minuteConversionFactor = {
            "Hours": 60,
            "Days": 24 * 60,
            "Weeks": 24 * 60 * 7,
            "Months": 24 * 60 * 30,
            "Years": 24 * 60 * 365,
        };
        await this.mapSuspensionActionWithReasons();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
    suspendCaptain = () => {
        let data; 
        const { selectedSuspensionType, selectedServiceGroup, suspensionReason, suspensionOtherReason, selectedUnit, enteredPeriod, p0SuspensionReason, selectedSuspensionAction, p0SuspensionDuration} = this;
        if(selectedSuspensionType == this.p0Issues){
            if(!selectedServiceGroup || !p0SuspensionReason || !selectedSuspensionAction){
                this.showReasonAlert = true;
            }
            data = {
                title: 'Confirm Suspension',
                type: 'p0Suspension',
                buttonText: 'Suspend',
                reason: p0SuspensionReason,
                serviceGroup: selectedServiceGroup,
                suspensionAction: this.selectedSuspensionAction,
            }
        }
        if(selectedSuspensionType == this.nonP0Issues){
            if (!selectedServiceGroup || !suspensionReason || !selectedUnit || !enteredPeriod) {
                this.showReasonAlert = true;
            } 
            data = {
                title: 'Confirm Suspension',
                type: 'nonP0Suspension',
                buttonText: 'Suspend',
                reason: suspensionReason == "Other"? suspensionOtherReason : suspensionReason,
                serviceGroup: this.selectedServiceGroup,
                suspensionDuration: this.enteredPeriod + ' '+ this.selectedUnit,
            }
        }
        if(!this.showReasonAlert){
            const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '335px',
                data: data
            });
    
            confirmDialogRef.afterClosed().subscribe(confirmResult => {
                if (confirmResult) {
                    this.onProceed();
                }
            });
        }
        
    }
    onProceed = () => {
        const { selectedSuspensionType, selectedServiceGroup, suspensionReason, suspensionOtherReason, selectedUnit, enteredPeriod, p0SuspensionReason, selectedSuspensionAction, p0SuspensionDuration} = this;
        if(selectedSuspensionType == this.nonP0Issues){
            if (selectedServiceGroup && suspensionReason && selectedUnit && enteredPeriod || (suspensionReason == "Other" && !suspensionOtherReason )) {
                this.showReasonAlert = false;
                const paramObj = {
                    serviceGroup: selectedServiceGroup.toLowerCase(),
                    suspensionReason,
                    suspensionOtherReason,
                    minutes: (Number(enteredPeriod))*this.minuteConversionFactor[selectedUnit].toFixed(2),
                };
                this.dialogRef.close(paramObj);
            }
        }
        if(selectedSuspensionType == this.p0Issues){
            if (selectedServiceGroup && p0SuspensionReason && selectedSuspensionAction){
                this.showReasonAlert = false;
                const paramObj = {
                    serviceGroup: selectedServiceGroup.toLowerCase(),
                    suspensionReason: p0SuspensionReason,
                    minutes: p0SuspensionDuration,
                    selectedSuspensionType
                };
                this.dialogRef.close(paramObj);
            }
        }

    }
    changedSuspensionType(suspensionType){
        this.selectedSuspensionType = suspensionType.value;
        if(this.selectedSuspensionType === this.p0Issues){
            this.showP0 = true;
            this.showP1 = false;
        }
        if(this.selectedSuspensionType === this.nonP0Issues){
            this.showP0 = false;
            this.showP1 = true;
        }
        this.showReasonAlert = false;
    }
    changedP0SuspensionReason(suspensionReason){
        this.p0SuspensionReason = suspensionReason.value;
        this.suspensionActionList = this.p0SuspensionActions[this.p0SuspensionReason];
    }
    changedP0SuspensionAction(suspensionAction){
        this.selectedSuspensionAction = suspensionAction.value;
        this.p0SuspensionDuration = this.p0PriorityMapping.priority.p0.issue[this.p0SuspensionReason].action[this.selectedSuspensionAction].suspensionPeriodInMinute;
    }
    async mapSuspensionActionWithReasons(){
        this.p0SuspensionActions = {};
        this.p0SuspensionReasons.forEach(reason=>{
            this.p0SuspensionActions[reason] = Object.keys(this.p0PriorityMapping.priority.p0.issue[reason].action);
        });
    }
}
