import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse, HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment'

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';


const httpOptions = {
  headers: new HttpHeaders({
    'user': '{"_id":"' + localStorage.getItem("userId") + '"}'
  })
};

export interface Config {
  heroesUrl: string;
  textfile: string;
}

@Injectable()
export class SigninService {
  server: string = environment.server;
  googleAuth: any;

  loginUrl = this.server + '/api/auth/login';
  currentUser = this.server + '/api/auth/currentUser';
  setCookie = this.server + '/api/setCookie';
  userUrl = this.server + '/api/getUser';
  // rolesUrl = process.env.SERVER + '/api/get/UserRoles';

  constructor(private http: HttpClient) {
  }

  setGoogleAuth(googleAuth: any) {
    this.googleAuth = googleAuth;
  }

  getCurrentUser(token: any) {
    const data = { accessToken: token };
    return this.http.post(this.currentUser, data);
  }

  logoutGoogle() {
    if (this.googleAuth) {
      this.googleAuth.signOut()
        .then(res => {
          console.log('loggedout');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }


  getLoginResponse(data) {
    return this.http.post(
      this.loginUrl, data);
  }

  setCookies(req) {
    return this.http.post(this.setCookie, req);
  }

  getUserInfo(email = '') {
    return this.http.post(this.userUrl, { 'email': email });
  }

  getUserInfoById(id) {
    return this.http.post(this.userUrl,
      {
        '_id': id
      });
  }
  // getUserRoles(id){
  //   return this.http.post(this.rolesUrl,{"userId": id});
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  makeIntentionalError() {
    return this.http.get('not/a/real/url')
      .pipe(
        catchError(this.handleError)
      );
  }

}
