import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Table, Pagination } from 'src/app/shared/types';
import { EventService } from 'src/app/event.service';
@Component({
  selector: 'app-captain-bulk-action',
  templateUrl: './captain-bulk-action.component.html',
})
export class CaptainBulkActionComponent implements OnInit {
  dataLoaded = true;
  @Input() captainId: string;
  @Input() riderId: string; 
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  bulkActionLogsTable: Table;
  constructor(
    public eventService: EventService,
  ) { }
  ngOnInit() {
    this.declareTable();
    this.getData(this.riderId,this.bulkActionLogsTable.pagination.pageNo, this.bulkActionLogsTable.pagination.perPage);
  }

  declareTable() {
  this.bulkActionLogsTable = new Table({
    headers: {
      type: 'Type',
      time: 'Time',
      date: 'Date',
      modifiedBy: 'Modified By',
      rule: 'Rule',
      shift: 'Shift',
      reason: 'Reason',
      remarks: 'Remarks',
      city: 'City',
      services: 'Services',
      tl: 'TL'
    },
    pagination: new Pagination(0, 10, 30, [10,15,20], 0, true),
    config: {
      refresh: true,
    },
    onRefresh: () => {
      this.bulkActionLogsTable.data = [];
      this.getData(this.riderId, this.bulkActionLogsTable.pagination.pageNo, this.bulkActionLogsTable.pagination.perPage);
    },
    selectedHeader: 2,
    defaultError: 'No Logs Found',
    toggleableHeaders: this.captainDetailsToggleableHeaders,
  });
}

  getData(riderId,page = 0, perPage = 10) {
    this.eventService.getCaptainBulkActionLogs(riderId, page, perPage).subscribe(data => {
      const bulkActions: any = data;
      this.bulkActionLogsTable.data = bulkActions.logs;
      this.bulkActionLogsTable.pagination.count = bulkActions.count;
    });
  }

  onPaginationChange(event) {
    this.getData(this.riderId,event.pageNo,event.perPage);
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }
}
