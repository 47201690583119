<head>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>

<div class = "order-activity-log-dialog-close-icon" (click)="onNoClick()">
    <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button> 
</div>

<div mat-dialog-content>
    <h1 mat-dialog-title class="dialog-title margin-0" [ngStyle]="{'color': 'black'}">{{data.title}}</h1>
    <div [hidden]="getOrderActivityLogStatus()" style="text-align: center">No logs for  this order ¯\_(ツ)_/¯.</div>
    <table class="table" [hidden]="!getOrderActivityLogStatus()">
        <tr>
            <th>Timestamp</th>
            <th>Event</th>
            <th>Description/Sample</th>
        </tr>
        <tr *ngFor="let log of data.logs; let i = index">
            <td>{{log.timestamp}}</td>
            <td>{{log.eventName}}</td>
            <td 
                class = "show-ellipsis"
                placement="bottom" 
                tooltipClass="paramTooltip" 
                container="body"  
                [ngbTooltip]="log.eventData"
            >{{log.eventData}}</td>
        </tr>
    </table>
    <div [hidden]="!getOrderActivityLogStatus()" mat-dialog-actions class="button-flex padding-17-0">
        <button  [hidden]="!getOrderActivityLogStatus()" class="dialog-cancel" (click)="onNoClick()">Ok</button>
    </div>
</div>
