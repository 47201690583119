import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
var startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
var currentDate = new Date();
export var DEFAULT_DATE = {
    FROM_DATE: new Date(new Date(startDate).setDate(new Date(currentDate).getDate() - 1)),
    TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(datePipe) {
        this.datePipe = datePipe;
        this.selectedDates = new EventEmitter();
    }
    CalendarComponent.prototype.ngOnInit = function () {
        if (this.fromDate || this.toDate) {
            this.fromDate = new Date(this.fromDate);
            this.toDate = new Date(this.toDate);
            this.changeInDate();
        }
    };
    CalendarComponent.prototype.ngOnChanges = function (changes) {
        if (changes.fromDate && this.fromDate) {
            this.fromDate = new Date(this.fromDate);
        }
        if (changes.toDate && this.toDate) {
            this.toDate = new Date(this.toDate);
        }
    };
    CalendarComponent.prototype.changeInDate = function () {
        var _a;
        if (this.fromDate) {
            this.minDate = this.fromDate;
        }
        if (this.toDate) {
            this.maxDate = this.toDate;
        }
        var fromDate = this.fromDate ? new Date(this.fromDate) : DEFAULT_DATE.FROM_DATE;
        var toDate = this.toDate ? new Date(this.toDate) : new Date();
        // console.log('dates : : : : ', this.fromDate, this.toDate);
        var sendStartDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
        var sendEndDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');
        var emitSelections = (_a = {},
            _a[this.calendarData.key] = [sendStartDate, sendEndDate],
            _a);
        // console.log('Emitted vals : ', emitSelections);
        this.selectedDates.emit(emitSelections);
    };
    return CalendarComponent;
}());
export { CalendarComponent };
