<div  class = "qucik-filters-wrap">
  <div *ngFor = "let filter of quickFilters; let i = index"
       [ngClass]="{'quick-filter': true, 'active-filter': selectedFilter == i}"
       (click) = "onFilterClick($event, i, filter)">
      {{filter}}
  </div>
  <calendar
    [fromDate]="startDate" 
    [toDate]="endDate"
    (selectedDates) = "getSelectedDateRanges($event)"
    [calendarData]="calendarData"
  ></calendar>
</div>
