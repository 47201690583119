import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class CaptainCallLogsService {
    constructor(public httpClient: HttpClient) { }

    async getCaptainCallLogs(id: string, page: number, pageSize: number): Promise<any> {
        const url = `${environment.server}/api/captain/callLogs/${id}`;
        const result = await this.httpClient.get(url, {
            params: { page: page.toString(), pageSize: pageSize.toString() }
        }).toPromise();
        return result;
    }
}
