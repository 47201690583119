var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerInvoiceDetailsService } from './customer-invoice-details.service';
import { ToasterService } from '../toaster.service';
import { ToasterType, Toaster } from '../shared/types';
var CustomerInvoiceDetailsComponent = /** @class */ (function () {
    function CustomerInvoiceDetailsComponent(route, CustomerInvoiceService, _toastMessage) {
        var _this = this;
        this.route = route;
        this.CustomerInvoiceService = CustomerInvoiceService;
        this._toastMessage = _toastMessage;
        this.email = '';
        this.getInvoiceDetails = function () {
            _this.CustomerInvoiceService.fetchInvoiceDetails(_this.orderId).subscribe(function (result) {
                _this.billOfSupply = result['billOfSupply'];
                _this.paymentSummary = __assign({}, result['paymentSummary'], { orderStatus: _this.orderStatus });
                _this.taxInvoice = result['taxInvoice'];
            }, function (err) {
                _this.errorMessage = err.error.message || 'Invoice not found for the order.';
            });
        };
    }
    CustomerInvoiceDetailsComponent.prototype.ngOnInit = function () {
        this.orderId = this.route.snapshot.params.orderId || '';
        this.orderStatus = this.route.snapshot.params.orderStatus || '';
        this.customerId = this.route.snapshot.params.customerId || '';
        this.getInvoiceDetails();
    };
    CustomerInvoiceDetailsComponent.prototype.sendInvoice = function () {
        var _this = this;
        this.CustomerInvoiceService.sendInvoiceToEmail(this.email, this.orderId, this.customerId).subscribe(function (result) {
            var message = _this.email ? "Email will be sent to " + _this.email : result['message'];
            _this._toastMessage.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: message,
            }));
        }, function (err) {
            _this._toastMessage.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: err.error.message || 'Unable to load.',
            }));
        });
    };
    return CustomerInvoiceDetailsComponent;
}());
export { CustomerInvoiceDetailsComponent };
