<div class="boxed-border">
  <div class="row title margin-9-16">
    <div class="color-card-header padding-0">
      {{data?.tableHeader}}
    </div>
  </div>
  <div class="line"></div>
  <div class="information-block container">
    <div class="row">
      <div class="col-8 name">
        {{data?.name}}
        <span class="number" *ngIf="data?.number">
          {{data?.number}}
        </span>
      </div>
      <div class="col-4 otp">
        <span class="order-value" *ngIf="data?.orderValue">
          Order Value : {{data?.orderValue || 'N/A'}}
        </span>
        <span class="green-otp" *ngIf="data?.otp">
          OTP : {{data?.otp || 'N/A'}}
        </span>

        <span class="red-otp" *ngIf="data?.dropOtp">
          OTP : {{data?.dropOtp || 'N/A'}}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col sub-header-texts">
        Address
      </div>
      <div class="otp-div" *ngIf="data?.pickupOtp">
        START OTP - {{ data?.pickupOtp}}
      </div>
      <div class="otp-div" *ngIf="data?.dropOtpStatus">
        DROP OTP - {{data?.dropOtpStatus}}
      </div>
      </div>
      <div class="address">
        {{data?.address || 'N/A'}}
      </div>
    <div class="row">
      <div class="col-3" *ngIf="data?.imageUrl">
        <div class="sub-header-texts">
          {{ this.getPackageImageTitle()}}
        </div>
        <div class="row">
          <div class="package-images" (click)="openDialog(image)" *ngFor="let image of allImages">
            <img [src]="image" height="60px" width="60px">
          </div>
        </div>
      </div>
      <div class="col-3" *ngIf="data?.directImageUrl">
        <div class="sub-header-texts">
          {{ this.getPackageImageTitle()}}
        </div>
        <div class="row">
          <div class="package-images" (click)="openDialog(image)" *ngFor="let image of data.directImageUrl">
            <img [src]="image" height="60px" width="60px">
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="data?.dropInstructions || data?.captainComments">
        <div class="clickable-sub-headers" *ngIf="data?.dropInstructions" (click)="openPopup(data?.dropInstructions)">
          Show Delivery Instructions
        </div>
        <div class="clickable-sub-headers" *ngIf="data?.captainComments" (click)="openPopup(data?.captainComments)">
          Show Captain Drop-off notes
        </div>
        <div *ngIf="showPopUp" class="popup">
          <span (click)="closePopup()" class="cross">
            <mat-icon style="color: white; font-size: 15px; height: fit-content; width: fit-content;">close</mat-icon>
          </span>
          {{popUpText}}
        </div>
      </div>
      <div class="col" *ngIf="data?.mainCategory">
        <div class="sub-header-texts">
          Category
        </div>
        <div class="item-category" *ngFor="let category of mainCategoryToShow">
          {{category}}
        </div>
        <div class="info-div" *ngIf="additionalMainCategories && additionalMainCategories.length">
          +{{additionalMainCategories.length}} more categories
          <span class="vertical-center" *ngIf="additionalMainCategories">
            <ng-template #tipContent>
              <div [innerHTML]="additionaMainlCategoriesToolTip"></div>
            </ng-template>
            <i data-toggle="tooltip" placement="bottom" tooltipClass="preserve-whitespace width-max-content"
              [ngbTooltip]="tipContent" class="material-icons-outlined info-icon-adjustment cursor-pointer">
              info
            </i>
          </span>
        </div>
      </div>
      <div class="col" *ngIf="data?.itemCategory">
        <div class="sub-header-texts">
          {{getItemCategoryTitle()}}
        </div>
        <div class="item-category" *ngFor="let item of categoriesToShow">
          {{item || 'N/A'}}
        </div>
        <div class="info-div" *ngIf="additionalCategories && additionalCategories.length">
          +{{additionalCategories.length}} more items
          <span class="vertical-center" *ngIf="additionalCategories">
            <ng-template #tipContent>
              <div [innerHTML]="additionalCategoriesToolTip"></div>
            </ng-template>
            <i data-toggle="tooltip" placement="bottom" tooltipClass="preserve-whitespace width-max-content"
              [ngbTooltip]="tipContent" class="material-icons-outlined info-icon-adjustment cursor-pointer">
              info
            </i>
          </span>
        </div>
      </div>
      <div class="col-3 track-url clickable-sub-headers" (click)="copyTrackUrl()" *ngIf="data?.trackUrl">
        <div>
          <i class="material-icons-outlined cursor-pointer copy-icon" pTooltip="Copy to clipboard"
            tooltipPosition="bottom">
            copy
          </i>
          Track Url
          <span style="font-size: 12px;" *ngIf="showCopied">
            Copied!
          </span>
        </div>
      </div>
    </div>
  </div>
</div>