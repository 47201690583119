<head>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>

<h1 mat-dialog-title class="dialog-title margin-0" [ngStyle]="{'color': 'black'}">{{data.title}}</h1>
<div>
    <div class="label-wrapper">
        <mat-label>Suspension Type</mat-label>
    </div>
    <mat-form-field>
        <mat-label>Select Suspension Type</mat-label>
        <mat-select [(ngModel)]="selectedSuspensionType" (selectionChange)="changedSuspensionType($event)" style="width: 200px;">
            <mat-option [value]="p0Issues">P0 Issues</mat-option>
            <mat-option [value]="nonP0Issues">Non P0 Issues</mat-option>
        </mat-select>
        </mat-form-field>
    </div>
<div *ngIf="showP1" mat-dialog-content>
    <div mat-dialog-content>
        <div>
            <div class="label-wrapper">
                <mat-label>Service Group</mat-label>
            </div>
            <mat-form-field>
                <mat-label>Select Service Group</mat-label>
                <mat-select [(ngModel)]="selectedServiceGroup" style="width: 200px;">
                    <mat-option *ngFor="let service of nonP0ServiceGroups" [value]="service">
                    {{service}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="reason-wrapper">
            <div>
                <div class="label-wrapper">
                    <mat-label>Reason for suspension</mat-label>
                </div>
                <mat-form-field>
                    <mat-label>Enter reason</mat-label>
                    <mat-select [(ngModel)]="suspensionReason" style="width: 200px;">
                        <mat-option *ngFor="let reason of suspensionReasons" [value]="reason">
                        {{reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <form *ngIf="suspensionReason === 'Other'">
                    <mat-form-field class="example-full-width">
                        <input maxlength="50" matInput [(ngModel)]="suspensionOtherReason" name="suspensionOtherReason" placeholder="Please provide explanation">
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div>
            <div class="label-wrapper">
                <mat-label>Suspension Action</mat-label>
            </div>
            <div class="reason-wrapper">
                <mat-form-field>
                    <input
                        type="number"
                        min="0"
                        matInput
                        [(ngModel)]="enteredPeriod"
                        name="enteredPeriod"
                        placeholder="Enter Number"
                        pattern="[0-9.]+"
                        style="width: 200px"
                    >
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Select Unit</mat-label>
                    <mat-select [(ngModel)]="selectedUnit" style="width: 200px">
                        <mat-option *ngFor="let unit of timeUnits" [value]="unit">
                        {{unit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
</div>

<div *ngIf="showP0" mat-dialog-content>
    <div mat-dialog-content>
        <div>
            <div class="label-wrapper">
                <mat-label>Service Group</mat-label>
            </div>
            <mat-form-field>
                <mat-label>Select Service Group</mat-label>
                <mat-select [(ngModel)]="selectedServiceGroup" style="width: 200px;">
                    <mat-option *ngFor="let service of serviceGroups" [value]="service">
                    {{service}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="reason-wrapper">
            <div>
                <div class="label-wrapper">
                    <mat-label>Reason for suspension</mat-label>
                </div>
                <mat-form-field style="width: 420px;">
                    <mat-label>Enter reason</mat-label>
                    <mat-select [(ngModel)]="p0SuspensionReason" (selectionChange)= "changedP0SuspensionReason($event)" style="width: 420px;">
                        <mat-option *ngFor="let reason of p0SuspensionReasons" [value]="reason">
                        {{reason}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="reason-wrapper">
            <div>
                <div class="label-wrapper">
                    <mat-label>Suspension Action</mat-label>
                </div>
                <mat-form-field style="width: 210px;">
                    <mat-label>Select Suspension Action</mat-label>
                    <mat-select [(ngModel)]="selectedSuspensionAction" (selectionChange) = "changedP0SuspensionAction($event)" style="width: 210px;">
                        <mat-option *ngFor="let suspensionAction of suspensionActionList" [value]="suspensionAction">
                        {{suspensionAction}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
</div>

<div *ngIf="showReasonAlert" class="reason-alert">
    <i class="material-icons-outlined info">info</i>
    <label *ngIf="showP1" class="font-size-13 color-rusty-red margin-left-5">Please select a service group, time and reasons or explanation for the suspension.</label>
    <label *ngIf="showP0" class="font-size-13 color-rusty-red margin-left-5">Please select a service group, reasons and suspension action</label>
    
</div>

<div mat-dialog-actions class="button-flex">
    <button class="dialog-cancel" (click)="onNoClick()">Cancel</button>
    <button class="proceed font-size-13 font-weight-600" (click)="suspendCaptain()">Proceed</button>
</div>