/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-general-info.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../card-layout/card-layout.component.ngfactory";
import * as i3 from "../../card-layout/card-layout.component";
import * as i4 from "./customer-general-info.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "./customer-general-info.service";
import * as i7 from "../../toaster.service";
import * as i8 from "../../data.service";
var styles_CustomerGeneralInfoComponent = [i0.styles];
var RenderType_CustomerGeneralInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerGeneralInfoComponent, data: {} });
export { RenderType_CustomerGeneralInfoComponent as RenderType_CustomerGeneralInfoComponent };
export function View_CustomerGeneralInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-layout", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.eventTriggered($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardLayoutComponent_0, i2.RenderType_CardLayoutComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardLayoutComponent, [], { info: [0, "info"] }, { change: "change" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerGeneralInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-general-info", [], null, null, null, View_CustomerGeneralInfoComponent_0, RenderType_CustomerGeneralInfoComponent)), i1.ɵdid(1, 638976, null, 0, i4.CustomerGeneralInfoComponent, [i5.MatDialog, i6.CustomerGeneralInfoService, i7.ToasterService, i8.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerGeneralInfoComponentNgFactory = i1.ɵccf("app-customer-general-info", i4.CustomerGeneralInfoComponent, View_CustomerGeneralInfoComponent_Host_0, { data: "data" }, { activate: "activate", deactivate: "deactivate" }, []);
export { CustomerGeneralInfoComponentNgFactory as CustomerGeneralInfoComponentNgFactory };
