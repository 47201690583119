import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable()
export class KycService {
  constructor(public httpClient: HttpClient) {}
  async getCaptainNudgeDetails(id: any) {
    const url = `${environment.server}/api/kyc/captain/${id}/nudges`;
    return await this.httpClient.get(url).toPromise();
  }
}
