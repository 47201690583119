var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { CaptainSubscriptionsService } from 'src/app/captain-subscriptions.service';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
var CaptainSubscriptionsComponent = /** @class */ (function () {
    function CaptainSubscriptionsComponent(captainSubscriptionsService) {
        this.captainSubscriptionsService = captainSubscriptionsService;
        this.toggleHeaders = new EventEmitter();
        this.dataLoaded = false;
    }
    CaptainSubscriptionsComponent.prototype.ngOnInit = function () {
        this.declareTable();
    };
    CaptainSubscriptionsComponent.prototype.declareTable = function () {
        var _this = this;
        this.captainSubscriptions = new Table({
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            headers: {
                amountAndPurchaseDate: 'Amount (INR)\n Purchase Date',
                activeTime: 'Start Time\n End Time',
                planType: 'Plan Type',
                planStatus: 'Plan Status',
                consumption: 'Consumption',
                cityAndServices: 'City\n Services',
                paymentStatusAndMode: 'Payment Status\n Payment Mode',
                commissionSaved: 'Commission\n Saved',
                id: 'Subscription ID',
            },
            pagination: new Pagination(0, 10, 30, [10, 15, 20], 0, true),
            selectedHeader: 6,
            defaultError: 'No Subscriptions found',
            onRefresh: function () {
                _this.captainSubscriptions = null;
                _this.ngOnInit();
            },
            data: []
        });
        this.getSubscriptions();
    };
    CaptainSubscriptionsComponent.prototype.getSubscriptions = function (pageNumber, perPage) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (perPage === void 0) { perPage = 10; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.dataLoaded = false;
                this.captainSubscriptionsService.getCaptainSubscriptions(this.captainId, pageNumber, perPage)
                    .then(function (_a) {
                    var subscriptions = _a.subscriptions;
                    _this.captainSubscriptions.data = subscriptions.map(function (subscription) {
                        return {
                            amountAndPurchaseDate: _this.createTableData(subscription.amount + "\n" + _this.dateString(subscription.createdOn)),
                            activeTime: _this.createTableData(subscription.startTime && subscription.endTime ? _this.dateString(subscription.startTime) + "\n" + _this.dateString(subscription.endTime) : "NA"),
                            planType: _this.createTableData(subscription.progress.map(function (p) { return p.type; }) + " validity\n" +
                                subscription.rules.map(function (r) { return r.operator + " " + r.value + " " + (r.type == "percent" ? "% commission" : "commission"); }).join('\n')),
                            planStatus: subscription.planStatus,
                            consumption: _this.createTableData(subscription.progress.map(function (p) { return p.type != "unlimited" && subscription.status == "SUCCESS" ? p.current + " / " + p.target + " " + p.type : "NA"; })),
                            cityAndServices: _this.createTableData(subscription.cityName + "\n" + subscription.serviceNames.join(', ')),
                            paymentStatusAndMode: _this.createTableData(subscription.status + "\n" + subscription.paymentMode),
                            commissionSaved: subscription.commissionSaved,
                            id: _this.createTableData(subscription.id, 'text-overflow-unset'),
                        };
                    });
                    _this.dataLoaded = true;
                }).catch(function (err) {
                    console.log(err);
                    _this.dataLoaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    CaptainSubscriptionsComponent.prototype.dateString = function (epoch) {
        return new Date(epoch * 1000).toLocaleString();
    };
    CaptainSubscriptionsComponent.prototype.createTableData = function (value, className, type, flag, hoverData) {
        if (flag === void 0) { flag = false; }
        if (hoverData === void 0) { hoverData = null; }
        return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
        });
    };
    CaptainSubscriptionsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CaptainSubscriptionsComponent.prototype.onPaginationChange = function (event) {
        this.getSubscriptions((event.pageNo + 1), event.perPage);
    };
    return CaptainSubscriptionsComponent;
}());
export { CaptainSubscriptionsComponent };
