<div class="relative-positioning">
  <div class="progress" id="progress-top">
    <div id="progress-bar" class="progress-bar {{classForColor}}" 
    [ngStyle]="{width: percentage}"
    role="progressbar" aria-valuenow="completed" aria-valuemin="min" aria-valuemax="max"></div>
  </div>
  <span class="absolute-positioning absolute-left">
    {{min}}
  </span>
  <span *ngIf="(progressStatus !== 'NOT_STARTED') && (completed < max)" class="absolute-positioning" [ngClass]="{'toast-title': completed !== 0}" [ngStyle]="{left: percentage}">
    {{completed}}
  </span>
  <span [ngClass]="{'toast-title':(progressStatus !== 'NOT_STARTED') && (completed >= max)}" class="absolute-positioning absolute-right">
    {{max}}
  </span>
</div>