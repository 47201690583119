import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class AppConfigService {
  constructor(public httpClient: HttpClient) {}

  async getSuspensionConfig() {
    const url = `${environment.server}/api/appConfig/captain/suspension`;
    const result = await this.httpClient.get(url).toPromise();
    return result;
  }

  async getAdjustmentConfig() {
    const url = `${environment.server}/api/appConfig/customer/adjustment`;
    const result = await this.httpClient.get(url).toPromise();
    return result;
  }

  async getAdjustmentConfigForCaptain() {
    const url = `${environment.server}/api/appConfig/captain/adjustment`;
    const result = await this.httpClient.get(url).toPromise();
    return result;
  }
}
