var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MerchantService } from '../merchant.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { DataService } from '../../data.service';
var MerchantSearchComponent = /** @class */ (function () {
    function MerchantSearchComponent(router, activedRoute, merchantService, toasterService, dataservice) {
        this.router = router;
        this.activedRoute = activedRoute;
        this.merchantService = merchantService;
        this.toasterService = toasterService;
        this.dataservice = dataservice;
        this.mobile = '';
        this.enteredNumber = false;
        this.focus = false;
    }
    MerchantSearchComponent.prototype.ngOnInit = function () {
        this.mobile = this.activedRoute.snapshot.paramMap.get('id');
        this.activedRoute.parent.params.subscribe(function (params) {
        });
    };
    MerchantSearchComponent.prototype.searchMerchant = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.isValidMobileNumber(this.mobile)) {
                    return [2 /*return*/];
                }
                this.mobile = this.mobile;
                this.searchMobileNumber();
                return [2 /*return*/];
            });
        });
    };
    MerchantSearchComponent.prototype.isValidMobileNumber = function (mobile) {
        var testMob = new RegExp('[0-9]{10}');
        if (!testMob.test(mobile)) {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Please enter a valid 10-digit mobile number",
            }));
            return false;
        }
        return true;
    };
    MerchantSearchComponent.prototype.searchMobileNumber = function () {
        var _this = this;
        this.merchantService.getMerchantByMobile(this.mobile)
            .subscribe(function (data) {
            if (data && data['data']) {
                _this.dataservice.updateSearchCount(_this.mobile);
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.SUCCESS,
                    message: "User found",
                }));
                _this.router.navigate(["/merchant/" + _this.mobile]);
                _this.mobile = '';
                document.getElementById('searchMerchant').blur();
            }
            else {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Unable to fetch this merchant; Please check the number again",
                    config: {}
                }));
            }
        }, function (err) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch this merchant; Please check the number again",
                config: {}
            }));
        });
    };
    return MerchantSearchComponent;
}());
export { MerchantSearchComponent };
