import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core'
import { UserData } from '../manager-profile.types'
import { ManagerGeneralInfoService } from './manager-general-info.service'
import { ReferralDetails } from './manager-general-info.types'
@Component({
  selector: 'app-manager-general-info',
  templateUrl: './manager-general-info.component.html',
  styleUrls: ['./manager-general-info.component.css'],
})
export class ManagerGeneralInfoComponent implements OnInit, OnChanges {
  @Input() data: UserData
  public actorEmail: string
  public actorId: string
  public actorName: string
  public referralCode: string
  public info: any = {}
  constructor(public managerGeneralInfoService: ManagerGeneralInfoService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue
      if (this.data) {
        this.fetchRefereeDetails()
        this.constructCard()
      } else {
        this.blankCard()
      }
    } else {
      this.emptyCard()
    }
  }

  ngOnInit() {
    const userCookie =
      this.accessCookie('user') && JSON.parse(this.accessCookie('user'))
        ? JSON.parse(this.accessCookie('user'))
        : {}
    this.actorEmail = userCookie && userCookie.emailId ? userCookie.emailId : ''
    this.actorId = userCookie && userCookie._id ? userCookie._id : ''
    this.actorName =
      userCookie && userCookie.firstName ? userCookie.firstName : ''
    this.fetchRefereeDetails()
  }

  fetchRefereeDetails() {
    if (this.data) {
      this.managerGeneralInfoService
        .getReferralDetails(this.data._id)
        .subscribe((referrerDetails: ReferralDetails) => {
          this.referralCode =
            referrerDetails.result && referrerDetails.result.referralCode
          this.constructCard()
        })
    }
  }

  blankCard() {
    this.info = {
      header: 'General Info',
      data: [],
      dataFetched: false,
    }
  }

  emptyCard() {
    this.info = {
      header: 'General Info',
      dataFetched: false,
      data: null,
    }
  }

  constructCard() {
    if (this.data) {
      this.info = {
        header: 'General Info',
        dataFetched: true,
        data: [
          {
            header: 'Name',
            value: this.data.firstName ? this.data.firstName : '',
            className: 'id-icon',
            svgIcon: true,
          },
          {
            header: 'Email',
            value: this.data.email ? this.data.email : '',
            className: 'email-id-icon',
            svgIcon: true,
          },
          {
            header: 'Last Used Device',
            value: this.data.device.manufacturer,
            className: 'last-used-device-icon',
            svgIcon: true,
          },
          {
            header: 'Referral code',
            value: this.referralCode,
            className: 'referral-code-icon',
            svgIcon: true,
          },
        ],
      }
    }
  }

  accessCookie(cookieName) {
    const name = cookieName + '='
    const allCookieArray = document.cookie.split(';')
    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim()
      if (temp.indexOf(name) === 0) {
        return temp.substring(name.length, temp.length)
      }
    }
    return ''
  }
}
