/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./c2c-receiver-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../c2c-order-details/c2c-order-details.component.ngfactory";
import * as i3 from "../c2c-order-details/c2c-order-details.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../order-details/order-details.service";
import * as i6 from "./c2c-receiver-details.component";
var styles_C2cReceiverDetailsComponent = [i0.styles];
var RenderType_C2cReceiverDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_C2cReceiverDetailsComponent, data: {} });
export { RenderType_C2cReceiverDetailsComponent as RenderType_C2cReceiverDetailsComponent };
export function View_C2cReceiverDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-c2c-order-details", [], null, null, null, i2.View_C2cOrderDetailsComponent_0, i2.RenderType_C2cOrderDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i3.C2cOrderDetailsComponent, [i4.MatDialog, i5.OrderDetailsService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.receiverDetails; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_C2cReceiverDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-c2c-receiver-details", [], null, null, null, View_C2cReceiverDetailsComponent_0, RenderType_C2cReceiverDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i6.C2cReceiverDetailsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var C2cReceiverDetailsComponentNgFactory = i1.ɵccf("app-c2c-receiver-details", i6.C2cReceiverDetailsComponent, View_C2cReceiverDetailsComponent_Host_0, { packageDetails: "packageDetails" }, {}, []);
export { C2cReceiverDetailsComponentNgFactory as C2cReceiverDetailsComponentNgFactory };
