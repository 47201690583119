import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MerchantService } from '../merchant.service';
import { Table, TableData, TableDataTypes, Pagination } from 'src/app/shared/types';
import { Router } from '@angular/router';
@Component({
  selector: 'app-merchant-transaction-details',
  templateUrl: './merchant-transaction-details.component.html',
  styleUrls: ['./merchant-transaction-details.component.css']
})
export class MerchantTransactionDetailsComponent implements OnInit {
  @Input() merchantId: any;
  @Input() merchantUserId: any;
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  transactionsTable: Table;
  transactions: any = [];
  pageNumber: Number = 0;
  offset: Number = 0;
  perPage: Number = 5;
  dataLoaded = false;
  constructor(
    private router: Router,
    private merchantService: MerchantService
  ) { }

  ngOnInit() {
    this.initializeTable();
    this.fillDataToTable();
  }

  onPaginationChange(event) {
    this.pageNumber = event.pageNo;
    this.offset = (event.pageNo * event.perPage);
    this.perPage = event.perPage;
    this.fillDataToTable();
  }


  fillDataToTable() {
    if (this.merchantId) {
      this.dataLoaded = false;
      this.merchantService
      .getMerchantTxns(this.merchantId, this.perPage, this.offset, this.merchantUserId).subscribe(result => {
        const self = this;
        this.dataLoaded = true;
        function createTableData(value, className?, info?, hover?) {
          return new TableData({
            data: value,
            type: TableDataTypes.DATA,
            information: info,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
            stopHover: hover ? true : false,
          });
        }
        const customerPassbook = result && result['list'] || [];
        this.transactions = customerPassbook.map((transaction) => {
          let paymentSplit = null;
          if (transaction.paymentSplit) {
            const keys = Object.keys(transaction.paymentSplit);
            const values = Object.values(transaction.paymentSplit);
            if (keys.length > 1) {
              const paymentSplitOnHover = [];
              for (const split in values) {
                if (values[split] && keys[split]) {
                  paymentSplitOnHover.push(keys[split] + ' : ₹' + values[split]);
                }
              }
              if (paymentSplitOnHover.length) {
                paymentSplit = paymentSplitOnHover.join('\n');
              }
            }
          }
          return {
            date: createTableData(transaction.transactionDate),
            paidFor: createTableData(transaction.uniqueId),
            txnId: createTableData(transaction.shortCode || 'N/A'),
            txnType: createTableData(transaction.transactionType),
            paymentMode: createTableData('Rapido Wallet'),
            couponCode: createTableData(transaction.couponCode || 'N/A'),
            total: createTableData(transaction.originalAmount)
          };
        });
        this.transactionsTable['data'] = this.transactions;
        this.transactionsTable['pagination'].count = result['count'];
      }, error => {
        this.transactionsTable['data'] = []
      });
    } else {
      this.dataLoaded = true;
    }
  }

  initializeTable(pageNumber = 0) {
    this.transactionsTable = new Table({
      pagination: new Pagination(pageNumber, 10),
      defaultError: 'No transactions found',
      data: [],
      headers: {
        date: 'Date',
        paidFor: 'Paid For',
        txnId: 'Transaction ID',
        txnType: 'Transaction Type',
        paymentMode: 'Payment Mode',
        couponCode: 'Coupon Code',
        total: 'Total'
      },
      toggleableHeaders: ['Order Details', 'Transaction Details'],
      selectedHeader: 1,
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.transactionsTable = null;
        this.ngOnInit();
      },
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }
}
