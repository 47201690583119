import { Table, TableData, ILabelAction } from '.';


export interface IRecordTable {
  dynamicClass?: any;
  header: any;
  data: TableData[];
  colCount?: number;
  filterComponents?: any;
  filterCount?: any;
  config?: {
    colName?: boolean,
    filter?: boolean,
    refresh?: boolean,
    ellipses?: ILabelAction[],
  };
  style?: any;
  events?: {
    onSearch?: (searchObj: any) => void;
    onFilter?: (filters: any) => void;
    onRefresh?: () => void;
  };
}

export class RecordTable {
  dynamicClass: any;
  header: any;
  table: Table;
  data: TableData[] = [];
  colCount = 1;
  style: any;
  ellipses: any;
  filterCount: any;
  private DEFAULT_CONFIG: any = {
    colName: false,
    filter: false,
    refresh: false,
    ellipses: [],
  };
  private DEFAULT_EVENTS = {
    onSearch: (searchObj: any) => console.log(searchObj),
    onFilter: () => console.log('filter'),
    onRefresh: () => console.log('refresh'),
  };
  constructor(config: IRecordTable) {
    Object.assign(this, config);
    const headers = {};
    for (let i = 0; i < this.colCount; i++) {
      headers[i] = i;
    }
    const tableConfig = Object.assign({}, this.DEFAULT_CONFIG, config.config);
    const events = Object.assign({}, this.DEFAULT_EVENTS, config.events);
    const tableData = [];
    for (let i = 0; i < this.data.length; i++) {
      const col = i % this.colCount;
      if (col === 0) {
        tableData.push({
          [col]: this.data[i]
        });
        continue;
      }
      const lastCol = tableData[tableData.length - 1];
      lastCol[col] = this.data[i];
    }
    const defaultStyle = {
        'margin-bottom': '0px'
    };
    const style = Object.assign({}, defaultStyle, config.style);
    this.table = new Table({
      dynamicClass: config.dynamicClass,
      tableHeader: config.header,
      headers,
      data: tableData,
      style,
      config: tableConfig,
      onFilter: events.onFilter,
      onRefresh: events.onRefresh,
      onSearch: events.onSearch,
      filterComponents: config.filterComponents,
      filterCount: config.filterCount
    });
  }
}
