import { Observable } from 'rxjs';
import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client.service";
var CustomerSubscriptionService = /** @class */ (function () {
    function CustomerSubscriptionService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.subscription = this.server + '/api/getSubscription';
    }
    CustomerSubscriptionService.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    CustomerSubscriptionService.prototype.getSubscriptionLogs = function (userId, limit, offset) {
        return this.http.post(this.subscription + '/history/', { userId: userId, limit: limit, offset: offset }).pipe(catchError(this.handleError));
    };
    CustomerSubscriptionService.prototype.cancelUserSubscription = function (transactionId) {
        var adminUser = this.accessCookie("user")
            ? JSON.parse(this.accessCookie("user"))
            : {};
        if (adminUser) {
            var adminUserId = adminUser["_id"] ? adminUser["_id"] : null;
            var adminEmailId = adminUser["emailId"] ? adminUser["emailId"] : null;
            var requestBody = {
                transactionId: transactionId,
                admin: {
                    userId: adminUserId,
                    emailId: adminEmailId,
                },
            };
            return this.http
                .post(this.server + "/api/cancelSubscription", requestBody)
                .pipe(catchError(this.handleError));
        }
        return Observable.of({});
    };
    CustomerSubscriptionService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    CustomerSubscriptionService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerSubscriptionService_Factory() { return new CustomerSubscriptionService(i0.inject(i1.MyHttpClient)); }, token: CustomerSubscriptionService, providedIn: "root" });
    return CustomerSubscriptionService;
}());
export { CustomerSubscriptionService };
