import { Injectable } from '@angular/core';
import { Toaster } from './shared/types';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor() { }
  toaster: Toaster;

  showToaster(toaster: Toaster) {
    this.toaster = toaster;
  }
}
