import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-adjustment-history',
  templateUrl: './adjustment-history.component.html',
  styleUrls: ['./adjustment-history.component.css']
})
export class AdjustmentHistoryComponent implements OnInit, OnChanges {
  @Input() custAdjustments: Object[];
  @Input() captAdjustments: Object[];
  @Input() offlineRechargeInfo: any;
  @Input() pageRefreshed: any;
  @Input() prevDuesAdjustments: any;
  custDetails: Object = {};
  captDetails: Object = {};
  captAdjTypes = ['penalty', 'ordersAdjustment', 'adjustment', 'ExtraKmFareAdjustment', 'pickupExtraKmFareAdjustment'];
  captSorted = false;
  custSorted = false;
  prevDuesSorted = false;
  custEmpty = false;
  captEmpty = false;
  prevDueEmpty = false;
  noData = false;
  dataRows = [];
  finalDataRows = [];
  tableData: any;
  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.offlineRechargeInfo && changes.offlineRechargeInfo.currentValue) {
      this.mergeHistory();
    }
    if (changes && changes.pageRefreshed && changes.pageRefreshed.currentValue) {
      this.finalDataRows = [];
    }
    if (changes && changes.custAdjustments && changes.custAdjustments.currentValue) {
      if (changes.custAdjustments.currentValue.dataFetched) {
        const finalCust = this.custAdjustments['data'] && this.custAdjustments['data']['response'] &&
            this.custAdjustments['data']['response']['data'] && this.custAdjustments['data']['response']['data']['list'] ?
            this.custAdjustments['data']['response']['data']['list'] : null;
        this.custAdjustments = finalCust;
        this.finalDataRows = [];
        this.sortCustomerAdjustments();
        if (this.captAdjustments) {
          this.sortCaptainAdjustments();
        }
        if(this.prevDuesAdjustments){
          this.sortPrevDueAdjustments();
        }
      } else {
        this.custEmpty = true;
        this.emptyCard('Cannot load adjustments');
      }
    } else {
      this.custSorted = true;
    }

    if (changes && changes.captAdjustments && changes.captAdjustments.currentValue) {
      if (changes.captAdjustments.currentValue.dataFetched) {
        const finalCapt = this.captAdjustments['data'] && this.captAdjustments['data']['data'] &&
        this.captAdjustments['data']['data']['riderPaymentDetails'] ?
        this.captAdjustments['data']['data']['riderPaymentDetails'] : null;
        this.captAdjustments = finalCapt;
        this.finalDataRows = [];
        this.sortCaptainAdjustments();
        if (this.custAdjustments) {
          this.sortCustomerAdjustments();
        }
        if(this.prevDuesAdjustments){
          this.sortPrevDueAdjustments();
        }
      } else {
        this.captEmpty = true;
        this.emptyCard('Cannot load adjustments');
      }
    } else {
      this.captSorted = true;
    }
    if (changes && changes.prevDuesAdjustments && changes.prevDuesAdjustments.currentValue) {
      if (changes.prevDuesAdjustments.currentValue.dataFetched) {
        const finalPrevDue = this.prevDuesAdjustments['data'] ? this.prevDuesAdjustments['data'] : null;
        this.prevDuesAdjustments = finalPrevDue;
        this.finalDataRows = [];
        this.sortPrevDueAdjustments();
        if (this.custAdjustments) {
          this.sortCustomerAdjustments();
        }
        if(this.captAdjustments){
          this.sortCaptainAdjustments();
        }
      } else {
        this.prevDueEmpty = true;
        this.emptyCard('Cannot load adjustments');
      }
    } else {
      this.prevDuesSorted = true;
    }
  }
  sortPrevDueAdjustments(){
    if(this.prevDuesAdjustments && this.prevDuesAdjustments.length > 0){
      this.prevDuesAdjustments.forEach(adj=> {
        this.finalDataRows.push({
          action: {data: adj["amount"]? "Dues Cleared  ₹" + adj["amount"] : "", className: 'color-kermit-green font-size-13'},
          change: {data: 'Customer'},
          reason: {data: adj["source"] == "cancellation"? "Cleared Cancellation Charges Dues" : adj["source"]},
          timeStamp: {data: adj["date"], className: 'preserve-whitespace'},
          adjustedBy: {data: adj["source"] == "cancellation"? "chatbot@rapido.bike": ""}
        })
      });
      this.prevDuesSorted = true;
      this.mergeHistory();
    }
    else{
      this.prevDuesSorted = true;
      this.mergeHistory();
    }
  }
  sortCustomerAdjustments() {
    if (this.custAdjustments && this.custAdjustments.length > 0) {
      for (const adj in this.custAdjustments) {
        if (this.custAdjustments[adj] && this.custAdjustments[adj]['transactionType']) {
          const data = this.custAdjustments[adj];
          switch (data['transactionType']) {
            case 'debit' :
              if (data['title'] === 'adjustment') {
                this.finalDataRows.push({
                  action: {data: data['originalAmount'] ? 'Debit ₹' + (data['originalAmount']).toFixed(2) : '' ,
                          className: 'color-rusty-red font-size-13'},
                  change: {data: 'Customer'},
                  reason: {data: data['remarks'] ? data['remarks'] : ''},
                  timeStamp: {data: data['transactionDate'] ? data['transactionDate'] : '',
                            className: 'preserve-whitespace'},
                  adjustedBy: {data: data['actorEmail'] ? data['actorEmail'] : ''}
                });
              }
              break;
            case 'credit' :
              if (data['title'] === 'customerRefund' || data['title'] === "customerCashBack") {
                this.finalDataRows.push({
                  action: {data: data['originalAmount'] ? 'Refund ₹' + (data['originalAmount']).toFixed(2)  : '' ,
                          className: 'color-kermit-green font-size-13'},
                  change: {data: 'Customer'},
                  reason: {data: data['remarks'] ? data['remarks'] : ''},
                  timeStamp: {data: data['transactionDate'] ? data['transactionDate'] : '',
                            className: 'preserve-whitespace'},
                  adjustedBy: {data: data['actorEmail'] ? data['actorEmail'] : ''}
                });
              }
              break;
            default : // noop
          }
        }
      }
      this.custSorted = true;
      this.mergeHistory();
    } else {
      this.custSorted = true;
      this.mergeHistory();
    }
  }

  sortCaptainAdjustments() {
    if (this.captAdjustments && this.captAdjustments.length > 0) {
        for (const adj in this.captAdjustments) {
          if (this.captAdjustments[adj] && this.captAdjustments[adj]['amount'] != null &&
            this.captAdjTypes.indexOf(this.captAdjustments[adj]['transactionType']) !== -1) {
            const data = this.captAdjustments[adj];
            const debit = data['amount'] < 0;
            const time = data['createdTime'] ? data['createdTime'] : (data['createdOn'] ? data['createdOn'] : '');
            switch (debit) {
              case true:
                if (this.captAdjustments[adj]['transactionType'] === 'penalty') {
                  this.finalDataRows.push({
                    action: {data: 'Debit ₹' + (0 - data['amount']).toFixed(2),
                          className: 'color-rusty-red font-size-13'},
                    change: {data: 'Captain'},
                    reason: {data: data['remarks'] ? data['remarks'] : ''},
                    timeStamp: {data: time ? time : '', className: 'preserve-whitespace'},
                    adjustedBy: {data: data['adjustedBy'] ? data['adjustedBy'] : ''}
                  });
                } else {
                  this.finalDataRows.push({
                    action: {data: 'Adjusted: ' + (data['distance']).toFixed(2) + ' KM\nDebit: ₹' + (data['amount']).toFixed(2),
                          className: 'color-rusty-red font-size-13 whiteSpacePre'},
                    change: {data: 'Captain'},
                    reason: {data: data['remarks'] ? data['remarks'] : ''},
                    timeStamp: {data: time ? time : '', className: 'preserve-whitespace'},
                    adjustedBy: {data: data['adjustedBy'] ? data['adjustedBy'] : ''}
                  });
                }
                break;
              case false:
                if (this.captAdjustments[adj]['transactionType'] === 'penalty' || this.captAdjustments[adj]['transactionType'] === 'adjustment') {
                  this.finalDataRows.push({
                    action: {data: 'Refund ₹' + (data['amount']).toFixed(2),
                          className: 'color-kermit-green font-size-13'},
                    change: {data: 'Captain'},
                    reason: {data: data['remarks'] ? data['remarks'] : ''},
                    timeStamp: {data: time ? time : '', className: 'preserve-whitespace'},
                    adjustedBy: {data: data['adjustedBy'] ? data['adjustedBy'] : ''}
                  });
                } else {
                  this.finalDataRows.push({
                    action: {data: 'Adjusted: ' + (data['distance']).toFixed(2) + ' KM\nRefund: ₹' + (data['amount']).toFixed(2),
                          className: 'color-kermit-green font-size-13 whiteSpacePre'},
                    change: {data: 'Captain'},
                    reason: {data: data['remarks'] ? data['remarks'] : ''},
                    timeStamp: {data: time ? time : '', className: 'preserve-whitespace'},
                    adjustedBy: {data: data['adjustedBy'] ? data['adjustedBy'] : ''}
                  });
                }
                break;
              default: // noop
            }
          }
        }
        this.captSorted = true;
        this.mergeHistory();
    } else {
      this.captSorted = true;
      this.mergeHistory();
    }
  }

  mergeHistory() {
    if (this.custSorted && this.captSorted && this.prevDuesSorted) {
      this.dataRows = [];
      this.dataRows = this.finalDataRows.sort(this.compare);
      if (this.offlineRechargeInfo && Object.keys(this.offlineRechargeInfo).length) {
        const rechargeAdjustment = Object.keys(this.offlineRechargeInfo)
        .reduce((acc, key) => {
          acc[key] = {
            data: this.offlineRechargeInfo[key]
          };
          if (key === 'adjustedBy') {
            acc[key].redirectLink = `captain/${this.offlineRechargeInfo[key]}`;
          }
          return acc;
        }, {});
        this.dataRows.push(rechargeAdjustment);
      }
      for (const log in this.dataRows) {
        if (this.dataRows[log] && this.dataRows[log].timeStamp && this.dataRows[log].timeStamp.data) {
          this.dataRows[log].timeStamp.data = this.getProperDate(this.dataRows[log].timeStamp.data);
        }
      }
      if (this.custSorted && this.captSorted && this.dataRows && this.dataRows.length > 0) {
       this.constructCard();
      } else {
        this.noData = true;
        this.emptyCard();
      }
    }
  }

  emptyCard(message?) {
    if ((this.custEmpty && this.captEmpty) || this.noData) {
      this.tableData = {
        tableHeader: 'Adjustment History',
        headers: null,
        data: null,
        tableHeight: 'max-height-177',
        emptyMessage: message || 'No adjustments found',
        dataFetched: false
      };
    }
  }

  toggleState(status) {
    if (status === 'sl') {
      this.tableData = {...this.tableData, data: this.dataRows};
    } else if (status === 'vm') {
      this.tableData = {...this.tableData, data: [this.dataRows[0]]};
    }
  }

  constructCard() {
    this.tableData = {
      tableHeader: 'Adjustment History',
      headers: {action: 'Action', change: 'Change', reason: 'Reason', timeStamp: 'Timestamp', adjustedBy: 'Adjusted By'},
      data: [this.dataRows[0]],
      expandTable: this.dataRows.length > 1 ? true : false,
      tableHeight: 'max-height-177',
      dataFetched: true,
      title: ['reason', 'adjustedBy']
    };
  }

  compare( a, b ) {
    if ( new Date(a.timeStamp.data) > new Date(b.timeStamp.data) ) {
      return -1;
    }
    if ( new Date(a.timeStamp.data) < new Date(b.timeStamp.data) ) {
      return 1;
    }
    return 0;
  }

  getProperDate(date) {
    if (!date || isNaN(new Date(date).getTime())) {
      return date;
    }
    const timeInfo = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
    const today = new Date(date);
    let dd: any = today.getDate();
    const mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear().toString().substr(-2);

    if (dd < 10) {
      dd = '0' + dd;
    }
    const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
    return (dateInfo + '\n' + timeInfo);
  }

  ngOnInit() {
  }

}
