import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MerchantService } from '../merchant.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
@Component({
  selector: 'app-merchant-wallet',
  templateUrl: './merchant-wallet.component.html',
  styleUrls: ['./merchant-wallet.component.css']
})
export class MerchantWalletComponent implements OnInit, OnChanges {
  @Input() data;
  info = {};
  merchantId;
  user;
  constructor(
    private merchantService: MerchantService,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
    this.getWalletInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getWalletInfo();
  }

  getWalletInfo() {
    if (this.data && this.data.userId && this.data._id && (this.data.userId !== this.user) && (this.data._id !== this.merchantId)) {
      this.merchantId = this.data._id;
      this.user = this.data.userId;
      this.merchantService.getMerchantWallets(this.user, this.merchantId).subscribe(result => {
        if (result && result['info'] && result['info'].status === 'SUCCESS') {
          let walletBalance = null;
          if (result['response']) {
            for (const wallet of result['response']) {
              if (wallet.type === 'sme' || wallet.type === 'rapido') {
                walletBalance = (wallet.balance).toString();
              }
            }
          }
          this.info = {
            header: 'Wallets',
            reload: true,
            dataFetched: true,
            data: [
              {header: 'Wallet balance', value: `₹ ${parseInt(walletBalance, 10).toFixed(2)}` || 'N/A'},
              {header: 'PayTM', value: 'N/A'},
              {header: 'Freecharge', value: 'N/A'},
              {header: 'Customer Due', value: result['outstandingBalance'].toString() || ''},
              {header: 'Simpl', value: 'N/A'},
              {header: 'Lazypay', value: 'N/A'}
            ]
          };
        } else {
          this.info = {
            header: 'Wallets',
            dataFetched: false,
            data: []
          };
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Failed to load merchant wallets`,
          }));
        }
      }, error => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Failed to load merchant wallets`,
        }));
      });
    }
  }

}
