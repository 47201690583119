import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(auth, router, route) {
        this.auth = auth;
        this.router = router;
        this.route = route;
    }
    AuthGuardService.prototype.canActivate = function () {
        var url = HelperService.getCurrentPath();
        if (!this.auth.isAuthenticated()) {
            this.auth.redirectUrl = url;
            HelperService.redirect(environment.oauth);
            // this.router.navigate(['login']);
            return false;
        }
        return true;
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AuthService), i0.inject(i2.Router), i0.inject(i2.ActivatedRoute)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
