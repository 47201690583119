import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FullViewMapService {
  dataReceived = false;
  showMap = false;
  mapData: any;

  toggleFullMap(data?) {
    this.showMap = !this.showMap;
    if (data && this.showMap) {
      this.mapData = data;
      this.dataReceived = true;
    } else {
      this.dataReceived = false;
    }
  }
}
