<head>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>
<h1 mat-dialog-title class="dialog-title margin-0">{{data.title}}</h1>

<div *ngFor="let component of data.components">
    <div *ngIf="component.type === 'INPUT'">
        <input class="form-control" [(ngModel)]="inputText" placeholder="{{ component.placeholder }}">
    </div>
    
    <div mat-dialog-content  *ngIf="component.type === 'CHIP'" class="{{ component.class.chipDialog }}">
          <div *ngFor="let selector of component.data.chipData | filter:inputText" [ngClass]="data.className">
              <span class="user-selector"> 
                  {{ selector.name || '-' }}
                  <i  *ngIf="component.removeAccess" class="material-icons user-selector-close"
                      (click)="removeRiderFromUserSelector(selector)">
                    close
                  </i>
              </span>
          </div>
    </div>
    <div mat-dialog-content  *ngIf="component.type === 'CHIPS'" class="{{ component.class.chipDialog }}">
        <div *ngFor="let selector of component.data.chipData | filter:inputText" [ngClass]="data.className">
            <span class="user-selector"> 
                {{ selector || '-' }}
            </span>
        </div>
  </div>
</div>

<div mat-dialog-actions class="button-flex">
    <button class="dialog-cancel" (click)="onNoClick()">{{ data.cancel }}</button>
    <button class="proceed font-size-13 font-weight-600" (click)="onNoClick()">{{ data.proceed }}</button>
</div>