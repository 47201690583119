<div class="row side-padding-15 is-flex">
	<div class="pull-right">
		<div class="form-group has-feedback is-flex">
			<div class="form-group has-feedback">
				<input [(ngModel)]="mobile" (keyup.enter)="searchMobileNumber()"
					onblur="this.placeholder = 'Search by Manager number'"
					onfocus="this.placeholder = ''" (ngModelChange)="enteredMobile()" type="text"
					[ngClass]="{'padding-left-30':focus===false && !enteredNumber}"
					(focusout)="focus=false" (focus)="focus=true"
					placeholder="Search by Manager number" class="form-control width-250"
					id="inputSuccess2" />
				<span
					[ngClass]="{'glyphicon glyphicon-search form-control-feedback': focus===false && !enteredNumber}"></span>
			</div>
			<label class="search-button" (click)="searchMobileNumber()">Go</label>
		</div>
	</div>
</div>
<router-outlet></router-outlet>