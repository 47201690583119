import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rating-details',
  templateUrl: './rating-details.component.html',
  styleUrls: ['./rating-details.component.css']
})
export class RatingDetailsComponent implements OnInit, OnChanges {

  customerDataRows = [];
  captainDataRows = [];
  captainTableData: any;
  customerTableData: any;
  feedback: any;
  @Input() ratingDetails: any;
  unavailableStatuses = [
    'new', 'onTheWay', 'arrived', 'started', 
    'customerCancelled', 'riderCancelled', 
    'expired', 'aborted'
  ];
  dataLoaded = false;
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.ratingDetails && changes.ratingDetails.currentValue) {
      if (!changes.ratingDetails.currentValue.dataFetched || !changes.ratingDetails.currentValue.feedback) {
        this.emptyCard();
      } else if (changes.ratingDetails.currentValue.status &&
              this.unavailableStatuses.indexOf(changes.ratingDetails.currentValue.status) !== -1) {
        this.emptyCard('Not available for the current order status');
      } else {
        this.constructCard();
      }
    }
  }

  emptyCard(message?) {
    this.dataLoaded = true;
    this.customerTableData = {
      tableHeader: 'Customer Ratings',
      dataFetched: false,
      emptyMessage: message || 'No ratings found'
    };

    this.captainTableData = {
      tableHeader: 'Captain Ratings',
      dataFetched: false,
      emptyMessage: message || 'No ratings found'
    };
  }

  constructCard() {
    this.feedback = this.ratingDetails.feedback;
    if (this.feedback) {
      const custFeedback = this.feedback.customer || {};
      const captFeedback = this.feedback.captain || {};
      this.customerDataRows = [
        {
          starRating: {data: custFeedback.rating || ''},
          tagsSelected: {data: custFeedback.reviews ? custFeedback.reviews.join(', ') : ''},
          description: {data: custFeedback.others || ''}
        }
      ];

      this.captainDataRows = [
        {
          starRating: {data: captFeedback.rating || ''},
          tagsSelected: {data: captFeedback.reviews ? custFeedback.reviews.join(', ') : ''},
          description: {data: captFeedback.others || ''}
        }
      ];

      this.customerTableData = {
        tableHeader: 'Rating By Customer',
        headers: {starRating: 'Star Rating', tagsSelected: 'Tags Selected', description: 'Description'},
        data: this.customerDataRows,
        title: ['description', 'tagsSelected'],
        dataFetched: true
      };

      this.captainTableData = {
        tableHeader: 'Rating By Captain',
        headers: {starRating: 'Star Rating', tagsSelected: 'Tags Selected', description: 'Description'},
        data: this.captainDataRows,
        title: ['description', 'tagsSelected'],
        dataFetched: true
      };
      this.dataLoaded = true;
    }
  }

}
