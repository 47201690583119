<mat-toolbar>
  <img src="../../assets/Rapido.png" width="30" height="30" class="d-inline-block align-top" alt="" /><span class="rapido-form-field">Rapido</span>

</mat-toolbar>

<div class="main-container">

  <div class="secondary-container shadow-all">
      <div class="alert alert-danger" role="alert" *ngIf="slowInternet">
          Your internet connection is slow.
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="noInternet">
          Your're offline. Check your Internet connection.
        </div>
      <button mat-raised-button (click)="socialSignIn()" color="accent"><span style="padding:10px;">Sign in with Google</span> <mat-icon>https</mat-icon></button>  
  </div>
</div>
