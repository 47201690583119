<mat-toolbar class="nav-toolbar">
    <img *ngIf="isExpanded" class="nav-header-image" src="./assets/img/group-25.png">
    <img *ngIf="!isExpanded" class="nav-header-image-small" src="./assets/img/Rapido.png">
</mat-toolbar>
<mat-list class="primary-pane">
      <!---------Menu option 1---------->
    <mat-list-item class="primary-nav-list-item" [ngClass]="_route.url.indexOf('captain')>0?'menu-item-selected':''" (click)="riderMenu=!riderMenu">
        <mat-icon (click)="gotoCaptain()">two_wheeler</mat-icon> 
        <p *ngIf="isExpanded" class="primary-nav-list-label cursor-pointer">Rider</p>
        <mat-icon class="cursor-pointer" *ngIf="isExpanded && !riderMenu">expand_more</mat-icon>
        <mat-icon class="cursor-pointer" *ngIf="isExpanded && riderMenu">expand_less</mat-icon>
    </mat-list-item>
    <mat-list *ngIf="isExpanded && riderMenu" #riderMenu>
        <mat-list-item  class="primary-nav-list-item cursor-pointer">
            <p class="primary-nav-list-label" (click)="gotoCaptain()">Rider Menu </p> 
        </mat-list-item>
    </mat-list>


    <!---------Menu option 2---------->
      <mat-list-item class="primary-nav-list-item" [ngClass]="_route.url.indexOf('customer')>0?'menu-item-selected':''" (click)="customerMenu=!customerMenu">
        <mat-icon (click)="gotoCustomer()">face</mat-icon> 
        <p *ngIf="isExpanded" class="primary-nav-list-label cursor-pointer">Customer</p>
        <mat-icon class="cursor-pointer" *ngIf="isExpanded && !customerMenu" >expand_more</mat-icon>
        <mat-icon class="cursor-pointer" *ngIf="isExpanded && customerMenu">expand_less</mat-icon>
      </mat-list-item>
      <mat-list *ngIf="isExpanded && customerMenu" #customerMenu>
        <mat-list-item  class="primary-nav-list-item cursor-pointer">
          <p class="primary-nav-list-label" (click)="gotoCustomer()">Customer Menu </p> 
        </mat-list-item>
      </mat-list>


   <!---------Menu option 3---------->
   <mat-list-item class="primary-nav-list-item" [ngClass]="_route.url.indexOf('merchant')>0?'menu-item-selected':''" (click)="merchantMenu=!merchantMenu">
      <mat-icon (click)="gotoMerchant()">store</mat-icon> 
      <p *ngIf="isExpanded" class="primary-nav-list-label cursor-pointer">Merchant</p>
      <mat-icon class="cursor-pointer" *ngIf="isExpanded && !merchantMenu">expand_more</mat-icon>
      <mat-icon class="cursor-pointer" *ngIf="isExpanded && merchantMenu">expand_less</mat-icon>
    </mat-list-item>
    <mat-list *ngIf="isExpanded && merchantMenu" #merchantMenu>
        <mat-list-item  class="primary-nav-list-item cursor-pointer">
            <p class="primary-nav-list-label" (click)="gotoMerchant()">Merchant Menu </p> 
        </mat-list-item>
    </mat-list>
  <!-- </mat-list> -->

<!---------Menu option 4---------->
  <mat-list-item class="primary-nav-list-item" [ngClass]="_route.url.indexOf('merchant')>0?'menu-item-selected':''" (click)="orderMenu=!orderMenu">
    <mat-icon (click)="goToOrder()">assessment</mat-icon> 
    <p *ngIf="isExpanded" class="primary-nav-list-label cursor-pointer">Order</p>
    <mat-icon class="cursor-pointer" *ngIf="isExpanded && !orderMenu">expand_more</mat-icon>
    <mat-icon class="cursor-pointer" *ngIf="isExpanded && orderMenu">expand_less</mat-icon>
  </mat-list-item>
  <mat-list *ngIf="isExpanded && orderMenu" #orderMenu>
      <mat-list-item  class="primary-nav-list-item cursor-pointer">
          <p class="primary-nav-list-label" (click)="goToOrder()">Order Menu </p> 
      </mat-list-item>
  </mat-list>

<!---------Menu option 5---------->
  <mat-list-item class="primary-nav-list-item" [ngClass]="_route.url.indexOf('manager')>0?'menu-item-selected':''" (click)="managerMenu=!managerMenu">
    <mat-icon (click)="goToManager()">people icon</mat-icon> 
    <p *ngIf="isExpanded" class="primary-nav-list-label cursor-pointer">Manager</p>
    <mat-icon class="cursor-pointer" *ngIf="isExpanded && !managerMenu">expand_more</mat-icon>
    <mat-icon class="cursor-pointer" *ngIf="isExpanded && managerMenu">expand_less</mat-icon>
  </mat-list-item>
  <mat-list *ngIf="isExpanded && managerMenu" #managerMenu>
      <mat-list-item  class="primary-nav-list-item cursor-pointer">
          <p class="primary-nav-list-label" (click)="goToManager()">Partner Manager Menu </p> 
      </mat-list-item>
  </mat-list>

  <mat-list-item class="primary-nav-list-item" [ngClass]="_route.url.indexOf('demandAreas')>0?'menu-item-selected':''" (click)="demandAreasMenu=!demandAreasMenu">
    <mat-icon (click)="goToDemandAreas()">map</mat-icon>
    <p *ngIf="isExpanded" class="primary-nav-list-label cursor-pointer">Demand Areas</p>
    <mat-icon class="cursor-pointer" *ngIf="isExpanded && !demandAreasMenu">expand_more</mat-icon>
    <mat-icon class="cursor-pointer" *ngIf="isExpanded && demandAreasMenu">expand_less</mat-icon>
  </mat-list-item>
  <mat-list *ngIf="isExpanded && demandAreasMenu" #demandAreasMenu>
    <mat-list-item class="primary-nav-list-item cursor-pointer">
      <p class="primary-nav-list-label" (click)="goToDemandAreas()">Demand Areas</p>
    </mat-list-item>
  </mat-list>
</mat-list>