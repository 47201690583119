import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InvoiceDetailsService } from './invoice-details.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { Router, ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
var InvoiceDetailsComponent = /** @class */ (function () {
    function InvoiceDetailsComponent(router, route, invoiceDetailsService, toasterService, decimalPipe) {
        this.router = router;
        this.route = route;
        this.invoiceDetailsService = invoiceDetailsService;
        this.toasterService = toasterService;
        this.decimalPipe = decimalPipe;
        this.tooltipContent = '';
        this.correlationIdsData = [];
        this.data = {};
        this.numFailed = 0;
        this.failCodes = {
            gpay: ['G001', 'G002'],
            paytm: ['P001', 'P002'],
            rapido: ['R001', 'R002'],
            lazypay: ['L001', 'L002'],
            simpl: ['S001', 'S002'],
            mobikwik: ['M001', 'M002'],
            qrcode: ['QR001'],
            upi: ['UPI001', 'UPI002'],
        };
        this.failedModes = [];
    }
    InvoiceDetailsComponent.prototype.ngOnInit = function () {
    };
    InvoiceDetailsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.correlationIds && changes.correlationIds.currentValue) {
            this.correlationIdsData = changes.correlationIds.currentValue;
        }
        if (changes && ((changes.invoiceDetails && Object.keys(changes.invoiceDetails.currentValue).length > 0) ||
            (changes.rateCard && changes.rateCard.currentValue && Object.keys(changes.rateCard.currentValue).length > 0))) {
            if (changes.invoiceDetails && changes.invoiceDetails.currentValue && !changes.invoiceDetails.currentValue.dataFetched
                && !changes.invoiceDetails.currentValue.data) {
                this.emptyCard();
            }
            else if (changes.invoiceDetails && changes.invoiceDetails.currentValue.deliveryOrder) {
                this.emptyCard('Not available for Delivery orders');
            }
            else {
                this.rateCardData = {
                    header: this.rateCard.city && this.rateCard.city.displayName && this.rateCard.serviceName
                        && this.rateCard.serviceName.name ?
                        (this.rateCard.city.displayName + " - " + this.rateCard.serviceName.name) :
                        (this.rateCard.city ? this.rateCard.city : ''),
                    tableValues: {}
                };
                if (this.rateCard.package) {
                    this.rateCardData.tableValues['Package'] = { data: this.rateCard.package, unit: '' };
                }
                this.rateCardData.tableValues['Base Fare'] = { data: this.rateCard.baseFare
                        ? (this.rateCard.baseFare.amount ? this.rateCard.baseFare.amount : this.rateCard.baseFare) : '', unit: '₹ ' };
                this.rateCardData.tableValues['Minimum Fare'] = { data: this.rateCard.minimumFare
                        ? (this.rateCard.minimumFare.amount ? this.rateCard.minimumFare.amount : this.rateCard.minimumFare) : '', unit: '₹ ' };
                this.rateCardData.tableValues['Insurance'] = { data: this.rateCard.insurance ? this.rateCard.insurance : '', unit: '₹ ' };
                this.rateCardData.tableValues['Per Minute'] = { data: this.rateCard.perMin ? this.rateCard.perMin : '', unit: '₹ ' };
                this.rateCardData.tableValues['Waiting Charges/min'] = { data: this.rateCard.waitTimeConfig ? this.rateCard.waitTimeConfig.amount : '', unit: '₹ ' };
                this.rateCardData.tableValues['Wait Time Threshold(min)'] = { data: this.rateCard.waitTimeConfig ? this.rateCard.waitTimeConfig.threshold : '', unit: '' };
                if (this.rateCard.timeBasedSlab) {
                    this.rateCardData.tableValues[this.rateCard.timeBasedSlab.key] = { data: this.rateCard.timeBasedSlab.value ? this.rateCard.timeBasedSlab.value : '', unit: '' };
                }
                if (this.rateCard.priceWithKm && this.rateCard.priceWithKm.length > 0) {
                    this.rateCard.priceWithKm.forEach(function (element, index) {
                        if (index == 0) {
                            _this.rateCardData.tableValues["Every Km till " + element.kms + " kms"] = { data: element.price, unit: '₹ ' };
                        }
                        else {
                            _this.rateCardData.tableValues["Every km from " + _this.rateCard.priceWithKm[index - 1].kms + " to " + element.kms + " kms"] = { data: element.price, unit: '₹ ' };
                        }
                    });
                }
                else {
                    this.rateCardData.tableValues["Per KM"] = { data: this.rateCard.perKm ? this.rateCard.perKm : '', unit: '₹ ' };
                }
                if (this.rateCard.pickupFare && this.rateCard.pickupFare.length > 0) {
                    this.rateCard.pickupFare.forEach(function (element, index) {
                        if (index == 0) {
                            _this.rateCardData.tableValues["Flat Pick up fare till " + element.kms + " km"] = { data: element.value, unit: '₹ ' };
                        }
                        else {
                            _this.rateCardData.tableValues["Flat Pick up fare from " + _this.rateCard.pickupFare[index - 1].kms + " to " + element.kms + " km"] = { data: element.value, unit: '₹ ' };
                        }
                    });
                }
                this.addCancelChargeInfo();
                this.tooltipData();
                this.constructCard();
            }
        }
    };
    InvoiceDetailsComponent.prototype.addCancelChargeInfo = function () {
        if (this.rateCard.cancelCharge > 0) {
            this.rateCardData.tableValues['Cancel Charge'] = { data: this.rateCard.cancelCharge, unit: '₹ ' };
        }
        if (this.rateCard.threshold > 0) {
            this.rateCardData.tableValues['Threshold (min)'] = { data: this.rateCard.threshold, unit: '' };
        }
    };
    InvoiceDetailsComponent.prototype.emptyCard = function (message) {
        this.info = {
            header: 'Customer fare details',
            rightHeader: 'Invoice Details',
            dataFetched: false,
            data: null,
            emptyMessage: message || 'No data found ¯\_(ツ)_/¯'
        };
    };
    InvoiceDetailsComponent.prototype.tooltipData = function () {
        this.tooltipContent = this.rateCardData.header + "<div class='dashed-border'>" + this.tableData() + '</div>' +
            (this.rateCard.rule ? ('*' + this.rateCard.rule) + "<br>" : '');
    };
    InvoiceDetailsComponent.prototype.getCompTooltip = function (data) {
        var failedWallets = [], usedWallets = [], tableData = '';
        if (data.walletChangeObjects) {
            data.walletChangeObjects.forEach(function (element) {
                if (element && element.name) {
                    var name_1 = element.name;
                    usedWallets.push(name_1.toLowerCase());
                }
            });
        }
        if (data.source && !usedWallets.includes(data.source)) {
            failedWallets.push(data.source);
        }
        var reasonObj = data.walletChangeReason;
        var failedCodes = this.failCodes;
        if (reasonObj && reasonObj.errorCode) {
            Object.keys(this.failCodes).forEach(function (key) {
                if (failedCodes[key].includes(reasonObj.errorCode) && !failedWallets.includes(key)) {
                    failedWallets.push(key);
                }
            });
        }
        if (failedWallets.length > 0) {
            failedWallets.forEach(function (element) {
                tableData += '<div class="left-align">'
                    + element +
                    '<span class="float-right">' + 'Failed' +
                    '</span>\
          </div>';
            });
            return this.getTotalFailedPaymentModes(data) + " payment mode failed" + "<div class='dashed-border'>" + tableData + '</div>';
        }
        return null;
    };
    InvoiceDetailsComponent.prototype.tableData = function () {
        var data = '';
        for (var values in this.rateCardData.tableValues) {
            if (values) {
                var key = values;
                var unit = this.rateCardData.tableValues[values].unit;
                var content = this.rateCardData.tableValues[values].data;
                data += '<div class="left-align">'
                    + key +
                    '<span class="float-right">' + unit + content +
                    '</span>\
                </div>';
            }
        }
        return data;
    };
    InvoiceDetailsComponent.prototype.buttonClicked = function (button) {
        var _this = this;
        if (button === 'Email Invoice') {
            this.invoiceDetailsService.emailInvoice(this.emailInvoice.orderId, this.emailInvoice.userId, this.getDate()).subscribe(function (result) {
                if (result && result['body'] && result['body'].success) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.SUCCESS,
                        message: "Email sent",
                    }));
                }
                else {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "Unable to send email. Please try again.",
                    }));
                }
            });
        }
        else if (button === 'Invoice Details') {
            var orderId = this.route && this.route.snapshot.params.id;
            var orderStatus = this.invoiceDetails.orderStatus || '';
            var customerId = this.invoiceDetails.customerId || '';
            this.router.navigate(["/invoice-details/" + orderId + "/" + orderStatus + "/" + customerId]);
        }
    };
    InvoiceDetailsComponent.prototype.getFinalPaymentMode = function (data) {
        if (data.walletChangeObjects) {
            var walletsArr_1 = [];
            data.walletChangeObjects.forEach(function (element) {
                walletsArr_1.push(element.name);
            });
            if (walletsArr_1.length == 1) {
                return walletsArr_1[0];
            }
            return walletsArr_1.join(' + ');
        }
        else {
            return data.paymentMode ? data.paymentMode : '';
        }
    };
    InvoiceDetailsComponent.prototype.getHasFailedPaymentModes = function (data) {
        var numfailed = this.getTotalFailedPaymentModes(data);
        return numfailed > 0 ? true : false;
    };
    InvoiceDetailsComponent.prototype.getTotalFailedPaymentModes = function (data) {
        var usedWallets = [], failedWallets = [];
        if (data.walletChangeObjects) {
            data.walletChangeObjects.forEach(function (element) {
                if (element && element.name) {
                    var name_2 = element.name;
                    usedWallets.push(name_2.toLowerCase());
                }
            });
        }
        if (data.source && !usedWallets.includes(data.source)) {
            failedWallets.push(data.source);
        }
        if (data.walletChangeReason && Object.values(data.walletChangeReason).length > 0) {
            var reasonObj_1 = data.walletChangeReason;
            var failedCodes_1 = this.failCodes;
            if (reasonObj_1 && reasonObj_1.errorCode) {
                Object.keys(this.failCodes).forEach(function (key) {
                    if (failedCodes_1[key].includes(reasonObj_1.errorCode) && !failedWallets.includes(key)) {
                        failedWallets.push(key);
                    }
                });
            }
        }
        return failedWallets.length;
    };
    InvoiceDetailsComponent.prototype.getInitialPaymentMode = function (data) {
        if (data.initialPaymentMode) {
            return data.initialPaymentMode;
        }
        return this.data.paymentMode ? this.data.paymentMode : '';
    };
    InvoiceDetailsComponent.prototype.shouldShowPaymentStatus = function (str) {
        if (!str)
            return false;
        // ---qrcode logic
        if (str == "qrcode") {
            return true;
        }
        // upi
        str = str.split("_");
        return (str[0] == "upi") ? true : false;
    };
    InvoiceDetailsComponent.prototype.constructCard = function () {
        this.data = this.invoiceDetails;
        var walletName;
        var waitingChargeQuantity = this.data.waitTimeConfig && this.data.waitTimeConfig.quantity || 0;
        this.info = {
            header: 'Customer fare details',
            rightHeader: 'Invoice Details',
            headerClass: 'color-card-header',
            dataFetched: true,
            // button: this.emailInvoice.userId && this.emailInvoice.orderId ? 'Email Invoice' : '',
            customTooltip: this.tooltipContent,
            data: [
                { header: 'Initial Payment mode', informationPopup: this.shouldShowPaymentStatus(this.getInitialPaymentMode(this.data)), headerClass: 'header-card-color', value: this.getInitialPaymentMode(this.data) },
                { header: 'Final Payment mode', informationPopup: this.shouldShowPaymentStatus(this.getFinalPaymentMode(this.data)), headerClass: 'header-card-color', value: this.getFinalPaymentMode(this.data), customTooltip: this.getCompTooltip(this.data), hasFailed: this.getHasFailedPaymentModes(this.data), numFailed: this.getTotalFailedPaymentModes(this.data) },
                { header: 'Amount', inr: true, headerClass: 'header-card-color', value: this.data.amount ? this.data.amount : '' },
                { header: 'SubTotal', inr: true, headerClass: 'header-card-color', value: this.data.subTotal >= 0 ? this.data.subTotal : '' },
                { header: 'Base fare', inr: true, headerClass: 'header-card-color', value: this.data.baseFare ? this.data.baseFare : '' },
                { header: 'Distance fare', inr: true, headerClass: 'header-card-color', value: this.data.distanceFare ? this.data.distanceFare : '' },
                { header: 'Time fare', inr: true, headerClass: 'header-card-color', value: this.data.timeFare ? this.data.timeFare : '' }
            ].concat((this.data.bidDelta ? [{ header: 'Bid Delta', inr: true, headerClass: 'header-card-color', value: this.data.bidDelta }] : []), [
                { header: 'Total Discount', inr: true, headerClass: 'header-card-color', value: this.data.totalDiscount ? this.data.totalDiscount.toString() : '' }
            ], (this.data.perceptionDiscount ? [{ header: this.data.perceptionDiscount.key, inr: true, headerClass: 'header-card-color', value: this.data.perceptionDiscount.total ? this.data.perceptionDiscount.total : '' }] : []), (this.data.discount ? [{ header: 'Offer Discount', inr: true, headerClass: 'header-card-color', value: this.data.discount.total ? this.data.discount.total : '' }] : []), [
                { header: 'Offer Code', headerClass: 'header-card-color', value: this.data.offer && this.data.offer.code ? this.data.offer.code : '',
                    information: this.data.offer && this.data.offer.desc ? this.data.offer.desc : '' }
            ], (this.data.timeBasedCharges ? [{ header: this.data.timeBasedCharges.key, inr: true, headerClass: 'header-card-color', value: this.data.timeBasedCharges.total ? this.data.timeBasedCharges.total : '' }] : []), [
                { header: 'Pickup Fare', inr: true, headerClass: 'header-card-color', value: this.data.pickupFare ? this.data.pickupFare : '' },
                { header: 'Dynamic Surge', inr: true, headerClass: 'header-card-color', value: this.data.dynamicSurge ? this.data.dynamicSurge : '' },
                { header: 'Static Surge', inr: true, headerClass: 'header-card-color', value: this.data.staticSurge ? this.data.staticSurge : '' },
                { header: "Waiting Charges (" + waitingChargeQuantity + " min)", inr: true, headerClass: 'header-card-color', value: this.data.waitTimeConfig ? this.data.waitTimeConfig.total : 0 },
                { header: 'Dynamic Fare', inr: true, headerClass: 'header-card-color', value: this.data.dynamicFare ? this.data.dynamicFare : '' },
                { header: 'Tip', inr: true, headerClass: 'header-card-color', value: this.data.tip ? this.data.tip : '' },
                { header: 'Toll Fare', inr: true, headerClass: 'header-card-color', value: this.data.tollFare ? this.data.tollFare : '' },
                { header: 'Parking Charges', inr: true, headerClass: 'header-card-color', value: this.data.parkingCharges ? this.data.parkingCharges : '' },
                { header: 'Insurance', inr: true, headerClass: 'header-card-color', value: this.data.insurance ? this.data.insurance : '' },
                { header: 'Cashback', inr: true, headerClass: 'header-card-color', value: this.data.cashback >= 0 ? this.data.cashback : '' },
                { header: 'Cash (To be collected)', inr: true, headerClass: 'header-card-color', value: this.data.cashToBeCollected >= 0 ? this.decimalPipe.transform(this.data.cashToBeCollected, '1.0-2') : '' },
                { header: 'Cancel Fee', inr: true, headerClass: 'header-card-color', value: this.data.cancelFee ? this.data.cancelFee : '' },
                { header: 'Previous Due', inr: true, headerClass: 'header-card-color', value: this.data.prevDue ? this.data.prevDue : '' },
            ])
        };
        if (this.data && this.data.collected && Object.keys(this.data.collected).length > 0) {
            var nonZeroWallets = Object.keys(this.data.collected);
            for (var wallet in nonZeroWallets) {
                if (nonZeroWallets[wallet]) {
                    walletName = nonZeroWallets[wallet];
                    this.info['data'].push({ header: walletName, inr: true, headerClass: 'header-card-color text-transform-capitalize',
                        value: this.data.collected[walletName] ? this.decimalPipe.transform(this.data.collected[walletName], '1.0-2') : '' });
                }
            }
        }
    };
    InvoiceDetailsComponent.prototype.getToasterService = function () {
        return this.toasterService;
    };
    InvoiceDetailsComponent.prototype.getDate = function () {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var dateInfo = yyyy + '-' + mm + '-' + dd;
        return (dateInfo);
    };
    return InvoiceDetailsComponent;
}());
export { InvoiceDetailsComponent };
