export const REFUND = 'Refund';
export const DEBIT = 'Debit';
export const UPDATE_KM = 'Update KM';
export const RIDER = 'Rider';
export const CUSTOMER = 'Customer';
export const REASSIGN = 'Reassign';
export const CAPTAIN_REFUND_ACCESS = {
    'Team Leader' : 250,
    'Super Admin' : 10000,
    'developer' : 10000,
    'Test - Supply Dashboard' : 10000,
    'Captain care centre agents' : 200,
    'Tech Support' : 10000,
    'ProductManager' : 10000,
    'ERT Team' : 1000,
    'CCC Admin' : 300,
    'CS - Ops Follow Up' : 500,
    'CSD - Finance': 10000,
    'City Manager': 250,
    'ATL': 250,
    'Tier 2 Deputy Manager': 250,
    'Tier 2 DM& TL': 250,
    'BizOps': 250,
    'Assistant Manager - Ops': 250,
    'B2B': 1000,
    'TLList': 250
  };
  
  export const CAPTAIN_DEBIT_ACCESS = {
    'Team Leader' : 250,
    'Super Admin' : 10000,
    'developer' : 10000,
    'Test - Supply Dashboard' : 10000,
    'Captain care centre agents' : 200,
    'Tech Support' : 10000,
    'Risk Analyst' : 10000,
    'ProductManager' : 10000,
    'ERT Team' : 1000,
    'CCC Admin' : 300,
    'CS - Ops Follow Up' : 500,
    'CSD - Finance': 10000,
    'City Manager': 250,
    'ATL': 250,
    'Tier 2 Deputy Manager': 250,
    'Tier 2 DM& TL': 250,
    'BizOps': 250,
    'Assistant Manager - Ops': 250,
    'B2B': 1000,
    'TLList': 250
  };
  
  export const CUSTOMER_REFUND_ACCESS = {
    'Super Admin' : 10000,
    'Customer Support' : 50,
    'developer' : 10000,
    'Test - Supply Dashboard' : 10000,
    'Tech Support' : 10000,
    'ProductManager' : 10000,
    'ERT Team' : 1000,
    'CS Manager' : 1000,
    'Customer Support ATL' : 500,
    'Customer Support TL' : 1000,
    'Senior CS agents' : 200,
    'CSD - Finance': 10000,
    'CCC Admin' : 500
  };
  
  export const CUSTOMER_DEBIT_ACCESS = {
    'Super Admin' : 10000,
    'Customer Support' : 50,
    'developer' : 10000,
    'Test - Supply Dashboard' : 10000,
    'Tech Support' : 10000,
    'ProductManager' : 10000,
    'ERT Team' : 1000,
    'CS Manager' : 1000,
    'Customer Support ATL' : 500,
    'Customer Support TL' : 1000,
    'Senior CS agents' : 200,
    'CSD - Finance': 10000,
    'CCC Admin' : 500,
    'Captain care centre agents': 200
  };

export const CAPTAIN_UPDATE_KM = {
    'Team Leader' : 1,
    'Team leader' : 1,
    'Super Admin' : 100,
    'developer' : 100,
    'Test - Supply Dashboard' : 100,
    'Tech Support' : 10000,
    'ProductManager' : 100,
    'City Manager' : 1,
    'ATL' : 1,
    'Tier 2 Deputy Manager' : 1,
    'Tier 2 DM& TL' : 1,
    'Captain care centre agents' : 5,
    'BizOps' : 1,
    'Assistant Manager - Ops' : 1,
    'B2B' : 1,
    'TLList' : 1,
    'CCC Admin' : 15,
    'Supply Agents head': 15
};

export const CAPTAIN_REASSIGN = [
    'Team Leader',
    'Team leader',
    'Super Admin',
    'City Manager',
    'ATL',
    'developer',
    'Test - Supply Dashboard',
    'Tier 2 Deputy Manager',
    'Tier 2 DM& TL',
    'Captain care centre agents',
    'Tech Support',
    'BizOps',
    'ProductManager',
    'Assistant Manager - Ops',
    'B2B',
    'TLList',
    'ERT Team',
    'CCC Admin',
    'Customer Support ATL',
    'Customer Support TL'
];

export const CAPTAIN_ABORT = [
    'Team Leader',
    'Team leader',
    'Super Admin',
    'Customer Support',
    'City Manager',
    'developer',
    'Test - Supply Dashboard',
    'Tier 2 Deputy Manager',
    'Tier 2 DM& TL',
    'ATL',
    'Tech Support',
    'BizOps',
    'Risk Analyst',
    'ProductManager',
    'Assistant Manager - Ops',
    'B2B',
    'TLList',
    'ERT Team',
    'CS Manager',
    'CCC Admin',
    'Captain care centre agents',
    'Customer Support ATL',
    'Customer Support TL'
];

export const CUSTOMER_ACTIVITY_HISTORY = [
    'Team Leader',
    'Team leader',
    'Super Admin',
    'Customer Support',
    'City Manager',
    'ATL',
    'developer',
    'Test - Supply Dashboard',
    'Tier 2 Deputy Manager',
    'Tier 2 DM& TL',
    'Captain care centre agents',
    'Tech Support',
    'BizOps',
    'Risk Analyst',
    'ProductManager',
    'Assistant Manager - Ops',
    'B2B',
    'TLList',
    'ERT Team',
    'CS Manager',
    'CCC Admin',
    'Customer Support ATL',
    'Customer Support TL',
];

export const REMOVE_CROSS_UTILIZATION = [
    'Super Admin',
    'developer',
    'B2B'
];
