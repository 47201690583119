import { Component, OnInit, Input } from '@angular/core';
import { Pagination } from '../types';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() pagination: Pagination;
  activePage = 1;
  visiblePages = [];
  constructor() { }

  ngOnInit() {
  }
  
}
