import { Component, OnInit, Input } from '@angular/core';
import { Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { RecordTable } from 'src/app/shared/types/record-table.types';
import { CaptainService } from 'src/app/captain.service';
import { HelperService } from 'src/app/helper.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { CustomDialogComponent } from '../../custom-dialog/custom-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { REMOVE_CROSS_UTILIZATION } from '../../roles.constants';
import { FOOD, COMPLETE_STATUS, ACTIVE_STATUS, PROCESSING_STATUS, PaymentStatus, PARCEL, OTHERS } from '../../shared/constants';

@Component({
  selector: 'app-captain-rapido-account',
  templateUrl: './captain-rapido-account.component.html',
  styleUrls: ['./captain-rapido-account.component.css']
})
export class CaptainRapidoAccountComponent implements OnInit {
  @Input() captainId: string;
  riderId: any;
  rapidoAccountInfoTable: Table;
  userSelectorListOfRider = [];
  captainDetails: any;
  inactiveServices = [];
  suspendedServices = [];
  requestedServices = [];
  rapidoPayStatus = true;
  assureFees: number;
  isAssurePaymentCompleted = false;
  isAssureTrainingCompleted = true;
  assureSubService = "";
  assureService = "";
  assureAddOns = [];
  constructor(
    private captainService: CaptainService,
    private toasterService: ToasterService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    const withEarnings = false;
    const withReferralDetails = false;
    this.captainService.captainDetails(this.captainId, null, null, null, withEarnings, withReferralDetails).subscribe(details => {

      this.riderId = details && details.userId || '';
      this.captainDetails = details;

      this.captainService.getRapidoPayBlockedUser(this.riderId).subscribe(data => {
        if (data['info']['data'].length > 0) {
          this.rapidoPayStatus = true;
        } else {
          this.rapidoPayStatus = false;
        }
      })

      this.captainService.getUserServices(this.riderId).subscribe((data: any) => {
        this.appendAssureInfo(data);
        this.captainService.getUserSelectorsOfRider(this.riderId).subscribe(userSelector => {
          let selectors = [];
          if (userSelector && userSelector['data']) {
            selectors = userSelector['data']['selectors'];
          }

          for (let index = 0; index < selectors.length; index++) {
            if (selectors[index].selectorFor === 'crossUtilization') {
              this.userSelectorListOfRider.push(selectors[index]);
            }
          }

          if (data && data.inactiveServices && data.inactiveServices.length > 0) {
            this.inactiveServices = data.inactiveServices;
            this.generateRapidoAccountTable();
          }
          if (data && data.suspendedServices && data.suspendedServices.length > 0) {
            this.suspendedServices = data.suspendedServices;
            this.generateRapidoAccountTable();
          }
          if (data && data.requestedServices && data.requestedServices.length > 0) {
            this.requestedServices = data.requestedServices;
            this.generateRapidoAccountTable();
          }
          this.generateRapidoAccountTable();

        }, err => {
          console.error('Error when fetching user selectors for cross utilization of rider: ', err);
        });
      })

    });
  }

  appendAssureInfo(captainUserPreference) {
    try {
      let paymentStatus = "", joiningFeeInfo = {};
      const serviceTypeProgress = captainUserPreference && captainUserPreference.serviceOnboardingConfig && captainUserPreference.serviceOnboardingConfig.serviceTypeProgress;
      for (const serviceType in serviceTypeProgress) {
        const serviceObj = serviceTypeProgress[serviceType];
        for (const subService in serviceObj) {
          joiningFeeInfo = serviceObj[subService].joiningFeeInfo;
          const transactionProgress = joiningFeeInfo['transactionProgress'] || [];
          paymentStatus = transactionProgress.length > 0 ? transactionProgress[transactionProgress.length - 1] && transactionProgress[transactionProgress.length - 1].status : "";
          if (paymentStatus == PaymentStatus.COMPLETED) {
            this.assureSubService = subService;
            this.isAssurePaymentCompleted = true;
            break;
          }
        }
        if (this.assureSubService) {
          this.assureAddOns = serviceObj[this.assureSubService]['addOns'] && serviceObj[this.assureSubService]['addOns']['subServicesSelected'];
          break;
        }

      }
      
      this.assureService = this.assureSubService == OTHERS ? PARCEL : this.assureSubService;
      this.assureAddOns = this.assureAddOns && this.assureAddOns.map(item => item == OTHERS ? PARCEL : item);
      this.assureFees = this.calulateFinalAmount(parseInt(joiningFeeInfo['baseAmount']), parseInt(joiningFeeInfo['discountPercentage']));
      const services = captainUserPreference.services;
      const assureService = services.find((service) => service.subServiceType == this.assureSubService);
      if (assureService && assureService.status == PROCESSING_STATUS && assureService.trainingStatus != COMPLETE_STATUS) {
        this.isAssureTrainingCompleted = false;
      }
      this.generateRapidoAccountTable();
    } catch (error) {
      console.log("appendAssureInfo error occured ", error)
    }

  }
  calulateFinalAmount(base, discount) {
    return (base - (base * discount) / 100);
  }

  generateRapidoAccountTable() {
    const registrationDate = new Date(this.captainDetails.registeredOn);
    const activatedOn = new Date(this.captainDetails.activatedDate);

    let mapping = {
      'Assure Service': this.isAssurePaymentCompleted ? this.assureService.toUpperCase() + ' - ₹ ' + this.assureFees : 'N/A',
      'Training Complete': this.isAssurePaymentCompleted ? (this.isAssureTrainingCompleted ? 'YES' : 'NO') : 'N/A',
      'Add Ons' : this.assureAddOns,
      'Current City': this.captainDetails.city.displayName,
      'Registered City': this.captainDetails.registeredCity,
      'Service': this.captainDetails.service.split(', '),
      'Inactive Services': this.inactiveServices,
      'Suspended Services': this.suspendedServices,
      'Requested Services': this.requestedServices,
      'Shift': this.captainDetails.shift,
      'Registered On': HelperService.dateString(registrationDate),
      'Activated On': HelperService.dateString(activatedOn),
      'Rapido Pay': this.rapidoPayStatus ? 'Active' : 'Deactivated'
    };
    const typeMapping = {
      'Assure Service': TableDataTypes.DATA,
      'Training Complete': TableDataTypes.DATA,
      'Add Ons' : TableDataTypes.DATA,
      'Current City': TableDataTypes.DATA,
      'Registered City': TableDataTypes.DATA,
      'Service': TableDataTypes.CHIPS,
      'Inactive Services': TableDataTypes.CHIPS,
      'Suspended Services': TableDataTypes.CHIPS,
      'Requested Services': TableDataTypes.CHIPS,
      'Shift': TableDataTypes.DATA,
      'Registered On': TableDataTypes.DATA,
      'Activated On': TableDataTypes.DATA,
      'Rapido Pay': TableDataTypes.DATA,
    };

    let classMapping = this.formClassForRapidoAccount();
    if(!this.assureAddOns || this.assureAddOns && this.assureAddOns.length < 2){
      delete mapping['Add Ons'];
    }
    this.rapidoAccountInfoTable = this.createMapTable(mapping, 'Rapido account',
      typeMapping, classMapping);
  }

  checkAccessForCrossUtilization() {
    const roles = this.accessCookie('Roles').split(',');

    if ((roles.some(roleValid => REMOVE_CROSS_UTILIZATION.indexOf(roleValid) >= 0))) {
      return true;
    }

    return false;
  }

  formClassForRapidoAccount() {
    const styleMapping = {
      'Assure Service': 'text-right height-52px',
      'Training Complete': 'text-right height-52px',
      'Add Ons' : 'text-right height-52px',
      'Current City': 'text-right height-52px',
      'Registered On': 'text-right height-52px',
      'Activated On': 'text-right height-52px',
      'Registered City': 'text-right height-52px',
      'Shift': 'text-right height-52px',
      'Service': 'text-right height-52px user-selector padding-top-6',
      //'Rapido Pay': 'inactive-lable'

      //[CAPTAIN_STATUS.ACTIVE]: 'active-lable',
      //[CAPTAIN_STATUS.IN_ACTIVE]: 'inactive-lable'
    };

    return styleMapping;
  }

  private createMapTable(mapping, tableHeader, typeMapping, classMapping) {
    const cols = [];
    Object.keys(mapping).map(header => {
      cols.push(
        new TableData({
          data: header,
          type: TableDataTypes.DATA,
          className: 'height-52px',
          style: {
            'padding-top': '4%',
          }
        }),
        new TableData({
          data: mapping[header],
          type: typeMapping[header],
          className: classMapping[header],
          onClick: userSelector => {
            this.confirmToRemoveRiderFomUserSelector(userSelector);
          },
          style: {
            'padding-top': '4%',
          },
          spanClassName: this.spanClassFn(header),
        })
      );
    });
    const recordTable = new RecordTable({
      data: cols,
      header: {
        toString() {
          return tableHeader;
        },
        style() {
          return {
            'color': '#9aa0ac',
            'line-height': '26px',
            'font-size': '16px',
          };
        }
      },
      colCount: 2,
      style: {
        'height': '324px',
        'overflow-x': 'scroll',
      },
    });
    return recordTable.table;
  }

  spanClassFn(header) {
    if (header == 'Rapido Pay') {
      return this.rapidoPayStatus ? 'active-lable-rapido-pay' : 'inactive-lable-rapido-pay';
    } else {
      return ''
    }
  }

  // removes captain from user selector.
  removeRiderFromUserSelector(userSelector) {
    const selectorId = userSelector._id;

    if (!selectorId) {
      console.error(`Selector ID empty!`);
      return;
    }

    const requestBody = { riderId: this.riderId, selectorId: selectorId };

    this.captainService.removeRiderFromUserSelector(requestBody).subscribe(response => {
      if (response && !response['data']) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Could not remove rider from user selector',
        }));
        console.error('Could not remove rider from user selector: ', response);
      } else {

        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: 'Rider is successfully removed from the user selector',
        }));

        for (let index = 0; index < this.userSelectorListOfRider.length; index++) {
          if (this.userSelectorListOfRider[index]._id === selectorId) {
            this.userSelectorListOfRider.splice(index, 1);
            return;
          }
        }
        this.generateRapidoAccountTable();
      }
    }, err => {
      console.error('Error when fetching user selectors for cross utilization of rider: ', err);
    });
  }

  // show user selectors in a dialog box that rider is part of
  showCUSelectors() {
    const chipData = { chipData: this.userSelectorListOfRider };
    const chipComponent = {
      type: 'CHIP',
      data: chipData,
      removeAccess: this.checkAccessForCrossUtilization(),
      class: { 'chipDialog': 'height-120 overflow-y' }
    };

    const inputComponent = {
      type: 'INPUT',
      placeholder: 'Search user selector'
    };

    const componentsForDialog = [inputComponent, chipComponent];

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '380px',
      data: {
        title: 'User selectors the rider is part of',
        components: componentsForDialog,
        cancel: 'Cancel',
        proceed: 'Ok',
        className: 'margin-top-12 margin-bottom-4'
      }
    });

    dialogRef.afterClosed().subscribe(removeSelector => {
      if (removeSelector) { this.confirmToRemoveRiderFomUserSelector(removeSelector); }
    });
  }

  showChips(chipInfo) {

    const chipData = { chipData: chipInfo.data };

    const chipComponent = {
      type: 'CHIPS',
      data: chipData,
      class: { 'chipDialog': 'height-120 overflow-y' }
    };

    const componentsForDialog = [chipComponent];

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '380px',
      data: {
        title: 'Other ',
        components: componentsForDialog,
        cancel: 'Cancel',
        proceed: 'Ok',
        className: 'margin-top-12 margin-bottom-4'
      }
    });

    dialogRef.afterClosed().subscribe(removeSelector => {
      if (removeSelector) { this.confirmToRemoveRiderFomUserSelector(removeSelector); }
    });
  }

  // confirm from user to remove captain from CU user selector
  confirmToRemoveRiderFomUserSelector(userSelector) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '412px',
      data: {
        title: 'Are you sure you want to remove captain from  ' + userSelector.name + ' ?',
        reason: 'abort',
        message: 'By removing, captain will not be part of user selector',
        buttonText: 'Yes, remove'
      }
    });

    confirmDialogRef.afterClosed().subscribe(confirmResult => {

      if (confirmResult) {
        this.removeRiderFromUserSelector(userSelector);
      }
    });
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }


}
