import { MyHttpClient } from '../http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var FareEstimateLogService = /** @class */ (function () {
    function FareEstimateLogService(http) {
        this.http = http;
        this.server = environment.server;
        this.offerDetailsUrl = this.server + '/api/offer';
    }
    FareEstimateLogService.prototype.fetchLocationOffer = function (offerId) {
        var url = this.offerDetailsUrl + "/location/" + offerId;
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    FareEstimateLogService.prototype.fetchRideOffer = function (offerId) {
        var url = this.offerDetailsUrl + "/ride/" + offerId;
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    FareEstimateLogService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    FareEstimateLogService.ngInjectableDef = i0.defineInjectable({ factory: function FareEstimateLogService_Factory() { return new FareEstimateLogService(i0.inject(i1.MyHttpClient)); }, token: FareEstimateLogService, providedIn: "root" });
    return FareEstimateLogService;
}());
export { FareEstimateLogService };
