import { Observable } from 'rxjs/Observable';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
    //   withCredentials: true,
      headers:  new HttpHeaders({
        'Authorization': `Bearer ${HelperService.accessCookie('token')}`
       }),
    });
  return next.handle(request);
  }
}