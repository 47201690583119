import { environment } from 'src/environments/environment';

export const STRING = Object.freeze({
    FUNCTION: 'function',
});

export const FRAUDTYPES = {
    contactFraud: 'Contact Fraud',
    distanceFraud: 'Distance Fraud',
    cancellationMoneyFraud: 'Cancellation Money Fraud',
    captainDeviceFraud: 'Captain Device Fraud',
    customerDeviceFraud: 'Customer Device Fraud',
    firstRideFraud:'First Ride Fraud',
    discountFraud:'Discount Fraud',
    referralFraud: 'Referral Fraud',
    tippingFraud: 'Tipping Fraud',
    adjustmentFraud: 'Adjustment Fraud',
    disintermediationFraud: 'Disintermediation Fraud',
    gpsSpoofingFraud: 'GPS Spoofing Fraud',
    groupFraud: 'Group Fraud',
    spdFraud: 'SPD Fraud',
    speedFraud: 'Over Speed',
    weekFraud: 'Week Fraud',
    dayFraud: 'Day Fraud',
    distanceToAcceptFraud: 'Distance To Accept Fraud',
    checkPickupLocationAndDeviceFraud: 'Check Pickup Location And Device Fraud',
    differentActualPickUpFraud: 'Different Actual Pick Up Fraud',
    shortRideFraud: 'Short Ride Fraud',
    differentDropLocationFraud: 'Different Drop Location Fraud',
    captainMaxRidesPerDayFraud: 'RPD Fraud',
    captainMaxRidesPerHourFraud: 'RPH Fraud',
    pickUpAndDropHexFraud: 'PickUpDrop Hex Fraud'
};

export const FRAUDTAGS = [
    'Different Set Pickup Location',
    'Low Accept to Pickup Distance',
    'Cancellations Before Order',
    'C-C Pair',
    'Very Long Ride',
    'Very Short Ride',
    'Unusual Tip',
    'Same Captain-Customer Device',
    'Different  Captain Device at Pickup/ Drop',
    'Same Device - Multiple Customers',
    'Unusual Adjustment',
    'BacktoBack Ride',
    'Spoofed Ride',
    'Cancellations Fraud'
]

export const FRAUDCAUSES = [
    'Incentives',
    'Discounts',
    'Referral Bonus',
    'Joining Bonus',
    'Adjustments',
    'Cancellation Charge'
]

export const SERVICE_GROUPS = [
    'App',
    'Rental', 
    'Food', 
    'Grocery',
    'Auto',
    'Alcohol',
    'Premium',
    'cab_economy',
    'cab_premium',
    'Others',
    'e_rickshaw',
    'auto_lite'
];

export const NON_P0_SERVICE_GROUPS = [
    'Rental', 
    'Food', 
    'Grocery',
    'Alcohol',
    'Premium'
];

export const ataEndTypesAllowed = ['arrived', 'customer_cancelled', 'captain_cancelled', 'aborted', 'expired']; // accept to arrive end types allowed
export const arEndTypesAllowed = ['dropped', 'aborted', 'expired', 'customer_cancelled', 'captain_cancelled', 'reached']; // actual route end types allowed

export const DASHBOARD_SOURCE = 'profiles-dashboard';

export const ROLE_RIDER = 'rider';

export const SUSPENDED_STATUS = 'suspended';

export const ACTIVE_STATUS = 'active';

export const FOOD = 'food';

export const COMPLETE_STATUS = 'complete';

export const PROCESSING_STATUS = 'processing';

export const PARCEL = 'parcel';

export const OTHERS = 'others';

export const FRESH_DESK_TICKETS_URL = environment.FRESH_DESK_TICKETS_URL
export const CAPTAIN_ISSUES_SUB_REASONS = environment.CAPTAIN_ISSUES_SUB_REASONS
export const CAPTAIN_AUTO_EARNINGS_CITY_CONFIG = environment.autoEarningsCityConfig
export const CAPTAIN_TICKET_DISPOSITIONS = environment.CAPTAIN_TICKET_DISPOSITIONS

export const TICKET_STATUS_MAPPING = {
    2: 'Open',
    3: 'Pending',
    4: 'Resolved',
    5: 'Closed'
}

export const TAGS = {
    CUSTOMER_TAG: 'customer-app',
    CAPTAIN_TAG: 'captain_ticket'
};

export const USER_TYPES = {
    CUSTOMER: 'Customer',
    CAPTAIN: 'Captain'
};

export const REVOKE = {
    PENALTY_ACTION: 'penalty',
    PENALTY_PERMISSION: 'revoke_penalty',
    SUSPEND_ACTION: 'suspend',
    SUSPEND_PERMISSION: 'revoke_suspension',
    P0_SUSPEND_REVOKE_PERMISSION: 'revoke_p0_suspension',
    P0_SUSPEND_PERMISSION: 'allow_p0_suspension'
}

export const BLACKLIST = {
    REVOKE_BLACKLIST: 'revoke_blacklist'
}

export const ORDERS_QOS = { qos1: '1', qos2: '2' }

export const ORDER_STATUS_TO_EVENTNAME_MAPPING= {
    'onTheWay'         : 'accepted',
    'arrived'          : 'arrived',
    'customerCancelled': 'customer_cancelled',
    'started'          : 'started',
    'reached'          : 'reached',
    'dropped'          : 'dropped',
    'aborted'          : 'aborted',
}

export const PaymentStatus = {
    COMPLETED: 'completed',
}

export const PROPAGATION_EVENT_NAMES = {
    CAPTAIN_BUSY: "captain_busy",
    CAPTAIN_REJECTED: "captain_rejected",
};
