/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./capt-adjustment.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../adjustment-forms/adjustment-forms.component.ngfactory";
import * as i3 from "../adjustment-forms/adjustment-forms.component";
import * as i4 from "./capt-adjustment.component";
import * as i5 from "../toaster.service";
import * as i6 from "./capt-adjustment.service";
import * as i7 from "@angular/router";
import * as i8 from "@angular/material/dialog";
import * as i9 from "../role-based-access.service";
import * as i10 from "../appConfig.service";
var styles_CaptAdjustmentComponent = [i0.styles];
var RenderType_CaptAdjustmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaptAdjustmentComponent, data: {} });
export { RenderType_CaptAdjustmentComponent as RenderType_CaptAdjustmentComponent };
export function View_CaptAdjustmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-adjustment-forms", [], null, [[null, "adjustmentDetails"], [null, "changeRows"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("adjustmentDetails" === en)) {
        var pd_0 = (_co.peformAdjustment($event) !== false);
        ad = (pd_0 && ad);
    } if (("changeRows" === en)) {
        var pd_1 = (_co.changeRows($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AdjustmentFormsComponent_0, i2.RenderType_AdjustmentFormsComponent)), i1.ɵdid(1, 638976, null, 0, i3.AdjustmentFormsComponent, [], { disableSubmit: [0, "disableSubmit"], info: [1, "info"], success: [2, "success"] }, { adjustmentDetails: "adjustmentDetails", changeRows: "changeRows" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableSubmit; var currVal_1 = _co.info; var currVal_2 = _co.success; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CaptAdjustmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-capt-adjustment", [], null, null, null, View_CaptAdjustmentComponent_0, RenderType_CaptAdjustmentComponent)), i1.ɵdid(1, 638976, null, 0, i4.CaptAdjustmentComponent, [i5.ToasterService, i6.CaptainAdjustmentService, i7.ActivatedRoute, i8.MatDialog, i9.RoleBasedAccessService, i10.AppConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaptAdjustmentComponentNgFactory = i1.ɵccf("app-capt-adjustment", i4.CaptAdjustmentComponent, View_CaptAdjustmentComponent_Host_0, { captainAdjDetails: "captainAdjDetails", distanceDetails: "distanceDetails", orderStatus: "orderStatus" }, { adjustmentDone: "adjustmentDone" }, []);
export { CaptAdjustmentComponentNgFactory as CaptAdjustmentComponentNgFactory };
