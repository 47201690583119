<ng-container [ngSwitch]="config.type" *ngIf="config && config.visible">
  <div *ngSwitchCase="ToasterType.NOTIFICATION" class="alert alert-icon alert-primary alert-dismissible" role="alert">
    <button *ngIf="config.config.dismissible" data-dismiss="alert" class="close"></button>
    <i class="fe fe-bell mr-2" aria-hidden="true"></i> {{config.message}}
  </div>
  <div *ngSwitchCase="ToasterType.SUCCESS" class="alert alert-icon alert-success alert-dismissible" role="alert">
    <button *ngIf="config.config.dismissible" data-dismiss="alert" class="close"></button>
    <i class="fe fe-check mr-2" aria-hidden="true"></i>
    <span style="margin-left: 24px;">
      {{config.message}}
    </span>
  </div>
  <div *ngSwitchCase="ToasterType.WARNING" class="alert alert-icon alert-danger alert-dismissible" role="alert">
    <button *ngIf="config.config.dismissible" data-dismiss="alert" class="close"></button>
    <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
    <span style="margin-left: 24px;">
      {{config.message}}
    </span>
  </div>
  <div *ngSwitchCase="ToasterType.BUTTON_INPUT" class="alert alert-success alert-dismissible">
      <button data-dismiss="alert" class="close"></button>
      <h4>{{config.title}}</h4>
      <p>
        {{config.message}}
      </p>
      <div class="btn-list">
        <ng-container *ngFor="let button of config.config.buttons">
          <ng-container [ngSwitch]="button.type">
            <button *ngSwitchCase="ToasterButtonType.SUCCESS" (click)="button.onClick()" class="btn btn-success" type="button">{{button.text}}</button>
            <button *ngSwitchCase="ToasterButtonType.SECONDARY" (click)="button.onClick()" class="btn btn-secondary" type="button">{{button.text}}</button>
          </ng-container>

        </ng-container>
      </div>
    </div>
    <div *ngSwitchCase="ToasterType.AVATAR">
      <div class="alert alert-avatar alert-primary alert-dismissible">
        <span class="avatar" [style.background-image]="config.config.thumbnailUrl"></span>
        {{config.message}}
      </div>
    </div>
    <div *ngSwitchCase="ToasterType.AVATAR_SUCCESS">
      <div class="alert alert-avatar alert-success alert-dismissible">
        <span class="avatar" [style.background-image]="config.config.thumbnailUrl"></span>
        {{config.message}}
      </div>
    </div>
</ng-container>