import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FilterService } from 'src/app/filter.service';
// import { EventEmitter } from 'events';
var startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
var currentDate = new Date();
export var DEFAULT_DATE = {
    FROM_DATE: new Date(new Date(startDate).setDate(new Date(currentDate).getDate() - 1)),
    TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};
var FilterSidenavComponent = /** @class */ (function () {
    function FilterSidenavComponent(filterService) {
        var _this = this;
        this.filterService = filterService;
        this.valuesStored = [];
        this.filterApplied = {};
        this.storedData = {};
        this.filtersAdded = false;
        this.change = new EventEmitter();
        this.submit = new EventEmitter();
        this.clear = new EventEmitter();
        this.filterStatus = new EventEmitter();
        this.setFIlterValues = function (filterData) {
            _this.filterApplied = filterData;
            Object.assign(_this.storedData, _this.filterApplied);
            _this.syncInput();
            _this.openNav();
        };
    }
    FilterSidenavComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filterComponents) {
            this.filterComponents = changes.filterComponents.currentValue;
        }
        if (changes.showFilter && this.showFilter) {
            this.openNav();
        }
        else {
            this.closeNav();
        }
    };
    FilterSidenavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showMenu = true;
        this.filterService.filterDataChange.subscribe(function (filterData) {
            _this.setFIlterValues(filterData);
        });
        this.filterService.filterValueChange.subscribe(function (filterValue) {
            _this.setFIlterValues(filterValue);
        });
        this.filterService.filterDefaultValueChange.subscribe(function (filterValue) {
            // Object.assign(this.storedData, filterValue);
            // this.syncInput();
            _this.setFIlterValues(filterValue);
        });
        this.openNav();
        Object.assign(this.storedData, this.filterApplied);
    };
    FilterSidenavComponent.prototype.syncInput = function () {
        var _this = this;
        this.filterComponents.forEach(function (component) {
            component.value = _this.getStoredValue(component);
        });
    };
    FilterSidenavComponent.prototype.getStoredValue = function (component) {
        return this.storedData[component.key] || component.value;
    };
    FilterSidenavComponent.prototype.openNav = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // this.storedData = {};
            Object.keys(_this.storedData).forEach(function (key) {
                delete _this.storedData[key];
            });
            Object.assign(_this.storedData, _this.filterApplied);
            _this.syncInput();
            document.getElementById('mySidenav').style.width = '350px';
            document.getElementById('mySidenav').style.top = '0';
            _this.showMenu = false;
            setTimeout(function () {
                window.dispatchEvent(new Event('resize'));
                return resolve();
            }, 400);
            _this.filterStatus.emit(0);
        });
    };
    FilterSidenavComponent.prototype.closeNav = function () {
        document.getElementById('mySidenav').style.width = '0';
        this.filterStatus.emit(1);
        this.showMenu = true;
    };
    FilterSidenavComponent.prototype.updateData = function ($evt, key) {
        if ($evt && $evt[key]) {
            if (($evt[key] instanceof Array && $evt[key].length === 0) ||
                (typeof ($evt[key]) === 'object' && Object.keys($evt[key]) && Object.keys($evt[key]).length === 0)) {
                if (this.valuesStored.indexOf(key) !== -1) {
                    this.valuesStored.splice(this.valuesStored.indexOf(key), 1);
                }
            }
            else if (this.valuesStored.indexOf(key) === -1) {
                this.valuesStored.push(key);
            }
        }
        if ($evt && !$evt[key]) {
            if (this.valuesStored.indexOf(key) !== -1) {
                this.valuesStored.splice(this.valuesStored.indexOf(key), 1);
            }
        }
        this.storedData[key] = $evt[key];
        this.change.emit($evt);
        var component = this.filterComponents.find(function (c) { return c.key === key; });
        if (!component) {
            return;
        }
        if (!component.onChange || typeof component.onChange !== 'function') {
            return;
        }
        component.onChange($evt[key]);
    };
    FilterSidenavComponent.prototype.updateDataValue = function (event, key) {
        var _a;
        var value = event.target.value;
        this.storedData[key] = value;
        this.change.emit((_a = {},
            _a[key] = value,
            _a));
    };
    FilterSidenavComponent.prototype.submitFilters = function () {
        var filters = Object.assign({}, this.storedData);
        this.filterService.filterValueChange.next(filters);
        this.submit.emit(filters);
        this.storedData = {};
        this.closeNav();
    };
    FilterSidenavComponent.prototype.clearFilters = function () {
        this.clearFilterData();
        this.clear.emit();
        this.submit.emit(this.storedData);
        this.closeNav();
        // setTimeout (() => {
        //   this.openNav();
        // }, 400);
    };
    FilterSidenavComponent.prototype.clearFilterData = function () {
        this.storedData = {};
        // this.filterApplied = {};
        this.filterComponents.forEach(function (c) { return c.value = null; });
        // this.syncInput();
    };
    return FilterSidenavComponent;
}());
export { FilterSidenavComponent };
