import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerInvoiceDetailsService {
    server = environment.server;
    invoiceDetailsUrl = this.server + '/api/getInvoiceDetails';
    sendInvoiceUrl = this.server + '/api/sendInvoice';

    fetchInvoiceDetails(orderId: any) {
        const url = `${this.invoiceDetailsUrl}/${orderId}`;
        const body = {
            orderId: orderId,
        };
        return this.http.post(url, body).pipe(catchError(this.handleError));
    }

    sendInvoiceToEmail(email: any, orderId: any, userId: any) {
        let url = `${this.sendInvoiceUrl}`;
        if(email) {
            url = `${url}?email=${email}`
        }
        const body = {
            email: email,
            orderId: orderId,
            userId: userId
        };
        return this.http.post(url, body).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }
    constructor(private http: MyHttpClient) { }
}
