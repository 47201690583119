import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var UpdateSearchRoleActions;
(function (UpdateSearchRoleActions) {
    UpdateSearchRoleActions["ADD"] = "add";
    UpdateSearchRoleActions["REMOVE"] = "remove";
})(UpdateSearchRoleActions || (UpdateSearchRoleActions = {}));
var ManagerRapidoAccountService = /** @class */ (function () {
    function ManagerRapidoAccountService(http) {
        this.http = http;
        this.server = "" + environment.server;
    }
    ManagerRapidoAccountService.prototype.updateSearchRole = function (userId, action) {
        return this.http
            .put(this.server + "/api/updateManagerSearchRole", {
            userId: userId,
            action: action,
        })
            .pipe(timeout(3000), map(function (updateManagerSearchRoleResponse) {
            if (updateManagerSearchRoleResponse &&
                updateManagerSearchRoleResponse.statusCode === 200) {
                return updateManagerSearchRoleResponse.message;
            }
            else {
                throw Error('Unable to fetch this user, Please check the number again');
            }
        }));
    };
    ManagerRapidoAccountService.ngInjectableDef = i0.defineInjectable({ factory: function ManagerRapidoAccountService_Factory() { return new ManagerRapidoAccountService(i0.inject(i1.HttpClient)); }, token: ManagerRapidoAccountService, providedIn: "root" });
    return ManagerRapidoAccountService;
}());
export { ManagerRapidoAccountService };
