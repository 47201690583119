import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FilterService } from 'src/app/filter.service';
// import { EventEmitter } from 'events';

const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
const currentDate = new Date();
export const DEFAULT_DATE = {
  FROM_DATE: new Date(new Date(startDate).setDate(new Date(currentDate).getDate() - 1)),
  TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};

@Component({
  selector: 'app-filter-sidenav',
  templateUrl: './filter-sidenav.component.html',
  styleUrls: ['./filter-sidenav.component.css']
})
export class FilterSidenavComponent implements OnInit, OnChanges {
  public showMenu: boolean;
  public showFilters: boolean;
  public radioButtonData: {};
  public valuesStored = [];
  @Input() filterComponents: any;
  @Input() showFilter: any;
  @Input() filterApplied: any = {};
  public storedData: any = {};
  filtersAdded = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() clear: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterStatus: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private filterService: FilterService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterComponents) {
      this.filterComponents = changes.filterComponents.currentValue;
    }
    if (changes.showFilter && this.showFilter) {
      this.openNav();
    } else {
      this.closeNav();
    }
  }


  setFIlterValues = (filterData: any) => {
    this.filterApplied = filterData;
    Object.assign(this.storedData, this.filterApplied);
    this.syncInput();
    this.openNav();
  }

  ngOnInit() {
    this.showMenu = true;
    this.filterService.filterDataChange.subscribe(filterData => {
      this.setFIlterValues(filterData);
    });
    this.filterService.filterValueChange.subscribe(filterValue => {
      this.setFIlterValues(filterValue);

    });
    this.filterService.filterDefaultValueChange.subscribe(filterValue => {
      // Object.assign(this.storedData, filterValue);
      // this.syncInput();
      this.setFIlterValues(filterValue);
    });
    this.openNav();
    Object.assign(this.storedData, this.filterApplied);
  }

  syncInput() {
    this.filterComponents.forEach(component => {
      component.value = this.getStoredValue(component);
    });
  }

  getStoredValue(component) {
    return this.storedData[component.key] || component.value;
  }

  openNav() {
    return new Promise((resolve, reject) => {
      // this.storedData = {};
      Object.keys(this.storedData).forEach(key => {
        delete this.storedData[key];
      });
      Object.assign(this.storedData, this.filterApplied);
      this.syncInput();
      document.getElementById('mySidenav').style.width = '350px';
      document.getElementById('mySidenav').style.top = '0';
      this.showMenu = false;
      setTimeout (() => {
        window.dispatchEvent(new Event('resize'));
        return resolve();
      }, 400);
      this.filterStatus.emit(0);
    });
   }

   closeNav() {
     document.getElementById('mySidenav').style.width = '0';
     this.filterStatus.emit(1);
     this.showMenu = true;
   }

   updateData($evt, key) {
     if ($evt && $evt[key]) {
      if (($evt[key] instanceof Array && $evt[key].length === 0) ||
            (typeof($evt[key]) === 'object' && Object.keys($evt[key]) && Object.keys($evt[key]).length === 0)) {
          if (this.valuesStored.indexOf(key) !== -1) {
            this.valuesStored.splice(this.valuesStored.indexOf(key), 1);
          }
      } else if (this.valuesStored.indexOf(key) === -1) {
          this.valuesStored.push(key);
        }
     }
     if ($evt && !$evt[key]) {
      if (this.valuesStored.indexOf(key) !== -1) {
        this.valuesStored.splice(this.valuesStored.indexOf(key), 1);
      }
     }
      this.storedData[key] = $evt[key];
      this.change.emit($evt);
      const component = this.filterComponents.find(c => c.key === key);
      if (!component) {
        return;
      }
      if (!component.onChange || typeof component.onChange !== 'function') {
        return;
      }
      component.onChange($evt[key]);
   }

   updateDataValue(event, key) {
     const value = event.target.value;
     this.storedData[key] = value;
      this.change.emit({
        [key]: value
      });
   }

   submitFilters() {
     const filters = Object.assign({}, this.storedData);
      this.filterService.filterValueChange.next(filters);
      this.submit.emit(filters);
      this.storedData = {};
      this.closeNav();
   }

   clearFilters() {
    this.clearFilterData();
    this.clear.emit();
    this.submit.emit(this.storedData);
    this.closeNav();
    // setTimeout (() => {
    //   this.openNav();
    // }, 400);
   }

   clearFilterData() {
    this.storedData = {};
    // this.filterApplied = {};
    this.filterComponents.forEach(c => c.value = null);
    // this.syncInput();
   }
}
