var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { HorizontalList } from '../../shared/horizontal-list/horizontal-list.component';
import { HttpClient } from '@angular/common/http';
import { TableData, TableDataTypes } from '../../shared/types';
import { PerformanceCard } from '../../shared/types/card.types';
import { environment } from '../../../environments/environment';
import { HelperService } from '../../helper.service';
import { DateFilterInput, FilterService, QuickFilters } from '../../filter.service';
var currentDate = new Date();
var monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
function padStart(length, data) {
    return data.toString().padStart(length, 0);
}
var currentDateString = currentDate.getFullYear() + "-" + padStart(2, currentDate.getMonth() + 1) + "-" + padStart(2, currentDate.getDate());
// tslint:disable-next-line:max-line-length
var monthStartDateString = monthStartDate.getFullYear() + "-" + padStart(2, monthStartDate.getMonth() + 1) + "-" + padStart(2, monthStartDate.getDate());
var defaultFilters = function () {
    return {
        fromDate: new Date(monthStartDate),
        duration: [monthStartDateString, currentDateString],
        toDate: new Date(currentDate),
        startDuration: new Date(monthStartDate),
        endDuration: new Date(currentDate),
        status: 'dropped',
    };
};
var ɵ0 = defaultFilters;
var CaptainPerformanceDataComponent = /** @class */ (function () {
    function CaptainPerformanceDataComponent(httpClient, filterService) {
        this.httpClient = httpClient;
        this.filterService = filterService;
        this.appliedFilters = defaultFilters();
        this.filterCount = 0;
    }
    CaptainPerformanceDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fromDate = this.getFormattedDate(this.appliedFilters.fromDate);
        this.toDate = this.getFormattedDate(this.appliedFilters.toDate);
        this.dataLoaded = false;
        if (this.horizontalList && this.horizontalList.table) {
            this.makeTableNull();
        }
        var riderId = this.riderId;
        var baseUrl = environment.server + "/api/svo/captain";
        var queries = riderId + "?startDate=" + this.fromDate + "&endDate=" + this.toDate;
        var aprDprUrl = baseUrl + "/aprdpr/" + queries;
        var ciccUrl = baseUrl + "/cicc/" + queries;
        var ltrUrl = baseUrl + "/rides/" + riderId;
        Promise.all([
            this.httpClient.get(aprDprUrl).toPromise(),
            this.httpClient.get(ciccUrl).toPromise(),
            this.httpClient.get(ltrUrl).toPromise()
        ])
            .then(function (res) {
            var aprdpr = res[0];
            var cicc = res[1];
            var ltrData = res[2];
            var aprdprFound = aprdpr.data.found;
            var ciccFound = cicc.data.found;
            var apr = aprdprFound ? (aprdpr.data.data.apr).toFixed(2) : 'N/A';
            var dpr = aprdprFound ? (aprdpr.data.data.dpr).toFixed(2) : 'N/A';
            var ccr = ciccFound ? (cicc.data.ccr).toFixed(2) : 'N/A';
            var trueDPR = ciccFound ? (cicc.data.trueDPR).toFixed(2) : 'N/A';
            var ltrFound = ltrData.data.found;
            var ltr = ltrFound ? (ltrData.data.rides).toFixed() : 'N/A';
            _this.defineList(apr, dpr, ccr, trueDPR, ltr);
        }).catch(function (error) {
            console.error(error);
            _this.defineList('N/A', 'N/A', 'N/A', 'N/A', 'N/A');
        });
    };
    CaptainPerformanceDataComponent.prototype.getFormattedBackDate = function (daysAgo) {
        if (daysAgo < 0) {
            throw new Error('daysAgo can\'t be negative');
        }
        var dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - daysAgo);
        return this.getFormattedDate(dateObj);
    };
    CaptainPerformanceDataComponent.prototype.getFormattedDate = function (dateObj) {
        var date = dateObj.getDate();
        var month = dateObj.getMonth();
        var year = dateObj.getFullYear();
        return year + "-" + (month + 1) + "-" + date;
    };
    CaptainPerformanceDataComponent.prototype.makeTableNull = function () {
        this.horizontalList.table = null;
        this.horizontalList = null;
    };
    CaptainPerformanceDataComponent.prototype.defineList = function (apr, dpr, ccr, trueDPR, ltr) {
        var _this = this;
        this.dataLoaded = true;
        var commonAttrs = {
            style: {
                'color': 'black',
                'font-size': '26px !imporatant'
            }
        };
        var data = [
            {
                label: 'APR',
                value: __assign({ toString: function () {
                        return "" + apr;
                    } }, commonAttrs),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: 'DPR',
                value: __assign({ toString: function () {
                        return "" + dpr;
                    } }, commonAttrs),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: 'True DPR',
                value: __assign({ toString: function () {
                        return "" + trueDPR;
                    } }, commonAttrs),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: 'LTR',
                value: __assign({ toString: function () {
                        return "" + ltr;
                    } }, commonAttrs),
                type: TableDataTypes.PERFORMANCE_CARD
            }
        ];
        var dataTable = [data, []];
        var tds = dataTable.map(function (records) {
            return records.map(function (record) {
                return new TableData({
                    type: record.type,
                    performanceCard: new PerformanceCard({
                        label: record.label,
                        value: record.value,
                        style: {
                            height: '127px',
                            width: '176px'
                        }
                    })
                });
            });
        });
        this.horizontalList = new HorizontalList({
            header: {
                toString: function () {
                    var prefix = 'Captain Performance Data ';
                    var startDate, endDate;
                    if (_this.appliedFilters.duration && _this.appliedFilters.duration instanceof Array) {
                        startDate = _this.appliedFilters.duration[0];
                        endDate = _this.appliedFilters.duration[1];
                    }
                    else {
                        startDate = _this.appliedFilters.duration['start'];
                        endDate = _this.appliedFilters.duration['end'];
                    }
                    if (startDate === endDate) {
                        return prefix + "Today, " + HelperService.dateString(new Date());
                    }
                    else {
                        // tslint:disable-next-line:max-line-length
                        return "" + prefix + HelperService.dateString(_this.appliedFilters.fromDate) + " - " + HelperService.dateString(_this.appliedFilters.toDate);
                    }
                }
            },
            tableData: tds,
            filterCount: this.filterCount,
            config: {
                refresh: true,
                filter: true,
            },
            events: {
                onRefresh: function () {
                    _this.ngOnInit();
                },
                onFilter: function () {
                    _this.filterCount = 0;
                    _this.horizontalList.table.appliedFilters = _this.filterService.appliedFilters;
                    var duration = DateFilterInput.parseValue(_this.filterService.appliedFilters.duration);
                    if (duration.startDuration || duration.endDuration) {
                        _this.filterCount++;
                    }
                    var appliedFilters = {};
                    if (duration.startDuration && duration.endDuration) {
                        var stringDuration = _this.filterService.appliedFilters.duration;
                        appliedFilters = {
                            duration: {
                                start: stringDuration[0],
                                end: stringDuration[1],
                            },
                            fromDate: duration.startDuration,
                            toDate: duration.endDuration,
                            startDuration: duration.startDuration,
                            endDuration: duration.endDuration,
                        };
                        if (stringDuration instanceof Array) {
                            appliedFilters.duration = {
                                start: stringDuration[0],
                                end: stringDuration[1],
                            };
                        }
                        else {
                            appliedFilters.duration = {
                                start: stringDuration.start,
                                end: stringDuration.end,
                            };
                        }
                    }
                    else {
                        appliedFilters = defaultFilters();
                    }
                    Object.assign(_this.appliedFilters, appliedFilters);
                    _this.filterService.filterDefaultValueChange.next(appliedFilters);
                    _this.ngOnInit();
                }
            },
            filterComponents: [
                new QuickFilters({
                    key: 'duration',
                    default: '',
                    title: 'Duration',
                })
            ],
            appliedFilters: HelperService.copyObj(this.appliedFilters),
        });
    };
    return CaptainPerformanceDataComponent;
}());
export { CaptainPerformanceDataComponent };
export { ɵ0 };
