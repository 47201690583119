var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { Pagination, Table, TableData, TableDataTypes, Toaster, ToasterType } from 'src/app/shared/types';
import { FRESH_DESK_TICKETS_URL, TICKET_STATUS_MAPPING, CAPTAIN_TICKET_DISPOSITIONS } from 'src/app/shared/constants';
import { UserTicketingService } from '../../user-tickets.service';
import { HelperService } from 'src/app/helper.service';
import { ToasterService } from 'src/app/toaster.service';
var CaptainTicketsComponent = /** @class */ (function () {
    function CaptainTicketsComponent(ticketingService, toasterService) {
        this.ticketingService = ticketingService;
        this.toasterService = toasterService;
        this.toggleHeaders = new EventEmitter();
        this.dataLoaded = false;
    }
    CaptainTicketsComponent.prototype.ngOnInit = function () {
        this.declareTable();
    };
    CaptainTicketsComponent.prototype.declareTable = function () {
        var _this = this;
        this.captainTickets = new Table({
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            headers: {
                date: 'Created Date',
                ticketId: 'Ticket ID',
                subject: 'Subject',
                description: 'Description',
                status: 'Status',
                agentAction: 'Agent Action',
                agentReason: 'Agent Reason',
                lastReply: 'Last Reply'
            },
            pagination: new Pagination(0, 10, 30, [10, 15, 20], 0, true),
            selectedHeader: 3,
            defaultError: 'No Tickets found',
            onRefresh: function () {
                _this.captainTickets = null;
                _this.ngOnInit();
            },
            data: []
        });
        this.getTickets();
    };
    CaptainTicketsComponent.prototype.getTickets = function (pageNumber, perPage) {
        if (pageNumber === void 0) { pageNumber = 1; }
        if (perPage === void 0) { perPage = 10; }
        return __awaiter(this, void 0, void 0, function () {
            var query, queryString, response, _a, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.dataLoaded = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        query = {
                            "mobile": this.mobile
                        };
                        queryString = JSON.stringify(query);
                        return [4 /*yield*/, this.ticketingService.fetchUserTicketsV2(this.captainId, 'captain', queryString, pageNumber).toPromise()];
                    case 2:
                        response = _b.sent();
                        this.dataLoaded = true;
                        if (!(response && response.tickets)) return [3 /*break*/, 4];
                        _a = this.captainTickets;
                        return [4 /*yield*/, this.formatCaptainTickets(response.tickets)];
                    case 3:
                        _a.data = _b.sent();
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _b.sent();
                        console.log('Error while fetching tickets for captain----', error_1);
                        this.dataLoaded = true;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CaptainTicketsComponent.prototype.formatCaptainTickets = function (captainTickets) {
        if (captainTickets === void 0) { captainTickets = []; }
        return __awaiter(this, void 0, void 0, function () {
            var formatedTickets;
            var _this = this;
            return __generator(this, function (_a) {
                if (captainTickets.length > 25)
                    captainTickets.length = 25;
                formatedTickets = [];
                captainTickets.filter(function (ticket) { return ticket.l1_agent_action && CAPTAIN_TICKET_DISPOSITIONS.includes(ticket.l1_agent_action); })
                    .map(function (item) {
                    var description = item.description_text.replace(/ +(?= )/g, '');
                    var ticket = {
                        date: _this.createTableData(HelperService.dateString(item.created_at)),
                        ticketId: new TableData({
                            data: item.ticket_id,
                            type: TableDataTypes.DATA,
                            className: 'cursor-pointer whiteSpacePre hyperlink',
                            onClick: function (data) {
                                var redirectToFreshDesk = FRESH_DESK_TICKETS_URL + "/" + item.ticket_id;
                                window.open(redirectToFreshDesk, '_blank');
                            }
                        }),
                        subject: _this.createTableData(item.subject || ''),
                        description: _this.createTableData(description || ''),
                        agentAction: _this.createTableData(item.l1_agent_action || ''),
                        agentReason: _this.createTableData(item.l1_agent_reason || ''),
                        status: _this.createTableData(TICKET_STATUS_MAPPING[item.status] || ''),
                        lastReply: new TableData({
                            data: "View Last Reply",
                            type: TableDataTypes.DATA,
                            className: 'cursor-pointer whiteSpacePre hyperlink',
                            onClick: function (data) { return __awaiter(_this, void 0, void 0, function () {
                                var includePrivate, perPage;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    includePrivate = true;
                                    perPage = 30;
                                    if (data.lastReply.data == "View Last Reply") {
                                        this.ticketingService.fetchTicketLastComment(this.captainId, data.ticketId.data, includePrivate, perPage).subscribe(function (response) {
                                            var ticketComments = response;
                                            if (ticketComments && ticketComments.comments && ticketComments.comments.data && ticketComments.comments.data.length > 0) {
                                                var index = _this.captainTickets.data.findIndex(function (ticket) { return ticket.ticketId.data == data.ticketId.data; });
                                                _this.captainTickets.data[index].lastReply.data = ticketComments.comments.data[ticketComments.comments.data.length - 1].body_text;
                                                _this.captainTickets.data[index].lastReply.className = 'cursor-pointer whiteSpacePre';
                                            }
                                            else {
                                                var index = _this.captainTickets.data.findIndex(function (ticket) { return ticket.ticketId.data == data.ticketId.data; });
                                                _this.captainTickets.data[index].lastReply.data = "N/A";
                                                _this.captainTickets.data[index].lastReply.className = 'cursor-pointer whiteSpacePre';
                                            }
                                        }, function (error) {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.WARNING,
                                                message: "Error while getting comments for ticket " + data.ticketId.data,
                                                delay: 3000
                                            }));
                                            return;
                                        });
                                    }
                                    return [2 /*return*/];
                                });
                            }); }
                        }),
                    };
                    formatedTickets.push(ticket);
                });
                return [2 /*return*/, formatedTickets];
            });
        });
    };
    CaptainTicketsComponent.prototype.createTableData = function (value, className, type, flag, hoverData) {
        if (flag === void 0) { flag = false; }
        return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData ? hoverData : null,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
        });
    };
    CaptainTicketsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CaptainTicketsComponent.prototype.onPaginationChange = function (event) {
        this.getTickets((event.pageNo + 1), event.perPage);
    };
    return CaptainTicketsComponent;
}());
export { CaptainTicketsComponent };
