import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { FRESH_DESK_TICKETS_URL, TICKET_STATUS_MAPPING, CAPTAIN_ISSUES_SUB_REASONS} from 'src/app/shared/constants';


import { UserTicketingService } from '../../user-tickets.service';
import { HelperService } from 'src/app/helper.service';
import { OrderService } from 'src/app/order-service.service';

@Component({
  selector: 'app-tickets-against-captain',
  templateUrl: './tickets-against-captain.component.html',
  styleUrls: ['./tickets-against-captain.component.css']
})
export class TicketsAgainstCaptainComponent implements OnInit {

  @Input() captainId: any;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  captainTickets: Table;
  dataLoaded = false;
  captainIssues = CAPTAIN_ISSUES_SUB_REASONS;

  constructor(
    public orderService: OrderService,
    public ticketingService: UserTicketingService
  ) { }

  ngOnInit() {
    this.declareTable();
  }

  declareTable() {
    this.captainTickets = new Table({
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      headers: {
        date: 'Created Date',
        ticketId: 'Ticket ID',
        orderId: 'Order ID',
        subReason: 'Sub Reason',
        service: 'Service',
        status: 'Status'
      },
      pagination: new Pagination(0, 10, 30, [10,15,20], 0,true),
      selectedHeader: 7,
      defaultError: 'No Tickets found',
      onRefresh: () => {
        this.captainTickets = null;
        this.ngOnInit();
      },
      data: []
    });
    this.getTickets();
  }

  async getTickets(pageNumber = 1, perPage = 10) {
    this.dataLoaded = false;
    const orderPagination = new Pagination(0, 200, 200, [10,50,100],0, true);
    try {
      const currentDate = new Date();
      const fromDate = new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 90));
      const appliedFilters = {
        duration: {
          start: fromDate,
          end: currentDate,
        },
        fromDate,
        currentDate,
        cities: [],
        services: [],
        status: '',
      };
      await this.orderService.getOrdersForCaptain(this.captainId, orderPagination, fromDate, currentDate, "captain", appliedFilters).subscribe(async data=>
        {
          const bookingIds = [];
          data.orders.forEach(order => {
            bookingIds.push(order._id)
          });
          const query = {
              "booking_id": bookingIds
          };
          const queryString = JSON.stringify(query);
          const response : any = await this.ticketingService.fetchUserTicketsV2(this.captainId, 'customer', queryString, pageNumber).toPromise();
          this.dataLoaded = true;
          if (response && response.tickets) {
            const tickets = [];
            response.tickets.forEach(ticket=>{
              if (this.captainIssues.includes(ticket.sub_reason918254)){
                tickets.push(ticket);
              }
            })
            this.captainTickets.data = this.formatTicketsAgainstCaptain(tickets);
          }
        });
      
    } catch(error) {
      console.log('Error while fetching tickets for captain');
      this.dataLoaded = true;
    }
  }

  formatTicketsAgainstCaptain(ticketsAgainstCaptain = []) {
    if (ticketsAgainstCaptain.length > 25) ticketsAgainstCaptain.length = 25;
    const formatedTickets = [];
    ticketsAgainstCaptain.map((item) => {
      const subReason = item.sub_reason918254;
      const orderId = item.booking_id;
      const service = item.service;
      const status = item.status;
      const ticket = {
        date: this.createTableData(HelperService.dateString(item.created_at)),
        ticketId: new TableData({
          data: item.ticket_id,
          type: TableDataTypes.DATA,
          className: 'cursor-pointer whiteSpacePre hyperlink',
          onClick: (data) => {
            const redirectToFreshDesk = `${FRESH_DESK_TICKETS_URL}/${item.ticket_id}`
            window.open(redirectToFreshDesk, '_blank');
          }
        }),
        subReason: this.createTableData(subReason || ''),
        orderId: this.createTableData(orderId || ''),
        service: this.createTableData(service || ''),
        status: this.createTableData(TICKET_STATUS_MAPPING[status] || '')
      }
      formatedTickets.push(ticket);
    })

    return formatedTickets;
  }

  createTableData(value, className?, type?, flag = false, hoverData?) {
    return new TableData({
      data: value,
      type: type ? type : TableDataTypes.DATA,
      flag: flag,
      hoverData: hoverData ? hoverData : null,
      className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  onPaginationChange(event) {
    this.getTickets((event.pageNo + 1), event.perPage);
  }
}
