import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CaptainService } from 'src/app/captain.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { FilterService } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';

@Component({
  selector: 'app-captain-search',
  templateUrl: './captain-search.component.html',
  styleUrls: ['./captain-search.component.css']
})
export class CaptainSearchComponent implements OnInit {
  mobile = '';
  public enteredNumber = false;
  public focus = false;
  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private captainService: CaptainService,
    private toasterService: ToasterService,
    private filterService: FilterService,
  ) { }

  ngOnInit() {
    this.mobile = this.activedRoute.snapshot.paramMap.get('id');
    this.activedRoute.parent.params.subscribe(params => {
      // console.log(params);
    });
  }

  searchMobileNumber() {
    this.filterService.showFilters = false;
    const withEarnings = false;
    const withReferralDetails = false;
    this.captainService.captainDetails(this.mobile, null, null, null, withEarnings, withReferralDetails)
      .subscribe(data => {
        // console.log(data);
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: `User found`,
        }));
        const id = HelperService.encode(this.mobile);
        this.router.navigate([`/captain/${id}`]);
      }, err => {
        console.error(err);
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch this user, Please check the number again`,
          config: {
            // dismissible: true,
          }
        }));
      });
  }

  enteredMobile() {
    if (this.mobile.length > 0) {
      this.enteredNumber = true;
    } else {
      this.enteredNumber = false;
    }
  }

}
