/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./capt-order-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card-table/card-table.component.ngfactory";
import * as i3 from "../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./capt-order-details.component";
var styles_CaptOrderDetailsComponent = [i0.styles];
var RenderType_CaptOrderDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaptOrderDetailsComponent, data: {} });
export { RenderType_CaptOrderDetailsComponent as RenderType_CaptOrderDetailsComponent };
export function View_CaptOrderDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, null, null, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaptOrderDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-capt-order-details", [], null, null, null, View_CaptOrderDetailsComponent_0, RenderType_CaptOrderDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i5.CaptOrderDetailsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaptOrderDetailsComponentNgFactory = i1.ɵccf("app-capt-order-details", i5.CaptOrderDetailsComponent, View_CaptOrderDetailsComponent_Host_0, { captDetails: "captDetails" }, {}, []);
export { CaptOrderDetailsComponentNgFactory as CaptOrderDetailsComponentNgFactory };
