/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./captain-earnings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/table/table.component.ngfactory";
import * as i3 from "../../shared/table/table.component";
import * as i4 from "../../filter.service";
import * as i5 from "./captain-earnings.component";
import * as i6 from "../../captain.service";
import * as i7 from "../../captain-redeem-service";
var styles_CaptainEarningsComponent = [i0.styles];
var RenderType_CaptainEarningsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaptainEarningsComponent, data: {} });
export { RenderType_CaptainEarningsComponent as RenderType_CaptainEarningsComponent };
export function View_CaptainEarningsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(1, 638976, null, 0, i3.TableComponent, [i4.FilterService], { table: [0, "table"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rapidoEarningInfoTable; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaptainEarningsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-captain-earnings", [], null, null, null, View_CaptainEarningsComponent_0, RenderType_CaptainEarningsComponent)), i1.ɵdid(1, 114688, null, 0, i5.CaptainEarningsComponent, [i6.CaptainService, i7.CaptainRedeemService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaptainEarningsComponentNgFactory = i1.ɵccf("app-captain-earnings", i5.CaptainEarningsComponent, View_CaptainEarningsComponent_Host_0, { captainId: "captainId", captainDetails: "captainDetails", mobile: "mobile" }, {}, []);
export { CaptainEarningsComponentNgFactory as CaptainEarningsComponentNgFactory };
