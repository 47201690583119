import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerSubscriptionService {

  constructor(private http: MyHttpClient) { }
  server = `${environment.server}`;
  subscription = this.server + '/api/getSubscription';

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  getSubscriptionLogs(userId,limit, offset) {
    return this.http.post(this.subscription + '/history/', {userId, limit, offset}).pipe(catchError(this.handleError));
  }

  cancelUserSubscription(transactionId: string) {
    const adminUser = this.accessCookie("user")
      ? JSON.parse(this.accessCookie("user"))
      : {};
    if (adminUser) {
      const adminUserId = adminUser["_id"] ? adminUser["_id"] : null;
      const adminEmailId = adminUser["emailId"] ? adminUser["emailId"] : null;
      const requestBody = {
        transactionId,
        admin: {
          userId: adminUserId,
          emailId: adminEmailId,
        },
      };
      return this.http
        .post(this.server + "/api/cancelSubscription", requestBody)
        .pipe(catchError(this.handleError));
    }
    return Observable.of({});
  }
  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }
}
