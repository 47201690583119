import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptainGeneralInfoComponent } from './captain-general-info/captain-general-info.component';
import { CaptainRapidoAccountComponent } from './captain-rapido-account/captain-rapido-account.component';
import { CaptainEarningsComponent } from './captain-earnings/captain-earnings.component';
import { CaptainDateWiseStatDetailsComponent } from './captain-date-wise-stat-details/captain-date-wise-stat-details.component';
import { CaptainCurrentStatDetailsComponent } from './captain-current-stat-details/captain-current-stat-details.component';
import { CaptainOrderDetailsComponent } from './captain-order-details/captain-order-details.component';
import { SharedModule } from '../shared/shared.module';
import { CaptainDetailsComponent } from './captain-details.component';
import { MyHttpClient } from '../http-client.service';
import { CaptainService } from '../captain.service';
import { EntityService } from '../entity.service';
import { CaptainSubscriptionsService } from '../captain-subscriptions.service';
import { CaptainRedeemService } from '../captain-redeem-service';
import { CaptainSearchComponent } from './captain-search/captain-search.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { TableScrollableComponent } from '../table-scrollable/table-scrollable.component';
import { CaptainIncentiveProgressComponent } from './captain-incentive-progress/captain-incentive-progress.component';
import { MatTabsModule, MatIconModule, MatExpansionModule, MatFormFieldModule } from '@angular/material';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DailyIncentivesComponent } from './captain-incentive-progress/daily-incentives/daily-incentives.component';
import { WeeklyIncentivesComponent } from './captain-incentive-progress/weekly-incentives/weekly-incentives.component';
import { LinearProgressComponent } from './captain-incentive-progress/linear-progress/linear-progress.component';
import { CaptainActivitiesComponent } from './captain-activities/captain-activities.component';
import { CaptainBulkActionComponent } from './captain-bulk-action/captain-bulk-action.component';
import { CaptainRapidoPayLogsComponent } from './captain-rapidoPay-logs/captain-rapidoPay-logs.component';
import { SvoService } from '../svo.service';
import { EventService } from '../event.service';
import { CaptainTicketsComponent } from './captain-tickets/captain-tickets.component';
import { CaptainSubscriptionsComponent } from './captain-subscriptions/captain-subscriptions.component';
import { RevokeFailedOrderComponent } from './captain-failed-orders/revoke-failed-order/revoke-failed-order.component';
import { SingleViewOfCaptainComponent } from './single-view-of-captain/single-view-of-captain.component';
import { CaptainPerformanceDataComponent } from './captain-performance-data/captain-performance-data.component';
import { HandHoldingDetailsComponent } from './hand-holding-details/hand-holding-details.component';
import { CaptainComplaintsByCustomerComponent } from './captain-complaints-by-customer/captain-complaints-by-customer.component';
import { CaptainRateCardComponent } from './captain-rate-card/captain-rate-card.component';
import { TicketsAgainstCaptainComponent } from './tickets-against-captain/tickets-against-captain.component';
import { CaptainCallLogsComponent } from './captain-call-logs/captain-call-logs.component';
import { CaptainCallLogsService } from '../captain-call-logs.service';
import { CaptainPropagationBreachesComponent } from './captain-propagation-breaches/captain-propagation-breaches.component';
import { CaptainPropagationEventsComponent } from './captain-propagation-events/captain-propagation-events.component';
import { CaptainAccessFeeDetails } from './captain-accessFee-details/captain-accessFee-details.component';

const routes: Routes = [{
  path: ':id',
  component: CaptainDetailsComponent,
}];
@NgModule({
  declarations: [
    CaptainGeneralInfoComponent,
    CaptainRapidoAccountComponent,
    CaptainEarningsComponent,
    CaptainDateWiseStatDetailsComponent,
    CaptainCurrentStatDetailsComponent,
    CaptainOrderDetailsComponent,
    CaptainDetailsComponent,
    CaptainSearchComponent,
    CaptainIncentiveProgressComponent,
    DailyIncentivesComponent,
    WeeklyIncentivesComponent,
    LinearProgressComponent,
    CaptainActivitiesComponent,
    CaptainBulkActionComponent,
    CaptainTicketsComponent,
    TicketsAgainstCaptainComponent,
    CaptainRapidoPayLogsComponent,
    SingleViewOfCaptainComponent,
    RevokeFailedOrderComponent,
    CaptainPerformanceDataComponent,
    HandHoldingDetailsComponent,
    CaptainComplaintsByCustomerComponent,
    CaptainSubscriptionsComponent,
    CaptainRateCardComponent,
    CaptainCallLogsComponent,
    CaptainPropagationBreachesComponent,
    CaptainPropagationEventsComponent,
    CaptainAccessFeeDetails
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    MatTabsModule,
    MatIconModule,
    MatExpansionModule,
    MatFormFieldModule,
    FontAwesomeModule,
    RouterModule.forRoot([]),
  ],
  exports: [
    CaptainGeneralInfoComponent,
    CaptainRapidoAccountComponent,
    CaptainEarningsComponent,
    CaptainDateWiseStatDetailsComponent,
    CaptainCurrentStatDetailsComponent,
    CaptainOrderDetailsComponent,
    CaptainDetailsComponent,
    CaptainSearchComponent,
  ],
  providers: [
    MyHttpClient,
    CaptainService,
    SvoService,
    EntityService,
    EventService,
    CaptainSubscriptionsService,
    CaptainCallLogsService,
    CaptainRedeemService
  ]
})
export class CaptainDetailsModule { }
