var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { CUSTOMER_REFUND_ACCESS, CUSTOMER_DEBIT_ACCESS, CUSTOMER } from '../../roles.constants';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleBasedAccessService } from '../../role-based-access.service';
import { CustomerDetailsService } from '../customer-details.service';
import { AppConfigService } from 'src/app/appConfig.service';
var ADJUSTMENT_TYPES = {
    REFUND: 'Refund',
    DEBIT: 'Debit',
    CREDIT: 'Credit'
};
var CustomerAdjustmentComponent = /** @class */ (function () {
    function CustomerAdjustmentComponent(customerDetailsService, toasterService, appConfigService, dialog, roleBasedAccess) {
        this.customerDetailsService = customerDetailsService;
        this.toasterService = toasterService;
        this.appConfigService = appConfigService;
        this.dialog = dialog;
        this.roleBasedAccess = roleBasedAccess;
        this.adjustmentDone = new EventEmitter();
        this.roles = this.accessCookie('Roles').split(',');
        this.statusValue = [];
        this.coinsCreditReasons = [];
        this.refundCustReasons = [];
        this.debitCustReasons = [];
        this.wallets = {
            rapido: "Rapido Wallet",
            prevDue: "Previous Due",
            lazypay: "Lazypay",
            freecharge: "Freecharge",
            simpl: "Simpl",
            gpay: "Gpay/JusPay",
            paytm: "Paytm",
            amazonpay: "Amazon Pay",
            coins: "Coins",
            upi: "Upi"
        };
        this.walletOptions = [
            this.wallets.rapido,
            this.wallets.prevDue,
            this.wallets.paytm,
            this.wallets.freecharge,
            this.wallets.lazypay,
            this.wallets.simpl,
            this.wallets.gpay,
            this.wallets.amazonpay,
            this.wallets.upi,
        ];
        this.debitWalletOptions = [
            this.wallets.rapido,
            this.wallets.prevDue
        ];
        this.creditWalletOptions = [
            this.wallets.coins
        ];
        this.info = {
            header: 'Adjustment',
            headerClass: null,
            dataFetched: true,
            rows: []
        };
        this.success = false;
    }
    CustomerAdjustmentComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var adjustmentConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.accessRoles = this.accessCookie('accessRoles');
                        return [4 /*yield*/, this.appConfigService.getAdjustmentConfig()];
                    case 1:
                        adjustmentConfig = _a.sent();
                        this.coinsCreditReasons = JSON.parse(adjustmentConfig.creditReasonsProfile);
                        this.debitCustReasons = JSON.parse(adjustmentConfig.debitReasonsProfile);
                        this.refundCustReasons = JSON.parse(adjustmentConfig.refundReasonsProfile);
                        this.checkStatus();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerAdjustmentComponent.prototype.ngOnChanges = function (changes) {
        this.checkStatus();
    };
    CustomerAdjustmentComponent.prototype.checkStatus = function () {
        // Push Refund if logged in user has Access for it.
        var refundAccess = Object.keys(CUSTOMER_REFUND_ACCESS);
        if ((this.roles.some(function (redundRole) { return refundAccess.indexOf(redundRole) >= 0; })) && this.statusValue.indexOf('Refund') < 0) {
            this.statusValue.push('Refund');
        }
        // Push Debit if logged in user has Access for it.
        var debitAccess = Object.keys(CUSTOMER_DEBIT_ACCESS);
        if ((this.roles.some(function (debitRole) { return debitAccess.indexOf(debitRole) >= 0; })) && this.statusValue.indexOf('Debit') < 0) {
            this.statusValue.push('Debit');
        }
        if (!(this.roles.length === 1 && this.roles.includes('Captain care centre agents')) && this.statusValue.indexOf('Credit') < 0) {
            this.statusValue.push('Credit');
        }
        this.constructData();
    };
    CustomerAdjustmentComponent.prototype.constructData = function () {
        if (this.statusValue.length > 0) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: undefined, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket', className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.walletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: [],
                        placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount', className: 'col-md-2' },
                ]
            ];
        }
        else {
            this.info = {
                header: 'Adjustment',
                accessDenied: true,
                dataFetched: true
            };
        }
    };
    CustomerAdjustmentComponent.prototype.changeRows = function (event) {
        if (event === 'Others') {
            var rowLength = this.info['rows'][0].length;
            this.info['rows'][0][rowLength - 1] = { titleClass: 'form-title-class', title: 'Other Reason', type: 'text-input',
                placeholder: 'Provide an explanation', key: 'cust-adj-other-reason', className: 'col-md-2' };
            this.info['rows'][0][rowLength] = { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount', className: 'col-md-2' };
        }
        else if (event === ADJUSTMENT_TYPES.REFUND) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: event, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket', className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Order ID', type: 'text-input', placeholder: 'Order ID', key: 'cust-adj-orderId' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.walletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: this.refundCustReasons,
                        placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount', className: 'col-md-2' },
                ],
            ];
        }
        else if (event === ADJUSTMENT_TYPES.DEBIT) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: event, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket', className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.debitWalletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', default: undefined, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: this.debitCustReasons,
                        placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'cust-adj-amount', className: 'col-md-2' },
                ],
            ];
        }
        // coins credit
        else if (event === ADJUSTMENT_TYPES.CREDIT) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'cust-adj-action', default: event, className: 'col-md-2' },
                    { titleClass: 'form-title-class', title: 'Ticket ID', type: 'text-input', placeholder: 'Ticket ID', key: 'cust-adj-ticket', className: 'col-md-4' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                        values: this.coinsCreditReasons, placeholder: 'Select reason', key: 'cust-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Coins', type: 'text-input', placeholder: 'Enter Coins', key: 'cust-adj-amount' },
                    { titleClass: 'form-title-class', title: 'Wallet', type: 'single-searchable-dropdown', values: this.creditWalletOptions,
                        placeholder: 'Select wallet', key: 'cust-adj-wallet', }
                ]
            ];
        }
        else {
            for (var index = 0; index < this.info['rows'][0].length; index++) {
                if (this.info['rows'][0][index] && this.info['rows'][0][index].title === 'Other Reason') {
                    this.info['rows'][0].splice(index, 1);
                }
            }
        }
    };
    CustomerAdjustmentComponent.prototype.peformAdjustment = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var userDetails, confirmDialogRef, previousDue, rapidoWallet, walletAmount, name, reason, walletType, adjustmentType, maxAdjAmount, adjustmentObj;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userDetails = this.accessCookie('user');
                        userDetails = JSON.parse(userDetails);
                        previousDue = this.walletInfo.outstandingBalance;
                        rapidoWallet = this.walletInfo.response.find(function (wallet) { return wallet.type === "rapido"; });
                        walletAmount = this.coinPreference ? rapidoWallet.balance : rapidoWallet.rapidoWalletBalance;
                        name = this.customerDetails.data.firstName + ' ' + this.customerDetails.data.lastName;
                        //Validation
                        if (!event['cust-adj-action'] || !event['cust-adj-reason'] || !event['cust-adj-wallet'] || !event['cust-adj-ticket']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please check customer adjustment details",
                            }));
                            return [2 /*return*/];
                        }
                        walletType = Object.keys(this.wallets).find(function (key) { return _this.wallets[key] === event['cust-adj-wallet']; });
                        adjustmentType = event['cust-adj-action'];
                        if (!event['cust-adj-amount'] || event['cust-adj-amount'] < 0) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: adjustmentType + " amount should be greater than zero",
                            }));
                            return [2 /*return*/];
                        }
                        else if (adjustmentType === ADJUSTMENT_TYPES.REFUND && event['cust-adj-wallet'] === this.wallets.prevDue) {
                            if (event['cust-adj-amount'] > previousDue) {
                                this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: adjustmentType + " amount should not be more than Previous Due",
                                }));
                                return [2 /*return*/];
                            }
                        }
                        else if (adjustmentType === ADJUSTMENT_TYPES.DEBIT && event['cust-adj-wallet'] === this.wallets.rapido) {
                            if (event['cust-adj-amount'] > walletAmount) {
                                this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: adjustmentType + " amount should not be more than Rapido Wallet Amount",
                                }));
                                return [2 /*return*/];
                            }
                        }
                        if (event['cust-adj-reason'] === 'Others' && !event['cust-adj-other-reason']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter reason",
                            }));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.roleBasedAccess.performAdjustment(adjustmentType, CUSTOMER)];
                    case 1:
                        maxAdjAmount = _a.sent();
                        if (maxAdjAmount && event['cust-adj-amount'] && (parseInt(event['cust-adj-amount'], 10) > maxAdjAmount)) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: 'You are allowed to  ' + adjustmentType + ' max Rs. ' + maxAdjAmount + '. Please enter less than ' + maxAdjAmount
                            }));
                            return [2 /*return*/];
                        }
                        else if (event['cust-adj-reason'] === 'Others' && event['cust-adj-other-reason']) {
                            reason = event['cust-adj-other-reason'];
                        }
                        else if (adjustmentType === ADJUSTMENT_TYPES.CREDIT && event['cust-adj-amount'] && !isNaN(event['cust-adj-amount']) && event['cust-adj-amount'] > 50) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Cannot credit more than 50 coins.",
                            }));
                            return [2 /*return*/];
                        }
                        else {
                            reason = event['cust-adj-reason'];
                        }
                        adjustmentObj = {
                            actorId: userDetails['_id'],
                            actorEmail: userDetails['emailId'],
                            ownerId: this.customerDetails.data._id ? this.customerDetails.data._id : '',
                            ownerType: 'customer',
                            source: walletType || 'rapido',
                            remarks: reason,
                            wallet: walletType,
                            email: this.customerDetails.data.email ? this.customerDetails.data.email : '',
                            uniqueId: '',
                            ownerName: name,
                            ownerPhoneNumber: this.customerDetails.data.mobile ? this.customerDetails.data.mobile : '',
                            orderId: event['cust-adj-orderId'],
                            ticketId: event['cust-adj-ticket']
                        };
                        confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                            width: '335px',
                            data: { title: 'Are you sure you want to  ' + event['cust-adj-action'] + ' ?',
                                type: 'order-details',
                                reason: event['cust-adj-action'],
                                message: '',
                                buttonText: 'Yes, ' + event['cust-adj-action']
                            }
                        });
                        if (event['cust-adj-action'] === ADJUSTMENT_TYPES.REFUND || event['cust-adj-action'] === ADJUSTMENT_TYPES.CREDIT) {
                            adjustmentObj['custRefundAmount'] = event['cust-adj-amount'];
                            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                                if (confirmResult) {
                                    _this.disableSubmit = true;
                                    _this.customerDetailsService.customerRefundDetails(adjustmentObj, userDetails['_id']).subscribe(function (response) {
                                        if (response && response['info'] && response['info'].status === 'success') {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.SUCCESS,
                                                message: "Amount refunded successfully"
                                            }));
                                            _this.disableSubmit = false;
                                            _this.success = true;
                                            _this.adjustmentDone.emit();
                                        }
                                    }, function (err) {
                                        _this.disableSubmit = false;
                                        _this.toasterService.showToaster(new Toaster({
                                            type: ToasterType.WARNING,
                                            message: "Unable to refund for customer",
                                        }));
                                    });
                                }
                            });
                        }
                        else {
                            adjustmentObj['customerPayableAmount'] = event['cust-adj-amount'];
                            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                                if (confirmResult) {
                                    _this.disableSubmit = true;
                                    _this.customerDetailsService.customerDebitDetails(adjustmentObj, userDetails['_id']).subscribe(function (response) {
                                        if (response && response['info'] && response['info'].status === 'success') {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.SUCCESS,
                                                message: "Amount debited successfully"
                                            }));
                                            _this.disableSubmit = false;
                                            _this.success = true;
                                            _this.adjustmentDone.emit();
                                        }
                                    }, function (err) {
                                        if (err && err['error'] && err['error'].data) {
                                            _this.disableSubmit = false;
                                            var errorMessage = JSON.parse(err.error.data.split('-')[1]);
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.WARNING,
                                                message: 'Unable to debit for customer',
                                            }));
                                        }
                                    });
                                }
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerAdjustmentComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    return CustomerAdjustmentComponent;
}());
export { CustomerAdjustmentComponent };
