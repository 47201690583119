
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    logs: any;
    title: string;
 }

@Component({
  selector: 'app-order-activity-log',
  templateUrl: './order-activity-log.component.html',
  styleUrls: ['./order-activity-log.component.css']
})

export class OrderActivityLogComponent {
    eventDataToolTip: string;
 
    constructor(
        public dialogRef: MatDialogRef<OrderActivityLogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getOrderActivityLogStatus = () => {
        if (this.data && this.data.logs && this.data.logs.length>0) {
            return true;
        } 
        return false;
    }

    tooltipData(data) {
        this.eventDataToolTip = `<div class='dashed-top-border'>${data}</div>`;
    }
}



