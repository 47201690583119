var IPerformanceCard = /** @class */ (function () {
    function IPerformanceCard() {
    }
    return IPerformanceCard;
}());
export { IPerformanceCard };
var PerformanceCard = /** @class */ (function () {
    function PerformanceCard(config) {
        this.isPositivehike = true;
        this.visible = true;
        this.style = {
            height: '127px',
            width: '176px',
        };
        Object.assign(this, config);
        this.onClick = this.onClick || (function () { });
    }
    Object.defineProperty(PerformanceCard.prototype, "displayPercentValue", {
        get: function () {
            if (this.isPositivehike) {
                return this.hikePercent;
            }
            return "-" + this.hikePercent;
        },
        enumerable: true,
        configurable: true
    });
    return PerformanceCard;
}());
export { PerformanceCard };
