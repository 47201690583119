import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes, Button } from '../types';
import { HelperService } from 'src/app/helper.service';
import { FilterService } from 'src/app/filter.service';
import { faRedo, faSearch, faFilter, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const STRING = {
  EMPTY: '',
  FUNCTION: 'function',
};


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnChanges {
  TableDataTypes = TableDataTypes;
  @Input() table: Table;
  @Output() paginationChange = new EventEmitter();
  @Output() showCUSelectors = new EventEmitter();
  @Output() showChips = new EventEmitter();
  displayedColumns: string[];
  _debounceSearch: any;
  headers = [];
  faRefresh = faRedo;
  faEllipsis = faEllipsisV;

  get trClass() {
    if (this.table && this.table.config && this.table.config.noColorOnTdOnHover) {
      return `table-hover-without-color`;
    }
    return '';
  }

  constructor(
    public filterService: FilterService,
  ) {
    // this.defineFilters();
   }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.table) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    if (!this.table) {
      return;
    }
    this.displayedColumns =  Object.keys(this.table.headers);
    this.headers = this.displayedColumns.map(col => this.table.headers[col]);
  }

  toInt(data: string) {
    return parseInt(data, 10);
  }

  doAction(index, cb) {
    if (cb && typeof cb === STRING.FUNCTION) {
      cb(this.table.realData[index]);
    }
  }

  debounceSearch() {

  }

  onSearch(searchString: string) {
    this.table.emitSearch(searchString);
  }

  onRefresh() {
    this.table.emitRefresh();
  }

  viewCrossUtilizationSelectors() {
    this.showCUSelectors.emit();
  }

  viewMoreChips(header) {
    this.showChips.emit(header);
  }

  private assignAppliedFilters(newFilter = {}) {
    Object.keys(this.filterService.appliedFilters).forEach(key => {
      delete this.filterService.appliedFilters[key];
    });
    this.filterService.filterValueChange.next(newFilter);
    Object.assign(this.filterService.appliedFilters, newFilter);
  }

  onFilter() {
    this.assignAppliedFilters(this.table.appliedFilters);
    this.filterService.filterDataChange.next(this.table.filterComponents);
    this.filterService.defineFilters(this.table.filterComponents, (filters) => {
      this.table.emitFilter(filters);
    });
    this.filterService.filterToggle();
  }
  redirectRoute(info) {
    if (info && info.route) {
      const link = '/' + info.type + '/' + info.route;
      window.open(link, '_blank');
    }
  }
}
