import * as i0 from "@angular/core";
var FullViewMapService = /** @class */ (function () {
    function FullViewMapService() {
        this.dataReceived = false;
        this.showMap = false;
    }
    FullViewMapService.prototype.toggleFullMap = function (data) {
        this.showMap = !this.showMap;
        if (data && this.showMap) {
            this.mapData = data;
            this.dataReceived = true;
        }
        else {
            this.dataReceived = false;
        }
    };
    FullViewMapService.ngInjectableDef = i0.defineInjectable({ factory: function FullViewMapService_Factory() { return new FullViewMapService(); }, token: FullViewMapService, providedIn: "root" });
    return FullViewMapService;
}());
export { FullViewMapService };
