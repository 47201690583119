import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OrderLogService } from './order-log-service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import * as _ from 'lodash';

@Component({
  selector: 'app-order-log',
  templateUrl: './order-log.component.html',
  styleUrls: ['./order-log.component.css']
})
export class OrderLogComponent implements OnInit, OnChanges {
  dataRows = [];
  tableData: any;
  orderLogs: any;
  @Input() orderLogDetails: any;
  @Input() statusMap: any;
  @Output() showOnMaps: EventEmitter<any> = new EventEmitter<any>();
  @Input() pageRefreshed: any;
  @Input() orderCityId: any;
  riderName = '';
  riderNumber = '';
  destChange: any;
  orderLogFetched = false;
  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };

  dataLoaded = false;
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  eventNames = [
    'requested', 'captains_mapped', 'accepted', 'arrived', 'started', 'dropped', 'customer_cancelled',
    'captain_rejected', 'captain_cancelled', 'expired', 'aborted', 'dropChanged', 'captain_busy'
  ];

  constructor(private orderLogService: OrderLogService,
              private toasterService: ToasterService) { }

  ngOnInit() {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.pageRefreshed && changes.pageRefreshed.currentValue) {
      this.dataRows = [];
    }
    if (changes && changes.orderLogDetails && changes.orderLogDetails.currentValue) {
      if (!changes.orderLogDetails.currentValue.dataFetched) {
        this.emptyCard();
      } else {
        this.orderLogFetched = true;
        this.riderLocationDetails();
      }
    }
  }

  emptyCard() {
    this.dataLoaded = true;
    this.tableData = {
      tableHeader: 'Order log',
      tableHeaderClass: 'margin-9-16',
      headers: null,
      data: null,
      dataFetched: false,
      emptyMessage: 'No order logs found for this order'
    };
  }

  buttonClicked(button) {
    if (button === 'Show on maps') {
      this.tableData = {
        tableHeader: 'Order log',
        tableHeaderClass: 'margin-9-16',
        expandTable: true,
        headers: {timeStamp: 'Timestamp', status: 'Status', riderDetails: 'Rider Details', location: 'Location', otherInfo: 'Other Info'},
        data: [this.dataRows[0]],
        button: {data: 'Hide from maps', classButton: 'show-on-map'},
        title: ['riderDetails', 'location'],
        dataFetched: true,
        tableHeight: 'max-height-275',
        tooltipPosition: 'left'
      };
      this.showOnMaps.emit();
    } else if (button === 'Hide from maps') {
      this.tableData = {
        tableHeader: 'Order log',
        tableHeaderClass: 'margin-9-16',
        expandTable: true,
        headers: {timeStamp: 'Timestamp', status: 'Status', riderDetails: 'Rider Details', location: 'Location', otherInfo: 'Other Info'},
        data: [this.dataRows[0]],
        // button: {data: 'Show on maps', classButton: 'show-on-map'},
        title: ['riderDetails', 'location'],
        dataFetched: true,
        tableHeight: 'max-height-275',
        tooltipPosition: 'left'
      };
      this.showOnMaps.emit();
    }
  }

  getProperDate(timeStamp) {
    const timeInfo = new Date(timeStamp).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
    const today = new Date(timeStamp);
    let dd: any = today.getDate();
    const mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear().toString().substr(-2);

    if (dd < 10) {
      dd = '0' + dd;
    }
    const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
    return (dateInfo + '\n' + timeInfo);
  }

  constructCard() {
    this.tableData = {
      tableHeader: 'Order log',
      tableHeaderClass: 'margin-9-16',
      expandTable: (this.dataRows.length) > 1 ? true : false,
      headers: {timeStamp: 'Timestamp', status: 'Status', riderDetails: 'Rider Details', location: 'Location', otherInfo: 'Other Info'},
      data: [this.dataRows[0]],
      // button: {data: 'Show on maps', classButton: 'show-on-map'},
      title: ['riderDetails', 'location', 'timeStamp'],
      dataFetched: true,
      tableHeight: 'max-height-275',
      tooltipPosition: 'below'
    };
    this.dataLoaded = true;
  }

  riderLocationDetails() {
    if (this.orderLogFetched) {
      this.orderLogs = this.orderLogDetails.orderLogs;
      const filteredOrderLogs = [];
      for (let index = 0; index < this.orderLogs.length; index++) {
        if (this.eventNames.indexOf(this.orderLogs[index].eventName) != -1) {
          filteredOrderLogs.push(this.orderLogs[index]);
        } else if (this.orderLogs[index].eventName == 'order_updated' && this.orderLogs[index].updateType == 'dropLocation') {
          this.orderLogs[index].eventName = 'dropChanged';
          filteredOrderLogs.push(this.orderLogs[index]);
        }
      }
      this.orderLogs = filteredOrderLogs;
      if (this.orderLogs) {
        this.orderLogs.map(orderLog => {
          orderLog.cityid = this.orderCityId;
          orderLog.location = orderLog.captainLocation || orderLog.customerLocation;
        })
        // add change drop if exist in correct pos
        if (this.destChange) {
          const data = this.destChange;
          const changeDropInfo = data['data'];
          for (let index = 0; index < changeDropInfo.length; index++) {
            if (  changeDropInfo[index].update_type
              && changeDropInfo[index].update_type === 'dropChanged' ) {
              const changeDrop = {};
              changeDrop['currStatus'] = 'dropChanged';
              changeDrop['createdOn'] = changeDropInfo[index].updatedAt;
              changeDrop['additionalInfo'] = '';
              changeDrop['location'] = {  lat: changeDropInfo[index].new_lat_lng.lat,
                                          lng: changeDropInfo[index].new_lat_lng.lng
                                        };
              this.orderLogs.push(changeDrop);
            }
          }
        }
        this.orderLogService.fetchRiderLocation(this.orderLogs).subscribe(async (response: any) => {
          if (response) {
            let orderLogDetails = response;
            let redirectUrl;
            const mapsUrl = 'https://www.google.com/maps/?q=';
            let otherInfo = '', riderName = '', riderMobile = '', riderDetails = '';
            await this.fetchRiderDetails()
            for (const log in orderLogDetails) {
              if (orderLogDetails[log]) {
                otherInfo = '';
                if (orderLogDetails[log].additionalInfo) {
                  otherInfo = orderLogDetails[log].additionalInfo.split(':')[1] + '% battery';
                }
                if (orderLogDetails[log].location && orderLogDetails[log].location.lat && orderLogDetails[log].location.lng) {
                  redirectUrl = mapsUrl + orderLogDetails[log].location.lat + ',' + orderLogDetails[log].location.lng;
                }
                if (this.orderLogs && this.orderLogs[log] && this.orderLogs[log].captainId) {
                  if (this.orderLogs[log].eventName && this.orderLogs[log].eventName !== 'aborted') {
                    if (this.orderLogs[log].captain.firstName) {
                      riderName = this.orderLogs[log].captain.firstName;
                    }
                    if (this.orderLogs[log].captain.lastName) {
                      riderName += this.orderLogs[log].captain.lastName;
                    }
                    riderMobile = this.orderLogs[log].captain.mobile ? this.orderLogs[log].captain.mobile : '';
                    this.riderName = riderName;
                    this.riderNumber = riderMobile;
                  } else {
                    riderName = this.riderName;
                    riderMobile = this.riderNumber;
                  }
                }

                if (riderName && riderMobile) {
                  riderDetails = riderName + '\n' + riderMobile;
                } else if (riderName && !riderMobile) {
                  riderDetails = riderName;
                } else if (!riderName && riderMobile) {
                  riderDetails = riderMobile;
                } else {
                  riderDetails = '';
                }

                const riderRedirectUrl = '/captain/' + riderMobile;
                this.dataRows.push(
                  {
                    timeStamp: {data: orderLogDetails[log].updatedAt},
                    status: {data: this.statusMap[orderLogDetails[log].eventName] ? this.statusMap[orderLogDetails[log].eventName] : ''},
                    riderDetails: {data: riderDetails, redirectLink: riderRedirectUrl, className: riderRedirectUrl ? 'web-link' : ''},
                    location: {data: orderLogDetails[log].locationName ? orderLogDetails[log].locationName.trim() : redirectUrl ? 'View Map' : '',
                              redirectLink: redirectUrl, className: redirectUrl ? 'web-link' : ''},
                    otherInfo: {data: otherInfo},
                  }
                );
              }
            }
            this.populateOrderLogs();
          }
        }, error => {
          this.dataLoaded = false;
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: 'Unable to fetch order log details',
          }));
        });
      }
    }
  }

  private async fetchRiderDetails() {
    let captainIds = [];
    let captainDetails;
    let captainDetailsWithId = {}

    for (const log in this.orderLogs) {
      if (this.orderLogs && this.orderLogs[log] && this.orderLogs[log].captainId) {
        captainIds.push(this.orderLogs[log].captainId)
      }
    }

    if (captainIds.length > 0) {
      let response = await this.orderLogService.fetchRiderDetails(captainIds).toPromise()
      if (response['data'] && response['data'].rider) {
        captainDetails = response['data'].rider;
        for (const index in captainDetails) {
          if (captainDetails[index] && captainDetails[index].lastName) {
            captainDetails[index].name = captainDetails[index].firstName + ' ' + captainDetails[index].lastName;
          } else {
            captainDetails[index].name = captainDetails[index].firstName;
          }
        }

        for (const rider in captainDetails) {
          if (captainDetails[rider] && captainDetails[rider]['_id']) {
            captainDetailsWithId[captainDetails[rider]['_id']] = captainDetails[rider]
          }
        }

        for (const log in this.orderLogs) {
          if (this.orderLogs && this.orderLogs[log] && this.orderLogs[log].captainId) {
            this.orderLogs[log].captain = captainDetailsWithId[this.orderLogs[log].captainId]
          }
        }
      }
    }
  }

  private populateOrderLogs() {
    this.dataRows.sort(this.compare);
    for (const log in this.dataRows) {
      if (this.isValidTimeStamp(log)) {
        this.dataRows[log].timeStamp.data = this.getProperDate(this.dataRows[log].timeStamp.data);
      }
    }
    this.orderLogFetched = false;
    this.constructCard();
  }

  private isValidTimeStamp(log: string) {
    return this.dataRows[log] && this.dataRows[log].timeStamp && this.dataRows[log].timeStamp.data && typeof this.dataRows[log].timeStamp.data != "string";
  }

  compare( a, b ) {
    if ( new Date(a.timeStamp.data) > new Date(b.timeStamp.data) ) {
      return -1;
    }
    if ( new Date(a.timeStamp.data) < new Date(b.timeStamp.data) ) {
      return 1;
    }
    return 0;
  }

  toggleState(status) {
    if (status === 'sl') {
      this.tableData = {...this.tableData, data: this.dataRows};
    } else if (status === 'vm') {
      this.tableData = {...this.tableData, data: [this.dataRows[0]]};
    }
  }

}
