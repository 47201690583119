/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rating-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@netbasal/ngx-content-loader/netbasal-ngx-content-loader.ngfactory";
import * as i3 from "@netbasal/ngx-content-loader";
import * as i4 from "../expandable-table/expandable-table.component.ngfactory";
import * as i5 from "../expandable-table/expandable-table.component";
import * as i6 from "@angular/common";
import * as i7 from "./rating-details.component";
var styles_RatingDetailsComponent = [i0.styles];
var RenderType_RatingDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RatingDetailsComponent, data: {} });
export { RenderType_RatingDetailsComponent as RenderType_RatingDetailsComponent };
function View_RatingDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(6, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(9, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(12, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 9, 0); _ck(_v, 12, 0); }, null); }
function View_RatingDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-expandable-table", [], null, null, null, i4.View_ExpandableTableComponent_0, i4.RenderType_ExpandableTableComponent)), i1.ɵdid(3, 638976, null, 0, i5.ExpandableTableComponent, [], { info: [0, "info"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-expandable-table", [], null, null, null, i4.View_ExpandableTableComponent_0, i4.RenderType_ExpandableTableComponent)), i1.ɵdid(6, 638976, null, 0, i5.ExpandableTableComponent, [], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerTableData; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.captainTableData; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_RatingDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RatingDetailsComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.dataLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.dataLoaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RatingDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rating-details", [], null, null, null, View_RatingDetailsComponent_0, RenderType_RatingDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i7.RatingDetailsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RatingDetailsComponentNgFactory = i1.ɵccf("app-rating-details", i7.RatingDetailsComponent, View_RatingDetailsComponent_Host_0, { ratingDetails: "ratingDetails" }, {}, []);
export { RatingDetailsComponentNgFactory as RatingDetailsComponentNgFactory };
