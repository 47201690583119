export enum RoundLevel {
  ONE,
  TWO,
}
export interface IThumbnailCardConfig {
  imageRound?: boolean;
  roundLevel?: RoundLevel;
  thumnailPart?: number;
  thumbnailPartStyle?: any;
  thumbnailImageStyle?: any;
  infoPart?: number;
}

export const defaultCardConfig = {
  imageRound: false,
  roundLevel: RoundLevel.ONE,
};

export enum ThumbnailCardTypes {
  SQUARE_IMAGE,
  ROUND_IMAGE,
  MINIMAL_THUMBNAIL,
  MINIMAL_SQUARE,
}

export class ThumbnailCardConfig {
  imageRound: boolean;
  roundLevel?: RoundLevel;
  thumnailPart?: number;
  thumbnailPartStyle?: any;
  infoPart?: number;
  thumbnailImageStyle?: any;
  constructor(config: IThumbnailCardConfig = {}) {
    if (config.imageRound) {
      // console.log(config);
    }
    Object.assign(this, defaultCardConfig, config);
  }
}
export interface IThumbnailCard {
  title?: string;
  type?: ThumbnailCardTypes;
  data?: any;
  info?: string;
  imageUrl: string;
  config?: IThumbnailCardConfig;
  style?: any;
}
export class ThumbnailCard {
  title?: string;
  type?: ThumbnailCardTypes = ThumbnailCardTypes.SQUARE_IMAGE;
  data?: any;
  info: string;
  imageUrl: string;
  config: ThumbnailCardConfig;
  style: any = {};
  constructor(config: IThumbnailCard) {
    Object.assign(this, config);
    this.config = new ThumbnailCardConfig(this.config);
  }
  get imageStyle() {
    return `url(${this.imageUrl})`;
  }
  get thumbnailPartClass() {
    this.config.thumnailPart = this.config.thumnailPart || 2;
    if (this.config.thumnailPart) {
      return `col-md-${this.config.thumnailPart} make-item-center`;
    } else {
      return '';
    }
  }
  get infoPartClass() {
    this.config.infoPart = this.config.infoPart || 10;
    if (this.config.infoPart) {
      return `col-md-${this.config.infoPart}`;
    } else {
      return '';
    }
  }
  get avatarClass() {
    let baseClass = `avatar-md mr-3 svgIcon`;
    switch (this.config.roundLevel) {
      case RoundLevel.ONE: {
        // baseClass += ` level-1-round`;
        break;
      }
      case RoundLevel.TWO: {
        baseClass += ` level-2-round`;
        break;
      }
    }
    if (this.type === ThumbnailCardTypes.MINIMAL_SQUARE) {
      baseClass += ` remove-border-radius`;
    }
    return baseClass;
  }
}
