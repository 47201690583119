
<div class="row" *ngIf="!dataLoaded">
    <div class="col-md-3">
        <bullet-list-content-loader></bullet-list-content-loader>
    </div>
    <div class="col-md-3">
        <bullet-list-content-loader></bullet-list-content-loader>
    </div>
    <div class="col-md-3">
        <bullet-list-content-loader></bullet-list-content-loader>
    </div>
    <div class="col-md-3">
        <bullet-list-content-loader></bullet-list-content-loader>
    </div>
</div>

<div class="row" *ngIf="dataLoaded">
  <div class="col-md-6">
    <app-expandable-table [info]="customerTableData"></app-expandable-table>
  </div>
  <div class="col-md-6">
      <app-expandable-table [info]="captainTableData"></app-expandable-table>
  </div>
</div>