import { Component, OnInit, Input } from '@angular/core';
import { HorizontalList } from 'src/app/shared/horizontal-list/horizontal-list.component';
import { TableData, ThumbnailCard, TableDataTypes } from 'src/app/shared/types';
import { ThumbnailCardTypes } from 'src/app/shared/types/thumnail-card.types';
import { of } from 'rxjs';
import { CaptainService } from 'src/app/captain.service';
import { QuickFilters } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';

const IMAGE_URLS = {
  // ONLINE: '',
    ONLINE: '/assets/svgImages/dash-icon-online.svg',
    OFFLINE: '/assets/svgImages/offline.svg',
  // ONLINE: './assets/demo/photos/david-klaasen-54203-500.jpg',
  // IDLE_TIME: '',
    IDLE_TIME: '/assets/svgImages/dash-icon-idle-time.svg',
  // IDLE_TIME: './assets/demo/photos/david-klaasen-54203-500.jpg',
  // LOCATION: '',
    LOCATION: '/assets/svgImages/dash-icon-current-location.svg',
  // NEARES_DEMAND_LOCATION: '',
    NEARES_DEMAND_LOCATION: '/assets/svgImages/dash-icon-nearest-demand.svg',
  // LOCATION: './assets/demo/photos/david-klaasen-54203-500.jpg',
};

const IMAGES = {
  noOfOrders: {
    'background-image': `url('/assets/svgImages/no_of_orders.svg')`
  },
  ONLINE: {
    'background-image': `url('/assets/svgImages/dash-icon-online.svg')`
  },
  starRating: {
    'background-image': `url('/assets/svgImages/star.svg')`,
  },
  IDLE_TIME: {
    'background-image': `url('/assets/svgImages/dash-icon-idle-time.svg')`,
  },
  lastAppLogin: {
    'backgroundImage': `url('/assets/svgImages/last_login.svg')`
  },
  LOCATION: {
    'backgroundImage': `url('/assets/svgImages/dash-icon-current-location.svg')`
  },
  lastOrderDate: {
    'background-image': `url('/assets/svgImages/last_order_date.svg')`,
  },
  NEARES_DEMAND_LOCATION: {
    'background-image': `url('/assets/svgImages/dash-icon-nearest-demand.svg')`,
  },

  avgRating: {
    'background-image': `url('/assets/svgImages/avg_rating.svg')`
  }
};

@Component({
  selector: 'app-captain-current-stat-details',
  templateUrl: './captain-current-stat-details.component.html',
  styleUrls: ['./captain-current-stat-details.component.css']
})
export class CaptainCurrentStatDetailsComponent implements OnInit {
  @Input() captainId: string;
  horizontalList: HorizontalList;
  public dataLoaded = false;
  info: any;
  constructor(
    public captainService: CaptainService,
  ) { }

  ngOnInit() {
    this.dataLoaded = false;
    if (this.horizontalList && this.horizontalList.table) {
      this.makeTableNull();
    }
    this.defineList(this.captainId);
  }
  private makeTableNull() {
    this.horizontalList.table = null;
    this.horizontalList = null;
  }

  getDemandLocationCoordinates(apiOutput: any): any {
    apiOutput = apiOutput || {};
    const data = apiOutput.data || {};
    const center = data.center || [];
    return center;
  }

  getCaptionCurrentStatus(captainId: string) {
    return this.captainService.captainLiveStat(this.captainId).map((data: any) => {
      let liveLocation = data.riderLocation && data.riderLocation.location;
      let liveLocationToShow = liveLocation;
      if (liveLocation && liveLocation instanceof Array) {
        liveLocationToShow = liveLocation.map(coord => parseFloat(coord).toFixed(4)).reverse().join(', ');
        liveLocation = liveLocation.reverse().join(', ');
      }
      let nearestDemandLocation = this.getDemandLocationCoordinates(data.nearestDemandLocation);
      let nearestDemandLocationToShow = nearestDemandLocation;
      if (nearestDemandLocation && nearestDemandLocation instanceof Array) {
        nearestDemandLocationToShow = nearestDemandLocation.map(coord => parseFloat(coord).toFixed(4)).reverse().join(', ');
        nearestDemandLocation = nearestDemandLocation.reverse().join(', ');
      }
      function isStatusOnline(status = '') {
        if (status && status.indexOf('Online') > -1) {
          return true;
        }
        return false;
      }
      // const isOnline = data.onlineSince.status === 'Online';
      const isOnline = isStatusOnline(data.onlineSince.status);
      const imageUrl = isOnline ? IMAGE_URLS.ONLINE : IMAGE_URLS.OFFLINE;
      // const imageUrl = isOnline ? IMAGE_URLS.ONLINE : IMAGE_URLS.OFFLINE;
      const listData = [{
        title: data.onlineSince.status,
        // title: 'Online',
        imageUrl: imageUrl,
        // imageUrl: IMAGE_URLS.ONLINE,
        info: data.onlineSince.since,
      }, {
        title: isOnline ? 'Current Location' : 'Last Location',
        info: liveLocationToShow,
        imageUrl: IMAGE_URLS.LOCATION,
        onClick: () => {
          const [lat, lng] = liveLocation.split(', ').map(cor => cor.toString().trim());
          if (lat && lng) {
            HelperService.openInMap(lat, lng);
          }
        }
      }, {
        title: 'Nearest demand Location',
        info: nearestDemandLocationToShow,
        imageUrl: IMAGE_URLS.NEARES_DEMAND_LOCATION,
        onClick: () => {
          const [lat, lng] = nearestDemandLocation.split(', ').map(cor => cor.toString().trim());
          if (lat && lng) {
            HelperService.openInMap(lat, lng);
          }
        }
      }];
      return listData;
    });
    // return of(listData);
  }

  defineNewCard(records) {
    this.dataLoaded = true;
    this.info =  {
      dataFetched: true,
      stats: [],
      filters: [
        // new DateFilterInput({
        //   key: 'statFilter',
        //   default: '',
        //   title: 'Select Date Range'
        // })
         new QuickFilters({
           key: 'statFilter',
           default: '',
           title: 'Duration',
         })
      ]
    };
    this.info.stats = records.map(record => {
      // record.info = record && record.info && record.info.split('Since').join('').trim();
      return {
        header : record.title,
        value : record.info,
        onClick: record.onClick,
        style: {
          'background-image': `url('${record.imageUrl}')`
        }
      };
    });
  }

  defineList(captainId: string) {
    this.getCaptionCurrentStatus(captainId).subscribe(records => {
      this.defineNewCard(records);
      const tds = records.map(record => {
        return new TableData({
          type: TableDataTypes.CARD,
          onClick: record.onClick,
          card: new ThumbnailCard({
            type: ThumbnailCardTypes.SQUARE_IMAGE,
            imageUrl: record.imageUrl,
            config: {
              thumbnailPartStyle: {
                'border-radius': '8px',
                'margin-left': '20px',
                'height': '65px',
                'width': '69px',
                'margin-top': '27px',
                'background-color': 'white',
              }
            },
            title: record.title,
            info: record.info,
            style: {
              height: '127px',
              width: '295px',
            }
          }),
        });
      });
      const currentDate = new Date();
      const todaysDate = HelperService.dateString(currentDate);
      this.horizontalList = new HorizontalList({
        header: '',
        tableData: [tds],
        config: {
          refresh: true,
        },
        events: {
          onRefresh: () => {
            this.ngOnInit();
          }
        }
      });
    });
  }

  onRefresh () {
    this.ngOnInit();
  }

}
