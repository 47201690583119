/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-view-map.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/primeng/components/inputswitch/inputswitch.ngfactory";
import * as i4 from "primeng/components/inputswitch/inputswitch";
import * as i5 from "@angular/forms";
import * as i6 from "../toll-info/toll-info.component.ngfactory";
import * as i7 from "../toll-info/toll-info.component";
import * as i8 from "../tolls.service";
import * as i9 from "../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i10 from "@fortawesome/angular-fontawesome";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "./full-view-map.component";
import * as i15 from "../full-view-map.service";
import * as i16 from "../ride-tracker-map/ride-tracker-map.service";
import * as i17 from "../toaster.service";
var styles_FullViewMapComponent = [i0.styles];
var RenderType_FullViewMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullViewMapComponent, data: {} });
export { RenderType_FullViewMapComponent as RenderType_FullViewMapComponent };
function View_FullViewMapComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "map-time font-size-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Time : ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.time; _ck(_v, 1, 0, currVal_0); }); }
function View_FullViewMapComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "font-size-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Distance : ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.distance; _ck(_v, 1, 0, currVal_0); }); }
function View_FullViewMapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row map-info"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "color-blue": 0, "align-on-ends": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "row map-info"; var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit.color, true); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.time; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.distance; _ck(_v, 6, 0, currVal_3); }, null); }
function View_FullViewMapComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "map-time font-size-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Time : ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.time2; _ck(_v, 1, 0, currVal_0); }); }
function View_FullViewMapComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "font-size-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Distance : ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.distance2; _ck(_v, 1, 0, currVal_0); }); }
function View_FullViewMapComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row map-info"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "color-purple": 0, "align-on-ends": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "row map-info"; var currVal_1 = _ck(_v, 2, 0, _v.parent.context.$implicit.color2, true); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.time2; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.distance2; _ck(_v, 6, 0, currVal_3); }, null); }
function View_FullViewMapComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null))], null, null); }
function View_FullViewMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "padding-10-10-0-0 row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "map-title font-size-14"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "col-md-2 floating-toggle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "p-inputSwitch", [["class", "map-toggle"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "onChange"], [null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.changeInOption(_v.context.$implicit.title) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedOptions[_v.context.$implicit.title] = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_InputSwitch_0, i3.RenderType_InputSwitch)), i1.ɵdid(12, 49152, null, 0, i4.InputSwitch, [i1.ChangeDetectorRef], { disabled: [0, "disabled"] }, { onChange: "onChange" }), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.InputSwitch]), i1.ɵdid(14, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(16, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_8)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.distance || _v.context.$implicit.time); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_v.context.$implicit.distance2 || _v.context.$implicit.time2); _ck(_v, 9, 0, currVal_2); var currVal_10 = _v.context.$implicit.disabled; _ck(_v, 12, 0, currVal_10); var currVal_11 = _v.context.$implicit.disabled; var currVal_12 = _co.selectedOptions[_v.context.$implicit.title]; _ck(_v, 14, 0, currVal_11, currVal_12); var currVal_13 = _v.context.$implicit.addLine; _ck(_v, 18, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_0); var currVal_3 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 16).ngClassValid; var currVal_8 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_FullViewMapComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-toll-info", [], null, null, null, i6.View_TollInfoComponent_0, i6.RenderType_TollInfoComponent)), i1.ɵdid(2, 114688, null, 0, i7.TollInfoComponent, [i8.TollsService], { entryTollLines: [0, "entryTollLines"], exitTollLines: [1, "exitTollLines"], tolls: [2, "tolls"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cityEntryTollLines; var currVal_1 = _co.cityExitTollLines; var currVal_2 = _co.mapData.cityTolls; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FullViewMapComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "refresh-live-loc"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getCaptLiveLocation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "cursor-pointer refresh-location ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i9.View_FaIconComponent_0, i9.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i10.FaIconComponent, [i11.DomSanitizer, i10.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵted(-1, null, [" Captain Live Location "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.faRefresh; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0); }); }
export function View_FullViewMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mapElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "sidenav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "row map-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-md-3 toggles padding-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "toggle-div"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_9)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-md-9"], ["style", "padding: 0px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullViewMapComponent_10)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, [[1, 0], ["fullMap", 1]], null, 0, "div", [["style", "width:100%;height:100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "close-map"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "close-map-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeFullView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(16, 9158656, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null], [2, i13.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.slideOptions; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.tollEnabled; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.showCaptainLocationRefresh; _ck(_v, 11, 0, currVal_2); _ck(_v, 16, 0); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 16).inline; var currVal_4 = (((i1.ɵnov(_v, 16).color !== "primary") && (i1.ɵnov(_v, 16).color !== "accent")) && (i1.ɵnov(_v, 16).color !== "warn")); _ck(_v, 15, 0, currVal_3, currVal_4); }); }
export function View_FullViewMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-view-map", [], null, null, null, View_FullViewMapComponent_0, RenderType_FullViewMapComponent)), i1.ɵdid(1, 638976, null, 0, i14.FullViewMapComponent, [i15.FullViewMapService, i16.RideTrackerMapService, i17.ToasterService, i8.TollsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullViewMapComponentNgFactory = i1.ɵccf("app-full-view-map", i14.FullViewMapComponent, View_FullViewMapComponent_Host_0, { mapData: "mapData" }, { fullViewMap: "fullViewMap" }, []);
export { FullViewMapComponentNgFactory as FullViewMapComponentNgFactory };
