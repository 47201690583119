import { Component, OnInit } from '@angular/core';
import { SigninService } from './signin.service';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/Rx';
import { ToasterService } from '../toaster.service';
import { Toaster, ToasterType } from '../shared/types';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';
import { DataService } from '../data.service';
import { LoaderService } from '../loader.service';
declare let navigator;
declare const google: any;
declare let document: any;
@Component({
  selector: 'app-signin',
  templateUrl: 'signin.component.html',
  providers: [SigninService, ToasterService]
})
export class SigninComponent implements OnInit {
  config: any;
  loginInfo: any;
  public token: any;
  private auth2: any;
  public user: any = {};
  public profile: any = {};
  public authResponseObj: any = {};
  public internetSpeed: any;
  public internetSubscriber: any;
  public slowInternet = false;
  public noInternet = false;
  public internetSpeeds = ['4g', '3g'];
  private referrer = '';
  private encodeLevel = 0;
  public roles: any;
  constructor(
    private _signinService: SigninService,
    private authService: AuthService,
    private router: Router,
    private _toastMessage: ToasterService,
    private loaderService: LoaderService,
    private dataService: DataService) {
    this.loaderService.openLoading();
    const signInJS = document.createElement('script');
    signInJS.src = 'https://rapido-generic-assets.s3.ap-south-1.amazonaws.com/gsi-client.js';
    signInJS.onload = () => {
      this.auth2 = google.accounts.oauth2.initTokenClient({
        client_id: environment.googleAccountKey,
        scope: 'email',
        callback: (tokenResponse) => {
          const accessToken = tokenResponse.access_token;
          this.signInCallback(accessToken);
        },
      });
      this.loaderService.closeLoading();
    };
    document.head.appendChild(signInJS);
  }
  ngOnInit() {
    const routeParams = HelperService.routeParams();
    this.referrer = routeParams.referrer;
    this.encodeLevel = routeParams.el;
    if (document.getElementById('BlowupLens')) {
      if (window) {
        window['jQuery']('#BlowupLens').hide();
      }
    }
    this.checkInternet();
  }

  checkInternet() {
    this.internetSubscriber = Observable.interval(2000).subscribe(() => {
      if (!navigator.onLine) {
        this.noInternet = true;
      } else {
        this.noInternet = false;
      }
      if (this.internetSpeeds.indexOf(navigator.connection.effectiveType) < 0) {
        this.slowInternet = true;
        this.internetSpeed = navigator.connection.effectiveType;
      } else {
        this.slowInternet = false;
      }
    });
  }

  private refer(token) {
    if (this.referrer && this.isValidReferrer()) {
      const encodedToken = HelperService.endcodeToLevel(token, this.encodeLevel);
      return HelperService.redirect(`${this.referrer}?token=${encodedToken}`);
    } else if (this.referrer) {
      document.write('Invalid Referrer');
      return true;
    }
    return false;
  }

  isValidReferrer() {
    const baseUrl = HelperService.getBaseRouteFromUrl(this.referrer);
    if (environment.validReferrer.includes(baseUrl)) {
      return true;
    }
    try {
      for (let validReferrer of environment.validReferrer) {
        if (baseUrl.includes(validReferrer)) {
          return true;
        }
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  showUserData(data) {
    this._signinService.getLoginResponse(data).subscribe((resp: any) => {

      this.config = resp;
      this.loginInfo = this.config.data;
      if (this.config.info && this.config.info.status) {
        this._toastMessage.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: 'logged in successfully',
        }));
        if (this.config.info.status === 'success') {
          const token = this.config.info.token;
          HelperService.setCookie({
            token: this.config.info.token,
          });
          const invalidReferer = this.refer(token);
          if (invalidReferer) {
            return;
          }
          // if (this.isValidReferrer()) {
          //   return HelperService.redirect(`${this.referrer}?token=${token}`);
          // }
          let response;
          this._signinService.getUserInfo(data.email).subscribe(result => {
            response = result;
            response = response.data;
            this.authService.setUserDetails(response);
            if (response && response._id) {
              this.roles = response.roles;
              document.cookie = 'Roles=' + this.roles;
              HelperService.setCookie({
                name: response.email,
                user: {
                  _id: response._id,
                  firstName: response.firstName,
                  emailId: response.email,
                },
                token: this.config.info.token,
              });
              this.onSuccessfullSignIn();
            } else {
              this._toastMessage.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: 'User not found! Please contact support.',
              }));
            }
          },
            err => {
              this._toastMessage.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: err.error.message,
              }));
            });
        }
      }
    }, err => {
      this.onUnauthorized();
    });
  }
  private onUnauthorized() {
    this.authService.deleteAllCookies();
    this.authService.clearUserCred();
    this._signinService.logoutGoogle();
  }

  signInCallback(token) {
    this._signinService.getCurrentUser(token).subscribe((result: any) => {
      const profile = result.info.result;
      this.user.id = profile.sub;
      this.user.name = profile.name;
      this.user.accessToken = token;
      this.user.image = profile.image;
      this.user.email = profile.email;
      this.showUserData(this.user);
    });
  }

  public socialSignIn() {
    this.auth2.requestAccessToken();
    this.authService.clearUserCred();
    this.authService.deleteAllCookies();
  }

  onSuccessfullSignIn() {
    const cookieRoles = [], accessRoles = ['refund_access', 'debit_access', 'distance_update_access', 'customer_deactivate_access'];
    this._signinService.setCookies({ roles: this.roles }).subscribe(res => {
      if (res['data'] && res['data'].data) {
        for (let index in accessRoles) {
          if (accessRoles[index] && res['data'].data.indexOf(accessRoles[index]) !== -1) {
            cookieRoles.push(accessRoles[index]);
          }
        }
      }
      if (cookieRoles.length > 0) {
        document.cookie = 'accessRoles=' + cookieRoles;
      }
      this.dataService.updatePermissions(res['data']);
      const redirectUrl = this.authService.redirectUrl;
      if (redirectUrl) {
        this.authService.redirectUrl = null;
        return this.router.navigate([
          redirectUrl
        ]);
      }
      this.router.navigate([
        '/captain'
      ]);
    });

  }
}
