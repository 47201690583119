<div id="mySidenav" class="sidenav">
    <mat-toolbar class="primary-toolbar">
            <mat-icon class="close-button cursor-pointer" (click)="closeNav()">close</mat-icon>
            <span class="header-font">Filter</span>
            <a *ngIf="valuesStored?.length > 0" (click)="clearFilters()" class="clear-filters-a">Clear All</a>
    </mat-toolbar>
    
        <div class="main-container">

        
          
          <div *ngFor="let component of filterComponents">

            <quick-filters
              *ngIf="component.type === 'quick-filters'"
              [calendarData]="component"
              (setQuickFilters)="updateData($event, component.key)"
              [startDate]="component?.value?.start"
              [endDate]="component?.value?.end"
            ></quick-filters>

            <searchable-dropdown [selectedValues]="component?.value"
              *ngIf="component.type === 'searchable-dropdown'"
              [dropdownData]="component"
              (selectedDropDowns)="updateData($event, component.key)"
            ></searchable-dropdown>
            <range-slider [value]="component.value"
              *ngIf="component.type === 'range-slider'"
              [rangeData]="component"
              (selectedRange)="updateData($event, component.key)"
            ></range-slider>
            <radio-buttons [selectedValues]="component?.value"
              *ngIf="component.type === 'radio-button'"
              [radioData]="component"
              (selectedRadio)="updateData($event, component.key)"
            ></radio-buttons>
            <calendar [fromDate]="component?.value?.start" [toDate]="component?.value?.end"
              *ngIf="component.type === 'calendar'"
              [calendarData]="component"
              (selectedDates)="updateData($event, component.key)"
            ></calendar>
            <single-select-dropdown [selectedValues]="component?.value"
              *ngIf="component.type === 'single-searchable-dropdown'"
              [singleDropdownData]="component"
              (selectedDropDown)="updateData($event, component.key)"
            ></single-select-dropdown>
            <input-box [selectedValues]="component?.value"
              *ngIf="component.type === 'text-input'"
              [inputBoxData]="component"
              (selectedText)="updateData($event, component.key)"> 
            </input-box>
          </div>
          <div class="row">
              <div class="col">
                <button mat-flat-button (click)="submitFilters()" type="button" class="apply-filters-btn bg-primary-color-white">Apply</button>
              </div>
            </div>
        </div>
  </div>