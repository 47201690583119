/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-log.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@netbasal/ngx-content-loader/netbasal-ngx-content-loader.ngfactory";
import * as i3 from "@netbasal/ngx-content-loader";
import * as i4 from "../expandable-table/expandable-table.component.ngfactory";
import * as i5 from "../expandable-table/expandable-table.component";
import * as i6 from "@angular/common";
import * as i7 from "./order-log.component";
import * as i8 from "./order-log-service";
import * as i9 from "../toaster.service";
var styles_OrderLogComponent = [i0.styles];
var RenderType_OrderLogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderLogComponent, data: {} });
export { RenderType_OrderLogComponent as RenderType_OrderLogComponent };
function View_OrderLogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(4, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(7, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
function View_OrderLogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expandable-table", [], null, [[null, "toggleExpansion"], [null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleExpansion" === en)) {
        var pd_0 = (_co.toggleState($event) !== false);
        ad = (pd_0 && ad);
    } if (("buttonClick" === en)) {
        var pd_1 = (_co.buttonClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ExpandableTableComponent_0, i4.RenderType_ExpandableTableComponent)), i1.ɵdid(2, 638976, null, 0, i5.ExpandableTableComponent, [], { info: [0, "info"], pageRefreshed: [1, "pageRefreshed"] }, { toggleExpansion: "toggleExpansion", buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableData; var currVal_1 = _co.pageRefreshed; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_OrderLogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderLogComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrderLogComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.dataLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.dataLoaded; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_OrderLogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-log", [], null, null, null, View_OrderLogComponent_0, RenderType_OrderLogComponent)), i1.ɵdid(1, 638976, null, 0, i7.OrderLogComponent, [i8.OrderLogService, i9.ToasterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderLogComponentNgFactory = i1.ɵccf("app-order-log", i7.OrderLogComponent, View_OrderLogComponent_Host_0, { orderLogDetails: "orderLogDetails", statusMap: "statusMap", pageRefreshed: "pageRefreshed", orderCityId: "orderCityId" }, { showOnMaps: "showOnMaps" }, []);
export { OrderLogComponentNgFactory as OrderLogComponentNgFactory };
