import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var OrderLogService = /** @class */ (function () {
    function OrderLogService(http) {
        this.http = http;
        this.baseUrl = environment.server + '/api/orderLog';
        this.riderDetailsBaseUrl = environment.server + '/api/rider';
    }
    OrderLogService.prototype.fetchRiderLocation = function (orderLogs) {
        var requestBody = {
            orderLog: orderLogs
        };
        return this.http.post(this.baseUrl + '/location/orderLogDetails', requestBody).pipe(catchError(this.handleError));
    };
    OrderLogService.prototype.fetchRiderDetails = function (riderId) {
        var requestBody = {
            riderId: riderId
        };
        return this.http.post(this.riderDetailsBaseUrl + '/riderDetails', requestBody).pipe(catchError(this.handleError));
    };
    OrderLogService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    OrderLogService.ngInjectableDef = i0.defineInjectable({ factory: function OrderLogService_Factory() { return new OrderLogService(i0.inject(i1.MyHttpClient)); }, token: OrderLogService, providedIn: "root" });
    return OrderLogService;
}());
export { OrderLogService };
