import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaptainOrderDetailsService {

  constructor(private http: MyHttpClient) { }
  server = `${environment.server}`;
  getAdjustments = this.server + '/api/payments/getCaptainAdjustmentsBatch';
  getAdjustmentsForOrders = this.server + '/api/payments/getAdjustmentsBatch';

  getAdjustmentAmounts(orders, id) {
    return this.http.post(this.getAdjustments, {orders, id}).map(res => {
      const details = res['data'] && res['data']['data'] && res['data']['data']['riderPaymentDetails'] ?
        res['data']['data']['riderPaymentDetails'] : [];
      return details;
    })
    .pipe(catchError(this.handleError));
  }

  getOrderAdjustments(orderIds) {
    return this.http.post(this.getAdjustmentsForOrders, {orderIds})
    .pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
