import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  options: string[];
  title: string;
  reason: string;
  type: string;
  message: string;
  errorMessage: string;
  imageUrl: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  public selectedReason: string;
  public otherReason: string;
  public showReasonAlert = false;
  public otherReasonAlert = false;
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  verifyAndSendOption() {
    if (this.data.reason === 'removeImei') {
      this.showReasonAlert = false;
      this.dialogRef.close('proceed');
    } else if (this.selectedReason) {
      if (this.selectedReason !== 'Other') {
        this.showReasonAlert = false;
        this.dialogRef.close(this.selectedReason);
      } else {
        if (!this.otherReason) {
          this.otherReasonAlert = true;
        } else {
          this.otherReasonAlert = false;
          const result = this.selectedReason + ': ' + this.otherReason;
          this.showReasonAlert = false;
          this.dialogRef.close(result);
        }
      }
    } else {
      this.showReasonAlert = true;
    }
  }

  checkReasons(reason) {
    if (reason) {
      this.showReasonAlert = false;
    }
  }

}
