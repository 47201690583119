import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Table, Pagination } from 'src/app/shared/types';
import { EntityService } from 'src/app/entity.service';
import { SearchableDropDownFilterInput } from 'src/app/filter.service';
@Component({
  selector: 'app-captain-activities',
  templateUrl: './captain-activities.component.html',
  styleUrls: ['./captain-activities.component.css']
})
export class CaptainActivitiesComponent implements OnInit {
  dataLoaded = true;
  agentEmails: any = [];
  selectedEmail = [];
  @Input() captainId: string;
  @Input() riderId: string;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  activityLogTable = new Table({
    headers: {
      date: 'Date',
      time: 'Time',
      status: 'Status',
      reason: 'Reason',
      agentName: 'Agent Name',
      agentEmail: 'Agent Email'
    },
    pagination: new Pagination(0, 10, 30, [10,15,20], 0, true),
    config: {
      refresh: true,
    },
    onRefresh: () => {
      this.activityLogTable.data = [];
      this.getData(this.riderId,this.activityLogTable.pagination.pageNo, this.activityLogTable.pagination.perPage);
    },
    selectedHeader: 1,
    defaultError: 'No Logs Found',
    toggleableHeaders: ['Order Details','Activity Logs','Bulk Action Logs','Ticket Details','Rapido Pay Logs','Complaints By Customer','Subscription Details','Customer Complaints against Captain','Call Logs','FM Breaches','Access Fee Transactions'],
    filterComponents: [
      new SearchableDropDownFilterInput({
        key : 'emails',
        title: 'Agent Emails',
        values: this.agentEmails,
        onChange: (value) => {
          this.selectedEmail = value;
          this.activityLogTable.pagination.pageNo = 0;
          this.activityLogTable.pagination.perPage = 20;
          this.getData(this.riderId, this.activityLogTable.pagination.pageNo, this.activityLogTable.pagination.perPage, this.selectedEmail)
        }
      })
    ]
  })
  constructor(
    public entityService: EntityService,
  ) { }
  ngOnInit() {
    this.getData(this.riderId, this.activityLogTable.pagination.pageNo, this.activityLogTable.pagination.perPage);
  }


  getData(riderId, page = 0, perPage = 10, email = undefined) {
    const condition = email && email.length > 0 ? JSON.stringify({"email" : { "$in": email}}) : undefined;
    this.entityService.getCaptainActivityLogs(riderId, page, perPage, condition).subscribe(data => {
      const activities: any = data;
      this.activityLogTable.data = activities.logs.filter(this.invalidData);
      this.getAgentEmails(activities.logs);
      if(condition){
        this.activityLogTable.pagination.count = this.activityLogTable.data.length;
      }else{
        this.activityLogTable.pagination.count = activities.count;
      }
    });
  }

  getAgentEmails(logs): void {
    logs.forEach(log => {
      if(log && log.agentEmail)
      if(!this.agentEmails.includes(log.agentEmail.data)){
      this.agentEmails.push(log.agentEmail.data)
      }
    });
  }

  invalidData(data){
    if (data) {
      return data;
    }
  }

  onPaginationChange(event) {
    if(this.selectedEmail.length > 0) {
      event.pageNo = 0;
      event.perPage = 20;
    }
    this.getData(this.riderId,event.pageNo,event.perPage, this.selectedEmail)
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }
}
