import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-c2c-sender-details',
  templateUrl: './c2c-sender-details.component.html',
  styleUrls: ['./c2c-sender-details.component.css']
})
export class C2cSenderDetailsComponent implements OnInit, OnChanges {

  @Input() packageDetails: any;
  public senderDetails: any;
  constructor() { }

  ngOnInit() {
    if (this.packageDetails && this.packageDetails.orderType && this.packageDetails.orderType === 'bfs') {
        this.generateBfsSenderDetails();
    } else {
        this.generateSenderDetails();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.packageDetails && this.packageDetails.orderType && this.packageDetails.orderType === 'delivery') {
        this.generateB2bSenderDetails();
      } else if (this.packageDetails && this.packageDetails.orderType && this.packageDetails.orderType === 'bfs') {
        this.generateBfsSenderDetails();
      } else {
        this.generateSenderDetails();
      }
      this.senderDetails.pickupOtp = this.packageDetails.pickupOtp || 'INACTIVE';
      
    }
  }

  getLineItems(items) {
    const itemsArray = [];
    if (items && items.length) {
      for (const item in items) {
        if (items[item] && items[item].type) {
          itemsArray.push(items[item].type);
        }
      }
    }
    return itemsArray;
  }

  getB2bItems(items) {
    const itemsArray = [];
    if (items && items.length) {
      for (const item of items) {
        if (item) {
          itemsArray.push(`${item.name} x${item.quantity} - ₹${item.price}`);
        }
      }
    }
    return itemsArray;
  }

  generateSenderDetails() {
    if (this.packageDetails) {
      let fullAddress = "";
      if (this.packageDetails.pickup) {
        fullAddress = (this.packageDetails.pickup.doorNo + ", " || "") + (this.packageDetails.pickup.address || "") + 
          (this.packageDetails.pickup.landmark ? " (Landmark : " + this.packageDetails.pickup.landmark + ")" : "");
      }
      
      this.senderDetails = {
          tableHeader: 'Sender Details',
          name: this.packageDetails.c2c && this.packageDetails.c2c.sender &&
            this.packageDetails.c2c.sender.name || 'N/A',
          otp: this.packageDetails.otp || 'N/A',
          address: fullAddress || 'N/A',
          itemCategory: this.packageDetails.c2c && this.packageDetails.c2c.lineItems ?
            this.getLineItems(this.packageDetails.c2c.lineItems) : [],
          imageUrl: this.packageDetails.c2c.pickImages,
          orderType: this.packageDetails.orderType
      };
    }
  }

  generateB2bSenderDetails() {
    if (this.packageDetails && this.packageDetails.data) {
      const data = this.packageDetails.data;
      this.senderDetails = {
          tableHeader: 'Sender Details',
          name: data.delivery && data.delivery.contact && data.delivery.contact.pickupName || 'N/A',
          number: data.delivery && data.delivery.contact && data.delivery.contact.pickupNumber || 'N/A',
          address: data.pickupLocation && data.pickupLocation.address || 'N/A',
          itemCategory: data.delivery && data.delivery.items ?
            this.getB2bItems(data.delivery.items) : null,
          directImageUrl: data.instructions && data.instructions[0] && data.instructions[0].photoUrls || [],
          orderType: this.packageDetails.orderType
          
      };
    }
  }

    getFormattedAddress = location => {
        let address = '';
        if (location.doorNo) {
            address = `${address} ${location.doorNo}`;
        }
        if (location.landmark) {
            address = `${address} ${location.landmark}`;
        }
        return `${address} ${location.address}`;
    }

    getLineItemsOfBfs = items => items.map(item => {
        if (item.quantity) {
            return `${item.quantity} x ${item.name}`;
        }
        return item.name;
    })

    generateBfsSenderDetails = () => {
        this.senderDetails = {
            tableHeader: 'Store Details',
            name: this.packageDetails.bfs && this.packageDetails.bfs.sender &&
                this.packageDetails.bfs.sender.name || 'N/A',
            orderValue: this.packageDetails.bfs.orderValue || 'N/A',
            address: this.packageDetails.pickup && this.getFormattedAddress(this.packageDetails.pickup) || 'N/A',
            itemCategory: this.packageDetails.bfs && this.packageDetails.bfs.lineItems ?
                this.getLineItemsOfBfs(this.packageDetails.bfs.lineItems) : [],
            imageUrl: this.packageDetails.bfs.pickImages,
            orderType: this.packageDetails.orderType,
            mainCategory: this.packageDetails.bfs && this.packageDetails.bfs.categories ?
                this.packageDetails.bfs.categories : [],
        };
    }
}
