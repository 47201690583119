<div class="heading">
    <span (click)="redirectToCaptainDetails()" style="cursor: pointer;">
        Captain details
    </span>
    <fa-icon [icon]="breadcrumb"></fa-icon>
    <span style="font-weight: bold;">
        Failed orders
    </span>
</div>
<app-table-scrollable *ngIf="orderTable && orderTable.state === 'loaded'" [table]="orderTable" (paginationChange)="onPaginationChange($event)"></app-table-scrollable>
<div _ngcontent-c11="" class="height-15"></div>
<app-table-scrollable *ngIf="dailyPingsTable && dailyPingsTable.state === 'loaded'" [table]="dailyPingsTable" ></app-table-scrollable>
<div _ngcontent-c11="" class="height-15"></div>
<app-table-scrollable *ngIf="hourlyPingsTable && hourlyPingsTable.state === 'loaded'" [table]="hourlyPingsTable" ></app-table-scrollable>
