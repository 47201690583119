import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dist-time-details',
  templateUrl: './dist-time-details.component.html',
  styleUrls: ['./dist-time-details.component.css']
})
export class DistTimeDetailsComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() distTimeDetails: any;
  public info: any;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.distTimeDetails && changes.distTimeDetails.currentValue) {
      if (!changes.distTimeDetails.currentValue.dataFetched) {
        this.emptyCard();
      } else {
        this.constructCard();
      }
    }
  }

  emptyCard() {
    this.info = {
      tableHeader: 'Distance & Time',
      dataFetched: false,
      data: [],
      emptyMessage: 'No data found'
    };
  }

  constructCard() {
    let data = {};
    let headers = {};
    if (this.distTimeDetails.acceptToCancel) {
      headers = {acceptToCancel: 'Accept to cancel'}
      data = { acceptToCancel: { data: this.distTimeDetails.acceptToCancel ? this.distTimeDetails.acceptToCancel + ' kms' : ''}};
    }
    data = { ...data,
      acceptToPickup: {data: this.distTimeDetails.acceptToPickup ? this.distTimeDetails.acceptToPickup + ' kms' : ''},
      estimatedDistance: {data: this.distTimeDetails.estimatedDistance ? this.distTimeDetails.estimatedDistance + ' kms' : ''},
      finalEstimatedDistance: {data: this.distTimeDetails.finalEstimatedDistance ? this.distTimeDetails.finalEstimatedDistance + ' kms' : this.distTimeDetails.finalDistance ? this.distTimeDetails.finalDistance + ' kms' : ''},
      hfDistance: {data: this.distTimeDetails.hfDistance ? this.distTimeDetails.hfDistance + ' kms' : ''},
      estimatedRideTime: {data: this.distTimeDetails.estimatedRideTime ? this.distTimeDetails.estimatedRideTime + ' mins' : ''},
      finalRideTime: {data: this.distTimeDetails.finalRideTime ? this.distTimeDetails.finalRideTime + ' mins' : ''},
      pickupCluster: {information: this.distTimeDetails.pickupCluster && this.distTimeDetails.pickupCluster.radius ? this.distTimeDetails.pickupCluster.radius + ' kms' : null,
                data: this.distTimeDetails.pickupCluster && this.distTimeDetails.pickupCluster.name ? this.distTimeDetails.pickupCluster.name : ''},
      dropCluster: {information: this.distTimeDetails.dropCluster && this.distTimeDetails.dropCluster.radius ? this.distTimeDetails.dropCluster.radius + ' kms' : null,
                data: this.distTimeDetails.dropCluster && this.distTimeDetails.dropCluster.name ? this.distTimeDetails.dropCluster.name : ''},
      odrdDistance: {data: this.distTimeDetails.odrdDistance ? this.distTimeDetails.odrdDistance + ' kms' : ''},
      distanceSuggested: {data: this.distTimeDetails.distanceSuggested ? this.distTimeDetails.distanceSuggested : ''},
    }
    this.info = {
      tableHeader: 'Distance & Time',
      expandTable: false,
      headers: {...headers, acceptToPickup: 'Accept to pickup', estimatedDistance: 'Initial Estimated distance', finalEstimatedDistance: 'Final Estimated distance', hfDistance: 'Hf distance', estimatedRideTime: 'Initial Estimated ride time',
      finalRideTime: 'Actual ride time', odrdDistance: 'ODRD(Actual) Distance', distanceSuggested: 'Distance Suggested', pickupCluster: 'Pickup cluster', dropCluster: 'Drop cluster'},
      headerClass: 'color-card-header',
      dataFetched: true,
      data: [data]
    };
  }
}