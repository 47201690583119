import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { FraudDialogComponent } from '../fraud-dialog/fraud-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { RoleBasedAccessService } from '../role-based-access.service';
import { FraudService } from './fraud-view.service';
import { FRAUDTYPES } from '../shared/constants';
import { CAPTAIN_REASSIGN, CAPTAIN_ABORT } from '../roles.constants';

@Component({
  selector: 'app-fraud-view',
  templateUrl: './fraud-view.component.html',
  styleUrls: ['./fraud-view.component.css']
})
export class FraudViewComponent implements OnInit, OnChanges {
  orderId: any;
  roles =  this.accessCookie('Roles').split(',');
  dataFetched: boolean = false;
  @Output() fraudUpdated: EventEmitter<void> = new EventEmitter();


  public fraudDetails: any;
  public title: string = 'Add';
  public fraudInfoToDisplay: any = {
    types: 'Nil',
    causes: 'Nil'
  };

  constructor( 
    private toasterService: ToasterService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private roleBasedAccess: RoleBasedAccessService,
    private fraudService: FraudService
  ){}

  ngOnInit() {
    this.orderId = this.route.snapshot.params.id;
    this.fetchTags();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      
    }
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  fetchTags() {
    this.fraudService.getFraudDetails(this.orderId)
      .subscribe(res => {
        let fraudData = res['data']['orders'][0] || [];

        this.dataFetched = true;
        this.fraudDetails = {
          types: (function() {
            let fraudTypes = [], refKeys = Object.keys(FRAUDTYPES);

            Object.keys(fraudData).forEach(key => {
              if(refKeys.includes(key) && fraudData[key].flag) {
                fraudTypes.push(FRAUDTYPES[key]);
              }
            })

            return fraudTypes;
          }.bind(this))(),
          tags: fraudData['fraudTags'] ? fraudData['fraudTags']: [],
          causes: fraudData['fraudCauses'] ? fraudData['fraudCauses']: []
        }

        this.title = this.getTitle();

        this.fraudInfoToDisplay = {
          types: this.fraudDetails.types.join(',') ? this.fraudDetails.types.join(', ') : 'Nil',
          causes: this.fraudDetails.causes.join(',') ? this.fraudDetails.causes.join(', ') : 'Nil'
        }
      }, error => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch fraud details!`,
        }));
      });
  }

  getTitle() {
    if (this.fraudDetails.tags.length === 0 && 
      this.fraudDetails.types.length === 0 && 
      this.fraudDetails.causes.length === 0
    ) {
      return 'Add';
    }

    return 'Edit'
  }

  editFraudDetails() {
    const dialogRef = this.dialog.open(FraudDialogComponent, {
      width: '335px',
      data: {
        title: `${this.title} Fraud Data`,
        fraudDetails: this.fraudDetails
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      let payLoad, updateData;

      if(!result) {
        return;
      }

      updateData = result.updateData;

      if(result.action === 'delete') {
        payLoad = {
          add: { 'fraudTypes': [], 'fraudTags': [], 'fraudCauses': [] },
          remove: {
            'fraudTypes': updateData['types'],
            'fraudTags': updateData['tags'],
            'fraudCauses': updateData['causes'],
          }
        };
      } else {
        payLoad = {
          add: {
            'fraudTypes': this.getFraudItemsToBeAdded(updateData['types'], 'types'),
            'fraudTags': this.getFraudItemsToBeAdded(updateData['tags'], 'tags'),
            'fraudCauses': this.getFraudItemsToBeAdded(updateData['causes'], 'causes')
            },
          remove: {
            'fraudTypes': this.getFrausItemsToRemove(updateData['types'], 'types'),
            'fraudTags': this.getFrausItemsToRemove(updateData['tags'], 'tags'),
            'fraudCauses': this.getFrausItemsToRemove(updateData['causes'], 'causes')
          }
        };
      }

      this.fraudService.updateFraudDetails({orderId: this.orderId, payLoad })
        .subscribe(res => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.SUCCESS,
            message: `Successfully updated fraud details!`,
          }));
          this.fetchTags();
          this.fraudUpdated.emit();

        },
        err => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Unable to fetch fraud details!`,
          }));
  
          console.log('Unable to update fraud details', err);
      });
    })
  }

  getFraudItemsToBeAdded(itemArr, type) {
    let itemsToAdd;

    if(type == 'types') {
      let keys = this.getKeys(this.fraudDetails[type]);

      itemsToAdd = itemArr.filter(item => {
        return !keys.includes(item);
      })

      return itemsToAdd;
    }

    itemsToAdd = itemArr.filter(item => {
      return !this.fraudDetails[type].includes(item);
    });

    return itemsToAdd;
  }

  getFrausItemsToRemove(itemArr, type) {
    let itemsToAdd;

    if(type == 'types') {
      let keys = this.getKeys(this.fraudDetails[type]);

      itemsToAdd = keys.filter(key => {
        return !itemArr.includes(key);
      })

      return itemsToAdd;
    }

    itemsToAdd = this.fraudDetails[type].filter(item => {
      return !itemArr.includes(item);
    });

    return itemsToAdd;
  }

  getKeys(itemArr) {
    let keys = [];

      keys = itemArr.map((val) => {
        let key;

        Object.keys(FRAUDTYPES).forEach(type => {
          if(FRAUDTYPES[type] === val) {
            key = type;
          }
        });

        return key;
      });

      return keys;
  }

}
