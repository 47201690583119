import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaptainSubscriptionsService } from 'src/app/captain-subscriptions.service';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';

@Component({
    selector: 'app-captain-subscriptions',
    templateUrl: './captain-subscriptions.component.html'
})
export class CaptainSubscriptionsComponent implements OnInit {
    @Input() captainId: string;
    @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
    @Input() captainDetailsToggleableHeaders: string[];
    captainSubscriptions: Table;
    dataLoaded = false;

    constructor(public captainSubscriptionsService: CaptainSubscriptionsService) { }

    ngOnInit() {
        this.declareTable();
    }

    declareTable() {
        this.captainSubscriptions = new Table({
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            headers: {
                amountAndPurchaseDate: 'Amount (INR)\n Purchase Date',
                activeTime: 'Start Time\n End Time',
                planType: 'Plan Type',
                planStatus: 'Plan Status',
                consumption: 'Consumption',
                cityAndServices: 'City\n Services',
                paymentStatusAndMode: 'Payment Status\n Payment Mode',
                commissionSaved: 'Commission\n Saved',
                id: 'Subscription ID',
            },
            pagination: new Pagination(0, 10, 30, [10, 15, 20], 0, true),
            selectedHeader: 6,
            defaultError: 'No Subscriptions found',
            onRefresh: () => {
                this.captainSubscriptions = null;
                this.ngOnInit();
            },
            data: []
        });
        this.getSubscriptions();
    }

    async getSubscriptions(pageNumber = 1, perPage = 10) {
        this.dataLoaded = false;
        this.captainSubscriptionsService.getCaptainSubscriptions(this.captainId, pageNumber, perPage)
            .then(({ subscriptions }) => {
                this.captainSubscriptions.data = subscriptions.map(subscription => {
                    return {
                        amountAndPurchaseDate: this.createTableData(subscription.amount + "\n" + this.dateString(subscription.createdOn)),
                        activeTime: this.createTableData(subscription.startTime && subscription.endTime ? this.dateString(subscription.startTime) + "\n" + this.dateString(subscription.endTime) : "NA"),
                        planType: this.createTableData(
                            subscription.progress.map(p => p.type) + " validity\n" +
                            subscription.rules.map(r => `${r.operator} ${r.value} ${r.type == "percent" ? "% commission" : "commission"}`).join('\n')
                        ),
                        planStatus: subscription.planStatus,
                        consumption: this.createTableData(subscription.progress.map(p => p.type != "unlimited" && subscription.status == "SUCCESS" ? `${p.current} / ${p.target} ${p.type}` : "NA")),
                        cityAndServices: this.createTableData(subscription.cityName + "\n" + subscription.serviceNames.join(', ')),
                        paymentStatusAndMode: this.createTableData(subscription.status + "\n" + subscription.paymentMode),
                        commissionSaved: subscription.commissionSaved,
                        id: this.createTableData(subscription.id, 'text-overflow-unset'),
                    };
                });
                this.dataLoaded = true;
            }).catch(err => {
                console.log(err);
                this.dataLoaded = true;
            });
    }

    dateString(epoch: number): string {
        return new Date(epoch * 1000).toLocaleString();
    }

    createTableData(value: string, className?: string, type?: TableDataTypes, flag = false, hoverData = null) {
        return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
        });
    }

    changeInHeader(header: string) {
        this.toggleHeaders.emit(header);
    }

    onPaginationChange(event: { pageNo: number, perPage: number; }) {
        this.getSubscriptions((event.pageNo + 1), event.perPage);
    }
}
