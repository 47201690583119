/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mapped-captains.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@netbasal/ngx-content-loader/netbasal-ngx-content-loader.ngfactory";
import * as i3 from "@netbasal/ngx-content-loader";
import * as i4 from "primeng/components/tooltip/tooltip";
import * as i5 from "@angular/common";
import * as i6 from "./mapped-captains.component";
import * as i7 from "./mapped-captains.service";
var styles_MappedCaptainsComponent = [i0.styles];
var RenderType_MappedCaptainsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MappedCaptainsComponent, data: {} });
export { RenderType_MappedCaptainsComponent as RenderType_MappedCaptainsComponent };
function View_MappedCaptainsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["style", "height: 125px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(4, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "bullet-list-content-loader", [], null, null, null, i2.View_BulletListContentLoaderComponent_0, i2.RenderType_BulletListContentLoaderComponent)), i1.ɵdid(7, 114688, null, 0, i3.BulletListContentLoaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 7, 0); }, null); }
function View_MappedCaptainsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mapped-rider-number"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["tooltipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i4.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), (_l()(), i1.ɵted(3, null, [" ", ". ", " "]))], function (_ck, _v) { var currVal_0 = "bottom"; var currVal_1 = i1.ɵinlineInterpolate(3, "Name : ", (_v.context.$implicit.name || "N/A"), " ", "\n", " mobile : ", (_v.context.$implicit.mobile || "N/A"), ""); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = (_v.context.index + 1); var currVal_3 = _v.context.$implicit._id; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
function View_MappedCaptainsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "height-80 overflow-y"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MappedCaptainsComponent_3)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mappedRiderId; _ck(_v, 3, 0, currVal_0); }, null); }
function View_MappedCaptainsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "min-height-98 center-items is-flex"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No data found ¯\\_(ツ)_/¯ "]))], null, null); }
export function View_MappedCaptainsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MappedCaptainsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "mapped-captains"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "order-title margin-10-24"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mapped captain"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "line margin-top-10"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MappedCaptainsComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MappedCaptainsComponent_4)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.dataLoaded; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.dataLoaded && _co.dataFetched); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.dataLoaded && !_co.dataFetched); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_MappedCaptainsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mapped-captains", [], null, null, null, View_MappedCaptainsComponent_0, RenderType_MappedCaptainsComponent)), i1.ɵdid(1, 638976, null, 0, i6.MappedCaptainsComponent, [i7.RiderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MappedCaptainsComponentNgFactory = i1.ɵccf("app-mapped-captains", i6.MappedCaptainsComponent, View_MappedCaptainsComponent_Host_0, { mapRiderDetails: "mapRiderDetails" }, {}, []);
export { MappedCaptainsComponentNgFactory as MappedCaptainsComponentNgFactory };
