import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CaptainAdjustmentService  {
    server = environment.server;
    orderBaseUrl = this.server + '/api/orders';

    captainAdjustmentDetails(orderId, adjustmentObj) {
        const requestBody = {
            adjustmentObj: adjustmentObj
        };
        return this.http.post(this.orderBaseUrl + '/' + orderId + '/captainRemarks', requestBody).pipe(catchError(this.handleError));
    }

    captainRefundDetails(orderId, adjustmentObj, role) {
        const requestBody = {
            adjustmentObj: adjustmentObj,
            role: role
        };
        return this.http.post(this.orderBaseUrl + '/' + orderId + '/refund', requestBody).pipe(catchError(this.handleError));
    }

    setUserDistance(orderId, updateKmObj) {
      const requestBody = {
        updateKmObj: updateKmObj
      };
      return this.http.post(this.orderBaseUrl + '/' + orderId + '/updateDistance', requestBody).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(error);
    }
    constructor(private http: MyHttpClient) { }
  }
