import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-capt-order-details',
  templateUrl: './capt-order-details.component.html',
  styleUrls: ['./capt-order-details.component.css']
})
export class CaptOrderDetailsComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() captDetails: any;
  public info: any;
  public data: any;
  today = this.formatDate(new Date());
  public subscription: any = {name: '', desc: ''};
  public walletSnapshot: any = {name: '', amount: ''};


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.captDetails && changes.captDetails.currentValue) {
      if (!changes.captDetails.currentValue.dataFetched ||
        !(changes.captDetails.currentValue.captain && changes.captDetails.currentValue.captainId)) {
        this.emptyCard();
      }
    }
    this.constructCard()
  }

  emptyCard() {
    this.info = {
      header: 'Captain Details',
      dataFetched: false,
      data: null
    };
  }

  constructCard() {
    if (this.captDetails) {
      this.data = this.captDetails.captain;
      const tl = this.captDetails.tl;
      let gender = 'Male';
      if (this.data.gender) {
        if (this.data.gender === '1') {
          gender = 'Female';
        } else if (this.data.gender === '2') {
          gender = 'Others';
        }
      }
      this.info = {
        header: 'Captain Details',
        headerClass: 'color-card-header',
        dataFetched: true,
        data: [
          {header: 'Name', headerClass: 'header-card-color', value: this.data.name ? this.data.name : '',
          redirectClass : 'redirect', route : this.data.mobile ? this.data.mobile : '', type : 'captain'},
          {header: 'Phone', headerClass: 'header-card-color', value: this.data.mobile ? this.data.mobile : ''},
          {header: 'Email', headerClass: 'header-card-color', value: this.data.email ? this.data.email : ''},
          {header: 'Gender', headerClass: 'header-card-color', value: gender},
          {header: 'Shift', headerClass: 'header-card-color', value: this.data.shift ? this.data.shift : ''},
          {header: 'Micro Corridor', headerClass: 'header-card-color', value: this.data.microCorridor ? 'Active' : 'Inactive'}
        ]
      };
    }
  }

  formatDate(date) {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-0' + monthIndex + '-0' + day;
  }
}
