import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { isEmpty } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { PrevDueDialogComponent } from 'src/app/prevdue-dialog/prevdue-dialog.component';
import { debug } from 'util';
import { CustomerDetailsService } from '../customer-details.service';

@Component({
  selector: 'app-customer-wallet',
  templateUrl: './customer-wallet.component.html',
  styleUrls: ['./customer-wallet.component.css']
})
export class CustomerWalletComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() prevDueData: any;

  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  public info: any;
  
  constructor(public dialog: MatDialog,
    private customerDetailService: CustomerDetailsService) { 
    
  }
  ngOnChanges(changes: SimpleChanges) {
    
    if (changes.data && changes.data.currentValue && changes.data.currentValue.dataFetched === false) {
      this.blankCard();
    } else if (changes.data && changes.data.currentValue && changes.data.currentValue.info && changes.data.currentValue.info.status &&
      changes.data.currentValue.info.status === 'FAILURE') {
        this.emptyCard();
    } else if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.constructCard();
    } else if (changes.prevDueData) {
      // do nothing
    } else {
      this.emptyCard();
    }
  }

  ngOnInit() {
  }

  refreshWallets() {
    this.refresh.emit();
  }

  blankCard() {
    this.info = {
      header: 'Wallets',
      data: [],
      emptyMessage: 'Unable to fetch data. Please try again.',
      dataFetched: false,
      reload: false
    };
  }

  emptyCard() {
    this.info = {
      header: 'Wallets',
      data: null,
      dataFetched: false,
      reload: false
    };
  }

  constructCard() {
  
    if (this.data && this.data.info && this.data.info.statusCode && this.data.info.statusCode === 200) {
      const data = this.data.response;
      const balances = [];
      for (const balance in data) {
        if (data[balance]['status'] === 'success') {
          const wallet = data[balance]['type'];
          const money = JSON.stringify(parseFloat(data[balance]['rapidoWalletBalance']));
          balances[wallet] = money;
        }
      }
      this.info = {
        header: 'Wallets',
        reload: true,
        dataFetched: true,
        data: [
          {header: 'Rapido Wallet',   informationPopup: false, value: balances['rapido'] ? '₹' + balances['rapido'] : 'Unavailable'},
          {header: 'Coins',   informationPopup : false, value : this.data.coinBalance ? this.data.coinBalance : 'Unavailable'},
          {header: 'Paytm',   informationPopup: false, value: balances['paytm'] ? '₹' + balances['paytm'] : 'Unavailable'},
          {header: 'Freecharge',   informationPopup: false, value: balances['freeCharge'] ? '₹' + balances['freeCharge'] : 'Unavailable'},
          {header: 'Simpl',   informationPopup: false, value: balances['simpl'] ? '₹' + balances['simpl'] : 'Unavailable'},
          {header: 'Lazypay',   informationPopup: false, value: balances['lazypay'] ? '₹' + balances['lazypay'] : 'Unavailable'},
          {header: 'Previous Due',   informationPopup : true, value : this.data.outstandingBalance ? this.data.outstandingBalance : 'Unavailable'}
        ]
      };
    }
  }

  popupClick (event) {
    this.dialog.open(PrevDueDialogComponent, {
      width: '535px',
      data: {
        title: `Previous Due Details`,
        prevDueTransactons: this.prevDueData.response.outstandingBalanceTransactions
      },
    });
  }
}
