/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-wallet.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../card-table/card-table.component.ngfactory";
import * as i3 from "../../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./customer-wallet.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../customer-details.service";
var styles_CustomerWalletComponent = [i0.styles];
var RenderType_CustomerWalletComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerWalletComponent, data: {} });
export { RenderType_CustomerWalletComponent as RenderType_CustomerWalletComponent };
export function View_CustomerWalletComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, [[null, "refresh"], [null, "popupEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refresh" === en)) {
        var pd_0 = (_co.refreshWallets() !== false);
        ad = (pd_0 && ad);
    } if (("popupEvent" === en)) {
        var pd_1 = (_co.popupClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, { refresh: "refresh", popupEvent: "popupEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerWalletComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-wallet", [], null, null, null, View_CustomerWalletComponent_0, RenderType_CustomerWalletComponent)), i1.ɵdid(1, 638976, null, 0, i5.CustomerWalletComponent, [i6.MatDialog, i7.CustomerDetailsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerWalletComponentNgFactory = i1.ɵccf("app-customer-wallet", i5.CustomerWalletComponent, View_CustomerWalletComponent_Host_0, { data: "data", prevDueData: "prevDueData" }, { refresh: "refresh" }, []);
export { CustomerWalletComponentNgFactory as CustomerWalletComponentNgFactory };
