import { Injectable } from '@angular/core';
declare var $;

@Injectable()
export class LoaderService {
  loading = false;
  constructor() { }
  private openCount = 0;
  openLoading(message = '') {
    // console.log(arguments.callee);
    this.openCount++;
    this.loading = true;
    // $('#loadMe').modal({
    //   backdrop: 'static', //remove ability to close modal with click
    //   keyboard: false, //remove option to close with keyboard
    //   show: true //Display loader!
    // });
  }

  closeLoading() {
    // console.log(arguments.callee);
    if (this.openCount > 0) {
      this.openCount--;
    }
    if (this.openCount === 0) {
      this.loading = false;
    }
    // $('#loadMe').modal('hide');
  }
  forceClose() {
    this.openCount = 0;
    this.loading = false;
  }
}
