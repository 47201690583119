import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerDetailsService } from 'src/app/customer-details/customer-details.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { DataService } from '../../data.service';
import { OrderDetailsService } from 'src/app/order-details/order-details.service';
import { EntityService } from 'src/app/entity.service';
import { SingleSearchableDropdown } from 'src/app/filter.service';
const SEARCH_BY = {
  ORDER_ID: 'orderId',
  MOBILE: 'mobile',
  EMAIL: 'email',
};

const PLACEHOLDER = {
  [SEARCH_BY.MOBILE]: 'Search by Customer number',
  [SEARCH_BY.ORDER_ID]: 'Search by Order Id',
  [SEARCH_BY.EMAIL]: 'Search by Customer email',
}

@Component({
  selector: 'app-customer-search',
  templateUrl: './customer-search.component.html',
  styleUrls: ['./customer-search.component.css']
})
export class CustomerSearchComponent implements OnInit {
  mobile = '';
  public enteredNumber = false;
  public focus = false;
  public SEARCH_BY = SEARCH_BY;

  private _searchBy = SEARCH_BY.MOBILE;
  public set searchBy(value) {
    this._searchBy = value;
    this.placeholder = PLACEHOLDER[value];
  }

  public get searchBy() {
    return this._searchBy;
  }
  public searchKeyword = '';
  public placeholder = PLACEHOLDER[this.searchBy];
  public filterTypeSelect = new SingleSearchableDropdown({
    key: 'value',
    title: '',
    values: [
      SEARCH_BY.MOBILE,
      SEARCH_BY.EMAIL,
      SEARCH_BY.ORDER_ID,
    ],
    default: SEARCH_BY.MOBILE,
    onChange: (event) => {
      console.log(event);
      const filterType = event.value;
      this.searchBy = filterType;
    }
  })
  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private customerSerivce: CustomerDetailsService,
    private toasterService: ToasterService,
    private dataservice: DataService,
    private orderDetailService: OrderDetailsService,
    private entityService: EntityService,
  ) { }

  ngOnInit() {
    this.mobile = this.activedRoute.snapshot.paramMap.get('id');
    this.activedRoute.parent.params.subscribe(params => {
    });
  }

  async searchByOrderId(orderId: string) {
    let orderDetails;
    try {
      orderDetails = await this.orderDetailService.fetchOrderDetails(orderId).toPromise();
    } catch(err) {
      console.log(err);
      return this.notFoundError();
    }
    try {
      this.router.navigate([`/order/${orderId}`]);
      // const customerMobile = orderDetails.data.order.customerObj.mobile;
      // if (!customerMobile) {
      //   throw "Customer Not found";
      // }
      // this.mobile = customerMobile;
    } catch(err) {
      return this.notFoundError();
    }
    // this.searchMobileNumber();
  }

  async searchByEmail(email: string) {
    try {

      const { data: user }: any = await this.entityService.getUserByEmail(email).toPromise();
      const mobile = user.mobile;
      if (!mobile) {
        return this.notFoundError();
      }
      this.mobile = mobile;
      this.searchMobileNumber();
    } catch(err) {
      return this.notFoundError();
    }
  }

  async searchCustomer() {
    switch(this.searchBy) {
      case SEARCH_BY.ORDER_ID: {
        if (!this.isValidOrderId(this.searchKeyword)) {
          return;
        }
        await this.searchByOrderId(this.searchKeyword);
        break;
      }
      case SEARCH_BY.EMAIL: {
        if (!this.isValidEmail(this.searchKeyword)) {
          return;
        }
        await this.searchByEmail(this.searchKeyword);
        break;
      }
      case SEARCH_BY.MOBILE: {
        if (!this.isValidMobileNumber(this.searchKeyword)) {
          return;
        }
        this.mobile = this.searchKeyword;
        this.searchMobileNumber();
      };
    }
  }

  private notFoundError() {
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: `Not Found`,
    }));
  }

  private isValidMobileNumber(mobile) {
    const testMob = new RegExp('[0-9]{10}');
    if (!testMob.test(mobile)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter a valid 10-digit mobile number`,
      }));
      return false;
    }
    return true;
  }

  private isValidEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)) {
      return true
    }
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: `Please enter a valid Email Id`,
    }));
    return false
    // const testMob = new RegExp('[0-9]{10}');
    // if (!testMob.test(mobile)) {
    //   this.toasterService.showToaster(new Toaster({
    //     type: ToasterType.WARNING,
    //     message: `Please enter a valid 10-digit mobile number`,
    //   }));
    //   return false;
    // }
    // return true;
  }

  private isValidOrderId(orderId) {
    if (/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/.test(orderId)) {
      return true
    }
    this.toasterService.showToaster(new Toaster({
      type: ToasterType.WARNING,
      message: `Please enter a valid Order Id`,
    }));
    return false
  }

  searchMobileNumber() {
    this.customerSerivce.getCustomerByMobile(this.mobile)
    .subscribe(data => {
      this.dataservice.updateSearchCount(this.mobile);
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.SUCCESS,
        message: `User found`,
      }));
      this.router.navigate([`/customer/${this.mobile}`]);
      this.mobile = '';
      document.getElementById('searchCustomer').blur();
    }, err => {
      console.error(err);
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to fetch this user, Please check the number again`,
        config: {
        }
      }));
    });
  }

}
