
<div class="row width-100 padding-bottom-15">
  <div class="col-md-6 ui-fluid">
    <mat-label>From Date</mat-label><br>
    <!-- <mat-form-field  style="
    width: 136.4px;
" appearance="outline">
      <input matInput [(ngModel)]="fromDate" (ngModelChange)="changeInDate()" [max]="maxDate" [matDatepicker]="pickerFrom" (click)="pickerFrom.open()" (focus)="pickerFrom.open()">
      <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
      <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field> -->
    <p-calendar styleClass="width-85" [(ngModel)]="fromDate" icon="pi pi-calendar" [showIcon]=true dateFormat="m/d/yy" (ngModelChange)="changeInDate()" [maxDate]="maxDate"></p-calendar>
  </div>

  <div class="col-md-6 ui-fluid">
    <mat-label>To Date</mat-label><br>
    <!-- <mat-form-field style="
    width: 136.4px;
"  appearance="outline">
      <input matInput [(ngModel)]="toDate" (ngModelChange)="changeInDate()" [min]="minDate" [matDatepicker]="pickerTo" (click)="pickerTo.open()" (focus)="pickerTo.open()">
      <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
      <mat-datepicker #pickerTo></mat-datepicker>
    </mat-form-field> -->
    <p-calendar [(ngModel)]="toDate" icon="pi pi-calendar" [showIcon]="true" (ngModelChange)="changeInDate()" [minDate]="minDate"></p-calendar>
  </div>
</div>
