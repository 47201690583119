import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'quick-filters',
  templateUrl: './quick-filters.component.html',
  styleUrls: ['./quick-filters.component.css'],
  providers: [DatePipe]
})
export class QuickFiltersComponent {

  // @Input() quickFilters: any;
  @Input() calendarData: any;
  @Input() startDate: any;
  @Input() endDate: any;

  @Output() setQuickFilters: EventEmitter < any > = new EventEmitter();
 
  public quickFilters = [
    'Past 30 days',
    'M0',
    'M-1',
    'Last Week',
    'Today'
  ];

  public selectedFilter = -1;
  constructor(private datePipe: DatePipe) { }

  formatDateForBody(date: any) {
    if (!date) {
      return null;
    }
    date = new Date(date);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    if (monthIndex < 10) {
      monthIndex = '0' + monthIndex;
    }
    if (day < 10) {
      day = '0' + day;
    }
    const year = date.getFullYear();
    return year + '-' + monthIndex + '-' + day;
  }

  setEmitSelections = () => {
    const emitSelections = {
      [this.calendarData.key]: [this.startDate, this.endDate]
    };

    this.setQuickFilters.emit(emitSelections);
  }

  getCurrentMonthDateRanges = () => {
    const todayDate: any = new Date();
    const firstDay: any = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);

    this.startDate = this.formatDateForBody(firstDay);
    this.endDate = this.formatDateForBody(todayDate);

    console.log(this.startDate, this.endDate);

    this.setEmitSelections();
  }

  getSelectedDateRanges(data) {
    const key = Object.keys(data)[0];
    const duration = data[key];
    const [sendStartDate, sendEndDate] = duration;

    this.startDate = this.formatDateForBody(sendStartDate);
    this.endDate = this.formatDateForBody(sendEndDate);

    this.setEmitSelections();
  }

  getLastMonthDateRanges = () => {
    const todayDate: any = new Date();
    const firstDay: any = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);
    const lastDay: any = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0)

    this.startDate = this.formatDateForBody(firstDay);
    this.endDate = this.formatDateForBody(lastDay);

    console.log(this.startDate, this.endDate);


    this.setEmitSelections();
  }

  getLastThirtyDaysDateRanges = () => {
    const todayDate: any = new Date();
    const firstDay: any = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 31);


    this.startDate = this.formatDateForBody(firstDay);
    this.endDate = this.formatDateForBody(todayDate);

    this.setEmitSelections();
  }

  getLastWeekDateRanges = () => {
    const todayDate: any = new Date();
    const startDate: any = new Date();

    startDate.setDate(todayDate.getDate() - 7);
    const startDateFormat = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    this.startDate = this.formatDateForBody(startDateFormat);
    this.endDate = this.formatDateForBody(todayDate);

    console.log(this.startDate, this.endDate);

    this.setEmitSelections();
  }

  getTodayDateRanges = () => {
    const todayDate = new Date();

    this.startDate = this.formatDateForBody(todayDate);
    this.endDate = this.formatDateForBody(todayDate);

    console.log(this.startDate, this.endDate);


    this.setEmitSelections();
  }

  getDateRanges = (filter: any) => {
    switch (filter) {
      case 'Past 30 days':
        this.getLastThirtyDaysDateRanges();
        break;
      case 'M0':
        this.getCurrentMonthDateRanges();
        break;
      case 'M-1':
        this.getLastMonthDateRanges();
        break;
      
      case 'Last Week':
        this.getLastWeekDateRanges();
        break;

      case 'Today':
        this.getTodayDateRanges();
        break;
    }
  }

    onFilterClick = (event: any, selectedFilterIndex: any, filter: any) => {
    this.selectedFilter = selectedFilterIndex;
    this.getDateRanges(filter);
  }

}
