import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
const currentDate = new Date();
export const DEFAULT_DATE = {
  FROM_DATE: new Date(new Date(startDate).setDate(new Date(currentDate).getDate() - 1)),
  TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};


@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [DatePipe]
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() calendarData: any;
  @Output() selectedDates: EventEmitter<any> = new EventEmitter();
  @Input() public fromDate: Date;
  @Input() public toDate: Date;
  public maxDate: Date;
  public minDate: Date;

  public selectedValues: any;
  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    if (this.fromDate || this.toDate) {
      this.fromDate = new Date(this.fromDate);
      this.toDate = new Date(this.toDate);
      this.changeInDate();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.fromDate && this.fromDate) {
      this.fromDate = new Date(this.fromDate);
    }
    if (changes.toDate && this.toDate) {
      this.toDate = new Date(this.toDate);
    }
  }

  changeInDate() {
    if (this.fromDate) {
      this.minDate = this.fromDate;
    }
    if (this.toDate) {
      this.maxDate = this.toDate;
    }
    const fromDate = this.fromDate ? new Date(this.fromDate) : DEFAULT_DATE.FROM_DATE;
    const toDate = this.toDate ? new Date(this.toDate) : new Date();

    // console.log('dates : : : : ', this.fromDate, this.toDate);
    const sendStartDate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    const sendEndDate = this.datePipe.transform(toDate, 'yyyy-MM-dd');

    const emitSelections = {
      [this.calendarData.key] : [sendStartDate, sendEndDate]
    };
    // console.log('Emitted vals : ', emitSelections);
    this.selectedDates.emit(emitSelections);
  }

}
