import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FareEstimateLogService } from './fare-estimate-log.service';
import { EntityService } from '../entity.service';
import { first } from 'rxjs-compat/operator/first';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-fare-estimate-log',
  templateUrl: './fare-estimate-log.component.html',
  styleUrls: ['./fare-estimate-log.component.css']
})
export class FareEstimateLogComponent implements OnInit, OnChanges {
  @Input() fareEstimateLogs: any;
  @Input() pageRefreshed: any;
  dataRows = [];
  tableData: any;
  servicesMap = {};

  constructor(
    public FareEstimateLogService: FareEstimateLogService,
    public EntityService: EntityService
  ) { }

  toggleState(status) {
    if (status === 'sl') {
      this.tableData = {...this.tableData, data: this.dataRows};
    } else if (status === 'vm') {
      this.tableData = {...this.tableData, data: [this.dataRows[0]]};
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.pageRefreshed && changes.pageRefreshed.currentValue) {
      this.dataRows = [];
    }
    if (changes && changes.fareEstimateLogs && changes.fareEstimateLogs.currentValue) {
      if (changes.fareEstimateLogs.currentValue.deliveryOrder) {
        this.emptyCard('Not available for Delivery orders');
      } else if (changes.fareEstimateLogs.currentValue.dataFetched) {
        this.constructCard();
      } else {
        this.emptyCard();
      }
    }
  }

  ngOnInit(){}

  constructCard() {
    if (this.fareEstimateLogs && this.fareEstimateLogs['fareEstimateLogs'] && this.fareEstimateLogs['fareEstimateLogs'].length > 0) {
      const firstFive = this.fareEstimateLogs['fareEstimateLogs'].slice(0, 5);
      const mapsUrl = 'https://www.google.com/maps/?q=';
      for (const log in firstFive) {
        if (firstFive[log]) {
          const data = firstFive;
          let recalculation = '';
          if (data[log].recalculationReason) {
            recalculation += 'Recalculation Reason : ' + data[log].recalculationReason + '\n';
          }
          if (data[log].locationProvider) {
            recalculation += 'Source : ' + data[log].locationProvider;
          }
          let offerInfo;
          if (data[log].offerId && data[log].offerType != "subscription") {
            let requests = [this.FareEstimateLogService.fetchLocationOffer(data[log].offerId),
            this.FareEstimateLogService.fetchRideOffer(data[log].offerId)]
            if (Object.keys(this.servicesMap).length == 0)
              requests.push(this.EntityService.getAllServices())
            combineLatest(requests, (locationOffer, rideOffer, serviceMap) => ({ locationOffer, rideOffer, serviceMap }))
              .subscribe(results => {
                this.servicesMap = { ...this.servicesMap, ...results.serviceMap }
                let result = results.locationOffer || results.rideOffer;
                if(result){
                  offerInfo = this.offerTooltipData(result);
                  this.constructFareEstimateLog(data[log], recalculation, offerInfo);
                }
                else{
                  this.constructFareEstimateLog(data[log], recalculation, offerInfo);
                }
                
              });
          } else {
            this.constructFareEstimateLog(data[log], recalculation, offerInfo)
          }
        }
      }
    }
  }

  offerTooltipData(result) {
    let offerInfo = '<div>' +
      '<div class="left-align"><b>Name : </b><span>' + result['name']+ '</span></div>' +
      '<div class="left-align"><b>Description : </b><span>' + result['description'] + '</span></div>' +
      '<div class="left-align"><b>Offer Text : </b><span>' + result['offerText'] + '</span></div>'

    offerInfo += '<table><tr><th class="padding-sides-5">Expression</th><th class="padding-sides-5">Condition</th><th class="padding-sides-5">Limit</th></tr>'
    if(result['expressions']){
      result['expressions'].forEach((expression) => {
        offerInfo += '<tr><td>' + expression['expression'] + '</td><td>' + expression['condition'] + '</td><td>' + expression['maxLimit'] + '</td></tr>'
      })
    }
    offerInfo += '</table>'
    if(result['tnc']){
      offerInfo += '<div class="left-align"><b>Terms And Conditions : </b><span>' + result['tnc'].join(', ') + '</span></div>'
    }
    if(result['city']){
      offerInfo += '<div class="left-align"><b>City : </b><span>' + result['city'].join(', ') + '</span></div>'
    }
    let services = []
    if(result['services']){
      result['services'].map(service => services.push(this.servicesMap[service].name))
      offerInfo += '<div class="left-align"><b>Services : </b><span>' + services.join(', ') + '</span></div>';
    }
    if (Array.isArray(result['days']))
      offerInfo += '<div class="left-align"><b>Days: </b><span>' + result['days'].join(', ') + '</span></div>';
    if(result['timings'] && result['timings'][0] && result['timings'][0]['to'] && result['timings'][0]['from'])
      offerInfo += '<div class="left-align"><b>From: </b><span>' + result['timings'][0]['from'] + '</span><b> To : </b><span>' + result['timings'][0]['to'] + '</span></div>';
    '</div>'

    return offerInfo
  }

  constructFareEstimateLog(dataLog, recalculation, offerInfo) {
    const mapsUrl = 'https://www.google.com/maps/?q=';
    const pickup = dataLog.pickupLocation && dataLog.pickupLocation.name ? dataLog.pickupLocation.name :
      (dataLog.pickupLocation && dataLog.pickupLocation.lat && dataLog.pickupLocation.lng ?
        dataLog.pickupLocation.lat + ', ' + dataLog.pickupLocation.lng : '');
    const drop = dataLog.dropLocation && dataLog.dropLocation.name ? dataLog.dropLocation.name :
      (dataLog.dropLocation && dataLog.dropLocation.lat && dataLog.dropLocation.lng ?
        dataLog.dropLocation.lat + ', ' + dataLog.dropLocation.lng : '');
    const pickUpUrl = dataLog.pickupLocation && dataLog.pickupLocation.lat && dataLog.pickupLocation.lng ?
      mapsUrl + dataLog.pickupLocation.lat + ',' + dataLog.pickupLocation.lng : '';
    const dropUrl = dataLog.dropLocation && dataLog.dropLocation.lat && dataLog.dropLocation.lng ?
      mapsUrl + dataLog.dropLocation.lat + ',' + dataLog.dropLocation.lng : '';
    this.dataRows.push({
      timeStamp: { data: dataLog.createdAt ? this.getTimeStamp(dataLog.createdAt) : '' },
      pickup: { data: pickup, redirectLink: pickUpUrl, className: pickUpUrl ? 'web-link' : '' },
      drop: { data: drop, redirectLink: dropUrl, className: dropUrl ? 'web-link' : '' },
      offerType: { data: dataLog.offerType ? dataLog.offerType : '' },
      offerCode: { data: dataLog.offerCode ? dataLog.offerCode : '', information: offerInfo ? offerInfo : null },
      estDistance: {
        data: dataLog.direction && !this.isEmpty(dataLog.direction.distanceInKms) ?
        dataLog.direction.distanceInKms + ' kms' : ''
      },
      estRideTime: { data: dataLog.direction ? dataLog.direction.timeInMins + ' mins' : '' },
      estFare: {
        data: !this.isEmpty(dataLog.amount) ? '₹ ' + dataLog.amount.toFixed(2).toString() : '',
        information: recalculation && recalculation.length ? recalculation : null
      },
    });

    this.tableData = {
      tableHeader: 'Fare Estimate log',
      expandTable: this.dataRows.length > 1 ? true : false,
      headers: {
        timeStamp: 'Timestamp', pickup: 'Pickup', drop: 'Drop', offerType: 'Offer Type', offerCode: 'Offer Code',
        estDistance: 'Est. Distance', estRideTime: 'Est. Ride Time', estFare: 'Est. Fare'
      },
      data: [this.dataRows[0]],
      title: ['pickup', 'drop'],
      tableHeight: 'max-height-275',
      dataFetched: true
    };
  }

  emptyCard(message?) {
    this.tableData = {
      tableHeader: 'Fare Estimate log',
      data: [],
      dataFetched: false,
      emptyMessage: message || 'No data found'
    };
  }

  getTimeStamp(date) {
    const timeInfo = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric'});
    const today = new Date(date);
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    const dateInfo = dd + '-' + mm + '-' + yyyy;
    return (dateInfo + '\n' + timeInfo);
  }

  isEmpty(value) {
    return (value == null || value.length === 0);
  }
}
