import { Component, OnInit, Input } from '@angular/core';
import { PerformanceCard } from '../types/card.types';
const HIKE_CLASS = {
  NEGATIVE: 'text-right text-red',
  POSITIVE:  'text-right text-green',
};

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})

export class CardComponent implements OnInit {
  @Input() card: PerformanceCard;
  hikeClass: string;
  cardStyle: any;
  constructor() { }

  ngOnInit() {
    this.hikeClass = this.card.isPositivehike ? HIKE_CLASS.POSITIVE : HIKE_CLASS.NEGATIVE;
  }
}
