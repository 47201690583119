import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ManagerService = /** @class */ (function () {
    function ManagerService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getManager = this.server + '/api/getManagerByMobile';
    }
    ManagerService.prototype.getManagerByMobile = function (mobile) {
        return this.http
            .get(this.getManager + '/' + mobile, {
            observe: 'response',
        })
            .pipe(timeout(3000), map(function (userDetailsResponse) {
            if (userDetailsResponse &&
                userDetailsResponse.body &&
                userDetailsResponse.body.data) {
                return userDetailsResponse.body.data;
            }
            else {
                throw Error('Unable to fetch this user, Please check the number again');
            }
        }));
    };
    ManagerService.ngInjectableDef = i0.defineInjectable({ factory: function ManagerService_Factory() { return new ManagerService(i0.inject(i1.HttpClient)); }, token: ManagerService, providedIn: "root" });
    return ManagerService;
}());
export { ManagerService };
