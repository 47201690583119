import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var CustActivityHistoryService = /** @class */ (function () {
    function CustActivityHistoryService(http) {
        this.http = http;
        this.server = environment.server;
        this.zendeskApiUrl = this.server + '/api/getZendeskTickets';
    }
    CustActivityHistoryService.prototype.getZendeskTickets = function (uniqueId) {
        return this.http.get(this.zendeskApiUrl + '/' + uniqueId).pipe(catchError(this.handleError));
    };
    CustActivityHistoryService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CustActivityHistoryService.ngInjectableDef = i0.defineInjectable({ factory: function CustActivityHistoryService_Factory() { return new CustActivityHistoryService(i0.inject(i1.MyHttpClient)); }, token: CustActivityHistoryService, providedIn: "root" });
    return CustActivityHistoryService;
}());
export { CustActivityHistoryService };
