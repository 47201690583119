import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class CaptainSubscriptionsService {
    constructor(public httpClient: HttpClient) { }

    async getCaptainSubscriptions(id: string, page: number, pageSize: number): Promise<any> {
        const url = `${environment.server}/api/captain/subscriptions/${id}`;
        const result = await this.httpClient.get(url, {
            params: { page: page.toString(), pageSize: pageSize.toString() }
        }).toPromise();
        return result;
    }
}
