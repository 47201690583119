/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/form-field";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-align-centre"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-label", [["class", "font-size-15"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatLabel, [], null, null), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.message; _ck(_v, 3, 0, currVal_0); }); }
function View_ConfirmDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-align-centre"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reason for Revoking "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-label", [["class", "font-size-15"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatLabel, [], null, null), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.reason; _ck(_v, 5, 0, currVal_0); }); }
function View_ConfirmDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "font-size-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Service Group:"])), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "font-size-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suspension Reason:"])), (_l()(), i1.ɵted(8, null, [" ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "p", [["class", "font-size-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suspension Action:"])), (_l()(), i1.ɵted(12, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.serviceGroup; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.data.reason; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.data.suspensionAction; _ck(_v, 12, 0, currVal_2); }); }
function View_ConfirmDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [["class", "font-size-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Service Group:"])), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [["class", "font-size-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suspension Reason:"])), (_l()(), i1.ɵted(8, null, [" ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "p", [["class", "font-size-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Suspension Duration:"])), (_l()(), i1.ɵted(12, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.serviceGroup; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.data.reason; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.data.suspensionDuration; _ck(_v, 12, 0, currVal_2); }); }
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h1", [["class", "dialog-title text-align-centre mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { color: 0 }), i1.ɵdid(3, 81920, null, 0, i4.MatDialogTitle, [[2, i4.MatDialogRef], i1.ElementRef, i4.MatDialog], null, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_4)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 7, "div", [["class", "button-flex padding-17-0  mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i4.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "dialog-cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["class", "font-size-13 font-weight-600 border-radius-3 color-ffffff confirm ml-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.abortOrder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(19, { "background-color": 0, "border": 1 }), (_l()(), i1.ɵted(20, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (((_co.data.reason === "abort") || (_co.data.reason === "Debit")) ? "#cd1f1e" : "#d88b0a")); _ck(_v, 1, 0, currVal_1); _ck(_v, 3, 0); var currVal_3 = (_co.data.type != "revoke"); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.data.type == "revoke"); _ck(_v, 8, 0, currVal_4); var currVal_5 = (_co.data.type == "p0Suspension"); _ck(_v, 10, 0, currVal_5); var currVal_6 = (_co.data.type == "nonP0Suspension"); _ck(_v, 12, 0, currVal_6); var currVal_7 = _ck(_v, 19, 0, (((_co.data.reason === "abort") || (_co.data.reason === "Debit")) ? "#d0021b" : (((_co.data.type === "p0Suspension") || (_co.data.type === "nonP0Suspension")) ? "#467fcf" : "#64b20b")), (((_co.data.reason === "abort") || (_co.data.reason === "Debit")) ? "1px solid #d0021b" : (((_co.data.type === "p0Suspension") || (_co.data.type === "nonP0Suspension")) ? "#467fcf" : "solid 1px #64b20b"))); _ck(_v, 18, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).id; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.data.title; _ck(_v, 4, 0, currVal_2); var currVal_8 = _co.data.buttonText; _ck(_v, 20, 0, currVal_8); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.ConfirmDialogComponent, [i4.MatDialogRef, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("app-confirm-dialog", i5.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
