/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pagination.component";
var styles_PaginationComponent = [i0.styles];
var RenderType_PaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
export function View_PaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "nav", [["aria-label", "..."]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "ul", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "li", [["class", "page-item disabled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "page-link"], ["href", "#"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Previous"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "li", [["class", "page-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "page-link"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["1"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "li", [["class", "page-item active"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "a", [["class", "page-link"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["2 "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["(current)"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "li", [["class", "page-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "a", [["class", "page-link"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["3"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "li", [["class", "page-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "a", [["class", "page-link"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next"]))], null, null); }
export function View_PaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i1.ɵccf("app-pagination", i2.PaginationComponent, View_PaginationComponent_Host_0, { pagination: "pagination" }, {}, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
