import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css']
})
export class OrderStatusComponent implements OnInit, OnChanges {

  @Input() statusDetails: any;
  @Input() statusMap: any;
  @Input() FNDcase: any;
  orderId: any;
  public statusRed = ['Customer cancelled', 'Captain rejected', 'Expired', 'Aborted', 'Captain cancelled'];
  public statusMarkReady = ['predictedShipmentReadyTimeInEpoch','shipmentReadyTimeInEpoch'];
  public status = [
    'requested', 'new', 'onTheWay', 'arrived', 'started', 'dropped', 'expired', 'captain_cancelled',
    'customerCancelled', 'captain_switch', 'reached','shipmentReadyTimeInEpoch',
    'aborted', 'predictedShipmentReadyTimeInEpoch'
  ];
  public orderTimeLine: any;
  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.orderId = this.route.snapshot.params.id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.statusDetails && changes.statusDetails.currentValue) {

      this.orderTimeLine = changes.statusDetails.currentValue;
      for (let index = 0; index < this.orderTimeLine.length; index++) {
        this.orderTimeLine[index].statusModified = this.statusMap[this.orderTimeLine[index].eventName];
        if (this.status.indexOf(this.orderTimeLine[index].status) === -1) {
          this.orderTimeLine.splice(index, 1);
          index--;
          continue;
        }
      }
    }
  }

  modifyTime() {
    if (this.orderTimeLine) {
      for (let index = 0; index < this.orderTimeLine.length; index++) {
        this.orderTimeLine[index].updatedAt = this.getProperDate(this.orderTimeLine[index].updatedAt);
      }
      for (let index = 0; index < this.orderTimeLine.length; index++) {
        if (this.status.indexOf(this.orderTimeLine[index].status) === -1) {
          this.orderTimeLine.splice(index, 1);
          continue;
        }
      }
    }
  }

  compare( a, b ) {
    if ( a.updatedAt < b.updatedAt) {
      return -1;
    }
    if ( a.updatedAt > b.updatedAt || a.updatedAt === b.updatedAt ) {
      return 1;
    }
    return 0;
  }

  getProperDate(timeStamp) {
    const timeInfo = new Date(timeStamp).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric',
                      second: 'numeric' });
    const today = new Date(timeStamp);
    let dd: any = today.getDate();
    const mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear().toString().substr(-2);

    if (dd < 10) {
      dd = '0' + dd;
    }
    const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
    return (dateInfo + '\n' + timeInfo);
  }

  getCancelB2BText(cancelledOrder) {
    return `${cancelledOrder.message} - ${new Date(cancelledOrder.updatedAt).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}`;
  }

  dropChanged(updateType) {
    return updateType == 'dropLocation' ? 'Drop Changed' : null;
  }

  getTooltipData(log) {
    const htmlString =
    `
      <span class="logs-header">Updated By :</span>
      <span class="logs-value">${log.updatedBy.emailId}</span>
      <span class="logs-header">Reason :</span>
      <span class="logs-value">${log.updatedBy.reason}</span>
    `;
    return htmlString;
  }

}
