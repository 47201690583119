var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
var AdjustmentHistoryComponent = /** @class */ (function () {
    function AdjustmentHistoryComponent() {
        this.custDetails = {};
        this.captDetails = {};
        this.captAdjTypes = ['penalty', 'ordersAdjustment', 'adjustment', 'ExtraKmFareAdjustment', 'pickupExtraKmFareAdjustment'];
        this.captSorted = false;
        this.custSorted = false;
        this.prevDuesSorted = false;
        this.custEmpty = false;
        this.captEmpty = false;
        this.prevDueEmpty = false;
        this.noData = false;
        this.dataRows = [];
        this.finalDataRows = [];
        this.monthMap = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
            7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
    }
    AdjustmentHistoryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.offlineRechargeInfo && changes.offlineRechargeInfo.currentValue) {
            this.mergeHistory();
        }
        if (changes && changes.pageRefreshed && changes.pageRefreshed.currentValue) {
            this.finalDataRows = [];
        }
        if (changes && changes.custAdjustments && changes.custAdjustments.currentValue) {
            if (changes.custAdjustments.currentValue.dataFetched) {
                var finalCust = this.custAdjustments['data'] && this.custAdjustments['data']['response'] &&
                    this.custAdjustments['data']['response']['data'] && this.custAdjustments['data']['response']['data']['list'] ?
                    this.custAdjustments['data']['response']['data']['list'] : null;
                this.custAdjustments = finalCust;
                this.finalDataRows = [];
                this.sortCustomerAdjustments();
                if (this.captAdjustments) {
                    this.sortCaptainAdjustments();
                }
                if (this.prevDuesAdjustments) {
                    this.sortPrevDueAdjustments();
                }
            }
            else {
                this.custEmpty = true;
                this.emptyCard('Cannot load adjustments');
            }
        }
        else {
            this.custSorted = true;
        }
        if (changes && changes.captAdjustments && changes.captAdjustments.currentValue) {
            if (changes.captAdjustments.currentValue.dataFetched) {
                var finalCapt = this.captAdjustments['data'] && this.captAdjustments['data']['data'] &&
                    this.captAdjustments['data']['data']['riderPaymentDetails'] ?
                    this.captAdjustments['data']['data']['riderPaymentDetails'] : null;
                this.captAdjustments = finalCapt;
                this.finalDataRows = [];
                this.sortCaptainAdjustments();
                if (this.custAdjustments) {
                    this.sortCustomerAdjustments();
                }
                if (this.prevDuesAdjustments) {
                    this.sortPrevDueAdjustments();
                }
            }
            else {
                this.captEmpty = true;
                this.emptyCard('Cannot load adjustments');
            }
        }
        else {
            this.captSorted = true;
        }
        if (changes && changes.prevDuesAdjustments && changes.prevDuesAdjustments.currentValue) {
            if (changes.prevDuesAdjustments.currentValue.dataFetched) {
                var finalPrevDue = this.prevDuesAdjustments['data'] ? this.prevDuesAdjustments['data'] : null;
                this.prevDuesAdjustments = finalPrevDue;
                this.finalDataRows = [];
                this.sortPrevDueAdjustments();
                if (this.custAdjustments) {
                    this.sortCustomerAdjustments();
                }
                if (this.captAdjustments) {
                    this.sortCaptainAdjustments();
                }
            }
            else {
                this.prevDueEmpty = true;
                this.emptyCard('Cannot load adjustments');
            }
        }
        else {
            this.prevDuesSorted = true;
        }
    };
    AdjustmentHistoryComponent.prototype.sortPrevDueAdjustments = function () {
        var _this = this;
        if (this.prevDuesAdjustments && this.prevDuesAdjustments.length > 0) {
            this.prevDuesAdjustments.forEach(function (adj) {
                _this.finalDataRows.push({
                    action: { data: adj["amount"] ? "Dues Cleared  ₹" + adj["amount"] : "", className: 'color-kermit-green font-size-13' },
                    change: { data: 'Customer' },
                    reason: { data: adj["source"] == "cancellation" ? "Cleared Cancellation Charges Dues" : adj["source"] },
                    timeStamp: { data: adj["date"], className: 'preserve-whitespace' },
                    adjustedBy: { data: adj["source"] == "cancellation" ? "chatbot@rapido.bike" : "" }
                });
            });
            this.prevDuesSorted = true;
            this.mergeHistory();
        }
        else {
            this.prevDuesSorted = true;
            this.mergeHistory();
        }
    };
    AdjustmentHistoryComponent.prototype.sortCustomerAdjustments = function () {
        if (this.custAdjustments && this.custAdjustments.length > 0) {
            for (var adj in this.custAdjustments) {
                if (this.custAdjustments[adj] && this.custAdjustments[adj]['transactionType']) {
                    var data = this.custAdjustments[adj];
                    switch (data['transactionType']) {
                        case 'debit':
                            if (data['title'] === 'adjustment') {
                                this.finalDataRows.push({
                                    action: { data: data['originalAmount'] ? 'Debit ₹' + (data['originalAmount']).toFixed(2) : '',
                                        className: 'color-rusty-red font-size-13' },
                                    change: { data: 'Customer' },
                                    reason: { data: data['remarks'] ? data['remarks'] : '' },
                                    timeStamp: { data: data['transactionDate'] ? data['transactionDate'] : '',
                                        className: 'preserve-whitespace' },
                                    adjustedBy: { data: data['actorEmail'] ? data['actorEmail'] : '' }
                                });
                            }
                            break;
                        case 'credit':
                            if (data['title'] === 'customerRefund' || data['title'] === "customerCashBack") {
                                this.finalDataRows.push({
                                    action: { data: data['originalAmount'] ? 'Refund ₹' + (data['originalAmount']).toFixed(2) : '',
                                        className: 'color-kermit-green font-size-13' },
                                    change: { data: 'Customer' },
                                    reason: { data: data['remarks'] ? data['remarks'] : '' },
                                    timeStamp: { data: data['transactionDate'] ? data['transactionDate'] : '',
                                        className: 'preserve-whitespace' },
                                    adjustedBy: { data: data['actorEmail'] ? data['actorEmail'] : '' }
                                });
                            }
                            break;
                        default: // noop
                    }
                }
            }
            this.custSorted = true;
            this.mergeHistory();
        }
        else {
            this.custSorted = true;
            this.mergeHistory();
        }
    };
    AdjustmentHistoryComponent.prototype.sortCaptainAdjustments = function () {
        if (this.captAdjustments && this.captAdjustments.length > 0) {
            for (var adj in this.captAdjustments) {
                if (this.captAdjustments[adj] && this.captAdjustments[adj]['amount'] != null &&
                    this.captAdjTypes.indexOf(this.captAdjustments[adj]['transactionType']) !== -1) {
                    var data = this.captAdjustments[adj];
                    var debit = data['amount'] < 0;
                    var time = data['createdTime'] ? data['createdTime'] : (data['createdOn'] ? data['createdOn'] : '');
                    switch (debit) {
                        case true:
                            if (this.captAdjustments[adj]['transactionType'] === 'penalty') {
                                this.finalDataRows.push({
                                    action: { data: 'Debit ₹' + (0 - data['amount']).toFixed(2),
                                        className: 'color-rusty-red font-size-13' },
                                    change: { data: 'Captain' },
                                    reason: { data: data['remarks'] ? data['remarks'] : '' },
                                    timeStamp: { data: time ? time : '', className: 'preserve-whitespace' },
                                    adjustedBy: { data: data['adjustedBy'] ? data['adjustedBy'] : '' }
                                });
                            }
                            else {
                                this.finalDataRows.push({
                                    action: { data: 'Adjusted: ' + (data['distance']).toFixed(2) + ' KM\nDebit: ₹' + (data['amount']).toFixed(2),
                                        className: 'color-rusty-red font-size-13 whiteSpacePre' },
                                    change: { data: 'Captain' },
                                    reason: { data: data['remarks'] ? data['remarks'] : '' },
                                    timeStamp: { data: time ? time : '', className: 'preserve-whitespace' },
                                    adjustedBy: { data: data['adjustedBy'] ? data['adjustedBy'] : '' }
                                });
                            }
                            break;
                        case false:
                            if (this.captAdjustments[adj]['transactionType'] === 'penalty' || this.captAdjustments[adj]['transactionType'] === 'adjustment') {
                                this.finalDataRows.push({
                                    action: { data: 'Refund ₹' + (data['amount']).toFixed(2),
                                        className: 'color-kermit-green font-size-13' },
                                    change: { data: 'Captain' },
                                    reason: { data: data['remarks'] ? data['remarks'] : '' },
                                    timeStamp: { data: time ? time : '', className: 'preserve-whitespace' },
                                    adjustedBy: { data: data['adjustedBy'] ? data['adjustedBy'] : '' }
                                });
                            }
                            else {
                                this.finalDataRows.push({
                                    action: { data: 'Adjusted: ' + (data['distance']).toFixed(2) + ' KM\nRefund: ₹' + (data['amount']).toFixed(2),
                                        className: 'color-kermit-green font-size-13 whiteSpacePre' },
                                    change: { data: 'Captain' },
                                    reason: { data: data['remarks'] ? data['remarks'] : '' },
                                    timeStamp: { data: time ? time : '', className: 'preserve-whitespace' },
                                    adjustedBy: { data: data['adjustedBy'] ? data['adjustedBy'] : '' }
                                });
                            }
                            break;
                        default: // noop
                    }
                }
            }
            this.captSorted = true;
            this.mergeHistory();
        }
        else {
            this.captSorted = true;
            this.mergeHistory();
        }
    };
    AdjustmentHistoryComponent.prototype.mergeHistory = function () {
        var _this = this;
        if (this.custSorted && this.captSorted && this.prevDuesSorted) {
            this.dataRows = [];
            this.dataRows = this.finalDataRows.sort(this.compare);
            if (this.offlineRechargeInfo && Object.keys(this.offlineRechargeInfo).length) {
                var rechargeAdjustment = Object.keys(this.offlineRechargeInfo)
                    .reduce(function (acc, key) {
                    acc[key] = {
                        data: _this.offlineRechargeInfo[key]
                    };
                    if (key === 'adjustedBy') {
                        acc[key].redirectLink = "captain/" + _this.offlineRechargeInfo[key];
                    }
                    return acc;
                }, {});
                this.dataRows.push(rechargeAdjustment);
            }
            for (var log in this.dataRows) {
                if (this.dataRows[log] && this.dataRows[log].timeStamp && this.dataRows[log].timeStamp.data) {
                    this.dataRows[log].timeStamp.data = this.getProperDate(this.dataRows[log].timeStamp.data);
                }
            }
            if (this.custSorted && this.captSorted && this.dataRows && this.dataRows.length > 0) {
                this.constructCard();
            }
            else {
                this.noData = true;
                this.emptyCard();
            }
        }
    };
    AdjustmentHistoryComponent.prototype.emptyCard = function (message) {
        if ((this.custEmpty && this.captEmpty) || this.noData) {
            this.tableData = {
                tableHeader: 'Adjustment History',
                headers: null,
                data: null,
                tableHeight: 'max-height-177',
                emptyMessage: message || 'No adjustments found',
                dataFetched: false
            };
        }
    };
    AdjustmentHistoryComponent.prototype.toggleState = function (status) {
        if (status === 'sl') {
            this.tableData = __assign({}, this.tableData, { data: this.dataRows });
        }
        else if (status === 'vm') {
            this.tableData = __assign({}, this.tableData, { data: [this.dataRows[0]] });
        }
    };
    AdjustmentHistoryComponent.prototype.constructCard = function () {
        this.tableData = {
            tableHeader: 'Adjustment History',
            headers: { action: 'Action', change: 'Change', reason: 'Reason', timeStamp: 'Timestamp', adjustedBy: 'Adjusted By' },
            data: [this.dataRows[0]],
            expandTable: this.dataRows.length > 1 ? true : false,
            tableHeight: 'max-height-177',
            dataFetched: true,
            title: ['reason', 'adjustedBy']
        };
    };
    AdjustmentHistoryComponent.prototype.compare = function (a, b) {
        if (new Date(a.timeStamp.data) > new Date(b.timeStamp.data)) {
            return -1;
        }
        if (new Date(a.timeStamp.data) < new Date(b.timeStamp.data)) {
            return 1;
        }
        return 0;
    };
    AdjustmentHistoryComponent.prototype.getProperDate = function (date) {
        if (!date || isNaN(new Date(date).getTime())) {
            return date;
        }
        var timeInfo = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
        var today = new Date(date);
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear().toString().substr(-2);
        if (dd < 10) {
            dd = '0' + dd;
        }
        var dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
        return (dateInfo + '\n' + timeInfo);
    };
    AdjustmentHistoryComponent.prototype.ngOnInit = function () {
    };
    return AdjustmentHistoryComponent;
}());
export { AdjustmentHistoryComponent };
