import { Component, OnInit, Input } from '@angular/core';
import { Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { RecordTable } from 'src/app/shared/types/record-table.types';
import { CaptainService } from 'src/app/captain.service';
import { QuickFilters } from 'src/app/filter.service';
import { DEFAULT_DATE } from '../captain-order-details/captain-order-details.component';
import { HelperService } from 'src/app/helper.service';
import { CaptainRedeemService } from 'src/app/captain-redeem-service';
// import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-captain-earnings',
  templateUrl: './captain-earnings.component.html',
  styleUrls: ['./captain-earnings.component.css']
})
export class CaptainEarningsComponent implements OnInit {
  @Input() captainId: string;
  @Input() captainDetails: any;
  @Input() mobile: string;
  rapidoEarningInfoTable: Table;
  fromDate = new Date(DEFAULT_DATE.CURRENT_FROM_DATE);
  toDate = new Date(DEFAULT_DATE.TO_DATE);
  earningsFromDate = new Date(DEFAULT_DATE.EARNINGS_FROM_DATE);
  filterCount = 0;
  appliedFilters: any = {};
  autoPayStatusDetails = {};
  serviceDetailIds: any;
  constructor(
    private captainService: CaptainService,
    private redeemService: CaptainRedeemService,
  ) { }

  ngOnInit() {
    this.loadData({});
  }
  makeTableNull() {
    this.rapidoEarningInfoTable = null;
  }
  loadData({
      startDate = DEFAULT_DATE.FROM_DATE,
      endDate = DEFAULT_DATE.TO_DATE,
      refresh = false,
    }) {
      const withEarnings = true;
      const withReferralDetails = false;
      
      const latitude= this.captainDetails && this.captainDetails.location && this.captainDetails.location.lat ? this.captainDetails.location.lat : 0
      const longitude= this.captainDetails && this.captainDetails.location && this.captainDetails.location.lng ? this.captainDetails.location.lng : 0

      this.captainService.getUserServices(this.captainId, false).subscribe(serviceGroupStatusMap => {
          const services = serviceGroupStatusMap['services']
          services.forEach(service => {
              this.serviceDetailIds = [service.serviceDetailId];
              this.redeemService.getAutopayStatusDetails(this.captainId, this.serviceDetailIds, latitude, longitude).then(statusDetails => {
                this.autoPayStatusDetails = statusDetails && statusDetails['data'];
              }, err => {
              });
          })
      }, error => {
        console.error('Error while fetching user service info', error);
      });
      this.captainService.captainDetails(this.mobile, startDate, endDate, refresh, withEarnings, withReferralDetails).subscribe(details => {
        this.generateCaptainEarningTable(details, this.autoPayStatusDetails);
      }, err => {
      });
  }
  generateCaptainEarningTable(details, autoPayDetails) {
    const metaDataMap = {
      'Last Redeem Status': {
        toString() {
            return 'Last Redeem';
        },
        toHoverData() {
            return details.redeemRemark;
        }
      }
    };
    if (details.lastRedeemStatus !== 'hold' && details.lastRedeemStatus !== 'rejected') {
      delete metaDataMap['Last Redeem Status'].toHoverData;
    }
  
    const mapping = {
      'Total earnings': {
        toString() {
          let rupees = details.totalEarning || 0;
          if (parseInt(rupees, 10)) {
            rupees = `₹ ${rupees}`;
          }
          return rupees;
        }
      },
      'Total Incentives': {
        toString() {
          let rupees = details.totalIncentive;
          if (parseInt(rupees, 10)) {
            rupees = `₹ ${rupees}`;
          }
          return rupees;
        }
      },
      'Wallet Balance': {
        toString() {
          const amount = `₹ ${(details.walletBalance).toFixed(2)}`;
          return amount;
        }
      },
      'Redeem Frequency': {
        toString() {
          const redeemFrequency = details.redeemFrequency;
          return redeemFrequency;
        }
      },
      'Redeem Left': {
        toString() {
          const redeemLimit = details.redeemLeft.toString() + '/' + details.redeemLimit.toString();
          return redeemLimit;
        }
      },
      'Auto Redeem': {
        toString() {
          let autoRedeem = '';
          autoRedeem = details.isAutoRedeemEnabled;
          return autoRedeem
          ;
        }
      },
      'Negative Balance Threshold': {
        toString() {
          const negativeBalance = details.blockingLimit;
          return negativeBalance;
        }
      },
      'Account Addition Summary': {
        toString() {
          if(details.accountSummary.accountAdditionStatus == "not_added") {
            return "Account never added";
          }
          if(details.accountSummary.accountAdditionStatus == "added_deleted") {
            return "Account added and deleted"
          } 
          if(details.accountSummary.accountAdditionStatus == "added") {
            return "Account added"
          } 
          return "N/A"
        }
      },
      'Last Redeem Amount': {
        toString() {
          let rupees = details.lastRedeemAmt || 0;
          if (parseInt(rupees, 10)) {
            rupees = `₹ ${rupees}`;
          }
          return rupees;
        }
      },
      'Last Redeem Status': {
        toString() {
          let rupees = details.lastRedeemStatus;
          if (parseInt(rupees, 10)) {
            rupees = `₹ ${rupees}`;
          }
          return rupees;
        },
        onClick() {
          const lastRedeemDate = Math.round(new Date(details.lastRedeemDate).getTime() / 1000*1000);

          HelperService.redirectToOldDashboard('redeems/approvals/list', {
            mobileNumber: details.mobileNumber,
            from: lastRedeemDate,
            to: lastRedeemDate,
          });
        },
        style : {
          'cursor': 'pointer',
        }
      },
      'Last Redeem Date': {
        toString() {
          const date = details.lastRedeemDate;
          return date;
        }
      },
      'Wallet Add Amount': {
        toString() {
          let rupees = details.wallet || 0;
          if (parseInt(rupees, 10)) {
            rupees = `₹ ${rupees}`;
          }
          return rupees;
        }
      },
      'Autopay Status': {
        toString() {
          let status = autoPayDetails.status
          return status
        }
      },
      'Autopay UpiId': {
        toString() {
          let upiId = autoPayDetails.vpa ? maskUpiId(autoPayDetails.vpa) : 'N/A'
          return upiId
        }
      },
      'Autopay AppId': {
        toString() {
          let appId = autoPayDetails.appId ? autoPayDetails.appId : 'N/A'
          return appId
        }
      }
    };
    const redeemStatusClassMapping = {
      Initiated: 'initiated-label',
      Credited: 'active-lable',
      failed: 'inactive-lable',
      rejected: 'rejected-label',
      hold: 'hold-label',
      refunded: 'refunded-label',
      'Awaiting for Approval' : 'awaiting-label',
      'Not Credited': 'inactive-lable',
      abort: 'inactive-lable',
    };
    const spanClassMapping = {
      'Last Redeem Status': redeemStatusClassMapping,
    };
    function spanClassFn(lable, value) {
      let className = '';
      Object.keys(spanClassMapping).map(dataLable => {
        if (className) {
          return;
        }
        if (dataLable === lable) {
          className = spanClassMapping[dataLable][value];
        }
      });
      return className;
    }
    const header = {
      toString: () => {
        return `Earnings`;
      }
    };
    this.rapidoEarningInfoTable = this.createMapTable(mapping, header, spanClassFn, metaDataMap);
  }

  private createMapTable(mapping, header, spanClassFn, metaDataMap) {
    const cols = [];
    Object.keys(mapping).map(lable => {
      cols.push(
        new TableData({
          data: metaDataMap[lable] || lable,
          type: TableDataTypes.DATA,
          className: 'height-52px',
          style: {
            'padding-top': '4%',
          }
        }),
        new TableData({
          data: mapping[lable],
          onClick: mapping[lable]['onClick'],
          type: TableDataTypes.DATA,
          className: 'text-right',
          spanClassName: spanClassFn(lable, mapping[lable]),
          style: Object.assign({
            'padding-top': '4%',
          }, mapping[lable].style || {})
        })
      );
    });
    // const _scope = this;
    const recordTable = new RecordTable({
      data: cols,
      header: {
        toString() {
          return header;
        },
        style() {
          return {
            'color': '#9aa0ac',
            'line-height': '26px',
            'font-size': '16px',
            'cursor': 'pointer',
            'text-decoration':'underline'
          };
        },
        onClick: () => {
          HelperService.redirectToOldDashboard('payroll/earnings-summary', {
            mobile: this.mobile,
            fromDate: this.earningsFromDate,
            toDate: this.toDate,
          });
        },
      },
      colCount: 2,
      config: {
        refresh: true,
        filter: true,
      },
      filterComponents: [
        // new DateFilterInput({
        //   key: 'duration',
        //   default: '',
        //   title: 'Duration',
        // }),
        new QuickFilters({
          key: 'duration',
          default: '',
          title: 'Duration',
        })
      ],
      style: {
        'height': '324px',
        'overflow-x': 'scroll',
      },
      events: {
        onRefresh: () => {
          this.makeTableNull();
          this.loadData({
            refresh: true,
          });
        },
        onFilter: (filters) => {
            const appliedFilters = filters || {};
            // const appliedFilters = this.appliedFilters;
            const duration = appliedFilters.duration || [];

            // if ('duration' in appliedFilters) {
            //     duration = [appliedFilters.duration.start, appliedFilters.duration.end];
            // } else {
            //     duration = this.filterService.appliedFilters.duration;
            // }
            // if (appliedFilters.duration) {

            // }

          this.filterCount = 0;
        //   duration = this.filterService.appliedFilters.duration || [];
          if (duration && duration.length > 0) {
            this.filterCount++;
          }
          const startDuration = duration[0];
          const endDuration = duration[1];
          if (startDuration && endDuration) {
            this.fromDate = new Date(startDuration);
            this.toDate = new Date(endDuration);
            Object.assign(this.appliedFilters, {
              duration: {
                start: this.fromDate,
                end: this.toDate,
              }
            });
          } else {
            Object.assign(this.appliedFilters, {
              duration: {
                start: null,
                end: null,
              }
            });
          }
          this.loadData({startDate: this.fromDate,
            endDate: this.toDate});
        }
      },
      filterCount: this.filterCount
    });
    recordTable.table.appliedFilters = this.appliedFilters;
    return recordTable.table;
  }

}

function maskUpiId(upiId: any) {
  const [user, domain] = upiId.split('@');
  const maskedUser = user.slice(0, 2) + '*'.repeat(user.length - 2);

  return `${maskedUser}@${domain}`;
}

