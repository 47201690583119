declare var __env;
const env: any = __env || {};
export const environment = {
  production: true,
  rapido_dashboard: 'https://dash.rapido.bike',
  server: 'https://profiles-api.rapido.bike',
  googleAccountKey: '924479965589-7e64mcomrnv5ekb5gr1kjdja488qfp8f.apps.googleusercontent.com',
  oauth: 'https://profiles.rapido.bike/login',
  validReferrer: [
    'http://payroll-dev.rapido.bike.s3-website.ap-south-1.amazonaws.com',
    'https://profile.prod.plectrum.dev',
    'http://payroll-dev.rapido.bike',
    'http://payroll.rapido.bike',
    'https://payroll.rapido.bike',
    'https://payroll.develop.plectrum.dev',
    'https://supply.plectrum.dev',
    'http://localhost:4200',
    'http://supply-dev.rapido.bike',
    'http://supply-dev.rapido.bike:7500',
    'https://profiles.rapido.bike',
    'https://profiles-api.rapido.bike',
    'https://profiles.plectrum.dev',
    'https://profile.staging.plectrum.dev',
    'https://payroll.staging.plectrum.dev',
    '.plectrum.dev',
  ],
  trackUrl: 'https://trk.rapido.bike/track/',
  s3RapidoUrl: 'http://rapido-file-store.svc.production.internal/file',
  servicesAllowedToArrive: env.servicesAllowedToArrive,
  servicesAllowedToDrop: env.servicesAllowedToDrop,
  autoEarningsCityConfig:env.autoEarningsCityConfig,
  FRESH_DESK_TICKETS_URL: env.FRESH_DESK_TICKETS_URL,
  CAPTAIN_ISSUES_SUB_REASONS: env.CAPTAIN_ISSUES_SUB_REASONS,
  CAPTAIN_TICKET_DISPOSITIONS: env.CAPTAIN_TICKET_DISPOSITIONS,
  autoFlatCommissionCities:env.autoFlatCommissionCities
};