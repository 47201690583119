import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { CaptainFailedOrdersService } from '../captain-failed-orders.service'
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { REVOKE } from '../../../shared/constants';
@Component({
  selector: 'app-revoke-failed-order',
  templateUrl: './revoke-failed-order.component.html',
  styleUrls: ['./revoke-failed-order.component.css']
})
export class RevokeFailedOrderComponent implements OnInit {
  revokePenaltyAccess = false;
  revokeSuspensionAccess = false;
  activityLog = [];
  eventCount = {
    event: "",
    eventDate: "",
    isRevoked: false,
    orderCreatedDate: "",
    orderId: "",
    revokedBy: "",
    revokedOn: "",
    riderId: "",
    currentUser: ""
  };
  failedOrderTypeMap = {
    'rider_busy': 'Rider Busy',
    'rider_reject': 'Rider Rejected',
    'customer_cancelled': 'Customer Cancelled',
    'rider_cancelled': 'Rider Cancelled',
    'aborted': 'Aborted'
  };
  constructor(
    public dialogRef: MatDialogRef<RevokeFailedOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Object,
    private captainFailedOrdersService: CaptainFailedOrdersService,
    public dialog: MatDialog,
    private toasterService: ToasterService 
  ) { }

  ngOnInit() {
    this.activityLog = this.data['activityLog'];
    this.eventCount = this.data['eventCount'];
    this.revokePenaltyAccess = this.data['accesses'].includes(REVOKE.PENALTY_PERMISSION);
    this.revokeSuspensionAccess = this.data['accesses'].includes(REVOKE.SUSPEND_PERMISSION);
  }

  revokeAction(orderId, event, eventDate, orderCreatedDate, action) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
              width: '380px',
              data: { title: 'Are you sure you want to revoke the order ?',
                      type: 'order-details',
                      message: 'By Revoking you will be adjusting the failed order count of the Captain',
                      buttonText: 'Proceed'
                    }
            });
            confirmDialogRef.afterClosed().subscribe(confirmResult => {
              if (confirmResult) {
                this.captainFailedOrdersService.revokeFailedOrder(this.eventCount.riderId, {
                  orderId, event, eventDate, orderCreatedDate, action,
                  'revokedBy': this.eventCount.currentUser
                }).subscribe(result => {
                  if (result['info'] && result['info'].status && result['info'].status === 'success') {
                    this.toasterService.showToaster(new Toaster({
                      type: ToasterType.SUCCESS,
                      message: `Successfully revoked order`,
                    }));
                    // what to do?
                  } else {
                    this.toasterService.showToaster(new Toaster({
                      type: ToasterType.WARNING,
                      message: `Unable to revoke this order. Please try again.`
                    }));
                  }
                }, err => {
                  if (err && err.error && err.error.data) {
                    let errorMessage = this.getErrorMessage(err.error.data);
                    this.toasterService.showToaster(new Toaster({
                      type: ToasterType.WARNING,
                      message: errorMessage
                    }));
                  } else {
                    this.toasterService.showToaster(new Toaster({
                      type: ToasterType.WARNING,
                      message: `Unable to revoke this order. Please try again.`
                    }));
                  }
                });
              }
            });
  }

  revokedOnData(log, eventCount)
  {
    return log.revokedOn ? this.getRevokedDate(log.revokedOn) : this.getRevokedDate(eventCount.revokedOn) || 'N/A'
  }

  revokedByData(log, eventCount)
  {
    return log.revokedBy ? log.revokedBy : eventCount.revokedBy || 'N/A';
  }

  allowRevoke(action) {
    return (action === REVOKE.PENALTY_ACTION && this.revokePenaltyAccess) || (action === REVOKE.SUSPEND_ACTION && this.revokeSuspensionAccess)
  }

  getErrorMessage(err) {
    let error = JSON.parse(err.split(' - ').slice(1).join(' - '));
    return error && error.error && error.error.message;
  }

  getRevokedDate(date) {
    if(!date) return;
    const revokedDate = new Date(date);
    return revokedDate.toISOString().split('T')[0]
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
