import { Component, OnInit , Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  components: any;
  data: any;
  cancel: string;
  proceed: string;
  className: string;
}

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.css']
})
export class CustomDialogComponent implements OnInit {

  chipData = [];
  inputText: any;
  @Output() removeRiderFromSelector = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if ( this.data && this.data['data'] ) {
      this.chipData = this.data['data'].chipData;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  removeRiderFromUserSelector(userSelector) {
    this.dialogRef.close(userSelector);
  }

}
