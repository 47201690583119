import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Table, Pagination } from 'src/app/shared/types';
import { CaptainService } from 'src/app/captain.service';
@Component({
  selector: 'app-captain-rapidoPay-logs',
  templateUrl: './captain-rapidoPay-logs.component.html',
})
export class CaptainRapidoPayLogsComponent implements OnInit {
  dataLoaded = true;
  @Input() captainDetailsToggleableHeaders: string[];
  @Input() riderId: string;
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  rapidoPayLogsTable: Table;
  constructor(
    public captainService: CaptainService,
  ) { }
  ngOnInit() {
    this.declareTable();
    this.getData(this.riderId,this.rapidoPayLogsTable.pagination.pageNo, this.rapidoPayLogsTable.pagination.perPage);
  }

  declareTable() {
    this.rapidoPayLogsTable = new Table({
      headers: {
        date: 'Date',
        status: 'Status',
        reason: 'Reason',
        agentName: 'Agent Name',
        agentEmail: 'Agent Email'
      },
      pagination: new Pagination(0, 10, 30, [10,15,20], 0, true),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.rapidoPayLogsTable.data = [];
        this.getData(this.riderId,this.rapidoPayLogsTable.pagination.pageNo, this.rapidoPayLogsTable.pagination.perPage);
      },
      selectedHeader: 4,
      defaultError: 'No Logs Found',
      toggleableHeaders: this.captainDetailsToggleableHeaders,
    });
  }

  getData(riderId, page = 0, perPage = 10) {
    this.captainService.getRapidoPayActivityLogs(riderId, perPage, page).subscribe(data => {
      const rapidoPayLogs: any = data && data['info'] && data['info']['data'] ?  data['info']['data'] : [];
      const tableData = this.createTableDataFromResp(rapidoPayLogs);

      this.rapidoPayLogsTable.data = tableData;
      this.rapidoPayLogsTable.pagination.count = rapidoPayLogs.length;
    });
  }

  createTableDataFromResp(logData) {
    let tableData = [];

    logData.forEach(log => {
      tableData.push({
        date: (function(){
          let date = new Date(log.createdAt);

          let month: any = date.getMonth() + 1;
          if(month < 10) {
            month = '0' + month;
          }

          return date.getFullYear() + '-' + month + '-' + date.getDate();
        })(),
        status: log.status == 'blocked' ? 'Blocked' : 'Active',
        reason: log.reason || '',
        agentName: log.agent && log.agent.name ? log.agent.name :  'NA',
        agentEmail: log.agent && log.agent.email ? log.agent.email : 'NA'
      })
    });

    return tableData;
  }

  onPaginationChange(event) {
    this.getData(this.riderId, event.pageNo, event.perPage);
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }
}
