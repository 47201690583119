import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MerchantService } from '../merchant.service';
import { CustomerDetailsService } from '../../customer-details/customer-details.service';

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.css']
})
export class MerchantDetailsComponent implements OnInit {
  merchantMobile = '';
  merchantId = '';
  merchantUserId = '';
  merchantCity = '';
  generalInfo;
  accountInfo;
  walletInfo;
  merchantFound = false;
  public toggleableDetails = 'Order Details';
  constructor(
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private merchantService: MerchantService,
    private customerDetailsService: CustomerDetailsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getMerchantInfo();
    });
    this.getMerchantInfo();
  }

  getMerchantInfo() {
    this.toggleableDetails = 'Order Details';
    this.merchantMobile = this.route.snapshot.params.mobile;
    this.merchantService.getMerchantByMobile(this.merchantMobile).subscribe(result => {
      this.merchantFound = true;
      this.merchantId = result['data']._id;
      this.merchantCity = result['data'].location && result['data'].location.city;
      this.generalInfo = {...this.generalInfo, ...result['data']};
      this.accountInfo = {...this.accountInfo, ...result['data']};
      this.walletInfo = {...this.walletInfo, ...result['data']};
    }, error => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Merchant not found`,
      }));
    });

    this.customerDetailsService.getCustomerByMobile(this.merchantMobile).subscribe(result => {
      if (result && result['data']) {
        this.accountInfo = {...this.accountInfo, appVersion: result['data'].appVersion};
        this.generalInfo = {...this.generalInfo, userId: result['data']._id};
        this.walletInfo = {...this.walletInfo, userId: result['data']._id};
        this.merchantUserId = result['data']._id;
      }
    });
  }

  headersToggled(toggledHeader) {
    this.toggleableDetails = toggledHeader;
  }
}
