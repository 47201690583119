import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerInvoiceDetailsService } from './customer-invoice-details.service';
import { ToasterService } from '../toaster.service';
import { ToasterType, Toaster } from '../shared/types';

@Component({
  selector: 'app-customer-invoice-details',
  templateUrl: './customer-invoice-details.component.html',
  styleUrls: ['./customer-invoice-details.component.css']
})
export class CustomerInvoiceDetailsComponent implements OnInit {
    email = '';
    orderId: any;
    data: any;
    billOfSupply: any;
    paymentSummary: any;
    taxInvoice: any;
    orderStatus: any;
    errorMessage: any;
    customerId: any;

    constructor(
        private route: ActivatedRoute,
        public CustomerInvoiceService: CustomerInvoiceDetailsService,
        private _toastMessage: ToasterService
    ) { }

    ngOnInit() {
        this.orderId = this.route.snapshot.params.orderId || '';
        this.orderStatus = this.route.snapshot.params.orderStatus || '';
        this.customerId = this.route.snapshot.params.customerId || '';
        this.getInvoiceDetails();
    }

    getInvoiceDetails = () => {
        this.CustomerInvoiceService.fetchInvoiceDetails(this.orderId).subscribe(result => {
            this.billOfSupply = result['billOfSupply'];
            this.paymentSummary = { ...result['paymentSummary'], orderStatus: this.orderStatus };
            this.taxInvoice = result['taxInvoice'];
        }, err => {
            this.errorMessage = err.error.message || 'Invoice not found for the order.';
        });
    }

    sendInvoice() {
        this.CustomerInvoiceService.sendInvoiceToEmail(this.email, this.orderId, this.customerId).subscribe(result => {
            const message = this.email ? `Email will be sent to ${this.email}` : result['message'];  
            this._toastMessage.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message,
            }));
        }, err => {
            this._toastMessage.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: err.error.message || 'Unable to load.',
            }));
        });
    }
}
