import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.css']
})
export class InputBoxComponent implements OnInit {

  @Input() inputBoxData: any;
  @Input() selectedValues: any;
  @Output() selectedText: EventEmitter<any> = new EventEmitter<any>();
  public enteredText: string;
  constructor() { }

  ngOnInit() {
    if (this.selectedValues) {
      this.enteredText = this.selectedValues;
    }
  }

  emitValue() {
    const emitData = {};
    emitData[this.inputBoxData.key] = this.enteredText;
    this.selectedText.emit(emitData);
  }

}
