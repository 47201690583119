import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import {} from 'googlemaps';
import { FullViewMapService } from '../full-view-map.service';
import * as _ from 'lodash';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { RideTrackerMapService } from '../ride-tracker-map/ride-tracker-map.service'
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
import { TollsService } from '../tolls.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-full-view-map',
  templateUrl: './full-view-map.component.html',
  styleUrls: ['./full-view-map.component.css']
})
export class FullViewMapComponent implements OnInit, OnChanges {

  @Input() mapData: any;
  @ViewChild('fullMap') mapElement: any;
  @Output() fullViewMap: EventEmitter<any> = new EventEmitter<any>();
  faRefresh = faRedo;
  mapProperties: any;
  map: google.maps.Map;
  riderLocation: any;
  slideOptions = [];
  markerWithPositions = {};
  bound = new google.maps.LatLngBounds(null);

  // setting markers
  infowindow = new google.maps.InfoWindow();
  abortMarker = new google.maps.Marker();
  arrivedMarker = new google.maps.Marker();
  captainCancelledMarker = new google.maps.Marker();
  customerCancelledMarker = new google.maps.Marker();
  droppedMarker = new google.maps.Marker();
  multipleMarker = new google.maps.Marker();
  newMarker = new google.maps.Marker();
  onTheWayMarker = new google.maps.Marker();
  expiredMarker = new google.maps.Marker();
  startedMarker = new google.maps.Marker();
  reachedMarker = new google.maps.Marker();
  captainLiveMarker = new google.maps.Marker();
  acceptRouteTitle = 'First Mile Travelled';
  // Setting icons
  abortedIcon = {
    url: '../../assets/map/aborted.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  arrivedIcon = {
    url: '../../assets/map/arrived.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  captainCancelledIcon = {
    url: '../../assets/map/captain-cancelled.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  customerCancelledIcon = {
    url: '../../assets/map/customer-cancelled.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  droppedIcon = {
    url: '../../assets/map/dropped.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  multipleStatusIcon = {
    url: '../../assets/map/multiple-status.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  newIcon = {
    url: '../../assets/map/new.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  otwIcon = {
    url: '../../assets/map/on-the-way.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  expiredIcon = {
    url: '../../assets/map/order-expired.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  startedIcon = {
    url: '../../assets/map/started.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  reachedIcon = {
    url: '../../assets/map/reached.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  polyStart = {
    url: '../../assets/map/polystart.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  polyEnd = {
    url: '../../assets/map/polyend.png',
    scaledSize: new google.maps.Size(35, 50)
  };
  actualRouteMarker = {
    url: '../../assets/map/actualRoute.png',
    scaledSize: new google.maps.Size(10, 10)
  };
  acceptToArriveRouteMarker = {
    url: '../../assets/map/acceptToArriveRoute.png',
    scaledSize: new google.maps.Size(10, 10)
  };
  captainLiveIcon = {
    url: '../../assets/map/bike.png',
    scaledSize: new google.maps.Size(30, 30)
  };

  // initial route
  initialRoute: any;
  startedInitialMarker = new google.maps.Marker();
  endInitialMarker = new google.maps.Marker();

  // final route
  finalRoute: any;
  startedFinalMarker = new google.maps.Marker();
  endFinalMarker = new google.maps.Marker();

  // Last Mile Travelled(hF)
  actualRoute = [];
  actualLatLngs: any;

  // accept to arrive route
  acceptToArriveRoute = [];
  acceptToArriveLatLngs: any;

  // Alternate routes
  otherRoutes = [];
  startedOtherMarker = new Array();
  endOtherMarker = new Array();
  colorsForOtherRoutes = ['#0000FF', '#753a88'];
  selectedOptions: any = {};

  cityEntryTollLines: string[] = [];
  cityExitTollLines: string[] = [];
  tollPolylines: any = [];
  tollEnabled: boolean = false;

  tollsFormGroup = new FormGroup({
    selectedEntryTollLine: new FormControl(null, Validators.required),
    selectedExitTollLine: new FormControl(null, Validators.required),
  })

   // Capt live loc
   riderId: string;
   captLiveCoords = {lat: null, lng: null};
   captainCurrentStatus: string;
   // status mapping
   statusMap =  {
     '2': 'On Duty',
     '2-6': 'On Duty On Way',
     '4': 'Logged out',
     '5': 'Unreachable',
     '6': 'On The Way',
     '7': 'Arrived',
     '8': 'Started',
     '8-6': 'Started On Way',
     '8-2': 'Started On Duty',
     '9': 'Offline',
     '10': 'Reached'
   };
   terminalStates = ['4', '5', '9'];
 
  public statusRed = ['customerCancelled', 'riderRejected', 'expired', 'aborted', 'riderCancelled', 'dropped'];
  showCaptainLocationRefresh = false;

  constructor(private fullViewMapService: FullViewMapService,
              private rideTrackerMapService: RideTrackerMapService,
              private toasterService: ToasterService,
              private tollService: TollsService) { }

  ngOnInit() {
    this.mapProperties = {
      center: new google.maps.LatLng(21.7679, 78.8718),
      zoom: 5,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      clickableIcons: false,
      gestureHandling: 'cooperative'
    };
    this.riderLocation = {lat: 12.921191215515137, lng: 77.64920806884766};
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    this.startedOtherMarker[0] = this.startedOtherMarker[1] = new google.maps.Marker();
    this.endOtherMarker[0] = this.endOtherMarker[1] = new google.maps.Marker();
    if (this.mapData && this.mapData.selectedOptions) {
      this.selectedOptions = {...this.mapData.selectedOptions};
      this.toggleSelectedOptions();
    }

    if (this.mapData && this.mapData['cityBoundaryPolygon']) {
      this.mapData['cityBoundaryPolygon'].setMap(this.map);
    }

  }

  setToggleOptions() {
    this.mapData.options[4].addLine = true;
    this.slideOptions = [...this.mapData.options];
  }

  changeInOption(option) {
    switch (option) {
      case 'Initial set route' :
        this.toggleInitialRoute();
        break;
      case 'Final estimated route' :
        this.toggleFinalRoute();
        break;
      case 'Last Mile Travelled(hF)' :
        this.toggleActualRoute();
        break;
      case 'ODRD(Actual) route' :
          this.toggleOdrdRoute();
          break;
      case 'Order Log Points' :
          this.toggleOrderLogsView();
          break;
      case 'First Mile Travelled' :
        this.toggleAcceptToArriveRoute();
        break;
      case 'Alternate routes' :
        this.toggleOtherRoutes();
        break;
      case 'Tolls':
        this.togglePopulateTolls();
        break;  
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.mapData && changes.mapData.currentValue) {
      if (changes.mapData.currentValue['routes']) {
        this.constructSideBar();
        this.getMarkers();
      }
      if (changes.mapData.currentValue['selectedOptions']) {
        this.selectedOptions = {...this.mapData.selectedOptions};
        this.setToggleOptions();
        this.toggleSelectedOptions();
      }
      if (changes.mapData.currentValue.actualRoute) {
        this.actualLatLngs = this.actualRoute;
      }
      if (changes.mapData.currentValue.riderId) {
        this.riderId = changes.mapData.currentValue.riderId;
      }
      if (changes.mapData.currentValue.orderStatus) {
        if (this.statusRed.indexOf(changes.mapData.currentValue.orderStatus) === -1) {
          this.showCaptainLocationRefresh = true;
          this.getCaptLiveLocation();
        }
      }
    }
  }

  showInfowindow(dataToShow, marker) {
    const infowindow = new google.maps.InfoWindow({
      content: dataToShow,
    });
    infowindow.open(this.map, marker);
  }

  toggleOrderLogsView() {
    if (this.selectedOptions['Order Log Points']) {
      if (this.mapData.captLiveLocInOrderLogs) {
        if (this.mapData.cancellationInformation && this.mapData.cancellationInformation.captainLocation &&
            this.mapData.cancellationInformation.captainLocation.length === 2) {
          const latLng = {lat: this.mapData.cancellationInformation.captainLocation[1],
              lng: this.mapData.cancellationInformation.captainLocation[0]};
          this.extendBounds(latLng);
          this.captainLiveMarker.setPosition(latLng);
          this.captainLiveMarker.setMap(this.map);
          this.captainLiveMarker.setIcon(this.captainLiveIcon);
          const dataToShow = 'Captain location on cancellation';
          this.showInfowindow(dataToShow, this.captainLiveMarker);
          this.captainLiveMarker.addListener('click', () => {
            this.showInfowindow(dataToShow, this.captainLiveMarker);
          });
        }
      }
      for (const log in this.markerWithPositions) {
        if (this.markerWithPositions[log]) {
          const logs = this.markerWithPositions[log];
          switch (logs.icon) {
            case 'new' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.newMarker.setPosition(latLng);
                this.newMarker.setMap(this.map);
                this.newMarker.setIcon(this.newIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.newMarker);
                this.newMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.newMarker);
                });
              }
              break;
            case 'onTheWay' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.onTheWayMarker.setPosition(latLng);
                this.onTheWayMarker.setMap(this.map);
                this.onTheWayMarker.setIcon(this.otwIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.onTheWayMarker);
                this.onTheWayMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.onTheWayMarker);
                });
              }
              break;
            case 'arrived' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.arrivedMarker.setPosition(latLng);
                this.arrivedMarker.setMap(this.map);
                this.arrivedMarker.setIcon(this.arrivedIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.arrivedMarker);
                this.arrivedMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.arrivedMarker);
                });
              }
              break;
            case 'started' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.startedMarker.setPosition(latLng);
                this.startedMarker.setMap(this.map);
                this.startedMarker.setIcon(this.startedIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.startedMarker);
                this.startedMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.startedMarker);
                });
              }
              break;
            case 'reached' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.reachedMarker.setPosition(latLng);
                this.reachedMarker.setMap(this.map);
                this.reachedMarker.setIcon(this.reachedIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.reachedMarker);
                this.reachedMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.reachedMarker);
                });
              }
              break;
            case 'dropped' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.droppedMarker.setPosition(latLng);
                this.droppedMarker.setMap(this.map);
                this.droppedMarker.setIcon(this.droppedIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.droppedMarker);
                this.droppedMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.droppedMarker);
                });
              }
              break;
            case 'customerCancelled' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.customerCancelledMarker.setPosition(latLng);
                this.customerCancelledMarker.setMap(this.map);
                this.customerCancelledMarker.setIcon(this.customerCancelledIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.customerCancelledMarker);
                this.customerCancelledMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.customerCancelledMarker);
                });
              }
              break;
            case 'riderCancelled' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.captainCancelledMarker.setPosition(latLng);
                this.captainCancelledMarker.setMap(this.map);
                this.captainCancelledMarker.setIcon(this.captainCancelledIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.captainCancelledMarker);
                this.captainCancelledMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.captainCancelledMarker);
                });
              }
              break;
            case 'expired' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.expiredMarker.setPosition(latLng);
                this.expiredMarker.setMap(this.map);
                this.expiredMarker.setIcon(this.expiredIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.expiredMarker);
                this.expiredMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.expiredMarker);
                });
              }
              break;
            case 'aborted' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.abortMarker.setPosition(latLng);
                this.abortMarker.setMap(this.map);
                this.abortMarker.setIcon(this.abortedIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.abortMarker);
                this.abortMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.abortMarker);
                });
              }
              break;

            case 'multiple' :
              if (logs && logs.lat && logs.lng) {
                const latLng = {lat: logs.lat, lng: logs.lng};
                this.extendBounds(latLng);
                this.multipleMarker.setPosition(latLng);
                this.multipleMarker.setMap(this.map);
                this.multipleMarker.setIcon(this.multipleStatusIcon);
                const dataToShow = this.getMarkerInfo(logs.label);
                this.showInfowindow(dataToShow, this.multipleMarker);
                this.multipleMarker.addListener('click', () => {
                  this.showInfowindow(dataToShow, this.multipleMarker);
                });
              }
              break;
          }
          this.map.setCenter(this.bound.getCenter());
        }
      }
    } else {
      this.abortMarker.setMap(null);
      this.arrivedMarker.setMap(null);
      this.captainCancelledMarker.setMap(null);
      this.customerCancelledMarker.setMap(null);
      this.droppedMarker.setMap(null);
      this.multipleMarker.setMap(null);
      this.newMarker.setMap(null);
      this.onTheWayMarker.setMap(null);
      this.expiredMarker.setMap(null);
      this.startedMarker.setMap(null);
    }
  }

  toggleCaptLiveLoc() {
    if (this.captLiveCoords && this.captLiveCoords.lat && this.captLiveCoords.lng) {
      const latLng = this.captLiveCoords;
      this.extendBounds(latLng);
      this.captainLiveMarker.setPosition(latLng);
      this.captainLiveMarker.setMap(this.map);
      this.captainLiveMarker.setIcon(this.captainLiveIcon);
      this.showInfowindow('Captain is ' + this.statusMap[this.captainCurrentStatus], this.captainLiveMarker);
      this.captainLiveMarker.addListener('click', () => {
        this.showInfowindow('Captain is ' + this.statusMap[this.captainCurrentStatus], this.captainLiveMarker);
      });
    }
  }

  constructSideBar() {
    if (this.mapData && this.mapData.routes) {
      const data = this.mapData.routes.fareEstimateLogs || [];
      const finalAllPaths = data[0] && data[0].direction && data[0].direction.allPaths
      const dataLength = data.length;
      this.slideOptions.push({title: 'Initial set route',
        distance: dataLength > 0 ? (data[dataLength - 1].direction.distanceInKms) + ' kms' : 'N/A',
        time: dataLength > 0 ? (data[dataLength - 1].direction.timeInMins) + ' mins' : 'N/A', polyline: '',
        addLine: true,
        disabled: !(data[dataLength - 1] && data[dataLength - 1].direction.allPaths && data[dataLength - 1].direction.allPaths.length > 0)});

      this.slideOptions.push({title: 'Final estimated route',
        distance: this.mapData && this.mapData.routes && this.mapData.routes.finalEstimatedDistance ? this.mapData.routes.finalEstimatedDistance + 'kms': 
        dataLength > 0 && data[0].allPaths.length > 0 ? ((data[0].allPaths[0].distance) / 1000).toFixed(2) + ' kms' : 'N/A',
        time: this.mapData && this.mapData.routes && this.mapData.routes.finalEstimatedTime ? this.mapData.routes.finalEstimatedTime + ' mins': dataLength > 0 && data[0].allPaths.length > 0? ((data[0].allPaths[0].time) / 60).toFixed(2) + ' mins' : 'N/A',
        polyline: '', addLine: true,
        disabled: !(data[0] && data[0].allPaths && data[0].allPaths.length > 0)});
      this.slideOptions.push({title: 'ODRD(Actual) route',
        distance: this.mapData && this.mapData.routes && this.mapData.routes.odrdDistance ? this.mapData.routes.odrdDistance + ' kms': 'N/A',
        time: this.mapData && this.mapData.routes && this.mapData.routes.odrdTime ? this.mapData.routes.odrdTime+" mins": 'N/A',
        polyline: '',addLine: true,
        disabled: !(this.mapData && this.mapData.routes && this.mapData.routes.odrdPolyline)});
      this.slideOptions.push({title: 'Last Mile Travelled(hF)',
        distance: this.mapData.finalDistance ? this.mapData.finalDistance + ' kms' : 'N/A',
        time: this.mapData.finalRideTime ? this.mapData.finalRideTime + ' mins' : 'N/A', polyline: '', addLine: true,
        disabled: !(this.actualLatLngs && this.actualLatLngs.locations && this.actualLatLngs.locations.length > 0)});

      this.slideOptions.push({title: 'First Mile Travelled', addLine: true,
        distance: this.mapData.acceptToArriveRoute && this.mapData.acceptToArriveRoute.distance ?
                  this.mapData.acceptToArriveRoute.distance.toFixed(2) + ' kms' : 'N/A',
        time: this.mapData.acceptToArriveRoute && this.mapData.acceptToArriveRoute.time ?
                  this.mapData.acceptToArriveRoute.time.toFixed(2) + ' mins' : 'N/A',
        disabled: !(this.acceptToArriveLatLngs && this.acceptToArriveLatLngs.locations &&
          this.acceptToArriveLatLngs.locations.length > 0)});

        this.slideOptions.push({
          title: 'Alternate routes',
          polyline: '',
          addLine: false,
          distance: finalAllPaths && finalAllPaths && finalAllPaths[1] && finalAllPaths[1].distance ?
            ((finalAllPaths[1].distance / 1000).toFixed(2)).toString() + ' kms' : 'N/A',
          time: finalAllPaths && finalAllPaths[1] && finalAllPaths[1].time ?
            ((finalAllPaths[1].time / 60).toFixed(2)).toString() + ' mins' : 'N/A',
          color: 'color-blue',
          distance2: data[0] && finalAllPaths && finalAllPaths[2] && finalAllPaths[2].distance ?
            ((finalAllPaths[2].distance / 1000).toFixed(2)).toString() + ' kms' : 'N/A',
          time2: data[0] && finalAllPaths && finalAllPaths[2] && finalAllPaths[2].time ?
            ((finalAllPaths[2].time / 60).toFixed(2)).toString() + ' mins' : 'N/A',
          color2: 'color-red',
          disabled: !(data[0] && finalAllPaths && finalAllPaths.length > 1)});
        this.slideOptions.push({
            title: 'Tolls',
            distance: null,
            time: null,
            addLine: true,
            disabled: false
          })
    }
  }

  toggleSelectedOptions() {
    for (const option in this.selectedOptions) {
      if (option && this.selectedOptions[option]) {
        switch (option) {
          case 'Initial set route' :
            this.toggleInitialRoute();
            break;
          case 'Final estimated route' :
            this.toggleFinalRoute();
            break;
          case 'Last Mile Travelled(hF)' :
            this.toggleActualRoute();
            break;
          case 'Order Log Points' :
            this.toggleOrderLogsView();
            break;
          case 'First Mile Travelled' :
            this.toggleAcceptToArriveRoute();
            break;
          case 'Alternate routes' :
            this.toggleOtherRoutes();
            break;
          case 'Tolls':
            this.togglePopulateTolls();
            break;
        }
      }
    }
  }

  getMarkerInfo(labels) {
    let htmlInfo = '';
    for (const label in labels) {
      if (labels[label]) {
        switch (labels[label]) {
          case 'new' :
            htmlInfo += `<div>
                  <div class="circle-9 new-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> New Order </div>
                </div>`;
            break;
          case 'onTheWay' :
            htmlInfo += `<div>
                  <div class="circle-9 on-the-way-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> On The Way </div>
                </div>`;
            break;
          case 'arrived' :
            htmlInfo += `<div>
                  <div class="circle-9 arrived-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Arrived </div>
                </div>`;
            break;
          case 'started' :
            htmlInfo += `<div>
                  <div class="circle-9 started-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Started </div>
                </div>`;
            break;
          case 'reached' :
            htmlInfo += `<div>
                  <div class="circle-9 reached-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Reached </div>
                </div>`;
            break;
          case 'dropped' :
            htmlInfo += `<div>
                  <div class="circle-9 dropped-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Dropped </div>
                </div>`;
            break;
          case 'customerCancelled' :
            htmlInfo += `<div>
                  <div class="circle-9 customer-cancelled-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Customer Cancelled </div>
                </div>`;
            break;
          case 'riderCancelled' :
            htmlInfo += `<div>
                  <div class="circle-9 captain-cancelled-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Captain Cancelled </div>
                </div>`;
            break;
          case 'expired' :
            htmlInfo += `<div>
                  <div class="circle-9 expired-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Expired </div>
                </div>`;
            break;
          case 'aborted' :
            htmlInfo += `<div>
                  <div class="circle-9 aborted-marker-color display-inline-block"></div>
                  <div class="text-info-window display-inline-block"> Aborted </div>
                </div>`;
            break;
        }
      }
    }
    return htmlInfo;
  }

  getMarkers() {
    if (this.mapData && this.mapData.routes && this.mapData.routes.orderLogPoints) {
      this.markerWithPositions = {};
      for (const item in this.mapData.routes.orderLogPoints) {
        if (this.mapData.routes.orderLogPoints[item]) {
          const log = this.mapData.routes.orderLogPoints[item];
          if (log.eventName && log.captainLocation && log.captainLocation.lat && log.captainLocation.lng) {
            const latLng = log.captainLocation.lat + ', ' + log.captainLocation.lng;
            if (this.markerWithPositions[latLng]) {
              this.markerWithPositions[latLng] = {
                icon: 'multiple',
                label: [...this.markerWithPositions[latLng].label, log.eventName],
                lat: log.captainLocation.lat,
                lng: log.captainLocation.lng
              };
            } else {
              this.markerWithPositions[latLng] = {
                icon: log.eventName,
                label: [log.eventName],
                lat: log.captainLocation.lat,
                lng: log.captainLocation.lng
              };
            }
          } else if (log.eventName && log.customerLocation && log.customerLocation.lat && log.customerLocation.lng) {
            const latLng = log.customerLocation.lat + ', ' + log.customerLocation.lng;
            if (this.markerWithPositions[latLng]) {
              this.markerWithPositions[latLng] = {
                icon: 'multiple',
                label: [...this.markerWithPositions[latLng].label, log.eventName],
                lat: log.customerLocation.lat,
                lng: log.customerLocation.lng
              };
            } else {
              this.markerWithPositions[latLng] = {
                icon: log.eventName,
                label: [log.eventName],
                lat: log.customerLocation.lat,
                lng: log.customerLocation.lng
              };
            }
          }
        }
      }
    }
  }


  getCaptLiveLocation() {
    if (this.riderId) {
      this.rideTrackerMapService.getLiveLocation(this.riderId).subscribe(res => {
        if (res && res['location'] && res['location'].length === 2) {
          this.captLiveCoords.lng = res['location'][0];
          this.captLiveCoords.lat = res['location'][1];
          this.captainCurrentStatus = res['status'] || '5';
          this.toggleCaptLiveLoc();
        }
      });
    }
  }

  toggleInitialRoute() {
    if (this.selectedOptions['Initial set route']) {
      if (this.mapData.isServiceDeliveryType && this.mapData.events.requestedEvent) {
        const initialPosition = {lat: this.mapData.events.requestedEvent.pickupLocation.lat, lng: this.mapData.events.requestedEvent.pickupLocation.lng};
        const finalPosition = {lat: this.mapData.events.requestedEvent.dropLocation.lat, lng: this.mapData.events.requestedEvent.dropLocation.lng};
        this.startedInitialMarker.setIcon(this.polyStart);
        this.startedInitialMarker.setPosition(initialPosition);
        this.startedInitialMarker.setMap(this.map);
        const infowindow = new google.maps.InfoWindow({
          content: 'Customer set pickup location',
        });
        infowindow.open(this.map, this.startedInitialMarker);
        this.endInitialMarker.setIcon(this.polyEnd);
        this.endInitialMarker.setPosition(finalPosition);
        this.endInitialMarker.setMap(this.map);
        const infowindow2 = new google.maps.InfoWindow({
          content: 'Customer set drop location',
        });

        infowindow2.open(this.map, this.endInitialMarker);
        this.extendBounds(initialPosition);
        this.extendBounds(finalPosition);
      } else {
        const encodedStr = this.mapData.routes.fareEstimateLogs[this.mapData.routes.fareEstimateLogs.length - 1].direction.polyline;
        const initialPath = google.maps.geometry.encoding.decodePath(encodedStr);
        this.initialRoute = new google.maps.Polyline({
          path: initialPath,
          geodesic: true,
          strokeColor: '#FF0000',
          strokeOpacity: 1.0,
          strokeWeight: 3
        });
        this.startedInitialMarker.setIcon(this.polyStart);
        this.startedInitialMarker.setPosition(this.initialRoute.getPath().getAt(0));
        this.startedInitialMarker.setMap(this.map);
        const infowindow = new google.maps.InfoWindow({
          content: 'Customer set pickup location',
        });
        infowindow.open(this.map, this.startedInitialMarker);
        this.endInitialMarker.setIcon(this.polyEnd);
        this.endInitialMarker.setPosition(this.initialRoute.getPath().getAt(this.initialRoute.getPath().getLength() - 1));
        this.endInitialMarker.setMap(this.map);
        const infowindow2 = new google.maps.InfoWindow({
          content: 'Customer set drop location',
        });
        infowindow2.open(this.map, this.endInitialMarker);

        for (let i = 0; i < initialPath.length; i++) {
          this.extendBounds(initialPath[i]);
        }
        this.initialRoute.setMap(this.map);
      }
    } else {
      if (this.initialRoute) {
        this.initialRoute.setMap(null);
      }
      this.startedInitialMarker.setMap(null);
      this.endInitialMarker.setMap(null);
    }
  }

  toggleFinalRoute() {
    if (this.selectedOptions['Final estimated route']) {
      if (this.mapData.isServiceDeliveryType) {
        const initialPosition = {lat: this.mapData.events.startedEvent.pickupLocation.lat, lng: this.mapData.events.startedEvent.pickupLocation.lng};
        const finalPosition = {lat: this.mapData.events.startedEvent.dropLocation.lat, lng: this.mapData.events.startedEvent.dropLocation.lng};
        this.startedFinalMarker.setIcon(this.polyStart);
        this.startedFinalMarker.setPosition(initialPosition);
        this.startedFinalMarker.setMap(this.map);
        const infowindow = new google.maps.InfoWindow({
          content: 'Customer pickup location',
        });
        infowindow.open(this.map, this.startedFinalMarker);
        this.endFinalMarker.setIcon(this.polyEnd);
        this.endFinalMarker.setPosition(finalPosition);
        this.endFinalMarker.setMap(this.map);
        const infowindow2 = new google.maps.InfoWindow({
          content: 'Customer drop location',
        });
        infowindow2.open(this.map, this.endFinalMarker);
        this.extendBounds(initialPosition);
        this.extendBounds(finalPosition);
      } else {
        const encodedStr = this.mapData && this.mapData.routes && this.mapData.routes.finalEstimatedPolyline ? this.mapData.routes.finalEstimatedPolyline :this.mapData.routes.fareEstimateLogs[0].direction.polyline;
        const finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
        for (let i = 0; i < finalPath.length; i++) {
          this.extendBounds(finalPath[i]);
        }
        this.finalRoute = new google.maps.Polyline({
          path: finalPath,
          geodesic: true,
          strokeColor: '#0FB27D',
          strokeOpacity: 1.0,
          strokeWeight: 3
        });
        this.startedFinalMarker.setIcon(this.polyStart);
        this.startedFinalMarker.setPosition(this.finalRoute.getPath().getAt(0));
        this.startedFinalMarker.setMap(this.map);
        const infowindow = new google.maps.InfoWindow({
          content: 'Customer pickup location',
        });
        infowindow.open(this.map, this.startedFinalMarker);

        this.endFinalMarker.setIcon(this.polyEnd);
        this.endFinalMarker.setPosition(this.finalRoute.getPath().getAt(this.finalRoute.getPath().getLength() - 1));
        this.endFinalMarker.setMap(this.map);
        const infowindow2 = new google.maps.InfoWindow({
          content: 'Customer drop location',
        });
        infowindow2.open(this.map, this.endFinalMarker);
        this.finalRoute.setMap(this.map);
      }
    } else {
      if (this.finalRoute) {
        this.finalRoute.setMap(null);
      }
      this.startedFinalMarker.setMap(null);
      this.endFinalMarker.setMap(null);
    }
  }

  toggleOdrdRoute() {
    if (this.selectedOptions['ODRD(Actual) route'] && this.mapData && this.mapData.routes && this.mapData.routes.odrdPolyline) {
      const encodedStr = this.mapData.routes.odrdPolyline;
      const finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
      for (let i = 0; i < finalPath.length; i++) {
        this.extendBounds(finalPath[i]);
      }
      this.finalRoute = new google.maps.Polyline({
        path: finalPath,
        geodesic: true,
        strokeColor: '#000099',
        strokeOpacity: 1.0,
        strokeWeight: 3
      });
      this.startedFinalMarker.setIcon(this.polyStart);
      this.startedFinalMarker.setPosition(this.finalRoute.getPath().getAt(0));
      this.startedFinalMarker.setMap(this.map);
      const infowindow = new google.maps.InfoWindow({
        content: 'Customer pickup location',
      });
      infowindow.open(this.map, this.startedFinalMarker);

      this.endFinalMarker.setIcon(this.polyEnd);
      this.endFinalMarker.setPosition(this.finalRoute.getPath().getAt(this.finalRoute.getPath().getLength() - 1));
      this.endFinalMarker.setMap(this.map);
      const infowindow2 = new google.maps.InfoWindow({
        content: 'Customer drop location',
      });
      infowindow2.open(this.map, this.endFinalMarker);
      this.finalRoute.setMap(this.map);
    } else {
      if (this.finalRoute) {
        this.finalRoute.setMap(null);
        this.startedFinalMarker.setMap(null);
        this.endFinalMarker.setMap(null);
      }
    }
  }

  toggleActualRoute() {
    if (this.selectedOptions['Last Mile Travelled(hF)']) {
      const locations = this.mapData.actualRoute['locations'];
      for (const position in locations) {
        if (locations[position]) {
          if (locations[position].location && locations[position].location[0] && locations[position].location[1]) {
            const latLng = {lat: locations[position].location[1], lng: locations[position].location[0]};
            // this.extendBounds(latLng);
            this.actualRoute[position] = new google.maps.Marker({
              icon: this.actualRouteMarker
            });
            this.actualRoute[position].setPosition(latLng);
            this.actualRoute[position].setMap(this.map);
          }
        }
      }
    } else {
      if (this.actualRoute.length > 0) {
        for (const position in this.actualRoute) {
          if (this.actualRoute[position]) {
            this.actualRoute[position].setMap(null);
          }
        }
      }
    }
  }

  toggleAcceptToArriveRoute() {
    if (this.selectedOptions['First Mile Travelled']) {
      const locations = this.mapData.acceptToArriveRoute['locations'];
      for (const position in locations) {
        if (locations[position]) {
          if (locations[position].location && locations[position].location[0] && locations[position].location[1]) {
            const latLng = {lat: locations[position].location[1], lng: locations[position].location[0]};
            // this.extendBounds(latLng);
            this.acceptToArriveRoute[position] = new google.maps.Marker({
              icon: this.acceptToArriveRouteMarker
            });
            this.acceptToArriveRoute[position].setPosition(latLng);
            this.acceptToArriveRoute[position].setMap(this.map);
          }
        }
      }
    } else {
      if (this.acceptToArriveRoute.length > 0) {
        for (const position in this.acceptToArriveRoute) {
          if (this.acceptToArriveRoute[position]) {
            this.acceptToArriveRoute[position].setMap(null);
          }
        }
      }
    }
  }

  toggleOtherRoutes() {
    if (this.selectedOptions['Alternate routes']) {
      const otherRoutes = _.cloneDeep(this.mapData.routes.fareEstimateLogs[0].direction.allPaths);
      const otherRoutesGoogle = otherRoutes.splice(1, 2);
      for (const path in otherRoutesGoogle) {
        if (otherRoutesGoogle[path]) {
          const encodedStr = otherRoutesGoogle[path]['polyline'];
          const otherPath = google.maps.geometry.encoding.decodePath(encodedStr);
          this.otherRoutes[path] = new google.maps.Polyline({
            path: otherPath,
            geodesic: true,
            strokeColor: this.colorsForOtherRoutes[path],
            strokeOpacity: 1.0,
            strokeWeight: 3
          });
          this.startedOtherMarker[path] = new google.maps.Marker({
            position: this.otherRoutes[path].getPath().getAt(0),
            map: this.map,
            icon: this.polyStart
          });
          this.endOtherMarker[path] =  new google.maps.Marker({
            position: this.otherRoutes[path].getPath().getAt(this.otherRoutes[path].getPath().getLength() - 1),
            map: this.map,
            icon: this.polyEnd
          });
          for (let i = 0; i < otherPath.length; i++) {
            // this.extendBounds(otherPath[i]);
          }
          this.otherRoutes[path].setMap(this.map);
        }
      }
    } else {
      if (this.otherRoutes && this.otherRoutes.length > 0) {
        for (const route in this.otherRoutes) {
          if (this.otherRoutes[route]) {
            this.otherRoutes[route].setMap(null);
            this.startedOtherMarker[route].setMap(null);
            this.endOtherMarker[route].setMap(null);
          }
        }
      }
    }
  }

  extendBounds(location) {
    if (!this.map) {
      this.map =  new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    }
    this.bound.extend(location);
    this.map.fitBounds(this.bound, 100);
    this.map.panToBounds(this.bound, 10);
  }

  closeFullView() {
    this.fullViewMapService.toggleFullMap();
  }

  togglePopulateTolls() {
    if (this.selectedOptions['Tolls']) {
      this.tollEnabled = true;
      if (this.mapData.cityTolls.length == 0) {
        this.rideTrackerMapService.getCityTolls(this.mapData.orderCityId).subscribe(result => {
          if (result) {
            this.mapData.cityTolls = result['tolls'];
            this.tollPolylines = this.tollService.addTollsToMap(this.mapData.cityTolls, this.map);
          }
        }, error => {
          if (error) {
            this.selectedOptions['Tolls'] = false;
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: 'Toll fetch failed'
          }));
          }
        })
      } else {
        this.tollPolylines = this.tollService.addTollsToMap(this.mapData.cityTolls, this.map);
      }
      const tollLines = this.tollService.fetchTollLinesFromTolls(this.mapData.cityTolls);
      this.cityEntryTollLines = tollLines.entryTollLines;
      this.cityExitTollLines = tollLines.exitTollLines;
    } else {
      this.tollEnabled = false;
      this.tollService.removeTollsFromMap(this.map, this.tollPolylines);
    }
  }
}
