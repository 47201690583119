<div class="boxed-border">
  <div class="title row">
    <span>
      Incentive Details
    </span>
    <span class="pull-right">
      <div class="row">
        <div class="margin-right-44 select-tabs">
         <span [ngClass]="{'selected-view' : selectedView === 'daily'}" (click)="changeView()" class="cursor-pointer">
           Daily
         </span>
         <span [ngClass]="{'selected-view' : selectedView === 'weekly'}" (click)="changeView()" class="cursor-pointer margin-left-34">
          Weekly Incentive
        </span>
        </div>
        <div style="color: black;">
          <div class="row margin-right-12 align-icons">
            <fa-icon class="cursor-pointer margin-icons" (click)="refreshData()" [icon]="faRefresh"></fa-icon>
            <div class="cursor-pointer filter-button" (click)="onFilter()">
              <span class="is-flex margin-sides-5">
                <mat-icon class="filter-icon">filter_list</mat-icon>Filters
              </span>
            </div>
        </div>
        </div>
      </div>
    </span>
  </div>
  <div class="line"></div>
  <div class="scrollable-y">
    <div *ngIf="selectedView === 'daily'" class="padding-bottom-10">
      <app-daily-incentives [incentives]="dailyIncentives"></app-daily-incentives>
    </div>
    <div *ngIf="selectedView === 'weekly'" class="padding-bottom-10">
      <app-weekly-incentives [incentives]="weeklyIncentives"></app-weekly-incentives>
    </div>
  </div>
</div>