/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-account.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../card-table/card-table.component.ngfactory";
import * as i3 from "../../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./merchant-account.component";
var styles_MerchantAccountComponent = [i0.styles];
var RenderType_MerchantAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantAccountComponent, data: {} });
export { RenderType_MerchantAccountComponent as RenderType_MerchantAccountComponent };
export function View_MerchantAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, null, null, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-account", [], null, null, null, View_MerchantAccountComponent_0, RenderType_MerchantAccountComponent)), i1.ɵdid(1, 638976, null, 0, i5.MerchantAccountComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantAccountComponentNgFactory = i1.ɵccf("app-merchant-account", i5.MerchantAccountComponent, View_MerchantAccountComponent_Host_0, { data: "data" }, {}, []);
export { MerchantAccountComponentNgFactory as MerchantAccountComponentNgFactory };
