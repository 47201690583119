import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var OrderStatusService = /** @class */ (function () {
    function OrderStatusService(http) {
        this.http = http;
        this.logUrl = environment.server + '/api/orderLog';
    }
    OrderStatusService.prototype.fetchChangeOrderStatus = function (orderId) {
        return this.http.get(this.logUrl + '/' + orderId).pipe(catchError(this.handleError));
    };
    OrderStatusService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    OrderStatusService.ngInjectableDef = i0.defineInjectable({ factory: function OrderStatusService_Factory() { return new OrderStatusService(i0.inject(i1.MyHttpClient)); }, token: OrderStatusService, providedIn: "root" });
    return OrderStatusService;
}());
export { OrderStatusService };
