<div *ngIf="info">
  <div class="boxed-border width-100">
    <div class="row title" [ngClass]="info.tableHeaderClass">
      <div class="color-card-header padding-0">
        {{info.tableHeader}}
      </div>
      <div *ngIf="info.button">
        <button (click)="buttonClicked(info.button.data)" [ngClass]="info.button.classButton">{{info.button.data}}</button>
      </div>
      <div *ngIf="info.expandTable" class="pull-right align-expansion" (click)="toggleStatus()">
        <span class="view-details">{{viewDetails}}</span>
        <i class="material-icons arrow-positioning" *ngIf="!viewMore">arrow_drop_up</i>
        <i class="material-icons arrow-positioning" *ngIf="viewMore">arrow_drop_down</i>
      </div>
    </div>
    <div class="line"></div>
    <div [ngClass]="info.tableHeight" style="overflow-y: scroll">
      <table *ngIf="info.dataFetched && !info.loadOnRequest && !info.accessDenied" mat-table [dataSource]="info.data" class="width-100">
        <ng-container *ngFor="let colKey of displayedColumns" [matColumnDef]="colKey">
            <th class="table-overflow center-align padding-30" mat-header-cell *matHeaderCellDef> {{info.headers[colKey]}}
            </th>
            <td [ngClass]="element && element[colKey]?.className" class="table-overflow center-align text-overflow-table white-space-pre padding-sides-5"
                mat-cell *matCellDef="let element; let i=index"  pTooltip="{{(info && info.title && info.title.indexOf(colKey) !== -1
                  ? element[colKey]
                    ? element[colKey].data && element[colKey].data.trim()
                    : (element[colKey].hoverData
                      ? element[colKey].hoverData
                      : '')
                  : '')}}"
                tooltipPosition="bottom">
              <ng-container *ngIf="element[colKey]">
                <span [ngClass]="{'center-icon' : info.headers[colKey] === 'Star Rating'}" (click)="redirectToUrl(element[colKey].redirectLink)">
                  <mat-icon *ngIf="info.headers[colKey] === 'Star Rating' && element[colKey].data" class="yellow-star">star_rate</mat-icon>
                      {{element[colKey].data || 'N/A'}}
                </span>
                <span *ngIf="element[colKey].information">
                  <ng-template #tipContent>
                    <div [innerHTML]="element[colKey].information"></div>
                  </ng-template>
                  <i class="material-icons-outlined set-icon-alignment card-info cursor-pointer" data-toggle="tooltip"
                    placement="top" tooltipClass="preserve-whitespace" [ngbTooltip]="tipContent">
                    info
                  </i>
                </span>
              </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="changeOnHover"></tr>
      </table>
    </div>
    <span *ngIf="!info.dataFetched" class="emptyMessage">
      {{info.emptyMessage}} ¯\_(ツ)_/¯
    </span>
    <span *ngIf="info.loadOnRequest && !info.accessDenied" class="loadDataWrapperCard">
      <button class="load-data" (click)="loadDataNow()">Load data</button>
    </span>
    <div *ngIf="info.accessDenied" class="loadDataWrapperCard">
      <div style="margin-left: auto; margin-right: auto;">
        <div class="svgIcon lock-icon position-absolute lock-icon-positioning"></div>
        <div style="margin-left: 80px;">
          Oops! you dont have access for this card ¯\_(ツ)_/¯
        </div>
      </div>
    </div>
  </div>
</div>