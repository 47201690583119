<div class="row side-padding-15 is-flex search-container  ">
  <div>
    <!-- <div class="pull-right"> -->
    <div class="form-group has-feedback is-flex">
      <div class="form-group has-feedback">
        <input [(ngModel)]="searchKeyword" (keyup.enter)="searchCustomer()" onblur="this.placeholder = 'Search by Customer number'"
        onfocus="this.placeholder = ''" type="text" 
        [ngClass]="{'padding-left-30':focus===false && !enteredNumber}" (focusout)="focus=false" (focus)="focus=true" 
        [placeholder]="placeholder" class="form-control width-250" id="searchCustomer"/>
        <span [ngClass]="{'glyphicon glyphicon-search form-control-feedback': focus===false && !enteredNumber}"></span>
      </div>
      <label class="search-button" (click)="searchCustomer()">Go</label>
    </div>
  </div>
  <div style="
    width: 173px;
    margin-top: -20px;
  ">
    <single-select-dropdown (selectedDropDown)="filterTypeSelect.onChange($event)"  [singleDropdownData]="filterTypeSelect" [selectedValues]="SEARCH_BY.MOBILE" ></single-select-dropdown>
  </div>
</div>
<router-outlet></router-outlet>