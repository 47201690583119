import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var RiderService = /** @class */ (function () {
    function RiderService(http) {
        this.http = http;
        this.baseUrl = environment.server + '/api/rider';
    }
    RiderService.prototype.fetchRiderDetails = function (riderId) {
        var requestBody = {
            riderId: riderId
        };
        return this.http.post(this.baseUrl + '/riderDetails', requestBody).pipe(catchError(this.handleError));
    };
    RiderService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    RiderService.ngInjectableDef = i0.defineInjectable({ factory: function RiderService_Factory() { return new RiderService(i0.inject(i1.MyHttpClient)); }, token: RiderService, providedIn: "root" });
    return RiderService;
}());
export { RiderService };
