import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.css']
})
export class PaymentSummaryComponent implements OnInit {

    @Input() data: any;
    rideId: any;
    tripDate: any;
    pickupLocation: any;
    dropLocation: any;
    bookingAndConvenienceFee: any;
    discount: any;
    distance: any;
    duration: string;
    rideCharge: string;
    total: string;
    cash: any;
    paytm: any;
    rapido: any;
    mobikwik: any;
    lazypay: any;
    amazonpay: any;
    upi: any;
    simpl: any;
    gpay: any;
    coin: any;
    qrcode: any;
    freecharge: string;
    prevDue: string;
    coinTotal: string;
    service: string;
    isCorporateOrder: boolean;
    coinUsedOnly: boolean;

    constructor() { }

    ngOnInit() {
        this.formatData();
    }

    formatData = () => {
        const data = this.data;
        this.rideId = data.rideId;
        this.tripDate = data.tripDate;
        this.pickupLocation = data.pickupLocation && data.pickupLocation.address || '';
        this.dropLocation = data.dropLocation && data.dropLocation.address || '';
        this.bookingAndConvenienceFee = `₹ ${data.bookingAndConvenienceFee}`;
        this.discount = `₹ ${data.discount}`;
        this.distance = `${data.distance} kms`;
        this.duration = `${data.duration} mins`;
        this.rideCharge = data.riderCharge && `₹ ${data.riderCharge.total}` || '0';
        this.prevDue = `₹ ${data.prevDue}`;
        this.service = data.service.toLowerCase();
        this.isCorporateOrder = data.isCorporateOrder;
        this.coinTotal = `₹ ${data.coins}`;
        this.coinUsedOnly = data.coinsUsedOnly;
        this.total = `₹ ${data.customerAmount}`

        if (data.orderAmount > 0) {
            const { cash, paytm, rapido, mobikwik, freecharge, lazypay, simpl, gpay, coin, amazonpay, upi, qrcode } = data.collected;
            this.cash = cash && `₹ ${cash}` || '';
            this.paytm = paytm && `₹ ${paytm}` || '';
            this.rapido = rapido && `₹ ${rapido}` || '';
            this.mobikwik = mobikwik && `₹ ${mobikwik}` || '';
            this.freecharge = freecharge && `₹ ${freecharge}` || '';
            this.lazypay = lazypay && `₹ ${lazypay}` || '';
            this.simpl = simpl && `₹ ${simpl}` || '';
            this.gpay = gpay && `₹ ${gpay}` || '';
            this.coin = coin && `₹ ${coin}` || '';
            this.amazonpay = amazonpay && `₹ ${amazonpay}` || '';
            this.upi = upi && `₹ ${upi}` || '';
            this.qrcode = qrcode && `₹ ${qrcode}` || '';
        }
    }

    showRapidoCoinDiscount = () => {
        const coins = parseFloat(this.data.coins);
        if (coins !== 0) {
            return true;
        }
        return false;
    }    

    showDiscount = () => {
        return this.data.discount;
    }

    showPreviousDue = () => {
        return this.data.prevDue;
    }
}
