/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adjustment-forms.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/single-select-dropdown/single-select-dropdown.component.ngfactory";
import * as i3 from "../shared/single-select-dropdown/single-select-dropdown.component";
import * as i4 from "../shared/input-box/input-box.component.ngfactory";
import * as i5 from "../shared/input-box/input-box.component";
import * as i6 from "@angular/common";
import * as i7 from "./adjustment-forms.component";
var styles_AdjustmentFormsComponent = [i0.styles];
var RenderType_AdjustmentFormsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdjustmentFormsComponent, data: {} });
export { RenderType_AdjustmentFormsComponent as RenderType_AdjustmentFormsComponent };
function View_AdjustmentFormsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "single-select-dropdown", [], null, [[null, "selectedDropDown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDropDown" === en)) {
        var pd_0 = (_co.updateData($event, _v.parent.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SingleSelectDropdownComponent_0, i2.RenderType_SingleSelectDropdownComponent)), i1.ɵdid(1, 114688, null, 0, i3.SingleSelectDropdownComponent, [], { singleDropdownData: [0, "singleDropdownData"], selectedValues: [1, "selectedValues"] }, { selectedDropDown: "selectedDropDown" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _v.parent.context.$implicit.default; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AdjustmentFormsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "input-euro left"]], null, null, null, null, null))], null, null); }
function View_AdjustmentFormsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "input-box", [], null, [[null, "selectedText"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedText" === en)) {
        var pd_0 = (_co.updateData($event, _v.parent.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_InputBoxComponent_0, i4.RenderType_InputBoxComponent)), i1.ɵdid(1, 114688, null, 0, i5.InputBoxComponent, [], { inputBoxData: [0, "inputBoxData"], selectedValues: [1, "selectedValues"] }, { selectedText: "selectedText" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.value); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AdjustmentFormsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_4)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_5)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_6)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.context.$implicit.type === "single-searchable-dropdown"); _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_v.context.$implicit.type === "text-input") && (_v.context.$implicit.title === "Amount")); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type === "text-input"); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.className ? _v.context.$implicit.className : "col-md-3"), ""); _ck(_v, 0, 0, currVal_0); }); }
function View_AdjustmentFormsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_3)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AdjustmentFormsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "padding-top-16 padding-left-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_2)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [], [[8, "className", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Submit"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info.rows; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", (_co.disableSubmit ? "submit-form-disabled" : "submit-form"), ""); var currVal_2 = _co.disableSubmit; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_AdjustmentFormsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "loadDataWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "svgIcon lock-icon position-absolute lock-icon-positioning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oops! you dont have access for this adjustment ¯\\_(ツ)_/¯ "]))], null, null); }
function View_AdjustmentFormsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "noDataWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ¯\\_(ツ)_/¯"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.info.emptyMessage || "No data available"); _ck(_v, 2, 0, currVal_0); }); }
export function View_AdjustmentFormsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "boxed-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "title"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "color-card-header padding-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_1)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_7)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdjustmentFormsComponent_8)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "title"; var currVal_1 = _co.info.headerClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (!_co.info.accessDenied && _co.info.dataFetched); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.info.accessDenied; _ck(_v, 9, 0, currVal_4); var currVal_5 = !_co.info.dataFetched; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.info.header; _ck(_v, 4, 0, currVal_2); }); }
export function View_AdjustmentFormsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-adjustment-forms", [], null, null, null, View_AdjustmentFormsComponent_0, RenderType_AdjustmentFormsComponent)), i1.ɵdid(1, 638976, null, 0, i7.AdjustmentFormsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdjustmentFormsComponentNgFactory = i1.ɵccf("app-adjustment-forms", i7.AdjustmentFormsComponent, View_AdjustmentFormsComponent_Host_0, { disableSubmit: "disableSubmit", info: "info", success: "success" }, { adjustmentDetails: "adjustmentDetails", changeRows: "changeRows" }, []);
export { AdjustmentFormsComponentNgFactory as AdjustmentFormsComponentNgFactory };
