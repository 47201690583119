/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./card.component";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fe fe-chevron-up"], ["style", "\n          color: white;\n      "]], null, null, null, null, null))], null, null); }
function View_CardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fe fe-chevron-down"], ["style", "\n          color: white;\n      "]], null, null, null, null, null))], null, null); }
function View_CardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "% "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.card.isPositivehike; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.card.isPositivehike; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card.displayPercentValue; _ck(_v, 1, 0, currVal_0); }); }
function View_CardComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fe fe-chevron-up"], ["style", "\n            color: white;\n        "]], null, null, null, null, null))], null, null); }
function View_CardComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["style", "font-size: 15px !important;"]], [[8, "title", 0], [2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.card.value && _co.card.value.className); _ck(_v, 1, 0, currVal_3); var currVal_4 = (_co.card.value && _co.card.value.style); _ck(_v, 2, 0, currVal_4); _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.card.value && _co.card.value.popoverIconTooltip), ""); var currVal_1 = i1.ɵnov(_v, 3).inline; var currVal_2 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = (_co.card.value && _co.card.value.popoverIcon); _ck(_v, 4, 0, currVal_5); }); }
function View_CardComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card.isPositivehike; _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.card == null) ? null : ((_co.card.value == null) ? null : _co.card.value.popover)) && ((_co.card == null) ? null : ((_co.card.value == null) ? null : _co.card.value.popoverIcon))); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CardComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["style", "\n          font-size: 20px !important;\n      "]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(3, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(4, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.card.value && _co.card.value.className); _ck(_v, 1, 0, currVal_2); var currVal_3 = (_co.card.value && _co.card.value.style); _ck(_v, 2, 0, currVal_3); _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).inline; var currVal_1 = (((i1.ɵnov(_v, 3).color !== "primary") && (i1.ɵnov(_v, 3).color !== "accent")) && (i1.ɵnov(_v, 3).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = (_co.card.value && _co.card.value.icon); _ck(_v, 4, 0, currVal_4); }); }
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "card"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "card-body p-3 text-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.card == null) ? null : _co.card.onClick()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "h1 m-0 clip-text"]], [[1, "title", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_8)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "text-muted mb-4 clip-text"]], [[1, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardStyle; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.card.style; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.card.hikePercent; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_co.card.hikePercent; _ck(_v, 8, 0, currVal_4); var currVal_6 = ((_co.card == null) ? null : ((_co.card.value == null) ? null : _co.card.value.icon)); _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.hikeClass; _ck(_v, 4, 0, currVal_2); var currVal_5 = _co.card.value; _ck(_v, 9, 0, currVal_5); var currVal_7 = (_co.card.value || "N/A"); _ck(_v, 12, 0, currVal_7); var currVal_8 = _co.card.label; _ck(_v, 13, 0, currVal_8); var currVal_9 = _co.card.label; _ck(_v, 14, 0, currVal_9); }); }
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i5.CardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i1.ɵccf("app-card", i5.CardComponent, View_CardComponent_Host_0, { card: "card" }, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
