var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ENTITY_TYPES, OrderService } from 'src/app/order-service.service';
import { Table, Pagination, TableDataTypes, TableData } from 'src/app/shared/types';
import { FilterService, SearchableDropDownFilterInput, SingleSearchableDropdown, QuickFilters } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { CaptainOrderDetailsService } from './captain-order-details.service';
import { CaptainFailedOrdersService } from '../captain-failed-orders/captain-failed-orders.service';
import { FraudService } from '../../fraud-component/fraud-view.service';
var currentDate = new Date();
var startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
HelperService.resetTimeTo(currentDate);
HelperService.resetTimeTo(startDate);
export var DEFAULT_DATE = {
    FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)),
    ONE_DAY_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 1)),
    CURRENT_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate())),
    EARNINGS_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7)),
    TO_DATE: currentDate
};
function defaultFiters() {
    var startDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)));
    var endDuration = HelperService.getDateOnlyV1(currentDate);
    return {
        startDuration: startDuration,
        endDuration: endDuration,
        cities: '',
        services: '',
        orderId: '',
        rating: '',
        status: 'all',
    };
}
var CaptainOrderDetailsComponent = /** @class */ (function () {
    function CaptainOrderDetailsComponent(orderService, filterService, entityService, dataService, router, captainOrderDetailsService, captainFailedOrdersService, fraudService) {
        this.orderService = orderService;
        this.filterService = filterService;
        this.entityService = entityService;
        this.dataService = dataService;
        this.router = router;
        this.captainOrderDetailsService = captainOrderDetailsService;
        this.captainFailedOrdersService = captainFailedOrdersService;
        this.fraudService = fraudService;
        this.toggleHeaders = new EventEmitter();
        this.fromDate = DEFAULT_DATE.FROM_DATE;
        this.toDate = DEFAULT_DATE.TO_DATE;
        this.filtersApplied = defaultFiters();
        this.cities = [];
        this.services = [];
        this.filterCount = 0;
        this.orderIds = [];
        this.statusList = [
            'all',
            'onTheWay',
            'arrived',
            'started',
            'dropped',
            'aborted',
            'customerCancelled'
        ];
        this.rating = { from: '0', to: '5' };
    }
    CaptainOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defineOrderTable(this.captainId);
        this.entityService.getCities().subscribe(function (cities) {
            var _a;
            (_a = _this.cities).push.apply(_a, cities);
        });
    };
    CaptainOrderDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.userId && this.userId && this.orderIds.length > 0) {
            this.appendSuspensionTag();
        }
    };
    CaptainOrderDetailsComponent.prototype.assignAppliedFilters = function (newFilter) {
        var _this = this;
        if (newFilter === void 0) { newFilter = {}; }
        Object.keys(this.filtersApplied).forEach(function (key) {
            delete _this.filtersApplied[key];
        });
        Object.assign(this.filtersApplied, newFilter);
    };
    CaptainOrderDetailsComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.dataService.searchUserCount.subscribe(function (result) {
            if (!_this.currentNumber || (_this.currentNumber === result)) {
                _this.filterService.appliedFilters = _this.filtersApplied;
            }
            else {
                // this.assignAppliedFilters(defaultFiters());
                _this.filterService.appliedFilters = {};
                _this.filtersApplied = defaultFiters();
            }
            _this.currentNumber = result;
        });
        this.orderTable = new Table({
            tableHeader: {
                toString: function () {
                    if (_this.fromDate.getTime() === DEFAULT_DATE.FROM_DATE.getTime() &&
                        _this.toDate.getTime() === DEFAULT_DATE.TO_DATE.getTime()) {
                        return "Order Details";
                    }
                    return "Order Details:- " + HelperService.dateString(_this.fromDate) + " - " + HelperService.dateString(_this.toDate);
                }
            },
            headers: {
                dateTime: 'Date \n Time',
                orderIdCity: 'OrderId \n City',
                service: 'Service',
                pickupDrop: 'Pickup \n Drop',
                customer: 'Customer',
                earnings: 'Earnings',
                adjustments: 'Adjustments',
                status: 'Status',
                ratings: 'Ratings',
            },
            selectedHeader: 0,
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            pagination: new Pagination(pageNumber, 10),
            config: {
                refresh: true,
            },
            onRefresh: function () {
                _this.orderTable = null;
                _this.defineOrderTable(_this.captainId, _this.fromDate, _this.toDate);
            },
            appliedFilters: this.filtersApplied,
            filterComponents: [
                // new DateFilterInput({
                //   key: 'duration',
                //   default: '',
                //   title: 'Duration'
                // }),
                new QuickFilters({
                    key: 'duration',
                    default: '',
                    title: 'Duration',
                }),
                new SearchableDropDownFilterInput({
                    key: 'cities',
                    title: 'Cities',
                    values: this.cities,
                    onChange: function (value) {
                        _this.entityService.getServices(value).subscribe(function (services) {
                            var _a;
                            _this.services.splice(0);
                            (_a = _this.services).push.apply(_a, services);
                        });
                    }
                }),
                new SearchableDropDownFilterInput({
                    key: 'services',
                    title: 'Services',
                    values: this.services,
                }),
                new SingleSearchableDropdown({
                    key: 'status',
                    title: 'Status',
                    values: this.statusList,
                }),
            ],
            onFilter: function () {
                var timeSlot = _this.filterService.appliedFilters.duration || ['', ''];
                var duration = [];
                if (!Array.isArray(timeSlot)) {
                    duration.push(timeSlot['start']);
                    duration.push(timeSlot['end']);
                }
                else {
                    duration = timeSlot;
                }
                var startDuration = duration[0];
                var endDuration = duration[1];
                var cities = _this.filterService.appliedFilters.cities;
                var services = _this.filterService.appliedFilters.services;
                var status = _this.filterService.appliedFilters.status;
                var filtersApplied = {
                    duration: {
                        start: startDuration,
                        end: endDuration,
                    },
                    startDuration: startDuration,
                    endDuration: endDuration,
                    cities: cities,
                    services: services,
                    status: status,
                };
                Object.assign(_this.filtersApplied, filtersApplied);
                var isValidNumber = function (value) {
                    return ((parseInt(value, 10) === 0) || value);
                };
                if ((startDuration && endDuration) || cities || services) {
                    startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
                    endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
                    return _this.defineOrderTable(_this.captainId, startDuration, endDuration, _this.filtersApplied);
                }
                return _this.defineOrderTable(_this.captainId, undefined, undefined, _this.filtersApplied);
            },
            data: []
        });
    };
    CaptainOrderDetailsComponent.prototype.onPaginationChange = function (event) {
        this.orderTable.data = [];
        this.fillDataToTable(DEFAULT_DATE.FROM_DATE, DEFAULT_DATE.TO_DATE, this.filtersApplied);
    };
    CaptainOrderDetailsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CaptainOrderDetailsComponent.prototype.getOrderList = function (captainId, fromDate, toDate, filters) {
        this.filterCount = 0;
        var dateFilter = [];
        var filterKeys = Object.keys(this.filtersApplied);
        var ignoreKeys = ['startDuration', 'endDuration'];
        if (this.filtersApplied && this.filtersApplied['duration']) {
            if (this.filtersApplied['duration'].start) {
                dateFilter.push(this.filtersApplied['duration'].start);
            }
            if (this.filtersApplied['duration'].end) {
                dateFilter.push(this.filtersApplied['duration'].end);
            }
        }
        if (dateFilter.length > 0) {
            this.filterCount++;
        }
        for (var i = 0; i < filterKeys.length; i++) {
            if (Array.isArray(this.filtersApplied[filterKeys[i]]) && (this.filtersApplied[filterKeys[i]]).length > 0) {
                this.filterCount++;
            }
            else if (typeof (this.filtersApplied[filterKeys[i]]) === 'object') {
                if (filterKeys[i] && filterKeys[i] === 'ratingInput' &&
                    ((this.filtersApplied[filterKeys[i]]['from']) || (this.filtersApplied[filterKeys[i]]['to']))) {
                    this.filterCount++;
                }
            }
            else {
                if (this.filtersApplied[filterKeys[i]] && ignoreKeys.indexOf(filterKeys[i]) === -1 &&
                    (this.filtersApplied[filterKeys[i]]).length > 0) {
                    this.filterCount++;
                }
            }
        }
        return this.orderService.getOrdersForCaptain(captainId, this.orderTable.pagination, fromDate, toDate, ENTITY_TYPES.CAPTAIN, filters);
    };
    CaptainOrderDetailsComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    CaptainOrderDetailsComponent.prototype.calculateAdjustmentsByOrderId = function (adjustments) {
        var STRING = {
            PENALTY: 'penalty',
        };
        var totalAdjustment = {};
        function toBeAdded(adjustment) {
            return adjustment.amount > 0 ? true : false;
        }
        function isProperTransactionType(adjustment) {
            return adjustment.transactionType === STRING.PENALTY;
        }
        adjustments.forEach(function (adjustment) {
            if (!adjustment) {
                return;
            }
            if (isProperTransactionType(adjustment)) {
                totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] || 0;
                if (toBeAdded(adjustment)) {
                    totalAdjustment[adjustment.orderId] += adjustment.amount;
                }
                else {
                    totalAdjustment[adjustment.orderId] -= Math.abs(adjustment.amount);
                }
            }
        });
        for (var adjustment in totalAdjustment) {
            if (!this.isEmpty(totalAdjustment[adjustment])) {
                totalAdjustment[adjustment] = totalAdjustment[adjustment].toString();
            }
        }
        return totalAdjustment;
    };
    CaptainOrderDetailsComponent.prototype.getEarningsAndAdjustments = function (adjustments) {
        var _this = this;
        var totalAdjustment = {};
        adjustments.forEach(function (adjustment) {
            if (!adjustment) {
                return;
            }
            if (adjustment.orderId) {
                totalAdjustment[adjustment.orderId] = {
                    earnings: !_this.isEmpty(adjustment.orderEarningsTotal) ? (adjustment.orderEarningsTotal).toString() : '',
                    adjustments: !_this.isEmpty(adjustment.orderAdjustmentsTotal) ? (adjustment.orderAdjustmentsTotal).toString() : ''
                };
            }
        });
        return totalAdjustment;
    };
    CaptainOrderDetailsComponent.prototype.fillDataToTable = function (fromDate, toDate, filters) {
        var _this = this;
        this.orderTable.dataWillLoad();
        this.getOrderList(this.captainObjectId, fromDate, toDate, filters).subscribe(function (_a) {
            var orders = _a.orders, count = _a.count;
            return __awaiter(_this, void 0, void 0, function () {
                function createTableData(value, className, hoverData, flag, suspendFlag) {
                    if (flag === void 0) { flag = false; }
                    if (suspendFlag === void 0) { suspendFlag = false; }
                    return new TableData({
                        data: value,
                        hoverData: hoverData ? hoverData : null,
                        type: TableDataTypes.DATA,
                        className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                        suspendFlag: suspendFlag,
                        flag: flag,
                        onClick: function (data) {
                            var newRelativeUrl = self.router.createUrlTree(["/order/" + data._id.data]);
                            var baseUrl = window.location.href.replace(self.router.url, '');
                            window.open(baseUrl + newRelativeUrl, '_blank');
                        }
                    });
                }
                var self, orderIdToAdjustmentsMap, orderIds, FifteenDaysPostMaxDate, adjustments, _b;
                var _this = this;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            self = this;
                            orderIdToAdjustmentsMap = {};
                            orderIds = [];
                            this.minDate = '', this.maxDate = '';
                            orders.map(function (order) {
                                orderIds.push(order._id);
                                var currentDate = order.date.toISOString();
                                _this.minDate = _this.minDate && (_this.minDate < currentDate) ? _this.minDate : currentDate;
                                _this.maxDate = _this.maxDate && (_this.maxDate > currentDate) ? _this.maxDate : currentDate;
                            });
                            if (this.minDate) {
                                this.minDate = this.minDate.substring(0, 10);
                            }
                            if (this.maxDate) {
                                FifteenDaysPostMaxDate = new Date(this.maxDate);
                                FifteenDaysPostMaxDate.setDate(FifteenDaysPostMaxDate.getDate() + parseInt("15"));
                                this.maxDate = FifteenDaysPostMaxDate.toISOString().substring(0, 10);
                            }
                            this.orderIds = orderIds;
                            return [4 /*yield*/, this.captainOrderDetailsService.getOrderAdjustments(orderIds).toPromise()];
                        case 1:
                            adjustments = _c.sent();
                            _b = this;
                            return [4 /*yield*/, this.fraudService.checkFraudOrders({ orders: orderIds }).toPromise()];
                        case 2:
                            _b.fraudOrdersList = _c.sent();
                            if (adjustments && adjustments['data'] && adjustments['data'].data && adjustments['data'].data.length) {
                                orderIdToAdjustmentsMap = this.getEarningsAndAdjustments(adjustments['data'].data);
                            }
                            this.orders = orders.map(function (order) {
                                var orderId = order._id;
                                var orderDate = new Date(order.date);
                                var self = _this;
                                var flag = _this.fraudOrdersList.orders[orderId] ? true : false;
                                return {
                                    _id: createTableData(orderId),
                                    dateTime: createTableData(HelperService.dateString(orderDate) + '\n' + order.orderTime, null, null, flag),
                                    orderIdCity: new TableData({
                                        data: order.orderId + '\n' + order.city,
                                        type: TableDataTypes.DATA,
                                        className: 'cursor-pointer whiteSpacePre',
                                        onClick: function (data) {
                                            var newRelativeUrl = self.router.createUrlTree(["/order/" + data._id.data]);
                                            var baseUrl = window.location.href.replace(self.router.url, '');
                                            window.open(baseUrl + newRelativeUrl, '_blank');
                                        }
                                    }),
                                    service: createTableData(order.service),
                                    pickupDrop: createTableData(order.pickup + '\n' + order.drop),
                                    customer: createTableData(order.customer),
                                    earnings: createTableData(orderIdToAdjustmentsMap[orderId] && orderIdToAdjustmentsMap[orderId].earnings),
                                    adjustments: createTableData(orderIdToAdjustmentsMap[orderId] && orderIdToAdjustmentsMap[orderId].adjustments),
                                    status: createTableData(order.status, null, order.cancelReason || null),
                                    ratings: createTableData(order.customerRating),
                                };
                            });
                            this.orderTable.data = this.orders;
                            this.orderTable.filterCount = this.filterCount;
                            this.orderTable.pagination.count = count;
                            if (this.userId && this.orderIds.length > 0) {
                                this.appendSuspensionTag();
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    CaptainOrderDetailsComponent.prototype.defineOrderTable = function (captainId, fromDate, toDate, filters) {
        if (fromDate === void 0) { fromDate = DEFAULT_DATE.FROM_DATE; }
        if (toDate === void 0) { toDate = DEFAULT_DATE.TO_DATE; }
        if (filters === void 0) { filters = this.filtersApplied; }
        this.declareTable();
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.fillDataToTable(fromDate, toDate, filters);
    };
    CaptainOrderDetailsComponent.prototype.appendSuspensionTag = function () {
        return __awaiter(this, void 0, void 0, function () {
            var suspendLogs, suspendOrders, index, currentOrderId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.captainFailedOrdersService.getSuspensionLog(this.userId, "suspend", "customer_cancelled", this.orderIds, this.minDate, this.maxDate).toPromise()];
                    case 1:
                        suspendLogs = _a.sent();
                        suspendOrders = suspendLogs['suspendedOrdersMap'];
                        for (index = 0; index < this.orderTable.data.length; ++index) {
                            currentOrderId = this.orderTable.data[index]._id.data;
                            this.orderTable.data[index].dateTime.suspendFlag = suspendOrders && suspendOrders[currentOrderId] ? true : false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return CaptainOrderDetailsComponent;
}());
export { CaptainOrderDetailsComponent };
