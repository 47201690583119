<div class="row side-padding-15 is-flex search-container  ">
  <div class="pull-right">
    <div class="form-group has-feedback is-flex">
      <div class="form-group has-feedback">
        <input type="number" [(ngModel)]="mobile" (keyup.enter)="searchMerchant()" onblur="this.placeholder = 'Search by Merchant number'"
        onfocus="this.placeholder = ''"
        [ngClass]="{'padding-left-30':focus===false && !enteredNumber}" (focusout)="focus=false" (focus)="focus=true" 
        placeholder="Search by merchant mobile no." class="form-control width-265" id="searchMerchant"/>
        <span [ngClass]="{'glyphicon glyphicon-search form-control-feedback': focus===false && !enteredNumber}"></span>
      </div>
      <label class="search-button" (click)="searchMerchant()">Go</label>
    </div>
  </div>
</div>
<router-outlet></router-outlet>