<div>
  <form [formGroup]="tollsFormGroup">
    <div class="padding-10-10-0-0 row">
      <div class="col-md-4">
        <span class="info-title">Toll entry line</span>
      </div>
      <div class="col-md-8">
        <ng-select formControlName="selectedEntryTollLine" name="entryToll" placeholder="Select entry toll"
          (change)="onTollLineChange()" (clear)="onTollLineChange()">
          <ng-option *ngFor="let entryTollLine of entryTollLines" [value]="entryTollLine">
            {{entryTollLine}}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="padding-10-10-0-0 row">
      <div class="col-md-4">
        <span class="info-title">Toll exit line</span>
      </div>
      <div class="col-md-8">
        <ng-select formControlName="selectedExitTollLine" name="exitToll" placeholder="Select exit toll"
          (change)="onTollLineChange()" (clear)="onTollLineChange()">
          <ng-option *ngFor="let exitTollLine of exitTollLines" [value]="exitTollLine">
            {{exitTollLine}}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </form>
  <div *ngIf="associatedTollFound" class="padding-10-10-10-0">
    <div class="row">
      <div class="col-md-6">
        <p>Toll name:</p>
      </div>
      <div class="col-md-4">
        <p class="info-title">{{currentAssociatedToll.tollRoadName}}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <p>Toll amount:</p>
      </div>
      <div class="col-md-4">
        <p class="info-title">{{currentAssociatedToll.amount}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="entryExitTollLinesSelected && !associatedTollFound" class="padding-10-10-10-0">
    <div class="col-md-12">
      <p class="warning">No toll is available in this selection.</p>
    </div>
  </div>
</div>