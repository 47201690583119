import { Component, OnInit, Input, Output, EventEmitter,OnChanges, SimpleChanges } from '@angular/core';
import { ENTITY_TYPES, OrderService } from 'src/app/order-service.service';
import { Table, Pagination, TableDataTypes, TableData } from 'src/app/shared/types';
import { FilterService, DateFilterInput, TextFilterInput, SearchableDropDownFilterInput,
  RangeSliderInput, SingleSearchableDropdown, QuickFilters} from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { CaptainOrderDetailsService } from './captain-order-details.service';
import { CaptainFailedOrdersService } from '../captain-failed-orders/captain-failed-orders.service';
import { FraudService } from '../../fraud-component/fraud-view.service';


const currentDate = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
HelperService.resetTimeTo(currentDate);
HelperService.resetTimeTo(startDate);
export const DEFAULT_DATE = {
  FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)),
  ONE_DAY_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 1)),
  CURRENT_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate())),
  EARNINGS_FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 7)),
  TO_DATE: currentDate
};
function defaultFiters() {
  let startDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)));
  let endDuration = HelperService.getDateOnlyV1(currentDate);
  return {
    startDuration,
    endDuration,
    cities: '',
    services: '',
    orderId: '',
    rating: '',
    status: 'all',
  };
}
@Component({
  selector: 'app-captain-order-details',
  templateUrl: './captain-order-details.component.html',
  styleUrls: ['./captain-order-details.component.css']
})
export class CaptainOrderDetailsComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() captainObjectId: string;
  @Input() captainId: string;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  currentNumber: any;
  orders: any[];
  orderTable: Table;
  fromDate = DEFAULT_DATE.FROM_DATE;
  toDate = DEFAULT_DATE.TO_DATE;
  filtersApplied = defaultFiters();
  cities: any = [];
  services: any = [];
  filterCount = 0;
  orderIds = [] ;
  minDate: string;
  maxDate: string; 
  statusList: any = [
    'all',
    'onTheWay',
    'arrived',
    'started',
    'dropped',
    'aborted',
    'customerCancelled'
  ];
  rating = {from : '0', to : '5'};
  fraudOrdersList: any;

  constructor(
    public orderService: OrderService,
    public filterService: FilterService,
    public entityService: EntityService,
    private dataService: DataService,
    private router: Router,
    private captainOrderDetailsService: CaptainOrderDetailsService,
    private captainFailedOrdersService: CaptainFailedOrdersService,
    private fraudService: FraudService
  ) { }

  ngOnInit() {
    this.defineOrderTable(this.captainId);
    this.entityService.getCities().subscribe(cities => {
      this.cities.push(...cities);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes && changes.userId  && this.userId && this.orderIds.length > 0) {
      this.appendSuspensionTag();
    }
  }

  private assignAppliedFilters(newFilter = {}) {
    Object.keys(this.filtersApplied).forEach(key => {
      delete this.filtersApplied[key];
    });
    Object.assign(this.filtersApplied, newFilter);
  }

  private declareTable(pageNumber = 0) {
    this.dataService.searchUserCount.subscribe(result => {
      if (!this.currentNumber || (this.currentNumber === result)) {
        this.filterService.appliedFilters = this.filtersApplied;
      } else {
        // this.assignAppliedFilters(defaultFiters());
        this.filterService.appliedFilters = {};
        this.filtersApplied = defaultFiters();
      }
      this.currentNumber = result;
    });
    this.orderTable = new Table({
      tableHeader: {
        toString: () => {
          if (this.fromDate.getTime() === DEFAULT_DATE.FROM_DATE.getTime() &&
          this.toDate.getTime() === DEFAULT_DATE.TO_DATE.getTime()
          ) {
            return `Order Details`;
          }
          return `Order Details:- ${HelperService.dateString(this.fromDate)} - ${HelperService.dateString(this.toDate)}`;
        }
      },
      headers: {
        dateTime: 'Date \n Time',
        orderIdCity: 'OrderId \n City',
        service: 'Service',
        pickupDrop: 'Pickup \n Drop',
        customer: 'Customer',
        earnings: 'Earnings',
        adjustments: 'Adjustments',
        status: 'Status',
        ratings: 'Ratings',
      },
      selectedHeader: 0,
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.orderTable = null;
        this.defineOrderTable(this.captainId, this.fromDate, this.toDate);
      },
      appliedFilters: this.filtersApplied,
      filterComponents: [
        // new DateFilterInput({
        //   key: 'duration',
        //   default: '',
        //   title: 'Duration'
        // }),
        new QuickFilters({
          key: 'duration',
          default: '',
          title: 'Duration',
        }),
        new SearchableDropDownFilterInput({
          key : 'cities',
          title: 'Cities',
          values: this.cities,
          onChange: (value) => {
            this.entityService.getServices(value).subscribe(services => {
              this.services.splice(0);
              this.services.push(...services);
            });
          }
        }),
        new SearchableDropDownFilterInput({
          key : 'services',
          title: 'Services',
          values: this.services,
        }),
        new SingleSearchableDropdown({
          key : 'status',
          title: 'Status',
          values: this.statusList,
        }),
      ],
      onFilter: () => {
        const timeSlot = this.filterService.appliedFilters.duration || ['', ''];
        let duration = [];
        if (!Array.isArray(timeSlot)) {
          duration.push(timeSlot['start']);
          duration.push(timeSlot['end']);
        } else {
          duration = timeSlot;
        }
        let startDuration = duration[0];
        let endDuration = duration[1];
        const cities = this.filterService.appliedFilters.cities;
        const services = this.filterService.appliedFilters.services;
        const status = this.filterService.appliedFilters.status;
        const filtersApplied = {
          duration: {
            start: startDuration,
            end: endDuration,
          },
          startDuration,
          endDuration,
          cities,
          services,
          status,
        };
        Object.assign(this.filtersApplied, filtersApplied);
        const isValidNumber = (value) => {
          return ((parseInt(value, 10) === 0) || value);
        };
        if ((startDuration && endDuration)  || cities || services) {
          startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
          endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
          return this.defineOrderTable(this.captainId, startDuration, endDuration, this.filtersApplied);
        }
        return this.defineOrderTable(this.captainId, undefined, undefined, this.filtersApplied);
      },
      data: []
    });
  }

  onPaginationChange(event) {
    this.orderTable.data = [];
    this.fillDataToTable(DEFAULT_DATE.FROM_DATE, DEFAULT_DATE.TO_DATE, this.filtersApplied);
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  getOrderList(captainId: string, fromDate?: Date, toDate?: Date, filters?: any) {
    this.filterCount = 0;
    const dateFilter = [];
    const filterKeys = Object.keys(this.filtersApplied);
    const ignoreKeys = ['startDuration', 'endDuration'];
    if (this.filtersApplied && this.filtersApplied['duration']) {
      if (this.filtersApplied['duration'].start) {
        dateFilter.push(this.filtersApplied['duration'].start);
      }
      if (this.filtersApplied['duration'].end) {
        dateFilter.push(this.filtersApplied['duration'].end);
      }
    }
    if (dateFilter.length > 0) {
      this.filterCount++;
    }
    for (let i = 0; i < filterKeys.length ; i++) {
      if (Array.isArray(this.filtersApplied[filterKeys[i]]) && (this.filtersApplied[filterKeys[i]]).length > 0) {
        this.filterCount++;
      } else if (typeof(this.filtersApplied[filterKeys[i]]) === 'object') {
        if (filterKeys[i] && filterKeys[i] === 'ratingInput' &&
        ((this.filtersApplied[filterKeys[i]]['from']) || (this.filtersApplied[filterKeys[i]]['to']))) {
          this.filterCount++;
        }
      } else {
        if (this.filtersApplied[filterKeys[i]] && ignoreKeys.indexOf(filterKeys[i]) === -1 &&
            (this.filtersApplied[filterKeys[i]]).length > 0) {
          this.filterCount++;
        }
      }
    }
    return this.orderService.getOrdersForCaptain(captainId, this.orderTable.pagination, fromDate, toDate, ENTITY_TYPES.CAPTAIN, filters);
  }

  isEmpty(value) {
    return (value == null || value.length === 0);
  }


  private calculateAdjustmentsByOrderId(adjustments) {
    const STRING = {
      PENALTY: 'penalty',
    };
    const totalAdjustment = {};
    function toBeAdded(adjustment) {
      return adjustment.amount > 0 ? true : false;
    }

     function isProperTransactionType(adjustment) {
      return adjustment.transactionType === STRING.PENALTY;
    }
    adjustments.forEach(adjustment => {
      if (!adjustment) {
        return;
      }
      if (isProperTransactionType(adjustment)) {
        totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] || 0;
        if (toBeAdded(adjustment)) {
          totalAdjustment[adjustment.orderId] += adjustment.amount;
        } else {
          totalAdjustment[adjustment.orderId] -= Math.abs(adjustment.amount);
        }
      }
    });
    for (const adjustment in totalAdjustment) {
      if (!this.isEmpty(totalAdjustment[adjustment])) {
        totalAdjustment[adjustment] = totalAdjustment[adjustment].toString();
      }
    }
    return totalAdjustment;
  }

  private getEarningsAndAdjustments(adjustments) {
    const totalAdjustment = {};
    adjustments.forEach(adjustment => {
      if (!adjustment) {
        return;
      }
      if (adjustment.orderId) {
        totalAdjustment[adjustment.orderId] = {
          earnings: !this.isEmpty(adjustment.orderEarningsTotal) ? (adjustment.orderEarningsTotal).toString() : '',
          adjustments: !this.isEmpty(adjustment.orderAdjustmentsTotal) ? (adjustment.orderAdjustmentsTotal).toString() : ''
        };
      }
    });
    return totalAdjustment;
  }

  fillDataToTable(fromDate?: Date, toDate?: Date, filters?: any) {
    this.orderTable.dataWillLoad();
    this.getOrderList(this.captainObjectId, fromDate, toDate, filters).subscribe(async({orders, count}) => {
      // this.declareTable();
      const self = this;
      function createTableData(value, className?, hoverData?, flag = false, suspendFlag = false) {
        return new TableData({
          data: value,
          hoverData: hoverData ? hoverData : null,
          type: TableDataTypes.DATA,
          className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
          suspendFlag: suspendFlag,
          flag,
          onClick: (data) => {
            const newRelativeUrl = self.router.createUrlTree([`/order/${data._id.data}`]);
            const baseUrl = window.location.href.replace(self.router.url, '');
            window.open(baseUrl + newRelativeUrl, '_blank');
          }
        });
      }
      let orderIdToAdjustmentsMap = {};
      var orderIds = [] ;
      this.minDate = '' , this.maxDate = '';
        orders.map(order =>{
         orderIds.push(order._id);
         var currentDate= order.date.toISOString();
         this.minDate = this.minDate && (this.minDate < currentDate) ? this.minDate : currentDate ;
         this.maxDate = this.maxDate && (this.maxDate > currentDate) ? this.maxDate : currentDate ;
      });

      

      
      if(this.minDate){
        this.minDate = this.minDate.substring(0,10);
      }
      if(this.maxDate){
        const FifteenDaysPostMaxDate = new Date(this.maxDate);
        FifteenDaysPostMaxDate.setDate(FifteenDaysPostMaxDate.getDate() + parseInt("15"));
        this.maxDate = FifteenDaysPostMaxDate.toISOString().substring(0,10);
      }
      this.orderIds = orderIds ;
      
      const adjustments = await this.captainOrderDetailsService.getOrderAdjustments(orderIds).toPromise();
      this.fraudOrdersList = await this.fraudService.checkFraudOrders({ orders: orderIds }).toPromise();

      if (adjustments && adjustments['data'] && adjustments['data'].data && adjustments['data'].data.length) {
        orderIdToAdjustmentsMap = this.getEarningsAndAdjustments(adjustments['data'].data);
      }

      this.orders = orders.map(order => {
        const orderId = order._id;
        const orderDate = new Date(order.date);
        const self = this;
        const flag = this.fraudOrdersList.orders[orderId] ? true : false;
        return {
          _id: createTableData(orderId),
          dateTime: createTableData(HelperService.dateString(orderDate) + '\n' + order.orderTime, null, null, flag),
          orderIdCity: new TableData({
            data: order.orderId + '\n' + order.city,
            type: TableDataTypes.DATA,
            className: 'cursor-pointer whiteSpacePre',
            onClick: (data) => {
              const newRelativeUrl = self.router.createUrlTree([`/order/${data._id.data}`]);
              const baseUrl = window.location.href.replace(self.router.url, '');
              window.open(baseUrl + newRelativeUrl, '_blank');
            }
          }),
          service: createTableData(order.service),
          pickupDrop: createTableData(order.pickup + '\n' + order.drop),
          customer: createTableData(order.customer),
          earnings: createTableData(orderIdToAdjustmentsMap[orderId] && orderIdToAdjustmentsMap[orderId].earnings),
          adjustments: createTableData(orderIdToAdjustmentsMap[orderId] && orderIdToAdjustmentsMap[orderId].adjustments),
          status: createTableData(order.status, null, order.cancelReason || null),
          ratings: createTableData(order.customerRating),
        };
      });

      this.orderTable.data = this.orders;
      this.orderTable.filterCount = this.filterCount;
      this.orderTable.pagination.count = count;

      if(this.userId && this.orderIds.length > 0) {
        this.appendSuspensionTag() ;
      }
  
    return 

    });
  }

  defineOrderTable(captainId: string, fromDate = DEFAULT_DATE.FROM_DATE, toDate = DEFAULT_DATE.TO_DATE,
    filters = this.filtersApplied) {
      this.declareTable();
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.fillDataToTable(fromDate, toDate, filters);
  }
  async appendSuspensionTag () {
    const suspendLogs = await this.captainFailedOrdersService.getSuspensionLog(this.userId, "suspend", "customer_cancelled", this.orderIds, this.minDate, this.maxDate).toPromise();
    const suspendOrders = suspendLogs['suspendedOrdersMap'];
    for (let index = 0; index < this.orderTable.data.length; ++index) {
      const currentOrderId = this.orderTable.data[index]._id.data;
      this.orderTable.data[index].dateTime.suspendFlag = suspendOrders && suspendOrders[currentOrderId] ? true : false;
   }
  }
}
