import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CustomerStatsService } from './customer-stats.service';
import { ToastrService } from 'ngx-toastr';
const IMAGES = {
  noOfOrders: {
    'background-image': `url('/assets/svgImages/no_of_orders.svg')`
  },
  starRating: {
    'background-image': `url('/assets/svgImages/star.svg')`,
  },
  lastAppLogin: {
    'backgroundImage': `url('/assets/svgImages/last_login.svg')`
  },
  lastOrderDate: {
    'background-image': `url('/assets/svgImages/last_order_date.svg')`,
  },
  avgRating: {
    'background-image': `url('/assets/svgImages/avg_rating.svg')`
  }
};

@Component({
  selector: 'app-customer-stats',
  templateUrl: './customer-stats.component.html',
  styleUrls: ['./customer-stats.component.css']
})
export class CustomerStatsComponent implements OnInit, OnChanges {
  @Input() data: any;
  public todayDate = this.formatDate(new Date());
  public dateRange = 'Today, ' + this.todayDate;
  public info: any;
  public status: string;
  public dataLoaded = false;

  constructor(
    private customerStatsService: CustomerStatsService,
    private toastr: ToastrService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.dataLoaded = false;
    if (changes.data && changes.data.currentValue) {
      if (changes.data.currentValue === 'empty') {
        this.emptyCard();
      } else {
        this.data = changes.data.currentValue;
        this.constructCard();
      }
    } else {
      this.emptyCard();
    }
  }

  ngOnInit() {
  }

  emptyCard() {
    this.info = {
      stats: [],
      dataFetched: false
    };
    this.dataLoaded = true;
  }

  async constructCard() {
    if (this.data) {
      const averageCustomerRatingFromSvoc = await this.customerStatsService.getAverageRatingFromSvoc(this.data.id);
      this.dataLoaded = true;
      this.info = {
        dataFetched: true,
        stats: [
          {header : 'Average ratings', value : averageCustomerRatingFromSvoc ? averageCustomerRatingFromSvoc : 'N/A',
                    style: IMAGES.avgRating},
          {header : 'Last order date', value : this.data.lastOrderDate, style: IMAGES.lastOrderDate},
          {header : 'Last app login', value : this.data.lastAppLogin, style: IMAGES.lastAppLogin}
        ],
        dateRange: this.dateRange
      };
    } else {
      this.dataLoaded = true;
    }
  }

  refresh() {
    this.dataLoaded = false;
    this.constructCard();
  }

  formatDate(date) {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }
}
