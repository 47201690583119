import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/order-service.service';
import { CaptainFraudOrdersService } from './captain-fraud-orders.service';
import { FilterService } from 'src/app/filter.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CaptainFailedOrdersService } from '../captain-failed-orders/captain-failed-orders.service';

const PAGE_LIMIT = 10;

@Component({
  selector: 'app-captain-fraud-orders',
  templateUrl: './captain-fraud-orders.component.html',
  styleUrls: ['./captain-fraud-orders.component.css']
})
export class CaptainFraudOrdersComponent implements OnInit {
  fraudOrderPayload: any;
  orderTable: Table;
  fraudType: string;
  breadcrumb = faChevronRight;
  orders: any[];
  orderIDArr: any[] = [];
  fraudOrderLen: number;

  public riderId: string;
  public riderCity: string;
  public riderMobile: string;

  constructor(
    public orderService: OrderService,
    public entityService: EntityService,
    private route: ActivatedRoute,
    private router: Router,
    public CaptainFraudOrdersService: CaptainFraudOrdersService,
    public captainFailedOrdersService: CaptainFailedOrdersService,
    public filterService: FilterService
  ) { }

  ngOnInit() {
    this.riderMobile = this.route.snapshot.params.mobile ? this.route.snapshot.params.mobile : '';
    this.filterService.showFilters = false;

    this.route.queryParams.subscribe(params => {
      this.fraudOrderPayload = {
        'fromDate':params['fromDate'],
	      'toDate':params['toDate'],
	      'frauds':[params['frauds']]
      }

      this.fraudType = params['frauds'];
    });

    const riderMobile = HelperService.decode(this.riderMobile);
    this.entityService.getEntityByNumber(riderMobile).subscribe(resp => {
      if (resp) {
        if (resp.userId._id) {
          this.riderId = resp.userId._id;
          this.defineOrderTable();
        }
      }
    });
  }

  onPaginationChange(event) {
    this.orderTable.data = [];
    this.fillDataToTable(event.pageNo);
  }

  redirectToCaptainDetails() {
    this.router.navigate([
      'captain/' + this.riderMobile
    ]);
  }

  private declareTable(pageNumber = 0) {
    this.orderTable = new Table({
      tableHeader: 'Fraud Orders',
      headers: {
        dateTime: 'Date & Time',
        orderId: 'Order Id',
        pickupLocation: 'Pickup',
        service: 'Service',
        orderStatus: 'Order Status'
      },
      pagination: new Pagination(pageNumber, PAGE_LIMIT),
      config: {
        refresh: true,
        filter: false
      },
      onRefresh: () => {
        this.orderTable = null;
        this.ngOnInit();
      },
      data: []
    });
  }

  getBulkOrderList(fraudOrderPayload: any) {
    return this.orderService.getOrders(fraudOrderPayload);
  }

  getFraudOrderIds(fraudOrderPayload: any) {
    return this.CaptainFraudOrdersService.getFraudOrders(fraudOrderPayload);
  }

  fillDataToTable(pageNumber = 0) {
        const payload = {
          'limit': 50,
          'searchConditions': {
            'orderIds': this.orderIDArr[pageNumber]
          },
          'requiredFields': ['status', 'customerId', 'captain', 'pickupLocation', 'dropLocation', 'serviceDetail.service', 'orderTime', 'createdOn', 'uniqueId']
        }

        this.getBulkOrderList(payload).subscribe(async (response) => {
          const orderIds = [];

          function createTableData(value, className?) {
            return new TableData({
              data: value,
              type: TableDataTypes.DATA,
              className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
              onClick: (data) => {
                HelperService.redirectToOldDashboard(`/order/${data._id.data}`);
              }
            });
          }

          if (response && response['data'] && response['data'].data) {
            this.orders = response['data'].data.map((order, i) => {
              const orderId = order._id;
              const orderDate = new Date(order.orderTime.date);

              orderIds.push(orderId);

              return {
                _id: createTableData(orderId),
                dateTime: createTableData(HelperService.dateString(orderDate) + ', ' + this.formatAMPM(new Date(order.createdOn))),
                orderId: new TableData({
                  data: order.uniqueId,
                  type: TableDataTypes.DATA,
                  className: 'cursor-pointer whiteSpacePre',
                  onClick: (data) => {
                    HelperService.redirectToOldDashboard(`/order/${data._id.data}`);
                  }
                }),
                pickupLocation: createTableData(order.pickupLocation.address),
                service: createTableData(order.serviceDetail.service.name),
                orderStatus: createTableData(this.formatEventName(order.status))
              };
            });

            this.orderTable.pagination.count = this.fraudOrderLen;
          }

          this.orderTable.data = this.orders;
        })    
  }

  formatEventName(name) {
    const event = name.split('_');

    if (event.length > 1) {
      const str = event.join(' ');
      return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    } else {
      return name;
    }
  }

  formatAMPM(date) {
    let strTime, hours, minutes, ampm;

    hours = date.getHours();
    minutes = date.getMinutes();
    ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
  }

  defineOrderTable() {
    this.declareTable();

    if (this.riderId) {
      this.orderTable.dataWillLoad();
      this.fraudOrderPayload['rider'] = this.riderId;

      this.getFraudOrderIds(this.fraudOrderPayload).subscribe(async (resp: any) => {
        let fraudOrdersArr = [...resp.data['orders']];
        this.fraudOrderLen = fraudOrdersArr.length;

        if (this.fraudOrderLen > PAGE_LIMIT) {
          let recordsPerPage = Math.ceil(this.fraudOrderLen/PAGE_LIMIT);

          for(let i=1; i<=recordsPerPage; i++) {
            let temp = fraudOrdersArr.splice(0, PAGE_LIMIT)

            this.orderIDArr.push(temp);
          }
        } else {
          this.orderIDArr.push(fraudOrdersArr);
        }

        this.fillDataToTable();
      })
    } else {
      console.log('Something went wrong');
    }
  }
}
