import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Table } from '../shared/types';
import { PaymentStatusService } from './payment-status.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  txnListData: Table;
  public updatedFraudData: any;

  constructor(
    private paymentStatusService: PaymentStatusService,
    public dialogRef: MatDialogRef<PaymentStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.declareTable() 
  }

  declareTable() {
    this.txnListData = new Table({
      tableHeader: this.data.title,

      headers: {
        id:                     "S.No",
        correlationId:          "correlation Id",
        source:                 "Source",
        rapidoTxnStatus:        "rapido Status",
        rapidoTxnTime:          "Rapido Date Time",
        thirdPartyTxnStatus:    "3rd Party Status",
        thirdPartyTxnTime:      "3rd Party Date Time",
        email:                  "Email",
        amount:                 "Amount",
        vpa:                    "VPA",
        rrn:                    "RRN",
        upi_transaction_id:     "UPI Txn Id",
        qr_transaction_id:      "QR Txn Id"
      },
      config: {
        refresh: false,
        filter: false
      }
    })

    this.loadTableData()
    
  }

  doRefresh() {
    this.txnListData.data = []
    this.loadTableData()
  }


  loadTableData() {
    const that = this;
    const gatewayIds = this.data.info.gatewayIds;
    this.paymentStatusService.getQrUpiData({
      "gatewayIds" : gatewayIds
    }).subscribe((response: any) => {
      if(response.statusCode == 200) {
        let tmp = []
        for(let i=0; i<response.txnInfo.length; i++) {
          tmp.push({
            id:                     i+1,
            correlationId:          response.txnInfo[i].correlationId || "-",
            source:                 response.txnInfo[i].source || "-",
            rapidoTxnStatus:        response.txnInfo[i].rapidoTxnStatus || "-",
            thirdPartyTxnStatus:    response.txnInfo[i].thirdPartyTxnStatus || "-",
            rapidoTxnTime:          response.txnInfo[i].rapidoTxnTime || "-",
            thirdPartyTxnTime:      response.txnInfo[i].thirdPartyTxnTime || "-",
            email:                  response.txnInfo[i].email || "-",
            amount:                 response.txnInfo[i].amount || "-",
            vpa:                    response.txnInfo[i].vpa || "-",
            rrn:                    response.txnInfo[i].rnn || "-",
            upi_transaction_id:     response.txnInfo[i].upiTxnId || "-",
            qr_transaction_id:      response.txnInfo[i].qrTxnId || "-"
            
          })
        }
        if(that.txnListData.data.length == 0) {
          that.txnListData.data = []
        }

        that.txnListData.data = [...that.txnListData.data, ...tmp]
      }
    })
  }
}
