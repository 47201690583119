var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerDetailsService } from 'src/app/customer-details/customer-details.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { DataService } from '../../data.service';
import { OrderDetailsService } from 'src/app/order-details/order-details.service';
import { EntityService } from 'src/app/entity.service';
import { SingleSearchableDropdown } from 'src/app/filter.service';
var SEARCH_BY = {
    ORDER_ID: 'orderId',
    MOBILE: 'mobile',
    EMAIL: 'email',
};
var PLACEHOLDER = (_a = {},
    _a[SEARCH_BY.MOBILE] = 'Search by Customer number',
    _a[SEARCH_BY.ORDER_ID] = 'Search by Order Id',
    _a[SEARCH_BY.EMAIL] = 'Search by Customer email',
    _a);
var CustomerSearchComponent = /** @class */ (function () {
    function CustomerSearchComponent(router, activedRoute, customerSerivce, toasterService, dataservice, orderDetailService, entityService) {
        var _this = this;
        this.router = router;
        this.activedRoute = activedRoute;
        this.customerSerivce = customerSerivce;
        this.toasterService = toasterService;
        this.dataservice = dataservice;
        this.orderDetailService = orderDetailService;
        this.entityService = entityService;
        this.mobile = '';
        this.enteredNumber = false;
        this.focus = false;
        this.SEARCH_BY = SEARCH_BY;
        this._searchBy = SEARCH_BY.MOBILE;
        this.searchKeyword = '';
        this.placeholder = PLACEHOLDER[this.searchBy];
        this.filterTypeSelect = new SingleSearchableDropdown({
            key: 'value',
            title: '',
            values: [
                SEARCH_BY.MOBILE,
                SEARCH_BY.EMAIL,
                SEARCH_BY.ORDER_ID,
            ],
            default: SEARCH_BY.MOBILE,
            onChange: function (event) {
                console.log(event);
                var filterType = event.value;
                _this.searchBy = filterType;
            }
        });
    }
    Object.defineProperty(CustomerSearchComponent.prototype, "searchBy", {
        get: function () {
            return this._searchBy;
        },
        set: function (value) {
            this._searchBy = value;
            this.placeholder = PLACEHOLDER[value];
        },
        enumerable: true,
        configurable: true
    });
    CustomerSearchComponent.prototype.ngOnInit = function () {
        this.mobile = this.activedRoute.snapshot.paramMap.get('id');
        this.activedRoute.parent.params.subscribe(function (params) {
        });
    };
    CustomerSearchComponent.prototype.searchByOrderId = function (orderId) {
        return __awaiter(this, void 0, void 0, function () {
            var orderDetails, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.orderDetailService.fetchOrderDetails(orderId).toPromise()];
                    case 1:
                        orderDetails = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [2 /*return*/, this.notFoundError()];
                    case 3:
                        try {
                            this.router.navigate(["/order/" + orderId]);
                            // const customerMobile = orderDetails.data.order.customerObj.mobile;
                            // if (!customerMobile) {
                            //   throw "Customer Not found";
                            // }
                            // this.mobile = customerMobile;
                        }
                        catch (err) {
                            return [2 /*return*/, this.notFoundError()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerSearchComponent.prototype.searchByEmail = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var user, mobile, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.entityService.getUserByEmail(email).toPromise()];
                    case 1:
                        user = (_a.sent()).data;
                        mobile = user.mobile;
                        if (!mobile) {
                            return [2 /*return*/, this.notFoundError()];
                        }
                        this.mobile = mobile;
                        this.searchMobileNumber();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        return [2 /*return*/, this.notFoundError()];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    CustomerSearchComponent.prototype.searchCustomer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.searchBy;
                        switch (_a) {
                            case SEARCH_BY.ORDER_ID: return [3 /*break*/, 1];
                            case SEARCH_BY.EMAIL: return [3 /*break*/, 3];
                            case SEARCH_BY.MOBILE: return [3 /*break*/, 5];
                        }
                        return [3 /*break*/, 6];
                    case 1:
                        if (!this.isValidOrderId(this.searchKeyword)) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.searchByOrderId(this.searchKeyword)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        if (!this.isValidEmail(this.searchKeyword)) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.searchByEmail(this.searchKeyword)];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        {
                            if (!this.isValidMobileNumber(this.searchKeyword)) {
                                return [2 /*return*/];
                            }
                            this.mobile = this.searchKeyword;
                            this.searchMobileNumber();
                        }
                        ;
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CustomerSearchComponent.prototype.notFoundError = function () {
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: "Not Found",
        }));
    };
    CustomerSearchComponent.prototype.isValidMobileNumber = function (mobile) {
        var testMob = new RegExp('[0-9]{10}');
        if (!testMob.test(mobile)) {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Please enter a valid 10-digit mobile number",
            }));
            return false;
        }
        return true;
    };
    CustomerSearchComponent.prototype.isValidEmail = function (email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(email)) {
            return true;
        }
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: "Please enter a valid Email Id",
        }));
        return false;
        // const testMob = new RegExp('[0-9]{10}');
        // if (!testMob.test(mobile)) {
        //   this.toasterService.showToaster(new Toaster({
        //     type: ToasterType.WARNING,
        //     message: `Please enter a valid 10-digit mobile number`,
        //   }));
        //   return false;
        // }
        // return true;
    };
    CustomerSearchComponent.prototype.isValidOrderId = function (orderId) {
        if (/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/.test(orderId)) {
            return true;
        }
        this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: "Please enter a valid Order Id",
        }));
        return false;
    };
    CustomerSearchComponent.prototype.searchMobileNumber = function () {
        var _this = this;
        this.customerSerivce.getCustomerByMobile(this.mobile)
            .subscribe(function (data) {
            _this.dataservice.updateSearchCount(_this.mobile);
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: "User found",
            }));
            _this.router.navigate(["/customer/" + _this.mobile]);
            _this.mobile = '';
            document.getElementById('searchCustomer').blur();
        }, function (err) {
            console.error(err);
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch this user, Please check the number again",
                config: {}
            }));
        });
    };
    return CustomerSearchComponent;
}());
export { CustomerSearchComponent };
