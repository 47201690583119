<div>
    <form [formGroup]="demandAreasFormGroup" class="row">
        <div class="col-md-3">
            <label class="heading"> City</label><br>
            <ng-select formControlName="selectedCity" name="city" placeholder="Select city"
                (change)="getServicesForSelectedCity()">
                <ng-option *ngFor="let city of cities" [value]="city">{{city.displayName}}</ng-option>
            </ng-select>
        </div>
    
        <div class="col-md-3">
            <label class="heading">Service</label><br>
            <ng-select formControlName="selectedServices" name="service" placeholder="Select services" [multiple]=true>
                <ng-option *ngFor="let item of services" [value]="item.value">{{item.name}}</ng-option>
            </ng-select>
        </div>
    
    
        <div class="col-md-3">
            <label class="heading">Date</label><br>
            <p-calendar selectOtherMonths="true" formControlName="selectedDate" icon="pi pi-calendar" [showIcon]=true dateFormat="dd/mm/yy" [maxDate]="maxDate"></p-calendar>
        </div>
    
    
        <div class="col-md-3">
            <br>
            <button mat-raised-button color="primary" type="button" (click)="submit()">View Demand Areas</button>
        </div>
    </form>
    <br>
</div>

   
<span *ngFor="let time of allTimes">
    <button mat-raised-button (click)="plotHexesForTime(time)" [disabled]="!times.includes(time)" style="margin-right: 2px; margin-bottom: 2px;" [color]="(currentSelectedTime == time) ? 'primary' : null">{{time}}</button>
</span>
<br>
<br>


<div #mapContainer id="map"></div>

<ng-template #alertDialog>
    <h3 matDialogTitle>{{alertText}}</h3>
    <div mat-dialog-actions class="button-flex">
        <button mat-button matDialogClose>ok</button>
    </div>
</ng-template>
