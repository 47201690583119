import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination, Table, TableData, TableDataTypes, Toaster, ToasterType } from 'src/app/shared/types';
import { FRESH_DESK_TICKETS_URL, TICKET_STATUS_MAPPING, CAPTAIN_TICKET_DISPOSITIONS } from 'src/app/shared/constants';


import { UserTicketingService } from '../../user-tickets.service';
import { HelperService } from 'src/app/helper.service';
import { ToasterService } from 'src/app/toaster.service';

@Component({
  selector: 'app-captain-tickets',
  templateUrl: './captain-tickets.component.html',
  styleUrls: ['./captain-tickets.component.css']
})
export class CaptainTicketsComponent implements OnInit {

  @Input() captainId: any;
  @Input() mobile: any;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  captainTickets: Table;
  dataLoaded = false;

  constructor(
    public ticketingService: UserTicketingService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.declareTable();
  }

  declareTable() {
    this.captainTickets = new Table({
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      headers: {
        date: 'Created Date',
        ticketId: 'Ticket ID',
        subject: 'Subject',
        description: 'Description',
        status: 'Status',
        agentAction: 'Agent Action',
        agentReason: 'Agent Reason',
        lastReply: 'Last Reply'
      },
      pagination: new Pagination(0, 10, 30, [10,15,20],0, true),
      selectedHeader: 3,
      defaultError: 'No Tickets found',
      onRefresh: () => {
        this.captainTickets = null;
        this.ngOnInit();
      },
      data: []
    });
    this.getTickets();
  }

  async getTickets(pageNumber = 1, perPage = 10) {
    this.dataLoaded = false;
    try {
      const query = {
        "mobile": this.mobile
      }
      const queryString = JSON.stringify(query);
      const response : any = await this.ticketingService.fetchUserTicketsV2(this.captainId, 'captain', queryString, pageNumber).toPromise();
      this.dataLoaded = true;
      if (response && response.tickets) {
        this.captainTickets.data = await this.formatCaptainTickets(response.tickets);
      }
    } catch(error) {
      console.log('Error while fetching tickets for captain----', error);
      this.dataLoaded = true;
    }
  }

  async formatCaptainTickets(captainTickets = []) {
    if (captainTickets.length > 25) captainTickets.length = 25;
    let formatedTickets = [];
    captainTickets.filter(ticket => ticket.l1_agent_action && CAPTAIN_TICKET_DISPOSITIONS.includes(ticket.l1_agent_action))
    .map((item) => {
      let description =  item.description_text.replace(/ +(?= )/g,'');
      let ticket = {
        date: this.createTableData(HelperService.dateString(item.created_at)),
        ticketId: new TableData({
          data: item.ticket_id,
          type: TableDataTypes.DATA,
          className: 'cursor-pointer whiteSpacePre hyperlink',
          onClick: (data) => {
            const redirectToFreshDesk = `${FRESH_DESK_TICKETS_URL}/${item.ticket_id}`
            window.open(redirectToFreshDesk, '_blank');
          }
        }),
        subject: this.createTableData(item.subject || ''),
        description: this.createTableData(description || ''),
        agentAction: this.createTableData(item.l1_agent_action || ''),
        agentReason: this.createTableData(item.l1_agent_reason || ''),
        status: this.createTableData(TICKET_STATUS_MAPPING[item.status] || ''),
        lastReply: new TableData({
          data: "View Last Reply",
          type: TableDataTypes.DATA,
          className: 'cursor-pointer whiteSpacePre hyperlink',
          onClick: async (data) => {
            const includePrivate = true;
            const perPage = 30;
            if(data.lastReply.data == "View Last Reply"){
              this.ticketingService.fetchTicketLastComment(this.captainId, data.ticketId.data, includePrivate, perPage).subscribe(response =>{
                const ticketComments: any = response;
                if(ticketComments && ticketComments.comments && ticketComments.comments.data && ticketComments.comments.data.length > 0){
                  const index = this.captainTickets.data.findIndex(ticket=> ticket.ticketId.data == data.ticketId.data);
                  this.captainTickets.data[index].lastReply.data = ticketComments.comments.data[ticketComments.comments.data.length - 1].body_text;
                  this.captainTickets.data[index].lastReply.className = 'cursor-pointer whiteSpacePre';
                }
                else{
                  const index = this.captainTickets.data.findIndex(ticket=> ticket.ticketId.data == data.ticketId.data);
                  this.captainTickets.data[index].lastReply.data = "N/A";
                  this.captainTickets.data[index].lastReply.className = 'cursor-pointer whiteSpacePre';
                }
              }, error => {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.WARNING,
                  message: `Error while getting comments for ticket ${data.ticketId.data}`,
                  delay: 3000
                }));
                return;
              })
              
            }
            
          }
        }),
      }
      formatedTickets.push(ticket);
    })

    return formatedTickets;
  }

  createTableData(value, className?, type?, flag = false, hoverData?) {
    return new TableData({
      data: value,
      type: type ? type : TableDataTypes.DATA,
      flag: flag,
      hoverData: hoverData ? hoverData : null,
      className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  onPaginationChange(event) {
    this.getTickets((event.pageNo + 1), event.perPage);
  }
}
