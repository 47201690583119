import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CustomerGeneralInfoService } from './customer-general-info.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { DataService } from '../../data.service';
@Component({
  selector: 'app-customer-general-info',
  templateUrl: './customer-general-info.component.html',
  styleUrls: ['./customer-general-info.component.css']
})
export class CustomerGeneralInfoComponent implements OnInit, OnChanges {

  @Input() data: any;
  @Output() activate: EventEmitter<any> = new EventEmitter<any>();
  @Output() deactivate: EventEmitter<any> = new EventEmitter<any>();
  public actorEmail: string;
  public actorId: string;
  public actorName: string;
  public referrerDetails = '';
  public info: any = {};
  constructor(
    public dialog: MatDialog,
    private customerGeneralInfoService: CustomerGeneralInfoService,
    private toasterService: ToasterService,
    private dataService: DataService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      if (this.data.dataFetched) {
        this.fetchRefereeDetails();
        this.constructCard();
      } else {
        this.blankCard();
      }
    } else {
      this.emptyCard();
    }
  }

  ngOnInit() {
    const userCookie = this.accessCookie('user') && JSON.parse(this.accessCookie('user')) ? JSON.parse(this.accessCookie('user')) : {};
    this.actorEmail = userCookie && userCookie.emailId ? userCookie.emailId : '';
    this.actorId = userCookie && userCookie._id ? userCookie._id : '';
    this.actorName = userCookie && userCookie.firstName ? userCookie.firstName : '';
    this.fetchRefereeDetails();
  }

  fetchRefereeDetails() {
    if (this.data && this.data.details && this.data.details.data && this.data.details.data.mobile) {
      this.customerGeneralInfoService.getRefereeDetails(this.actorId, this.data.details.data.mobile).subscribe(result => {
        if (result && result['result'] && result['result']['referrerUserDetails']) {
          const details = result['result']['referrerUserDetails'];
          this.referrerDetails =
            `${details.name}   ${details.mobile}   ${details.referralCode}`;
        }
      });
      this.constructCard();
    }
  }

  blankCard() {
    this.info = {
      header: 'General Info',
      data: [],
      dataFetched: false
    };
  }

  emptyCard() {
    this.info = {
      header: 'General Info',
      dataFetched: false,
      data: null
    };
  }

  constructCard() {
    if (this.data && this.data.dataFetched) {
      const data = this.data.details.data;
      const pref = this.data.preferences;
      const referralInfo = this.data.referralInfo;
      const customerSegment = this.data.customerSegment;
      let ellipsisMenu = [];
      const lastUsedDevice = this.data.lastDevice && this.data.lastDevice.manufacturer && this.data.lastDevice.model ?
      this.data.lastDevice.manufacturer + ' ' + this.data.lastDevice.model : '';
      if (this.data.details.data.active) {
        ellipsisMenu = ['Remove IMEI', 'Deactivate'];
      } else {
        ellipsisMenu = ['Remove IMEI', 'Activate'];
      }
      this.info = {
        header: 'General Info',
        buttons: ['Logout'],
        ellipsis: ellipsisMenu,
        dataFetched: true,
        data: [
          {header: 'Name', value: data.firstName ? data.firstName : '', className: 'id-icon', svgIcon: true},
          {header: 'Email', value: data.email ? data.email : '', className: 'email-id-icon', svgIcon: true},
          {header: 'Customer Segment', value: customerSegment ? customerSegment : 'N/A', className: 'id-icon', svgIcon: true},
          {header: 'Last Used Device', value: lastUsedDevice,
                className: 'last-used-device-icon', svgIcon: true},
          {header: 'Referral code', value: referralInfo && referralInfo.length > 0 ? referralInfo.join(', ') : '',
                className: 'referral-code-icon', svgIcon: true},
          {header: 'Referee', value: this.referrerDetails ? this.referrerDetails : 'Organic User',
                className: 'referral-code-icon', fullText: true, valueClass: 'whiteSpacePre', svgIcon: true},
          {header: 'Language preference', value: pref.language ? pref.language : '', className: 'language-preference-icon', svgIcon: true},
          {header: 'Communication preference', value: pref.communication ? pref.communication.join(', ') : '',
                className: 'communication-preferences-icon', svgIcon: true}
        ]
      };
    }
  }

  eventTriggered(value) {
    if (value === 'Logout') {
      if (this.data && this.data.details && this.data.details.data && this.data.details.data._id) {
        this.customerGeneralInfoService.logoutCustomer(this.data.details.data._id).subscribe(res => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.SUCCESS,
            message: `Successfully logged out customer`,
          }));
        },
        error => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Failed to log out customer`,
          }));
        });
      } else {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Failed to log out customer. Id not found.`,
        }));
      }
    } else if (value === 'Remove IMEI') {
      this.openDialog('removeImei');
    } else if (value === 'Deactivate') {
      if (document.cookie) {
        if (document.cookie.includes('customer_deactivate_access')) {
          this.openDialog('deactivate');
        }  else {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `You do not have access to deactivate customers.`,
          }));
        }
      }
    } else if (value === 'Activate') {
      if (document.cookie) {
        if (document.cookie.includes('customer_deactivate_access')) {
          this.openDialog('activate');
        }  else {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `You do not have access to activate customers.`,
          }));
        }
      }
    } else {
    }
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim();
      if (temp.indexOf(name) === 0) {
        return temp.substring(name.length, temp.length);
      }
    }
    return '';
  }

  openDialog(type): void {
    if (type === 'deactivate' || type === 'activate') {
      let options = ['Rude Behavior', 'Multiple cancellations', 'Fake/Fraud customer', 'Not clearing previous due',
        'Requested by customer', 'Customer cancellation fraud', 'iOS Account Deletion Request', 'Other'];

      if(type === 'activate') {
        options = ['Customer Request', 'Wrongly Deactivated', 'Wrongly Tagged as Fraud', 'No Previous Dues', 'Other']
      }

      const dialogRef = this.dialog.open(DialogComponent, {
        width: '335px',
        data: {options,
               title: `Please enter reason for ${type}`,
               type: 'customer-details',
               reason: `${type}`}
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.customerGeneralInfoService.changeCustomerStatus({
            reason: result,
            userId: this.data.details.data._id,
            userMail: this.data.details.data.email,
            actorId: this.actorId,
            actorMail: this.actorEmail,
            actorName: this.actorName,
            type: `${type}`
          }).subscribe(res => {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.SUCCESS,
              message: `Successfully ${type}ed customer`,
            }));
            this.data.details.data.active = false;
            this.constructCard();
            this.deactivate.emit();
          }, error => {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: `Unable to ${type} customer`,
            }));
          });
        }
      });
    } else if (type === 'removeImei') {
        const dialogRef = this.dialog.open(DialogComponent, {
          width: '335px',
          data: {title: 'Are you sure you want to remove IMEI for this user?',
                type: 'customer-details-imei',
                reason: 'removeImei',
                message: 'By removing IMEI, you will release the user\'s device',
                buttonText: 'Yes, remove'}
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.customerGeneralInfoService.removeDeviceId(this.data.details.data.device.deviceId).subscribe(res => {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: `Successfully removed IMEI for customer`,
              }));
            },
            error => {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Failed to removed IMEI for customer`,
              }));
            });
          }
        });
    }
  }
}
