import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  Table,
  Pagination,
  TableData,
  TableDataTypes,
} from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../order-service.service';
import { Router } from '@angular/router';
import { HelperService } from '../../helper.service';
import { promise } from 'protractor';

@Component({
  selector: 'app-captain-propagation-events',
  templateUrl: './captain-propagation-events.component.html',
  styleUrls: ['./captain-propagation-events.component.css'],
})
export class CaptainPropagationEventsComponent implements OnInit, OnChanges {
  @Input() captainObjectId: string;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  propagationEvents: any[];
  propagationEventsTable: Table;

  constructor(
    public dialog: MatDialog,
    public orderService: OrderService,
    private router: Router
  ) { }

  ngOnInit() {
    this.definePropagationEventsTable();
  }

  ngOnChanges(changes: SimpleChanges) { }

  private declareTable(pageNumber = 0) {
    this.propagationEventsTable = new Table({
      tableHeader: {
        toString: () => {
          return `Propagtion Events`;
        },
      },
      defaultError: 'No propagtion records found',
      headers: {
        date: 'Order Date',
        updatedAt: 'Updated At',
        orderId: 'OrderId',
        eventName: 'Event',
        serviceDetailId: 'ServiceDetailId',
        captainLocation: 'Captain Location',
        captainPositionInMappedList: 'Captain position in mapped list' + '\n' + '( position / total captains )'
      },
      selectedHeader: 10,
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.propagationEventsTable = null;
        this.definePropagationEventsTable();
      },
      data: [],
    });
  }

  onPaginationChange(event) {
    this.propagationEventsTable.data = [];
    this.fillDataToTable();
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  definePropagationEventsTable() {
    this.declareTable();
    this.fillDataToTable();
  }

  private getPropagationEvents() {
    return this.orderService.getPropagationEventsForCaptain(this.captainObjectId, this.propagationEventsTable.pagination)
  }

  fillDataToTable() {
    this.propagationEventsTable.dataWillLoad();
    function getCaptainLocation(captainLocation) {
      if (captainLocation && captainLocation.lat && captainLocation.lng) {
        return captainLocation.lat + '\n' + captainLocation.lng
      }
      return ''
    }

    function getCaptainPositionInMappedCaptainList(captainId, mappedCaptainList) {
      if (!mappedCaptainList) return ''

      return `${mappedCaptainList.indexOf(captainId) + 1} / ${mappedCaptainList.length}`
    }

    function getReadableDateFromEpoch(epoch) {
      const date = new Date(epoch);
      return date.toLocaleString();
    }

    this.getPropagationEvents().subscribe(async ({ events, count }) => {
      const self = this
      function createTableData(value, className?, hoverData?, flag = false, suspendFlag = false) {
        return new TableData({
          data: value,
          hoverData: hoverData ? hoverData : null,
          type: TableDataTypes.DATA,
          className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
          onClick: (data) => {
            const newRelativeUrl = self.router.createUrlTree([`/order/${data.orderId}`]);
            const baseUrl = window.location.href.replace(self.router.url, '');
            window.open(baseUrl + newRelativeUrl, '_blank');
          }
        });
      }

      this.propagationEvents = events.map((event) => {
        const orderId = event.orderId;
        return {
          date: createTableData(event.orderDate),
          orderId: createTableData(event.orderId),
          eventName: createTableData(event.eventName),
          serviceDetailId: createTableData(event.serviceDetailId),
          updatedAt: createTableData(getReadableDateFromEpoch(event.updatedAt)),
          captainLocation: createTableData(getCaptainLocation(event.captainLocation)),
          captainPositionInMappedList: createTableData(getCaptainPositionInMappedCaptainList(this.captainObjectId, event.mappedCaptains))
        };
      });
      this.propagationEventsTable.data = this.propagationEvents;
      this.propagationEventsTable.pagination.count = count;
    }, err => {
      console.log('error while fetching propagtion events :', err)
      this.propagationEventsTable.data = [];
      this.propagationEventsTable.pagination.count = 0;
    })
  }
}
