import { Component, OnInit } from '@angular/core';
import { FilterService } from '../filter.service';
import { FullViewMapService } from '../full-view-map.service';
import { ToasterService } from '../toaster.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';
import { environment } from 'src/environments/environment';
const DEFAULT_USER = {
  name: '...',
  role: '...',
  imageUrl: '/assets/svgImages/profile_circle.svg',
};
function getDefaultUser() {
  return Object.assign({}, DEFAULT_USER);
}
@Component({
  selector: 'app-sidenav-content',
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.css']
})
export class SidenavContentComponent implements OnInit {
  events: string[] = [];
  showLabels: boolean = false;
  toggleFilter: boolean  = false;
  user = getDefaultUser();
  constructor(
    public filterService: FilterService,
    public fullViewMapService: FullViewMapService,
    public toasterService: ToasterService,
    public authService: AuthService,
    public router: Router,
  ) {
    this.authService.getUserDetails()
      .subscribe(data => {
        data.roles = data.roles || [];
        data.google = data.google || {};
        data.google.image = data.google.image || {};
        this.user.name = `${data.firstName}`;
        this.user.imageUrl = data.google.image.url || data.google.picture || this.user.imageUrl;
        this.user.role = data.roles.join(', ');
      });
  }

  ngOnInit() {
  }

  closeFilter() {
    this.filterService.dataReceived = false;
    this.handleOverlay(1);
  }

  handleOverlay(status) {
    if (status === 0) {
      this.showOverlay();
    } else if (status === 1) {
      this.removeOverlay();
    }
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
}

  logOut() {
    this.authService.clearUserCred();
    this.deleteAllCookies();
    HelperService.redirect(environment.oauth);
  }

  removeOverlay() {
    document.getElementById('overlay').style.display = 'none';
  }
  showOverlay() {
    document.getElementById('overlay').style.display = 'block';
  }

}
