import { Component, OnInit, Input, OnChanges, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HelperService } from '../../../helper.service';
import * as moment from 'moment';
import { getCurrentStatus } from '../service';


@Component({
  selector: 'app-weekly-incentives',
  templateUrl: './weekly-incentives.component.html',
  styleUrls: ['./weekly-incentives.component.css']
})
export class WeeklyIncentivesComponent implements OnInit, OnChanges {
  @ViewChild('tnc') tnc: TemplateRef<any>;
  @ViewChild('orders') orders: TemplateRef<any>;
  @Input() incentives;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    config: NgbModalConfig
  ) { }
  panelOpenState = false;
  numbers = ['1', '2', '4'];
  statusMapping = {
    'NOT_STARTED': 'Upcoming',
    'STARTED': 'Ongoing',
    'COMPLETED': 'Achieved',
    'EXPIRED': 'Missed',
    'ACHIEVED': 'Achieved',
    'ONGOING': 'Ongoing',
  };
  orderList;
  ngOnInit() {

  }

  ngOnChanges() {

  }

  formatTime(timeSlot) {
    const timeString = [];
    for (const time of timeSlot) {
      if (time) {
        timeString.push(HelperService.convert24to12hrFormat(time.fromTime) + ' - ' + HelperService.convert24to12hrFormat((time.toTime)));
      }
    }
    return timeString.join(' & ');
  }

  getClass(incentive, status, card) {
    return this.getStatus(incentive, status, card).toLowerCase();
  }

  getCircleClass(incentive, status, card) {
    return this.getStatus(incentive, status, card).toLowerCase() + '-circle';
  }

  getStatus(incentive, status, card) {
    const currentStatus = getCurrentStatus(incentive, status, card);
    return this.statusMapping[currentStatus];
  }

  openTncDialog() {
    this.open(this.tnc);
  }

  openOrdersDialog(orderList) {
    this.orderList = orderList;
    this.open(this.orders);
  }

  open(content) {
    this.modalService.open(content, {
      centered: true
    });
  }

  goToOrder(orderId) {
    if (orderId) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/order/${orderId.id}`]));
      window.open(url, '_blank');
      this.modalService.dismissAll();
    }
  }

  getDaysOfTheWeek(daysString) {
    const days = daysString.split(', ');
    if (days && days.length) {
      if (days.length === 1) {
        return days[0].substring(0, 3);
      } else {
        const firstDay = days[0].substring(0, 3);
        const lastDay = days[days.length - 1].substring(0, 3);
        return (firstDay + ' - ' + lastDay);
      }
    }
  }

}
