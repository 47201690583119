import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { HelperService } from 'src/app/helper.service'
import { ManagerService } from './manager-profile.service'
import { ToasterService } from 'src/app/toaster.service'
import { Toaster, ToasterType } from 'src/app/shared/types'
import { UserData } from './manager-profile.types'

@Component({
  selector: 'app-manager-profile',
  templateUrl: './manager-profile.component.html',
  styleUrls: ['./manager-profile.component.css'],
})
export class ManagerProfileComponent implements OnInit {
  private mobile: string
  public managerProfile: UserData
  pmSearchRoleChecked = false
  pmSearchRoleDisabled = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private managerService: ManagerService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('mobile')
    if (!id) {
      this.router.navigate(['manager'])
      return
    }
    this.mobile = HelperService.decode(id)
    this.managerService.getManagerByMobile(this.mobile).subscribe(
      (data: UserData) => {
        this.toasterService.showToaster(
          new Toaster({
            type: ToasterType.SUCCESS,
            message: `User found`,
          })
        )
        this.managerProfile = data
      },
      (err) => {
        this.toasterService.showToaster(
          new Toaster({
            type: ToasterType.WARNING,
            message: `Unable to fetch this user, Please check the number again`,
            config: {},
          })
        )
        this.router.navigate(['manager'])
      }
    )
  }
}
