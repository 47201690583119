import { MyHttpClient } from '../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./http-client.service";
var UserComplaintsService = /** @class */ (function () {
    function UserComplaintsService(http) {
        this.http = http;
        this.server = environment.server;
        this.fetchComplaintsUrl = this.server + "/api/svo/complaints/customer";
    }
    UserComplaintsService.prototype.fetchCustomerComplaints = function (customerId) {
        return this.http.get(this.fetchComplaintsUrl + "/" + customerId).pipe(catchError(this.handleError));
    };
    UserComplaintsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    UserComplaintsService.ngInjectableDef = i0.defineInjectable({ factory: function UserComplaintsService_Factory() { return new UserComplaintsService(i0.inject(i1.MyHttpClient)); }, token: UserComplaintsService, providedIn: "root" });
    return UserComplaintsService;
}());
export { UserComplaintsService };
