import { Component, Input, OnInit } from '@angular/core';
import { SingleSearchableDropdown } from 'src/app/filter.service';

@Component({
  selector: 'app-captain-rate-card',
  templateUrl: './captain-rate-card.component.html',
  styleUrls: ['./captain-rate-card.component.css'],
})

export class CaptainRateCardComponent implements OnInit {
  @Input() rateCardInfo: object;
  public selectedService: string;
  public selectedStrategy: string;
  public possibleStrategies: string[];
  public selectedRateCard: Object;
  public serviceFilter: any;
  public pricingStrategyFilter: any;
  constructor() {}

  ngOnInit() {  
    this.selectedService = Object.keys(this.rateCardInfo).includes('Link') ? 'Link'
      : Object.keys(this.rateCardInfo).includes('Auto') ? 'Auto'
      : Object.keys(this.rateCardInfo).includes('CabEconomy') ? 'CabEconomy'
      : Object.keys(this.rateCardInfo)[0];
    this.possibleStrategies = Object.keys(this.rateCardInfo[this.selectedService]);
    this.selectedStrategy = this.possibleStrategies.includes('default') ? 'default' : this.possibleStrategies[0];
    this.selectedRateCard = this.rateCardInfo[this.selectedService][this.selectedStrategy];
    
    this.serviceFilter = new SingleSearchableDropdown({
      key: 'value',
      title: 'Select Service',
      values: Object.keys(this.rateCardInfo),
      default: 'Link',
      onChange: (event) => {
        if(event.value){
          this.selectedService = event.value;
          this.pricingStrategyFilter.values = Object.keys(this.rateCardInfo[this.selectedService]);
          this.selectedStrategy = this.pricingStrategyFilter.values.includes('default') ? 'default' : this.pricingStrategyFilter.values[0];
          this.selectedRateCard = this.rateCardInfo[this.selectedService] && this.rateCardInfo[this.selectedService][this.selectedStrategy];
        }  
      }
    })

    this.pricingStrategyFilter = new SingleSearchableDropdown({
      key: 'value',
      title: 'Select Pricing Strategy',
      values: Object.keys(this.rateCardInfo[this.selectedService]),
      default: 'default',
      onChange: (event) => {
        if(event.value){
          this.selectedStrategy = event.value;
          this.selectedRateCard = this.rateCardInfo[this.selectedService] && this.rateCardInfo[this.selectedService][this.selectedStrategy];
        }
      }
    })

  }
}
