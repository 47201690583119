var _a;
import { Observable, forkJoin } from "rxjs";
import { EntityService } from "./entity.service";
import "rxjs/add/operator/map";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { shareReplay, mergeMap, catchError } from "rxjs/operators";
import { HelperService } from "./helper.service";
import { DateFilterInput } from "./filter.service";
import { DataService } from "./data.service";
// import { LoaderService } from './loader.service';
var DEFAULT = "N/A";
var STATUSES = {
    ON_DUTY: "OnDuty",
    ON_DUTY_ON_WAY: "onDutyOnWay",
    LOGOUT: "Logout",
    UNREACHABLE: "Unreachable",
    ON_THE_WAY: "onTheWay",
    ARRIVED: "arrived",
    STARTED: "started",
    STARTED_ON_WAY: "startedOnWay",
    STARTED_ON_DUTY: "startedOnDuty",
    OFFLINE: "offline",
};
var STATUS_MAPPING = {
    "2": STATUSES.ON_DUTY,
    "2-6": STATUSES.ON_DUTY_ON_WAY,
    "4": STATUSES.LOGOUT,
    "5": STATUSES.UNREACHABLE,
    "6": STATUSES.ON_THE_WAY,
    "7": STATUSES.ARRIVED,
    "8": STATUSES.STARTED,
    "8-6": STATUSES.STARTED_ON_WAY,
    "8-2": STATUSES.STARTED_ON_DUTY,
    "9": STATUSES.OFFLINE,
};
var CONNECTION_STATUS_MAPPING = (_a = {},
    _a[STATUSES.ON_DUTY] = "online",
    _a[STATUSES.ON_DUTY_ON_WAY] = "online",
    _a[STATUSES.LOGOUT] = "offline",
    _a[STATUSES.UNREACHABLE] = "offline",
    _a[STATUSES.ON_THE_WAY] = "online",
    _a[STATUSES.ARRIVED] = "online",
    _a[STATUSES.STARTED] = "online",
    _a[STATUSES.STARTED_ON_WAY] = "online",
    _a[STATUSES.STARTED_ON_DUTY] = "online",
    _a[STATUSES.OFFLINE] = "offline",
    _a);
var CaptainService = /** @class */ (function () {
    function CaptainService(entityService, httpClient, dataService // public loaderService: LoaderService,
    ) {
        this.entityService = entityService;
        this.httpClient = httpClient;
        this.dataService = dataService;
        this.cache = {};
        this.cachedObservable = {};
    }
    CaptainService.prototype.deviceName = function (_a) {
        var manufacturer = _a.manufacturer, model = _a.model;
        return manufacturer + " " + model;
    };
    CaptainService.prototype.captainDetails = function (captainId, startDate, endDate, refresh, withEarnings, withReferralDetails) {
        var _this = this;
        if (refresh === void 0) { refresh = false; }
        if (withEarnings === void 0) { withEarnings = true; }
        if (withReferralDetails === void 0) { withReferralDetails = false; }
        var fromDate = (startDate && new Date(startDate)) || new Date(2010, 1, 1);
        var toDate = (endDate && new Date(endDate)) || new Date();
        var uniqueId = function () {
            return "captainDetails-" + captainId + "-" + fromDate + "-" + toDate;
        };
        var captainEarningObs = Observable.of({});
        if (withEarnings) {
            captainEarningObs = this.captainEarnings(captainId, { fromDate: fromDate, toDate: toDate }, refresh);
        }
        this.cachedObservable[uniqueId()] = forkJoin(captainEarningObs, this.entityService.getEntityByNumber(captainId))
            .pipe(mergeMap(function (_a) {
            var captainEarnings = _a[0], captainDetailsRes = _a[1];
            var data = captainDetailsRes;
            var userId = data.userId._id;
            var userDetails = data.userId || {};
            var shift = data.shift || {};
            data.tl = data.tl || {};
            data.services = data.services || [];
            var activatedDate = data.statusUpdates && data.statusUpdates.activatedOn;
            var captainDetails = {
                active: data.active,
                imageUrl: data.profilePicture.link,
                mobileNumber: data.mobile || DEFAULT,
                name: data.firstName + " " + data.lastName || DEFAULT,
                emailid: data.email || DEFAULT,
                lastUserDevice: _this.deviceName(data.device) || DEFAULT,
                referralCode: userDetails.referralCode || [],
                registeredOn: data.registeredDate || DEFAULT,
                languages: data.languages.join(", ") || DEFAULT,
                registeredCity: data.registeredCity || DEFAULT,
                activatedDate: activatedDate || DEFAULT,
                shift: shift.name || DEFAULT,
                service: data.services.map(function (service) { return service.service.name; }).join(", ") ||
                    DEFAULT,
                tlName: data.tl.firstName || DEFAULT,
                tlEmailId: data.tl.email || DEFAULT,
                status: userDetails.status,
                riderId: data.userId && data.userId._id,
                connectionStatus: CONNECTION_STATUS_MAPPING[STATUS_MAPPING[userDetails.status]],
                vehicleDetails: data.currentVehicle,
                license: data.license,
                city: data.city,
                serviceList: data.services,
                userId: data.userId && data.userId._id,
                mode: data.modeId && data.modeId.mode,
            };
            var result = Object.assign(captainDetails, captainEarnings);
            if (withReferralDetails) {
                return _this.entityService
                    .getReferralInfo(userId)
                    .map(function (referralCode) {
                    result.referralCode =
                        referralCode && referralCode.length
                            ? referralCode.join(", ").trim()
                            : "N/A";
                    return result;
                });
            }
            else {
                return Observable.of(result);
            }
        }))
            .catch(function (err) {
            console.log(err);
            throw new Error("Invalid UserName");
        })
            .pipe(shareReplay(2));
        return this.cachedObservable[uniqueId()];
    };
    CaptainService.prototype.captainEarnings = function (mobileNumber, filter, refresh) {
        var _this = this;
        if (filter === void 0) { filter = {}; }
        if (refresh === void 0) { refresh = false; }
        var from = filter.fromDate;
        var to = filter.toDate;
        var startDate = from || new Date(2010, 1, 1);
        var endDate = to || new Date();
        var duration = DateFilterInput.formatDurationForQuery({
            fromDate: startDate,
            toDate: endDate,
        });
        startDate = duration.fromDate;
        endDate = duration.toDate;
        var uniqueId = function () {
            return "captainEarnings-" + mobileNumber + "-" + startDate + "-" + endDate;
        };
        var url = environment.server + "/api/rider/earnings/" + mobileNumber;
        return forkJoin(this.httpClient.post(url, {
            startDate: startDate,
            endDate: endDate,
        }), this.lastRedeemInfoByMobileNumber(mobileNumber), this.autoRedeemInfoByMobileNumber(mobileNumber), this.getCityNegativeBalanceThresholdByMobileNumber(mobileNumber))
            .map(function (_a) {
            var res = _a[0], redeemInfo = _a[1], autoRedeemData = _a[2], cityNegativeThreshold = _a[3];
            var walletDetails = res["walletDetails"] || {};
            res = res["earningDetails"] || {};
            var details = res["detail"] || {};
            var lastRedeemRequest = redeemInfo.data.lastRedeemRequest || {};
            var previousRedeems = redeemInfo.data.previousRedeems || [];
            var redeemFrequency = redeemInfo.data.redeemRulesData[0] ? redeemInfo.data.redeemRulesData[0].transactionPeriod : '-';
            var redeemLimit = redeemInfo.data.redeemRulesData[0] ? redeemInfo.data.redeemRulesData[0].transactionCount : '-';
            var redeemLeft = redeemLimit && redeemLimit != '-' && redeemInfo.data.redeemLeft != undefined ? redeemInfo.data.redeemLeft.toString() : '-';
            var blockingLimit = cityNegativeThreshold.blockingLimit ? cityNegativeThreshold.blockingLimit : '-';
            var isAutoRedeemEnabled;
            if (autoRedeemData && autoRedeemData.config) {
                if (autoRedeemData.config.isEnabled) {
                    isAutoRedeemEnabled = 'ON';
                }
                else if (autoRedeemData.config.isAvailable == false) {
                    isAutoRedeemEnabled = 'NA';
                }
                else if (autoRedeemData.config.isAvailable && autoRedeemData.config.isEnabled == false) {
                    isAutoRedeemEnabled = 'OFF';
                }
            }
            else {
                isAutoRedeemEnabled = 'NA';
            }
            var status, date, amount, remarks;
            if (!Object.keys(lastRedeemRequest).length) {
                var latestRedeemApproved = previousRedeems[0] || {};
                status = latestRedeemApproved.status;
                amount = latestRedeemApproved.amount;
                date = latestRedeemApproved.date;
            }
            else {
                var updateLog = lastRedeemRequest.updateLog || [];
                var latestUpdateLog = updateLog || {};
                remarks = latestUpdateLog.remarks;
                date = lastRedeemRequest.date;
                status = lastRedeemRequest.status;
                amount = lastRedeemRequest.amount;
            }
            _this.cache[uniqueId()] = {
                totalEarning: details.finalAmount,
                totalIncentive: (details.specialincentives || 0) + (details.boosterIncentive || 0),
                wallet: details.wallet,
                lastRedeemStatus: status || "-",
                lastRedeemDate: date || "-",
                lastRedeemAmt: HelperService.roundNumber(amount) || "-",
                walletBalance: walletDetails.balance,
                redeemRemark: remarks,
                accountSummary: redeemInfo.data.accountSummary,
                redeemFrequency: redeemFrequency || "-",
                redeemLimit: redeemLimit || "-",
                redeemLeft: redeemLeft || "-",
                isAutoRedeemEnabled: isAutoRedeemEnabled || "NA",
                blockingLimit: blockingLimit || "-"
            };
            return _this.cache[uniqueId()];
        })
            .catch(function (err) {
            console.log(err);
            throw new Error("Invalid UserName");
        });
    };
    CaptainService.prototype.getLoginHour = function (fromDate, toDate, riderId) {
        return this.httpClient
            .post(environment.server + "/api/captain/loginHour", {
            fromDate: fromDate,
            toDate: toDate,
            riderId: riderId,
        })
            .map(function (res) {
            res = res || {};
            return res[riderId] || "";
        })
            .catch(function (err, caught) {
            return Observable.of("");
        });
    };
    CaptainService.prototype.fraudStat = function (_a) {
        var riderId = _a.riderId, startDuration = _a.startDuration, endDuration = _a.endDuration;
        return this.httpClient
            .get(environment.server + "/api/fraudEngine/fraudOrderStatus/grouped/" + riderId, {
            params: {
                fromDate: HelperService.timeStampToDateOnly(startDuration),
                endDuration: HelperService.timeStampToDateOnly(endDuration),
            },
        })
            .map(function (res) {
            return res.data;
        })
            .catch(function (err, caught) {
            return Observable.of([]);
        });
    };
    CaptainService.prototype.captainLiveStat = function (mobileNumber) {
        var url = environment.server + "/api/rider/liveStat/" + mobileNumber;
        return this.httpClient.get(url).map(function (res) {
            return res;
        });
    };
    CaptainService.prototype.lastRedeemInfo = function (riderId) {
        var url = environment.server + "/api/get/rider/redeem/summary/" + riderId;
        return this.httpClient.get(url).map(function (res) {
            return res;
        });
    };
    CaptainService.prototype.autoRedeemData = function (riderId) {
        var url = environment.server + "/api/get/rider/autoRedeem/riderAutoRedeemData/" + riderId;
        return this.httpClient.get(url).map(function (res) {
            return res;
        }).pipe(catchError(this.handleError));
    };
    CaptainService.prototype.redeemCaptain = function (riderId, shiftName, city, userId) {
        var url = environment.server + "/api/enable/redeem";
        var payload = {
            id: riderId,
            shift: shiftName,
            city: city,
            userId: userId,
        };
        return this.httpClient.post(url, payload).map(function (res) {
            return res;
        });
    };
    CaptainService.prototype.logoutCaptain = function (riderId) {
        var url = environment.server + "/api/logoutCaptain";
        var body = {
            riderId: riderId,
        };
        return this.httpClient.post(url, body).map(function (res) {
            return res;
        });
    };
    CaptainService.prototype.lastRedeemInfoByMobileNumber = function (mobileNumber) {
        var _this = this;
        // return Observable.of({"status":200,"message":"Ok","data":{"earnings":{"totalEarning":5202.85,"cashCollected":5056,"orders":116,"balance":19.8},"lastPayout":"2020-07-11","previousRedeems":[{"status":"Credited","date":"2020-07-11","amount":132.76,"createdOn":1594485817068,"uniqueId":"f929eb2332e771356c9a3f80fd8ff205","transactionType":"redeem","accountDetails":{},"upiDetails":{"upiId":"8686581049@upi"}},{"status":"Credited","date":"2020-07-10","amount":106.56,"createdOn":1594377240045,"uniqueId":"d894e8cf88d79d473552b74e156f2e0a","transactionType":"redeem","accountDetails":{},"upiDetails":{"upiId":"8686581049@upi"}},{"status":"Credited","date":"2020-06-29","amount":49.58,"createdOn":1593445473206,"uniqueId":"1082dc2fcf5519f289fe69d80f3d8473","transactionType":"redeem","accountDetails":{},"upiDetails":{"upiId":"8686581049@upi"}}],"lastRedeemRequest":{},"redeemRulesData":[{"minTransactionAmount":1,"firstRedeemMinAmount":1,"shiftName":"Flexi_CM","termsAndCondition":["- Maximum 2 transactions are allowed per week.  - It is the responsibility of the captain to provide correct bank account number and other details. In case of wrong transfer to an account, only the captain is responsible and liable. - The company is eligible to hold your payment in case of suspicion of fraudulent or criminal activity.  - For any query, kindly contact your Team Support."],"transactionPeriod":"weekly","weekDays":["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],"transactionCount":2,"cityName":"Hyderabad"}]}})
        return this.entityService
            .getEntityByNumber(mobileNumber)
            .pipe(mergeMap(function (res) {
            var data = res;
            _this.dataService.setCurrentEntity(data);
            return _this.lastRedeemInfo(data.userId._id);
        }))
            .catch(function (err) {
            console.log(err);
            throw new Error("Invalid UserName");
        });
    };
    CaptainService.prototype.autoRedeemInfoByMobileNumber = function (mobileNumber) {
        var _this = this;
        return this.entityService
            .getEntityByNumber(mobileNumber)
            .pipe(mergeMap(function (res) {
            var data = res;
            _this.dataService.setCurrentEntity(data);
            return _this.autoRedeemData(data.userId._id);
        }))
            .catch(function (err) {
            throw new Error("Invalid UserName");
        });
    };
    CaptainService.prototype.getCityNegativeBalanceThresholdByMobileNumber = function (mobileNumber) {
        var _this = this;
        return this.entityService
            .getEntityByNumber(mobileNumber)
            .pipe(mergeMap(function (res) {
            var data = res;
            _this.dataService.setCurrentEntity(data);
            return _this.getCityNegativeBalanceThreshold(data.userId._id, data.city._id);
        }))
            .catch(function (err) {
            throw new Error("Invalid UserName");
        });
    };
    CaptainService.prototype.getCityNegativeBalanceThreshold = function (captainId, cityId) {
        var url = environment.server + "/api/rider/negativeBalanceThresholdInfo";
        var body = {
            userId: captainId,
            cityId: cityId
        };
        return this.httpClient.post(url, body).map(function (res) {
            return res;
        }).pipe(catchError(this.handleError));
    };
    CaptainService.prototype.getUserSelectorsOfRider = function (riderId) {
        var url = environment.server + "/api/b2b/crossUtilization/selectors/rider/" + riderId;
        return this.httpClient.get(url).map(function (res) {
            return res;
        }).pipe(catchError(this.handleError));
    };
    CaptainService.prototype.handleError = function (error) {
        // Temporarily returing an empty array whenever there is a failure while getting cross utilisation
        return Promise.resolve({});
    };
    CaptainService.prototype.removeRiderFromUserSelector = function (requestBody) {
        var riderId = requestBody.riderId, selectorId = requestBody.selectorId;
        var url = environment.server + "/api/b2b/crossUtilization/selectors/rider/remove";
        return this.httpClient.post(url, { riderId: riderId, selectorId: selectorId }).map(function (res) {
            return res;
        });
    };
    CaptainService.prototype.incentiveProgress = function (riderId, startDate, endDate) {
        var url = environment.server + "/api/rider/incentiveProgress";
        return this.httpClient
            .post(url, { riderId: riderId, startDate: startDate, endDate: endDate })
            .map(function (res) {
            return res;
        });
    };
    CaptainService.prototype.getCaptainAdjustment = function (riderId, startDate, endDate) {
        var url = environment.server + "/api/rider/adjustment";
        return this.httpClient
            .post(url, { riderId: riderId, startDate: startDate, endDate: endDate })
            .map(function (res) {
            return res ? res : {};
        });
    };
    CaptainService.prototype.suspendCaptain = function (requestParams) {
        var url = environment.server + "/api/userPreference/suspendGroup";
        return this.httpClient.post(url, requestParams).map(function (res) {
            return res ? res : {};
        });
    };
    CaptainService.prototype.unsuspendCaptain = function (requestParams) {
        var url = environment.server + "/api/userPreference/unsuspendGroup";
        return this.httpClient.post(url, requestParams).map(function (res) {
            return res ? res : {};
        });
    };
    CaptainService.prototype.getUserServices = function (captainId, getServiceGroups) {
        if (getServiceGroups === void 0) { getServiceGroups = false; }
        var url = environment.server + "/api/rider/eligibility/" + captainId + "?getServiceGroups=" + getServiceGroups;
        return this.httpClient.post(url, {}).map(function (res) {
            return res || [];
        });
    };
    CaptainService.prototype.getSuspendedServices = function (captainId) {
        var url = environment.server + "/api/rider/eligibility/" + captainId;
        return this.httpClient.post(url, {}).map(function (res) {
            return res || [];
        });
    };
    CaptainService.prototype.getRapidoPayActivityLogs = function (captainId, limit, offset) {
        var url = environment.server + "/api/rapidoPayLogs/" + captainId;
        return this.httpClient
            .post(url, { limit: limit, offset: offset * 10 })
            .map(function (res) {
            return res || [];
        });
    };
    CaptainService.prototype.getRapidoPayBlockedUser = function (captainId) {
        var url = environment.server + "/api/blockedRapidoPay/" + captainId;
        return this.httpClient.get(url).map(function (res) {
            return res || [];
        });
    };
    CaptainService.prototype.getHandHoldingCaptainDetails = function (captainId) {
        var url = environment.server + "/api/handholding/" + captainId;
        return this.httpClient.get(url);
    };
    CaptainService.prototype.getCaptainAllRateCards = function (riderId, lat, lng) {
        return this.httpClient.get(environment.server + '/api/captain/allRateCards/' + riderId, {
            params: lat & lng ? { lat: lat, lng: lng } : {},
        }).pipe(catchError(this.handleError));
    };
    return CaptainService;
}());
export { CaptainService };
