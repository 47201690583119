<head>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>
  
<h1 mat-dialog-title class="dialog-title margin-0" [ngStyle]="{'color': 'black'}">{{data.title}}</h1>

<div mat-dialog-content>
    <div mat-dialog-content>
        <div>
            <div>
                <div class="label-wrapper">
                    <mat-label>Service Group</mat-label>
                </div>
                <mat-form-field style="width: 100%;">
                    <mat-label>Select Service Group</mat-label>
                    <mat-select [(ngModel)]="selectedServiceGroup" style="width: 100%;"
                                (selectionChange)="onServiceChange()">
                        <mat-option *ngFor="let service of serviceGroups" [value]="service">
                            {{service}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="selectedServiceGroup?hasSuspensionRevokeAccess(): false">
                <div>
                    <div>
                        <mat-label style="font-weight: bold;">Reason for Suspension:  </mat-label>
                        <mat-label>{{suspendedReason[selectedServiceGroup]}} </mat-label>
                    </div>
                </div>
                <div>
                    <div>
                        <mat-label style="font-weight: bold;">Suspended Until:  </mat-label>
                        <mat-label>{{suspendedUntil[selectedServiceGroup]}} </mat-label>
                    </div>
                </div>
                <div>
                    <div class="label-wrapper mt-5">
                        <mat-label>Revoke Suspension Reason</mat-label>
                    </div>
                        <mat-form-field  style="width: 100%;">
                            <mat-label>Enter reason</mat-label>
                            <mat-select [(ngModel)]="suspensionReason" style="width: 100%;">
                                <mat-option *ngFor="let reason of filteredSuspensionReasons" [value]="reason">
                                    {{reason}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    <form *ngIf="suspensionReason === 'Other'">
                        <mat-form-field class="example-full-width">
                            <input matInput [(ngModel)]="suspensionOtherReason" name="suspensionOtherReason"
                                   placeholder="Please provide explanation">
                        </mat-form-field>
                    </form>
                </div>
                <div mat-dialog-actions class="button-flex">
                    <button class="dialog-cancel" (click)="onNoClick()">Cancel</button>
                    <button class="proceed font-size-13 font-weight-600" (click)="revokeSuspension()">Revoke </button>
                </div>
            </div>
            <div>
                <mat-label *ngIf="selectedServiceGroup?!hasSuspensionRevokeAccess():false">YOU DONT HAVE PERMISSION TO REVOKE FOR THIS ISSUE</mat-label>
            </div>
        </div>
    </div>

<div *ngIf="showReasonAlert" class="reason-alert">
    <i class="material-icons-outlined info">info</i> 
    <label class="font-size-13 color-rusty-red margin-left-5">Please select a service group, and reason for revoking suspension.</label>
</div>
