/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-wallet.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../card-table/card-table.component.ngfactory";
import * as i3 from "../../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./merchant-wallet.component";
import * as i6 from "../merchant.service";
import * as i7 from "../../toaster.service";
var styles_MerchantWalletComponent = [i0.styles];
var RenderType_MerchantWalletComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantWalletComponent, data: {} });
export { RenderType_MerchantWalletComponent as RenderType_MerchantWalletComponent };
export function View_MerchantWalletComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, [[null, "refresh"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("refresh" === en)) {
        var pd_0 = (_co.getWalletInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, { refresh: "refresh" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantWalletComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-wallet", [], null, null, null, View_MerchantWalletComponent_0, RenderType_MerchantWalletComponent)), i1.ɵdid(1, 638976, null, 0, i5.MerchantWalletComponent, [i6.MerchantService, i7.ToasterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantWalletComponentNgFactory = i1.ɵccf("app-merchant-wallet", i5.MerchantWalletComponent, View_MerchantWalletComponent_Host_0, { data: "data" }, {}, []);
export { MerchantWalletComponentNgFactory as MerchantWalletComponentNgFactory };
