import * as moment from 'moment';
import * as i0 from "@angular/core";
var OrderDetailsHelper = /** @class */ (function () {
    function OrderDetailsHelper() {
        this.captainAutoEarnings = {};
        this.orderDetailsMapping = {
            KEY_TAX_COLLECTED: 'Tax Collected',
            CASH_COLLECTED_FROM_CUSTOMER: 'Cash collected from the customer',
            REDEEM_WALLET_CREDITED: 'Amount added to wallet',
            REDEEM_WALLET_DEBITED: 'Amount deducted from wallet',
            REMARKS_TAX: 'Goods and Service Tax levied on Ride Charges and Booking/Convenience fees paid by Customer to the Government Authorities',
            commissionsParentKey: 'commissionTaxes',
            commissionTaxesLabel: 'Commission and Taxes',
            gstKey: 'gst',
            gstLabel: 'GST Collected(paid to government)',
            rapidoCommissionkey: 'rapidoCommission',
            rapidoCommissionLabel: 'Rapido\'s Commission',
            tdsPenaltyKey: 'tdsPenalty',
            tdsPenaltyLabel: 'Tax Deducted at source (TDS)',
            cancellationFareKey: 'cancellationFare',
            cancellationFareLabel: 'Cancellation Fare',
            adjustmentKey: 'adjustments',
            penaltyKey: 'penalty',
            orderFareParentKey: 'orderFare',
            orderFare: 'Order Fare',
            minimumFareKey: 'minimumFare',
            minimumFare: 'Minimum Fare',
            customerPlatformChargeKey: 'customerPlatformCharges',
            platformFeeLabel: 'Platform Fee',
            extraFareParentKey: 'extraFare',
            extraFareLabel: 'Extra Fare',
            surgeKey: 'surgeFare',
            firstMileFareKey: 'firstMileFare',
            pickupFareKey: 'pickupFare'
        };
    }
    OrderDetailsHelper.prototype.getFormattedEarningsBreakup = function (orderDetails, invoiceDetails, autoEarningsResponse, autoFlatCommissionServiceDetails) {
        if (!this.isSpdOrder(orderDetails)) {
            var _a = this.getCaptainEarningsSplit(autoEarningsResponse, orderDetails.status), captainEarnings = _a.captainEarnings, earningsMapping = _a.earningsMapping;
            this.captainAutoEarnings.orderBreakup = earningsMapping;
            var gst = this.getTaxCollected(invoiceDetails);
            this.captainAutoEarnings.orderBreakup.gst = gst && gst.value ? gst.value : 0;
            var paidToRapido = this.calculatePaidToRapido(invoiceDetails, captainEarnings, orderDetails);
            this.captainAutoEarnings.orderBreakup.paidToRapido = paidToRapido;
            this.captainAutoEarnings.orderBreakup.customerBill = orderDetails.pricing && orderDetails.pricing.amount ? orderDetails.pricing.amount : 0;
            this.captainAutoEarnings.orderBreakup.prevDue = orderDetails.pricing && orderDetails.pricing.prevDue ? orderDetails.pricing.prevDue : 0;
            this.captainAutoEarnings.orderBreakup.captainEarnings = captainEarnings ? captainEarnings.toFixed(2) : 0;
            this.captainAutoEarnings.orderBreakup.totalCaptainEarnings = autoEarningsResponse.earnings && autoEarningsResponse.earnings.amount ? autoEarningsResponse.earnings.amount : 0;
            this.captainAutoEarnings.orderBreakup.paymentMode = orderDetails.payment && orderDetails.payment.mode ? orderDetails.payment.mode : '';
            // Flat commission was rolled out to hyderabad,chennai and bangalore on 5th May. This calculation is to be refactored - to be fetched from payroll
            if (autoFlatCommissionServiceDetails.includes(orderDetails.serviceDetail._id)) {
                this.captainAutoEarnings.orderBreakup.gst = earningsMapping.tax;
                this.captainAutoEarnings.orderBreakup.paidToRapido = Math.abs(earningsMapping.platformCharges - earningsMapping.tax).toFixed(2);
            }
        }
        return this.captainAutoEarnings;
    };
    OrderDetailsHelper.prototype.isSpdOrder = function (orderDetails) {
        return orderDetails && orderDetails.pricing && (orderDetails.pricing.spdFraud ? orderDetails.pricing.spdFraud.flag : false);
    };
    OrderDetailsHelper.prototype.isAutoV2EarningEnabled = function (serviceDetail, serviceDetailIdArray, autoEarningsConfig) {
        var serviceDetailId = serviceDetail._id;
        serviceDetailIdArray.push(serviceDetailId);
        var cityId = serviceDetail.city._id;
        var config = JSON.parse(autoEarningsConfig);
        if (config.enabled == true && config.enabledForAll == true) {
            return true;
        }
        else if (config.cityServiceConfig[cityId]) {
            var configArray_1 = config.cityServiceConfig[cityId];
            return serviceDetailIdArray.every(function (i) { return configArray_1.includes(i); });
        }
        return false;
    };
    OrderDetailsHelper.prototype.getCaptainEarningsSplit = function (autoEarningsResponse, orderStatus) {
        var _this = this;
        if (autoEarningsResponse.earnings && autoEarningsResponse.earnings.breakUp) {
            var captainEarnings = 0;
            var captainEarningsMapping_1 = {};
            var activeUpiId = autoEarningsResponse.activeUpiId ? autoEarningsResponse.activeUpiId : "";
            var breakUp = autoEarningsResponse.earnings.breakUp;
            var baseFare = this.getAmount(breakUp.baseFare);
            var distance = this.getAmount(breakUp.distanceFare);
            var totalDistance = breakUp.distanceFare && breakUp.distanceFare.details && breakUp.distanceFare.details.totalUnits ? "(" + breakUp.distanceFare.details.totalUnits + " km)" : "";
            var timeFare = this.getAmount(breakUp.timeFare);
            var totalTime = breakUp.timeFare && breakUp.timeFare.details && breakUp.timeFare.details.totalUnits ? "(" + breakUp.timeFare.details.totalUnits + " min)" : "";
            var distanceFare = baseFare + distance;
            var orderFare = distanceFare + timeFare;
            var minimumFare = this.getAmount(breakUp.minimumFare);
            var isMinimumFare = minimumFare > orderFare ? 1 : 0;
            if (breakUp.cancellationFare && orderStatus == "customerCancelled") {
                var cancellationBreakup = breakUp.cancellationFare;
                captainEarnings += this.getAmount(cancellationBreakup);
                captainEarningsMapping_1.cancellationFare = this.getAmount(cancellationBreakup);
            }
            else {
                if (activeUpiId) {
                    captainEarningsMapping_1.activeUpiId = activeUpiId;
                }
                captainEarningsMapping_1.tax = breakUp.platformCharges.details.totalTax;
                captainEarningsMapping_1.platformCharges = breakUp.platformCharges.amount;
                var longPickupFare = this.getAmount(breakUp.firstMileFare);
                var longPickupDistance = '';
                if (longPickupFare > 0) {
                    longPickupDistance = "(" + breakUp.firstMileFare.details.totalUnits + " km)";
                }
                var surgeFare = this.getAmount(breakUp.surgeFare);
                var pickupFare = this.getAmount(breakUp.pickupFare);
                var waitTimeCharge = this.getAmount(breakUp.waitingFare);
                var waitingTime = '';
                if (waitTimeCharge > 0) {
                    waitTimeCharge = +waitTimeCharge;
                    waitingTime = "(" + breakUp.waitingFare.details.totalUnits + " min)";
                }
                if (breakUp.nightFare) {
                    var totalAmount = breakUp.nightFare && breakUp.nightFare.amount ? breakUp.nightFare.amount : 0;
                    captainEarningsMapping_1.nightFare = totalAmount;
                }
                if (isMinimumFare > 0) {
                    captainEarnings += minimumFare;
                    captainEarningsMapping_1.minimumFare = minimumFare;
                }
                else {
                    captainEarnings += distanceFare + timeFare;
                    captainEarningsMapping_1.distanceFare = +distanceFare.toFixed(2);
                    captainEarningsMapping_1.timeFare = timeFare;
                    captainEarningsMapping_1.totalDistance = totalDistance;
                    captainEarningsMapping_1.totalTime = totalTime;
                }
                if (longPickupFare > 0) {
                    captainEarnings += longPickupFare;
                    captainEarningsMapping_1.longPickupFare = "+\u20B9" + Math.abs(longPickupFare);
                    captainEarningsMapping_1.longPickupDistance = longPickupDistance;
                }
                if (pickupFare > 0) {
                    captainEarnings += pickupFare;
                    captainEarningsMapping_1.pickupFare = Math.abs(pickupFare);
                }
                if (surgeFare > 0) {
                    captainEarnings += surgeFare;
                    captainEarningsMapping_1.surgeFare = surgeFare;
                }
                if (waitTimeCharge > 0) {
                    captainEarnings += waitTimeCharge;
                    captainEarningsMapping_1.waitTimeFare = "+\u20B9" + Math.abs(waitTimeCharge);
                    captainEarningsMapping_1.waitTime = waitingTime;
                }
                if (breakUp.adjustments) {
                    if (Array.isArray(breakUp.adjustments) && breakUp.adjustments.length > 0) {
                        var breakupArray = breakUp.adjustments;
                        var adjustmentValue = this.getCompressedBreakup(breakupArray).amount;
                        captainEarningsMapping_1.adjustments = "+\u20B9" + Math.abs(adjustmentValue);
                    }
                    else {
                        captainEarningsMapping_1.adjustments = this.getAmountValue(this.getAmount(breakUp.adjustments));
                    }
                }
                if (breakUp.penalty) {
                    if (Array.isArray(breakUp.penalty) && breakUp.penalty.length > 0) {
                        var breakupArray = breakUp.penalty;
                        var penaltyValue = this.getCompressedBreakup(breakupArray).amount;
                        captainEarningsMapping_1.penalty = "-\u20B9" + Math.abs(penaltyValue);
                    }
                    else {
                        captainEarningsMapping_1.penalty = this.getAmountValue(this.getAmount(breakUp.penalty));
                    }
                }
                if (breakUp.tdsPenalty) {
                    if (Array.isArray(breakUp.tdsPenalty) && breakUp.tdsPenalty.length > 0) {
                        var breakupArray = breakUp.tdsPenalty;
                        var tdsPenaltyValue = this.getCompressedBreakup(breakupArray).amount;
                        captainEarningsMapping_1.tds = "-\u20B9" + Math.abs(tdsPenaltyValue).toFixed(2);
                    }
                    else {
                        captainEarningsMapping_1.tds = this.getAmountValue(this.getAmount(breakUp.tdsPenalty).toFixed(2));
                    }
                }
                if (breakUp.tip) {
                    if (Array.isArray(breakUp.tip) && breakUp.tip.length > 0) {
                        var breakupArray = breakUp.tip;
                        var breakupSpreadArray = this.getSpreadBreakup(breakupArray);
                        breakupSpreadArray.forEach(function (breakupItem) {
                            var value = _this.getRoundedAmount(breakupItem.amount);
                            captainEarningsMapping_1.tip = _this.getAmountValue(value);
                        });
                    }
                    else {
                        captainEarningsMapping_1.tip = this.getAmountValue(this.getAmount(breakUp.tip));
                    }
                }
                if (breakUp.tollFare) {
                    captainEarningsMapping_1.tollFare = this.getAmount(breakUp.tollFare);
                }
                if (breakUp.bidDelta) {
                    captainEarningsMapping_1.bidDelta = this.getAmount(breakUp.bidDelta);
                }
                if (breakUp.parkingCharges) {
                    captainEarningsMapping_1.parkingCharges = this.getAmount(breakUp.parkingCharges);
                }
            }
            return { earningsMapping: captainEarningsMapping_1, captainEarnings: captainEarnings };
        }
        return;
    };
    OrderDetailsHelper.prototype.calculatePaidToRapido = function (invoiceReceipt, captainEarnings, orderDetail) {
        //TODO - handle negative case
        var gst = this.getTaxCollected(invoiceReceipt);
        var customerBill = orderDetail.pricing.amount;
        var paidToRapido = customerBill - captainEarnings - (gst && gst.value);
        return +paidToRapido.toFixed(2);
    };
    OrderDetailsHelper.prototype.getTaxCollected = function (invoiceReceipt) {
        var taxAmount = 0;
        if (invoiceReceipt && invoiceReceipt.riderCharge && invoiceReceipt.riderCharge.tax) {
            taxAmount += parseFloat(invoiceReceipt.riderCharge.tax);
        }
        if (invoiceReceipt && invoiceReceipt.finalAmount && invoiceReceipt.finalAmount.tax) {
            taxAmount += parseFloat(invoiceReceipt.finalAmount.tax);
        }
        if (taxAmount != 0) {
            var finalTax = parseFloat(taxAmount.toFixed(2));
            return {
                key: this.orderDetailsMapping.KEY_TAX_COLLECTED,
                value: finalTax,
                remarks: this.orderDetailsMapping.REMARKS_TAX
            };
        }
        return {};
    };
    OrderDetailsHelper.prototype.getSpreadBreakup = function (breakupArray) {
        var breakups = [];
        breakupArray.forEach(function (item) {
            if (item && item.amount && !isNaN(item.amount) && item.amount != 0.0) {
                var amount = item.amount;
                var remarks = '';
                var unit = 'amount';
                if (item && item.details && item.details.remarks)
                    remarks = item.details.remarks;
                if (item && item.details && item.details.unit)
                    unit = item.details.unit;
                breakups.push({
                    amount: amount,
                    remarks: remarks,
                    unit: unit
                });
            }
        });
        return breakups;
    };
    OrderDetailsHelper.prototype.getCompressedBreakup = function (breakupArray) {
        var amount = 0.0;
        var remarks = '';
        var unit = 'amount';
        if (breakupArray != undefined) {
            breakupArray.forEach(function (item) {
                if (item && item.amount && !isNaN(item.amount) && item.amount != 0.0)
                    amount += item.amount;
                if (item && item.details && item.details.remarks)
                    remarks = item.details.remarks;
                if (item && item.details && item.details.unit)
                    unit = item.details.unit;
            });
            return {
                amount: amount,
                remarks: remarks,
                unit: unit
            };
        }
    };
    OrderDetailsHelper.prototype.getRoundedAmount = function (amount) {
        return amount.toFixed(2) * 1;
    };
    OrderDetailsHelper.prototype.getAmount = function (item) {
        var amount = 0.0;
        if (item && item.amount && !isNaN(item.amount) && item.amount != 0.0)
            amount += item.amount;
        return amount;
    };
    OrderDetailsHelper.prototype.getAmountValue = function (amount) {
        if (amount) {
            if (amount < 0) {
                var abs = Math.abs(amount);
                return "-" + abs;
            }
            return "" + amount;
        }
        return "0";
    };
    OrderDetailsHelper.prototype.getLabelWithInfo = function (breakupObject) {
        if (breakupObject && breakupObject.details && breakupObject.details.totalUnits && breakupObject.details.unit) {
            var value = this.getRoundedAmount(breakupObject.details.totalUnits);
            var unit = breakupObject.details.unit;
            return {
                extraInfo: "(" + value + " " + unit + "s)"
            };
        }
        return {
            extraInfo: ''
        };
    };
    OrderDetailsHelper.prototype.getTimeBasedSlab = function (data, orderEvents) {
        var timeBasedSlab = {};
        if (!this.isEmpty(data.customer.timeBasedSlabs) && !this.isEmpty(orderEvents)) {
            var orderRequestedDate_1 = moment(orderEvents[0].updatedAt).format('YYYY-MM-DD');
            var orderRequestedTime_1 = moment(orderEvents[0].updatedAt);
            var slabDetails = data.customer.timeBasedSlabs.find(function (slab) {
                var timeBasedSlabStartTime = moment(orderRequestedDate_1 + ' ' + slab.startTime, 'YYYY-MM-DD HH:mm:ss');
                var timeBasedSlabEndTime = moment(orderRequestedDate_1 + ' ' + slab.endTime, 'YYYY-MM-DD HH:mm:ss');
                return orderRequestedTime_1.isBetween(timeBasedSlabStartTime, timeBasedSlabEndTime);
            });
            if (!this.isEmpty(slabDetails)) {
                timeBasedSlab.key = slabDetails.appliedAt.charAt(0).toUpperCase() + slabDetails.appliedAt.slice(1) + " Fare";
                timeBasedSlab.value = slabDetails.rules[0].type == 'fact' ? "₹ " + slabDetails.rules[0].value : slabDetails.rules[0].value + " %";
            }
        }
        return timeBasedSlab;
    };
    OrderDetailsHelper.prototype.getCaptainTimeBasedSlab = function (data, orderEvents) {
        var timeBasedSlab = {};
        if (!this.isEmpty(data.captain.timeBasedSlabs) && !this.isEmpty(orderEvents)) {
            var orderRequestedDate_2 = moment(orderEvents[0].updatedAt).format('YYYY-MM-DD');
            var orderRequestedTime_2 = moment(orderEvents[0].updatedAt);
            var slabDetails = data.captain.timeBasedSlabs.find(function (slab) {
                var timeBasedSlabStartTime = moment(orderRequestedDate_2 + ' ' + slab.startTime, 'YYYY-MM-DD HH:mm:ss');
                var timeBasedSlabEndTime = moment(orderRequestedDate_2 + ' ' + slab.endTime, 'YYYY-MM-DD HH:mm:ss');
                return orderRequestedTime_2.isBetween(timeBasedSlabStartTime, timeBasedSlabEndTime);
            });
            if (!this.isEmpty(slabDetails)) {
                timeBasedSlab.key = slabDetails.appliedAt.charAt(0).toUpperCase() + slabDetails.appliedAt.slice(1) + " Fare";
                timeBasedSlab.value = slabDetails.rules[0].type == 'fact' ? "₹ " + slabDetails.rules[0].value : slabDetails.rules[0].value + " %";
            }
        }
        return timeBasedSlab;
    };
    OrderDetailsHelper.prototype.getCaptainTimeBasedSlabFromRateCardApi = function (captainRateCard, orderEvents) {
        var timeBasedSlab = {};
        if (!this.isEmpty(captainRateCard.timeBasedSlabs) && !this.isEmpty(orderEvents)) {
            var requestedEvent = orderEvents.find(function (event) {
                if (event.eventName === "requested") {
                    return event;
                }
            });
            if (!requestedEvent) {
                return timeBasedSlab;
            }
            var orderRequestedDate_3 = moment(requestedEvent.updatedAt).format('YYYY-MM-DD');
            var orderRequestedTime_3 = moment(requestedEvent.updatedAt);
            var slabDetails = captainRateCard.timeBasedSlabs.find(function (slab) {
                var timeBasedSlabStartTime = moment(orderRequestedDate_3 + ' ' + slab.startTime, 'YYYY-MM-DD HH:mm:ss');
                var timeBasedSlabEndTime = moment(orderRequestedDate_3 + ' ' + slab.endTime, 'YYYY-MM-DD HH:mm:ss');
                return orderRequestedTime_3.isBetween(timeBasedSlabStartTime, timeBasedSlabEndTime);
            });
            if (!this.isEmpty(slabDetails)) {
                timeBasedSlab.key = slabDetails.appliedAt.charAt(0).toUpperCase() + slabDetails.appliedAt.slice(1) + " Fare";
                timeBasedSlab.value = slabDetails.rules[0].type == 'flat' ? "₹ " + slabDetails.rules[0].value : slabDetails.rules[0].value + " %";
            }
        }
        return timeBasedSlab;
    };
    OrderDetailsHelper.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    OrderDetailsHelper.ngInjectableDef = i0.defineInjectable({ factory: function OrderDetailsHelper_Factory() { return new OrderDetailsHelper(); }, token: OrderDetailsHelper, providedIn: "root" });
    return OrderDetailsHelper;
}());
export { OrderDetailsHelper };
