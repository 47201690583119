  <app-page-header title="Customer details"></app-page-header>
  <div class="row">
    <div class="col-md-4">
      <app-customer-general-info [data]="generalInfo" (activate)="changeStatus(true)" (deactivate)="changeStatus(false)"></app-customer-general-info>
    </div>

    <div class="col-md-4">
      <app-customer-account [data]="accountInfo"></app-customer-account>
    </div>

    <div class="col-md-4">
      <app-customer-wallet [data]="walletInfo" [prevDueData]="prevDueinfo" (refresh)="getWallets()"></app-customer-wallet>
    </div>
  </div> 

  <div class="height-15"></div>

  <div class="row">
    <div class="col">
      <app-customer-stats [data]="statInfo" class="width-100"></app-customer-stats>
    </div>
  </div>

  <div class="height-15"></div>

  <div class="row">
    <div class="col">
      <app-customer-adjustment [coinPreference]="coinPreference" [walletInfo]="walletInfo" [customerDetails]="generalDetails" (adjustmentDone)="refreshPage(null)"></app-customer-adjustment>
    </div>
  </div> 

  <div class="height-15"></div>

  <div class="row">
    <div class="col">
      <app-customer-order-details *ngIf="toggleableDetails === 'Order Details'" class="width-100" [customerMobile]="mobileNumber" [customerId]="id" [customerDetailsToggleableHeaders] ="customerDetailsToggleableHeaders"
        (lastOrderDate)="getStatInfo($event)" (toggleHeaders)="headersToggled($event)"></app-customer-order-details>
        <app-customer-transaction-details (toggleHeaders)="headersToggled($event)" *ngIf="toggleableDetails === 'Transaction Details'" [customerId]="id" [customerDetailsToggleableHeaders] ="customerDetailsToggleableHeaders"></app-customer-transaction-details>
        <app-customer-activities (toggleHeaders)="headersToggled($event)" *ngIf="toggleableDetails === 'Activity Logs'" [customerId]="id" [customerDetailsToggleableHeaders] ="customerDetailsToggleableHeaders"></app-customer-activities>
        <app-cust-subscription-details (toggleHeaders)="headersToggled($event)" *ngIf="toggleableDetails === 'Subscription Logs'" [customerId]="id" [customerDetailsToggleableHeaders] ="customerDetailsToggleableHeaders"></app-cust-subscription-details>
        <app-customer-tickets (toggleHeaders)="headersToggled($event)" *ngIf="toggleableDetails === 'Ticket Details'" [customerId]="id" [mobile]="mobileNumber" [customerDetailsToggleableHeaders] ="customerDetailsToggleableHeaders"></app-customer-tickets>
        <app-customer-complaints (toggleHeaders)="headersToggled($event)" *ngIf="toggleableDetails === 'Customer Complaints'" [customerId]="id" [customerDetailsToggleableHeaders] ="customerDetailsToggleableHeaders"></app-customer-complaints>  
    </div>
  </div> 