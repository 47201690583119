var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { FullViewMapService } from '../full-view-map.service';
import * as _ from 'lodash';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { RideTrackerMapService } from '../ride-tracker-map/ride-tracker-map.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
import { TollsService } from '../tolls.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
var FullViewMapComponent = /** @class */ (function () {
    function FullViewMapComponent(fullViewMapService, rideTrackerMapService, toasterService, tollService) {
        this.fullViewMapService = fullViewMapService;
        this.rideTrackerMapService = rideTrackerMapService;
        this.toasterService = toasterService;
        this.tollService = tollService;
        this.fullViewMap = new EventEmitter();
        this.faRefresh = faRedo;
        this.slideOptions = [];
        this.markerWithPositions = {};
        this.bound = new google.maps.LatLngBounds(null);
        // setting markers
        this.infowindow = new google.maps.InfoWindow();
        this.abortMarker = new google.maps.Marker();
        this.arrivedMarker = new google.maps.Marker();
        this.captainCancelledMarker = new google.maps.Marker();
        this.customerCancelledMarker = new google.maps.Marker();
        this.droppedMarker = new google.maps.Marker();
        this.multipleMarker = new google.maps.Marker();
        this.newMarker = new google.maps.Marker();
        this.onTheWayMarker = new google.maps.Marker();
        this.expiredMarker = new google.maps.Marker();
        this.startedMarker = new google.maps.Marker();
        this.reachedMarker = new google.maps.Marker();
        this.captainLiveMarker = new google.maps.Marker();
        this.acceptRouteTitle = 'First Mile Travelled';
        // Setting icons
        this.abortedIcon = {
            url: '../../assets/map/aborted.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.arrivedIcon = {
            url: '../../assets/map/arrived.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.captainCancelledIcon = {
            url: '../../assets/map/captain-cancelled.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.customerCancelledIcon = {
            url: '../../assets/map/customer-cancelled.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.droppedIcon = {
            url: '../../assets/map/dropped.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.multipleStatusIcon = {
            url: '../../assets/map/multiple-status.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.newIcon = {
            url: '../../assets/map/new.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.otwIcon = {
            url: '../../assets/map/on-the-way.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.expiredIcon = {
            url: '../../assets/map/order-expired.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.startedIcon = {
            url: '../../assets/map/started.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.reachedIcon = {
            url: '../../assets/map/reached.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.polyStart = {
            url: '../../assets/map/polystart.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.polyEnd = {
            url: '../../assets/map/polyend.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.actualRouteMarker = {
            url: '../../assets/map/actualRoute.png',
            scaledSize: new google.maps.Size(10, 10)
        };
        this.acceptToArriveRouteMarker = {
            url: '../../assets/map/acceptToArriveRoute.png',
            scaledSize: new google.maps.Size(10, 10)
        };
        this.captainLiveIcon = {
            url: '../../assets/map/bike.png',
            scaledSize: new google.maps.Size(30, 30)
        };
        this.startedInitialMarker = new google.maps.Marker();
        this.endInitialMarker = new google.maps.Marker();
        this.startedFinalMarker = new google.maps.Marker();
        this.endFinalMarker = new google.maps.Marker();
        // Last Mile Travelled(hF)
        this.actualRoute = [];
        // accept to arrive route
        this.acceptToArriveRoute = [];
        // Alternate routes
        this.otherRoutes = [];
        this.startedOtherMarker = new Array();
        this.endOtherMarker = new Array();
        this.colorsForOtherRoutes = ['#0000FF', '#753a88'];
        this.selectedOptions = {};
        this.cityEntryTollLines = [];
        this.cityExitTollLines = [];
        this.tollPolylines = [];
        this.tollEnabled = false;
        this.tollsFormGroup = new FormGroup({
            selectedEntryTollLine: new FormControl(null, Validators.required),
            selectedExitTollLine: new FormControl(null, Validators.required),
        });
        this.captLiveCoords = { lat: null, lng: null };
        // status mapping
        this.statusMap = {
            '2': 'On Duty',
            '2-6': 'On Duty On Way',
            '4': 'Logged out',
            '5': 'Unreachable',
            '6': 'On The Way',
            '7': 'Arrived',
            '8': 'Started',
            '8-6': 'Started On Way',
            '8-2': 'Started On Duty',
            '9': 'Offline',
            '10': 'Reached'
        };
        this.terminalStates = ['4', '5', '9'];
        this.statusRed = ['customerCancelled', 'riderRejected', 'expired', 'aborted', 'riderCancelled', 'dropped'];
        this.showCaptainLocationRefresh = false;
    }
    FullViewMapComponent.prototype.ngOnInit = function () {
        this.mapProperties = {
            center: new google.maps.LatLng(21.7679, 78.8718),
            zoom: 5,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false,
            gestureHandling: 'cooperative'
        };
        this.riderLocation = { lat: 12.921191215515137, lng: 77.64920806884766 };
        this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
        this.startedOtherMarker[0] = this.startedOtherMarker[1] = new google.maps.Marker();
        this.endOtherMarker[0] = this.endOtherMarker[1] = new google.maps.Marker();
        if (this.mapData && this.mapData.selectedOptions) {
            this.selectedOptions = __assign({}, this.mapData.selectedOptions);
            this.toggleSelectedOptions();
        }
        if (this.mapData && this.mapData['cityBoundaryPolygon']) {
            this.mapData['cityBoundaryPolygon'].setMap(this.map);
        }
    };
    FullViewMapComponent.prototype.setToggleOptions = function () {
        this.mapData.options[4].addLine = true;
        this.slideOptions = this.mapData.options.slice();
    };
    FullViewMapComponent.prototype.changeInOption = function (option) {
        switch (option) {
            case 'Initial set route':
                this.toggleInitialRoute();
                break;
            case 'Final estimated route':
                this.toggleFinalRoute();
                break;
            case 'Last Mile Travelled(hF)':
                this.toggleActualRoute();
                break;
            case 'ODRD(Actual) route':
                this.toggleOdrdRoute();
                break;
            case 'Order Log Points':
                this.toggleOrderLogsView();
                break;
            case 'First Mile Travelled':
                this.toggleAcceptToArriveRoute();
                break;
            case 'Alternate routes':
                this.toggleOtherRoutes();
                break;
            case 'Tolls':
                this.togglePopulateTolls();
                break;
        }
    };
    FullViewMapComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.mapData && changes.mapData.currentValue) {
            if (changes.mapData.currentValue['routes']) {
                this.constructSideBar();
                this.getMarkers();
            }
            if (changes.mapData.currentValue['selectedOptions']) {
                this.selectedOptions = __assign({}, this.mapData.selectedOptions);
                this.setToggleOptions();
                this.toggleSelectedOptions();
            }
            if (changes.mapData.currentValue.actualRoute) {
                this.actualLatLngs = this.actualRoute;
            }
            if (changes.mapData.currentValue.riderId) {
                this.riderId = changes.mapData.currentValue.riderId;
            }
            if (changes.mapData.currentValue.orderStatus) {
                if (this.statusRed.indexOf(changes.mapData.currentValue.orderStatus) === -1) {
                    this.showCaptainLocationRefresh = true;
                    this.getCaptLiveLocation();
                }
            }
        }
    };
    FullViewMapComponent.prototype.showInfowindow = function (dataToShow, marker) {
        var infowindow = new google.maps.InfoWindow({
            content: dataToShow,
        });
        infowindow.open(this.map, marker);
    };
    FullViewMapComponent.prototype.toggleOrderLogsView = function () {
        var _this = this;
        if (this.selectedOptions['Order Log Points']) {
            if (this.mapData.captLiveLocInOrderLogs) {
                if (this.mapData.cancellationInformation && this.mapData.cancellationInformation.captainLocation &&
                    this.mapData.cancellationInformation.captainLocation.length === 2) {
                    var latLng = { lat: this.mapData.cancellationInformation.captainLocation[1],
                        lng: this.mapData.cancellationInformation.captainLocation[0] };
                    this.extendBounds(latLng);
                    this.captainLiveMarker.setPosition(latLng);
                    this.captainLiveMarker.setMap(this.map);
                    this.captainLiveMarker.setIcon(this.captainLiveIcon);
                    var dataToShow_1 = 'Captain location on cancellation';
                    this.showInfowindow(dataToShow_1, this.captainLiveMarker);
                    this.captainLiveMarker.addListener('click', function () {
                        _this.showInfowindow(dataToShow_1, _this.captainLiveMarker);
                    });
                }
            }
            var _loop_1 = function (log) {
                if (this_1.markerWithPositions[log]) {
                    var logs = this_1.markerWithPositions[log];
                    switch (logs.icon) {
                        case 'new':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.newMarker.setPosition(latLng);
                                this_1.newMarker.setMap(this_1.map);
                                this_1.newMarker.setIcon(this_1.newIcon);
                                var dataToShow_2 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_2, this_1.newMarker);
                                this_1.newMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_2, _this.newMarker);
                                });
                            }
                            break;
                        case 'onTheWay':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.onTheWayMarker.setPosition(latLng);
                                this_1.onTheWayMarker.setMap(this_1.map);
                                this_1.onTheWayMarker.setIcon(this_1.otwIcon);
                                var dataToShow_3 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_3, this_1.onTheWayMarker);
                                this_1.onTheWayMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_3, _this.onTheWayMarker);
                                });
                            }
                            break;
                        case 'arrived':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.arrivedMarker.setPosition(latLng);
                                this_1.arrivedMarker.setMap(this_1.map);
                                this_1.arrivedMarker.setIcon(this_1.arrivedIcon);
                                var dataToShow_4 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_4, this_1.arrivedMarker);
                                this_1.arrivedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_4, _this.arrivedMarker);
                                });
                            }
                            break;
                        case 'started':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.startedMarker.setPosition(latLng);
                                this_1.startedMarker.setMap(this_1.map);
                                this_1.startedMarker.setIcon(this_1.startedIcon);
                                var dataToShow_5 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_5, this_1.startedMarker);
                                this_1.startedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_5, _this.startedMarker);
                                });
                            }
                            break;
                        case 'reached':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.reachedMarker.setPosition(latLng);
                                this_1.reachedMarker.setMap(this_1.map);
                                this_1.reachedMarker.setIcon(this_1.reachedIcon);
                                var dataToShow_6 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_6, this_1.reachedMarker);
                                this_1.reachedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_6, _this.reachedMarker);
                                });
                            }
                            break;
                        case 'dropped':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.droppedMarker.setPosition(latLng);
                                this_1.droppedMarker.setMap(this_1.map);
                                this_1.droppedMarker.setIcon(this_1.droppedIcon);
                                var dataToShow_7 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_7, this_1.droppedMarker);
                                this_1.droppedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_7, _this.droppedMarker);
                                });
                            }
                            break;
                        case 'customerCancelled':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.customerCancelledMarker.setPosition(latLng);
                                this_1.customerCancelledMarker.setMap(this_1.map);
                                this_1.customerCancelledMarker.setIcon(this_1.customerCancelledIcon);
                                var dataToShow_8 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_8, this_1.customerCancelledMarker);
                                this_1.customerCancelledMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_8, _this.customerCancelledMarker);
                                });
                            }
                            break;
                        case 'riderCancelled':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.captainCancelledMarker.setPosition(latLng);
                                this_1.captainCancelledMarker.setMap(this_1.map);
                                this_1.captainCancelledMarker.setIcon(this_1.captainCancelledIcon);
                                var dataToShow_9 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_9, this_1.captainCancelledMarker);
                                this_1.captainCancelledMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_9, _this.captainCancelledMarker);
                                });
                            }
                            break;
                        case 'expired':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.expiredMarker.setPosition(latLng);
                                this_1.expiredMarker.setMap(this_1.map);
                                this_1.expiredMarker.setIcon(this_1.expiredIcon);
                                var dataToShow_10 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_10, this_1.expiredMarker);
                                this_1.expiredMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_10, _this.expiredMarker);
                                });
                            }
                            break;
                        case 'aborted':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.abortMarker.setPosition(latLng);
                                this_1.abortMarker.setMap(this_1.map);
                                this_1.abortMarker.setIcon(this_1.abortedIcon);
                                var dataToShow_11 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_11, this_1.abortMarker);
                                this_1.abortMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_11, _this.abortMarker);
                                });
                            }
                            break;
                        case 'multiple':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.multipleMarker.setPosition(latLng);
                                this_1.multipleMarker.setMap(this_1.map);
                                this_1.multipleMarker.setIcon(this_1.multipleStatusIcon);
                                var dataToShow_12 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_12, this_1.multipleMarker);
                                this_1.multipleMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_12, _this.multipleMarker);
                                });
                            }
                            break;
                    }
                    this_1.map.setCenter(this_1.bound.getCenter());
                }
            };
            var this_1 = this;
            for (var log in this.markerWithPositions) {
                _loop_1(log);
            }
        }
        else {
            this.abortMarker.setMap(null);
            this.arrivedMarker.setMap(null);
            this.captainCancelledMarker.setMap(null);
            this.customerCancelledMarker.setMap(null);
            this.droppedMarker.setMap(null);
            this.multipleMarker.setMap(null);
            this.newMarker.setMap(null);
            this.onTheWayMarker.setMap(null);
            this.expiredMarker.setMap(null);
            this.startedMarker.setMap(null);
        }
    };
    FullViewMapComponent.prototype.toggleCaptLiveLoc = function () {
        var _this = this;
        if (this.captLiveCoords && this.captLiveCoords.lat && this.captLiveCoords.lng) {
            var latLng = this.captLiveCoords;
            this.extendBounds(latLng);
            this.captainLiveMarker.setPosition(latLng);
            this.captainLiveMarker.setMap(this.map);
            this.captainLiveMarker.setIcon(this.captainLiveIcon);
            this.showInfowindow('Captain is ' + this.statusMap[this.captainCurrentStatus], this.captainLiveMarker);
            this.captainLiveMarker.addListener('click', function () {
                _this.showInfowindow('Captain is ' + _this.statusMap[_this.captainCurrentStatus], _this.captainLiveMarker);
            });
        }
    };
    FullViewMapComponent.prototype.constructSideBar = function () {
        if (this.mapData && this.mapData.routes) {
            var data = this.mapData.routes.fareEstimateLogs || [];
            var finalAllPaths = data[0] && data[0].direction && data[0].direction.allPaths;
            var dataLength = data.length;
            this.slideOptions.push({ title: 'Initial set route',
                distance: dataLength > 0 ? (data[dataLength - 1].direction.distanceInKms) + ' kms' : 'N/A',
                time: dataLength > 0 ? (data[dataLength - 1].direction.timeInMins) + ' mins' : 'N/A', polyline: '',
                addLine: true,
                disabled: !(data[dataLength - 1] && data[dataLength - 1].direction.allPaths && data[dataLength - 1].direction.allPaths.length > 0) });
            this.slideOptions.push({ title: 'Final estimated route',
                distance: this.mapData && this.mapData.routes && this.mapData.routes.finalEstimatedDistance ? this.mapData.routes.finalEstimatedDistance + 'kms' :
                    dataLength > 0 && data[0].allPaths.length > 0 ? ((data[0].allPaths[0].distance) / 1000).toFixed(2) + ' kms' : 'N/A',
                time: this.mapData && this.mapData.routes && this.mapData.routes.finalEstimatedTime ? this.mapData.routes.finalEstimatedTime + ' mins' : dataLength > 0 && data[0].allPaths.length > 0 ? ((data[0].allPaths[0].time) / 60).toFixed(2) + ' mins' : 'N/A',
                polyline: '', addLine: true,
                disabled: !(data[0] && data[0].allPaths && data[0].allPaths.length > 0) });
            this.slideOptions.push({ title: 'ODRD(Actual) route',
                distance: this.mapData && this.mapData.routes && this.mapData.routes.odrdDistance ? this.mapData.routes.odrdDistance + ' kms' : 'N/A',
                time: this.mapData && this.mapData.routes && this.mapData.routes.odrdTime ? this.mapData.routes.odrdTime + " mins" : 'N/A',
                polyline: '', addLine: true,
                disabled: !(this.mapData && this.mapData.routes && this.mapData.routes.odrdPolyline) });
            this.slideOptions.push({ title: 'Last Mile Travelled(hF)',
                distance: this.mapData.finalDistance ? this.mapData.finalDistance + ' kms' : 'N/A',
                time: this.mapData.finalRideTime ? this.mapData.finalRideTime + ' mins' : 'N/A', polyline: '', addLine: true,
                disabled: !(this.actualLatLngs && this.actualLatLngs.locations && this.actualLatLngs.locations.length > 0) });
            this.slideOptions.push({ title: 'First Mile Travelled', addLine: true,
                distance: this.mapData.acceptToArriveRoute && this.mapData.acceptToArriveRoute.distance ?
                    this.mapData.acceptToArriveRoute.distance.toFixed(2) + ' kms' : 'N/A',
                time: this.mapData.acceptToArriveRoute && this.mapData.acceptToArriveRoute.time ?
                    this.mapData.acceptToArriveRoute.time.toFixed(2) + ' mins' : 'N/A',
                disabled: !(this.acceptToArriveLatLngs && this.acceptToArriveLatLngs.locations &&
                    this.acceptToArriveLatLngs.locations.length > 0) });
            this.slideOptions.push({
                title: 'Alternate routes',
                polyline: '',
                addLine: false,
                distance: finalAllPaths && finalAllPaths && finalAllPaths[1] && finalAllPaths[1].distance ?
                    ((finalAllPaths[1].distance / 1000).toFixed(2)).toString() + ' kms' : 'N/A',
                time: finalAllPaths && finalAllPaths[1] && finalAllPaths[1].time ?
                    ((finalAllPaths[1].time / 60).toFixed(2)).toString() + ' mins' : 'N/A',
                color: 'color-blue',
                distance2: data[0] && finalAllPaths && finalAllPaths[2] && finalAllPaths[2].distance ?
                    ((finalAllPaths[2].distance / 1000).toFixed(2)).toString() + ' kms' : 'N/A',
                time2: data[0] && finalAllPaths && finalAllPaths[2] && finalAllPaths[2].time ?
                    ((finalAllPaths[2].time / 60).toFixed(2)).toString() + ' mins' : 'N/A',
                color2: 'color-red',
                disabled: !(data[0] && finalAllPaths && finalAllPaths.length > 1)
            });
            this.slideOptions.push({
                title: 'Tolls',
                distance: null,
                time: null,
                addLine: true,
                disabled: false
            });
        }
    };
    FullViewMapComponent.prototype.toggleSelectedOptions = function () {
        for (var option in this.selectedOptions) {
            if (option && this.selectedOptions[option]) {
                switch (option) {
                    case 'Initial set route':
                        this.toggleInitialRoute();
                        break;
                    case 'Final estimated route':
                        this.toggleFinalRoute();
                        break;
                    case 'Last Mile Travelled(hF)':
                        this.toggleActualRoute();
                        break;
                    case 'Order Log Points':
                        this.toggleOrderLogsView();
                        break;
                    case 'First Mile Travelled':
                        this.toggleAcceptToArriveRoute();
                        break;
                    case 'Alternate routes':
                        this.toggleOtherRoutes();
                        break;
                    case 'Tolls':
                        this.togglePopulateTolls();
                        break;
                }
            }
        }
    };
    FullViewMapComponent.prototype.getMarkerInfo = function (labels) {
        var htmlInfo = '';
        for (var label in labels) {
            if (labels[label]) {
                switch (labels[label]) {
                    case 'new':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 new-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> New Order </div>\n                </div>";
                        break;
                    case 'onTheWay':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 on-the-way-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> On The Way </div>\n                </div>";
                        break;
                    case 'arrived':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 arrived-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Arrived </div>\n                </div>";
                        break;
                    case 'started':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 started-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Started </div>\n                </div>";
                        break;
                    case 'reached':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 reached-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Reached </div>\n                </div>";
                        break;
                    case 'dropped':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 dropped-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Dropped </div>\n                </div>";
                        break;
                    case 'customerCancelled':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 customer-cancelled-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Customer Cancelled </div>\n                </div>";
                        break;
                    case 'riderCancelled':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 captain-cancelled-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Captain Cancelled </div>\n                </div>";
                        break;
                    case 'expired':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 expired-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Expired </div>\n                </div>";
                        break;
                    case 'aborted':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 aborted-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Aborted </div>\n                </div>";
                        break;
                }
            }
        }
        return htmlInfo;
    };
    FullViewMapComponent.prototype.getMarkers = function () {
        if (this.mapData && this.mapData.routes && this.mapData.routes.orderLogPoints) {
            this.markerWithPositions = {};
            for (var item in this.mapData.routes.orderLogPoints) {
                if (this.mapData.routes.orderLogPoints[item]) {
                    var log = this.mapData.routes.orderLogPoints[item];
                    if (log.eventName && log.captainLocation && log.captainLocation.lat && log.captainLocation.lng) {
                        var latLng = log.captainLocation.lat + ', ' + log.captainLocation.lng;
                        if (this.markerWithPositions[latLng]) {
                            this.markerWithPositions[latLng] = {
                                icon: 'multiple',
                                label: this.markerWithPositions[latLng].label.concat([log.eventName]),
                                lat: log.captainLocation.lat,
                                lng: log.captainLocation.lng
                            };
                        }
                        else {
                            this.markerWithPositions[latLng] = {
                                icon: log.eventName,
                                label: [log.eventName],
                                lat: log.captainLocation.lat,
                                lng: log.captainLocation.lng
                            };
                        }
                    }
                    else if (log.eventName && log.customerLocation && log.customerLocation.lat && log.customerLocation.lng) {
                        var latLng = log.customerLocation.lat + ', ' + log.customerLocation.lng;
                        if (this.markerWithPositions[latLng]) {
                            this.markerWithPositions[latLng] = {
                                icon: 'multiple',
                                label: this.markerWithPositions[latLng].label.concat([log.eventName]),
                                lat: log.customerLocation.lat,
                                lng: log.customerLocation.lng
                            };
                        }
                        else {
                            this.markerWithPositions[latLng] = {
                                icon: log.eventName,
                                label: [log.eventName],
                                lat: log.customerLocation.lat,
                                lng: log.customerLocation.lng
                            };
                        }
                    }
                }
            }
        }
    };
    FullViewMapComponent.prototype.getCaptLiveLocation = function () {
        var _this = this;
        if (this.riderId) {
            this.rideTrackerMapService.getLiveLocation(this.riderId).subscribe(function (res) {
                if (res && res['location'] && res['location'].length === 2) {
                    _this.captLiveCoords.lng = res['location'][0];
                    _this.captLiveCoords.lat = res['location'][1];
                    _this.captainCurrentStatus = res['status'] || '5';
                    _this.toggleCaptLiveLoc();
                }
            });
        }
    };
    FullViewMapComponent.prototype.toggleInitialRoute = function () {
        if (this.selectedOptions['Initial set route']) {
            if (this.mapData.isServiceDeliveryType && this.mapData.events.requestedEvent) {
                var initialPosition = { lat: this.mapData.events.requestedEvent.pickupLocation.lat, lng: this.mapData.events.requestedEvent.pickupLocation.lng };
                var finalPosition = { lat: this.mapData.events.requestedEvent.dropLocation.lat, lng: this.mapData.events.requestedEvent.dropLocation.lng };
                this.startedInitialMarker.setIcon(this.polyStart);
                this.startedInitialMarker.setPosition(initialPosition);
                this.startedInitialMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer set pickup location',
                });
                infowindow.open(this.map, this.startedInitialMarker);
                this.endInitialMarker.setIcon(this.polyEnd);
                this.endInitialMarker.setPosition(finalPosition);
                this.endInitialMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer set drop location',
                });
                infowindow2.open(this.map, this.endInitialMarker);
                this.extendBounds(initialPosition);
                this.extendBounds(finalPosition);
            }
            else {
                var encodedStr = this.mapData.routes.fareEstimateLogs[this.mapData.routes.fareEstimateLogs.length - 1].direction.polyline;
                var initialPath = google.maps.geometry.encoding.decodePath(encodedStr);
                this.initialRoute = new google.maps.Polyline({
                    path: initialPath,
                    geodesic: true,
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                this.startedInitialMarker.setIcon(this.polyStart);
                this.startedInitialMarker.setPosition(this.initialRoute.getPath().getAt(0));
                this.startedInitialMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer set pickup location',
                });
                infowindow.open(this.map, this.startedInitialMarker);
                this.endInitialMarker.setIcon(this.polyEnd);
                this.endInitialMarker.setPosition(this.initialRoute.getPath().getAt(this.initialRoute.getPath().getLength() - 1));
                this.endInitialMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer set drop location',
                });
                infowindow2.open(this.map, this.endInitialMarker);
                for (var i = 0; i < initialPath.length; i++) {
                    this.extendBounds(initialPath[i]);
                }
                this.initialRoute.setMap(this.map);
            }
        }
        else {
            if (this.initialRoute) {
                this.initialRoute.setMap(null);
            }
            this.startedInitialMarker.setMap(null);
            this.endInitialMarker.setMap(null);
        }
    };
    FullViewMapComponent.prototype.toggleFinalRoute = function () {
        if (this.selectedOptions['Final estimated route']) {
            if (this.mapData.isServiceDeliveryType) {
                var initialPosition = { lat: this.mapData.events.startedEvent.pickupLocation.lat, lng: this.mapData.events.startedEvent.pickupLocation.lng };
                var finalPosition = { lat: this.mapData.events.startedEvent.dropLocation.lat, lng: this.mapData.events.startedEvent.dropLocation.lng };
                this.startedFinalMarker.setIcon(this.polyStart);
                this.startedFinalMarker.setPosition(initialPosition);
                this.startedFinalMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer pickup location',
                });
                infowindow.open(this.map, this.startedFinalMarker);
                this.endFinalMarker.setIcon(this.polyEnd);
                this.endFinalMarker.setPosition(finalPosition);
                this.endFinalMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer drop location',
                });
                infowindow2.open(this.map, this.endFinalMarker);
                this.extendBounds(initialPosition);
                this.extendBounds(finalPosition);
            }
            else {
                var encodedStr = this.mapData && this.mapData.routes && this.mapData.routes.finalEstimatedPolyline ? this.mapData.routes.finalEstimatedPolyline : this.mapData.routes.fareEstimateLogs[0].direction.polyline;
                var finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
                for (var i = 0; i < finalPath.length; i++) {
                    this.extendBounds(finalPath[i]);
                }
                this.finalRoute = new google.maps.Polyline({
                    path: finalPath,
                    geodesic: true,
                    strokeColor: '#0FB27D',
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                this.startedFinalMarker.setIcon(this.polyStart);
                this.startedFinalMarker.setPosition(this.finalRoute.getPath().getAt(0));
                this.startedFinalMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer pickup location',
                });
                infowindow.open(this.map, this.startedFinalMarker);
                this.endFinalMarker.setIcon(this.polyEnd);
                this.endFinalMarker.setPosition(this.finalRoute.getPath().getAt(this.finalRoute.getPath().getLength() - 1));
                this.endFinalMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer drop location',
                });
                infowindow2.open(this.map, this.endFinalMarker);
                this.finalRoute.setMap(this.map);
            }
        }
        else {
            if (this.finalRoute) {
                this.finalRoute.setMap(null);
            }
            this.startedFinalMarker.setMap(null);
            this.endFinalMarker.setMap(null);
        }
    };
    FullViewMapComponent.prototype.toggleOdrdRoute = function () {
        if (this.selectedOptions['ODRD(Actual) route'] && this.mapData && this.mapData.routes && this.mapData.routes.odrdPolyline) {
            var encodedStr = this.mapData.routes.odrdPolyline;
            var finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
            for (var i = 0; i < finalPath.length; i++) {
                this.extendBounds(finalPath[i]);
            }
            this.finalRoute = new google.maps.Polyline({
                path: finalPath,
                geodesic: true,
                strokeColor: '#000099',
                strokeOpacity: 1.0,
                strokeWeight: 3
            });
            this.startedFinalMarker.setIcon(this.polyStart);
            this.startedFinalMarker.setPosition(this.finalRoute.getPath().getAt(0));
            this.startedFinalMarker.setMap(this.map);
            var infowindow = new google.maps.InfoWindow({
                content: 'Customer pickup location',
            });
            infowindow.open(this.map, this.startedFinalMarker);
            this.endFinalMarker.setIcon(this.polyEnd);
            this.endFinalMarker.setPosition(this.finalRoute.getPath().getAt(this.finalRoute.getPath().getLength() - 1));
            this.endFinalMarker.setMap(this.map);
            var infowindow2 = new google.maps.InfoWindow({
                content: 'Customer drop location',
            });
            infowindow2.open(this.map, this.endFinalMarker);
            this.finalRoute.setMap(this.map);
        }
        else {
            if (this.finalRoute) {
                this.finalRoute.setMap(null);
                this.startedFinalMarker.setMap(null);
                this.endFinalMarker.setMap(null);
            }
        }
    };
    FullViewMapComponent.prototype.toggleActualRoute = function () {
        if (this.selectedOptions['Last Mile Travelled(hF)']) {
            var locations = this.mapData.actualRoute['locations'];
            for (var position in locations) {
                if (locations[position]) {
                    if (locations[position].location && locations[position].location[0] && locations[position].location[1]) {
                        var latLng = { lat: locations[position].location[1], lng: locations[position].location[0] };
                        // this.extendBounds(latLng);
                        this.actualRoute[position] = new google.maps.Marker({
                            icon: this.actualRouteMarker
                        });
                        this.actualRoute[position].setPosition(latLng);
                        this.actualRoute[position].setMap(this.map);
                    }
                }
            }
        }
        else {
            if (this.actualRoute.length > 0) {
                for (var position in this.actualRoute) {
                    if (this.actualRoute[position]) {
                        this.actualRoute[position].setMap(null);
                    }
                }
            }
        }
    };
    FullViewMapComponent.prototype.toggleAcceptToArriveRoute = function () {
        if (this.selectedOptions['First Mile Travelled']) {
            var locations = this.mapData.acceptToArriveRoute['locations'];
            for (var position in locations) {
                if (locations[position]) {
                    if (locations[position].location && locations[position].location[0] && locations[position].location[1]) {
                        var latLng = { lat: locations[position].location[1], lng: locations[position].location[0] };
                        // this.extendBounds(latLng);
                        this.acceptToArriveRoute[position] = new google.maps.Marker({
                            icon: this.acceptToArriveRouteMarker
                        });
                        this.acceptToArriveRoute[position].setPosition(latLng);
                        this.acceptToArriveRoute[position].setMap(this.map);
                    }
                }
            }
        }
        else {
            if (this.acceptToArriveRoute.length > 0) {
                for (var position in this.acceptToArriveRoute) {
                    if (this.acceptToArriveRoute[position]) {
                        this.acceptToArriveRoute[position].setMap(null);
                    }
                }
            }
        }
    };
    FullViewMapComponent.prototype.toggleOtherRoutes = function () {
        if (this.selectedOptions['Alternate routes']) {
            var otherRoutes = _.cloneDeep(this.mapData.routes.fareEstimateLogs[0].direction.allPaths);
            var otherRoutesGoogle = otherRoutes.splice(1, 2);
            for (var path in otherRoutesGoogle) {
                if (otherRoutesGoogle[path]) {
                    var encodedStr = otherRoutesGoogle[path]['polyline'];
                    var otherPath = google.maps.geometry.encoding.decodePath(encodedStr);
                    this.otherRoutes[path] = new google.maps.Polyline({
                        path: otherPath,
                        geodesic: true,
                        strokeColor: this.colorsForOtherRoutes[path],
                        strokeOpacity: 1.0,
                        strokeWeight: 3
                    });
                    this.startedOtherMarker[path] = new google.maps.Marker({
                        position: this.otherRoutes[path].getPath().getAt(0),
                        map: this.map,
                        icon: this.polyStart
                    });
                    this.endOtherMarker[path] = new google.maps.Marker({
                        position: this.otherRoutes[path].getPath().getAt(this.otherRoutes[path].getPath().getLength() - 1),
                        map: this.map,
                        icon: this.polyEnd
                    });
                    for (var i = 0; i < otherPath.length; i++) {
                        // this.extendBounds(otherPath[i]);
                    }
                    this.otherRoutes[path].setMap(this.map);
                }
            }
        }
        else {
            if (this.otherRoutes && this.otherRoutes.length > 0) {
                for (var route in this.otherRoutes) {
                    if (this.otherRoutes[route]) {
                        this.otherRoutes[route].setMap(null);
                        this.startedOtherMarker[route].setMap(null);
                        this.endOtherMarker[route].setMap(null);
                    }
                }
            }
        }
    };
    FullViewMapComponent.prototype.extendBounds = function (location) {
        if (!this.map) {
            this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
        }
        this.bound.extend(location);
        this.map.fitBounds(this.bound, 100);
        this.map.panToBounds(this.bound, 10);
    };
    FullViewMapComponent.prototype.closeFullView = function () {
        this.fullViewMapService.toggleFullMap();
    };
    FullViewMapComponent.prototype.togglePopulateTolls = function () {
        var _this = this;
        if (this.selectedOptions['Tolls']) {
            this.tollEnabled = true;
            if (this.mapData.cityTolls.length == 0) {
                this.rideTrackerMapService.getCityTolls(this.mapData.orderCityId).subscribe(function (result) {
                    if (result) {
                        _this.mapData.cityTolls = result['tolls'];
                        _this.tollPolylines = _this.tollService.addTollsToMap(_this.mapData.cityTolls, _this.map);
                    }
                }, function (error) {
                    if (error) {
                        _this.selectedOptions['Tolls'] = false;
                        _this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: 'Toll fetch failed'
                        }));
                    }
                });
            }
            else {
                this.tollPolylines = this.tollService.addTollsToMap(this.mapData.cityTolls, this.map);
            }
            var tollLines = this.tollService.fetchTollLinesFromTolls(this.mapData.cityTolls);
            this.cityEntryTollLines = tollLines.entryTollLines;
            this.cityExitTollLines = tollLines.exitTollLines;
        }
        else {
            this.tollEnabled = false;
            this.tollService.removeTollsFromMap(this.map, this.tollPolylines);
        }
    };
    return FullViewMapComponent;
}());
export { FullViewMapComponent };
