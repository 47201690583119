/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./captain-general-info.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/table/table.component.ngfactory";
import * as i3 from "../../shared/table/table.component";
import * as i4 from "../../filter.service";
import * as i5 from "./captain-general-info.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../captain.service";
import * as i8 from "../../appConfig.service";
import * as i9 from "../../entity.service";
import * as i10 from "../../svo.service";
import * as i11 from "../../kyc.service";
import * as i12 from "../../toaster.service";
var styles_CaptainGeneralInfoComponent = [i0.styles];
var RenderType_CaptainGeneralInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaptainGeneralInfoComponent, data: {} });
export { RenderType_CaptainGeneralInfoComponent as RenderType_CaptainGeneralInfoComponent };
export function View_CaptainGeneralInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(1, 638976, null, 0, i3.TableComponent, [i4.FilterService], { table: [0, "table"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.generalInfoTable; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaptainGeneralInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-captain-general-info", [], null, null, null, View_CaptainGeneralInfoComponent_0, RenderType_CaptainGeneralInfoComponent)), i1.ɵdid(1, 114688, null, 0, i5.CaptainGeneralInfoComponent, [i6.MatDialog, i7.CaptainService, i8.AppConfigService, i9.EntityService, i10.SvoService, i11.KycService, i12.ToasterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaptainGeneralInfoComponentNgFactory = i1.ɵccf("app-captain-general-info", i5.CaptainGeneralInfoComponent, View_CaptainGeneralInfoComponent_Host_0, { mobile: "mobile", device: "device", captainDetails: "captainDetails" }, {}, []);
export { CaptainGeneralInfoComponentNgFactory as CaptainGeneralInfoComponentNgFactory };
