/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-invoice-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./payment-summary/payment-summary.component.ngfactory";
import * as i3 from "./payment-summary/payment-summary.component";
import * as i4 from "./bill-of-supply/bill-of-supply.component.ngfactory";
import * as i5 from "./bill-of-supply/bill-of-supply.component";
import * as i6 from "./tax-invoice/tax-invoice.component.ngfactory";
import * as i7 from "./tax-invoice/tax-invoice.component";
import * as i8 from "@angular/forms";
import * as i9 from "@angular/common";
import * as i10 from "./customer-invoice-details.component";
import * as i11 from "@angular/router";
import * as i12 from "./customer-invoice-details.service";
import * as i13 from "../toaster.service";
var styles_CustomerInvoiceDetailsComponent = [i0.styles];
var RenderType_CustomerInvoiceDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerInvoiceDetailsComponent, data: {} });
export { RenderType_CustomerInvoiceDetailsComponent as RenderType_CustomerInvoiceDetailsComponent };
function View_CustomerInvoiceDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); }); }
function View_CustomerInvoiceDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invoice-content payment-summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-payment-summary", [], null, null, null, i2.View_PaymentSummaryComponent_0, i2.RenderType_PaymentSummaryComponent)), i1.ɵdid(2, 114688, null, 0, i3.PaymentSummaryComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentSummary; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomerInvoiceDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invoice-content bill-of-supply"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-bill-of-supply", [], null, null, null, i4.View_BillOfSupplyComponent_0, i4.RenderType_BillOfSupplyComponent)), i1.ɵdid(2, 114688, null, 0, i5.BillOfSupplyComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.billOfSupply; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomerInvoiceDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invoice-content tax-invoice"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-tax-invoice", [], null, null, null, i6.View_TaxInvoiceComponent_0, i6.RenderType_TaxInvoiceComponent)), i1.ɵdid(2, 114688, null, 0, i7.TaxInvoiceComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taxInvoice; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomerInvoiceDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "invoice-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "header-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "invoice-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invoices Details"])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "search-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "input-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "input", [["autofocus", ""], ["class", "search-email-input"], ["placeholder", "Enter customer email address"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.email = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        var pd_5 = (_co.sendInvoice() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(12, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "send-btn-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "send-invoice-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendInvoice() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Send Invoice"])), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "invoice-content-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerInvoiceDetailsComponent_1)), i1.ɵdid(18, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerInvoiceDetailsComponent_2)), i1.ɵdid(20, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerInvoiceDetailsComponent_3)), i1.ɵdid(22, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerInvoiceDetailsComponent_4)), i1.ɵdid(24, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.email; _ck(_v, 10, 0, currVal_7); var currVal_8 = _co.errorMessage; _ck(_v, 18, 0, currVal_8); var currVal_9 = _co.paymentSummary; _ck(_v, 20, 0, currVal_9); var currVal_10 = _co.billOfSupply; _ck(_v, 22, 0, currVal_10); var currVal_11 = _co.taxInvoice; _ck(_v, 24, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_CustomerInvoiceDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-invoice-details", [], null, null, null, View_CustomerInvoiceDetailsComponent_0, RenderType_CustomerInvoiceDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i10.CustomerInvoiceDetailsComponent, [i11.ActivatedRoute, i12.CustomerInvoiceDetailsService, i13.ToasterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerInvoiceDetailsComponentNgFactory = i1.ɵccf("app-customer-invoice-details", i10.CustomerInvoiceDetailsComponent, View_CustomerInvoiceDetailsComponent_Host_0, {}, {}, []);
export { CustomerInvoiceDetailsComponentNgFactory as CustomerInvoiceDetailsComponentNgFactory };
