import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { UserData } from '../manager-profile.types'
import {
  ManagerRapidoAccountService,
  UpdateSearchRoleActions,
} from './manager-rapido-account.service'
import { MatSlideToggleChange } from '@angular/material/slide-toggle'
import { RapidoAccountTable } from './manager-rapido-account.types'
import { Toaster, ToasterType } from 'src/app/shared/types'
import { ToasterService } from 'src/app/toaster.service'

@Component({
  selector: 'app-manager-rapido-account',
  templateUrl: './manager-rapido-account.component.html',
  styleUrls: ['./manager-rapido-account.component.css'],
})
export class ManagerRapidoAccountComponent implements OnInit, OnChanges {
  @Input() data: UserData
  private searchRoleActive: boolean = false
  private searchRoleToggleActive: boolean = false
  public info: RapidoAccountTable
  constructor(
    private toasterService: ToasterService,
    private managerRapidoAccountService: ManagerRapidoAccountService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue
      if (this.data) {
        this.fetchAccountDetails()
        this.constructCard()
      } else {
        this.blankCard()
      }
    } else {
      this.emptyCard()
    }
  }

  ngOnInit() {
    this.searchRoleToggleActive = this.isAllowedToUpdateSearchRole()
    this.fetchAccountDetails()
  }

  isAllowedToUpdateSearchRole(): boolean {
    return this.accessCookie('accessRoles')
      .split(',')
      .includes('manager_search_role_edit')
  }

  fetchAccountDetails() {
    if (
      this.data &&
      this.data.roles &&
      Array.isArray(this.data.roles) &&
      this.data.roles.includes('pm_search_captain')
    ) {
      this.searchRoleActive = true
    }
    this.constructCard()
  }

  blankCard() {
    this.info = {
      header: 'General Info',
      data: [],
      dataFetched: false,
    }
  }

  emptyCard() {
    this.info = {
      header: 'General Info',
      dataFetched: false,
      data: null,
    }
  }

  constructCard() {
    if (this.data) {
      this.info = {
        header: 'Rapido Account',
        dataFetched: true,
        data: [
          {
            header: 'PM Search role',
            value: '',
            type: 'toggle',
            checked: this.searchRoleActive,
            disabled: !this.searchRoleToggleActive,
          },
        ],
      }
    }
  }

  accessCookie(cookieName) {
    const name = cookieName + '='
    const allCookieArray = document.cookie.split(';')
    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim()
      if (temp.indexOf(name) === 0) {
        return temp.substring(name.length, temp.length)
      }
    }
    return ''
  }

  eventTriggered(toggleChange: MatSlideToggleChange) {
    this.searchRoleToggleActive = false
    this.searchRoleActive = toggleChange.checked
    this.constructCard()
    this.managerRapidoAccountService
      .updateSearchRole(
        this.data._id,
        toggleChange.checked
          ? UpdateSearchRoleActions.ADD
          : UpdateSearchRoleActions.REMOVE
      )
      .subscribe(
        (_success) => {
          this.searchRoleToggleActive = true
          this.searchRoleActive = toggleChange.checked
          this.constructCard()
        },
        (_error) => {
          this.searchRoleToggleActive = true
          this.searchRoleActive = !toggleChange.checked
          this.constructCard()
          this.toasterService.showToaster(
            new Toaster({
              type: ToasterType.WARNING,
              message: `Could not update search role! Please refresh the page and try again`,
              delay: 5000,
            })
          )
        }
      )
  }
}
