import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UserComplaintsService } from '../../customer-complaints.service';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-complaints',
  templateUrl: './customer-complaints.component.html',
  styleUrls: ['./customer-complaints.component.css']
})
export class CustomerComplaintsComponent implements OnInit {

  constructor(
    public ComplaintsService: UserComplaintsService,
    private router: Router,
  ) { }

  @Input() customerId: any;
  @Input() customerDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  dataForReason: any[];
  customerComplaints: Table;
  dataLoaded = false;
  innerTableDataSource : any[] = [];
  innerTableDisplayedColumns : string[] = ['reason','subReason','complaintsToday','complaintsLast3Days','complaintsLast7Days','complaintsLast15Days','complaintsLast30Days', 'createdDate'];

  async ngOnInit() {
   await this.declareTable();
  }

 async declareTable(pageNumber = 0) {
    this.customerComplaints = new Table({
        toggleableHeaders: this.customerDetailsToggleableHeaders,
        headers: {
        reason: 'Reason',
        subReason: 'Sub Reason',
        complaintsToday: 'Complaints Today',
        complaintsLast3Days: 'Complaints Last 3 Days',
        complaintsLast7Days: 'Complaints Last 7 Days',
        complaintsLast15Days: 'Complaints Last 15 Days',
        complaintsLast30Days: 'Complaints Last 30 Days',
        day: 'Created Date',
      },
      pagination: new Pagination(pageNumber, 10),
      selectedHeader: 5,
      defaultError: 'No Complaints Found',
      onRefresh: () => {
        this.customerComplaints = null;
        this.ngOnInit();
      },
      data: []
    });
    await this.getComplaints();
  }

  async getComplaints() {
    this.dataLoaded = false;
    try {
      const response : any = await this.ComplaintsService.fetchCustomerComplaints(this.customerId).toPromise();
      this.dataLoaded = true;
      if (response && response.data.found) {
        this.customerComplaints.data = this.formatCustomerComplaints(response.data.complaints);
      }
    } catch(error) {
      console.log('Error while fetching complaints for customer---', error);
      this.dataLoaded = true;
    }
  }

  formatCustomerComplaints(customerComplaints = []) {
    let innerTableData = [];
    let formatedComplaints = []; let complaint = {} as any;
    customerComplaints = this.reStructure(customerComplaints);
    customerComplaints.map((reason) => {
      let totalComplaintsToday=0;let totalComplaintsLast3Days=0;let totalComplaintsLast7Days=0;let totalComplaintsLast15Days=0; let totalComplaintsLast30Days = 0;
      innerTableData = [];
      const r : any = Object.keys(reason);
      reason[r].forEach((item) => {
        totalComplaintsToday += item.complaintsToday;
        totalComplaintsLast3Days += item.complaintsLast3Days;
        totalComplaintsLast7Days += item.complaintsLast7Days;
        totalComplaintsLast15Days += item.complaintsLast15Days;
        totalComplaintsLast30Days += item.complaintsLast30Days;
        complaint = {
          reason: this.createTableData(r[0] || ''),
          subReason: "Total",
          complaintsToday: this.createTableData(totalComplaintsToday || ''),
          complaintsLast3Days: this.createTableData(totalComplaintsLast3Days || ''),
          complaintsLast7Days: this.createTableData(totalComplaintsLast7Days || ''),
          complaintsLast15Days: this.createTableData(totalComplaintsLast15Days || ''),
          complaintsLast30Days: this.createTableData(totalComplaintsLast30Days || ''),
          day: '',
        };
        innerTableData.push({
          reason: '-',
          subReason: item.subReason || '-',
          complaintsToday: item.complaintsToday || '-',
          complaintsLast3Days: item.complaintsLast3Days || '-',
          complaintsLast7Days: item.complaintsLast7Days || '-',
          complaintsLast15Days: item.complaintsLast15Days || '-',
          complaintsLast30Days: item.complaintsLast30Days || '-',
          createdDate: item.day
        });
      });
      this.innerTableDataSource.push({[r] : innerTableData});
      formatedComplaints.push(complaint);
    });
    this.customerComplaints.data = formatedComplaints;
    return formatedComplaints;
  }

  reStructure(complains): any[] {
  // This code re-structures the complains array received from svo reason-wise. 
  // Example : [reason1 : {response from svo without reason}, reason2: {}]
  const result = [];
   complains.forEach((c) => {
      const reason =  c.reason;
      delete c.reason;
      const filteredReason = this.checkIfReasonExists(result, reason);
      if(filteredReason && Object.keys(filteredReason).length > 0){
        filteredReason[reason].push(c);
      }else {
        result.push({[reason]: [c]});
      }
    });
    return result;
  }

  checkIfReasonExists(arr: any[], reason: string) : any{
    const filteredReasons =  arr.filter((obj: any) =>  {return obj.hasOwnProperty(reason)});
    return filteredReasons[0];
  }
  
  createTableData(value, className?, type?, flag = false, hoverData?) {
    return new TableData({
      data: value,
      type: type ? type : TableDataTypes.DATA,
      flag: flag,
      hoverData: hoverData ? hoverData : null,
      className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  onPaginationChange(event) {
    this.getComplaints();
  }
}
