import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerGeneralInfoService {

  constructor(private http: MyHttpClient) { }

  server = `${environment.server}`;
  removeDevice = this.server + '/api/removeImei';
  logoutCust = this.server + '/api/logoutCustomer';
  activate = this.server + '/api/activateCustomer';
  deactivate = this.server + '/api/deactivateCustomer';
  referee = this.server + '/api/getRefereeDetails'
  toggleCustomerStatus = this.server + '/api/toggleCustomerStatus';

  removeDeviceId(deviceId) {
    const payload = {
      deviceId: deviceId
    };
    return this.http.post(this.removeDevice, payload).pipe(catchError(this.handleError));
  }

  logoutCustomer(userId) {
    const payload = {
      userId: userId
    };
    return this.http.post(this.logoutCust, payload).pipe(catchError(this.handleError));
  }

  changeCustomerStatus(body) {
    return this.http.post(this.toggleCustomerStatus, body).pipe(catchError(this.handleError));
  }

  getRefereeDetails(id, mobile) {
    return this.http.get(this.referee + '/' + id + '/CUSTOMER/' + mobile).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
