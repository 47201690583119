import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OrderService } from 'src/app/order-service.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { CustomerOrderDetailsService } from '../../customer-details/customer-order-details/customer-order-details.service';
import { FilterService, TextFilterInput,
  SearchableDropDownFilterInput, RangeSliderInput, SingleSearchableDropdown, QuickFilters} from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { MerchantService } from '../merchant.service';
const currentDate = new Date();
const startDate = new Date(2010, 1, 1);
export const DEFAULT_DATE = {
  FROM_DATE: new Date(new Date(startDate).setDate(new Date(startDate).getDate() - 1)),
  TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};

function defaultFiters() {
  return {
    startDuration: '',
    endDuration: '',
    cities: '',
    services: '',
    orderId: '',
    status: 'all'
  };
}
@Component({
  selector: 'app-merchant-order-details',
  templateUrl: './merchant-order-details.component.html',
  styleUrls: ['./merchant-order-details.component.css']
})
export class MerchantOrderDetailsComponent implements OnInit, OnChanges {
  @Input() merchantId;
  @Input() merchantCity;
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  serviceMap = {};
  cityMap = {};

  orderTable: Table;
  currentNumber: any;
  fromDate = DEFAULT_DATE.FROM_DATE;
  toDate = DEFAULT_DATE.TO_DATE;
  filtersApplied = defaultFiters();
  cities: any = [];
  services: any = [];
  sendInfo: any;
  orders: any[];
  statusList: any = [
    'all',
    'new',
    'customerCancelled',
    'riderCancelled',
    'onTheWay',
    'arrived',
    'started',
    'dropped',
    'expired',
    'aborted'
  ];
  filterCount: any;
  constructor(
    public orderService: OrderService,
    public filterService: FilterService,
    public entityService: EntityService,
    private customerOrderDetailsService: CustomerOrderDetailsService,
    private dataService: DataService,
    private router: Router,
    private merchantService: MerchantService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.merchantId) {
      if (this.merchantId) {
        this.defineOrderTable(this.merchantId, DEFAULT_DATE.FROM_DATE, DEFAULT_DATE.TO_DATE, this.filtersApplied);
      }
    }
  }

  ngOnInit() {
    this.defineOrderTable(this.merchantId);
    this.entityService.getCities().subscribe(cities => {
      for (const city in cities) {
        if (cities[city]) {
          this.cityMap[cities[city].toString()] = cities[city].valueOf();
        }
      }
      this.cities.push(...cities);
      const fetchServicesForCity = this.cityMap[this.merchantCity];
      this.entityService.getServicesPerCity(fetchServicesForCity).subscribe(services => {
        services = services['data'];
        this.services.splice(0);
        for (const service in services) {
          if (services[service] && services[service]._id && services[service].service && services[service].service.active) {
            this.services.push(services[service].service.name);
            this.serviceMap[services[service].service.name] = services[service]._id;
          }
        }
      });
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  private declareTable(pageNumber = 0) {

    this.dataService.searchUserCount.subscribe(result => {
      if (!this.currentNumber || (this.currentNumber === result)) {
        this.filterService.appliedFilters = this.filtersApplied;
      } else {
        this.filterService.appliedFilters = {};
        this.filtersApplied = defaultFiters();
      }
      this.currentNumber = result;
    });
    this.orderTable = new Table({
      // tableHeader: 'Order Details',
      toggleableHeaders: ['Order Details', 'Transaction Details'],
      selectedHeader: 0,
      headers: {
        dateTime: 'Date \n Time',
        orderIdCity: 'Order Id \n City',
        pickupDrop: 'Pickup \n Drop',
        captainName: 'Captain Name',
        offerTypeCode: 'Offer Type \n Offer Code',
        discountCashback: 'Discount \n Cashback',
        fare: 'Fare',
        adjustment: 'Total Adjustment',
        status: 'Status',
        ratings: 'Ratings'
      },
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.orderTable = null;
        this.ngOnInit();
      },
      appliedFilters: this.filtersApplied,
      filterComponents: [
        new QuickFilters({
          key: 'duration',
          default: '',
          title: 'Duration',
        }),
        new TextFilterInput({
          key: 'orderId',
          title: 'Order ID',
        }),
        new SearchableDropDownFilterInput({
          key : 'services',
          title: 'Services',
          values: this.services,
        }),
        new SingleSearchableDropdown({
          key : 'status',
          title: 'Status',
          values: this.statusList,
        }),
      ],
      onFilter: () => {
        const timeSlot = this.filterService.appliedFilters.duration || ['', ''];
        let duration = [];
        if (!Array.isArray(timeSlot)) {
          duration.push(timeSlot['start']);
          duration.push(timeSlot['end']);
        } else {
          duration = timeSlot;
        }
        let startDuration = duration[0];
        let endDuration = duration[1];
        const cities = this.filterService.appliedFilters.cities;
        const services = this.filterService.appliedFilters.services;
        const orderId = this.filterService.appliedFilters.orderId;
        // const refund = this.filterService.appliedFilters.refund;
        const cashback = this.filterService.appliedFilters.cashback;
        const rating = this.filterService.appliedFilters.rating;
        const status = this.filterService.appliedFilters.status;
        const filtersApplied = {
          duration: {
            start: startDuration,
            end: endDuration,
          },
          cashback: {
            from: cashback && cashback.from,
            to: cashback && cashback.to,
          },
          rating: {
            from: rating && rating.from,
            to: rating && rating.to,
          },
          startDuration,
          endDuration,
          cities,
          services,
          orderId,
          cashbackInput: cashback,
          ratingInput: rating,
          status,
        };
        Object.assign(this.filtersApplied, filtersApplied);

        const isValidNumber = (value) => {
          return ((parseInt(value, 10) === 0) || value);
        };
        if ((startDuration && endDuration) || cities || services || orderId || isValidNumber(cashback) || rating) {
          startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
          endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
          return this.defineOrderTable(this.merchantId, startDuration, endDuration, this.filtersApplied);
        }
        return this.defineOrderTable(this.merchantId, undefined, undefined, this.filtersApplied);
      },
      data: []
    });
  }

  onPaginationChange(event) {
    this.fillDataToTable(undefined, undefined, this.filtersApplied);
  }

  getOrderList(customerMobile: string, fromDate?: Date, toDate?: Date, filters?: any) {
    this.filterCount = 0;
    const filterKeys = Object.keys(this.filtersApplied);
    const ignoreKeys = ['startDuration', 'endDuration'];
    const dateFilter = [];
    if (this.filtersApplied && this.filtersApplied['duration']) {
      if (this.filtersApplied['duration'].start) {
        dateFilter.push(this.filtersApplied['duration'].start);
      }
      if (this.filtersApplied['duration'].end) {
        dateFilter.push(this.filtersApplied['duration'].end);
      }
    }
    if (dateFilter.length > 0) {
      this.filterCount++;
    }
    for (let i = 0; i < filterKeys.length ; i++) {
      if (Array.isArray(this.filtersApplied[filterKeys[i]]) && (this.filtersApplied[filterKeys[i]]).length > 0) {
        this.filterCount++;
      } else if (typeof(this.filtersApplied[filterKeys[i]]) === 'object') {
        if (filters[i] && filterKeys[i] === 'rating' &&
            ((this.filtersApplied[filterKeys[i]]['from']) || (this.filtersApplied[filterKeys[i]]['to']))) {
          this.filterCount++;
        }
      } else {
        if (this.filtersApplied[filterKeys[i]] && ignoreKeys.indexOf(filterKeys[i]) === -1
            && (this.filtersApplied[filterKeys[i]]).length > 0) {
          this.filterCount++;
        }
      }
    }
    const filterConditions = this.getFilterConditions();
    const payload = {
      'limit': this.orderTable.pagination.perPage,
      'offset': (this.orderTable.pagination.perPage * this.orderTable.pagination.pageNo),
      'searchConditions': {
        'orderType': 'sme',
        'customer': this.merchantId
      },
      'requiredFields':
        'orderDate createdOn _id serviceObj uniqueId pickupLocation dropLocation riderObj rider customer customerObj offerCode couponCode\
        discount cashback feedback status amount orderTime serviceType',
      'requireTotalCount': true
    };
    payload.searchConditions = {...payload.searchConditions, ...filterConditions};

    return this.merchantService.getMerchantOrders(payload);
  }

  getFilterConditions() {
    const conditions = {};
    if (this.filtersApplied && this.filterCount > 0) {
      for (const condition in this.filtersApplied) {
        if (this.filtersApplied[condition]) {
          switch (condition) {
            case 'duration':
              if (this.filtersApplied[condition].start && this.filtersApplied[condition].end) {
                conditions['orderDate'] = {$gte: this.filtersApplied[condition].start, $lte: this.filtersApplied[condition].end};
              }
              break;
            case 'services':
              const service = [];
              const services = this.filtersApplied[condition];
              for (const serviceName of services) {
                if (serviceName) {
                  service.push(this.serviceMap[serviceName + '']);
                }
              }
              conditions['serviceType'] = {$in: service};
              break;
            case 'orderId':
              conditions['uniqueId'] = this.filtersApplied[condition];
              break;
            case 'status':
              if (this.filtersApplied[condition] !== 'all') {
                conditions['status'] = this.filtersApplied[condition];
              }
              break;
            default: break;
          }
        }
      }
    }
    return conditions;
  }

  private calculateAdjustmentsByOrderId(adjustments) {
    const STRING = {
      CREDIT: 'credit',
      ADJUSTMENTS: 'adjustment',
      DEBIT: 'debit'
    };
    const adjustmentsHistory = {};
    const totalAdjustment = {};
    function toBeAdded(adjustment) {
      return adjustment.transactionType === STRING.CREDIT ? true : false;
    }

     function isProperTransactionType(adjustment) {
      return adjustment.title === STRING.ADJUSTMENTS &&
      (adjustment.transactionType === STRING.CREDIT || adjustment.transactionType === STRING.DEBIT);
    }
    adjustments.forEach(adjustment => {
      if (!adjustment) {
        return;
      }
      if (isProperTransactionType(adjustment)) {
        totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] || 0;
        if (toBeAdded(adjustment)) {
          totalAdjustment[adjustment.orderId] += adjustment.originalAmount;
        } else {
          totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] - adjustment.originalAmount;
        }
      }
    });
    for (const adjustment in totalAdjustment) {
      if (!this.isEmpty(totalAdjustment[adjustment])) {
        totalAdjustment[adjustment] = totalAdjustment[adjustment].toString();
      }
    }
    return totalAdjustment;
  }

  formatAMPM(date) {
    const time = new Date(date);
    return (time.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }));
  }

  fillDataToTable(fromDate?: Date, toDate?: Date, filters?: any) {
    if (this.merchantId) {
      this.orderTable.dataWillLoad();
      this.getOrderList(this.merchantId, fromDate, toDate, filters).subscribe(async(response) => {
        const orders = response['data'];
        const orderIds = [];
        const self = this;
        function createTableData(value, className?) {
          return new TableData({
            data: value,
            type: TableDataTypes.DATA,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
            onClick: (data) => {
              const newRelativeUrl = self.router.createUrlTree([`/order/${data._id.data}`]);
              const baseUrl = window.location.href.replace(self.router.url, '');
              window.open(baseUrl + newRelativeUrl, '_blank');
            }
          });
        }

        const orderIdss = orders.map(o => o._id);
        const adjustments = await this.customerOrderDetailsService.getAdjustmentAmounts(orderIdss, this.merchantId).toPromise();
        const orderIdToAdjustmentsMap = this.calculateAdjustmentsByOrderId(adjustments);

        this.orders = orders.map((order, i) => {
          const orderId = order._id;
          orderIds.push(orderId);
          const self = this;
          const orderDate = new Date(order.orderDate);
          return {
            _id: createTableData(orderId),
            dateTime: createTableData(HelperService.dateString(orderDate) + '\n' + this.formatAMPM(order.createdOn)),
            orderIdCity: new TableData({
              data: order.uniqueId + '\n' + order.serviceObj.city || '',
              type: TableDataTypes.DATA,
              className: 'cursor-pointer whiteSpacePre',
              onClick: (data) => {
                const newRelativeUrl = self.router.createUrlTree([`/order/${data._id.data}`]);
                const baseUrl = window.location.href.replace(self.router.url, '');
                window.open(baseUrl + newRelativeUrl, '_blank');
              }
            }),
            pickupDrop: createTableData(order.pickupLocation.address + '\n' + order.dropLocation.address),
            offerTypeCode: createTableData((order.offerType || '') +
              (order.offerCode ? ('\n' + order.offerCode) : (order.couponCode ? ('\n' + order.couponCode) : ''))),
            discountCashback: createTableData(order.discount + '\n' + (order.cashback || ''), 'preserve-whitespace'),
            captainName: createTableData(order.riderObj && order.riderObj.name),
            fare: createTableData(order.amount),
            ratings: createTableData(order.feedback && order.feedback.riderRating || ' - '),
            adjustment: createTableData(orderIdToAdjustmentsMap[orderId]),
            status: createTableData(order.status, 'capitalize whiteSpacePre')
          };
        });
        this.orderTable.data = this.orders;
        this.orderTable.filterCount = this.filterCount;
        this.orderTable.pagination.count = response['totalCount'];
      });
    }
  }

  calculateAdjustment (adjustments) {
    if (adjustments && adjustments.length > 0) {
      let finalAdjustment = 0;
      for (const adj in adjustments) {
        if (adjustments[adj]) {
          switch (adjustments[adj].type) {
            case 'credit' : finalAdjustment += adjustments[adj].amount;
                            break;
            case 'debit' : finalAdjustment -= adjustments[adj].amount;
                            break;
            default : break;
          }
        }
      }
      return (finalAdjustment > 0 ? '+' + finalAdjustment.toString() : finalAdjustment.toString());
    }
  }

  isEmpty(value) {
    return (value == null || value.length === 0);
  }

  defineOrderTable(customerMobile: string, fromDate = DEFAULT_DATE.FROM_DATE, toDate = DEFAULT_DATE.TO_DATE,
    filters = this.filtersApplied) {
    this.declareTable();
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fillDataToTable(fromDate, toDate, filters);
  }

}
