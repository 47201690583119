var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CUSTOMER_REFUND_ACCESS, CUSTOMER_DEBIT_ACCESS, CAPTAIN_REFUND_ACCESS, CAPTAIN_DEBIT_ACCESS, REFUND, DEBIT, CAPTAIN_UPDATE_KM, CUSTOMER } from './roles.constants';
import * as i0 from "@angular/core";
var RoleBasedAccessService = /** @class */ (function () {
    function RoleBasedAccessService() {
    }
    RoleBasedAccessService.prototype.performAdjustment = function (transactionType, user) {
        return __awaiter(this, void 0, void 0, function () {
            var maxAdjAmount;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.accessRoles = this.findRoles('Roles');
                        if (!(user === CUSTOMER)) return [3 /*break*/, 5];
                        if (!(transactionType === REFUND)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.checkRoles(CUSTOMER_REFUND_ACCESS)];
                    case 1:
                        maxAdjAmount = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.checkRoles(CUSTOMER_DEBIT_ACCESS)];
                    case 3:
                        maxAdjAmount = _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 11];
                    case 5:
                        if (!(transactionType === REFUND)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.checkRoles(CAPTAIN_REFUND_ACCESS)];
                    case 6:
                        maxAdjAmount = _a.sent();
                        return [3 /*break*/, 11];
                    case 7:
                        if (!(transactionType === DEBIT)) return [3 /*break*/, 9];
                        return [4 /*yield*/, this.checkRoles(CAPTAIN_DEBIT_ACCESS)];
                    case 8:
                        maxAdjAmount = _a.sent();
                        return [3 /*break*/, 11];
                    case 9: return [4 /*yield*/, this.checkRoles(CAPTAIN_UPDATE_KM)];
                    case 10:
                        maxAdjAmount = _a.sent();
                        _a.label = 11;
                    case 11: return [2 /*return*/, maxAdjAmount];
                }
            });
        });
    };
    RoleBasedAccessService.prototype.checkRoles = function (roleBasedObj) {
        return __awaiter(this, void 0, void 0, function () {
            var loggedInUserRoles, roleLimits, index, amount;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loggedInUserRoles = this.accessRoles.split(',');
                        roleLimits = [];
                        for (index in loggedInUserRoles) {
                            if (loggedInUserRoles[index] && roleBasedObj[loggedInUserRoles[index]]) {
                                roleLimits.push(roleBasedObj[loggedInUserRoles[index]]);
                            }
                        }
                        return [4 /*yield*/, this.findMaxAmount(roleLimits)];
                    case 1:
                        amount = _a.sent();
                        return [2 /*return*/, amount];
                }
            });
        });
    };
    RoleBasedAccessService.prototype.findMaxAmount = function (amountList) {
        return Math.max.apply(Math, amountList);
    };
    RoleBasedAccessService.prototype.findRoles = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    RoleBasedAccessService.ngInjectableDef = i0.defineInjectable({ factory: function RoleBasedAccessService_Factory() { return new RoleBasedAccessService(); }, token: RoleBasedAccessService, providedIn: "root" });
    return RoleBasedAccessService;
}());
export { RoleBasedAccessService };
