import { Component, OnInit, Input } from '@angular/core';
declare var $;
export const ICON_MAPPING = {
  RAPIDO: 'glyphicon glyphicon-align-justify',
  ORDER: 'glyphicon glyphicon-list',
  ENTITIES: 'glyphicon glyphicon-screenshot',
  PAYROLL: 'glyphicon glyphicon-edit',
  REPORTS: 'glyphicon glyphicon-sort',
  MAPS: 'glyphicon glyphicon-move',
  RIDER: 'glyphicon glyphicon-plane',
}

export interface INavBar {
  title: NavBarTitle;
  options: NavBarOptions;
}
export interface INavBarOption {
  name: string;
  id?: string;
  iconClass: string;
  visible?: boolean;
}

export class NavBarOption {
  name: string;
  id: string;
  iconClass: string;
  visible = true;
  constructor(config: INavBarOption) {
    Object.assign(this, config);
    this.id = this.id || this.name;
  }
  get className() {
    return `sub_icon ${this.iconClass} pad-nav-icon padding-right-nav`;
  }
}

export class INavBarOptions {
  options: NavBarOption[];
  visibility ?: any = {};
}

export class NavBarOptions {
  _options: NavBarOption[];
  set options(options: NavBarOption[]) {
    this._options = options.map(option => {
      option.visible = (this.visibility.hasOwnProperty(option.id)) ? this.visibility[option.id] : option.visible;
      return option;
    });
  }
  get options() {
    return this._options;
  }

  visibility: any = {};

  value() {
    return this.options;
  }
  constructor(config: INavBarOptions) {
    this.visibility = config.visibility;
    Object.assign(this, config);
  }
}

export class NavBarTitle {
  name: string;
  iconClass: string;
  constructor(config: INavBarOption) {
    Object.assign(this, config);
  }
  get className() {
    return `${this.iconClass} pad-nav-icon padding-right-nav`;
  }
}

export class NavBar {
  title: NavBarOption;
  options: NavBarOptions;
  constructor(config: INavBar) {
    Object.assign(this, config);
  }
}
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  @Input() nav: NavBar;
  @Input('toggleList') isExpanded: boolean;
  constructor() { }

  ngOnInit() {
    $("#menu-toggle").click(function(e) {
      e.preventDefault();
      $("#wrapper").toggleClass("active");
});
  }

}
