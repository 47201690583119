<!-- <div class="overlay"></div> -->
<div class="sidenav">
    <div class="row map-view">
        <div class="col-md-3 toggles padding-0">
            <div class="toggle-div">
                <div *ngFor="let option of slideOptions">
                    <div class="padding-10-10-0-0 row">
                        <div class="col-md-10">
                            <div class="row">
                                <span class="map-title font-size-14">{{option.title}}</span>
                            </div>
                            <div class="row map-info" *ngIf="option.distance || option.time" [ngClass]="{'color-blue': option.color, 'align-on-ends': true}">
                                <div class="map-time font-size-14" *ngIf="option.time">Time : {{option.time}}</div>
                                <div class="font-size-14" *ngIf="option.distance">Distance : {{option.distance}}</div>
                            </div>
                            <div class="row map-info" *ngIf="option.distance2 || option.time2" [ngClass]="{'color-purple': option.color2, 'align-on-ends': true}">
                                <div class="map-time font-size-14" *ngIf="option.time2">Time : {{option.time2}}</div>
                                <div class="font-size-14" *ngIf="option.distance2">Distance : {{option.distance2}}</div>
                            </div>
                        </div>
                        <div class="col-md-2 floating-toggle">
                        <p-inputSwitch [disabled]="option.disabled" class="map-toggle" (onChange)="changeInOption(option.title)" [(ngModel)]="selectedOptions[option.title]"></p-inputSwitch>
                        </div>
                    </div>
                    <div *ngIf="option.addLine" class="line"></div>
                </div>
                <div *ngIf="tollEnabled">
                    <app-toll-info [entryTollLines]="cityEntryTollLines" [exitTollLines]="cityExitTollLines" [tolls]="mapData.cityTolls"></app-toll-info>
                </div>
            </div>
        </div>

        <div class="col-md-9" style="padding: 0px;">
            <button *ngIf="showCaptainLocationRefresh" (click)="getCaptLiveLocation()" class="refresh-live-loc">
                <fa-icon class="cursor-pointer refresh-location" [icon]="faRefresh"></fa-icon>
                Captain Live Location
            </button>
            <div #fullMap style="width:100%;height:100%"></div>
        </div>
    </div>
    <div class="close-map">
        <button (click)="closeFullView()" class="close-map-button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>