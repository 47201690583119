
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
declare const MyFunction: any;

export const MatchStatus = {
  UPCOMING: 'upcoming',
  ONGOING: 'ongoing',
  LEADERBOARD: 'leaderBoard',
  ENDED: 'ended',
};

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public MatchStatus = MatchStatus;

  private userPermissions = new BehaviorSubject({});
  permissions = this.userPermissions.asObservable();
  private currentEntitiyDetails = {};

  private searchCount = new BehaviorSubject({});
  searchUserCount = this.searchCount.asObservable();

  constructor() { }

  updatePermissions(roles) {
    this.userPermissions.next(roles);
  }
  
  setCurrentEntity(entity) {
    this.currentEntitiyDetails = entity;
  }
  getCurrentEntity() {
    return this.currentEntitiyDetails;
  }

  updateSearchCount(number) {
    this.searchCount.next(number);
  }
}
