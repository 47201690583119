import { Component, OnInit, Input, OnChanges, ViewEncapsulation, ViewChild, TemplateRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HelperService } from '../../../helper.service';
import { getCurrentStatus } from '../service';
@Component({
  selector: 'app-daily-incentives',
  templateUrl: './daily-incentives.component.html',
  styleUrls: ['./daily-incentives.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DailyIncentivesComponent implements OnInit, OnChanges {
  @ViewChild('tnc') tnc: TemplateRef<any>;
  @ViewChild('orders') orders: TemplateRef<any>;
  @Input() incentives;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    config: NgbModalConfig,
  ) { }
  panelOpenState = false;

  time = new Date();
  currentTime = this.time.getHours() + ':' + this.time.getMinutes();
  statusMapping = {
    'NOT_STARTED': 'Upcoming',
    'STARTED': 'Ongoing',
    'COMPLETED': 'Achieved',
    'EXPIRED': 'Missed',
    'EXCEEDED': "Exceeded"
  };
  orderList;
  ngOnInit() {
  }

  ngOnChanges() {

  }

  formatTime(timeSlot) {
    const timeString = [];
    for (const time of timeSlot) {
      if (time) {
        timeString.push(HelperService.convert24to12hrFormat(time.fromTime) + ' - ' + HelperService.convert24to12hrFormat((time.toTime)));
      }
    }
    return timeString.join(' & ');
  }

  getClass(status) {
    return (this.statusMapping[status]).toLowerCase();
  }

  getCircleClass(status) {
    return ((this.statusMapping[status]).toLowerCase() + '-circle');
  }

  getCurrentStatus(incentive, status, incentiveCard) {
    return getCurrentStatus(incentive, status, incentiveCard);
  }

  getStatus(status) {
    return this.statusMapping[status];
  }

  openTncDialog() {
    this.open(this.tnc);
  }

  openOrdersDialog(orderList) {
    this.orderList = orderList;
    this.open(this.orders);
  }

  open(content) {
    this.modalService.open(content, {
      centered: true
    });
  }

  goToOrder(orderId) {
    if (orderId) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/order/${orderId.id}`]));
      window.open(url, '_blank');
      this.modalService.dismissAll();
    }
  }

  padStart(length, data) {
    return data.toString().padStart(length, 0);
  }

  getFormattedDate(date) {
    const currentDate = new Date(date);
    return `${this.padStart(2, currentDate.getDate())}-${this.padStart(2, currentDate.getMonth() + 1)}-${currentDate.getFullYear()}`;
  }

  getLastIncentive(incentiveData, limitType) {
    if (limitType == "global") {
      return incentiveData.at(-1);
    } else if (limitType == "set") {
      return incentiveData;
    }
    return null;
  }

  getCancellationStatus(incentive, incentiveCards, limitType) {
    let lastIncentiveCard = this.getLastIncentive(incentiveCards, limitType);
    let incentiveStatus = getCurrentStatus(incentive, lastIncentiveCard.rules.at(-1).status, lastIncentiveCard);

    let cancellationData = lastIncentiveCard.rules.at(-1).cancellationData;
    let limit = cancellationData.cancellationLimit;
    let count = cancellationData.overallCancellation;

    if (incentiveStatus == "EXPIRED" && count <= limit) {
      return incentiveStatus;
    }
    if (incentiveStatus == "COMPLETED") {
      return incentiveStatus;
    }
    if (incentiveStatus == "NOT_STARTED" || count <= limit) {
      return "STARTED";
    }
    if (count > limit) {
      return "EXCEEDED";
    }
  }

  getCancellationStatusBarValues(incentiveData, type, limitType) {
    let cancellationData = this.getLastIncentive(incentiveData, limitType).rules.at(-1).cancellationData;
    if (cancellationData == null) {
      return 0;
    }
    let limit = cancellationData.cancellationLimit;
    let count = cancellationData.overallCancellation;
    if (type == "max") {
      return limit > count ? limit : count;
    }
    return limit < count ? limit : count;
  }

  getCancellationLimit(incentiveData, limitType) {
    return this.getLastIncentive(incentiveData, limitType).rules.at(-1).cancellationData.cancellationLimit;
  }
}
