var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges } from '@angular/core';
import { PingData } from "src/app/shared/types/svo.types";
import { EntityService } from "src/app/entity.service";
import { RideTrackerMapService } from "src/app/ride-tracker-map/ride-tracker-map.service";
import { SvoService } from "src/app/svo.service";
import { HorizontalList } from "../../shared/horizontal-list/horizontal-list.component";
import { TableData, TableDataTypes } from "../../shared/types";
import { PerformanceCard } from "../../shared/types/card.types";
import { CaptainService } from "src/app/captain.service";
import { HandHoldingCaptain, } from "src/app/shared/types/captain.types";
var HH_SEGMENT = "HH";
var HH_DISPOSITIONS = ["OUTBOUNDHH"];
var ɵ0 = ["5ee36220c044cc3cbc22ed6b"];
var HandHoldingDetailsComponent = /** @class */ (function () {
    function HandHoldingDetailsComponent(entityService, svoService, rideTrackerMapService, captainService) {
        this.entityService = entityService;
        this.svoService = svoService;
        this.rideTrackerMapService = rideTrackerMapService;
        this.captainService = captainService;
        this.pingsData = [];
    }
    HandHoldingDetailsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dataLoaded = false;
                        this.isHandHoldingCaptain = false;
                        return [4 /*yield*/, this.getCaptainSegment()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.fetchData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HandHoldingDetailsComponent.prototype.ngOnChanges = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCaptainSegment()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.fetchData()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    HandHoldingDetailsComponent.prototype.getCaptainSegment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var segmentDetails, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.captainId) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.svoService.getCaptainSegment(this.captainId)];
                    case 2:
                        segmentDetails = _a.sent();
                        this.isHandHoldingCaptain =
                            segmentDetails && segmentDetails["segment"] && segmentDetails["segment"].toString().toUpperCase() === HH_SEGMENT;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.dataLoaded = true;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    HandHoldingDetailsComponent.prototype.fetchData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, totalPings, latestLocation, authLog, handholdingDetails;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.captainId && this.isHandHoldingCaptain)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                this.svoService
                                    .getTotalPings([this.captainId])
                                    .toPromise()
                                    .catch(function () { return new PingData(); }),
                                this.rideTrackerMapService
                                    .getRiderGeoLocation(this.captainId)
                                    .toPromise()
                                    .catch(function () { return ({}); }),
                                this.entityService
                                    .getLatestAuthLog(this.captainId)
                                    .toPromise()
                                    .catch(function () { return ({}); }),
                                this.captainService
                                    .getHandHoldingCaptainDetails(this.captainId)
                                    .toPromise()
                                    .catch(function () { return new HandHoldingCaptain(); }),
                            ])];
                    case 1:
                        _a = _b.sent(), totalPings = _a[0], latestLocation = _a[1], authLog = _a[2], handholdingDetails = _a[3];
                        this.dataLoaded = true;
                        this.pingsData = totalPings.data.response;
                        this.lastKnownLocation =
                            latestLocation["data"] &&
                                latestLocation["data"]["_source"] &&
                                latestLocation["data"]["_source"]["existingClusters"]
                                ? latestLocation["data"]["_source"]["existingClusters"][0]
                                : "N/A";
                        this.lastLogin = authLog["loginOn"] ? new Date(authLog["loginOn"]) : null;
                        this.connectedCallCount = handholdingDetails.connectedCallCount
                            ? handholdingDetails.connectedCallCount
                            : 0;
                        this.handholdingCallCount = handholdingDetails.campaigns
                            ? handholdingDetails.campaigns
                                .filter(function (campaign) {
                                return HH_DISPOSITIONS.includes(campaign.name);
                            })
                                .map(function (campaign) { return campaign.count; })
                                .reduce(function (prevCount, nextCount) { return prevCount + nextCount; }, 0)
                            : 0;
                        this.makeTable();
                        return [3 /*break*/, 3];
                    case 2:
                        this.dataLoaded = true;
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    HandHoldingDetailsComponent.prototype.makeTable = function () {
        var _this = this;
        var data = [
            {
                label: "Total Pings Received",
                value: {
                    toString: function () {
                        return _this.pingsData && _this.pingsData[0] && _this.pingsData[0].totalPings
                            ? _this.pingsData[0].totalPings
                            : "N/A";
                    },
                },
                type: TableDataTypes.PERFORMANCE_CARD,
            },
            {
                label: "Last Login Time",
                value: {
                    toString: function () {
                        return _this.lastLogin ? formatTime(_this.lastLogin) : "N/A";
                    },
                },
                type: TableDataTypes.PERFORMANCE_CARD,
            },
            {
                label: "Last Login Date",
                value: {
                    toString: function () {
                        return _this.lastLogin ? _this.lastLogin.toLocaleDateString() : "N/A";
                    },
                },
                type: TableDataTypes.PERFORMANCE_CARD,
            },
            {
                label: "Last Known Location",
                value: {
                    toString: function () {
                        return _this.lastKnownLocation ? _this.lastKnownLocation : "N/A";
                    },
                },
                type: TableDataTypes.PERFORMANCE_CARD,
            },
            {
                label: "Hand Holding Calls",
                value: {
                    toString: function () {
                        return _this.handholdingCallCount;
                    },
                },
                type: TableDataTypes.PERFORMANCE_CARD,
            },
            {
                label: "Connected Call Count",
                value: {
                    toString: function () {
                        return _this.connectedCallCount;
                    },
                },
                type: TableDataTypes.PERFORMANCE_CARD,
            },
        ];
        var dataTable = [data, []];
        var tds = dataTable.map(function (records) {
            return records.map(function (record) {
                return new TableData({
                    type: record.type,
                    performanceCard: new PerformanceCard({
                        label: record.label,
                        value: record.value,
                    }),
                });
            });
        });
        // this.horizontalList.table = null;
        this.horizontalList = new HorizontalList({
            header: "Hand Holding Details",
            tableData: tds,
            config: {
                refresh: true,
                filter: false,
            },
            events: {
                onRefresh: function () {
                    _this.ngOnInit();
                },
            },
        });
    };
    return HandHoldingDetailsComponent;
}());
export { HandHoldingDetailsComponent };
var formatTime = function (date) {
    return date.toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric"
    });
};
var ɵ1 = formatTime;
export { ɵ0, ɵ1 };
