import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map, timeout } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { UpdateManagerSearchRoleResponse } from './manager-rapido-account.types'

export enum UpdateSearchRoleActions {
  ADD = 'add',
  REMOVE = 'remove',
}
@Injectable({
  providedIn: 'root',
})
export class ManagerRapidoAccountService {
  constructor(private http: HttpClient) {}
  private server = `${environment.server}`

  updateSearchRole(
    userId: string,
    action: UpdateSearchRoleActions
  ): Observable<string> {
    return this.http
      .put<UpdateManagerSearchRoleResponse>(
        `${this.server}/api/updateManagerSearchRole`,
        {
          userId,
          action,
        }
      )
      .pipe(
        timeout(3000),
        map(
          (
            updateManagerSearchRoleResponse: UpdateManagerSearchRoleResponse
          ) => {
            if (
              updateManagerSearchRoleResponse &&
              updateManagerSearchRoleResponse.statusCode === 200
            ) {
              return updateManagerSearchRoleResponse.message
            } else {
              throw Error(
                'Unable to fetch this user, Please check the number again'
              )
            }
          }
        )
      )
  }
}
