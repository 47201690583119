import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UserTicketingService } from '../../user-tickets.service';
import { HelperService } from 'src/app/helper.service';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';

import { TICKET_STATUS_MAPPING } from 'src/app/shared/constants';
import { Router } from '@angular/router';
import { FRESH_DESK_TICKETS_URL } from 'src/app/shared/constants';

@Component({
  selector: 'app-customer-tickets',
  templateUrl: './customer-tickets.component.html',
  styleUrls: ['./customer-tickets.component.css']
})
export class CustomerTicketsComponent implements OnInit {

  constructor(
    public ticketingService: UserTicketingService,
    private router: Router,
  ) { }

  @Input() customerId: any;
  @Input() mobile:any;
  @Input() customerDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  customerTickets: Table;
  dataLoaded = false;


  ngOnInit() {
    this.declareTable();
  }

 declareTable() {
    this.customerTickets = new Table({
      toggleableHeaders: this.customerDetailsToggleableHeaders,
      headers: {
        date: 'Created Date',
        ticketId: 'Ticket ID',
        subject: 'Subject',
        description: 'Description',
        status: 'Status',
        reason: 'Reason',
        subReason: 'Sub Reason'
      },
      pagination: new Pagination(0, 10, 30, [10,15,20],0, true),
      selectedHeader: 4,
      defaultError: 'No Logs Found',
      onRefresh: () => {
        this.customerTickets = null;
        this.ngOnInit();
      },
      data: []
    });
    this.getTickets();
  }

  async getTickets(pageNumber = 1, perPage = 10) {
    this.dataLoaded = false;
    try {
      const query = {
        "mobile":this.mobile
      }
      const queryString = JSON.stringify(query);
      const response : any = await this.ticketingService.fetchUserTicketsV2(this.customerId, 'customer', queryString, pageNumber).toPromise();
      this.dataLoaded = true;
      if (response && response.tickets) {
        this.customerTickets.data = this.formatCaptainTickets(response.tickets);
      }
    } catch(error) {
      console.log('Error while fetching tickets for captain ---', error);
      this.dataLoaded = true;
    }
  }

  formatCaptainTickets(customerTickets = []) {
    if (customerTickets.length > 25) customerTickets.length = 25;
    let formatedTickets = [];
    customerTickets.map((item) => {
      let description =  item.description_text.replace(/ +(?= )/g,'');
      let ticket = {
        date: this.createTableData(HelperService.dateString(item.created_at)),
        ticketId: new TableData({
          data: item.ticket_id,
          type: TableDataTypes.DATA,
          className: 'cursor-pointer whiteSpacePre hyperlink',
          onClick: (data) => {
            const redirectToFreshDesk = `${FRESH_DESK_TICKETS_URL}/${item.ticket_id}`
            window.open(redirectToFreshDesk, '_blank');
          }
        }),
        subject: this.createTableData(item.subject || ''),
        description: this.createTableData(description || ''),
        reason: this.createTableData(item.reason || ''),
        subReason: this.createTableData(item.sub_reason918254 || ''),
        status: this.createTableData(TICKET_STATUS_MAPPING[item.status] || '')
      }
      formatedTickets.push(ticket);
    });
    this.customerTickets.data = formatedTickets;
    return formatedTickets;
  }

  createTableData(value, className?, type?, flag = false, hoverData?) {
    return new TableData({
      data: value,
      type: type ? type : TableDataTypes.DATA,
      flag: flag,
      hoverData: hoverData ? hoverData : null,
      className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  onPaginationChange(event) {
    this.getTickets((event.pageNo + 1), event.perPage);
  }
}
