import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var onFilter = function () { };
var ɵ0 = onFilter;
var SearchableDropDownFilterInput = /** @class */ (function () {
    function SearchableDropDownFilterInput(config) {
        this.type = 'searchable-dropdown';
        Object.assign(this, config);
    }
    return SearchableDropDownFilterInput;
}());
export { SearchableDropDownFilterInput };
var SingleSearchableDropdown = /** @class */ (function () {
    function SingleSearchableDropdown(config) {
        this.type = 'single-searchable-dropdown';
        Object.assign(this, config);
    }
    return SingleSearchableDropdown;
}());
export { SingleSearchableDropdown };
var RangeSliderInput = /** @class */ (function () {
    function RangeSliderInput(config) {
        this.type = 'range-slider';
        Object.assign(this, config);
    }
    return RangeSliderInput;
}());
export { RangeSliderInput };
var TextFilterInput = /** @class */ (function () {
    function TextFilterInput(config) {
        this.type = 'text-input';
        Object.assign(this, config);
    }
    return TextFilterInput;
}());
export { TextFilterInput };
var RadioButtonFilterInput = /** @class */ (function () {
    function RadioButtonFilterInput(config) {
        this.type = 'radio-button';
        Object.assign(this, config);
    }
    return RadioButtonFilterInput;
}());
export { RadioButtonFilterInput };
var DateFilterInput = /** @class */ (function () {
    function DateFilterInput(config) {
        this.type = 'calendar';
        Object.assign(this, config);
    }
    DateFilterInput.parseValue = function (inputDuration) {
        var duration = inputDuration || [];
        var startDuration = null;
        var endDuration = null;
        if (duration instanceof Array) {
            startDuration = duration[0] && new Date(duration[0]);
            endDuration = duration[1] && new Date(duration[1]);
        }
        else {
            startDuration = duration.start && new Date(duration.start);
            endDuration = duration.end && new Date(duration.end);
        }
        return {
            startDuration: startDuration,
            endDuration: endDuration,
        };
    };
    DateFilterInput.formatDurationForQuery = function (_a) {
        var fromDate = _a.fromDate, toDate = _a.toDate;
        fromDate = new Date(fromDate);
        toDate = new Date(toDate);
        var startDate = fromDate && fromDate.setDate(fromDate.getDate());
        var endDate = toDate && toDate.setDate(toDate.getDate() + 1) || new Date().getTime();
        return {
            fromDate: new Date(startDate),
            toDate: new Date(endDate)
        };
    };
    return DateFilterInput;
}());
export { DateFilterInput };
var QuickFilters = /** @class */ (function () {
    function QuickFilters(config) {
        this.type = 'quick-filters';
        Object.assign(this, config);
    }
    QuickFilters.parseValue = function (inputDuration) {
        var duration = inputDuration || [];
        var startDuration = null;
        var endDuration = null;
        if (duration instanceof Array) {
            startDuration = duration[0] && new Date(duration[0]);
            endDuration = duration[1] && new Date(duration[1]);
        }
        else {
            startDuration = duration.start && new Date(duration.start);
            endDuration = duration.end && new Date(duration.end);
        }
        return {
            startDuration: startDuration,
            endDuration: endDuration,
        };
    };
    QuickFilters.formatDurationForQuery = function (_a) {
        var fromDate = _a.fromDate, toDate = _a.toDate;
        fromDate = new Date(fromDate);
        toDate = new Date(toDate);
        var startDate = fromDate && fromDate.setDate(fromDate.getDate());
        var endDate = toDate && toDate.setDate(toDate.getDate() + 1) || new Date().getTime();
        return {
            fromDate: new Date(startDate),
            toDate: new Date(endDate)
        };
    };
    return QuickFilters;
}());
export { QuickFilters };
var FilterService = /** @class */ (function () {
    function FilterService() {
        this.dataReceived = false;
        this.filterComponents = [];
        this.showFilters = false;
        this.filterDataChange = new Subject();
        this.filterValueChange = new Subject();
        this.filterDefaultValueChange = new Subject();
        this.showFilters = false;
    }
    Object.defineProperty(FilterService.prototype, "appliedFilters", {
        get: function () {
            return this._appliedFilters;
        },
        set: function (filters) {
            this._appliedFilters = filters;
            // this.applyFilters(this._appliedFilters);
        },
        enumerable: true,
        configurable: true
    });
    FilterService.prototype.defineFilters = function (filterComponents, onFilterApply) {
        onFilter = onFilterApply || (function () { });
        this.filterComponents = filterComponents || [];
        this.dataReceived = true;
    };
    FilterService.prototype.filterToggle = function () {
        this.showFilters = !this.showFilters;
    };
    FilterService.prototype.listen = function (events) {
        // console.log('listening', events);
    };
    FilterService.prototype.clearFilters = function () {
        this.dataReceived = false;
        //   setTimeout (() => {
        //     this.dataReceived = true;
        //  }, 200);
    };
    FilterService.prototype.applyFilters = function (event) {
        this.appliedFilters = event;
        if (onFilter && typeof onFilter === 'function') {
            onFilter(event);
        }
    };
    FilterService.ngInjectableDef = i0.defineInjectable({ factory: function FilterService_Factory() { return new FilterService(); }, token: FilterService, providedIn: "root" });
    return FilterService;
}());
export { FilterService };
export { ɵ0 };
