import { Component, OnInit, Input } from '@angular/core';
import { HorizontalList } from 'src/app/shared/horizontal-list/horizontal-list.component';
import { TableDataTypes, TableData } from 'src/app/shared/types';
import { PerformanceCard } from 'src/app/shared/types/card.types';
import { of, forkJoin } from 'rxjs';
import { CaptainService } from 'src/app/captain.service';
import { DateFilterInput, FilterService, SingleSearchableDropdown, QuickFilters } from 'src/app/filter.service';
import { OrderService } from 'src/app/order-service.service';
import { HelperService } from 'src/app/helper.service';
import { Router } from '@angular/router';
import { CaptainFailedOrdersService } from '../captain-failed-orders/captain-failed-orders.service';
import { EntityService } from 'src/app/entity.service';
import { SvoService } from 'src/app/svo.service';
const currentDate = new Date();
const monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const FRAUD_TITLE_MAPPING = {
  differentDropLocationFraud: 'Drop Location Frauds',
  checkPickupLocationAndDeviceFraud: 'Pickup Location And Device Frauds',
  differentActualPickUpFraud: 'Different Pickup Location Frauds',
  distanceToAcceptFraud: 'Distance to Accept Frauds'
};


function padStart(length, data) {
  return data.toString().padStart(length, 0);
}
const currentDateString = `${currentDate.getFullYear()}-${padStart(2, currentDate.getMonth()+1)}-${padStart(2, currentDate.getDate())}`
const monthStartDateString = `${monthStartDate.getFullYear()}-${padStart(2, monthStartDate.getMonth()+1)}-${padStart(2, monthStartDate.getDate())}`
const defaultFilters = () => {
  return {
    fromDate: new Date(monthStartDate),
    duration: [monthStartDateString, currentDateString],
    toDate: new Date(currentDate),
    startDuration: new Date(monthStartDate),
    endDuration: new Date(currentDate),
    status: 'dropped',
  };
};

@Component({
  selector: 'app-captain-date-wise-stat-details',
  templateUrl: './captain-date-wise-stat-details.component.html',
  styleUrls: ['./captain-date-wise-stat-details.component.css']
})
export class CaptainDateWiseStatDetailsComponent implements OnInit {
  @Input() captainMobile: string;
  @Input() captainId: string;
  captainUserId: string;
  horizontalList: HorizontalList;
  dataLoaded = false;
  appliedFilters = defaultFilters();
  filterCount = 0;
  statusList: any = [
    'new',
    'customerCancelled',
    'riderCancelled',
    'onTheWay',
    'arrived',
    'started',
    'dropped',
    'expired',
    'aborted'
  ];
  constructor(
    private captainService: CaptainService,
    private filterService: FilterService,
    private orderService: OrderService,
    private router: Router,
    private captainFailedOrdersService: CaptainFailedOrdersService,
    private entityService: EntityService,
    private svoService: SvoService
  ) { }

  ngOnInit() {
    this.dataLoaded = false;
    if (this.horizontalList && this.horizontalList.table) {
      this.makeTableNull();
    }
    this.defineList(this.captainMobile, this.captainId);
  }

  private makeTableNull() {
    this.horizontalList.table = null;
    this.horizontalList = null;
  }

  padZeroAtStart(data) {
    try {
      return data.toString().padStart(2,0);
    } catch(err) {
      return data;
    }
  }

  getFormattedDate(d) {
    const date = new Date(d);
    return `${date.getFullYear()}-${this.padZeroAtStart(date.getMonth() + 1)}-${this.padZeroAtStart(date.getDate())}`;
  }

  getDateWiseStat(captainMobile: string, captainId:string) {
    return this.entityService.getEntityByNumber(captainMobile)
    .flatMap(data => {
      if (data && data.userId && data.userId._id) {
        this.captainUserId = data.userId._id;
      }
      return forkJoin(
        this.svoService.getAverageCaptainRatings(this.captainUserId),
        this.captainService.fraudStat({
          riderId: captainMobile,
          startDuration: this.appliedFilters.startDuration,
          endDuration: this.appliedFilters.endDuration,
        }),
        this.captainService.getCaptainAdjustment(
          data.userId._id,
          this.getFormattedDate(this.appliedFilters.startDuration),
          this.getFormattedDate(this.appliedFilters.endDuration)
        ),
        this.entityService.getCaptainCancellations(this.captainUserId),
        this.entityService.getServiceDetails(this.captainUserId),
        this.entityService.getServiceNameDetails()
      );
    })
    .map(([
      averageRating,
      fraudStats = [],
      adjustmentData,
      captainCancellationData,
      serviceLevelCancellationLimits,
      serviceNameDetails
    ]) => {
      // this.filterService.filterDataChange.next(this.appliedFilters);
      this.filterService.appliedFilters = this.appliedFilters;
      const roundTwoDecimal = (num) => {
       return Math.round(num * 100) / 100;
      };
      const fixedAverageRating = averageRating ? roundTwoDecimal(averageRating) : 'N/A';
      // const failedOrderCount = failedOrders && failedOrders['data'] && failedOrders['data']['length'] || 'N/A';
      const adjustedAmount = adjustmentData['adjustedAmount'] || 'N/A';
      const adjustedDistance = adjustmentData['adjustedDistance'] || 'N/A';

      let dailyCancellationLimit;
      let weeklyCancellationLimit;
      if(captainCancellationData && captainCancellationData['data'])
      {
        dailyCancellationLimit = captainCancellationData['data']['dailyLimit'];
        weeklyCancellationLimit = captainCancellationData['data']['weeklyLimit'];
      }

      const data = [
        {
          label: 'Average rating',
          value: {
            toString: () => {
              return fixedAverageRating;
            },
            icon: 'star_rate',
            className: 'yellow-star',
            style: {
              'font-size': '26px !important',
            },
          },
          type: TableDataTypes.PERFORMANCE_CARD,
        },
        {
          label: 'Failed Orders',
          value: {
            toString: () => {
              return ' ';
            },
            icon: 'info',
            className: 'yellow-star',
            style: {
              'color': 'black',
              'font-size': '26px !important',
            },
          },
          onClick: (() => {
            this.router.navigate([
              'failedOrders/' + HelperService.encode(this.captainMobile)
            ]);
          }),
          type: TableDataTypes.PERFORMANCE_CARD,
        },
        {
          label:'Adjustment Amount',
          value: `₹ ${adjustedAmount}`,
          type: TableDataTypes.PERFORMANCE_CARD,
        },
        {
          label:"Adjusted KM's",
          value: `${adjustedDistance} km`,
          type: TableDataTypes.PERFORMANCE_CARD,
        }
      ];

      if(serviceLevelCancellationLimits && serviceNameDetails)
      {
        (serviceLevelCancellationLimits as any[]).forEach(element => {
          data.push(
            {
            label: `${serviceNameDetails[element.service]} : Daily & weekly cancellations`,
            value: `[${dailyCancellationLimit? dailyCancellationLimit : 0 }/${element.dailyLimit}] [${weeklyCancellationLimit? weeklyCancellationLimit : 0 }/${element.weeklyLimit}]`,
            type: TableDataTypes.PERFORMANCE_CARD,
          });
        });
      }

      const fraudCards = fraudStats.map(fraud => {
        return {
          label: FRAUD_TITLE_MAPPING[fraud._id.type] || fraud._id.type,
          value: fraud.count || 0,
          type: TableDataTypes.FRAUD_CARD,
          onClick: (() => {
            let fromDate, toDate;

            fromDate = this.appliedFilters.duration['start'];
            toDate = this.appliedFilters.duration['end'];
            this.appliedFilters = defaultFilters();

            this.router.navigate(
              ['fraudOrders/' + HelperService.encode(this.captainMobile)],
              {
                queryParams: {
                  frauds: fraud._id.type,
                  fromDate,
                  toDate
                }
              }
            );
          })
        };
      });

      return [data, fraudCards];
    });
  }

  defineList(captainMobile: string, captainId: string) {
    this.dataLoaded = false;
    this.filterService.appliedFilters = this.appliedFilters;
    this.getDateWiseStat(captainMobile, captainId)
    .subscribe(multiRecords => {
      this.dataLoaded = true;
       const tds = multiRecords.map(records => {
          return records.map(record => {
            return new TableData({
              type: record.type,
              performanceCard: new PerformanceCard({
                // hikePercent: record.hikePercent,
                label: record.label,
                value: record.value,
                onClick: record.onClick,
                // isPositivehike: isPositive,
                style: {
                  height: '127px',
                  width: '176px',
                },
              })
            });
          });
        });
        this.horizontalList = new HorizontalList({
          header: {
            toString: () => {
              let startDate, endDate;
              if (this.appliedFilters.duration && this.appliedFilters.duration instanceof Array) {
                startDate = this.appliedFilters.duration[0];
                endDate = this.appliedFilters.duration[1];
              } else {
                startDate = this.appliedFilters.duration['start']
                endDate = this.appliedFilters.duration['end']
              }

              if (startDate === endDate)  {
                return `Today, ${HelperService.dateString(new Date())}`;
              } else {
                return `${HelperService.dateString(this.appliedFilters.fromDate)} - ${HelperService.dateString(this.appliedFilters.toDate)}`
              }
            }
          },
          // header: 'Today, 13 March 2019',
          tableData: tds,
          filterCount: this.filterCount,
          config: {
            refresh: true,
            filter: true,
          },
          events: {
            onRefresh: () => {
              this.ngOnInit();
            },
            onFilter: () => {
              this.filterCount = 0;
              this.horizontalList.table.appliedFilters = this.filterService.appliedFilters;
              const status = this.filterService.appliedFilters.status;
              const duration = DateFilterInput.parseValue(this.filterService.appliedFilters.duration);
              if (status) {
                this.filterCount++;
              }
              if (duration.startDuration || duration.endDuration) {
                this.filterCount++;
              }
              let appliedFilters: any = {};
              if (duration.startDuration && duration.endDuration) {
                const stringDuration = this.filterService.appliedFilters.duration;
                appliedFilters = {
                  duration: {
                    start: stringDuration[0],
                    end: stringDuration[1],
                  },
                  fromDate: duration.startDuration,
                  toDate: duration.endDuration,
                  startDuration: duration.startDuration,
                  endDuration: duration.endDuration,
                };
                if (stringDuration instanceof Array) {
                  appliedFilters.duration = {
                    start: stringDuration[0],
                    end: stringDuration[1],
                  };
                } else {
                  appliedFilters.duration = {
                    start: stringDuration.start,
                    end: stringDuration.end,
                  };
                }
              } else {
                appliedFilters = defaultFilters();
              }
              if (status) {
                appliedFilters.status = status;
              } else {
                appliedFilters.status = null;
              }
              Object.assign(this.appliedFilters, appliedFilters);
              this.filterService.filterDefaultValueChange.next(appliedFilters);
              this.defineList(this.captainMobile, this.captainId);
              // console.log(duration);
            }
          },
          filterComponents: [
            // new DateFilterInput({
            //   key: 'duration',
            //   default: '',
            //   // default: this.appliedFilters.duration,
            //   title: 'Duration'
            // }),
            new QuickFilters({
              key: 'duration',
              default: '',
              title: 'Duration',
            }),
            new SingleSearchableDropdown({
              key: 'status',
              default: '',
              values: this.statusList,
              title: 'Status'
            }),
          ],
          appliedFilters: HelperService.copyObj(this.appliedFilters),
        });
    });
  }


  isEmpty(value) {
    return (value == null || value.length === 0);
  }

}
