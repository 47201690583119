import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ataEndTypesAllowed, arEndTypesAllowed } from '../shared/constants';
import { RideTrackerMapService } from './ride-tracker-map.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
import { TollsService } from '../tolls.service';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
var RideTrackerMapComponent = /** @class */ (function () {
    function RideTrackerMapComponent(rideTrackerMapService, toasterService, tollService) {
        this.rideTrackerMapService = rideTrackerMapService;
        this.toasterService = toasterService;
        this.tollService = tollService;
        this.fullViewMap = new EventEmitter();
        this.faRefresh = faRedo;
        this.slideOptions = [];
        this.markerWithPositions = {};
        this.captLiveLocInOrderLogs = false;
        this.bound = new google.maps.LatLngBounds(null);
        this.isServiceDeliveryType = false;
        this.requestedEvent = {};
        this.startedEvent = {};
        // setting markers
        this.infowindow = new google.maps.InfoWindow();
        this.abortMarker = new google.maps.Marker();
        this.arrivedMarker = new google.maps.Marker();
        this.captainCancelledMarker = new google.maps.Marker();
        this.customerCancelledMarker = new google.maps.Marker();
        this.droppedMarker = new google.maps.Marker();
        this.multipleMarker = new google.maps.Marker();
        this.newMarker = new google.maps.Marker();
        this.onTheWayMarker = new google.maps.Marker();
        this.expiredMarker = new google.maps.Marker();
        this.startedMarker = new google.maps.Marker();
        this.reachedMarker = new google.maps.Marker();
        this.captainLiveMarker = new google.maps.Marker();
        this.acceptRouteTitle = 'First Mile Travelled';
        // Setting icons
        this.abortedIcon = {
            url: '../../assets/map/aborted.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.arrivedIcon = {
            url: '../../assets/map/arrived.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.captainCancelledIcon = {
            url: '../../assets/map/captain-cancelled.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.customerCancelledIcon = {
            url: '../../assets/map/customer-cancelled.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.droppedIcon = {
            url: '../../assets/map/dropped.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.multipleStatusIcon = {
            url: '../../assets/map/multiple-status.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.newIcon = {
            url: '../../assets/map/new.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.otwIcon = {
            url: '../../assets/map/on-the-way.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.expiredIcon = {
            url: '../../assets/map/order-expired.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.startedIcon = {
            url: '../../assets/map/started.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.reachedIcon = {
            url: '../../assets/map/reached.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.polyStart = {
            url: '../../assets/map/polystart.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.polyEnd = {
            url: '../../assets/map/polyend.png',
            scaledSize: new google.maps.Size(35, 50)
        };
        this.actualRouteMarker = {
            url: '../../assets/map/actualRoute.png',
            scaledSize: new google.maps.Size(10, 10)
        };
        this.acceptToArriveRouteMarker = {
            url: '../../assets/map/acceptToArriveRoute.png',
            scaledSize: new google.maps.Size(10, 10)
        };
        this.captainLiveIcon = {
            url: '../../assets/map/bike.png',
            scaledSize: new google.maps.Size(30, 30)
        };
        this.startedInitialMarker = new google.maps.Marker();
        this.endInitialMarker = new google.maps.Marker();
        this.startedFinalMarker = new google.maps.Marker();
        this.endFinalMarker = new google.maps.Marker();
        // actual route
        this.actualRoute = [];
        this.arEndTypesAllowed = arEndTypesAllowed;
        // accept to arrive route
        this.acceptToArriveRoute = [];
        this.ataEndTypesAllowed = ataEndTypesAllowed;
        // Alternate routes
        this.otherRoutes = [];
        this.startedOtherMarker = new Array();
        this.endOtherMarker = new Array();
        this.colorsForOtherRoutes = ['#0000FF', '#753a88'];
        this.captLiveCoords = { lat: null, lng: null };
        // status mapping
        this.statusMap = {
            '2': 'On Duty',
            '2-6': 'On Duty On Way',
            '4': 'Logged out',
            '5': 'Unreachable',
            '6': 'On The Way',
            '7': 'Arrived',
            '8': 'Started',
            '8-6': 'Started On Way',
            '8-2': 'Started On Duty',
            '9': 'Offline',
            '10': 'Reached'
        };
        this.terminalStates = ['4', '5', '9'];
        this.isFirstMileTriggered = false;
        this.isLastMileTriggered = false;
        this.statusRed = ['customerCancelled', 'riderRejected', 'expired', 'aborted', 'riderCancelled', 'dropped'];
        this.showCaptainLocationRefresh = false;
        this.selectedOptions = {
            'Initial set route': false, 'Final estimated route': false, 'Last Mile Travelled(hF)': false, 'ODRD(Actual) route': false, 'Order Log Points': false,
            'First Mile Travelled': false, 'Alternate routes': false, 'Captain live location': true, 'Tolls': true
        };
        this.cityBoundaryPolygon = {};
        this.cityTolls = [];
        this.tollPolylines = [];
        this.cityEntryTollLines = [];
        this.cityExitTollLines = [];
        this.tollEnabled = false;
    }
    RideTrackerMapComponent.prototype.ngOnInit = function () {
        this.mapProperties = {
            center: new google.maps.LatLng(21.7679, 78.8718),
            zoom: 5,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false,
            gestureHandling: 'cooperative'
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
        this.startedOtherMarker[0] = this.startedOtherMarker[1] = new google.maps.Marker();
        this.endOtherMarker[0] = this.endOtherMarker[1] = new google.maps.Marker();
    };
    RideTrackerMapComponent.prototype.ngOnChanges = function (changes) {
        this.isServiceDeliveryType = this.mapData.fareEstimateLogs ? this.mapData.fareEstimateLogs[0].service.serviceType == 'delivery' : false;
        this.requestedEvent = this.mapData.events ? this.mapData.events.find(function (event) { return event.eventName == 'requested'; }) : null;
        this.startedEvent = this.mapData.events ? this.mapData.events.find(function (event) { return event.eventName == 'started'; }) : null;
        if (changes) {
            if (this.orderCityId) {
                this.addCityBoundary();
            }
            if (changes.mapData && changes.mapData.currentValue) {
                this.getAcceptToArriveRoute();
                this.getActualRoute();
                if (this.orderStatus) {
                    if (this.statusRed.indexOf(this.orderStatus) === -1) {
                        this.showCaptainLocationRefresh = true;
                        this.getCaptLiveLocation();
                    }
                    if (this.orderStatus === 'customerCancelled') {
                        this.captLiveLocInOrderLogs = true;
                    }
                }
            }
        }
    };
    RideTrackerMapComponent.prototype.addCityBoundary = function () {
        var _this = this;
        this.rideTrackerMapService.getCityInfo(this.orderCityId).subscribe(function (result) {
            var geoJson = [];
            var cityBoundary = [];
            if (result && result['data'] && result['data']['boundary']) {
                cityBoundary = result['data']['boundary'];
                cityBoundary = cityBoundary.reduce(function (acc, x) {
                    if (isNaN(x["latitude"]) || isNaN(x["longitude"]) || x["latitude"] === null || x["longitude"] === null) {
                        return acc;
                    }
                    else {
                        acc.push({ lat: x["latitude"], lng: x["longitude"] });
                        return acc;
                    }
                }, []);
                var cityPolygon = new google.maps.Polygon({
                    paths: cityBoundary,
                    fillOpacity: 0
                });
                _this.cityBoundaryPolygon = cityPolygon;
                cityPolygon.setMap(_this.map);
            }
        }, function (err) {
            console.log("unable to load city boundary", err);
        });
    };
    RideTrackerMapComponent.prototype.changeInOption = function (option) {
        switch (option) {
            case 'Initial set route':
                this.toggleInitialRoute();
                break;
            case 'Final estimated route':
                this.toggleFinalRoute();
                break;
            case 'ODRD(Actual) route':
                this.toggleOdrdRoute();
                break;
            case 'Last Mile Travelled(hF)':
                this.toggleActualRoute();
                break;
            case 'Order Log Points':
                this.toggleOrderLogsView();
                break;
            case 'First Mile Travelled':
                this.toggleAcceptToArriveRoute();
                break;
            case 'Alternate routes':
                this.toggleOtherRoutes();
                break;
            case 'Tolls':
                this.togglePopulateTolls();
                break;
        }
    };
    RideTrackerMapComponent.prototype.showInfowindow = function (dataToShow, marker) {
        var infowindow = new google.maps.InfoWindow({
            content: dataToShow,
        });
        infowindow.open(this.map, marker);
    };
    RideTrackerMapComponent.prototype.getFareEstimates = function () {
        var _this = this;
        if (this.mapData && this.mapData.requestId) {
            var fareEstimateData_1 = [];
            this.rideTrackerMapService.getOrderRequests(this.mapData.requestId, localStorage.getItem('userId') || '').subscribe(function (result) {
                if (result && result['data']) {
                    fareEstimateData_1.push({ allPaths: result['data'].allPaths || [] });
                }
                _this.mapData.fareEstimateLogs = fareEstimateData_1;
            });
        }
    };
    RideTrackerMapComponent.prototype.toggleOrderLogsView = function () {
        var _this = this;
        if (this.selectedOptions['Order Log Points']) {
            if (this.captLiveLocInOrderLogs) {
                if (this.cancellationInformation && this.cancellationInformation.captainLocation &&
                    this.cancellationInformation.captainLocation.length === 2) {
                    var latLng = { lat: this.cancellationInformation.captainLocation[1], lng: this.cancellationInformation.captainLocation[0] };
                    this.extendBounds(latLng);
                    this.captainLiveMarker.setPosition(latLng);
                    this.captainLiveMarker.setMap(this.map);
                    this.captainLiveMarker.setIcon(this.captainLiveIcon);
                    var dataToShow_1 = 'Captain location on cancellation';
                    this.showInfowindow(dataToShow_1, this.captainLiveMarker);
                    this.captainLiveMarker.addListener('click', function () {
                        _this.showInfowindow(dataToShow_1, _this.captainLiveMarker);
                    });
                }
            }
            var _loop_1 = function (log) {
                if (this_1.markerWithPositions[log]) {
                    var logs = this_1.markerWithPositions[log];
                    switch (logs.icon) {
                        case 'new':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.newMarker.setPosition(latLng);
                                this_1.newMarker.setMap(this_1.map);
                                this_1.newMarker.setIcon(this_1.newIcon);
                                var dataToShow_2 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_2, this_1.newMarker);
                                this_1.newMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_2, _this.newMarker);
                                });
                            }
                            break;
                        case 'accepted':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.onTheWayMarker.setPosition(latLng);
                                this_1.onTheWayMarker.setMap(this_1.map);
                                this_1.onTheWayMarker.setIcon(this_1.otwIcon);
                                var dataToShow_3 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_3, this_1.onTheWayMarker);
                                this_1.onTheWayMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_3, _this.onTheWayMarker);
                                });
                            }
                            break;
                        case 'arrived':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.arrivedMarker.setPosition(latLng);
                                this_1.arrivedMarker.setMap(this_1.map);
                                this_1.arrivedMarker.setIcon(this_1.arrivedIcon);
                                var dataToShow_4 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_4, this_1.arrivedMarker);
                                this_1.arrivedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_4, _this.arrivedMarker);
                                });
                            }
                            break;
                        case 'started':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.startedMarker.setPosition(latLng);
                                this_1.startedMarker.setMap(this_1.map);
                                this_1.startedMarker.setIcon(this_1.startedIcon);
                                var dataToShow_5 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_5, this_1.startedMarker);
                                this_1.startedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_5, _this.startedMarker);
                                });
                            }
                            break;
                        case 'reached':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.reachedMarker.setPosition(latLng);
                                this_1.reachedMarker.setMap(this_1.map);
                                this_1.reachedMarker.setIcon(this_1.reachedIcon);
                                var dataToShow_6 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_6, this_1.reachedMarker);
                                this_1.reachedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_6, _this.reachedMarker);
                                });
                            }
                            break;
                        case 'dropped':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.droppedMarker.setPosition(latLng);
                                this_1.droppedMarker.setMap(this_1.map);
                                this_1.droppedMarker.setIcon(this_1.droppedIcon);
                                var dataToShow_7 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_7, this_1.droppedMarker);
                                this_1.droppedMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_7, _this.droppedMarker);
                                });
                            }
                            break;
                        case 'customer_cancelled':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.customerCancelledMarker.setPosition(latLng);
                                this_1.customerCancelledMarker.setMap(this_1.map);
                                this_1.customerCancelledMarker.setIcon(this_1.customerCancelledIcon);
                                var dataToShow_8 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_8, this_1.customerCancelledMarker);
                                this_1.customerCancelledMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_8, _this.customerCancelledMarker);
                                });
                            }
                            break;
                        case 'captain_cancelled':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                // this.extendBounds(latLng);
                                this_1.captainCancelledMarker.setPosition(latLng);
                                this_1.captainCancelledMarker.setMap(this_1.map);
                                this_1.captainCancelledMarker.setIcon(this_1.captainCancelledIcon);
                                var dataToShow_9 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_9, this_1.captainCancelledMarker);
                                this_1.captainCancelledMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_9, _this.captainCancelledMarker);
                                });
                            }
                            break;
                        case 'expired':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.expiredMarker.setPosition(latLng);
                                this_1.expiredMarker.setMap(this_1.map);
                                this_1.expiredMarker.setIcon(this_1.expiredIcon);
                                var dataToShow_10 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_10, this_1.expiredMarker);
                                this_1.expiredMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_10, _this.expiredMarker);
                                });
                            }
                            break;
                        case 'aborted':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.abortMarker.setPosition(latLng);
                                this_1.abortMarker.setMap(this_1.map);
                                this_1.abortMarker.setIcon(this_1.abortedIcon);
                                var dataToShow_11 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_11, this_1.abortMarker);
                                this_1.abortMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_11, _this.abortMarker);
                                });
                            }
                            break;
                        case 'multiple':
                            if (logs && logs.lat && logs.lng) {
                                var latLng = { lat: logs.lat, lng: logs.lng };
                                this_1.extendBounds(latLng);
                                this_1.multipleMarker.setPosition(latLng);
                                this_1.multipleMarker.setMap(this_1.map);
                                this_1.multipleMarker.setIcon(this_1.multipleStatusIcon);
                                var dataToShow_12 = this_1.getMarkerInfo(logs.label);
                                this_1.showInfowindow(dataToShow_12, this_1.multipleMarker);
                                this_1.multipleMarker.addListener('click', function () {
                                    _this.showInfowindow(dataToShow_12, _this.multipleMarker);
                                });
                            }
                            break;
                    }
                    this_1.map.setCenter(this_1.bound.getCenter());
                }
            };
            var this_1 = this;
            for (var log in this.markerWithPositions) {
                _loop_1(log);
            }
        }
        else {
            this.abortMarker.setMap(null);
            this.arrivedMarker.setMap(null);
            this.captainCancelledMarker.setMap(null);
            this.customerCancelledMarker.setMap(null);
            this.droppedMarker.setMap(null);
            this.multipleMarker.setMap(null);
            this.newMarker.setMap(null);
            this.onTheWayMarker.setMap(null);
            this.expiredMarker.setMap(null);
            this.startedMarker.setMap(null);
        }
    };
    RideTrackerMapComponent.prototype.toggleCaptLiveLoc = function () {
        var _this = this;
        if (this.captLiveCoords && this.captLiveCoords.lat && this.captLiveCoords.lng) {
            var latLng = this.captLiveCoords;
            this.extendBounds(latLng);
            this.captainLiveMarker.setPosition(latLng);
            this.captainLiveMarker.setMap(this.map);
            this.captainLiveMarker.setIcon(this.captainLiveIcon);
            this.showInfowindow('Captain is ' + this.statusMap[this.captainCurrentStatus], this.captainLiveMarker);
            this.captainLiveMarker.addListener('click', function () {
                _this.showInfowindow('Captain is ' + _this.statusMap[_this.captainCurrentStatus], _this.captainLiveMarker);
            });
        }
    };
    RideTrackerMapComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    RideTrackerMapComponent.prototype.constructSideBar = function () {
        this.slideOptions = [];
        if (this.mapData) {
            var data = this.mapData.fareEstimateLogs || [];
            var finalAllPaths = data[0] && data[0].direction && data[0].direction.allPaths;
            var dataLength = data.length;
            var isInitialRouteToggleDisabled = false;
            var isFinalRouteToggleDisabled = false;
            if (this.isServiceDeliveryType) {
                isInitialRouteToggleDisabled = !this.requestedEvent;
                isFinalRouteToggleDisabled = !this.startedEvent;
            }
            else {
                isInitialRouteToggleDisabled = !(data[dataLength - 1] && data[dataLength - 1].direction.allPaths && data[dataLength - 1].direction.allPaths.length > 0);
                isFinalRouteToggleDisabled = !(finalAllPaths && finalAllPaths.length > 0);
            }
            if (dataLength > 0 && data[dataLength - 1] && data[dataLength - 1].direction) {
                this.slideOptions.push({
                    title: 'Initial set route',
                    distance: !this.isEmpty(data[dataLength - 1].direction.distanceInKms) ?
                        (data[dataLength - 1].direction.distanceInKms) + ' kms' : 'N/A',
                    time: !this.isEmpty(data[dataLength - 1].direction.timeInMins) ?
                        (data[dataLength - 1].direction.timeInMins) + ' mins' : 'N/A',
                    polyline: '',
                    addLine: true,
                    disabled: isInitialRouteToggleDisabled
                });
            }
            else {
                this.slideOptions.push({
                    title: 'Initial set route',
                    distance: 'N/A',
                    time: 'N/A',
                    polyline: '',
                    addLine: true,
                    disabled: isInitialRouteToggleDisabled
                });
            }
            if (dataLength > 0 && data[0] && data[0].direction) {
                this.slideOptions.push({ title: 'Final estimated route',
                    distance: this.mapData && this.mapData.finalEstimatedDistance ? this.mapData.finalEstimatedDistance + 'kms' :
                        data[0].direction.distanceInKms ? (data[0].direction.distanceInKms) + ' kms' : 'N/A',
                    time: this.mapData && this.mapData.finalEstimatedTime ? this.mapData.finalEstimatedTime + ' mins' :
                        data[0].direction.timeInMins ? (data[0].direction.timeInMins) + ' mins' : 'N/A',
                    polyline: '',
                    addLine: true,
                    disabled: isFinalRouteToggleDisabled
                });
            }
            else {
                this.slideOptions.push({ title: 'Final estimated route',
                    distance: 'N/A',
                    time: 'N/A',
                    polyline: '',
                    addLine: true,
                    disabled: isFinalRouteToggleDisabled
                });
            }
            this.slideOptions.push({
                title: 'Last Mile Travelled(hF)',
                distance: this.mapData && this.mapData.finalDistance ?
                    ((parseFloat(this.mapData.finalDistance)).toFixed(2)).toString() + ' kms' : 'N/A',
                time: this.mapData && this.mapData.finalRideTime ? this.mapData.finalRideTime + ' mins' : 'N/A',
                polyline: '',
                addLine: true,
                disabled: !(this.actualLatLngs && this.actualLatLngs.locations && this.actualLatLngs.locations.length > 0)
            });
            if (this.mapData && this.mapData.odrdPolyline && this.mapData.odrdDistance) {
                this.slideOptions.push({ title: 'ODRD(Actual) route',
                    distance: this.mapData.odrdDistance ? this.mapData.odrdDistance + ' kms' : 'N/A',
                    time: this.mapData.odrdTime ? this.mapData.odrdTime + " mins" : 'N/A',
                    polyline: '',
                    addLine: true,
                    disabled: !(this.mapData.odrdPolyline)
                });
            }
            else {
                this.slideOptions.push({ title: 'ODRD(Actual) route',
                    distance: 'N/A',
                    time: 'N/A',
                    polyline: '',
                    addLine: true,
                    disabled: !(this.mapData.odrdPolyline)
                });
            }
            this.slideOptions.push({
                title: 'First Mile Travelled',
                addLine: true,
                distance: this.acceptToArriveLatLngs && (this.acceptToArriveLatLngs.distance).toString() ?
                    (this.acceptToArriveLatLngs.distance.toFixed(2)).toString() + ' kms' : 'N/A',
                time: this.acceptToArriveLatLngs && this.acceptToArriveLatLngs.time ? this.acceptToArriveLatLngs.time.toFixed(2) + ' mins' : 'N/A',
                disabled: !(this.acceptToArriveLatLngs && this.acceptToArriveLatLngs.locations &&
                    this.acceptToArriveLatLngs.locations.length > 0)
            });
            this.slideOptions.push({
                title: 'Alternate routes',
                polyline: '',
                addLine: true,
                distance: finalAllPaths && finalAllPaths && finalAllPaths[1] && finalAllPaths[1].distance ?
                    ((finalAllPaths[1].distance / 1000).toFixed(2)).toString() + ' kms' : 'N/A',
                time: finalAllPaths && finalAllPaths && finalAllPaths[1] && finalAllPaths[1].time ?
                    ((finalAllPaths[1].time / 60).toFixed(2)).toString() + ' mins' : 'N/A',
                color: 'color-blue',
                distance2: finalAllPaths && finalAllPaths[2] && finalAllPaths[2].distance ?
                    ((finalAllPaths[2].distance / 1000).toFixed(2)).toString() + ' kms' : 'N/A',
                time2: finalAllPaths && finalAllPaths[2] && finalAllPaths[2].time ?
                    ((finalAllPaths[2].time / 60).toFixed(2)).toString() + ' mins' : 'N/A',
                color2: 'color-red',
                disabled: !(finalAllPaths && finalAllPaths.length > 1)
            });
            this.slideOptions.push({
                title: 'Order Log Points',
                distance: null,
                time: null,
                addLine: true,
                disabled: !(this.markerWithPositions)
            });
            this.slideOptions.push({
                title: 'Tolls',
                distance: null,
                time: null,
                addLine: true,
                disabled: false
            });
        }
        if (!this.slideOptions[0].disabled) {
            this.firstToggle();
        }
    };
    RideTrackerMapComponent.prototype.firstToggle = function () {
        this.selectedOptions = { 'Initial set route': true, 'Final estimated route': false,
            'Last Mile Travelled(hF)': false, 'ODRD(Actual) route': false, 'Order Log Points': true,
            'First Mile Travelled': false, 'Alternate routes': false, 'Captain live location': false,
            'Tolls': false
        };
        this.changeInOption('Initial set route');
        this.changeInOption('Order Log Points');
    };
    RideTrackerMapComponent.prototype.getMarkerInfo = function (labels) {
        var htmlInfo = '';
        for (var label in labels) {
            if (labels[label]) {
                switch (labels[label]) {
                    case 'new':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 new-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> New Order </div>\n                </div>";
                        break;
                    case 'accepted':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 on-the-way-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> On The Way </div>\n                </div>";
                        break;
                    case 'arrived':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 arrived-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Arrived </div>\n                </div>";
                        break;
                    case 'started':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 started-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Started </div>\n                </div>";
                        break;
                    case 'reached':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 reached-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Reached </div>\n                </div>";
                        break;
                    case 'dropped':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 dropped-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Dropped </div>\n                </div>";
                        break;
                    case 'customer_cancelled':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 customer-cancelled-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Customer Cancelled </div>\n                </div>";
                        break;
                    case 'captain_cancelled':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 captain-cancelled-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Captain Cancelled </div>\n                </div>";
                        break;
                    case 'expired':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 expired-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Expired </div>\n                </div>";
                        break;
                    case 'aborted':
                        htmlInfo += "<div>\n                  <div class=\"circle-9 aborted-marker-color display-inline-block\"></div>\n                  <div class=\"text-info-window display-inline-block\"> Aborted </div>\n                </div>";
                        break;
                }
            }
        }
        return htmlInfo;
    };
    RideTrackerMapComponent.prototype.getAcceptToArriveRoute = function () {
        var _this = this;
        var startedState, endState;
        if (this.mapData && this.mapData.orderLogPoints) {
            var logs = this.mapData.orderLogPoints;
            for (var log in logs) {
                if (logs[log]) {
                    if (logs[log].eventName && logs[log].eventName === 'accepted') {
                        startedState = logs[log]['updatedAt'] ? logs[log]['updatedAt'] : '';
                    }
                    else if (startedState && logs[log].eventName && this.ataEndTypesAllowed.indexOf(logs[log].eventName) > -1) {
                        endState = logs[log]['updatedAt'] ? logs[log]['updatedAt'] : '';
                    }
                }
            }
        }
        if (!endState) {
            endState = new Date().getTime();
        }
        if (this.riderIdForOrder && startedState && endState && !this.isFirstMileTriggered) {
            this.isFirstMileTriggered = true;
            this.rideTrackerMapService.getActualRoute(this.riderIdForOrder, startedState, endState).subscribe(function (result) {
                if (result) {
                    result['time'] = _this.differenceTimestamps(startedState, endState);
                    _this.acceptToArriveLatLngs = result;
                    _this.getActualRoute();
                }
                else {
                    _this.getActualRoute();
                }
            }, function (err) {
                _this.isFirstMileTriggered = false;
                _this.getActualRoute();
            });
        }
        else {
            this.getActualRoute();
        }
    };
    RideTrackerMapComponent.prototype.getActualRoute = function () {
        var _this = this;
        var started, endState;
        if (this.mapData && this.mapData.orderLogPoints) {
            var logs = this.mapData.orderLogPoints;
            for (var log in logs) {
                if (logs[log]) {
                    if (logs[log].eventName && logs[log].eventName === 'started') {
                        started = logs[log]['updatedAt'] ? logs[log]['updatedAt'] : '';
                    }
                    else if (started && logs[log].eventName && this.arEndTypesAllowed.indexOf(logs[log].eventName) > -1) {
                        endState = logs[log]['updatedAt'] ? logs[log]['updatedAt'] : '';
                    }
                }
            }
        }
        if (!endState) {
            endState = new Date().getTime();
        }
        if (this.riderIdForOrder && started && endState && !this.isLastMileTriggered) {
            this.isLastMileTriggered = true;
            this.rideTrackerMapService.getActualRoute(this.riderIdForOrder, started, endState).subscribe(function (result) {
                _this.actualLatLngs = result;
                _this.constructSideBar();
                _this.getMarkers();
            }, function (err) {
                _this.isLastMileTriggered = false;
                _this.getSideBar();
            });
        }
        else {
            this.getSideBar();
        }
    };
    RideTrackerMapComponent.prototype.getCaptLiveLocation = function () {
        var _this = this;
        if (this.riderIdForOrder) {
            this.rideTrackerMapService.getLiveLocation(this.riderIdForOrder).subscribe(function (res) {
                if (res && res['location'] && res['location'].length === 2) {
                    _this.captLiveCoords.lng = res['location'][0];
                    _this.captLiveCoords.lat = res['location'][1];
                    _this.captainCurrentStatus = res['status'] || '5';
                    _this.toggleCaptLiveLoc();
                }
            });
        }
    };
    RideTrackerMapComponent.prototype.getSideBar = function () {
        this.constructSideBar();
        this.getMarkers();
    };
    RideTrackerMapComponent.prototype.getMarkers = function () {
        if (this.mapData && this.mapData.orderLogPoints) {
            this.markerWithPositions = {};
            for (var item in this.mapData.orderLogPoints) {
                if (this.mapData.orderLogPoints[item]) {
                    var log = this.mapData.orderLogPoints[item];
                    if (log.eventName && log.captainLocation && log.captainLocation.lat && log.captainLocation.lng) {
                        var latLng = log.captainLocation.lat + ', ' + log.captainLocation.lng;
                        if (this.markerWithPositions[latLng]) {
                            this.markerWithPositions[latLng] = {
                                icon: 'multiple',
                                label: this.markerWithPositions[latLng].label.concat([log.eventName]),
                                lat: log.captainLocation.lat,
                                lng: log.captainLocation.lng
                            };
                        }
                        else {
                            this.markerWithPositions[latLng] = {
                                icon: log.eventName,
                                label: [log.eventName],
                                lat: log.captainLocation.lat,
                                lng: log.captainLocation.lng
                            };
                        }
                    }
                    else if (log.eventName && log.customerLocation && log.customerLocation.lat && log.customerLocation.lng) {
                        var latLng = log.customerLocation.lat + ', ' + log.customerLocation.lng;
                        if (this.markerWithPositions[latLng]) {
                            this.markerWithPositions[latLng] = {
                                icon: 'multiple',
                                label: this.markerWithPositions[latLng].label.concat([log.eventName]),
                                lat: log.customerLocation.lat,
                                lng: log.customerLocation.lng
                            };
                        }
                        else {
                            this.markerWithPositions[latLng] = {
                                icon: log.eventName,
                                label: [log.eventName],
                                lat: log.customerLocation.lat,
                                lng: log.customerLocation.lng
                            };
                        }
                    }
                }
            }
        }
    };
    RideTrackerMapComponent.prototype.toggleInitialRoute = function () {
        if (this.selectedOptions['Initial set route']) {
            if (this.isServiceDeliveryType && this.requestedEvent) {
                var initialPosition = { lat: this.requestedEvent.pickupLocation.lat, lng: this.requestedEvent.pickupLocation.lng };
                var finalPosition = { lat: this.requestedEvent.dropLocation.lat, lng: this.requestedEvent.dropLocation.lng };
                this.startedInitialMarker.setIcon(this.polyStart);
                this.startedInitialMarker.setPosition(initialPosition);
                this.startedInitialMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer set pickup location',
                });
                infowindow.open(this.map, this.startedInitialMarker);
                this.endInitialMarker.setIcon(this.polyEnd);
                this.endInitialMarker.setPosition(finalPosition);
                this.endInitialMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer set drop location',
                });
                infowindow2.open(this.map, this.endInitialMarker);
                this.extendBounds(initialPosition);
                this.extendBounds(finalPosition);
            }
            else {
                var encodedStr = this.mapData.fareEstimateLogs[this.mapData.fareEstimateLogs.length - 1].direction.polyline;
                var initialPath = google.maps.geometry.encoding.decodePath(encodedStr);
                if (this.initialRoute) {
                    this.initialRoute.setMap(null);
                }
                this.initialRoute = new google.maps.Polyline({
                    path: initialPath,
                    geodesic: true,
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                this.startedInitialMarker.setIcon(this.polyStart);
                this.startedInitialMarker.setPosition(this.initialRoute.getPath().getAt(0));
                this.startedInitialMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer set pickup location',
                });
                infowindow.open(this.map, this.startedInitialMarker);
                this.endInitialMarker.setIcon(this.polyEnd);
                this.endInitialMarker.setPosition(this.initialRoute.getPath().getAt(this.initialRoute.getPath().getLength() - 1));
                this.endInitialMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer set drop location',
                });
                infowindow2.open(this.map, this.endInitialMarker);
                for (var i = 0; i < initialPath.length; i++) {
                    this.extendBounds(initialPath[i]);
                }
                this.initialRoute.setMap(this.map);
            }
        }
        else {
            if (this.initialRoute) {
                this.initialRoute.setMap(null);
            }
            this.startedInitialMarker.setMap(null);
            this.endInitialMarker.setMap(null);
        }
    };
    RideTrackerMapComponent.prototype.toggleFinalRoute = function () {
        if (this.selectedOptions['Final estimated route']) {
            if (this.isServiceDeliveryType) {
                var initialPosition = { lat: this.startedEvent.pickupLocation.lat, lng: this.startedEvent.pickupLocation.lng };
                var finalPosition = { lat: this.startedEvent.dropLocation.lat, lng: this.startedEvent.dropLocation.lng };
                this.startedFinalMarker.setIcon(this.polyStart);
                this.startedFinalMarker.setPosition(initialPosition);
                this.startedFinalMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer pickup location',
                });
                infowindow.open(this.map, this.startedFinalMarker);
                this.endFinalMarker.setIcon(this.polyEnd);
                this.endFinalMarker.setPosition(finalPosition);
                this.endFinalMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer drop location',
                });
                infowindow2.open(this.map, this.endFinalMarker);
                this.extendBounds(initialPosition);
                this.extendBounds(finalPosition);
            }
            else {
                var encodedStr = this.mapData && this.mapData.finalEstimatedPolyline ? this.mapData.finalEstimatedPolyline : this.mapData.fareEstimateLogs[0].direction.polyline;
                var finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
                for (var i = 0; i < finalPath.length; i++) {
                    this.extendBounds(finalPath[i]);
                }
                this.finalRoute = new google.maps.Polyline({
                    path: finalPath,
                    geodesic: true,
                    strokeColor: '#0FB27D',
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                this.startedFinalMarker.setIcon(this.polyStart);
                this.startedFinalMarker.setPosition(this.finalRoute.getPath().getAt(0));
                this.startedFinalMarker.setMap(this.map);
                var infowindow = new google.maps.InfoWindow({
                    content: 'Customer pickup location',
                });
                infowindow.open(this.map, this.startedFinalMarker);
                this.endFinalMarker.setIcon(this.polyEnd);
                this.endFinalMarker.setPosition(this.finalRoute.getPath().getAt(this.finalRoute.getPath().getLength() - 1));
                this.endFinalMarker.setMap(this.map);
                var infowindow2 = new google.maps.InfoWindow({
                    content: 'Customer drop location',
                });
                infowindow2.open(this.map, this.endFinalMarker);
                this.finalRoute.setMap(this.map);
            }
        }
        else {
            if (this.finalRoute) {
                this.finalRoute.setMap(null);
            }
            this.startedFinalMarker.setMap(null);
            this.endFinalMarker.setMap(null);
        }
    };
    RideTrackerMapComponent.prototype.toggleOdrdRoute = function () {
        if (this.selectedOptions['ODRD(Actual) route'] && this.mapData && this.mapData.odrdPolyline) {
            var encodedStr = this.mapData.odrdPolyline;
            var finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
            for (var i = 0; i < finalPath.length; i++) {
                this.extendBounds(finalPath[i]);
            }
            this.finalRoute = new google.maps.Polyline({
                path: finalPath,
                geodesic: true,
                strokeColor: '#000099',
                strokeOpacity: 1.0,
                strokeWeight: 3
            });
            this.startedFinalMarker.setIcon(this.polyStart);
            this.startedFinalMarker.setPosition(this.finalRoute.getPath().getAt(0));
            this.startedFinalMarker.setMap(this.map);
            var infowindow = new google.maps.InfoWindow({
                content: 'Customer pickup location',
            });
            infowindow.open(this.map, this.startedFinalMarker);
            this.endFinalMarker.setIcon(this.polyEnd);
            this.endFinalMarker.setPosition(this.finalRoute.getPath().getAt(this.finalRoute.getPath().getLength() - 1));
            this.endFinalMarker.setMap(this.map);
            var infowindow2 = new google.maps.InfoWindow({
                content: 'Customer drop location',
            });
            infowindow2.open(this.map, this.endFinalMarker);
            this.finalRoute.setMap(this.map);
        }
        else {
            if (this.finalRoute) {
                this.finalRoute.setMap(null);
                this.startedFinalMarker.setMap(null);
                this.endFinalMarker.setMap(null);
            }
        }
    };
    RideTrackerMapComponent.prototype.toggleActualRoute = function () {
        if (this.selectedOptions['Last Mile Travelled(hF)']) {
            var points = this.actualLatLngs['locations'];
            for (var position in points) {
                if (points[position]) {
                    if (points[position].location && points[position].location[0] && points[position].location[1]) {
                        var latLng = { lat: points[position].location[1], lng: points[position].location[0] };
                        // this.extendBounds(latLng);
                        this.actualRoute[position] = new google.maps.Marker({
                            icon: this.actualRouteMarker
                        });
                        this.actualRoute[position].setPosition(latLng);
                        this.actualRoute[position].setMap(this.map);
                    }
                }
            }
        }
        else {
            if (this.actualRoute.length > 0) {
                for (var position in this.actualRoute) {
                    if (this.actualRoute[position]) {
                        this.actualRoute[position].setMap(null);
                    }
                }
            }
        }
    };
    RideTrackerMapComponent.prototype.toggleAcceptToArriveRoute = function () {
        if (this.selectedOptions['First Mile Travelled']) {
            var locations = this.acceptToArriveLatLngs['locations'];
            for (var position in locations) {
                if (locations[position]) {
                    if (locations[position].location && locations[position].location[0] && locations[position].location[1]) {
                        var latLng = { lat: locations[position].location[1], lng: locations[position].location[0] };
                        // this.extendBounds(latLng);
                        this.acceptToArriveRoute[position] = new google.maps.Marker({
                            icon: this.acceptToArriveRouteMarker
                        });
                        this.acceptToArriveRoute[position].setPosition(latLng);
                        this.acceptToArriveRoute[position].setMap(this.map);
                    }
                }
            }
        }
        else {
            if (this.acceptToArriveRoute.length > 0) {
                for (var position in this.acceptToArriveRoute) {
                    if (this.acceptToArriveRoute[position]) {
                        this.acceptToArriveRoute[position].setMap(null);
                    }
                }
            }
        }
    };
    RideTrackerMapComponent.prototype.toggleOtherRoutes = function () {
        if (this.selectedOptions['Alternate routes']) {
            var otherRoutes = _.cloneDeep(this.mapData.fareEstimateLogs[0].direction.allPaths);
            var otherRoutesGoogle = otherRoutes.splice(1, 2);
            for (var path in otherRoutesGoogle) {
                if (otherRoutesGoogle[path]) {
                    var encodedStr = otherRoutesGoogle[path]['polyline'];
                    var otherPath = google.maps.geometry.encoding.decodePath(encodedStr);
                    this.otherRoutes[path] = new google.maps.Polyline({
                        path: otherPath,
                        geodesic: true,
                        strokeColor: this.colorsForOtherRoutes[path],
                        strokeOpacity: 1.0,
                        strokeWeight: 3
                    });
                    this.startedOtherMarker[path] = new google.maps.Marker({
                        position: this.otherRoutes[path].getPath().getAt(0),
                        map: this.map,
                        icon: this.polyStart
                    });
                    this.endOtherMarker[path] = new google.maps.Marker({
                        position: this.otherRoutes[path].getPath().getAt(this.otherRoutes[path].getPath().getLength() - 1),
                        map: this.map,
                        icon: this.polyEnd
                    });
                    for (var i = 0; i < otherPath.length; i++) {
                        // this.extendBounds(otherPath[i]);
                    }
                    this.otherRoutes[path].setMap(this.map);
                }
            }
        }
        else {
            if (this.otherRoutes && this.otherRoutes.length > 0) {
                for (var route in this.otherRoutes) {
                    if (this.otherRoutes[route]) {
                        this.otherRoutes[route].setMap(null);
                        this.startedOtherMarker[route].setMap(null);
                        this.endOtherMarker[route].setMap(null);
                    }
                }
            }
        }
    };
    RideTrackerMapComponent.prototype.extendBounds = function (location) {
        this.bound.extend(location);
        this.map.fitBounds(this.bound, 100);
        // this.map.setZoom(12);
    };
    RideTrackerMapComponent.prototype.differenceTimestamps = function (date1, date2) {
        var res = Math.abs(date1 - date2) / 1000;
        // get total days between two dates
        var days = (res / 86400);
        // get hours
        var hours = (res / 3600) % 24;
        // get minutes
        var minutes = (res / 60) % 60;
        return minutes;
    };
    RideTrackerMapComponent.prototype.openFullView = function () {
        var fullMapObj = { routes: this.mapData, actualRoute: this.actualLatLngs, orderStatus: this.orderStatus,
            captLiveLocInOrderLogs: this.captLiveLocInOrderLogs, cancellationInformation: this.cancellationInformation,
            riderId: this.riderIdForOrder, captLiveCoords: this.captLiveCoords, captainCurrentStatus: this.captainCurrentStatus,
            acceptToArriveRoute: this.acceptToArriveLatLngs, options: this.slideOptions, selectedOptions: this.selectedOptions,
            acceptRouteTitle: this.acceptRouteTitle, cityBoundaryPolygon: this.cityBoundaryPolygon, orderCityId: this.orderCityId,
            cityTolls: this.cityTolls,
            events: { requestedEvent: this.requestedEvent, startedEvent: this.startedEvent },
            isServiceDeliveryType: this.isServiceDeliveryType
        };
        this.fullViewMap.emit(fullMapObj);
    };
    RideTrackerMapComponent.prototype.togglePopulateTolls = function () {
        var _this = this;
        if (this.selectedOptions['Tolls']) {
            this.tollEnabled = true;
            if (this.cityTolls.length == 0) {
                this.rideTrackerMapService.getCityTolls(this.orderCityId).subscribe(function (result) {
                    if (result) {
                        _this.cityTolls = result['tolls'];
                        _this.tollPolylines = _this.tollService.addTollsToMap(_this.cityTolls, _this.map);
                        var tollLines = _this.tollService.fetchTollLinesFromTolls(_this.cityTolls);
                        _this.cityEntryTollLines = tollLines.entryTollLines;
                        _this.cityExitTollLines = tollLines.exitTollLines;
                    }
                }, function (error) {
                    if (error) {
                        _this.selectedOptions['Tolls'] = false;
                        _this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: 'Toll fetch failed'
                        }));
                    }
                });
            }
            else {
                this.tollPolylines = this.tollService.addTollsToMap(this.cityTolls, this.map);
            }
        }
        else {
            this.tollEnabled = false;
            this.tollService.removeTollsFromMap(this.map, this.tollPolylines);
        }
    };
    return RideTrackerMapComponent;
}());
export { RideTrackerMapComponent };
