/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cust-activity-history.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../expandable-table/expandable-table.component.ngfactory";
import * as i3 from "../expandable-table/expandable-table.component";
import * as i4 from "./cust-activity-history.component";
import * as i5 from "./cust-activity-history.service";
import * as i6 from "../user-tickets.service";
var styles_CustActivityHistoryComponent = [i0.styles];
var RenderType_CustActivityHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustActivityHistoryComponent, data: {} });
export { RenderType_CustActivityHistoryComponent as RenderType_CustActivityHistoryComponent };
export function View_CustActivityHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expandable-table", [], null, [[null, "loadData"], [null, "toggleExpansion"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadData" === en)) {
        var pd_0 = (_co.getTickets() !== false);
        ad = (pd_0 && ad);
    } if (("toggleExpansion" === en)) {
        var pd_1 = (_co.toggleState($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ExpandableTableComponent_0, i2.RenderType_ExpandableTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.ExpandableTableComponent, [], { info: [0, "info"] }, { toggleExpansion: "toggleExpansion", loadData: "loadData" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustActivityHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cust-activity-history", [], null, null, null, View_CustActivityHistoryComponent_0, RenderType_CustActivityHistoryComponent)), i1.ɵdid(1, 638976, null, 0, i4.CustActivityHistoryComponent, [i5.CustActivityHistoryService, i6.UserTicketingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustActivityHistoryComponentNgFactory = i1.ɵccf("app-cust-activity-history", i4.CustActivityHistoryComponent, View_CustActivityHistoryComponent_Host_0, { custActHistory: "custActHistory" }, {}, []);
export { CustActivityHistoryComponentNgFactory as CustActivityHistoryComponentNgFactory };
