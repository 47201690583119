import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
var RangeSliderComponent = /** @class */ (function () {
    function RangeSliderComponent() {
        this.selectedRange = new EventEmitter();
        this.value = {
            from: '',
            to: '',
        };
    }
    RangeSliderComponent.prototype.ngOnChanges = function (changes) {
        // console.log(changes);
        this.ngOnInit();
    };
    RangeSliderComponent.prototype.ngOnInit = function () {
        // this.rangeData = {
        //     type : 'range-slider',
        //     title : 'Cashback',
        //     values : [0, 50],
        //     default : '',
        //     key : 'cashback-range'
        //   };
        this.value = this.value || {};
        this.rangeData.values[0] = parseInt(this.rangeData.values.from, 10);
        this.rangeData.values[1] = parseInt(this.rangeData.values.to, 10);
        this.options = {
            floor: this.rangeData.values[0],
            ceil: this.rangeData.values[1]
        };
        this.sliderForm = new FormGroup({
            sliderControl: new FormControl([this.value.from || this.rangeData.values.from, this.value.to || this.rangeData.values.to])
        });
    };
    RangeSliderComponent.prototype.resetForm = function () {
        this.sliderForm.reset({ sliderControl: [20, 80] });
    };
    RangeSliderComponent.prototype.chosenOptions = function () {
        var _a;
        var emitSelections = (_a = {},
            _a[this.rangeData.key] = { from: this.sliderForm.value.sliderControl[0], to: this.sliderForm.value.sliderControl[1] },
            _a);
        this.selectedRange.emit(emitSelections);
    };
    return RangeSliderComponent;
}());
export { RangeSliderComponent };
