import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaptainFailedOrdersService {
  constructor(private http: MyHttpClient) { }
  server = `${environment.server}`;
  getFailedOrdersUrl = this.server + '/api/b2b/failedOrders';
  revokeFailedOrderUrl = this.server + '/api/b2b/revokeOrder';
  getActivityLogUrl = this.server + '/api/b2b/getActivityLog';
  getSuspensionLogUrl = this.server + '/api/b2b/getSuspensionLog';
  getFailedOrders(id, body) {
    return this.http.post(this.getFailedOrdersUrl + '/' + id, body).pipe(catchError(this.handleError));
  }

  revokeFailedOrder(riderId, body) {
    return this.http.post(this.revokeFailedOrderUrl + '/' + riderId, body).pipe(catchError(this.handleError));

  }

  getActivityLog(riderId, orderId) {
    return this.http.get(`${this.getActivityLogUrl}/${riderId}/${orderId}`).pipe(catchError(this.handleError));
  }

  getSuspensionLog(riderId, action, event, orderIds, minDate, maxDate) {
    let riderObject = { riderId, action, event, orderIds, minDate, maxDate};
    let riderObjectJsonFormat = JSON.stringify(riderObject)
    return this.http.get(`${this.getSuspensionLogUrl}?Object=${riderObjectJsonFormat}`).pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
