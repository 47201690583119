import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent implements OnInit {
@Input('toggleList') isExpanded: boolean;
@Output() isFilterOpen: EventEmitter<any> = new EventEmitter<any>();
public riderMenu = false;
public customerMenu = false;
public merchantMenu = false;
public orderMenu = false;
public managerMenu = false;
public demandAreasMenu = false;
  constructor(public _route: Router) { }

  ngOnInit() {
  }

  gotoCaptain() {
    this.isFilterOpen.emit();
    this._route.navigate(
      ['/captain']
    );
  }

  gotoCustomer() {
    this.isFilterOpen.emit();
    this._route.navigate(
      ['/customer']
    );
  }

  gotoMerchant() {
    this.isFilterOpen.emit();
    this._route.navigate(
      ['/merchant']
    );
  }

  goToOrder() {
    this.isFilterOpen.emit();
    this._route.navigate(
      ['/order']
    );
  }

  goToManager() {
    this.isFilterOpen.emit();
    this._route.navigate(
      ['/manager/']
    );
  }

  goToDemandAreas() {
    this.isFilterOpen.emit();
    this._route.navigate(
      ['/demand-areas']
    );
  }

}
