export class IPerformanceCard {
  label: string;
  value: any;
  hikePercent?: any;
  isPositivehike?: boolean;
  onClick?: () => void;
  visible?: boolean;
  style?: any;
}
export class PerformanceCard {
  label: string;
  value: any;
  hikePercent: any;
  isPositivehike = true;
  visible = true;
  onClick: () => void;
  style: any = {
    height: '127px',
    width: '176px',
  };
  constructor(config: IPerformanceCard) {
    Object.assign(this, config);
    this.onClick = this.onClick || (() => {});
  }
  get displayPercentValue() {
    if (this.isPositivehike) {
      return this.hikePercent;
    }
    return `-${this.hikePercent}`
  }
}
