import { Component, OnInit, Input } from '@angular/core';
import { Table, TableData, TableDataTypes, Pagination, ITable } from '../types';
import { PerformanceCard } from '../types/card.types';

export interface IHorizontalList {
  tableData: TableData[][];
  filterCount?: any;
  header?: any;
  filterComponents?: any;
  appliedFilters?: any;
  config?: {
    showHeaderBar?: boolean
    colName?: boolean,
    filter?: boolean,
    refresh?: boolean,
  };
  events?: {
    onSearch?: (searchObj: any) => void;
    onFilter?: () => void;
    onRefresh?: () => void;
  };
}

const defaultHorizontalListConfig = {
  showHeaderBar: true
};
export class HorizontalList {
  table: Table;
  tableData: TableData[];
  filterCount: any;
  filterComponents: any;
  _config ?: {
    showHeaderBar?: boolean
  };
  private DEFAULT_CONFIG = {
    showHeaderBar: true,
    colName: false,
    filter: false,
    refresh: false,
    noColorOnTdOnHover: true,
    headerStyle: {
      'border-bottom': 0,
    }
  };
  private DEFAULT_EVENTS = {
    onSearch: (searchObj: any) => console.log(searchObj),
    onFilter: () => console.log('filter'),
    onRefresh: () => console.log('refresh'),
  };
  set config(config) {
    this._config = Object.assign({}, defaultHorizontalListConfig, config);
  }
  get config() {
    return this._config;
  }

  constructor(config: IHorizontalList) {
    this.config = config.config || {};
    Object.assign(this, config);
    const tableConfig = Object.assign({}, this.DEFAULT_CONFIG, config.config);
    const tableEvents = Object.assign({}, this.DEFAULT_EVENTS, config.events);
    const mappedHeaders = config.tableData.map((data, index) => {
      return data.map((item, index) => {
        return {
          [index]: index
        };
      });
    }).reduce((acc, header) => {
      return Object.assign(acc, header);
    }, {});
    const mappedData = config.tableData.map((data) => {
      // const row = {};
      return data.reduce((acc, item, index) => {
        acc[index] = item;
        return acc;
      }, {});
    });
    this.table = new Table({
      tableHeader: config.header,
      config: tableConfig,
      onFilter: tableEvents.onFilter,
      onRefresh: tableEvents.onRefresh,
      onSearch: tableEvents.onSearch,
      headers: mappedHeaders,
      data: mappedData,
      // data: [mappedData],
      filterComponents: config.filterComponents,
      appliedFilters: config.appliedFilters,
      filterCount: config.filterCount,
    });
  }
}

@Component({
  selector: 'app-horizontal-list',
  templateUrl: './horizontal-list.component.html',
  styleUrls: ['./horizontal-list.component.css']
})
export class HorizontalListComponent implements OnInit {
  @Input() list: HorizontalList;
  constructor() { }

  ngOnInit() {
  }

}
