import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class FareEstimateLogService {
    server = environment.server;
    offerDetailsUrl = this.server + '/api/offer';

    fetchLocationOffer(offerId: any) {
        const url = `${this.offerDetailsUrl}/location/${offerId}`;
        return this.http.get(url).pipe(catchError(this.handleError));
    }

    fetchRideOffer(offerId: any) {
        const url = `${this.offerDetailsUrl}/ride/${offerId}`;
        return this.http.get(url).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(error);
    }
    constructor(private http: MyHttpClient) { }
}
