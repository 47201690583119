import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { EntityService } from '../entity.service';
import { DatePipe } from "@angular/common";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { } from "googlemaps";
import { DemandAreasService } from './demand-areas.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";


@Component({
	selector: "demand-areas-component",
	templateUrl: "./demand-areas.component.html",
	styleUrls: ["./demand-areas.component.css"],
})
export class DemandAreasComponent implements OnInit {
	@ViewChild("mapContainer") gmap: ElementRef;
	@ViewChild("alertDialog") alertDialog: TemplateRef<any>;
	alertText: string;

	cities: any = [];
	services: any = [];
	demandAreas: any = [];
	times: any = [];
	allTimes = _.range(24);
	mapProperties: any;
	map: google.maps.Map;
	polygons: any = [];
	datePipe: DatePipe = new DatePipe("en");
	currentSelectedTime: any;
	maxDate: Date = new Date();

	demandAreasFormGroup = new FormGroup({
		selectedCity: new FormControl(null, Validators.required),
		selectedServices: new FormControl(null, Validators.required),
		selectedDate: new FormControl(null, Validators.required),
	});
	constructor(
		private entityService: EntityService,
		private demandAreasService: DemandAreasService,
		private dialog: MatDialog
	) { }
	ngOnInit() {
		this.demandAreasService.getCities().subscribe((cities) => {
			this.cities.push(...cities);
		});
		this.mapProperties = {
			center: new google.maps.LatLng(
				12.998337399998206,
				77.62443977505797
			),
			zoom: 10,
			streetViewControl: false,
			fullscreenControl: false,
			mapTypeControl: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			clickableIcons: false,
			gestureHandling: "cooperative",
		};
		this.map = new google.maps.Map(
			this.gmap.nativeElement,
			this.mapProperties
		);

		this.maxDate.setDate(this.maxDate.getDate() + 6);
	}

	getServicesForSelectedCity() {
		this.demandAreasFormGroup.patchValue({ selectedServices: null });
		this.services = [];
		let selectedCity = this.demandAreasFormGroup.get("selectedCity").value;
		if (selectedCity) {
			this.map.setCenter(new google.maps.LatLng(selectedCity.latitude, selectedCity.longitude));
			this.entityService.getServicesPerCity(selectedCity._id).subscribe((res) => {
				let services = res["data"];
				services.map((service) => {
					this.services.push({
						value: service["service"]["_id"],
						name: service["service"]["name"],
					});
				});
			});
		}
	}

	submit() {
		this.times = [];
		this.demandAreas = [];
		this.currentSelectedTime = null;
		this.setAllPolygons(null);
		this.polygons = [];
		let city = this.demandAreasFormGroup.get("selectedCity").value;
		let services = this.demandAreasFormGroup.get("selectedServices").value;
		let date = this.datePipe.transform(
			this.demandAreasFormGroup.get("selectedDate").value,
			"dd-MM-yyyy"
		);
		let time =
			"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23";

		if (!city) {
			this.openAlertDialog("Please select city to proceed");
			return;
		}

		if (!services || services.length == 0) {
			this.openAlertDialog("Please select services to proceed");
			return;
		}

		if (!date) {
			this.openAlertDialog("Please select date to proceed");
			return;
		}

		this.demandAreasService
			.fetchDemandAreas(city._id, services, date, time)
			.subscribe({
				next: (res) => {
					if (res["demandAreas"].length == 0) {
						this.alertText =
							"No Demand Areas found for your selection";
						this.dialog.open(this.alertDialog);
					}
					res["demandAreas"].map((demandArea) => {
						this.demandAreas.push(demandArea);
						this.times.push(demandArea["time"]);
					});
				},
				error: () => {
					this.openAlertDialog(
						"NDL service might be down, please try again later"
					);
					return;
				},
			});
	}

	plotHexesForTime(selection: any) {
		this.currentSelectedTime = selection;
		this.setAllPolygons(null);
		this.polygons = [];
		const demandArea = this.demandAreas.filter(
			(demandArea) => demandArea["time"] === selection
		);

		demandArea[0]["areas"].map((area) => {
			let points = [];
			area["hexes"].map((hex) => {
				points.push(hex["points"]);
			});
			this.polygons.push(
				new google.maps.Polygon({
					paths: points,
					fillOpacity: 0.4,
					fillColor: "#FDA13A",
					strokeOpacity: 0,
				})
			);
		});

		this.setAllPolygons(this.map);
		this.map.setZoom(13);
	}

	setAllPolygons(map: google.maps.Map) {
		this.polygons.map((polygon) => {
			polygon.setMap(map);
		});
	}

	openAlertDialog(alertMessage: string) {
		this.alertText = alertMessage;
		this.dialog.open(this.alertDialog);
	}
}
