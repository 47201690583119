import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MyHttpClient } from "../http-client.service";


@Injectable({
	providedIn: "root",
})
export class DemandAreasService {
	server = environment.server;
	demandAreasUrl = this.server + "/api/ndl/demandAreas";

	fetchDemandAreas(city, services, date, time) {
		const url = `${this.demandAreasUrl}?city=${city}&services=${services}&date=${date}&time=${time}`;
		return this.http.get(url).pipe(catchError(this.handleError));
	}

	getCities() {
		const url = `${this.server}/api/get/cities`;
		return this.http.get(url).map((res: any) => res.data.cities).pipe(catchError(this.handleError));
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error("An error occurred:", error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
					`body was: ${error.error}`
			);
		}
		// return an observable with a user-facing error message
		return throwError(error);
	}

	constructor(private http: MyHttpClient) {}
}
