<mat-accordion *ngIf="incentives && incentives.length" [multi]="true">
  <mat-expansion-panel *ngFor="let incentive of incentives; let i = index" class="mat-elevation-z0">
    <mat-expansion-panel-header class="dailyIncentiveHeader">
      <mat-panel-title>
        <b>
          Daily Incentive - {{i + 1}}
          [{{getFormattedDate(incentive.startDate)}}]
        </b>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="row margin-top-16 panel-body">
      <div class="margin-right-45">
        <label>Name</label>
        <p>{{incentive.name}}</p>
      </div>
      <div class="margin-right-45">
        <label>Service</label>
        <p>{{incentive.services.join(', ')}}</p>
      </div>
      <div class="margin-right-45">
        <label>City</label>
        <p>{{incentive.cities.join(', ')}}</p>
      </div>
      <div *ngIf="incentive.displayName" class="margin-right-45">
        <label>Display Name</label>
        <p>{{incentive.displayName}}</p>
      </div>
      <div *ngIf="incentive.maxOrderDistance" class="margin-right-45">
        <label>Max Order Distance</label>
        <p>{{incentive.maxOrderDistance}}</p>
      </div>
      <div>
        <label>T&C</label>
        <p (click)="openTncDialog()" class="web-link">View T&C</p>
        <ng-template #tnc let-modal>
          <div class="modal-header">
            Terms & Conditions
          </div>
          <div class="modal-body">
            <ol>
              <li *ngFor="let tnc of incentive.tncs">
                {{tnc}}
              </li>
            </ol>
          </div>
        </ng-template>
      </div>

      <div *ngIf="incentive.cancellationLimit">
        <label class="cancellation-limit-global-heading">Cancellations</label>
        <div class="cancellation-limit-row">
          <div class="col cancellation-limit-text width-90">
            0-{{getCancellationLimit(incentive.incentiveCards,"global")}} Cancellations
          </div>
          <div class="col">
            <app-linear-progress [max]="getCancellationStatusBarValues(incentive.incentiveCards,'max','global')"
              [min]="0"
              [completed]="getCancellationStatusBarValues(incentive.incentiveCards,'min','global') |  number:'1.0-2'">
            </app-linear-progress>
          </div>
          <div class="status width-100 col"
            [ngClass]="getClass(getCancellationStatus(incentive, incentive.incentiveCards,'global'))">
            <div class="circle" [ngClass]="getCircleClass(getCancellationStatus(incentive, incentive.incentiveCards,'global'))">
            </div>
            {{getStatus(getCancellationStatus(incentive, incentive.incentiveCards,'global'))}}
          </div>
        </div>
      </div>

    </div>

    <div class="line"></div>
    <div *ngFor="let slots of incentive.incentiveCards">
      <div class="padding-30">
        <div class="row padding-bottom-30">
          <div class="incentive-font col">
            {{formatTime(slots.timeSlot)}}
          </div>

          <div class="cancellation-limit-text width-90 col" *ngIf="slots.cancellationLimit">
            <label class="cancellation-limit-set-heading">Cancellations</label>
            0-{{getCancellationLimit(slots,"set")}} Cancellations
          </div>
          <div class="col margin-top-30" *ngIf="slots.cancellationLimit">
            <app-linear-progress [max]="getCancellationStatusBarValues(slots,'max','set')" [min]="0"
              [completed]="getCancellationStatusBarValues(slots,'min','set') |  number:'1.0-2'">
            </app-linear-progress>
          </div>
          <div class="status width-100 col margin-top-30"
            [ngClass]="getClass(getCancellationStatus(incentive, slots,'set'))" *ngIf="slots.cancellationLimit">
            <div class="circle" [ngClass]="getCircleClass(getCancellationStatus(incentive, slots,'set'))">
            </div>
            {{getStatus(getCancellationStatus(incentive, slots,'set'))}}
          </div>

        </div>
        <div [class.progressBars]="i !== (slots.rules.length - 1)" *ngFor="let progress of slots.rules; let i = index">
          <div class="row progress-section">
            <div class="row orders" *ngIf="progress.order">
              <div class="incentive-font width-90">
                {{progress.order}} Orders
              </div>
              <div [ngClass]="{'width-259' : progress.distance, 'width-680': !progress.distance}">
                <app-linear-progress [max]="progress.order" [min]="0"
                  [completed]="(progress.completedOrders) |  number:'1.0-2'"
                  [progressStatus]="progress.status"></app-linear-progress>
              </div>
            </div>
            <div class="selective-condition incentive-font width-40" *ngIf="progress.order && progress.distance">
              {{incentive.selectedCondition === "&&" ? "(AND)" : "(OR)"}} </div>
            <div class="row distance" *ngIf="progress.distance">
              <div class="incentive-font width-90">
                {{progress.distance}} kms
              </div>
              <div [ngClass]="{'width-259' : progress.order, 'width-680': !progress.order}">
                <app-linear-progress [max]="progress.distance" [min]="0"
                  [completed]="(progress.completedDistance) |  number:'1.0-2'"
                  [progressStatus]="progress.status"></app-linear-progress>
              </div>
            </div>
            <div class="incentive-font width-90 money">
              ₹ {{progress.amount}}
            </div>
            <div class="status width-100" [ngClass]="getClass(getCurrentStatus(incentive, progress.status, slots))">
              <div class="circle" [ngClass]="getCircleClass(getCurrentStatus(incentive, progress.status, slots))"></div>
              {{getStatus(getCurrentStatus(incentive, progress.status, slots))}}
            </div>
            <button class="view-button" (click)="this.openOrdersDialog(progress.orders)">
              View orders
            </button>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #orders let-modal>
  <div class="modal-header">
    Orders
  </div>
  <div class="modal-body" *ngIf="orderList && orderList.length">
    <ol>
      <li class="cursor-pointer web-link" *ngFor="let order of orderList">
        <div *ngIf="order.id" (click)="goToOrder(order)">
          {{order.id}}
        </div>
      </li>
    </ol>
  </div>
  <div class="no-incentives" *ngIf="!orderList || !orderList.length">
    No orders found
  </div>
</ng-template>
<div *ngIf="!incentives || !incentives.length" class="no-incentives">
  No incentives found
</div>
