var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { OrderDetailsService } from './order-details.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { OrderStatusService } from '.././order-status/order-status-service';
import { FullViewMapService } from '../full-view-map.service';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomerDetailsService } from '../customer-details/customer-details.service';
import { CustomerTransactionDetailsService } from '../customer-details/customer-transaction-details/customer-transaction-details.service';
import { PaymentStatusComponent } from '../payment-status/payment-status.component';
import { MatDialog } from '@angular/material';
import { ORDERS_QOS } from '../shared/constants';
import { OrderDetailsHelper } from './order-details.helper';
import { EntityService } from '../entity.service';
import { CAPTAIN_AUTO_EARNINGS_CITY_CONFIG } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';
var OrderDetailsComponent = /** @class */ (function () {
    function OrderDetailsComponent(dialog, orderDetailsService, route, fullViewMapService, toasterService, orderStatusService, titleService, router, orderDetailsHelper, customerDetailsService, customerTransactionDetailsService, entityService) {
        this.dialog = dialog;
        this.orderDetailsService = orderDetailsService;
        this.route = route;
        this.fullViewMapService = fullViewMapService;
        this.toasterService = toasterService;
        this.orderStatusService = orderStatusService;
        this.titleService = titleService;
        this.router = router;
        this.orderDetailsHelper = orderDetailsHelper;
        this.customerDetailsService = customerDetailsService;
        this.customerTransactionDetailsService = customerTransactionDetailsService;
        this.entityService = entityService;
        this.faRefresh = faRedo;
        this.isRefreshing = false;
        this.newOrderMapping = {};
        this.orderPromiseMapping = {};
        this.autoEarningsMapping = {};
        this.newOrderBreakup = {};
        this.uniqueId = null;
        this.deliveryId = null;
        this.clientName = null;
        this.orderType = null;
        this.invoiceDetailsMapping = {};
        this.order = {};
        this.abortReason = '';
        this.rateCard = {};
        this.invoiceDetails = {};
        this.captainInvoiceDetails = {};
        this.emailInvoice = {};
        this.distTime = {};
        this.fareEstimateLogs = {};
        this.mapData = {};
        this.showOrderLogsOnMaps = false;
        this.dispatch = {};
        this.orderTimeLine = { dataFetched: true, data: null };
        this.fullViewMapToggle = false;
        this.b2bConfig = null;
        this.correlationIds = null;
        this.showTxnInfoModal = false;
        this.correlationTs = null;
        this.errorResponse = { dataFetched: false, data: null };
        this.pageRefreshed = false;
        this.packageOrderTypes = ['c2c', 'sme', 'delivery', 'bfs'];
        this.showPackageCard = false;
        this.isBatchable = false;
        this.batchId = null;
        this.FNDCase = null;
        this.batchingInfo = {};
        this.batchingOrderDetails = [];
        this.deviceId = '';
        this.statusClassMapping = {
            new: 'yellow-status',
            accepted: 'yellow-status',
            arrived: 'blue-status',
            started: 'blue-status',
            reached: 'blue-status',
            dropped: 'green-status',
            customer_cancelled: 'red-status',
            rejected: 'red-status',
            aborted: 'red-status',
            rider_busy: 'red-status',
            unreachable: 'red-status',
            expired: 'red-status',
            rider_switch: 'yellow-status'
        };
        this.statusTextMapping = {
            new: 'New',
            accepted: 'Accepted',
            arrived: 'Arrived',
            started: 'Started',
            reached: 'Reached',
            dropped: 'Dropped',
            customer_cancelled: 'Customer Cancelled',
            rejected: 'Rejected',
            aborted: 'Aborted',
            rider_busy: 'Rider Busy',
            unreachable: 'Unreachable',
            expired: 'Expired',
            rider_switch: 'Rider Switched'
        };
        this.notAllowedToAbortStatus = [
            'aborted', 'dropped', 'customerCancelled', 'expired',
            'new', 'requested', 'captainCancelled'
        ];
        this.filterDuplicateStatus = function (timelineDetails) {
            var uniqueTimelineStatus = timelineDetails.filter(function (timelineEvent, index) {
                if (index == 0)
                    return true;
                return timelineEvent.status !== timelineDetails[index - 1].status;
            });
            return uniqueTimelineStatus;
        };
        this.monthMap = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
            7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        this.titleService.setTitle('Order Details');
    }
    OrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (data) {
            _this.refreshPage(null);
        });
    };
    OrderDetailsComponent.prototype.refreshPage = function (pageRefreshed) {
        this.pageRefreshed = pageRefreshed;
        this.isRefreshing = true;
        this.orderId = this.route.snapshot.params.id;
        this.statusMap = {};
        this.fareEstimateLogs = {};
        this.statusMap['requested'] = 'Requested';
        this.statusMap['new'] = 'New';
        this.statusMap['captains_mapped'] = 'New';
        this.statusMap['accepted'] = 'On the way';
        this.statusMap['arrived'] = 'Arrived';
        this.statusMap['started'] = 'Started';
        this.statusMap['dropped'] = 'Dropped';
        this.statusMap['customer_cancelled'] = 'Customer cancelled';
        this.statusMap['captain_rejected'] = 'Captain rejected';
        this.statusMap['captain_cancelled'] = 'Captain cancelled';
        this.statusMap['expired'] = 'Expired';
        this.statusMap['aborted'] = 'Aborted';
        this.statusMap['dropChanged'] = 'Drop Changed';
        this.statusMap['captain_switch'] = 'Captain Switched';
        this.statusMap['captain_busy'] = 'Captain Busy';
        this.statusMap['reached'] = 'Reached';
        this.statusMap['shipmentReadyTimeInEpoch'] = 'Restaurant SMR';
        this.statusMap['predictedShipmentReadyTimeInEpoch'] = 'Estimated SMR';
        this.fetchOrderDetails();
    };
    OrderDetailsComponent.prototype.showLogsOnMap = function () {
        this.showOrderLogsOnMaps = !this.showOrderLogsOnMaps;
    };
    OrderDetailsComponent.prototype.fullViewMap = function (mapInfo) {
        this.fullViewMapService.toggleFullMap(mapInfo);
    };
    OrderDetailsComponent.prototype.copyCorrelationIds = function () {
        var _this = this;
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', (_this.correlationIds.join(', ')));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    };
    OrderDetailsComponent.prototype.getProperDate = function (timeStamp) {
        var timeInfo = new Date(timeStamp).toLocaleTimeString('en-US', {
            hour: 'numeric', hour12: true, minute: 'numeric',
            second: 'numeric'
        });
        var today = new Date(timeStamp);
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear().toString().substr(-2);
        if (dd < 10) {
            dd = '0' + dd;
        }
        var dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
        return (dateInfo + '\n' + timeInfo);
    };
    OrderDetailsComponent.prototype.fetchOrderDetails = function () {
        var _this = this;
        this.orderDetailsService.fetchOrderDetailsV2(this.orderId, ORDERS_QOS.qos1).subscribe(function (orderResponse) { return __awaiter(_this, void 0, void 0, function () {
            var lat, lng, serviceDetailIdArray_1, showV2AutoEarnings_1, events, _i, events_1, event_1, isExternalOTP, deliveryInfo, id, customer, wallets, userData, requestIds, data, customerPackages, captainPackages, chosenPackageIndex, chosenCustomerPackage, chosenCaptainPackage, nightFareInfoFromRateCardApi, customerIdForInvoice, cashBackObj, totalCashBack, cashback, walletDetails, nonZeroWalletDetails, wallets, nonZeroWallet, wallet, requestedAt, amountBreakup, totalDiscount, pricingDetails, finalDistance, hfDistance, finalRideTime, pickupOtp, dropOtp;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.order = orderResponse || {};
                        this.pricing = orderResponse && orderResponse['pricing'] || {};
                        this.payment = orderResponse && orderResponse['payment'] || {};
                        this.serviceDetail = orderResponse && orderResponse['serviceDetail'] || {};
                        this.geoFence = orderResponse && orderResponse['geofence'] || {};
                        this.pickupLocation = orderResponse && orderResponse['pickupLocation'] || {};
                        this.fetchRequiredEventFields();
                        if (!this.order) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getNewCaptainRateCard()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getInvoiceDetails()];
                    case 2:
                        _a.sent();
                        lat = this.order.pickupLocation.lat;
                        lng = this.order.pickupLocation.lng;
                        serviceDetailIdArray_1 = [];
                        this.entityService.getServiceDetailsForRider(this.order.captainId, lat, lng).subscribe(function (serviceDetails) {
                            if (serviceDetails) {
                                var serviceDetailMap = JSON.parse(JSON.stringify(serviceDetails));
                                serviceDetailIdArray_1 = serviceDetailMap.map(function (item) { return item.serviceDetailId; });
                            }
                        });
                        showV2AutoEarnings_1 = this.orderDetailsHelper.isAutoV2EarningEnabled(this.serviceDetail, serviceDetailIdArray_1, CAPTAIN_AUTO_EARNINGS_CITY_CONFIG);
                        if (this.order.status == 'dropped' && this.serviceDetail.service.displayName != 'Local') {
                            this.orderDetailsService.fetchOrderAcceptPromise(this.orderId).subscribe(function (orderPromise) {
                                _this.orderPromiseMapping = _this.parseOrderAcceptResponse(orderPromise, _this.order.captainId);
                            });
                        }
                        this.orderDetailsService.fetchDetailedOrderEarnings(this.orderId).subscribe(function (result) {
                            if (result && Array.isArray(result) && result.length > 0) {
                                var serviceName_1 = _this.serviceDetail && _this.serviceDetail.service && _this.serviceDetail.service.name || '';
                                if (result[0].version == 3 && ['link', 'bike pink', 'c2c'].includes(serviceName_1.toLowerCase())) {
                                    _this.orderDetailsService.fetchInvoiceReceiptDetails(_this.orderId).subscribe(function (invoiceDetails) {
                                        _this.newOrderBreakup = _this.parseCaptainNewEarningApiResponse(_this.orderId, _this.order.status, result[0], invoiceDetails, _this.payment.mode, _this.pricing.prevDue);
                                        _this.captainInvoiceDetails = {
                                            deliveryOrder: _this.deliveryId ? true : false,
                                            dataFetched: true,
                                            asPerNewEarnings: true,
                                            orderServiceName: serviceName_1,
                                            earningsBreakup: _this.newOrderBreakup,
                                            orderStatus: _this.orderStatus,
                                            orderAcceptPromiseDetails: _this.orderPromiseMapping,
                                            data: {
                                                distance: _this.pricing['distance']['finalDistance'],
                                                totalTime: _this.pricing['amountBreakup']['captain']['timeFare']['quantity'],
                                                waitingTime: _this.pricing['amountBreakup']['captain']['waitTimeCharges']['quantity'],
                                                nightFareTooltip: _this.newOrderBreakup['orderBreakup']['nightFare'] && _this.orderDetailsHelper.getCaptainTimeBasedSlab(_this.serviceDetail, _this.order.events).value
                                            }
                                        };
                                    });
                                }
                                else if (showV2AutoEarnings_1 && ['auto'].includes(serviceName_1.toLowerCase()) || (['autopremium'].includes(serviceName_1.toLowerCase()))) {
                                    _this.autoEarningsMapping = _this.orderDetailsHelper.getFormattedEarningsBreakup(_this.order, _this.invoiceDetailsMapping, result[0], JSON.parse(environment.autoFlatCommissionCities));
                                    _this.captainInvoiceDetails = {
                                        deliveryOrder: _this.deliveryId ? true : false,
                                        dataFetched: true,
                                        asPerNewEarnings: true,
                                        captainId: _this.order.captainId,
                                        orderServiceName: serviceName_1,
                                        earningsBreakup: _this.autoEarningsMapping,
                                        orderStatus: _this.orderStatus,
                                        orderAcceptPromiseDetails: _this.orderPromiseMapping,
                                        data: {
                                            distance: _this.pricing['distance']['finalDistance'],
                                            totalTime: _this.pricing['amountBreakup']['captain']['timeFare']['quantity'],
                                            waitingTime: _this.pricing['amountBreakup']['captain']['waitTimeCharges']['quantity'],
                                            nightFareTooltip: _this.autoEarningsMapping['orderBreakup']['nightFare'] && _this.orderDetailsHelper.getCaptainTimeBasedSlab(_this.serviceDetail, _this.order.events).value
                                        }
                                    };
                                }
                                else {
                                    var resp = _this.parseCaptainEarningApiResponse(result[0]);
                                    _this.captainInvoiceDetails = {
                                        deliveryOrder: _this.deliveryId ? true : false,
                                        dataFetched: true,
                                        tip: resp['tip'],
                                        asPerNewEarnings: false,
                                        earningsBreakup: resp,
                                        orderStatus: _this.orderStatus,
                                        orderServiceName: serviceName_1,
                                        orderAcceptPromiseDetails: _this.orderPromiseMapping,
                                        data: {
                                            distance: _this.pricing['distance']['finalDistance'],
                                            totalTime: _this.pricing['amountBreakup']['captain']['timeFare']['quantity'],
                                            waitingTime: _this.pricing['amountBreakup']['captain']['waitTimeCharges']['quantity'],
                                            nightFareTooltip: resp['orderBreakup']['nightFare'] && _this.orderDetailsHelper.getCaptainTimeBasedSlab(_this.serviceDetail, _this.order.events).value
                                        }
                                    };
                                }
                            }
                        });
                        if (this.order.orderType) {
                            this.orderType = this.order.orderType;
                            if (this.packageOrderTypes.indexOf(this.orderType) !== -1) {
                                this.showPackageCard = true;
                            }
                        }
                        this.orderCityId = this.order && this.order.orderContext && this.order.orderContext.quintuple &&
                            this.order.orderContext.quintuple.cityId ? this.order.orderContext.quintuple.cityId : '';
                        // order-status details for cust-capt
                        if (this.order.status) {
                            this.orderStatus = this.order.status;
                        }
                        if (this.orderType === 'delivery') {
                            this.orderDetailsService.fetchDetailedOrderEarnings(this.orderId).subscribe(function (result) {
                                var resp = _this.parseCaptainEarningApiResponse(result[0]);
                                _this.captainInvoiceDetails = __assign({}, _this.captainInvoiceDetails, { dataFetched: true, earningsBreakup: resp, tip: resp['tip'], orderStatus: _this.orderStatus });
                            });
                        }
                        if (this.order && this.order.microCorridor) {
                            this.microCorridorDetails = this.order.microCorridor;
                        }
                        this.getTransactionDetails(this.orderId, this.order.customer.id);
                        this.deviceId = this.order && this.order.captain &&
                            this.order.captain.device && this.order.captain.device.deviceId || null;
                        this.isBatchable = this.order.orderMeta.isBatchable || this.isBatchable;
                        this.batchId = this.order.batch && this.order.batch.batchId || null;
                        if (this.isBatchable && this.batchId) {
                            this.orderDetailsService.getBatchingInfo(this.batchId).subscribe(function (result) {
                                if (result && result['data']) {
                                    _this.batchingInfo = JSON.parse(result['data']);
                                    _this.getBatchingDropDown();
                                }
                            }, function (err) {
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'Unable to fetch batching info',
                                }));
                            });
                        }
                        if (this.order.uniqueId) {
                            this.uniqueId = this.order.uniqueId;
                        }
                        this.orderCurrentStatus = this.orderStatus || '';
                        if (this.order.captainId) {
                            this.riderId = this.order.captainId;
                        }
                        else if (this.orderStatus === 'customerCancelled') {
                            events = this.order.events || {};
                            for (_i = 0, events_1 = events; _i < events_1.length; _i++) {
                                event_1 = events_1[_i];
                                if (event_1['eventName'] == 'accepted') {
                                    this.riderId = event_1['captainId'] || this.riderId;
                                    this.captainDetails = event_1['captain'] || null;
                                }
                            }
                        }
                        isExternalOTP = (this.order.otp && this.order.otp.drop && this.order.otp.drop.source === 'external');
                        if (this.order.otp && !isExternalOTP) {
                            this.otp = this.order.otp.pickup && this.order.otp.pickup.otp;
                        }
                        if (this.order.orderMeta) {
                            this.dropOtpStatus = this.order.orderMeta.dropOtpEnabled ? 'ACTIVE' : 'INACTIVE';
                        }
                        if (this.order.otp && this.order.otp.drop) {
                            this.dropOtp = this.order.otp.drop.otp;
                        }
                        if (this.order.events) {
                            this.timeLineDetails = this.order.events;
                        }
                        if (this.order.delivery) {
                            deliveryInfo = this.order.delivery;
                            if (deliveryInfo.config) {
                                this.b2bConfig = deliveryInfo.config;
                            }
                            if (deliveryInfo.clientOrderId) {
                                this.deliveryId = deliveryInfo.clientOrderId;
                            }
                            if (deliveryInfo.clientName) {
                                this.clientName = deliveryInfo.clientName;
                            }
                            if (this.order.delivery.shipmentReadyTimeInEpoch) {
                                this.timeLineDetails.push({
                                    status: 'shipmentReadyTimeInEpoch',
                                    eventName: 'shipmentReadyTimeInEpoch',
                                    updatedAt: this.order.delivery.shipmentReadyTimeInEpoch
                                });
                            }
                            if (this.order.delivery.predictedShipmentReadyTimeInEpoch) {
                                this.timeLineDetails.push({
                                    status: 'predictedShipmentReadyTimeInEpoch',
                                    eventName: 'predictedShipmentReadyTimeInEpoch',
                                    updatedAt: this.order.delivery.predictedShipmentReadyTimeInEpoch
                                });
                            }
                            this.timeLineDetails.sort(function (a, b) { return a.updatedAt > b.updatedAt ? 1 : -1; });
                            this.timeLineDetails = this.filterDuplicateStatus(this.timeLineDetails);
                        }
                        this.isRefreshing = false;
                        if (this.orderId) {
                            this.orderDetailsService.getCorrelationIds(this.orderId).subscribe(function (result) {
                                if (result && result['info'] && result['info'].statusCode && result['info'].statusCode === 200) {
                                    if (result['data'] && result['data'].length > 0) {
                                        for (var data in result['data']) {
                                            if (result['data'][data] && result['data'][data].gatewayId) {
                                                _this.showTxnInfoModal = _this.showTxnInfoModal || ['Razorpay-QR-Code', 'upi', 'Paytm-QR-Code', 'rapido-pg'].includes(result['data'][data].gateway);
                                                _this.correlationIds = _this.correlationIds || [];
                                                _this.correlationIds.push(result['data'][data].gatewayId);
                                                _this.correlationTs = _this.correlationTs || [];
                                                _this.correlationTs.push(_this.getProperDate(result['data'][data].createdAt));
                                            }
                                        }
                                    }
                                }
                            });
                        }
                        if (this.order.fareCorrelationIds && this.order.customer && this.order.customerId
                            && this.serviceDetail && this.serviceDetail._id) {
                            id = this.order.customerId;
                            customer = this.order.customer;
                            wallets = ['rapido', 'paytm', 'simpl', 'freecharge', 'lazypay'];
                            userData = {
                                _id: id,
                                firstName: customer.name,
                                mobile: customer.mobile,
                                email: customer.email,
                                roles: ['customer']
                            };
                            this.customerDetailsService.getCustomerWallets(userData, wallets).subscribe(function (res) {
                                if (res['info'].status === 'FAILURE') {
                                    _this.toasterService.showToaster(new Toaster({
                                        type: ToasterType.NOTIFICATION,
                                        message: "Wallets do not exist for this user.",
                                    }));
                                    _this.walletInfo = { dataFetched: false };
                                }
                                else {
                                    var resp = __assign({}, res, { dataFetched: true });
                                    _this.walletInfo = resp;
                                }
                            }, function (err) {
                                _this.walletInfo = { dataFetched: false };
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: "Unable to fetch wallets for this user. Please try again",
                                }));
                            });
                            this.customerDetailsService.getDebitPreference(userData).subscribe(function (res) {
                                _this.coinPreference = res && res["debitPreference"] ? res["debitPreference"].find(function (preference) { return preference.name === "coin"; }).preference : false;
                            }, function (err) {
                                _this.coinPreference = false;
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: "Unable to fetch debit preference. Please try again",
                                }));
                            });
                            requestIds = this.order.fareCorrelationIds || [];
                            this.orderDetailsService.getFareEstimates(this.serviceDetail._id, requestIds, this.order.customerId).subscribe(function (result) {
                                if (result && result['data'] && result['data'].length > 0) {
                                    _this.fareEstimateLogs = {
                                        dataFetched: true, fareEstimateLogs: result['data'],
                                        deliveryOrder: _this.deliveryId ? true : false
                                    };
                                    _this.mapData = __assign({}, _this.mapData, { dataFetched: true, fareEstimateLogs: result['data'] });
                                    var firstEstimate = result['data'][result['data'].length - 1];
                                    var latestEstimate = result['data'][0];
                                    if (latestEstimate) {
                                        _this.distTime = __assign({}, _this.distTime, { finalEstimatedDistance: latestEstimate.estimatedRoute && !_this.orderDetailsHelper.isEmpty(latestEstimate.estimatedRoute.distance) ? ((latestEstimate.estimatedRoute.distance) / 1000).toFixed(2).toString() : '', odrdDistance: latestEstimate.actualRoute && !_this.orderDetailsHelper.isEmpty(latestEstimate.actualRoute.distance) ? ((latestEstimate.actualRoute.distance) / 1000).toFixed(2).toString() : '' });
                                        if (!_this.orderDetailsHelper.isEmpty(latestEstimate.selectedRoute)) {
                                            if (latestEstimate.selectedRoute === 'estimatedRoute') {
                                                var distanceSuggested = "Final Estimated Distance";
                                                _this.distTime = __assign({}, _this.distTime, { distanceSuggested: distanceSuggested });
                                            }
                                            if (latestEstimate.selectedRoute === 'actualRoute') {
                                                var distanceSuggested = "ODRD (Actual) Distance";
                                                _this.distTime = __assign({}, _this.distTime, { distanceSuggested: distanceSuggested });
                                            }
                                            if (latestEstimate.selectedRoute === 'hFRoute') {
                                                var distanceSuggested = "HF Distance";
                                                _this.distTime = __assign({}, _this.distTime, { distanceSuggested: distanceSuggested });
                                            }
                                        }
                                        else {
                                            _this.distTime = __assign({}, _this.distTime, { distanceSuggested: '' });
                                        }
                                        _this.mapData = __assign({}, _this.mapData, { events: _this.order.events, finalEstimatedDistance: latestEstimate.estimatedRoute && !_this.orderDetailsHelper.isEmpty(latestEstimate.estimatedRoute.distance) ? ((latestEstimate.estimatedRoute.distance) / 1000).toFixed(2).toString() : '', finalEstimatedPolyline: latestEstimate.estimatedRoute && !_this.orderDetailsHelper.isEmpty(latestEstimate.estimatedRoute.polyline) ? latestEstimate.estimatedRoute.polyline : '', finalEstimatedTime: latestEstimate.estimatedRoute && !_this.orderDetailsHelper.isEmpty((latestEstimate.estimatedRoute.time)) ? ((latestEstimate.estimatedRoute.time) / 60).toFixed(2).toString() : '', odrdTime: latestEstimate.actualRoute && !_this.orderDetailsHelper.isEmpty(latestEstimate.actualRoute.time) ? ((latestEstimate.actualRoute.time) / 60).toFixed(2).toString() : '', odrdDistance: _this.distTime.odrdDistance, odrdPolyline: latestEstimate.actualRoute && !_this.orderDetailsHelper.isEmpty(latestEstimate.actualRoute.polyline) ? latestEstimate.actualRoute.polyline : '' });
                                        var ratecardData = latestEstimate.service;
                                        var timeBasedSlab = _this.orderDetailsHelper.getTimeBasedSlab(ratecardData, _this.order.events);
                                        _this.rateCard = __assign({}, _this.rateCard, { baseFare: !_this.orderDetailsHelper.isEmpty(ratecardData.baseFare) ? (ratecardData.baseFare).toString() : '', minimumFare: !_this.orderDetailsHelper.isEmpty(ratecardData.minimumFare) ? (ratecardData.minimumFare).toString() : '', insurance: !_this.orderDetailsHelper.isEmpty(ratecardData.insuranceCharges) ? (ratecardData.insuranceCharges).toString() : '', perKm: !_this.orderDetailsHelper.isEmpty(ratecardData.pricePerKm) ? (ratecardData.pricePerKm).toString() : '', priceWithKm: !_this.orderDetailsHelper.isEmpty(ratecardData.priceWithKm) ? ratecardData.priceWithKm : [], perMin: !_this.orderDetailsHelper.isEmpty(ratecardData.pricePerMinute) ? (ratecardData.pricePerMinute).toString() : '', rule: ratecardData.rule ? ratecardData.rule : '', cancelCharge: !_this.orderDetailsHelper.isEmpty(ratecardData.cancelCharge) ? (ratecardData.cancelCharge).toString() : '', threshold: !_this.orderDetailsHelper.isEmpty(ratecardData.cancelChargeTime) ? (ratecardData.cancelChargeTime).toString() : '', waitTimeConfig: !_this.orderDetailsHelper.isEmpty(ratecardData.waitTimeConfig) ? (ratecardData.waitTimeConfig) : { amount: 0, threshold: 0 }, pickupFare: !_this.orderDetailsHelper.isEmpty(ratecardData.customer.pickupFare) ? ratecardData.customer.pickupFare : [] }, (!_this.orderDetailsHelper.isEmpty(timeBasedSlab.key) ? { timeBasedSlab: timeBasedSlab } : {}));
                                    }
                                    if (firstEstimate) {
                                        _this.distTime = __assign({}, _this.distTime, { estimatedDistance: firstEstimate.direction &&
                                                !_this.orderDetailsHelper.isEmpty(firstEstimate.direction.distanceInKms) ? firstEstimate.direction.distanceInKms.toString() : '', estimatedRideTime: !_this.orderDetailsHelper.isEmpty(firstEstimate.direction.timeInMins) ? firstEstimate.direction.timeInMins.toString() : '' });
                                    }
                                }
                                else {
                                    _this.fareEstimateLogs = { dataFetched: false, fareEstimateLogs: null };
                                }
                            }, function (error) {
                                _this.fareEstimateLogs = { dataFetched: false, fareEstimateLogs: null };
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'Fare estimates couldn\'t be fetched',
                                }));
                            });
                        }
                        else {
                            this.mapData = __assign({}, this.mapData, { fareEstimateLogs: null, dataFetched: true });
                        }
                        if (this.order.customerId) {
                            this.getCustAdjustments(this.order.customerId);
                        }
                        this.getCaptAdjustments(this.order.captainId);
                        if (this.order.customerId && this.order.captainId && this.order.orderTime && this.order.orderTime.date && this.order.captain && this.order.captain.mobile) {
                            this.getOfflineRecharge(this.order.customerId, this.order.captainId, this.order.orderTime.date, this.order.captain.mobile);
                        }
                        // Add dispatch related info. Ex: propagation type
                        if (this.order.dispatch) {
                            this.dispatch = this.order.dispatch;
                        }
                        // cust-details
                        if (this.order.customer) {
                            this.custDetails = {
                                customer: this.order.customer ? this.order.customer : {},
                                deliveryOrder: this.deliveryId ? true : false,
                                dataFetched: true
                            };
                        }
                        else {
                            this.custDetails = {
                                customer: null,
                                dataFetched: true
                            };
                        }
                        // capt-details
                        if (this.order.captain) {
                            this.captDetails = {
                                captain: this.order.captain || null,
                                captainId: this.order.captainId || null,
                                dataFetched: true
                            };
                            this.captainTimelineDetails = {
                                captainId: this.order.captainId || null,
                                status: this.orderStatus || '',
                                abortReason: this.abortReason
                            };
                        }
                        else if (this.captainDetails && this.riderId) {
                            this.captDetails = {
                                captain: this.captainDetails,
                                captainId: this.riderId,
                                dataFetched: true
                            };
                            this.captainTimelineDetails = {
                                captainId: this.riderId,
                                status: this.orderStatus || '',
                                abortReason: this.abortReason
                            };
                        }
                        if (this.order.captain || this.riderId) {
                            this.captainAdjDetails = {
                                rider: this.order.captainId || this.riderId || null,
                                riderObj: this.order.captain || this.captainDetails || null,
                                orderDate: this.order.orderTime && this.order.orderTime.date || null,
                                uniqueId: this.order.uniqueId || null,
                                customer: this.order.customer || '',
                                finalDistance: this.pricing.distance &&
                                    this.pricing.distance.finalDistance ? this.pricing.distance.finalDistance : '',
                                status: this.orderStatus,
                                serviceDetail: this.serviceDetail && this.serviceDetail._id || null,
                                serviceId: this.serviceDetail.service && this.serviceDetail.service._id ||
                                    this.serviceDetail.serviceId || null,
                                service: this.serviceDetail && this.serviceDetail.service &&
                                    this.serviceDetail.service.name || null,
                                dataFetched: true
                            };
                        }
                        else {
                            if (this.orderStatus != 'customerCancelled') {
                                this.captDetails = {
                                    rider: null, riderObj: null,
                                    dataFetched: true
                                };
                            }
                            this.captainTimelineDetails = {
                                rider: null, status: this.orderStatus || '',
                                dataFetched: false
                            };
                            this.captainAdjDetails = {
                                rider: null, riderObj: null, orderDate: null, uniqueId: null,
                                customer: null, finalDistance: null, dataFetched: false,
                                status: this.orderStatus,
                                serviceDetail: null, serviceId: null, service: null
                            };
                        }
                        if (this.pricing.distance) {
                            this.orderDistance = this.pricing.distance;
                        }
                        // Card 3
                        // email invoice
                        if (this.order.customerId) {
                            this.emailInvoice = { userId: this.order.customerId, orderId: this.orderId };
                        }
                        // rate card
                        if (this.serviceDetail) {
                            this.serviceObj = this.serviceDetail;
                            data = this.serviceObj;
                            this.rateCard.city = data.city ? data.city : '';
                            this.rateCard.serviceName = data.service ? data.service : '';
                            data.captain.regular = __assign({}, data.captain.regular, this.newCaptainRateCard);
                            this.captainRateCard = data.captain ? __assign({ city: data.city ? data.city : '', serviceName: data.service ? data.service : '' }, data.captain.regular) : this.rateCard;
                            if (this.order.orderType == 'hire' && this.order.hirePackageId) {
                                customerPackages = data.customer.package;
                                captainPackages = data.captain.package;
                                chosenPackageIndex = customerPackages.packageDetails.findIndex(function (packageDetail) { return packageDetail._id == _this.order.hirePackageId; });
                                chosenCustomerPackage = customerPackages.packageDetails[chosenPackageIndex];
                                chosenCaptainPackage = captainPackages.packageDetails[chosenPackageIndex];
                                this.rateCard.perKm = customerPackages.pricePerKm;
                                this.rateCard.perMin = customerPackages.pricePerMinute;
                                this.rateCard.baseFare = chosenCustomerPackage.price;
                                this.rateCard.minimumFare = chosenCustomerPackage.price;
                                this.rateCard.priceWithKm = [];
                                this.rateCard.waitTimeConfig = customerPackages.waitTimeConfig || { amount: 0, threshold: 0 };
                                this.rateCard.package = chosenCustomerPackage.duration + ' min ' + chosenCustomerPackage.distance + ' km';
                                this.rateCard.rule = customerPackages.tnc && customerPackages.tnc.length > 0 ? customerPackages.tnc.join('\n') : this.rateCard.rule;
                                this.captainRateCard.perKm = captainPackages.pricePerKm;
                                this.captainRateCard.pricePerMinute = captainPackages.pricePerMinute;
                                this.captainRateCard.baseFare = chosenCaptainPackage.price;
                                this.captainRateCard.minimumFare = chosenCaptainPackage.price;
                                this.captainRateCard.priceWithKm = [];
                                this.captainRateCard.waitTimeConfig = captainPackages.waitTimeConfig || { amount: 0, threshold: 0 };
                                this.captainRateCard.package = chosenCaptainPackage.duration + ' min ' + chosenCaptainPackage.distance + ' km';
                            }
                            nightFareInfoFromRateCardApi = this.orderDetailsHelper.getCaptainTimeBasedSlabFromRateCardApi(this.captainRateCard, this.order.events);
                            if (nightFareInfoFromRateCardApi && Object.keys(nightFareInfoFromRateCardApi).length !== 0) {
                                this.captainRateCard.nightFareTooltip = nightFareInfoFromRateCardApi.value;
                            }
                        }
                        customerIdForInvoice = void 0;
                        if (this.order.customerId) {
                            customerIdForInvoice = this.order.customerId;
                        }
                        this.invoiceDetails = __assign({}, this.invoiceDetails, { deliveryOrder: this.deliveryId ? true : false, orderStatus: this.orderStatus || '', customerId: customerIdForInvoice });
                        // cashback
                        if (this.pricing.cashBack && this.pricing.cashBack.length) {
                            cashBackObj = this.pricing.cashBack;
                            totalCashBack = 0;
                            for (cashback in cashBackObj) {
                                if (cashBackObj[cashback] && cashBackObj[cashback].ownerType === 'customer') {
                                    totalCashBack += cashBackObj[cashback].cashBackAmount;
                                }
                            }
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { cashback: totalCashBack.toString() });
                        }
                        // cash to be collected
                        if (this.payment.collected && !this.orderDetailsHelper.isEmpty(this.payment.collected['cash']) &&
                            this.payment.collected['cash'] >= 0) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { cashToBeCollected: this.payment.collected['cash'].toString() });
                        }
                        // subtotal
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.subTotal) && this.pricing.subTotal >= 0) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { subTotal: this.pricing.subTotal.toString() });
                        }
                        // cancelFee
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.cancelFee) && this.pricing.cancelFee >= 0) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { cancelFee: this.pricing.cancelFee.toString() || '' });
                        }
                        // previous due
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.prevDue) && this.pricing.prevDue >= 0) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { prevDue: this.pricing.prevDue.toString() });
                        }
                        // Non-zero wallet
                        if (this.payment.collected) {
                            walletDetails = this.payment.collected, nonZeroWalletDetails = {};
                            wallets = Object.keys(walletDetails);
                            nonZeroWallet = void 0;
                            for (wallet in wallets) {
                                if (wallets[wallet] && walletDetails[wallets[wallet]]) {
                                    nonZeroWallet = wallets[wallet];
                                    nonZeroWalletDetails[nonZeroWallet] = walletDetails[nonZeroWallet] ? walletDetails[nonZeroWallet].toString() : '';
                                }
                            }
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { collected: nonZeroWalletDetails });
                        }
                        if (this.order.captainId && this.order.orderTime && this.order.orderTime.date) {
                            this.getTipDetails(orderResponse);
                        }
                        if (this.payment.mode) {
                            this.paymentMode = this.payment.mode;
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { paymentMode: this.payment.mode, dataFetched: true });
                        }
                        if (this.pricing.discount) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { discount: this.pricing.discount, dataFetched: true });
                        }
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.amount)) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { amount: (this.pricing.amount.toFixed(2)).toString(), dataFetched: true });
                        }
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.rateCardAmount)) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { rateCardAmount: (this.pricing.rateCardAmount.toFixed(2)).toString(), dataFetched: true });
                        }
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.rateCardAmountWithSurge)) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { dataFetched: true, rateCardAmountWithSurge: (this.pricing.rateCardAmountWithSurge.toFixed(2)).toString() });
                        }
                        if (Array.isArray(this.order.events)) {
                            requestedAt = this.order.events.find(function (u) { return u.status === 'requested'; });
                            if (requestedAt && requestedAt.payment && requestedAt.payment.mode) {
                                this.invoiceDetails = __assign({}, this.invoiceDetails, { initialPaymentMode: requestedAt.payment.mode, dataFetched: true });
                            }
                        }
                        amountBreakup = void 0;
                        amountBreakup = this.pricing.amountBreakup || null;
                        if (this.pricing.offer && this.pricing.offer.applied && this.pricing.offer.code) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { dataFetched: true, offer: {
                                    code: this.pricing.offer && this.pricing.offer.code || null,
                                    desc: this.pricing.offerText || '' //todo - offerText missing in ordersv2
                                } });
                        }
                        if (amountBreakup) {
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { baseFare: amountBreakup.baseFare && !this.orderDetailsHelper.isEmpty(amountBreakup.baseFare.total) ?
                                    amountBreakup.baseFare.total.toString() : '', distanceFare: amountBreakup.distanceFare && !this.orderDetailsHelper.isEmpty(amountBreakup.distanceFare.total) ?
                                    amountBreakup.distanceFare.total.toString() : '', timeFare: amountBreakup.timeFare && !this.orderDetailsHelper.isEmpty(amountBreakup.timeFare.total) ?
                                    amountBreakup.timeFare.total.toString() : '', nightFare: amountBreakup.captain && amountBreakup.captain.timeBasedCharges && !this.orderDetailsHelper.isEmpty(amountBreakup.captain.timeBasedCharges.total) && amountBreakup.captain.timeBasedCharges.total > 0 ?
                                    amountBreakup.captain.timeBasedCharges.total.toString() : '', insurance: amountBreakup.insuranceCharges && !this.orderDetailsHelper.isEmpty(amountBreakup.insuranceCharges.total) ?
                                    amountBreakup.insuranceCharges.total.toString() : '', dynamicSurge: amountBreakup.surge && !this.orderDetailsHelper.isEmpty(amountBreakup.surge.total) ?
                                    amountBreakup.surge.total.toString() : '', staticSurge: amountBreakup.staticSurge && !this.orderDetailsHelper.isEmpty(amountBreakup.staticSurge.total) ?
                                    amountBreakup.staticSurge.total.toString() : '', dynamicFare: amountBreakup.dynamicFare && !this.orderDetailsHelper.isEmpty(amountBreakup.dynamicFare.total) ?
                                    amountBreakup.dynamicFare.total.toString() : '', waitTimeConfig: amountBreakup.waitTimeCharges || { unit: 0, quantity: 0, total: 0 }, pickupFare: amountBreakup.pickupFare && !this.orderDetailsHelper.isEmpty(amountBreakup.pickupFare.total) ?
                                    amountBreakup.pickupFare.total.toString() : '', tollFare: amountBreakup.tollFare && !this.orderDetailsHelper.isEmpty(amountBreakup.tollFare.total) ?
                                    amountBreakup.tollFare.total.toString() : '', bidDelta: amountBreakup.bidDelta && !this.orderDetailsHelper.isEmpty(amountBreakup.bidDelta.total) ?
                                    amountBreakup.bidDelta.total.toString() : '', parkingCharges: amountBreakup.parkingCharges && !this.orderDetailsHelper.isEmpty(amountBreakup.parkingCharges.total) ?
                                    amountBreakup.parkingCharges.total.toString() : '' });
                            if (amountBreakup.hasOwnProperty("timeBasedCharges") && amountBreakup.timeBasedCharges.total > 0) {
                                this.invoiceDetails = __assign({}, this.invoiceDetails, { timeBasedCharges: {
                                        key: amountBreakup.timeBasedCharges.key,
                                        total: amountBreakup.timeBasedCharges.total.toString()
                                    } });
                            }
                            totalDiscount = 0;
                            if (amountBreakup.hasOwnProperty("perceptionDiscount") && amountBreakup.perceptionDiscount.total && amountBreakup.perceptionDiscount.total > 0) {
                                this.invoiceDetails = __assign({}, this.invoiceDetails, { perceptionDiscount: {
                                        key: amountBreakup.perceptionDiscount.key,
                                        total: amountBreakup.perceptionDiscount.total.toString()
                                    } });
                                totalDiscount += amountBreakup.perceptionDiscount.total;
                            }
                            if (amountBreakup.hasOwnProperty("discount") && amountBreakup.discount.total && amountBreakup.discount.total > 0) {
                                this.invoiceDetails = __assign({}, this.invoiceDetails, { discount: {
                                        key: amountBreakup.discount.key,
                                        total: amountBreakup.discount.total.toString()
                                    } });
                                totalDiscount += amountBreakup.discount.total;
                            }
                            this.invoiceDetails = __assign({}, this.invoiceDetails, { totalDiscount: totalDiscount });
                        }
                        // distance-and-time
                        if (this.pricing.distance) {
                            pricingDetails = this.pricing;
                            this.distanceDetails = pricingDetails.distance;
                            finalDistance = this.distanceDetails.finalDistance ? (parseFloat(this.distanceDetails.finalDistance).toFixed(2)).toString() : '0';
                            hfDistance = this.distanceDetails.hfDistance ? (parseFloat(this.distanceDetails.hfDistance).toFixed(2)).toString() : '0';
                            this.distTime = __assign({}, this.distTime, { dataFetched: true, finalDistance: finalDistance });
                            this.mapData = __assign({}, this.mapData, { finalDistance: hfDistance });
                            this.distanceDetails['orderAmount'] = pricingDetails.amount;
                        }
                        if (this.distanceDetails && !this.orderDetailsHelper.isEmpty(this.distanceDetails.hfDistance)) {
                            this.distTime = __assign({}, this.distTime, { hfDistance: (this.distanceDetails.hfDistance.toFixed(2)).toString(), dataFetched: true });
                        }
                        if (!this.orderDetailsHelper.isEmpty(this.pricing.rideTime)) {
                            finalRideTime = (this.pricing.rideTime.toFixed(2)).toString();
                            this.distTime = __assign({}, this.distTime, { finalRideTime: finalRideTime, dataFetched: true });
                            this.mapData = __assign({}, this.mapData, { finalRideTime: finalRideTime });
                        }
                        if (this.order.pickupLocation && this.order.pickupLocation.cluster) {
                            this.distTime = __assign({}, this.distTime, { pickupCluster: {
                                    name: this.order.pickupLocation.cluster
                                }, dataFetched: true });
                        }
                        if (this.order.dropLocation && this.order.dropLocation.cluster) {
                            this.distTime = __assign({}, this.distTime, { dropCluster: {
                                    name: this.order.dropLocation.cluster
                                }, dataFetched: true });
                        }
                        if (!this.orderDetailsHelper.isEmpty(this.order.acceptToPickupDistance)) {
                            this.distTime = __assign({}, this.distTime, { acceptToPickup: ((this.order.acceptToPickupDistance / 1000).toFixed(2)).toString(), dataFetched: true });
                        }
                        if (this.cancellationInformation &&
                            !this.orderDetailsHelper.isEmpty(this.cancellationInformation.acceptToCancelled)) {
                            this.distTime = __assign({}, this.distTime, { acceptToCancel: ((this.cancellationInformation.acceptToCancelled).toFixed(2)).toString(), dataFetched: true });
                        }
                        // mapped-riders
                        if (this.order.mappedCaptains) {
                            this.mapRiderDetails = {
                                mappedRiderId: this.order.mappedCaptains,
                                dataFetched: true
                            };
                        }
                        else {
                            this.mapRiderDetails = {
                                mappedRiderId: null,
                                dataFetched: true
                            };
                        }
                        // order-logs
                        if (this.order.events) {
                            this.orderLogDetails = { orderLogs: this.order.events, dataFetched: true };
                            this.mapData = __assign({}, this.mapData, { orderLogPoints: this.order.events });
                            this.fareEstimateLogs = __assign({}, this.fareEstimateLogs, { orderLogs: this.order.events });
                        }
                        else {
                            this.orderLogDetails = { orderLogs: null, dataFetched: true };
                        }
                        // customer-adjustemnt
                        if (this.order.customer && this.order.customerId) {
                            this.customerAdjDetails = {
                                customerId: this.order.customerId, customer: this.order.customer,
                                uniqueId: this.order.uniqueId, orderAmount: this.pricing.amount,
                                deliveryOrder: this.deliveryId ? true : false, status: this.orderStatus,
                                dataFetched: true
                            };
                        }
                        // custActHistory
                        if (this.order.uniqueId) {
                            this.custActHistory = {
                                dataFetched: true,
                                id: this.order._id,
                                userId: this.order.customerId || ''
                            };
                        }
                        // rating
                        if (this.order.feedback) {
                            this.ratingDetails = {
                                feedback: this.order.feedback,
                                deliveryOrder: this.deliveryId ? true : false,
                                dataFetched: true,
                                status: this.orderStatus
                            };
                        }
                        else {
                            this.ratingDetails = {
                                feedback: null,
                                deliveryOrder: this.deliveryId ? true : false,
                                status: this.orderStatus,
                                dataFetched: true
                            };
                        }
                        return [4 /*yield*/, this.checkDropOrderEligibility()];
                    case 3:
                        _a.sent();
                        pickupOtp = this.order.otp && this.order.otp.pickup || {};
                        dropOtp = this.order.otp && this.order.otp.drop || {};
                        if (this.orderType === 'bfs' && this.order.packageDelivery) {
                            // Book from store package delivery (bfs)
                            this.packageDetails = {
                                orderType: this.orderType,
                                bfs: this.order.packageDelivery || this.order.bfs
                            };
                            this.packageDetails['pickup'] = this.order.pickupLocation || {};
                            this.packageDetails['drop'] = this.order.dropLocation || {};
                            this.packageDetails['otp'] = pickupOtp.otp || null;
                            this.packageDetails['dropOtp'] = dropOtp.otp || null;
                            this.packageDetails['orderId'] = this.orderId;
                            this.packageDetails['orderType'] = this.orderType;
                            this.packageDetails['orderValue'] = this.order.packageDelivery.orderValue;
                            this.packageDetails.pickupOtp = this.otp || null;
                            this.packageDetails.dropOtpStatus = this.dropOtpStatus;
                        }
                        else if (this.order.packageDelivery) {
                            this.packageDetails = {
                                orderType: this.orderType,
                                c2c: this.order.packageDelivery
                            };
                            this.packageDetails['pickup'] = this.order.pickupLocation || {};
                            this.packageDetails['drop'] = this.order.dropLocation || {};
                            this.packageDetails['otp'] = pickupOtp.otp || null;
                            this.packageDetails['dropOtp'] = dropOtp.otp || null;
                            this.packageDetails['orderId'] = this.orderId;
                            this.packageDetails['orderType'] = this.orderType;
                            this.packageDetails.pickupOtp = this.otp || null;
                            this.packageDetails.dropOtpStatus = this.dropOtpStatus;
                        }
                        else if (this.orderType === 'delivery') {
                            this.orderDetailsService.fetchB2bOrderDetails(this.orderId).subscribe(function (result) {
                                if (result && result['body']) {
                                    var details = void 0;
                                    try {
                                        details = JSON.parse(result['body']);
                                        if (details) {
                                            var deliveryOrderData = details.data || {};
                                            _this.packageDetails = { orderType: _this.orderType, data: __assign({}, _this.order, deliveryOrderData) };
                                            if (details.data && details.data.updatedAt) {
                                                var lastStatus = details.data.updatedAt[details.data.updatedAt.length - 1].status;
                                                if (lastStatus === 'cancelRequested' || lastStatus === 'incorrectlyDropped') {
                                                    _this.FNDCase = details.data.updatedAt[details.data.updatedAt.length - 1];
                                                }
                                                var otpMeta = details.data.otpMeta;
                                                if (otpMeta && otpMeta.started && otpMeta.started.otp) {
                                                    _this.otp = otpMeta.started.otp;
                                                }
                                                _this.packageDetails.pickupOtp = _this.otp || null;
                                                _this.packageDetails.dropOtpStatus = _this.dropOtpStatus;
                                            }
                                        }
                                    }
                                    catch (err) {
                                        _this.packageDetails = {};
                                    }
                                }
                            }, function (error) {
                                _this.packageDetails = {};
                            });
                        }
                        // re-assign details
                        this.reAssignDetails = __assign({}, this.reAssignDetails, { orderType: this.orderType ? this.orderType : '', serviceType: this.serviceDetail && this.serviceDetail._id ?
                                this.serviceDetail._id : '', customerId: this.order.customer ? this.order.customer.id : '', pickUpLocation: this.order.pickupLocation ? this.order.pickupLocation : '', dropLocation: this.order.dropLocation ? this.order.dropLocation : '' });
                        return [3 /*break*/, 5];
                    case 4:
                        this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: 'Unable to fetch order details',
                        }));
                        _a.label = 5;
                    case 5:
                        if (this.pageRefreshed && this.order) {
                            this.pageRefreshed = false;
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.NOTIFICATION,
                                message: 'Page refreshed successfully',
                            }));
                        }
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: 'Unable to find this order. Please re-check the orderId'
            }));
            _this.custDetails = _this.errorResponse;
            _this.captDetails = _this.errorResponse;
            _this.invoiceDetails = _this.errorResponse;
            _this.captainInvoiceDetails = _this.errorResponse;
            _this.rateCard = _this.errorResponse;
            _this.captainRateCard = _this.errorResponse;
            _this.distTime = _this.errorResponse;
            _this.mapRiderDetails = _this.errorResponse;
            _this.ratingDetails = _this.errorResponse;
            _this.orderLogDetails = _this.errorResponse;
            _this.custActHistory = _this.errorResponse;
            _this.captAdjustments = _this.errorResponse;
            _this.custAdjustments = _this.errorResponse;
            _this.customerAdjDetails = _this.errorResponse;
            _this.captainAdjDetails = _this.errorResponse;
            _this.orderTimeLine = _this.errorResponse;
        });
    };
    OrderDetailsComponent.prototype.parseOrderAcceptResponse = function (promiseObj, captainId) {
        var _this = this;
        if (promiseObj.length > 0) {
            promiseObj.forEach(function (element) {
                if (element.captainId === captainId) {
                    if (element && element.promise && element.promise.earnings) {
                        _this.orderPromiseMapping.totalEarning = element.promise.earnings.total ? _this.orderDetailsHelper.getAmountValue(element.promise.earnings.total) : 0;
                        _this.orderPromiseMapping.orderEarning = element.promise.earnings.amountWithoutExtra ? _this.orderDetailsHelper.getAmountValue(element.promise.earnings.amountWithoutExtra) : 0;
                        _this.orderPromiseMapping.extraEarning = element.promise.earnings.extra && element.promise.earnings.extra.amount ? _this.orderDetailsHelper.getAmountValue(element.promise.earnings.extra.amount) : 0;
                        return _this.orderPromiseMapping;
                    }
                }
            });
        }
        return this.orderPromiseMapping;
    };
    OrderDetailsComponent.prototype.fetchRequiredEventFields = function () {
        var events = this.order.events || [];
        for (var event_2 in events) {
            if (event_2['abortReason']) {
                this.abortReason = event_2['abortReason'];
            }
            if (event_2['cancellationInformation'] && event_2['cancellationInformation'].acceptToCancelled) {
                this.cancellationInformation = event_2['cancellationInformation'];
            }
        }
    };
    OrderDetailsComponent.prototype.getInvoiceDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var invoiceDetails, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.orderDetailsService.fetchInvoiceReceiptDetails(this.orderId).toPromise()];
                    case 1:
                        invoiceDetails = _a.sent();
                        if (invoiceDetails) {
                            this.invoiceDetailsMapping = invoiceDetails;
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.invoiceDetailsMapping = "";
                        console.log('An error occured while fetching invoice - ' + error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailsComponent.prototype.getNewCaptainRateCard = function () {
        return __awaiter(this, void 0, void 0, function () {
            var ratecards, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.orderDetailsService.fetchCaptainRateCard(this.orderId).toPromise()];
                    case 1:
                        ratecards = _a.sent();
                        if (ratecards && Array.isArray(ratecards)) {
                            this.newCaptainRateCard = ratecards[0];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.log('An error occured while fetching captain rate card - ' + error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderDetailsComponent.prototype.getTipDetails = function (orderResponse) {
        if (orderResponse && orderResponse.payment && orderResponse.payment.tip) {
            var tip = orderResponse.payment.tip.amount;
            this.invoiceDetails = __assign({}, this.invoiceDetails, { tip: tip });
        }
    };
    OrderDetailsComponent.prototype.getTransactionDetails = function (orderId, custId) {
        var _this = this;
        var walletTransactionSuceessStatus = 'done';
        this.customerTransactionDetailsService
            .getCustomerPassbookDetails(custId, 1, 0, custId, { title: 'order', orderId: orderId, transactionStatus: walletTransactionSuceessStatus })
            .subscribe(function (resp) {
            //@ts-ignore
            if (resp && resp.data && resp.data && resp.data.data && resp.data.data.length > 0) {
                //@ts-ignore
                var txn = resp.data.data[0];
                _this.invoiceDetails = __assign({}, _this.invoiceDetails, { walletChangeObjects: txn.walletChangeObjects, walletChangeReason: txn.walletChangeReason });
            }
        }, function (err) {
            console.log("Unable to fetch the transaction details", err);
        });
    };
    OrderDetailsComponent.prototype.getCustAdjustments = function (id) {
        var _this = this;
        this.orderDetailsService.getCustomerAdjustments([this.orderId], id).subscribe(function (result) {
            if (result && result['info'] && result['info'].status === 'success') {
                _this.custAdjustments = { dataFetched: true, data: result['data'] };
            }
        });
        this.orderDetailsService.getPrevDuesTransactions(id).subscribe(function (result) {
            if (result && result["data"] && result["data"]["response"]) {
                _this.prevDuesAdjustments = { dataFetched: true, data: _this.getPrevDuesAdjustments(result["data"]["response"]) };
            }
        });
    };
    OrderDetailsComponent.prototype.getPrevDuesAdjustments = function (transactions) {
        var _this = this;
        return transactions.outstandingBalanceTransactions.filter(function (ele) { return ele.orderId == _this.orderId && ele.type == "debit"; });
    };
    OrderDetailsComponent.prototype.getCaptAdjustments = function (id) {
        var _this = this;
        this.orderDetailsService.getCaptainAdjustments(this.orderId, id).subscribe(function (result) {
            if (result && result['info'] && result['info'].status === 'success') {
                _this.captAdjustments = { dataFetched: true, data: result['data'] };
            }
        });
    };
    OrderDetailsComponent.prototype.getOfflineRecharge = function (customerId, riderId, orderDate, riderMobile) {
        var _this = this;
        this.orderDetailsService.getOfflineRecharge(customerId, riderId, orderDate)
            .subscribe(function (result) {
            var offlineRechargeInfo = result.data;
            if (!offlineRechargeInfo) {
                _this.offlineRechargeInfo = {};
                return;
            }
            // this.offlineRechargeInfo = result.data;
            var formattedofflineRechargeInfo = {
                action: "Recharged \u20B9" + offlineRechargeInfo.amountRequested,
                change: offlineRechargeInfo.initiator,
                reason: 'Offline Recharge',
                timeStamp: offlineRechargeInfo.timestamp || '-',
                adjustedBy: riderMobile,
            };
            _this.offlineRechargeInfo = formattedofflineRechargeInfo;
        });
    };
    OrderDetailsComponent.prototype.getBatchingDropDown = function () {
        var batchedOrders = this.batchingInfo && this.batchingInfo['data'] && this.batchingInfo['data'].orders || [];
        this.batchingOrderDetails = [];
        if (batchedOrders && batchedOrders.length) {
            for (var order in batchedOrders) {
                if (batchedOrders[order]) {
                    this.batchingOrderDetails.push({
                        id: batchedOrders[order]['orderId'],
                        status: this.statusTextMapping[batchedOrders[order]['orderStatus']],
                        statusClass: this.statusClassMapping[batchedOrders[order]['orderStatus']]
                    });
                }
            }
        }
    };
    OrderDetailsComponent.prototype.parseCaptainEarningApiResponse = function (response) {
        try {
            return __assign({ firstMile: {
                    fmFare: response.earnings.breakUp.firstMileFare ? response.earnings.breakUp.firstMileFare.amount : 0.0,
                    fmFareEnabled: response.earnings.breakUp.firstMileFare ? true : false
                }, lastMile: {
                    lmFare: response.earnings.breakUp.lastMileFare ? response.earnings.breakUp.lastMileFare.amount : 0.0,
                    lmFareEnabled: response.earnings.breakUp.lastMileFare ? true : false
                }, pickupFare: response.earnings.breakUp.pickupFare ? response.earnings.breakUp.pickupFare.amount : 0.0 }, (response.earnings.breakUp.cancellationEligibilityDetails) && {
                cancelationEligibility: {
                    cancelationEligibility: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.cancellationEligibility : true,
                    dailyLimitExceeded: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.dailyLimitExceeded : false,
                    weeklyLimitExceeded: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.weeklyLimitExceeded : false,
                    cancelationEligibilityCurrentOrder: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.cancellationEligibilityCurrentOrder : true,
                    cancelationEligibilityReason: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.cancellationEligibilityReason : 'Conditions Not Met'
                },
                cancelationFare: {
                    amount: response.earnings.breakUp.cancellationFare ? response.earnings.breakUp.cancellationFare.amount : 0,
                    remarks: response.earnings.breakUp.cancellationFare ? response.earnings.breakUp.cancellationFare.details.remarks : 'NA',
                },
            }, { tip: response.earnings.breakUp.tip ? response.earnings.breakUp.tip.amount : 0.0, orderBreakup: {
                    surgeBreakup: {
                        totalSurge: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.amount ? response.earnings.breakUp.surgeFare.amount : 0.0,
                        dynamicSurge: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.details.dynamicSurge ? response.earnings.breakUp.surgeFare.details.dynamicSurge : 0.0,
                        dynamicFare: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.details.dynamicFare ? response.earnings.breakUp.surgeFare.details.dynamicFare : 0.0,
                        staticSurge: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.details.staticSurge ? response.earnings.breakUp.surgeFare.details.staticSurge : 0.0
                    },
                    preCommissionOrderEarning: response.earnings.preCommissionAmount,
                    totalOrderEarning: response.earnings.amount,
                    orderEarning: response.earnings.orderEarning,
                    platformCharges: response.earnings.breakUp.platformCharges ? response.earnings.breakUp.platformCharges.amount : 0.0,
                    timeFare: response.earnings.breakUp.timeFare ? response.earnings.breakUp.timeFare.amount : 0.0,
                    baseFare: response.earnings.breakUp.baseFare ? response.earnings.breakUp.baseFare.amount : 0.0,
                    distanceFare: response.earnings.breakUp.distanceFare ? response.earnings.breakUp.distanceFare.amount : 0.0,
                    waitingFare: response.earnings.breakUp.waitingFare ? response.earnings.breakUp.waitingFare.amount : 0.0,
                    minimumFare: response.earnings.breakUp.minimumFare ? response.earnings.breakUp.minimumFare.amount : 0.0,
                    cashCollected: response.cashCollected ? response.cashCollected : 0.0,
                    nightFare: response.earnings.breakUp.nightFare ? response.earnings.breakUp.nightFare.amount : 0.0,
                    adjustments: "+\u20B9" + this.getCompressedBreakup(response.earnings.breakUp.adjustments),
                    penalty: "-\u20B9" + this.getCompressedBreakup(response.earnings.breakUp.penalty),
                    tds: "-\u20B9" + this.getCompressedBreakup(response.earnings.breakUp.tdsPenalty),
                    tollFare: response.earnings.breakUp.tollFare ? response.earnings.breakUp.tollFare.amount : 0.0,
                    bidDelta: response.earnings.breakUp.bidDelta,
                    parkingCharges: response.earnings.breakUp.parkingCharges ? response.earnings.breakUp.parkingCharges.amount : 0.0,
                    activeUpiId: response.activeUpiId ? response.activeUpiId : '',
                } });
        }
        catch (err) {
            return {};
        }
    };
    // to render array fields liks adjustment, penalty for general earnings
    OrderDetailsComponent.prototype.getCompressedBreakup = function (item) {
        if (item && Array.isArray(item) && item.length > 0) {
            var itemValue = this.orderDetailsHelper.getCompressedBreakup(item).amount;
            return Math.abs(itemValue);
        }
        else {
            return this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(item));
        }
    };
    OrderDetailsComponent.prototype.goToOrderPage = function (id) {
        var url = this.router.serializeUrl(this.router.createUrlTree(["/order/" + id]));
        window.open(url, '_blank');
    };
    OrderDetailsComponent.prototype.popupClick = function (event) {
        this.dialog.open(PaymentStatusComponent, {
            width: '900vw',
            maxHeight: '405px',
            data: {
                title: "Payment Status",
                info: {
                    gatewayIds: this.correlationIds
                }
            },
        });
    };
    OrderDetailsComponent.prototype.getCommonEarningDetails = function (orderEarnings, paymentType, prevDue) {
        if (orderEarnings.cashCollected) {
            var prevDueValue = '';
            if (prevDue > 0) {
                prevDueValue = "" + this.orderDetailsHelper.getAmountValue(prevDue);
            }
            var cashCollectedValue = this.orderDetailsHelper.getAmountValue(orderEarnings.cashCollected);
            this.newOrderMapping.cashCollected = cashCollectedValue;
            this.newOrderMapping.prevDue = prevDueValue;
        }
        // add wallet money data
        if (orderEarnings.walletAmount) {
            var walletAmount = orderEarnings.walletAmount;
            this.newOrderMapping.moneyDeductedValue = walletAmount >= 0 ? "+" + Math.abs(walletAmount) : "-" + Math.abs(walletAmount);
            this.newOrderMapping.moneyDeductedKey = walletAmount >= 0 ? this.orderDetailsHelper.orderDetailsMapping.REDEEM_WALLET_CREDITED : this.orderDetailsHelper.orderDetailsMapping.REDEEM_WALLET_DEBITED;
            this.newOrderMapping.isAmountDeducted = walletAmount >= 0 ? false : true;
        }
        // add total earnings amount
        if (orderEarnings.earnings && !isNaN(orderEarnings.earnings.amount)) {
            var totalEarnings = Number(this.orderDetailsHelper.getRoundedAmount(orderEarnings.earnings.amount));
            this.newOrderMapping.total = totalEarnings;
        }
        if (this.newOrderMapping.total && this.newOrderMapping.cashCollected && this.newOrderMapping.cashCollected.value) {
            this.newOrderMapping.amountToGetInWallet = Number(this.orderDetailsHelper.getRoundedAmount(this.newOrderMapping.total - orderEarnings.cashCollected));
        }
        this.newOrderMapping.paymentType = paymentType;
        return this.newOrderMapping;
    };
    OrderDetailsComponent.prototype.parseCaptainNewEarningApiResponse = function (orderId, orderStatus, orderEarnings, invoiceReceipt, paymentType, prevDue) {
        var _this = this;
        this.newOrderMapping = this.getCommonEarningDetails(orderEarnings, paymentType, prevDue);
        var earningsBreakup = [];
        var breakUp = orderEarnings.earnings.breakUp;
        var minimumFareAmount = this.orderDetailsHelper.getAmount(breakUp.minimumFare);
        var baseFareAmount = this.orderDetailsHelper.getAmount(breakUp.baseFare);
        var distanceFareAmount = this.orderDetailsHelper.getAmount(breakUp.distanceFare);
        var timeFareAmount = this.orderDetailsHelper.getAmount(breakUp.timeFare);
        var distanceFareInfoLabel = this.orderDetailsHelper.getLabelWithInfo(breakUp.distanceFare);
        var TimeFareInfoLabel = this.orderDetailsHelper.getLabelWithInfo(breakUp.timeFare);
        var platformFee = breakUp.customerPlatformCharges ? breakUp.customerPlatformCharges : { amount: 0 };
        var conveyanceFee = JSON.parse(JSON.stringify(platformFee));
        var basicFare = baseFareAmount + distanceFareAmount + timeFareAmount;
        var cancellation = breakUp[this.orderDetailsHelper.orderDetailsMapping.cancellationFareKey];
        //Commision & Taxes
        var taxes = this.orderDetailsHelper.getTaxCollected(invoiceReceipt);
        var commission = breakUp.platformCharges.amount ? parseFloat(breakUp.platformCharges.amount) : 0;
        var taxesFromCaptain = breakUp.platformCharges.details.totalTax ? parseFloat(breakUp.platformCharges.details.totalTax) : 0;
        var taxRebate = parseFloat(String(-taxesFromCaptain - taxes.value));
        conveyanceFee.amount += taxRebate;
        if (!breakUp.platformCharges.details.totalTax) {
            conveyanceFee.amount = 0;
        }
        var commissionWithoutTax = (commission == 0) ? 0 : (-commission - taxes.value - taxRebate);
        var totalCommissionTaxes = ((taxes.value ? taxes.value : 0) + commissionWithoutTax + this.orderDetailsHelper.getAmount(conveyanceFee));
        var tdsPenalty = breakUp[this.orderDetailsHelper.orderDetailsMapping.tdsPenaltyKey];
        var orderFareTotal = ((minimumFareAmount > basicFare) ? minimumFareAmount : basicFare) + this.orderDetailsHelper.getAmount(platformFee);
        /**
           * In case there is cancellation fare then no need to check other calculations
        */
        if (cancellation && this.orderDetailsHelper.getAmount(cancellation) > 0 && orderStatus == "customerCancelled") {
            this.newOrderMapping.orderFare = this.orderDetailsHelper.getAmountValue(0);
            this.newOrderMapping.cancelationFare = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(cancellation));
            this.newOrderMapping.commisionTaxes = this.orderDetailsHelper.getAmountValue(0);
        }
        else {
            /**
             * else perform complere following calculation
            */
            this.newOrderMapping.orderFare = this.orderDetailsHelper.getAmountValue(orderFareTotal.toFixed(2));
            if (minimumFareAmount > (baseFareAmount + distanceFareAmount + timeFareAmount)) {
                this.newOrderMapping.minimumFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.minimumFare));
                if (platformFee) {
                    this.newOrderMapping.platformFee = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(platformFee).toFixed(2));
                }
            }
            else {
                this.newOrderMapping.distanceFareInfoLabel = distanceFareInfoLabel.extraInfo;
                this.newOrderMapping.TimeFareInfoLabel = TimeFareInfoLabel.extraInfo;
                this.newOrderMapping.baseFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.baseFare).toFixed(2)),
                    this.newOrderMapping.timeFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.timeFare).toFixed(2)),
                    this.newOrderMapping.distanceFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.distanceFare).toFixed(2)),
                    this.newOrderMapping.platformFee = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.customerPlatformCharges).toFixed(2));
            }
            var totalExtraFare = this.orderDetailsHelper.getAmount(breakUp[this.orderDetailsHelper.orderDetailsMapping.surgeKey]) +
                this.orderDetailsHelper.getAmount(breakUp[this.orderDetailsHelper.orderDetailsMapping.firstMileFareKey]) +
                this.orderDetailsHelper.getAmount(breakUp[this.orderDetailsHelper.orderDetailsMapping.pickupFareKey]);
            if (totalExtraFare > 0) {
                this.newOrderMapping.extraFareLabel = 'Extra Fare';
                this.newOrderMapping.extraFare = this.orderDetailsHelper.getAmountValue(+totalExtraFare.toFixed(2));
                this.newOrderMapping.surgeFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp['surgeFare']));
                if (breakUp['pickupFare']) {
                    this.newOrderMapping.pickupFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp['pickupFare']));
                }
                if (breakUp['firstMileFare']) {
                    var longPickUpInfoLabel = this.orderDetailsHelper.getLabelWithInfo(breakUp['firstMileFare']);
                    this.newOrderMapping.longPickUpInfoLabel = longPickUpInfoLabel.extraInfo;
                    this.newOrderMapping.longPickupFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp['firstMileFare']));
                }
            }
            if (tdsPenalty) {
                var amount = this.orderDetailsHelper.getAmount(this.orderDetailsHelper.getCompressedBreakup(tdsPenalty));
                totalCommissionTaxes = totalCommissionTaxes + Math.abs(amount);
            }
            this.newOrderMapping.commissionTaxes = totalCommissionTaxes ? "-" + this.orderDetailsHelper.getAmountValue(+totalCommissionTaxes.toFixed(2)) : 0;
            this.newOrderMapping.rapidoCommission = commissionWithoutTax ? "-" + this.orderDetailsHelper.getAmountValue(+commissionWithoutTax.toFixed(2)) : 0;
            this.newOrderMapping.gst = taxes && taxes.value ? "-" + this.orderDetailsHelper.getAmountValue(taxes.value) : 0;
            if (conveyanceFee) {
                var fee = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(conveyanceFee).toFixed(2));
                if (fee.startsWith('-')) {
                    fee = fee.substring(1);
                }
                this.newOrderMapping.conveyanceFee = "-" + fee;
            }
            if (tdsPenalty) {
                this.newOrderMapping.tdsPenalty = "" + this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getCompressedBreakup(tdsPenalty).amount);
            }
            if (breakUp.nightFare) {
                this.newOrderMapping.nightFare = breakUp.nightFare ? breakUp.nightFare.amount : 0.0;
            }
            // other breakup
            if (breakUp.adjustments) {
                if (Array.isArray(breakUp.adjustments) && breakUp.adjustments.length > 0) {
                    var breakupArray = breakUp.adjustments;
                    var adjustmentValue = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getCompressedBreakup(breakupArray).amount);
                    this.newOrderMapping.adjustments = adjustmentValue;
                }
                else {
                    this.newOrderMapping.adjustments = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(breakUp.adjustments));
                }
            }
            if (breakUp.penalty) {
                if (Array.isArray(breakUp.penalty) && breakUp.penalty.length > 0) {
                    var breakupArray = breakUp.penalty;
                    var penaltyValue = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getCompressedBreakup(breakupArray).amount);
                    this.newOrderMapping.penalty = penaltyValue;
                }
                else {
                    this.newOrderMapping.penalty = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(breakUp.penalty));
                }
            }
            if (breakUp.tip) {
                if (Array.isArray(breakUp.tip) && breakUp.tip.length > 0) {
                    var breakupArray = breakUp.tip;
                    var breakupSpreadArray = this.orderDetailsHelper.getSpreadBreakup(breakupArray);
                    breakupSpreadArray.forEach(function (breakupItem) {
                        var value = _this.orderDetailsHelper.getRoundedAmount(breakupItem.amount);
                        _this.newOrderMapping.tip = _this.orderDetailsHelper.getAmountValue(value);
                    });
                }
                else {
                    this.newOrderMapping.tip = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(breakUp.tip));
                }
            }
            this.newOrderBreakup.orderBreakup = this.newOrderMapping;
            return this.newOrderBreakup;
        }
    };
    OrderDetailsComponent.prototype.getCancellationBreakUp = function (cancellation) {
        this.newOrderMapping.orderFare = this.orderDetailsHelper.getAmountValue(0);
        this.newOrderMapping.cancelationFare = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(cancellation)),
            this.newOrderMapping.commisionTaxes = this.orderDetailsHelper.getAmountValue(0);
        return this.newOrderMapping;
    };
    OrderDetailsComponent.prototype.checkDropOrderEligibility = function () {
        return __awaiter(this, void 0, void 0, function () {
            var isPartialDrop;
            return __generator(this, function (_a) {
                isPartialDrop = this.geoFence && this.geoFence.drop && this.geoFence.drop.validation && this.geoFence.drop.validation.status === "failed" ? true : false;
                if (this.paymentMode === "cash" && this.orderStatus === "started" && (this.orderType === "auto" || this.orderType === "app") && isPartialDrop) {
                    this.isDropOrderEnabled = true;
                }
                else {
                    this.isDropOrderEnabled = false;
                }
                return [2 /*return*/];
            });
        });
    };
    return OrderDetailsComponent;
}());
export { OrderDetailsComponent };
