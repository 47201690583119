import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';


@Injectable()
export class EventService {

    constructor(
        public httpClient: HttpClient
    ) {
    }

    getCaptainBulkActionLogs(captainId: any, page: number, perPage: number) {
        const url = `${environment.server}/api/eventLogs/bulkActionEvent/logs/${captainId}/${page}/${perPage}`;
        return this.httpClient.get(url);
    }
}
