<div class="boxed-border">
  <div class="rideMapBlank">
    <div class="row">
      <div class="col-md-3 toggles padding-0">
        <div class="row title margin-9-16">
          <div class="color-card-header">Ride Tracker Map</div>
        </div>
        <div class="line"></div>
        <div class="toggle-div">
          <div *ngFor="let option of slideOptions">
              <div class="padding-10-10-0-0 row">
                <div class="col-md-10">
                  <div class="row">
                    <span class="map-title font-size-14">{{option.title}}</span>
                  </div>
                  <div class="row map-info" *ngIf="option.distance || option.time" [ngClass]="{'color-blue': option.color, 'align-on-ends': true}">
                    <div class="map-time font-size-14" *ngIf="option.time">Time : {{option.time}}</div>
                    <div class="font-size-14" *ngIf="option.distance">Distance : {{option.distance}}</div>
                  </div>
                  <div class="row map-info" *ngIf="option.distance2 || option.time2" [ngClass]="{'color-purple': option.color2, 'align-on-ends': true}">
                    <div class="map-time font-size-14" *ngIf="option.time2">Time : {{option.time2}}</div>
                    <div class="font-size-14" *ngIf="option.distance2">Distance : {{option.distance2}}</div>
                  </div>
                </div>
                <div class="col-md-2 floating-toggle">
                  <p-inputSwitch [disabled]="option.disabled" class="map-toggle" (onChange)="changeInOption(option.title)" [(ngModel)]="selectedOptions[option.title]"></p-inputSwitch>
                </div>
              </div>
            <div *ngIf="option.addLine" class="line"></div>
          </div>
          <div *ngIf="tollEnabled">
            <app-toll-info [entryTollLines]="cityEntryTollLines" [exitTollLines]="cityExitTollLines" [tolls]="cityTolls"></app-toll-info>
        </div>
        </div>
      </div>
      <div class="col-md-9 padding-0 actualMap">
        <button (click)="openFullView()" class="fullViewButton">
          <img src="../../assets/full-view-map.png" width="10px" height="10px"> Full size
        </button>
        <button *ngIf="showCaptainLocationRefresh" (click)="getCaptLiveLocation()" class="refresh-live-loc">
            <fa-icon class="cursor-pointer refresh-location" [icon]="faRefresh"></fa-icon>
            Capt Live
        </button>
        <!--The div element for the map -->
        <div #map style="width:100%;height:330px"></div>
      </div>
    </div>
  </div>
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">Modal Header</h4>
        </div>
        <div class="modal-body">
          <p>Some text in the modal.</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
  
    </div>
  </div>
</div>