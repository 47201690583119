<div *ngIf="!dataLoaded">
  <bullet-list-content-loader></bullet-list-content-loader>
  <bullet-list-content-loader></bullet-list-content-loader>
</div>
<div *ngIf="dataLoaded" class="boxed-border">
  <div class="row">
    <div class="col">
      <div class="title">{{info.header}}</div>
    </div>
    <div class="col">
      <div class="row pull-right">
        <div class="bordered-button no-padding {{info.buttonClass}}" *ngFor="let button of info.buttons" (click)="emitSelection(button)">
          <div class="margin-sides-10 cursor-pointer"> {{button}} </div>
        </div>
        <div *ngIf="info.ellipsis">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="emitSelection(menu)" *ngFor="let menu of info.ellipsis" mat-menu-item>
              <span>{{menu}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div *ngIf="info.dataFetched" class="scrollable-y height-250">
    <div *ngFor="let info of info.data">
      <div class="rows row changeOnHover">
        <div *ngIf="info.className" class="svgIcon {{info.className}}"></div>
        <div *ngIf="info.iconPath">
          <img class="{{info.iconClass}}" src={{info.iconPath}}>
        </div>
          <div>
            <div class="headings">
              {{info.header}}
            </div>
            <div *ngIf="!info.fullText" class="values clip-text {{info.valueClass}}" [attr.title]="info.value">
              {{info.value || 'N/A'}}
            </div>
            <div *ngIf="info.fullText" class="values {{info.valueClass}}" [attr.title]="info.value">
              {{info.value || 'N/A'}}
            </div>
          </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
  <div *ngIf="!info.dataFetched" class="height-250 padding-top-90 center-content is-flex"> 
    No data found for this user ¯\_(ツ)_/¯
  </div>
</div>