import { Component, OnInit, Input, Output, EventEmitter,OnChanges, SimpleChanges } from '@angular/core';
import {Table, Pagination, TableData, TableDataTypes} from 'src/app/shared/types';
import {MatDialog} from '@angular/material/dialog';
import {OrderService} from '../../order-service.service';
import {Router} from '@angular/router';
import {HelperService} from '../../helper.service';

@Component({
  selector: 'app-captain-propagation-breaches',
  templateUrl: './captain-propagation-breaches.component.html',
  styleUrls: ['./captain-propagation-breaches.component.css']
})
export class CaptainPropagationBreachesComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() captainObjectId: string;
  @Input() captainId: string;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  propagationEvents: any[];
  propagationMetricTable: Table;

  constructor(
    public dialog: MatDialog,
    public orderService: OrderService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.definePropagationMetricTable();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  private declareTable(pageNumber = 0) {
    this.propagationMetricTable = new Table({
      tableHeader: {
        toString: () => {
          return `FM Breach Details`;
        }
      },
      headers: {
        date: 'Date',
        orderId: 'OrderId',
        sourcingLocation: 'Sourcing\nLocation',
        sourcingLocationTime: 'Sourcing\nLocation\nTime',
        sourcingSystemTime: 'Sourcing\nSystem\nTime',
        hex: 'Sourcing\nPropagation\nHex',
        propagationLocation: 'Propagation\nLocation',
        propagationLocationTime: 'Propagation\nLocation\nTime',
        propagationSystemTime: 'Propagation\nSystem\nTime',
        threshold: 'FM Threshold',
        fmBreach: 'FM Breach',
      },
      selectedHeader: 9,
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.propagationMetricTable = null;
        this.definePropagationMetricTable();
      },
      data: []
    });
  }

  onPaginationChange(event) {
    this.propagationMetricTable.data = [];
    this.fillDataToTable();
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  definePropagationMetricTable() {
      this.declareTable();
      this.fillDataToTable();
  }

  private getPropagationMetricEventsForFMBreach(captainId: string) {
    return this.orderService.getPropagationMetricEventsForCaptain(captainId, 'first_mile_breached', this.propagationMetricTable.pagination);
  }

  fillDataToTable() {
    this.propagationMetricTable.dataWillLoad();

    function getSourcingLatLng(captainLocation) {
      if (captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.coordinate
          && captainLocation.atSourcing.coordinate.lat && captainLocation.atSourcing.coordinate.lng) {
        return captainLocation.atSourcing.coordinate.lat + '\n' + captainLocation.atSourcing.coordinate.lng
      }
      return ''
    }

    function getBookingAckLatLng(captainLocation) {
      if (captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.coordinate
          && captainLocation.atBookingAck.coordinate.lat && captainLocation.atBookingAck.coordinate.lng) {
        return captainLocation.atBookingAck.coordinate.lat + '\n' + captainLocation.atBookingAck.coordinate.lng
      }
      return ''
    }

    function getSourcingLocationUpdateAt(captainLocation) {
      if(captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.updatedAt) {
        const date = new Date(captainLocation.atSourcing.updatedAt);
        return HelperService.dateString(date) + '\n' + date.toLocaleTimeString()
      }
      return '';
    }

    function getSourcingHex(captainLocation) {
      return captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.hex8
    }

    function getSourcingLocationSystemUpdateAt(captainLocation) {
      if(captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.systemUpdatedAt) {
        const date = new Date(captainLocation.atSourcing.systemUpdatedAt)
        return HelperService.dateString(date) + '\n' + date.toLocaleTimeString()
      }
      return '';
    }

    function getBookingAckLocationUpdateAt(captainLocation) {
      if(captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.updatedAt) {
        const date = new Date(captainLocation.atBookingAck.updatedAt)
        return HelperService.dateString(date) + '\n' + date.toLocaleTimeString()
      }
      return ''
    }

    function getBookingAckLocationSystemUpdateAt(captainLocation) {
      if(captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.systemUpdatedAt) {
        const date = new Date(captainLocation.atBookingAck.systemUpdatedAt);
        return HelperService.dateString(date) + '\n' + date.toLocaleTimeString()
      }
      return '';
    }

    function getBookingAckLocationHex(captainLocation) {
      return captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.hex8
    }

    function getFMThreshold(captainThreshold) {
      if(captainThreshold && captainThreshold.firstMileDistanceInMetres){
        return captainThreshold.firstMileDistanceInMetres.value + '\n' + captainThreshold.firstMileDistanceInMetres.source
      }
    }

    function getFMBreach(captainFirstMile, captainThreshold) {
      const firstMileThreshold = captainThreshold && captainThreshold.firstMileDistanceInMetres
      if(captainFirstMile && captainFirstMile.value && firstMileThreshold && firstMileThreshold.value){
        return (captainFirstMile.value - firstMileThreshold.value) + 'm\n' + captainFirstMile.provider
      }
    }

    this.getPropagationMetricEventsForFMBreach(this.captainObjectId).subscribe(async({events, count}) => {
      const self = this;
      function createTableData(value, className?, hoverData?, flag = false, suspendFlag = false) {
        return new TableData({
          data: value,
          hoverData: hoverData ? hoverData : null,
          type: TableDataTypes.DATA,
          className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
          onClick: (data) => {
            const newRelativeUrl = self.router.createUrlTree([`/order/${data.orderId}`]);
            const baseUrl = window.location.href.replace(self.router.url, '');
            window.open(baseUrl + newRelativeUrl, '_blank');
          }
        });
      }

      this.propagationEvents = events.map(event => {
        const orderId = event.orderId;
        const sourcingLocation = getSourcingLatLng(event.captainLocation)
        const bookingAckLocation = getBookingAckLatLng(event.captainLocation)
        const sourcingLocationUpdatedAt = getSourcingLocationUpdateAt(event.captainLocation)
        const sourcingLocationSystemUpdatedAt = getSourcingLocationSystemUpdateAt(event.captainLocation)
        const bookingAckLocationUpdatedAt = getBookingAckLocationUpdateAt(event.captainLocation)
        const bookingAckLocationSystemUpdatedAt = getBookingAckLocationSystemUpdateAt(event.captainLocation)
        const sourcingHex = getSourcingHex(event.captainLocation)
        const bookingAckHex = getBookingAckLocationHex(event.captainLocation)
        const fmThreshold = getFMThreshold(event.captainThreshold)
        const fmBreach = getFMBreach(event.firstMileDistanceInMetres, event.captainThreshold)
        const self = this;
        return {
          date: createTableData(event.orderDate),
          orderId: createTableData(orderId),
          sourcingLocation: createTableData(sourcingLocation),
          sourcingLocationTime: createTableData(sourcingLocationUpdatedAt),
          sourcingSystemTime:  createTableData(sourcingLocationSystemUpdatedAt),
          hex: createTableData(sourcingHex +'\n '+bookingAckHex),
          propagationLocation:  createTableData(bookingAckLocation),
          propagationLocationTime: createTableData(bookingAckLocationUpdatedAt),
          propagationSystemTime: createTableData(bookingAckLocationSystemUpdatedAt),
          threshold: createTableData(fmThreshold),
          fmBreach: createTableData(fmBreach),
        };
      });

      this.propagationMetricTable.data = this.propagationEvents;
      this.propagationMetricTable.pagination.count = count;
    });
  }
}
