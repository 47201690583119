/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quick-filters.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../calendar/calendar.component.ngfactory";
import * as i4 from "../calendar/calendar.component";
import * as i5 from "./quick-filters.component";
var styles_QuickFiltersComponent = [i0.styles];
var RenderType_QuickFiltersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuickFiltersComponent, data: {} });
export { RenderType_QuickFiltersComponent as RenderType_QuickFiltersComponent };
function View_QuickFiltersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFilterClick($event, _v.context.index, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "quick-filter": 0, "active-filter": 1 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, (_co.selectedFilter == _v.context.index)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
export function View_QuickFiltersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "qucik-filters-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickFiltersComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "calendar", [], null, [[null, "selectedDates"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDates" === en)) {
        var pd_0 = (_co.getSelectedDateRanges($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CalendarComponent_0, i3.RenderType_CalendarComponent)), i1.ɵprd(512, null, i2.DatePipe, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(5, 638976, null, 0, i4.CalendarComponent, [i2.DatePipe], { calendarData: [0, "calendarData"], fromDate: [1, "fromDate"], toDate: [2, "toDate"] }, { selectedDates: "selectedDates" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.quickFilters; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.calendarData; var currVal_2 = _co.startDate; var currVal_3 = _co.endDate; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_QuickFiltersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "quick-filters", [], null, null, null, View_QuickFiltersComponent_0, RenderType_QuickFiltersComponent)), i1.ɵprd(512, null, i2.DatePipe, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 49152, null, 0, i5.QuickFiltersComponent, [i2.DatePipe], null, null)], null, null); }
var QuickFiltersComponentNgFactory = i1.ɵccf("quick-filters", i5.QuickFiltersComponent, View_QuickFiltersComponent_Host_0, { calendarData: "calendarData", startDate: "startDate", endDate: "endDate" }, { setQuickFilters: "setQuickFilters" }, []);
export { QuickFiltersComponentNgFactory as QuickFiltersComponentNgFactory };
