<div class = "wrapper">
    <div *ngIf="isCorporateOrder; then corporateTitle else nonCorporateTitle"></div>
    <ng-template #corporateTitle>
        <div class = "invoice-header">Statement of work</div>
    </ng-template>
    <ng-template #nonCorporateTitle>
        <div class = "invoice-header">Tax Invoice</div>
    </ng-template>
    <div class = "invoice-id">{{rideId}}</div>
    <div class = "bill-info-wrap">
        <div *ngIf="isCorporateOrder; then statementBlock else invoiceBlock"></div>
        <ng-template #statementBlock>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Statement No.</div>
                <div class = "invoice-value">{{invoiceNumber}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Statement Date</div>
                <div class = "invoice-value">{{invoiceDate}}</div>
            </div>
        </ng-template>
        <ng-template #invoiceBlock>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Invoice No.</div>
                <div class = "invoice-value">{{invoiceNumber}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Invoice Date</div>
                <div class = "invoice-value">{{invoiceDate}}</div>
            </div>
        </ng-template>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">State</div>
            <div class = "invoice-value">{{state}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider">
            <div class = "invoice-label">Tax Category</div>
            <div class = "invoice-value">{{riderTaxCategory}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">Place of Supply</div>
            <div class = "invoice-value">{{placeOfSupply}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">GST</div>
            <div class = "invoice-value">{{gstTin}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">Captain Name</div>
            <div class = "invoice-value">{{captainName}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider">
            <div class = "invoice-label">Vehicle Number</div>
            <div class = "invoice-value">{{bikeNumber}}</div>
        </div>

        <div *ngIf="isCorporateOrder; then corporateBlock else nonCorporateBlock"></div>
        <ng-template #corporateBlock>
            <div class="invoice-info-wrap">
                <div class="invoice-label">Corporate Name</div>
                <div class="invoice-value">{{clientName}}</div>
            </div>
            <div class="invoice-info-wrap">
                <div class="invoice-label">Corporate GST Number</div>
                <div class="invoice-value">{{merchantGstNumber}}</div>
            </div>
            <div class="invoice-info-wrap">
                <div class="invoice-label">Corporate Address</div>
            </div>
            <div class="address">{{merchantAddress}}</div>
        </ng-template>
        <ng-template #nonCorporateBlock>
            <div *ngIf="service === 'sme'; then merchantBlock else customerBlock"></div>
            <ng-template #customerBlock>
                <div  class = "invoice-info-wrap">
                    <div class = "invoice-label">Customer Name</div>
                    <div class = "invoice-value">{{customerName}}</div>
                </div>
                <div class = "invoice-info-wrap">
                    <div class = "invoice-label">Customer Pick up Address</div>
                </div>
                <div class = "address">{{pickupLocationAddress}}</div>
            </ng-template>
            <ng-template #merchantBlock>
                <div  class = "invoice-info-wrap">
                    <div class = "invoice-label">Merchant Name</div>
                    <div class = "invoice-value">{{merchantName}}</div>
                </div>
                <div  class = "invoice-info-wrap">
                    <div class="invoice-label">Merchant GST Number</div>
                    <div class="invoice-value">{{merchantGstNumber}}</div>
                </div>
                <div class = "invoice-info-wrap">
                    <div class = "invoice-label">Merchant Address</div>
                </div>
                <div class = "address">{{merchantAddress}}</div>
            </ng-template>
        </ng-template>
    </div>

    <div class = "billdetails-wrap">
        <div class = "invoice-header">Bill Details</div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">Captain Fee</div>
            <div class = "invoice-value">{{captainFee}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">CGST {{cgst}}</div>
            <div class = "invoice-value">{{cgstVal}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">SGST {{sgst}}</div>
            <div class = "invoice-value">{{sgstVal}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider">
            <div class = "invoice-label">IGST {{igst}}</div>
            <div class = "invoice-value">{{igstVal}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div *ngIf="['c2c', 'sme', 'bfs'].includes(service); else rideBlock" class = "invoice-header">Courier Charges</div>
            <ng-template #rideBlock>
                <div class = "invoice-header">Ride Charge</div>
            </ng-template>
            <div class = "invoice-header">{{total}}</div>
        </div>
        <div class = "invoice-terms">(Inclusive of Taxes)</div>
    </div>
</div>