import { Injectable } from '@angular/core';
import { CUSTOMER_REFUND_ACCESS, CUSTOMER_DEBIT_ACCESS,
  CAPTAIN_REFUND_ACCESS, CAPTAIN_DEBIT_ACCESS, REFUND, DEBIT, CAPTAIN_UPDATE_KM,
  CUSTOMER, RIDER , UPDATE_KM } from './roles.constants';

@Injectable({
  providedIn: 'root'
})
export class RoleBasedAccessService {

  public accessRoles;
  constructor() { }

  async performAdjustment(transactionType, user) {
    this.accessRoles = this.findRoles('Roles');
    let maxAdjAmount;
    if (user === CUSTOMER) {
      if (transactionType === REFUND) {
        maxAdjAmount = await this.checkRoles(CUSTOMER_REFUND_ACCESS);
      } else {
        maxAdjAmount = await this.checkRoles(CUSTOMER_DEBIT_ACCESS);
      }
    } else {
      if (transactionType === REFUND) {
        maxAdjAmount = await this.checkRoles(CAPTAIN_REFUND_ACCESS);
      } else if (transactionType === DEBIT) {
        maxAdjAmount = await this.checkRoles(CAPTAIN_DEBIT_ACCESS);
      } else {
        maxAdjAmount = await this.checkRoles(CAPTAIN_UPDATE_KM);
      }
    }
    return maxAdjAmount;
  }

  async checkRoles(roleBasedObj) {
    const loggedInUserRoles = this.accessRoles.split(',');
    const roleLimits = [];
    for (const index in loggedInUserRoles) {
      if (loggedInUserRoles[index] && roleBasedObj[loggedInUserRoles[index]]) {
        roleLimits.push(roleBasedObj[loggedInUserRoles[index]]);
      }
    }
    const amount = await this.findMaxAmount(roleLimits);
    return amount;
  }

  findMaxAmount(amountList) {
    return Math.max(...amountList);
  }

  findRoles(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }
}
