import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';
import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute) {}
  canActivate(): boolean {
    const url = HelperService.getCurrentPath();
    if (!this.auth.isAuthenticated()) {
      this.auth.redirectUrl = url;
      HelperService.redirect(environment.oauth);
      // this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
