<div *ngIf="!dataLoaded">
  <bullet-list-content-loader></bullet-list-content-loader>
  <bullet-list-content-loader></bullet-list-content-loader>
  <!-- <bullet-list-content-loader></bullet-list-content-loader> -->
</div>
<div *ngIf="dataLoaded" class="boxed-border">
  <div class="title row"> <span class="{{info.headerClass}}" [ngClass]="info.headerClass">{{info.header}}</span>
    <span *ngIf="info.customTooltip">
        <ng-template #tipContent>
          <div [innerHTML]="info.customTooltip"></div> 
        </ng-template>
          <i  data-toggle="tooltip" placement="bottom" tooltipClass="preserve-whitespace rate-card-tooltip" [ngbTooltip]="tipContent" class="material-icons-outlined info cursor-pointer">
          info
        </i>
    </span>
    <div *ngIf="info.rightHeader" 
        class="redirect pull-right" 
        style="text-align: right; color: blue"
        (click)="buttonClicked(info.rightHeader)">
        {{info.rightHeader}}
    </div>
    <div *ngIf="info.reload" class="pull-right" style="color: black">
      <fa-icon class="cursor-pointer" (click)="refreshData()" [icon]="faRefresh"></fa-icon>
    </div>
    <div *ngIf="info.button" class="pull-right" style="color: black">
      <button (click)="buttonClicked(info.button)" class="card-table-button">{{info.button}}</button>
    </div>
  </div>
  <div class="line"></div>
  <div *ngIf="info.dataFetched" class="scrollable-y height-250">
    <table class="card-rows no-border-top border-bottom-line" style="height: 50px"  *ngFor="let info of info.data">
        <!-- border=1 frame=hsides rules=rows -->
        <tr class="table-rows changeOnHover">
          <th *ngIf="!info.isExpansion" class="left-align padding-10 padding-left-16 is-flex">
            <span class="{{info.headerClass}}" [ngClass]="info.headerClass">{{info.header}} {{info.hasFailed}}</span>
            <span *ngIf="info.information" style="height: auto" class="icon-alignment" matTooltip="{{info.information}}" [matTooltipPosition]="'after'">
              <i class="material-icons-outlined card-info cursor-pointer">
                  info
              </i>
            </span> 
          </th>
          <td [ngSwitch]="info.type" class="right-align padding-10" *ngIf="!info.hasFailed && !info.isExpansion">
            <div *ngSwitchCase="'toggle'">
              <mat-slide-toggle color="primary" checked="{{info.checked}}" disabled="{{info.disabled}}" (change)="infoToggled($event)">
              </mat-slide-toggle>
            </div>
            <div *ngSwitchDefault>
              <span *ngIf="info.inr && info.value" class="font-size-16">
                &#x20b9;
              </span>
              <span class="{{info.className}}" [ngClass]="info.redirectClass" (click)="redirectRoute(info)">{{info.value || 'N/A'}}</span>
            </div>
          </td>
          <td *ngIf="info.isExpansion">
            <mat-expansion-panel class="matExpansionPanel">
              <mat-expansion-panel-header class="cardTableExpansionHeader">
                <div class="my-panel header-card-color right-align">{{info.matExpansionPanelHeader}}
                <td class="mat-expansion-td right-align">
                    <span class="{{info.className}}" [ngClass]="info.redirectClass" (click)="redirectRoute(info)">{{info.value}}</span>
                </td>
                </div> 
              </mat-expansion-panel-header>
              <div class="horizontal-rule"></div>
                <div class="cardTableExpansion" *ngFor="let content of info.matExpansionPanelContent; index as i; first as isFirst">
                      <div class="mat-expansion-td left-align">{{content.header}}
                        <div *ngIf="content.isInfo" class="customExpansionToolTip">
                          <span style="height: auto" class="icon-alignment" matTooltip={{content.toolTip}} [matTooltipPosition]="'after'">
                            <i class="material-icons-outlined card-info cursor-pointer">
                                info
                            </i>
                          </span> 
                        </div>
                      </div> 
                      <td class="mat-expansion-td right-align">
                        <span class="{{info.className}}" [ngClass]="info.redirectClass" (click)="redirectRoute(info)">{{content.value}}</span>
                    </td>
                </div>
                <div class="horizontal-rule"></div>
            </mat-expansion-panel>
        </td>
          <td class="padding-10" *ngIf="info.hasFailed">
            <span *ngIf="info.hasFailed" style="color: blue; display: flex; justify-content: flex-end; font-size: 12px;">
              {{info.numFailed}} failed modes
              <ng-template #tipContentPayment>
                <div [innerHTML]="info.customTooltip"></div> 
              </ng-template>
              <span style="display: flex; justify-content: flex-end;"  data-toggle="tooltip" placement="bottom" tooltipClass="preserve-whitespace" [ngbTooltip]="tipContentPayment" class="material-icons-outlined info cursor-pointer">
                {{info.hasFailed}}
              </span>
            </span>
          <span class="{{info.className}}" [ngClass]="info.redirectClass" (click)="redirectRoute(info)" style="margin-right: -22px;">{{info.value || 'N/A'}}</span>
        </td>
        </tr>
    </table>
  </div>
  <div *ngIf="!info.dataFetched" class="height-250 padding-top-90 center-content is-flex"> 
    {{info.emptyMessage || 'No data found ¯\_(ツ)_/¯'}}
  </div>
</div>