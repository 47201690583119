/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./horizontal-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../table/table.component.ngfactory";
import * as i3 from "../table/table.component";
import * as i4 from "../../filter.service";
import * as i5 from "@angular/common";
import * as i6 from "./horizontal-list.component";
var styles_HorizontalListComponent = [i0.styles];
var RenderType_HorizontalListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HorizontalListComponent, data: {} });
export { RenderType_HorizontalListComponent as RenderType_HorizontalListComponent };
function View_HorizontalListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(1, 638976, null, 0, i3.TableComponent, [i4.FilterService], { table: [0, "table"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.list == null) ? null : _co.list.table); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HorizontalListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HorizontalListComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.list && _co.list.table); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HorizontalListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-horizontal-list", [], null, null, null, View_HorizontalListComponent_0, RenderType_HorizontalListComponent)), i1.ɵdid(1, 114688, null, 0, i6.HorizontalListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HorizontalListComponentNgFactory = i1.ɵccf("app-horizontal-list", i6.HorizontalListComponent, View_HorizontalListComponent_Host_0, { list: "list" }, {}, []);
export { HorizontalListComponentNgFactory as HorizontalListComponentNgFactory };
