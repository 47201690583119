<head>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>
  <div (click)="clear()" class="clear-all">Clear All</div>
  <h1 mat-dialog-title class="dialog-title margin-0" [ngStyle]="{'color': 'black'}">{{data.title}}</h1>

  <div class="line"></div>
  
  <div style="margin-top: 16px">
      <div>
        <searchable-dropdown
          [dropdownData]="dropDownFraudType" [selectedValues]="component?.types"
          (selectedDropDowns)="captureSelectedOptions($event)"
        ></searchable-dropdown>
    </div>
    <div>
      <searchable-dropdown
        [dropdownData]="dropDownFraudTags" [selectedValues]="component?.tags"
        (selectedDropDowns)="captureSelectedOptions($event)"
      ></searchable-dropdown>
    </div>
    <div>
      <searchable-dropdown
        [dropdownData]="dropDownFraudCause" [selectedValues]="component?.causes"
        (selectedDropDowns)="captureSelectedOptions($event)"
      ></searchable-dropdown>
    </div>
  </div>
  
  <div class="row button-flexbox">
    <div *ngIf="allowDelete" class="cursor-pointer delete font-size-13 font-weight-600" (click)="deleteFrauds()">Delete</div>
    <div class="cursor-pointer save font-size-13 font-weight-600" (click)="updateFrauds()">Save</div>
  </div>