import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { TimeLineService } from './timeline-view.service';
import { RoleBasedAccessService } from '../role-based-access.service';
import { CAPTAIN_REASSIGN, CAPTAIN_ABORT } from '../roles.constants';
import { environment } from 'src/environments/environment';
import { OrderActivityLogComponent } from '../order-activity-log/order-activity-log.component';
import * as moment from 'moment';
import { OrderDetailsService } from '../order-details/order-details.service';
import { RideTrackerMapService } from '../ride-tracker-map/ride-tracker-map.service';

@Component({
  selector: 'app-timeline-view',
  templateUrl: './timeline-view.component.html',
  styleUrls: ['./timeline-view.component.css']
})

export class TimelineViewComponent implements OnInit, OnChanges {

  @Input() pickupLocation: any;
  @Input() captainDetails: any;
  @Input() customerDetails: any;
  @Input() timeLineDetails: any;
  @Input() orderTimeLineStatus: any;
  @Input() statusMap: any;
  @Input() reAssignDetails: any;
  @Input() isBatchable: any;
  @Input() deviceId: any;
  @Input() dropOTP: any;
  @Input() serviceObj: any;
  @Input() dispatch: any;
  @Input() FNDCase: any;
  @Input() b2bConfig: any;
  @Input() microCorridorDetails: any;
  @Input() isDropOrderEnabled: boolean;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  public viewMore = true;
  public viewDetails = 'Show less';
  public isReassignable = false;
  public abortRide = true;
  public cancelRide = true;
  public showTimeline = true;
  public fraudTags = [];
  public orderTags = [];
  public abortReason: any;
  public abortedBy: any;
  public aborted = false;
  public orderTimeLine: any;
  public currentStatus: any;
  public propagationType: String;
  public dataFetched = true;
  public statusRed = ['Customer cancelled', 'Rider rejected', 'Expired', 'Aborted'];
  public eventName = [
    'requested', 'accepted', 'new', 'onTheWay', 'accepted', 'arrived', 'started', 'dropped', 'expired',
    'captain_cancelled', 'customer_cancelled', 'captain_rejected', 'captain_switch', 
    'reached', 'aborted', 'captains_mapped', 'shipmentReadyTimeInEpoch','predictedShipmentReadyTimeInEpoch'
  ];
  public notAllowedToAbortStatus = [
    'aborted', 'dropped', 'customerCancelled', 'expired',
    'new', 'requested', 'captainCancelled'
  ];
  public notAllowedToCancelStatus = [
    'aborted', 'dropped', 'customerCancelled', 'expired',
    'new', 'requested', 'captainCancelled'
  ];
  public reAssignStatus = ['onTheWay', 'arrived']; // can re-assing during this status
  servicesAllowedToArrive = [];
  servicesAllowedToDrop = [];
  service;
  allowStatusChangeFrom = {
    'On the way': false,
    'Reached': false,
    'Started': false,
    'Arrived' : false
  };
  orderId: any;
  roles =  this.accessCookie('Roles').split(',');
  perPage: Number = 20;
  pageNumber: Number = 1;
  logs: any;
  microCorridor: any;
  serviceType: any;
  city: string;
  constructor( private toasterService: ToasterService,
               public dialog: MatDialog,
               private route: ActivatedRoute,
               private timeLineService: TimeLineService,
               private orderDetailsService: OrderDetailsService,
               private rideTrackerMapService: RideTrackerMapService,
               private roleBasedAccess: RoleBasedAccessService) { }

  ngOnInit() {
    this.orderId = this.route.snapshot.params.id;
    this.setDispatchValues(this.dispatch);
    this.changeInStatus();
    this.fetchTags();
    this.getOrderActivityLogs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.changeInStatus();
      if (changes.dispatch && changes.dispatch.currentValue) {
        this.orderTags = [];
        this.setDispatchValues(changes.dispatch.currentValue)
      }
      if (changes.timeLineDetails && changes.timeLineDetails.currentValue) {
        this.orderTimeLine = changes.timeLineDetails.currentValue;
        for (let index = 0; index < this.orderTimeLine.length; index++) {
          if (this.eventName.indexOf(this.orderTimeLine[index].eventName) == -1) {
            if (this.orderTimeLine[index].updateType == 'dropLocation') {
              continue;
            }
            this.orderTimeLine.splice(index, 1);
            index--;
            continue;
          }
        }
        if (changes.captainDetails.currentValue.status && this.notAllowedToAbortStatus.indexOf(changes.captainDetails.currentValue.status) !== -1) {
          this.abortRide = false;
        }
        if (changes.captainDetails.currentValue.status && this.notAllowedToCancelStatus.indexOf(changes.captainDetails.currentValue.status) !== -1) {
          this.cancelRide = false;
        }
        if (this.orderTimeLine.length > 0) {
          this.currentStatus = this.statusMap[this.fetchCurrentOrderStatus()];
        }
        if (changes.captainDetails.currentValue.status && this.reAssignStatus.indexOf(changes.captainDetails.currentValue.status) !== -1) {
          this.isReassignable = true;
        }
        for (let index = 0; index < this.orderTimeLine.length; index++) {
          if (this.orderTimeLine[index].status === 'aborted') {
            this.abortReason = this.orderTimeLine[index].message;
            this.abortedBy = this.orderTimeLine[index].emailId;
            this.aborted = true;
          }
        }
      }
      if (changes.orderTimeLineStatus && !changes.orderTimeLineStatus.currentValue.dataFetched) {
        this.dataFetched = changes.orderTimeLineStatus.currentValue.dataFetched;
      }

      if (this.orderTags.length === 0) {
        this.orderTags.push('Nil')
      }

      if (changes.microCorridorDetails && changes.microCorridorDetails.currentValue && changes.microCorridorDetails.currentValue.name) {
        this.microCorridor = changes.microCorridorDetails.currentValue.name
      }

      if (changes.serviceObj && changes.serviceObj.currentValue && changes.serviceObj.currentValue.service && 
          changes.serviceObj.currentValue.service.displayName) {
        this.serviceType = changes.serviceObj.currentValue.service.displayName;
        this.city = changes.serviceObj.currentValue.city.displayName
      }
    }
  }

  fetchCurrentOrderStatus(){
    const notOrderStatusList = ["shipmentReadyTimeInEpoch", "predictedShipmentReadyTimeInEpoch"];
    for (let i = this.orderTimeLine.length - 1; i >= 0; i--) {
      if (!notOrderStatusList.includes(this.orderTimeLine[i].eventName)) {
        return this.orderTimeLine[i].eventName;
      }
    }
    return this.orderTimeLine[this.orderTimeLine.length - 1].eventName;
  }

  setDispatchValues(dispatch) {
    this.propagationType = dispatch && dispatch.propagationType;

    if (dispatch && dispatch.globalPriority) {
      this.orderTags.push('Global Priority')
    }
  }

  changeInStatus() {
    // const servicesAllowedToArrive = environment.servicesAllowedToArrive.replace(/[\[\]]/g, '').replace(/\'/g, '').split(',');
    // const servicesAllowedToDrop = environment.servicesAllowedToDrop.replace(/[\[\]]/g, '').replace(/\'/g, '').split(',');
    const servicesAllowedToArrive = JSON.parse(environment.servicesAllowedToArrive);
    const servicesAllowedToDrop = JSON.parse(environment.servicesAllowedToDrop);
    this.servicesAllowedToArrive = servicesAllowedToArrive;
    this.servicesAllowedToDrop = servicesAllowedToDrop;

    if (this.serviceObj && this.serviceObj.service && this.serviceObj.service.name) {
      if (this.servicesAllowedToArrive.indexOf(this.serviceObj.service.name) !== -1) {
        this.allowStatusChangeFrom['On the way'] = true;
      }
      if (this.servicesAllowedToDrop.indexOf(this.serviceObj.service.name) !== -1) {
        this.allowStatusChangeFrom.Reached = true;
        this.allowStatusChangeFrom.Started = true;
        this.allowStatusChangeFrom.Arrived = true;
      }
    }
  }

  toggleStatus() {
    this.viewMore = !this.viewMore;
    if (this.viewMore) {
      this.viewDetails = 'Show less';
      this.showTimeline = true;
    } else {
      this.viewDetails = 'View more';
      this.showTimeline = false;
    }
  }

  abort() {
    if (this.abortRide) {
      const  roles = this.accessCookie('Roles').split(',');
      let accessToAbort = false;
      if ((roles.some(roleValid => CAPTAIN_ABORT.indexOf(roleValid) >= 0))) {
          accessToAbort = true;
      } if (!accessToAbort) {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `You do not have access to abort the ride`,
          }));
          return;
      } else {
        this.abortDialog();
      }
    } else  {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `You cannot abort this ride`,
      }));
    }
  }

  cancel() {
    if (this.cancelRide) {
      const  roles = this.accessCookie('Roles').split(',');
      let accessToCancel = false;
      if ((roles.some(roleValid => CAPTAIN_ABORT.indexOf(roleValid) >= 0))) {
        accessToCancel = true;
      } if (!accessToCancel) {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `You do not have access to cancel the ride`,
          }));
          return;
      } else {
        this.cancelDialog();
      }
    } else  {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `You cannot cancel this ride`,
      }));
    }
  }

  cancelDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '380px',
      data: {
        options: [
          'Customer Issue - Customer asked to cancel',
          'Customer Issue - Customer not reachable'
        ],
        title: 'Are you sure you want to cancel order ?',
        type: 'order-details',
        message: 'For canceling the order, please provide a reason',
        reason: 'cancel',
        errorMessage: 'Select reason to cancel'
      }
    });

    dialogRef.afterClosed().subscribe(cancelReason => {
      let orderCancelReason, location, userId = this.customerDetails.customer.id;
      if (cancelReason) {
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '335px',
          data: {
            title: 'Are you sure you want to cancel order ?',
            type: 'order-details',
            reason: 'cancel',
            message: 'By canceling order will get cancelled',
            buttonText: 'Yes, cancel'
          }
        });

        confirmDialogRef.afterClosed().subscribe(confirmResult => {
          if (confirmResult) {
          const userDetails = this.accessCookie('user');
          const user = JSON.parse(userDetails);
            if (cancelReason === 'Customer Issue - Customer asked to cancel') {
              location = { 'lat': this.pickupLocation.lat, 'lng': this.pickupLocation.lng };
              orderCancelReason = 'order cancelled before rider accepted';
              const cancelObj = {
                'userId': userId,
                'type': 'cancelled',
                'repropagate': false,
                'orderId': this.orderId,
                'message' : cancelReason,
                'emailId' : user.emailId,
                'locationDetails': location,
                'cancelReason': orderCancelReason
              }
              this.cancelOrder(cancelObj);
            } else {
              this.rideTrackerMapService.getLiveLocation(this.captainDetails.captainId).subscribe(res => {
                if (res && res['location'] && res['location'].length === 2) {
                  location = { 'lat': res['location'][1], 'lng': res['location'][0] };
                }
                orderCancelReason = 'customer not reachable';
                const cancelObj = {
                  'userId': userId,
                  'type': 'cancelled',
                  'repropagate': false,
                  'orderId': this.orderId,
                  'emailId' : user.emailId,
                  'message' : cancelReason,
                  'locationDetails': location,
                  'cancelReason': orderCancelReason
                }
                this.cancelOrder(cancelObj);
              }, error => {
                console.log(`Error in getCaptainLiveLocation in timeLineViewComponent for captain - ${this.captainDetails.captainId}`, error);
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.WARNING,
                  message: `Unable to cancel the order`,
                }));
              });
            }
          }
        });
      }
    });
  }

  cancelOrder(cancelObj): void {
    this.orderDetailsService.cancel(cancelObj).subscribe(response => {
      if (response && response['info'] && response['info'].status === 'success') {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: `Successfully cancelled the order`,
        }));
      }
    }, err => {
      console.log(`Error while cancelling the order - ${this.orderId} for captain - ${this.captainDetails.captainId}`, err);
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to cancel the order`,
      }));
    });
  }

  abortDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '380px',
      data: {
        options: [
          'Customer Issue - Customer informed to cancel',
          'Customer Issue - Customer unreachable',
          'Captain Denied Duty - Long Pickup',
          'Captain Denied Duty - Drunk Customer',
          'Captain Denied Duty - Rude Customer',
          'Captain Denied Duty - Vehicle Issue',
          'Captain Denied Duty - Mobile Issue/Battery',
          'Captain Issue - Captain is far away',
          'Captain Issue - Captain moving in opp direction',
          'Captain Issue - Rude Captain',
          'Captain Issue - Captain unreachable',
          'B2B - Food Damaged',
          'Partial Offline Abort',
          'Captain Issue - Long Drop',
          'Customer Issue - Requested for Delivery',
          'Customer Issue - Asked for Triple ride',
          'Customer Issue - Wrong Pickup Location',
          'Tech Issue - Multiple Orders booked',
          'Tech Issue - Customer denied booking',
          'Tech Issue - Unable to Swipe',
          'Tech Issue - No OTP',
          'Legal Issue - Police Stopped the vehicle/Other',
          'Abort due to Rain/Weather',
          'Met with Accident after starting order',
          'B2B - Food Delivered - Unable to Swipe',
          'B2B - Food Not Delivered',
          //Specifically for Paytm (Return flow) --- start
          'Order returned-126-Customer cancellation',
          'Order returned-127-Customer reject due to delay',
          'Order returned-128-Unsafe area',
          'Order returned-129-Mx - No answer',
          'Order returned-130-Wrong user address',
          'Order returned-131-FE - Accident / Rain / Strike / vehicle issues',
          'Order returned-132-Customer non-responsive',
          '126-Customer cancellation',
          '127-Customer reject due to delay',
          '128-Unsafe area',
          '129-Mx - No answer',
          '130-Wrong user address',
          '131-FE - Accident / Rain / Strike / vehicle issues',
          '132-Customer non-responsive',
          //Specifically for Paytm (Return flow) --- End
        ],
        title: 'Are you sure you want to abort order ?',
        type: 'order-details',
        message: 'For aborting the order, please provide a reason',
        reason: 'abort',
        errorMessage: 'Select reason to abort'
      }
    });

    dialogRef.afterClosed().subscribe(cancelReason => {
      if (cancelReason) {
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '335px',
          data: { title: 'Are you sure you want to abort order ?',
                  type: 'order-details',
                  reason: 'abort',
                  message: 'By aborting order will get cancelled',
                  buttonText: 'Yes, abort'
                }
        });

        confirmDialogRef.afterClosed().subscribe(confirmResult => {
          if (confirmResult) {
             // call the api
             let  userDetails = this.accessCookie('user');
             userDetails = JSON.parse(userDetails);
             const abortObj = {
               userId: userDetails['_id'],
               riderId: this.captainDetails.captainId || '',
               orderId: this.orderId,
               isBatchable: this.isBatchable
             };
             abortObj['message'] = cancelReason;
             abortObj['userName'] = userDetails['firstName'];
             abortObj['emailId'] = userDetails['emailId'];
             this.timeLineService.abortOrder(abortObj).subscribe(response => {
               if (response && response['info'] && response['info'].status === 'success') {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.SUCCESS,
                  message: `Successfully aborted order`,
                }));
               }
             }, err => {
               this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to abort order`,
              }));
             });
          }
        });
      }
    });
  }

  reAssignDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '380px',
      data: {options: ['Customer Issue - Customer unreachable', 'Captain Denied Duty - No Fuel', 'Captain Denied Duty - Long Pickup',
        'Captain Denied Duty - Rude Customer', 'Captain Denied Duty - Vehicle breakdown', 'Captain Denied Duty - Mobile Issue/Battery',
        'Captain Issue - Captain is far away', 'Captain Issue - Captain moving in opp direction', 'Captain Issue - Rude Captain',
        'Captain Issue - Captain unreachable', 'Tech Issue - Customer denied booking', 'Other'],
            title: 'Are you sure you want to re-assign order ?',
            type: 'order-details',
            message: 'For re-assigning the order, please provide a reason ',
            reason: 're-assign',
            errorMessage: 'Select reason to re-assign'
            }
    });

    dialogRef.afterClosed().subscribe(reAssignReason => {
      if (reAssignReason) {
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '335px',
          data: { title: 'Are you sure you want to Re-assign ?',
                  type: 'order-details',
                  reason: 're-assign',
                  message: '',
                  buttonText: 'Yes, reassign'
                }
        });

        confirmDialogRef.afterClosed().subscribe(confirmResult => {
          if (confirmResult) {
            // call the api
            let  userDetails = this.accessCookie('user');
            if (userDetails) {
              userDetails = JSON.parse(userDetails);
            }
            const reAssignObj = {
              pickupLocation : {
                lat : this.reAssignDetails.pickUpLocation ? this.reAssignDetails.pickUpLocation.lat : '',
                lng : this.reAssignDetails.pickUpLocation ? this.reAssignDetails.pickUpLocation.lng : ''
              },
              dropLocation : {
                lat : this.reAssignDetails.pickUpLocation ? this.reAssignDetails.dropLocation.lat : '',
                lng : this.reAssignDetails.pickUpLocation ? this.reAssignDetails.dropLocation.lng : ''
              },
              customerId: this.reAssignDetails.customerId ? this.reAssignDetails.customerId : '',
              serviceType: this.reAssignDetails.serviceType ? this.reAssignDetails.serviceType : '',
              orderType: this.reAssignDetails.orderType ? this.reAssignDetails.orderType : '',
              riderId: this.captainDetails.captainId || '',
              switchReason: reAssignReason,
              userId: userDetails['_id'] ? userDetails['_id'] : '',
              userEmail: userDetails['emailId'] ? userDetails['emailId'] : '',
              userName: userDetails['firstName'] ? userDetails['firstName'] : '',
              isBatchable: this.isBatchable,
              cityId: this.serviceObj.city._id
            };
            if (this.b2bConfig) {
              reAssignObj['orderConfig'] = this.b2bConfig;
            }
            this.timeLineService.reAssignOrder(this.orderId, reAssignObj).subscribe(response => {
              if (response['info'] && response['info'].status && response['info'].status === 'success') {
                this.isReassignable = false;
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.SUCCESS,
                  message: `Order re-assigned successfully`,
                }));
                this.timeLineService.pushOrderActivityEventLogs({
                  captain_id: this.captainDetails.captainId,
                  order_id: this.orderId,
                  timestamp: Date.now(),
                  source: 'profilesDashboard',
                  event_name: 'Order Reassigned',
                  user: userDetails['emailId'],
                  reason: reAssignReason,
                  description: userDetails['emailId'].length
                    ? `Order reassigned by ${userDetails['emailId']} due to ${reAssignReason}`
                    : `Order reassigned due to ${reAssignReason}`
                }).subscribe(()=>{ });
              } else {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.WARNING,
                  message: `Failed to re-assign the order`,
                }));
              }
            }, err => {
              let errorMessage = '';
              if (err && err.error && err.error['data']) {
                errorMessage = err.error['data'];
                if (errorMessage.indexOf('-') > -1) {
                  let splitMessage = errorMessage.split('- ');
                  splitMessage = splitMessage[1] ? JSON.parse(splitMessage[1]) : '';
                  errorMessage = splitMessage['error'] ? splitMessage['error']:
                                'Unable to re-assign order';
                }
              } else {
                errorMessage = 'Unable to re-assign order';
              }
              this.toasterService.showToaster(new Toaster({
               type: ToasterType.WARNING,
               message: errorMessage,
              }));
              this.timeLineService.pushOrderActivityEventLogs({
                captain_id: this.captainDetails.captainId,
                order_id: this.orderId,
                timestamp: Date.now(),
                source: 'profilesDashboard',
                event_name: 'Order Reassigned',
                user: userDetails['emailId'],
                reason: reAssignReason,
                description: userDetails['emailId'].length
                  ? `Order reassigned tried by ${userDetails['emailId']} due to ${reAssignReason}. Failed: ${errorMessage}`
                  : `Order reassigned tried due to ${reAssignReason}. Failed: ${errorMessage}`
              }).subscribe(()=>{ });
            });
          }
        });
      }
    });
  }

  async reAssignCaptain() {
     // call role based access api
     let accessToReassign = false;
     if ((this.roles.some(roleValid => CAPTAIN_REASSIGN.indexOf(roleValid) >= 0))) {
       accessToReassign = true;
      }

      if (!accessToReassign) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `You do not have access to Re-Assign the ride`,
        }));
        return;
      }
      
      if (!this.isReassignable) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Cannot re-assign this ride`,
        }));
      } else {
        this.reAssignDialog();
      }
  }

  async changeStatus(status) {
    const ReachedOptions = [ 'Internet Issue', 'No OTP option to captain', 'Customer change location', 'Other'];
    const defaultOptions = ['Internet Issue', 'Different customer location', 'Other'];
    const options = status.toLowerCase() === 'started' ? ReachedOptions : defaultOptions;
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '380px',
      data: {options,
            title: 'Please enter reason for Updating Status',
            type: 'order-details',
            message: 'Select Reason',
            reason: 'changeStatus',
            errorMessage: 'Provide reason to change status'
            }
    });

    dialogRef.afterClosed().subscribe(changeReason => {
      if (changeReason) {
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '335px',
          data: { title: 'Are you sure you want to update status?',
                  type: 'order-details',
                  reason: 'changeStatus',
                  message: '',
                  buttonText: 'Yes'
                }
        });

        confirmDialogRef.afterClosed().subscribe(confirmResult => {
          if (confirmResult) {
            let requestBody, finalStatus;
            const riderId  = this.captainDetails.captainId || '';
            let  userDetails = this.accessCookie('user');
             if (userDetails) {
               userDetails = JSON.parse(userDetails);
             }
            if (status === 'On the way') {
              finalStatus = 'arrived';
              requestBody = {
                riderId: riderId,
                deviceId: this.deviceId,
                timeStamp: new Date().getTime(),
                withLocationUpdate: false,
                message: changeReason,
                emailId: userDetails['emailId'],
                triggerSource: 'profiles-dashboard'
              };
            } else if (status.toLowerCase() === 'arrived') {
              finalStatus = 'started';
              requestBody = {
                riderId: riderId,
                deviceId: this.deviceId ? this.deviceId : '',
                withLocationUpdate: false,
                orderId: this.orderId,
                message: changeReason,
                emailId: userDetails['emailId'],
                isBatchable: this.isBatchable,
                appVersion: '273',
                triggerSource: 'profiles-dashboard'
              };
            } else if (status.toLowerCase() === 'started') {
              finalStatus = 'reached';
              requestBody = {
                riderId: riderId,
                deviceId: this.deviceId ? this.deviceId : '',
                withLocationUpdate: false,
                orderId: this.orderId,
                message: changeReason,
                emailId: userDetails['emailId'],
                isBatchable: this.isBatchable,
                appVersion: '273',
                triggerSource: 'profiles-dashboard'
              };
            } else if (status.toLowerCase() === 'reached') {
              finalStatus = 'dropped';
              requestBody = {
                riderId: riderId,
                deviceId: this.deviceId ? this.deviceId : '',
                withLocationUpdate: false,
                orderId: this.orderId,
                message: changeReason,
                emailId: userDetails['emailId'],
                dropOtp: this.dropOTP,
                isBatchable: this.isBatchable,
                appVersion: '273',
                triggerSource: 'profiles-dashboard'
              };
            }
            this.timeLineService.changeStatus(this.orderId, riderId, requestBody, finalStatus).subscribe(result => {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: `Status changed successfully!`,
              }));
              this.refresh.emit();
            }, err => {
              this.refresh.emit();
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to change status`,
              }));
            });
          }
        });
      }
    });
 }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  fetchTags() {
    this.timeLineService.fetchTags(this.orderId).subscribe(response => {
      if (response && response['data'] && response['data'].orders && response['data'].orders.length > 0) {
        const tags = Object.keys(response['data'].orders[0]);
        let fraudType;
        for (let index = 0; index < tags.length; index++) {
          if (tags[index]) {
            fraudType = tags[index];
            if (response['data'].orders[0][fraudType].flag) {
              if (fraudType === 'speedFraud') {
                  this.fraudTags.push('Over Speed');
              } else if (fraudType === 'spdFraud') {
                  this.fraudTags.push('SPD');
              }
            }
          }
        }
      }
      if (this.fraudTags.length === 0) {
        this.fraudTags.push('Nil');
      }
    }, err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to fetch tags`,
      }));
    });
  }
    
    getFormattedOrderActivityLogs = (logs: any) => {
        if(logs.length) {
            return logs.map(eventLog => ({
                'timestamp': moment(new Date(Number(eventLog.timestamp))).format('DD-MM-YYYY hh:mm a'),
                'eventName': eventLog.event_name || 'N/A',
                'eventData': eventLog.description || 'N/A'
            }))
        } 
        return logs;
    }

    getOrderActivityLogs() {
        this.timeLineService.fetchOrderActivityLog(this.orderId, this.pageNumber, this.perPage).subscribe(response => {   
            this.logs = this.getFormattedOrderActivityLogs(response['data']);
        }, (error: any) => {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to fetch order activity logs - error is ${error}`,
            }));
        });
    }

    getActivityLog = () => {
        this.getOrderActivityLogs();
        this.activityLogDialog();
    }

    activityLogDialog(): void {
        this.dialog.open(OrderActivityLogComponent, {
            width: '767px',
            panelClass: 'log-dialog',
            data: {
                title: 'Activity log',
                logs: this.logs
            }
        });
    }

    partialDropOrder() {
      const status = 'dropped';
      const riderId  = this.captainDetails.captainId || '';
      let  userDetails = this.accessCookie('user');
      if (userDetails) {
        userDetails = JSON.parse(userDetails);
      }
      const requestBody = {
        riderId: riderId,
        deviceId: this.deviceId ? this.deviceId : '',
        withLocationUpdate: false,
        orderId: this.orderId,
        message: "Partial Offline Drop",
        emailId: userDetails['emailId'],
        dropOtp: this.dropOTP,
        isBatchable: this.isBatchable,
        appVersion: '273',
        dropVerified: true,
        triggerSource: 'profiles-dashboard'
      };
      this.timeLineService.changeStatus(this.orderId, riderId, requestBody, status).subscribe(result => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: `Dropped order successfully!`,
        }));
        this.isDropOrderEnabled = false;
        this.refresh.emit();
      }, err => {
        this.isDropOrderEnabled = true;
        this.refresh.emit();
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to drop order`,
        }));
      });
    }

}
