/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fraud-card.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./fraud-card.component";
var styles_FraudCardComponent = [i0.styles];
var RenderType_FraudCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FraudCardComponent, data: {} });
export { RenderType_FraudCardComponent as RenderType_FraudCardComponent };
function View_FraudCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "inner-div"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.card == null) ? null : _co.card.onClick()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "label-span"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "value-span"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardStyle; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.card.label; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.card.value; _ck(_v, 6, 0, currVal_2); }); }
export function View_FraudCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FraudCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.card.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FraudCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fraud-card", [], null, null, null, View_FraudCardComponent_0, RenderType_FraudCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.FraudCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FraudCardComponentNgFactory = i1.ɵccf("app-fraud-card", i3.FraudCardComponent, View_FraudCardComponent_Host_0, { card: "card" }, {}, []);
export { FraudCardComponentNgFactory as FraudCardComponentNgFactory };
