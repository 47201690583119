var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from '../filter.service';
import { CustomerDetailsService } from './customer-details.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { HelperService } from '../helper.service';
import { DataService } from '../data.service';
var CustomerDetailsComponent = /** @class */ (function () {
    function CustomerDetailsComponent(route, filterService, toastr, toasterService, customerDetailService, router, dataservice) {
        this.route = route;
        this.filterService = filterService;
        this.toastr = toastr;
        this.toasterService = toasterService;
        this.customerDetailService = customerDetailService;
        this.router = router;
        this.dataservice = dataservice;
        this.stats = {};
        this.statInfo = 'empty';
        this.focus = false;
        this.mobile = '';
        // public tickets: any;
        this.enteredNumber = false;
        this.lastUsedDevice = '';
        this.toggleableDetails = 'Order Details';
        this.languageMap = {
            en: 'English',
            hi: 'Hindi',
            kn: 'Kannada',
            te: 'Telugu',
            ta: 'Tamil'
        };
        this.customerDetailsToggleableHeaders = [
            'Order Details',
            'Transaction Details',
            'Activity Logs',
            'Subscription Logs',
            'Ticket Details',
            'Customer Complaints'
        ];
    }
    CustomerDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.mobile = params.mobile || _this.route.snapshot.queryParamMap.get('phone');
            if (!_this.mobile) {
                return;
            }
            _this.getUser();
        });
        this.dataservice.updateSearchCount(this.mobile);
    };
    CustomerDetailsComponent.prototype.refreshPage = function (pageRefresh) {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.mobile = params.mobile || _this.route.snapshot.queryParamMap.get('phone');
            if (!_this.mobile) {
                return;
            }
            _this.getUser();
        });
    };
    CustomerDetailsComponent.prototype.enteredMobile = function () {
        if (this.mobile.length > 0) {
            this.enteredNumber = true;
        }
        else {
            this.enteredNumber = false;
        }
    };
    CustomerDetailsComponent.prototype.getUser = function () {
        var _this = this;
        this.headersToggled('Order Details');
        var testMob = new RegExp('[0-9]{10}');
        if (!testMob.test(this.mobile)) {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Please enter a valid 10-digit mobile number",
            }));
        }
        else {
            this.router.navigate(['/customer/' + this.mobile]);
            this.generalInfo = null;
            this.accountInfo = null;
            this.walletInfo = null;
            this.passInfo = null;
            this.stats = {};
            this.customerDetailService.getCustomerByMobile(this.mobile).subscribe(function (res) {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.SUCCESS,
                    message: "User found",
                }));
                _this.generalDetails = res;
                _this.accountInfo = { general: __assign({}, _this.generalDetails), pass: null, status: _this.generalDetails.data.active };
                _this.mobileNumber = _this.mobile;
                _this.id = _this.generalDetails.data._id;
                _this.getPreference();
                _this.getReferralDetails();
                _this.getWallets();
                // this.getTickets(this.id);
                _this.getPass();
                _this.getLastLogin();
                _this.getCustomerSegment();
                _this.mobile = null;
                _this.enteredNumber = false;
            }, function (error) {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "Unable to fetch this user. Please check the number again",
                }));
                _this.mobileNumber = _this.mobile;
                _this.getStatInfo('empty');
                _this.generalInfo = { dataFetched: false };
                _this.accountInfo = { dataFetched: false };
                _this.walletInfo = { dataFetched: false };
            });
        }
    };
    CustomerDetailsComponent.prototype.getWallets = function () {
        var _this = this;
        var data = this.generalDetails.data;
        var wallets = ['rapido', 'paytm', 'simpl', 'freecharge', 'lazypay'];
        var userData = {
            _id: data._id,
            firstName: data.firstName,
            mobile: data.mobile,
            email: data.email,
            roles: ['customer']
        };
        this.customerDetailService.getCustomerWallets(userData, wallets).subscribe(function (res) {
            if (res['info'].status === 'FAILURE') {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.NOTIFICATION,
                    message: "Wallets do not exist for this user.",
                }));
                _this.walletInfo = { dataFetched: false };
            }
            else {
                var resp = __assign({}, res, { dataFetched: true, id: _this.generalDetails.data._id });
                _this.walletInfo = resp;
            }
        }, function (err) {
            _this.walletInfo = { dataFetched: false };
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch wallets for this user. Please try again",
            }));
        });
        this.customerDetailService.getPreviousDueTransactions(userData, this.generalDetails.data._id).subscribe(function (res) {
            var resp = __assign({}, res, { dataFetched: true });
            _this.prevDueinfo = resp;
        }, function (err) {
            _this.prevDueinfo = { dataFetched: false };
            console.log(err);
        });
        this.customerDetailService.getDebitPreference(userData).subscribe(function (res) {
            _this.coinPreference = res && res["debitPreference"] ? res["debitPreference"].find(function (preference) { return preference.name === "coin"; }).preference : false;
        }, function (err) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch debit preference. Please try again",
            }));
        });
    };
    // getTickets(userId){
    //   this.customerDetailService.getTickets(userId).subscribe(result => {
    //     if(result && result['data'] && result['data']['status'] === "success"){
    //       let tickets = result['data']['data'];
    //       let ticketIds = tickets.map( ticket => {
    //         return ticket.ticket_id;
    //       })
    //       this.tickets = ticketIds;
    //     }else {
    //       this.tickets = [];
    //     }
    //   })
    // }
    CustomerDetailsComponent.prototype.headersToggled = function (toggledHeader) {
        this.toggleableDetails = toggledHeader;
    };
    CustomerDetailsComponent.prototype.changeStatus = function (status) {
        this.mobile = this.mobileNumber;
        this.getUser();
        // this.accountInfo = {...this.accountInfo, status: status};
    };
    CustomerDetailsComponent.prototype.getPass = function () {
        var _this = this;
        this.customerDetailService.getPassInfo(this.id).subscribe(function (res) {
            _this.passInfo = res;
            _this.accountInfo = { general: __assign({}, _this.generalDetails), pass: __assign({}, _this.passInfo), status: _this.generalDetails.data.active };
        }, function (error) {
            _this.accountInfo = { general: __assign({}, _this.generalDetails), pass: null, status: _this.generalDetails.data.active };
        });
    };
    CustomerDetailsComponent.prototype.getCustomerSegment = function () {
        var _this = this;
        this.customerDetailService.getCustomerSegment(this.id)
            .then(function (res) {
            var segmentResponse = res.data;
            if (segmentResponse.found === true) {
                _this.generalInfo = __assign({}, _this.generalInfo, { customerSegment: segmentResponse.segment });
            }
            else {
                _this.generalInfo = __assign({}, _this.generalInfo, { customerSegment: "N/A" });
            }
        })
            .catch(function (err) {
            console.error('Error getting customer segment:', err);
            _this.generalInfo = __assign({}, _this.generalInfo, { customerSegment: "N/A" });
        });
    };
    CustomerDetailsComponent.prototype.getLastLogin = function () {
        var _this = this;
        this.customerDetailService.getLastLoginDate(this.id).subscribe(function (res) {
            if (res && res['data'] && res['data']['loginOn']) {
                var loginDate = _this.formatDate(res['data']['loginOn']);
                _this.getStatInfo({ lastAppLogin: loginDate });
                _this.lastUsedDevice = res['data']['device'];
                _this.generalInfo = __assign({}, _this.generalInfo, { lastDevice: _this.lastUsedDevice });
            }
            else {
                _this.getStatInfo({ lastAppLogin: 'N/A' });
            }
        }, function (err) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch last login for this user. Please try again",
            }));
        });
    };
    CustomerDetailsComponent.prototype.getPreference = function () {
        var preferences = { 'language': 'N/A', 'communication': ['N/A'] };
        this.preferences = preferences;
        this.generalInfo = { details: __assign({}, this.generalDetails), preferences: __assign({}, this.preferences), lastDevice: this.lastUsedDevice,
            referralInfo: this.referralInfo, dataFetched: true };
    };
    CustomerDetailsComponent.prototype.getReferralDetails = function () {
        var _this = this;
        this.customerDetailService.getReferralDetails(this.id).subscribe(function (referrals) {
            _this.referralInfo = referrals;
            _this.setGeneralInfo();
        }, function (err) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to get referral details for this user.",
            }));
            _this.referralInfo = [];
            _this.setGeneralInfo();
        });
    };
    CustomerDetailsComponent.prototype.setGeneralInfo = function () {
        this.generalInfo = {
            details: __assign({}, this.generalDetails), preferences: __assign({}, this.preferences), lastDevice: this.lastUsedDevice,
            referralInfo: this.referralInfo, dataFetched: true
        };
    };
    CustomerDetailsComponent.prototype.getStatInfo = function ($event) {
        if ($event && $event !== 'empty') {
            var tempStats = void 0;
            tempStats = __assign({}, $event, this.stats);
            this.stats = tempStats;
            if (this.stats['lastOrderDate'] && this.stats['lastAppLogin']) {
                this.stats['id'] = this.id;
                this.statInfo = this.stats;
            }
        }
        else {
            this.statInfo = 'empty';
        }
    };
    CustomerDetailsComponent.prototype.formatDate = function (date) {
        date = new Date(date);
        return HelperService.dateString(date);
        // let d = new Date(date),
        //     month = '' + (d.getMonth() + 1),
        //     day = '' + d.getDate(),
        //     year = d.getFullYear();
        // if (month.length < 2) {
        //   month = '0' + month;
        // }
        // if (day.length < 2) {
        //   day = '0' + day;
        // }
        // return [year, month, day].join('-');
    };
    return CustomerDetailsComponent;
}());
export { CustomerDetailsComponent };
