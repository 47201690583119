import { Component, OnInit, Input } from '@angular/core';
import { TableData, ThumbnailCard, TableDataTypes, Table } from 'src/app/shared/types';
import { ThumbnailCardTypes, RoundLevel } from 'src/app/shared/types/thumnail-card.types';
import { RecordTable } from 'src/app/shared/types/record-table.types';
import { CaptainService } from 'src/app/captain.service';
import { EntityService } from 'src/app/entity.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { CaptainSuspensionDailogComponent } from 'src/app/captain-suspension/captain-suspension-dailog/captain-suspension-dailog.component';
import { RevokeSuspensionDialogComponent } from  'src/app/captain-suspension/revoke-suspension/revoke-suspension-dialog.component';
import { DASHBOARD_SOURCE, ROLE_RIDER, SUSPENDED_STATUS, ACTIVE_STATUS, BLACKLIST, REVOKE } from 'src/app/shared/constants';
import { SvoService } from 'src/app/svo.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/appConfig.service';
import {KycService} from '../../kyc.service';


const IMAGES = {
  email: '/assets/svgImages/email_id_circle.svg',
  id: '/assets/svgImages/profile_circle.svg',
  status: '/assets/svgImages/profile_circle.svg',
  referral: '/assets/svgImages/referral_circle.svg',
  langularPreference: '/assets/svgImages/lang_pref_circle.svg',
  device: '/assets/svgImages/last_used_device_circle.svg',
  vehicle: '/assets/svgImages/vehicle.svg',
  pocSegment: '/assets/svgImages/profile_circle.svg',
  kyc: '/assets/svgImages/profile_circle.svg'
};
const STATUS_MAPPING = {
  '2': 'OnDuty',
  '2-6': 'onDutyOnWay',
  '4': 'Logout',
  '5': 'Unreachable',
  '6': 'onTheWay',
  '7': 'arrived',
  '8': 'started',
  '8-6': 'startedOnWay' ,
  '8-2': 'startedOnDuty',
  '9': 'offline'
};

const CAPTAIN_STATUS = {
  ACTIVE: 'Active',
  IN_ACTIVE: 'InActive',
  BLACKLISTED: 'Blacklisted'
};

const STATUS_CLASS_MAPPING = {
  [CAPTAIN_STATUS.ACTIVE]: 'active-lable',
  [CAPTAIN_STATUS.IN_ACTIVE]: 'inactive-lable',
  [CAPTAIN_STATUS.BLACKLISTED]: 'blacklisted-lable'
};

const SEGMENT_MAPPING = {
  'LP' : 'Low performing',
  'MP': 'Mid Performing',
  'HP': 'High Performing',
  'UHP': 'Ultra High Performing',
};

const SAFETY_SEGMENT_MAPPING = {
  'UHR' : 'Ultra High Risk',
  'HR' : 'High Risk',
  'MR' : 'Medium Risk',
  'LR' : 'Low Risk',
  'NC' : 'No Coverage',
};

@Component({
  selector: 'app-captain-general-info',
  templateUrl: './captain-general-info.component.html',
  styleUrls: ['./captain-general-info.component.css']
})
export class CaptainGeneralInfoComponent implements OnInit {
  @Input() mobile: string;
  @Input() device: string;
  @Input() captainDetails: any;
  captainId: string;
  revokeServiceGroups: string[] = [];
  suspensionConfig: any;
  generalInfoTable: Table;
  isBlacklisted: Boolean = false;
  blacklistedAt = null;
  details: any;
  segment: any;
  menuOptions;
  refereeDetails: any;
  userType = 'RIDER';
  segmentDetails: Object;
  safetySegmentDetails: any;
  nudgeData: any;

  suspensionPriority: Object = {};
  suspendedUntil: Object = {};
  suspendedReason: Object = {};
  constructor(
    public dialog: MatDialog,
    public captainService: CaptainService,
    public appConfigService: AppConfigService,
    public entityService: EntityService,
    public svoService: SvoService,
    public kycService: KycService,
    private toasterService: ToasterService
  ) { }

  async ngOnInit() {
    const withEarnings = false;
    const withReferralDetails = true;
    this.captainService.captainDetails(this.mobile, null, null, null, withEarnings, withReferralDetails).subscribe(async details => {
      this.details = details;
      this.captainId = this.details.userId;
      this.segmentDetails = await this.getCaptainSegment();
       this.nudgeData = await  this.getCaptainNudgeDetails();
      this.segment = this.segmentDetails && this.segmentDetails['segment'] === 'HH' ? '(HH)' : '';
      details.pocSegment = this.segmentDetails ? this.details.mode === 'Bike' ? this.segmentDetails['pocSegment'] : this.segmentDetails['autoPcSegment'] : '';
      this.safetySegmentDetails = await this.getCaptainSafetySegment();
      details.safetySegment = await this.mapSafetySegment(this.safetySegmentDetails);
      await this.entityService.getRefereeDetails(this.captainId, this.userType, this.mobile).subscribe(async(response: any) => {
        if (response && response.result && response.result.referrerUserDetails) {
           const details = response.result.referrerUserDetails;
           this.refereeDetails = `${details.name}   ${details.mobile}   ${details.referralCode}`;
        }
        await this.generateCaptainDetailsTable(details);
      });
      this.getBlacklistStatus();
      details.segment = this.segment;
      this.captainService.getUserServices(this.captainId, true)
      .subscribe(serviceGroupStatusMap => {
        console.log('ServiceGroupStatusMap to check the suspension status: ', serviceGroupStatusMap);
          for (const property in serviceGroupStatusMap) {
              if (serviceGroupStatusMap[property] === SUSPENDED_STATUS) {
                  this.revokeServiceGroups.push(property.toUpperCase());
              }
          }

        if (serviceGroupStatusMap['priority']) {
          for (const subServiceType in serviceGroupStatusMap['priority']) {
            this.suspensionPriority[subServiceType.toUpperCase()] = serviceGroupStatusMap['priority'][subServiceType];
          }
        }

        if (serviceGroupStatusMap['suspendedReason']) {
          for (const subServiceType in serviceGroupStatusMap['suspendedReason']) {
            this.suspendedReason[subServiceType.toUpperCase()] = serviceGroupStatusMap['suspendedReason'][subServiceType];
          }
        }

        if (serviceGroupStatusMap['suspendedUntil']) {
          for (const subServiceType in serviceGroupStatusMap['suspendedUntil']) {
            let suspendedUntilTime = 'NA';
            if (serviceGroupStatusMap['suspendedUntil'][subServiceType] ) {
              suspendedUntilTime =  moment(serviceGroupStatusMap['suspendedUntil'][subServiceType]).format('YYYY-MM-DD HH:mmss');
            }
            this.suspendedUntil[subServiceType.toUpperCase()] = suspendedUntilTime;

          }
        }

      }, error => {
          console.error('Error while fetching revoke suspension', error);
      });
    });
    this.suspensionConfig = await this.appConfigService.getSuspensionConfig();
  }

  async getCaptainSegment() {
    const s = await this.svoService.getCaptainSegment(this.captainId);
    return s;
  }

  async getCaptainSafetySegment() {
    return await this.svoService.getCaptainSafetySegment(this.captainId);
  }

  async mapPOCSegment(pocSegment) {
    if (SEGMENT_MAPPING[pocSegment.split('_')[0]]) {
      return SEGMENT_MAPPING[pocSegment.split('_')[0]];
    } else {
      return 'N/A';
    }
  }
  async mapSafetySegment(safetySegmentDetails: any) {
    let safetySegment: string;
    if (safetySegmentDetails) {
      if (safetySegmentDetails['safety_profile'] && safetySegmentDetails['safety_profile'] != '' && SAFETY_SEGMENT_MAPPING[safetySegmentDetails['safety_profile']]) {
        safetySegment = SAFETY_SEGMENT_MAPPING[safetySegmentDetails['safety_profile']];
        if (safetySegmentDetails['safety_proximity'] && safetySegmentDetails['safety_proximity'] != '') {
          safetySegment = safetySegment + ` (Proximity:${safetySegmentDetails['safety_proximity']})`;
        } else {
          safetySegment = safetySegment + ` (Proximity:None)`;
        }
        return safetySegment;
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  }
  async getBlacklistStatus() {
    this.entityService.isBlacklisted(this.device, this.captainId).subscribe(result => {
      this.isBlacklisted = result['blacklisted'] || false;
      this.blacklistedAt = result['updatedAt'] ? moment(new Date(0).setUTCSeconds(result['updatedAt'])).format('DD-MM-YYYY hh:mm a') : null;
      this.generateCaptainDetailsTable(this.details);
    });
    return false;
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  hasBlacklistRevokeAccess() {
    const permissions = this.accessCookie('accessRoles');
    return permissions.split(',').includes(BLACKLIST.REVOKE_BLACKLIST);
  }

  hasSuspensionRevokeAccess() {
    const permissions = this.accessCookie('accessRoles');
    return permissions.split(',').includes(REVOKE.SUSPEND_PERMISSION);
  }

  hasP0SuspensionRevokeAccess() {
    const permissions = this.accessCookie('accessRoles');
    return permissions.split(',').includes(REVOKE.P0_SUSPEND_REVOKE_PERMISSION);
  }

  redeemForCaptain(riderId, shiftName, city) {
    let user = this.accessCookie('user');
    user = JSON.parse(user);
    this.captainService.redeemCaptain(riderId, shiftName, city, user['_id']).subscribe(response => {
      if (response['info'] === 'success') {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: 'Redeem enabled',
        }));
      } else {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Could not enable redeem',
        }));
      }
    }, err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: err && err.error && err.error.message &&  err.error.message.message ?
                err.error.message.message : 'Could not enable redeem',
      }));
    });
  }

  logoutCaptain(riderId) {
    this.captainService.logoutCaptain(riderId).subscribe(response => {
      if (response && response['data'] &&
          response['data'].status && response['data'].status === 'success') {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: response['message'],
        }));
      } else {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: response['message'],
        }));
      }
    });
  }

  suspendCaptain = () => {
        const dialogRef = this.dialog.open(CaptainSuspensionDailogComponent, {
            width: '525px',
            data: {
                title: `Please Select Service and Reason for Suspension`
            }
        });

        dialogRef.afterClosed().subscribe(suspensionData => {
            let userDetails = this.accessCookie('user');
            userDetails = JSON.parse(userDetails);
            const reason = (suspensionData.suspensionReason == 'Other') ? suspensionData.suspensionOtherReason : suspensionData.suspensionReason;
            const requestBody = {
                'userId': this.details.userId || this.details.riderId,
                'role': ROLE_RIDER,
                'subServiceType': suspensionData.serviceGroup,
                'status': SUSPENDED_STATUS,
                'activateAfterMins': Number(suspensionData.minutes),
                reason,
                'source': DASHBOARD_SOURCE,
                'modifiedBy': userDetails['_id'] || '',
            };
            if (suspensionData.selectedSuspensionType == 'p0Issues') {
              const permissions = this.accessCookie('accessRoles');
              if (!permissions.split(',').includes(REVOKE.P0_SUSPEND_PERMISSION)) {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.WARNING,
                  message: `You are not authorized to perform P0 suspension`,
                  delay: 3000
                }));
                return;
              }
              requestBody['priority'] = '0';
            }

            if (!this.details.userId && !this.details.riderId) {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable suspend the captain due to tech issues`,
              }));
              return;
            }
            this.captainService.suspendCaptain(requestBody).subscribe((data: any) => {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.SUCCESS,
                    message: `Captain has been suspended successfully!`,
                    delay: 3000
                }));
            }, error => {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: `Unable suspend the captain`,
                    delay: 3000
                }));
            });
       });
  }

  revokeSuspension = () => {
    if (!this.hasSuspensionRevokeAccess()) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `You don't have access to revoke captain suspension`,
        delay: 3000
    }));
    return;
    }
    const dialogRef = this.dialog.open(RevokeSuspensionDialogComponent, {
        width: '525px',
        data: {
            title: `Please Select Service and Reason for Revoking Suspension`,
            serviceGroups: this.revokeServiceGroups,
            suspensionPriority: this.suspensionPriority,
            suspendedReason: this.suspendedReason,
            suspendedUntil: this.suspendedUntil,
            hasP0SuspensionRevocationPermission : this.hasP0SuspensionRevokeAccess()
        }
    });

    dialogRef.afterClosed().subscribe(suspensionData => {
        let userDetails = this.accessCookie('user');
        userDetails = JSON.parse(userDetails);

        const requestBody = {
            'userId': this.details.userId || this.details.riderId,
            'role': ROLE_RIDER,
            'subServiceType': [suspensionData.serviceGroup],
            'status': ACTIVE_STATUS,
            'reason': suspensionData.suspensionReason,
            'source': DASHBOARD_SOURCE,
            'modifiedBy': userDetails['_id'] || '',
            'byPassChecks': {
              'lms': true,
              'validTillTime': true
          }
        };

        if (!this.details.userId && !this.details.riderId) {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Unable to revoke captain suspension due to tech issues`,
          }));
          return;
        }
        this.captainService.unsuspendCaptain(requestBody).subscribe((data: any) => {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: `Captain's suspension has been revoked successfully!`,
            }));
        }, error => {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to revoke captain suspension`,
            }));
        });
   });
}

  revokeBlacklist = (riderId) => {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '335px',
      data: { title: 'Are you sure you want to revoke blacklist for this captain?',
              type: 'order-details',
              buttonText: 'Yes, revoke'
            }
    });

    confirmDialogRef.afterClosed().subscribe(confirmResult => {
      if (confirmResult) {
        this.entityService.unblockCaptain(this.device).subscribe(result => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.SUCCESS,
            message: `Captain has been unblocked successfully!`,
          }));
          this.getBlacklistStatus();
        }, error => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Something went wrong. Please try again.`,
        }));
        });
      }
    });
  }

  getCaptainStatus(details) {
    return this.isBlacklisted ? CAPTAIN_STATUS.BLACKLISTED : (details.active ? CAPTAIN_STATUS.ACTIVE : CAPTAIN_STATUS.IN_ACTIVE);
  }
    async getCaptainNudgeDetails() {
        const s = await this.kycService.getCaptainNudgeDetails(this.captainId);
        return s;
    }

  generateCaptainDetailsTable(details) {
    let vehicleAdditionalInfo = '';
    if (details && details.vehicleDetails)  {
      if (details.vehicleDetails.color) {
        vehicleAdditionalInfo += 'Color : ' + details.vehicleDetails.color + '\n';
      }
      if (details.vehicleDetails.make) {
        vehicleAdditionalInfo += 'Make : ' + details.vehicleDetails.make + '\n';
      }
    }
    if (details.license && details.license.number) {
      vehicleAdditionalInfo += 'DL number : ' + details.license.number;
    }
    const thumbnailImageStyle = {
          'border-radius': '30px',
          'margin-bottom': '-12px',
          'margin-top': '-12px',
          'background-size': 'contain'
    };
    const tdStyle = {
      'padding': '0px 20px'
    };
    this.menuOptions = {
      ellipses: [
        {
          label: 'Enable Redeem',
          onClick: () => {
            this.redeemForCaptain(this.details.riderId, this.details.shift, this.captainDetails.city.displayName);
          }
        },
        {
            label: 'Suspend',
            onClick: () => {
                this.suspendCaptain();
            }
        },
        {
          label: 'Revoke Suspension',
          onClick: () => {
              this.revokeSuspension();
          }
      },
        {
          label: 'Logout Captain',
          onClick: () => {
            this.logoutCaptain(this.details.riderId);
          }
        }
      ]
    };
    if (this.hasBlacklistRevokeAccess() && this.isBlacklisted) {
      this.menuOptions['ellipses'].push(
        {
          label: 'Un-blacklist Captain',
          onClick: () => {
            this.revokeBlacklist(this.details.riderId);
          }
        }
      );
    }
    // @ts-ignore
      const captainDetails = [
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.id,
          title: `${details.name} ${details.segment}`,
          data: details.mobileNumber,
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: tdStyle,
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.status,
          title: 'Status',
          data: {
            toString: () => {
              return this.getCaptainStatus(details);
            },
            className: STATUS_CLASS_MAPPING[this.getCaptainStatus(details)],
          },
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          },
          info: this.isBlacklisted ? `${this.blacklistedAt}` : null,
        }),
        type: TableDataTypes.CARD,
        style: {
          'padding': '0px 20px'
        }
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.email  ,
          title: 'Email Id',
          data: details.emailid,
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: {
          'padding': '0px 20px'
        }
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.vehicle  ,
          title: 'Vehicle',
          data: details.vehicleDetails.number,
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          info: vehicleAdditionalInfo,
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: {
          'padding': '0px 20px'
        }
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.pocSegment,
          title: 'Captain Segmentation',
          data: details.pocSegment,
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: tdStyle,
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.pocSegment,
          title: 'Captain Risk Segment',
          data: details.safetySegment,
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: tdStyle,
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.referral,
          title: 'Referral Code',
          data: details.referralCode,
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: tdStyle,
      }),
      new TableData({
        card: new ThumbnailCard({
          type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
          imageUrl: IMAGES.referral,
          title: 'Referee',
          data: this.refereeDetails ? this.refereeDetails : 'Organic User',
         info: 'raghu test',
          style: {
            'padding-top': 0,
            'padding-bottom': 0,
          },
          config: {
            imageRound: true,
            roundLevel: RoundLevel.ONE,
            thumbnailImageStyle,
          }
        }),
        type: TableDataTypes.CARD,
        style: tdStyle,
      }),
    new TableData({
        type: TableDataTypes.KYC_CARD,
        data: [{headerClass: 'header-card-color',
            matExpansionPanelContent: [{ header: 'RC',
                value: this.nudgeData && this.nudgeData.rc ?  this.nudgeData.rc : 'NA'},
                {header: 'DL',
                    value: this.nudgeData  && this.nudgeData.dl ? this.nudgeData.dl : 'NA'}],
            isExpansion: true, matExpansionPanelHeader: 'KYC', imageUrl: IMAGES.kyc}],
        style: tdStyle
    })
    ];

      const recordTable = new RecordTable({
      data: captainDetails,
      header: {
        toString() {
          return 'General Info';
        },
        style() {
          return {
            'color': '#9aa0ac',
            'line-height': '26px',
            'font-size': '16px',
          };
        }
      },
      style: {
        'height': '324px',
        'overflow-x': 'scroll',
      },
      config: this.menuOptions
    });
    this.generalInfoTable = recordTable.table;
  }
}
