import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HelperService } from 'src/app/helper.service';

@Component({
  selector: 'app-customer-account',
  templateUrl: './customer-account.component.html',
  styleUrls: ['./customer-account.component.css']
})
export class CustomerAccountComponent implements OnInit, OnChanges {
  @Input() data;
  public info: any;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue && changes.data.currentValue.dataFetched === false) {
      this.blankCard();
    } else if (changes.data && changes.data.currentValue && (changes.data.currentValue.general || changes.data.currentValue.pass)) {
      this.data = changes.data.currentValue;
      this.constructCard();
    } else {
      this.emptyCard();
    }
  }
  ngOnInit() {
  }

  blankCard() {
    this.info = {
      header: 'Rapido account',
      dataFetched: false,
      data: []
    };
  }

  emptyCard() {
    this.info = {
      header: 'Rapido account',
      dataFetched: false,
      data: null
    };
  }

  constructCard() {
    if (this.data && this.data.general) {
      const data = this.data.general.data;
      const status = this.data.status;
      const deactivationReason = !status && this.data.general && this.data.general.data && this.data.general.data.deactivationReason ?
      'Deactivation Reason : ' + this.data.general.data.deactivationReason : null;
      const deactivatedBy = !status && this.data.general && this.data.general.data && this.data.general.data.deactivatedBy ?
      'Deactivated By : ' + this.data.general.data.deactivatedBy : null;
      const deactivationInfo = deactivationReason && deactivatedBy ? deactivationReason + ', ' + deactivatedBy :
        (deactivationReason ? deactivationReason : (deactivatedBy ? deactivatedBy : null));
      const activatedBy = status && this.data.general && this.data.general.data &&
          this.data.general.data.activatedBy ? 'Activated By : ' + this.data.general.data.activatedBy : null;
      const pass = this.data.pass && this.data.pass.data ? this.data.pass.data : '';
      let passInfo = '';
      const passTooltip = [];
      if (pass.purchased) {
        if (pass.subsStartDate) {
          passTooltip.push('Start Date : ' + pass.subsStartDate);
        }
        if (pass.validTillDate) {
          passTooltip.push('End date : ' + pass.validTillDate);
        }
        if (pass.remainingRides) {
          passTooltip.push('Remaining Rides : ' + pass.remainingRides);
        }
        if (pass.tnc) {
          passTooltip.push('Terms & Conditions : ' + (pass.tnc.join('\t')));
        }
        if (pass.ruleName) {
          passTooltip.push('Rule Name : ' + (pass.ruleName));
        }
        if (pass.subsAmount) {
          passTooltip.push('Subscription Amount: ' + (pass.subsAmount));
        }
        passInfo = passTooltip.join('\n');
      }
      this.info = {
        header: 'Rapido account',
        dataFetched: true,
        data: [
          {header: 'Registration Date', value: data.createdOn ? this.formatDate(new Date(data.createdOn)) : ''},
          {header: 'Registered City', value: data.city ? data.city : ''},
          {header: 'Current Status', className: status && status === true ? 'green-badge' : 'red-badge',
                    value: status && status === true ? 'Active' : 'Inactive',
                    information: !status && deactivationInfo ? deactivationInfo : (status && activatedBy ? activatedBy : null)},
          {header: 'Pass Details', value: pass.validTillDate ? pass.name : '',
                    information: passInfo ? passInfo : null},
          {header: 'App version', value: data.appVersion ? data.appVersion : ''}
        ]
      };
    }
  }

  formatDate(date) {
    return HelperService.dateString(date);
    // const monthNames = [
    //   'January', 'February', 'March',
    //   'April', 'May', 'June', 'July',
    //   'August', 'September', 'October',
    //   'November', 'December'
    // ];
    // const day = date.getDate();
    // const monthIndex = date.getMonth();
    // const year = date.getFullYear();
    // return day + ' ' + monthNames[monthIndex] + ' ' + year;
  };

}
