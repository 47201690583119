import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

export class HelperService {
  static debounce = (func, delay = 3000) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      };
  }
  static copyObj(obj = {}) {
    return _.cloneDeep(obj);
  }
  static getQueryString(find: any = {}, select: any = []) {
      const keywords = Object.keys(find);
      const specialOperator = [
        'sort',
        'limit',
        'offset',
      ];
      let queryString = keywords.reduce((acc, keyword, index) => {
        if (keyword === 'status' && find[keyword] === 'all') {
          acc = acc;
          return acc;
        }
        const findCondition = find[keyword];
        if (specialOperator.includes(keyword)) {
          acc += `${keyword}=${findCondition}`;
        } else if (findCondition instanceof Array) {
          acc += `${keyword}__in=${findCondition.join(',')}`;
        } else if (typeof findCondition === 'object' ) {
          acc += this.makeConditionQuery(keyword, findCondition);
        } else {
          acc += `${keyword}__eq=${findCondition}`;
        }
        acc += `&`;
        return acc;
      }, '');
      if (select && select.length) {
        queryString += `select=${select.join(',')}`;
      }
      return queryString;
  }
  static queryString(query: any = {}) {
    const keywords = Object.keys(query);
    const queryString = keywords.reduce((acc, keyword) => {
      const findCondition = query[keyword];
      // if (specialOperator.includes(keyword)) {
      //   acc += `${keyword}=${findCondition}`;
      // } else if (findCondition instanceof Array) {
      //   acc += `${keyword}__in=${findCondition.join(',')}`;
      // } else if (typeof findCondition === 'object' ) {
      //   acc += this.makeConditionQuery(keyword, findCondition);
      // } else {
        acc += `${keyword}=${findCondition}`;
      // }
      acc += `&`;
      return acc;
    }, '');
    return queryString;
  }
  static makeConditionQuery(parent: string, condition: any) {
    const conditionOperators = Object.keys(condition);
    const conditionQueryString = conditionOperators.map(cond => {
      return `${parent}__${cond}=${condition[cond]}`;
    });
    return conditionQueryString.join('&');
  }
  static timestampToReadableFormat(timeStamp: Date) {
    const date = new Date(timeStamp);
    const timeString = date.toLocaleTimeString().split(':').splice(0, 2).join(':');
    return `${timeString} ${HelperService.dateString(date)}`;
  }

  static isValidDate(date: Date) {
    return (date && date.getTime()) ? true : false;
  }

  static getDateOnly(date: Date) {
    const monthDate = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${monthDate.toString().padStart(2, '0')}`;

  }

  static getDateOnlyV1(date: Date) {
    const monthDate = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month.toString().padStart(2, '0')}-${monthDate.toString().padStart(2, '0')}`;

  }

  static setCookie(data: any = {}) {
    Object.keys(data).map(key => {
      let value = data[key];
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      document.cookie = `${key}=${value};path=/`;
    });
  }

  static regexString(string) {
    return `${string}$/i`;
  }
  static roundNumber(num) {
    // const roundTwoDecimal = (num) => {
      return Math.round(num * 100) / 100;
    //  };
  }

  static async getOrderDateyUniqueId(uniqueId) {
    if (uniqueId.length !== 19) {
      return null;
    }
    let ts = uniqueId.replace("RD", "");
    if (!Date.parse(ts)) {
      ts = parseInt(ts.toString().split('').reverse().splice(4).reverse().join(''));
      if (!Date.parse(new Date(ts).toString())) {
        return null;
      }
    }
    const date = HelperService.getDateOnlyV1(new Date(ts));
    return date;
  }
  static accessCookie(cookieName) {
          const name = cookieName + '=';
          const allCookieArray = document.cookie.split(';');
          for (let i = 0; i < allCookieArray.length; i++) {
            const temp = allCookieArray[i].trim();
            if (temp.indexOf(name) === 0) {
              return temp.substring(name.length, temp.length);
            }
          }
          return '';
        }

  static dateString(date: any) {
    if (date && Date.parse(date)) {
      return new Date(date).toDateString();
    }
    return 'N/A';
  }

  static getCurrentUrl() {
    return window.location.href;
  }

  static getCurrentPath() {
    return window.location.pathname;
  }

  static removeParam(params: string[] = []) {
    const baseUrl = window.location.origin;
    const pathname = window.location.pathname;
    const routeParams = this.routeParams();
    const filteredParams = Object.keys(routeParams).reduce((acc, param) => {
      if (!params.includes(param)) {
        acc[param] = routeParams[param];
      }
      return acc;
    }, {});
    const queryString = HelperService.queryString(filteredParams);
    return queryString ?
        `${baseUrl}${pathname}?${HelperService.queryString(filteredParams)}` : `${baseUrl}${pathname}`;
  }

  static routeParams(): any {
    return decodeURIComponent(window.location.search.substring(1)).split('&')
      .reduce((acc, keyValue) => {
        const data = keyValue.split('=');
        acc[data[0]] = data[1];
        return acc;
        }, {});
  }
  static getBaseRouteFromUrl(url: string) {
    return url.split('/').splice(0, 3).join('/');
  }

  static minutesToReadableHourMinuteFormat(minutes: number) {
    const date = new Date('1-1-2019');
    date.setMinutes(minutes);
    let readableString = '';
    if (date.getHours()) {
      readableString = `${date.getHours()} hours`;
    }
    if (date.getMinutes()) {
      readableString += ` ${date.getMinutes()} min`;
    }
    return readableString.trim();
  }

  static timeStampToDateOnly(date: Date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  static encode(data: string) {
    return btoa(btoa(data));
  }

  static endcodeToLevel(data: string, encodeLevel: any) {
    encodeLevel = parseInt(encodeLevel, 10) || 0;
    for (let i = 0; i < encodeLevel; i++) {
      data = HelperService.encode(data);
    }
    return data;

  }

  static decode(data: string) {
    try {
      return atob(atob(data));
    } catch (err) {
      console.log(err);
      return data;
    }
  }

  static resetTimeTo(date: Date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  static redirect(url: string, withReferer = true) {
    const referrer = document.URL;
    const finalUrl = withReferer ? `${url}?referrer=${referrer}` : url;
    window.location.replace(finalUrl);
  }

  static redirectToOldDashboard(relativeRoute, queryParams = {}) {
    const basePath = environment.rapido_dashboard;
    // const token = HelperService.accessCookie('token');
    const queryString = HelperService.queryString(Object.assign({}, queryParams));
    const path = `${basePath}/${relativeRoute}?${queryString}`;
    // const path = `${basePath}/${relativeRoute}?token=${token}`;
    // window.location.replace(path);
    window.open(path, '_blank');
  }

  static redirectToNewPage(url) {
    window.open(url, '_blank');
  }

  // static redirect(url) {
  //   window.location.replace(url);
  // }

  static openInMap(lat, lng) {
    if (lat && lng) {
      const googleUrl = `https://maps.google.com/?q=${lat},${lng}`;
      this.redirectToNewPage(googleUrl);
    }
  }

  static convert24to12hrFormat(time) {
    let timeIn12HrFormat = time;
    let meridian = 'am';
    if (time) {
      const parts = time.split(':');
      if (parts.length === 2) {
        if (parseInt(parts[0], 10)) {
          if (parseInt(parts[0], 10) > 12) {
            parts[0] = parseInt(parts[0], 10) - 12;
            meridian = 'pm';
          } else if (parseInt(parts[0], 10) === 12) {
            meridian = 'pm';
          }
        }
      }
      timeIn12HrFormat = parts.join(':') + ' ' + meridian;
    }
    return timeIn12HrFormat;
  }
}
