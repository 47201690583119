import { isObject } from 'util';
var TableAction = /** @class */ (function () {
    function TableAction(action) {
        Object.assign(this, action);
    }
    return TableAction;
}());
export { TableAction };
export var TableDataTypes;
(function (TableDataTypes) {
    TableDataTypes[TableDataTypes["DATA"] = 0] = "DATA";
    TableDataTypes[TableDataTypes["ACTION"] = 1] = "ACTION";
    TableDataTypes[TableDataTypes["BUTTON"] = 2] = "BUTTON";
    TableDataTypes[TableDataTypes["CARD"] = 3] = "CARD";
    TableDataTypes[TableDataTypes["PERFORMANCE_CARD"] = 4] = "PERFORMANCE_CARD";
    TableDataTypes[TableDataTypes["FRAUD_CARD"] = 5] = "FRAUD_CARD";
    TableDataTypes[TableDataTypes["CHIP"] = 6] = "CHIP";
    TableDataTypes[TableDataTypes["CHIPS"] = 7] = "CHIPS";
    TableDataTypes[TableDataTypes["FRAUD"] = 8] = "FRAUD";
    TableDataTypes[TableDataTypes["KYC_CARD"] = 9] = "KYC_CARD";
})(TableDataTypes || (TableDataTypes = {}));
var TableActions = /** @class */ (function () {
    function TableActions(actions) {
        this.actions = actions;
    }
    return TableActions;
}());
export { TableActions };
var TableData = /** @class */ (function () {
    function TableData(data) {
        Object.assign(this, data);
        this.onClick = this.onClick || (function (data) { });
    }
    TableData.prototype.valueOf = function () {
        switch (this.type) {
            case TableDataTypes.DATA: {
                return this.data;
            }
            // case TableDataTypes.BUTTON: {
            //   return this.button;
            // }
            // case TableDataTypes.ACTION: {
            //   return this.actions;
            // }
        }
        return this;
    };
    TableData.prototype.toString = function () {
        return this.valueOf();
    };
    return TableData;
}());
export { TableData };
export var TABLE_SPAN_CLASS;
(function (TABLE_SPAN_CLASS) {
    TABLE_SPAN_CLASS["ACTIVE_BUTTON"] = "active-lable";
    TABLE_SPAN_CLASS["INACTIVE_BUTTON"] = "inactive-lable";
    TABLE_SPAN_CLASS["WARNING_BUTTON"] = "warning-lable";
})(TABLE_SPAN_CLASS || (TABLE_SPAN_CLASS = {}));
var defaultConfig = {
    header: true,
    colName: true,
    search: false,
    filter: false,
    refresh: true,
};
var TableConfig = /** @class */ (function () {
    function TableConfig(config) {
        if (config === void 0) { config = {}; }
        Object.assign(this, defaultConfig, config);
    }
    return TableConfig;
}());
export { TableConfig };
var Table = /** @class */ (function () {
    function Table(data) {
        // = new Pagination();
        this._data = [];
        this.headers = {};
        this.realData = [];
        this.dynamicClass = {};
        this.style = {};
        // ellipses?: ILabelAction[];
        this.state = Table.TableStates.LOADING;
        this._onSearch = [function (searchObj) {
                console.log(searchObj);
                return;
            }];
        this._onRefresh = [function () {
                return;
            }];
        this._onFilter = [function (filters) {
                return;
            }];
        Object.assign(this, data);
        // this.pagination = data.pagination || new Pagination();
        this.config = new TableConfig(this.config);
        // this.state = Table.TableStates.LOADING;
    }
    Object.defineProperty(Table.prototype, "onSearch", {
        set: function (fn) {
            var alreadyExists = this._onSearch.find(function (searchFn) { return searchFn === fn; });
            if (alreadyExists) {
                return;
            }
            this._onSearch.push(fn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "onRefresh", {
        set: function (fn) {
            var alreadyExists = this._onRefresh.find(function (refreshFn) { return refreshFn === fn; });
            if (alreadyExists) {
                return;
            }
            this._onRefresh.push(fn);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "onFilter", {
        set: function (fn) {
            var alreadyExists = this._onFilter.find(function (filterFn) { return filterFn === fn; });
            if (alreadyExists) {
                return;
            }
            this._onFilter.push(fn);
        },
        enumerable: true,
        configurable: true
    });
    Table.prototype.emitSearch = function (searchString) {
        this._onSearch.forEach(function (search) {
            if (!search || typeof search !== 'function') {
                return;
            }
            search({
                searchString: searchString
            });
        });
    };
    Table.prototype.emitRefresh = function () {
        this._onRefresh.forEach(function (refresh) {
            if (!refresh || typeof refresh !== 'function') {
                return;
            }
            refresh();
        });
    };
    Table.prototype.emitFilter = function (filters) {
        this._onFilter.forEach(function (filter) {
            if (!filter || typeof filter !== 'function') {
                return;
            }
            filter(filters);
        });
    };
    Table.prototype.isElipsesAvailable = function () {
        if (this.config.ellipses && this.config.ellipses.length) {
            return true;
        }
        return false;
    };
    Table.prototype.dataWillLoad = function () {
        this.state = Table.TableStates.LOADING;
    };
    Object.defineProperty(Table.prototype, "data", {
        get: function () {
            return this._data;
        },
        // onSearch?: (searchObj: any) => void;
        set: function (rows) {
            var _this = this;
            this.realData = rows;
            this._data = rows.map(function (row) {
                Object.keys(row).map(function (key) {
                    var dataValue = row[key];
                    if (!isObject(dataValue)) {
                        row[key] = new TableData({
                            data: row[key],
                            type: TableDataTypes.DATA,
                            className: _this.dynamicClass[key],
                        });
                    }
                    else if (dataValue instanceof TableData) {
                        switch (dataValue.type) {
                            case TableDataTypes.ACTION: {
                                row[key] = dataValue;
                                break;
                            }
                            case TableDataTypes.BUTTON: {
                                row[key] = dataValue;
                                break;
                            }
                            case TableDataTypes.DATA: {
                                row[key] = dataValue;
                                break;
                            }
                            // case TableDataTypes.CARD: {
                            // }
                        }
                    }
                });
                return row;
            });
            this.state = Table.TableStates.LOADED;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table, "TableStates", {
        get: function () {
            return {
                LOADING: 'loading',
                LOADED: 'loaded',
                ERRORED: 'errored',
            };
        },
        enumerable: true,
        configurable: true
    });
    return Table;
}());
export { Table };
