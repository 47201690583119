<div class = "wrapper">
    <div *ngIf="isCorporateOrder; then corporateTitle else nonCorporateTitle"></div>
    <ng-template #corporateTitle>
        <div class = "invoice-header">Statement of work</div>
    </ng-template>
    <ng-template #nonCorporateTitle>
        <div class = "invoice-header">Tax Invoice</div>
    </ng-template>
    <div class = "invoice-id">{{rideId}}</div>
     <div class = "tax-info-wrap">
        <div class = "invoice-info-wrap">
            <div class = "invoice-label invoice-address">Roppen Transportation Services Private Limited</div>
        </div>
        <div class = "invoice-info-wrap invoice-address">
            <div class = "invoice-label">{{rapidoAddress}}</div>
        </div>

        <div *ngIf="isCorporateOrder; then corporateNameBlock else nonCorporateNameBlock"></div>
        <ng-template #corporateNameBlock>
            <div class="invoice-info-wrap">
                <div class="invoice-label invoice-address">{{clientName}}</div>
            </div>
            <div class="invoice-info-wrap invoice-address">
                <div class="invoice-label">{{merchantAddress}}</div>
            </div>
        </ng-template>
        <ng-template #nonCorporateNameBlock>
            <div *ngIf="service === 'sme'; then merchantNameBlock else customerNameBlock"></div>
            <ng-template #customerNameBlock>
                <div  class = "invoice-info-wrap">
                    <div class = "invoice-label invoice-address">{{customerName}}</div>
                </div>
                <div class = "invoice-info-wrap invoice-address">
                    <div class = "invoice-label">{{pickupLocationAddress}}</div>
                </div>
            </ng-template>
            <ng-template #merchantNameBlock>
                <div  class = "invoice-info-wrap">
                    <div class = "invoice-label invoice-address">{{merchantName}}</div>
                </div>
                <div class = "invoice-info-wrap invoice-address">
                    <div class = "invoice-label">{{merchantAddress}}</div>
                </div>
            </ng-template>
        </ng-template>

        <div *ngIf="isCorporateOrder; then corporateGSTBlock else nonCorporateGSTBlock"></div>
        <ng-template #corporateGSTBlock>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Statement No.</div>
                <div class = "invoice-value">{{invoiceNumber}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Statement Date</div>
                <div class = "invoice-value">{{invoiceDate}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Tax Category</div>
                <div class = "invoice-value">{{rapidoTaxCategory}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Place of Supply</div>
                <div class = "invoice-value">{{placeOfSupply}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Rapido GST Number</div>
                <div class = "invoice-value">{{gstTin}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Corporate GST Number</div>
                <div class = "invoice-value">{{merchantGstNumber}}</div>
            </div>
        </ng-template>
        <ng-template #nonCorporateGSTBlock>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Invoice No.</div>
                <div class = "invoice-value">{{invoiceNumber}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Invoice Date</div>
                <div class = "invoice-value">{{invoiceDate}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Tax Category</div>
                <div class = "invoice-value">{{rapidoTaxCategory}}</div>
            </div>
            <div class = "invoice-info-wrap">
                <div class = "invoice-label">Place of Supply</div>
                <div class = "invoice-value">{{placeOfSupply}}</div>
            </div>
            <div *ngIf="service === 'sme'; then merchantGSTBlock else customerGSTBlock"></div>
            <ng-template #customerGSTBlock>
                <div class = "invoice-info-wrap">
                    <div class = "invoice-label">GST</div>
                    <div class = "invoice-value">{{gstTin}}</div>
                </div>
            </ng-template>
            <ng-template #merchantGSTBlock>
                <div class = "invoice-info-wrap">
                    <div class = "invoice-label">Rapido GST Number</div>
                    <div class = "invoice-value">{{gstTin}}</div>
                </div>
                <div class = "invoice-info-wrap">
                    <div class = "invoice-label">Merchant GST Number</div>
                    <div class = "invoice-value">{{merchantGstNumber}}</div>
                </div>
            </ng-template>
        </ng-template>
     </div>

     <div class = "billdetails-wrap">
        <div class = "invoice-header">Bill Details</div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">Booking Fee</div>
            <div class = "invoice-value">{{bookingFeeWithoutTax}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider">
            <div class = "invoice-label">Convenience Charges</div>
            <div class = "invoice-value">{{convenienceFeeWithoutTax}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider" *ngIf="showDiscount()">
            <div class = "invoice-label green-text">Discount</div>
            <div class = "invoice-value green-text">{{discount}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider" *ngIf="showRapidoCoinDiscount()">
            <div class = "invoice-label green-text">Rapido Coin Discount</div>
            <div class = "invoice-value green-text">{{coinWithoutTax}}</div>
        </div>        
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">CGST {{cgst}}</div>
            <div class = "invoice-value">{{cgstVal}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-label">SGST {{sgst}}</div>
            <div class = "invoice-value">{{sgstVal}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider">
            <div class = "invoice-label">IGST {{igst}}</div>
            <div class = "invoice-value">{{igstVal}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-header">Final Amount</div>
            <div class = "invoice-header">{{finalAmount}}</div>
        </div>
        <div class = "invoice-terms">(Inclusive of Taxes)</div>


        <div *ngIf="isCorporateOrder; then corporateThanksBlock else nonCorporateThanksBlock"></div>
        <ng-template #corporateThanksBlock>
            <div class="thank-you-txt">Thank you</div>
        </ng-template>
        <ng-template #nonCorporateThanksBlock>
            <div *ngIf="service === 'sme'; then merchantThanksBlock else customerThanksBlock"></div>
            <ng-template #customerThanksBlock>
                <div class="thank-you-txt">Thank you {{customerName}}</div>
            </ng-template>
            <ng-template #merchantThanksBlock>
                <div class="thank-you-txt">Thank you {{merchantName}}</div>
            </ng-template>
        </ng-template>
    </div>
</div>