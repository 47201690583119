import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { FilterService } from '../filter.service';
import { CustomerDetailsService } from './customer-details.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { HelperService } from '../helper.service';
import { DataService } from '../data.service';


@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})

export class CustomerDetailsComponent implements OnInit {
  public generalDetails: any;
  public preferences: any;
  public referralInfo: any;
  public generalInfo: any;
  public accountInfo: any;
  public passInfo: any;
  public walletInfo: any;
  public coinPreference: any;
  public prevDueinfo: any;
  public stats = {};
  public statInfo: any = 'empty';
  public focus = false;
  public mobile = '';
  public mobileNumber: string;
  public id: string;
  // public tickets: any;
  public enteredNumber = false;
  public lastUsedDevice = '';
  public toggleableDetails = 'Order Details';
  public languageMap = {
    en : 'English',
    hi : 'Hindi',
    kn : 'Kannada',
    te : 'Telugu',
    ta : 'Tamil'
  };
  public customerDetailsToggleableHeaders: string[] = [
    'Order Details', 
    'Transaction Details', 
    'Activity Logs', 
    'Subscription Logs', 
    'Ticket Details', 
    'Customer Complaints'
  ];


  constructor(private route: ActivatedRoute,
              public filterService: FilterService,
              private toastr: ToastrService,
              private toasterService: ToasterService,
              private customerDetailService: CustomerDetailsService,
              private router: Router,
              private dataservice: DataService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.mobile = params.mobile || this.route.snapshot.queryParamMap.get('phone');
      if (!this.mobile) {
        return;
      }
      this.getUser();
    });
    this.dataservice.updateSearchCount(this.mobile);
  }

  refreshPage(pageRefresh){
    this.route.params.subscribe(params => {
      this.mobile = params.mobile || this.route.snapshot.queryParamMap.get('phone');
      if (!this.mobile) {
        return;
      }
      this.getUser();
    });
  }

  enteredMobile() {
    if (this.mobile.length > 0) {
      this.enteredNumber = true;
    } else {
      this.enteredNumber = false;
    }
  }

  getUser() {
    this.headersToggled('Order Details');
    const testMob = new RegExp('[0-9]{10}');
    if (!testMob.test(this.mobile)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter a valid 10-digit mobile number`,
      }));
    } else {
      this.router.navigate(['/customer/' + this.mobile]);
      this.generalInfo = null;
      this.accountInfo = null;
      this.walletInfo = null;
      this.passInfo = null;
      this.stats =  {};
      this.customerDetailService.getCustomerByMobile(this.mobile).subscribe(res => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: `User found`,
        }));
        this.generalDetails = res;
        this.accountInfo = {general: {...this.generalDetails}, pass: null,  status: this.generalDetails.data.active};
        this.mobileNumber = this.mobile;
        this.id = this.generalDetails.data._id;
        this.getPreference();
        this.getReferralDetails();
        this.getWallets();
        // this.getTickets(this.id);
        this.getPass();
        this.getLastLogin();
        this.getCustomerSegment();
        this.mobile = null;
        this.enteredNumber = false;
      },
      error => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch this user. Please check the number again`,
        }));
        this.mobileNumber = this.mobile;
        this.getStatInfo('empty');
        this.generalInfo = {dataFetched: false};
        this.accountInfo = {dataFetched: false};
        this.walletInfo = {dataFetched: false};
      });
    }
  }

  getWallets() {
    const data = this.generalDetails.data;
    const wallets = ['rapido', 'paytm', 'simpl', 'freecharge', 'lazypay'];
    const userData = {
      _id : data._id,
      firstName : data.firstName,
      mobile : data.mobile,
      email : data.email,
      roles : ['customer']
    };
    this.customerDetailService.getCustomerWallets(userData, wallets).subscribe(res => {
      if (res['info'].status === 'FAILURE') {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.NOTIFICATION,
          message: `Wallets do not exist for this user.`,
        }));
        this.walletInfo = {dataFetched: false};
      } else {
        const resp = {...res, dataFetched: true, id : this.generalDetails.data._id};
        this.walletInfo = resp;
      }
    }, err => {
      this.walletInfo = {dataFetched: false};
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to fetch wallets for this user. Please try again`,
      }));
    });

    this.customerDetailService.getPreviousDueTransactions(userData, this.generalDetails.data._id).subscribe(res => {
      const resp = {...res, dataFetched: true};
      this.prevDueinfo = resp
    }, err => {
      this.prevDueinfo = {dataFetched: false};
        console.log(err)
    });
    this.customerDetailService.getDebitPreference(userData).subscribe(res => {
      this.coinPreference = res && res["debitPreference"] ? res["debitPreference"].find(preference => preference.name === "coin").preference : false;
    }, err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to fetch debit preference. Please try again`,
      }));
    })
  }

  // getTickets(userId){
  //   this.customerDetailService.getTickets(userId).subscribe(result => {
  //     if(result && result['data'] && result['data']['status'] === "success"){
  //       let tickets = result['data']['data'];
  //       let ticketIds = tickets.map( ticket => {
  //         return ticket.ticket_id;
  //       })
  //       this.tickets = ticketIds;
  //     }else {
  //       this.tickets = [];
  //     }
  //   })
  // }

  headersToggled(toggledHeader) {
    this.toggleableDetails = toggledHeader;
  }

  changeStatus(status) {
    this.mobile = this.mobileNumber;
    this.getUser();
    // this.accountInfo = {...this.accountInfo, status: status};
  }

  getPass() {
    this.customerDetailService.getPassInfo(this.id).subscribe(res => {
      this.passInfo = res;
      this.accountInfo = {general: {...this.generalDetails}, pass: {...this.passInfo},  status: this.generalDetails.data.active};
    }, error => {
      this.accountInfo = {general: {...this.generalDetails}, pass: null,  status: this.generalDetails.data.active};
    });
  }

  getCustomerSegment() {
    this.customerDetailService.getCustomerSegment(this.id)
      .then((res: any) => {
        const segmentResponse = res.data;
        if (segmentResponse.found === true) {
          this.generalInfo = { ...this.generalInfo, customerSegment: segmentResponse.segment };
        } else {
          this.generalInfo = { ...this.generalInfo, customerSegment: "N/A" };
        }
      })
      .catch(err => {
        console.error('Error getting customer segment:', err);
        this.generalInfo = { ...this.generalInfo, customerSegment: "N/A" };
      });
  }
  

  getLastLogin() {
    this.customerDetailService.getLastLoginDate(this.id).subscribe(res => {
      if (res && res['data'] && res['data']['loginOn']) {
        const loginDate = this.formatDate(res['data']['loginOn']);
        this.getStatInfo({lastAppLogin : loginDate});
        this.lastUsedDevice = res['data']['device'];
        this.generalInfo = {...this.generalInfo, lastDevice: this.lastUsedDevice};
      } else {
        this.getStatInfo({lastAppLogin : 'N/A'});
      }
    }, err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to fetch last login for this user. Please try again`,
      }));
    });
  }

  getPreference() {
        const preferences = {'language' : 'N/A', 'communication' : ['N/A']};
        this.preferences = preferences;
        this.generalInfo = {details: {...this.generalDetails}, preferences: {...this.preferences},  lastDevice: this.lastUsedDevice,
                              referralInfo: this.referralInfo, dataFetched: true};
  }

  getReferralDetails() {
    this.customerDetailService.getReferralDetails(this.id).subscribe(referrals => {
      this.referralInfo = referrals;
      this.setGeneralInfo();
    },err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to get referral details for this user.`,
      }));
      this.referralInfo = []
      this.setGeneralInfo();
    })
  }

  private setGeneralInfo() {
    this.generalInfo = {
      details: { ...this.generalDetails }, preferences: { ...this.preferences }, lastDevice: this.lastUsedDevice,
      referralInfo: this.referralInfo, dataFetched: true
    };
  }

  getStatInfo($event) {
    if ($event && $event !== 'empty') {
      let tempStats;
      tempStats = {...$event, ...this.stats};
      this.stats = tempStats;
      if (this.stats['lastOrderDate'] && this.stats['lastAppLogin']) {
          this.stats['id'] = this.id;
        this.statInfo = this.stats;
      }
    } else {
      this.statInfo = 'empty';
    }
  }

  formatDate(date) {
    date = new Date(date);
    return HelperService.dateString(date);
    // let d = new Date(date),
    //     month = '' + (d.getMonth() + 1),
    //     day = '' + d.getDate(),
    //     year = d.getFullYear();

    // if (month.length < 2) {
    //   month = '0' + month;
    // }
    // if (day.length < 2) {
    //   day = '0' + day;
    // }
    // return [year, month, day].join('-');
  }
}
