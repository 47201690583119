import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MerchantService } from '../merchant.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-merchant-search',
  templateUrl: './merchant-search.component.html',
  styleUrls: ['./merchant-search.component.css']
})
export class MerchantSearchComponent implements OnInit {
  mobile = '';
  public enteredNumber = false;
  public focus = false;
  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private merchantService: MerchantService,
    private toasterService: ToasterService,
    private dataservice: DataService
  ) { }

  ngOnInit() {
    this.mobile = this.activedRoute.snapshot.paramMap.get('id');
    this.activedRoute.parent.params.subscribe(params => {
    });
  }

  async searchMerchant() {
    if (!this.isValidMobileNumber(this.mobile)) {
      return;
    }
    this.mobile = this.mobile;
    this.searchMobileNumber();
  }

  private isValidMobileNumber(mobile) {
    const testMob = new RegExp('[0-9]{10}');
    if (!testMob.test(mobile)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter a valid 10-digit mobile number`,
      }));
      return false;
    }
    return true;
  }

  searchMobileNumber() {
    this.merchantService.getMerchantByMobile(this.mobile)
    .subscribe(data => {
      if (data && data['data']) {
        this.dataservice.updateSearchCount(this.mobile);
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.SUCCESS,
          message: `User found`,
        }));
        this.router.navigate([`/merchant/${this.mobile}`]);
        this.mobile = '';
        document.getElementById('searchMerchant').blur();
      } else {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: `Unable to fetch this merchant; Please check the number again`,
          config: {
          }
        }));
      }
    }, err => {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Unable to fetch this merchant; Please check the number again`,
        config: {
        }
      }));
    });
  }
}
