import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Table, Pagination, TableActions, TableAction, TableData, TableDataTypes, Button } from '../shared/types';
import { PageEvent } from '@angular/material';
import { STRING } from '../shared/constants';
import * as _ from 'lodash';
import { faRedo, faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FilterService } from '../filter.service';
import { HelperService } from '../helper.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

const ELEMENT_DATA: any[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];

const OrderData: any[] = [
  {Date: '24-01-19', OrderId: 'ABC123', Service: 'Link', City: 'Bangalore', 
    Pickup: '24, HSR sector 3 24, HSR sector 3 24, HSR sector 3 24, HSR sector 3', Drop: 'Tea Villa Cafe',
  CaptainName: 'XYZ', Fare: '20', Cashback: '0', Refund: '0', Coupons: 'N/A', Ratings: '4'},
  {Date: '24-01-19', OrderId: 'ABC123', Service: 'Link', City: 'Bangalore', Pickup: '24, HSR sector 3', Drop: 'Tea Villa Cafe',
  CaptainName: 'XYZ', Fare: '20', Cashback: '0', Refund: '0', Coupons: 'N/A', Ratings: '4'},
];

@Component({
  selector: 'app-table-scrollable',
  templateUrl: './table-scrollable.component.html',
  styleUrls: ['./table-scrollable.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableScrollableComponent implements OnInit {
  faRefresh = faRedo;
  faSearch = faSearch;
  faFilter = faFilter;
  TableDataTypes = TableDataTypes;
  @Input() table: Table;
  @Input() filterNotRequired: Boolean;
  @Input() innerTableDataSource: any[];
  @Input() isCustomerComplaintsTable: Boolean;
  @Input() innerTableDisplayedColumns : string[];
  @Output() paginationChange = new EventEmitter();
  @Output() headerToSort = new EventEmitter();
  @Output() headerToggled = new EventEmitter();
  displayedColumns: string[];
  sortStatus = {};
  headers = [];

  constructor(
    public filterService: FilterService,
  ) {
    // if (!this.table) {
    //   throw Error('Table is Not Available in input');
    // }
    // this.displayedColumns =  Object.keys(this.table.headers);
    // this.headers = this.displayedColumns.map(col => this.table.headers[col]);

  }

  ngOnInit() {
    if (!this.table) {
      this.table = this.fakeTable();
    }
    this.displayedColumns =  Object.keys(this.table.headers);
    this.headers = this.displayedColumns.map(col => this.table.headers[col]);
    for (const x of this.displayedColumns) {
      this.sortStatus[x] = 'None';
    }
  }

  alert(val) {
  }

  toggleChange(header) {
    this.headerToggled.emit(header);
  }

  onRefresh() {
    this.table.emitRefresh();
  }

  onSearch(data) {
    this.table.emitSearch(data);
  }

  fakeTable() {
    return new Table({
      data: this.fakeData(),
      headers: {
        Date: 'Date',
        OrderId: 'Order Id',
        Service: 'Service',
        City: 'City',
        Pickup: 'Pickup',
        Drop: 'Drop',
        CaptainName: 'Captain Name',
        Fare: 'Fare',
        Cashback: 'Cashback',
        Refund: 'Refund',
        Coupons: 'Coupons',
        Ratings: 'Ratings'
      },
      pagination: new Pagination(0, 10, 10, [10, 20])
    });
  }

  doAction(index, cb) {
    if (cb && typeof cb === STRING.FUNCTION) {
      cb(this.table.realData[index]);
    }
  }

  doSelectAction(index, headerName, buttonName) {
    const realAction = this.table.data[index] &&
                      this.table.data[index][headerName] &&
                      this.table.data[index][headerName].actions &&
                      this.table.data[index][headerName].actions.actions.find(a => {
      return a.text === buttonName;
    });
    if (realAction) {
      if (realAction && typeof realAction.onClick === STRING.FUNCTION ) {
        realAction.onClick(this.table.realData[index]);
      }
    }
  }

  callFnc(index, fnc) {
    const data = this.table.realData[index];
    if (fnc && typeof fnc === 'function') {
      fnc(data);
    }
  }

  getInnerTableDataSource(element) {
    const data =  this.innerTableDataSource.filter((item) => {
      return Object.keys(item)[0] ==  element.reason.data;
    });
    return data[0][element.reason.data];
  }

  fakeData() {
    // return ELEMENT_DATA.map(data => {
    //   data.action = new TableData({
    //     actions: new TableActions([
    //       new TableAction({
    //         onClick: () => {
    //           console.log('clicked');
    //         },
    //         text: 'Test'
    //       })
    //     ]),
    //     type: TableDataTypes.ACTION,
    //   });
    //   data.buttonAction = new TableData({
    //     type: TableDataTypes.BUTTON,
    //     button: new Button({
    //       className: 'test',
    //       label: 'testing',
    //       onClick: ()=>{console.log('clicked')}
    //     })
    //   });
    //   return data;
    // });

    return OrderData;
  }

  onPageChange(event: PageEvent) {
    this.table.pagination.previousPageIndex = event.previousPageIndex;
    this.table.pagination.pageNo = event.pageIndex;
    this.table.pagination.perPage = event.pageSize;
    this.paginationChange.emit(this.table.pagination);
  }

  private assignAppliedFilters(newFilter = {}) {
    Object.keys(this.filterService.appliedFilters).forEach(key => {
      delete this.filterService.appliedFilters[key];
    });
    Object.assign(this.filterService.appliedFilters, newFilter);
  }


  onFilter() {
    const filter = HelperService.copyObj(this.table.appliedFilters);
    this.assignAppliedFilters(filter);
    this.filterService.defineFilters(this.table.filterComponents, (filters) => {
      this.table.emitFilter(filters);
    });
    this.filterService.filterToggle();
  }

    clickedHeader(event: string) {
      const headerName = (_.invert(this.table.headers))[event];
      let valToEmit = '';
      if (this.sortStatus[headerName] === 'None' || this.sortStatus[headerName] === 'desc') {
        this.sortStatus[headerName] = 'asc';
        valToEmit = headerName;
      } else {
        this.sortStatus[headerName] = 'desc';
        valToEmit = '-' + headerName;
      }
      for (const x in this.sortStatus) {
        if (x !== headerName) {
          this.sortStatus[x] = 'None';
        }
      }
      this.headerToSort.emit(valToEmit);
    }
}
