import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import {CaptainCallLogsService} from '../../captain-call-logs.service';

@Component({
    selector: 'app-captain-call-logs',
    templateUrl: './captain-call-logs.component.html'
})
export class CaptainCallLogsComponent implements OnInit {
    @Input() captainId: string;
    @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
    @Input() captainDetailsToggleableHeaders: string[];
    captainCallLogs: Table;
    dataLoaded = false;

    constructor(public captainCallLogsService: CaptainCallLogsService) { }

    ngOnInit() {
        this.declareTable();
    }

    declareTable() {
        this.captainCallLogs = new Table({
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            headers: {
                timeOfCall: 'Time of call',
                orderID: 'Order Id',
                reasonForCall: 'Reason for call',
                remarks: 'Remarks',
            },
            pagination: new Pagination(0, 10, 30, [10, 15, 20], 0, true),
            selectedHeader: 8,
            defaultError: 'No Call Logs found',
            onRefresh: () => {
                this.captainCallLogs = null;
                this.ngOnInit();
            },
            data: []
        });
        this.getCallLogs();
    }

    async getCallLogs(pageNumber = 1, perPage = 10) {
        this.dataLoaded = false;
        this.captainCallLogsService.getCaptainCallLogs(this.captainId, pageNumber, perPage)
            .then((response) => {
                const logs = response.result.callLogs.logs;
                const totalLogs = response.result.callLogs.count;
                this.captainCallLogs.data = logs.map(callLog => {
                    return {
                        timeOfCall: this.createTableData(callLog.startTime),
                        orderID: this.createTableData(callLog.orderId ? callLog.orderId : 'NA'),
                        reasonForCall: this.createTableData(callLog.subDisposition ? callLog.subDisposition : 'NA'),
                        remarks: this.createTableData(callLog.remarks ? callLog.remarks : 'NA'),
                    };
                });
                this.captainCallLogs.pagination =  new Pagination((pageNumber - 1), perPage, totalLogs, [10, 15, 20], 0, true),
                this.dataLoaded = true;
            }).catch(err => {
            console.log(err);
            this.captainCallLogs.data = [];
            this.captainCallLogs.defaultError = err.error.message || 'Error while fetching CallLogs,Please try Later.';
            this.dataLoaded = true;
        });
    }

    dateString(epoch: number): string {
        return new Date(epoch * 1000).toLocaleString();
    }

    createTableData(value: string, className?: string, type?: TableDataTypes, flag = false, hoverData = null) {
        return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
        });
    }

    changeInHeader(header: string) {
        this.toggleHeaders.emit(header);
    }

    onPaginationChange(event: { pageNo: number, perPage: number; }) {
        this.getCallLogs((event.pageNo + 1), event.perPage);
    }
}
