<div class="order-timeline">
  <div class="row">
    <div class="col-md-2">
      <div class="order-title margin-10-12">Fraud Details</div>
    </div>
  </div>

  <div class="line"></div>

  <div *ngIf="dataFetched">
    <div class="row padding-bottom-18">
      <div class="col-md-4">
        <span class="margin-left-12 display-inline-block margin-top-13 font-size-14 font-weight-600">Fraud Type: 
          <span [ngStyle]="{'color' : 'black'}">
            {{fraudInfoToDisplay?.types}}
          </span>
        </span><br>
        <div *ngIf="fraudDetails?.tags && fraudDetails?.tags?.length !== 0;then tags else nil"></div>
        <ng-template #tags>
          <span class="margin-left-12 display-inline-block margin-top-8 font-size-14 font-weight-600">
            Fraud tags :
            <span [ngStyle]="{'color' : 'black'}" class="fraud-tags" *ngFor="let tag of fraudDetails?.tags">
              {{tag}}
            </span>
          </span>
        </ng-template>
        <ng-template #nil>
          <span class="margin-left-12 display-inline-block margin-top-8 font-size-14 font-weight-600">Fraud tags:
            <span [ngStyle]="{'color' : 'black'}" class="font-size-13">Nil</span>
          </span>
        </ng-template>
      </div>

      <div class="col-md-2 margin-top-13 font-size-14 font-weight-600" style="display: flex; align-items: center;">
        Fraud Reason: 
        <div class="status-indicator display-inline-block background-color-cd1f1e"
        [ngStyle]="{'background-color': '#5fba00'}" class="pulse"></div>
        <span class="font-size-13 font-weight-600" [ngStyle]="{'color' : 'black'}" >
          {{fraudInfoToDisplay?.causes}}
        </span>
      </div>
      <div class="col edit-add-button">
          <button class="save font-size-13 font-weight-600" (click)="editFraudDetails()">
            Add/Edit
          </button>
      </div>
    </div>
  </div>

  <div *ngIf="!dataFetched" class="noDataWrapper">
    <span>No data found ¯\_(ツ)_/¯</span>
  </div>
</div>