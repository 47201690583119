import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerTransactionDetailsService {

  constructor(private http: MyHttpClient) { }
  server = `${environment.server}`;
  getCustomerPassbook = this.server + '/api/payments/getCustomerPassbook';

  getCustomerPassbookDetails(customerId, limit, offset, userId, filters: any = {}) {
    return this.http.post(this.getCustomerPassbook, {customerId, limit, offset, userId, filters})
    .pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
