import { ElementRef, OnInit, TemplateRef } from '@angular/core';
import { EntityService } from '../entity.service';
import { DatePipe } from "@angular/common";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DemandAreasService } from './demand-areas.service';
import { MatDialog } from '@angular/material/dialog';
import * as _ from "lodash";
var DemandAreasComponent = /** @class */ (function () {
    function DemandAreasComponent(entityService, demandAreasService, dialog) {
        this.entityService = entityService;
        this.demandAreasService = demandAreasService;
        this.dialog = dialog;
        this.cities = [];
        this.services = [];
        this.demandAreas = [];
        this.times = [];
        this.allTimes = _.range(24);
        this.polygons = [];
        this.datePipe = new DatePipe("en");
        this.maxDate = new Date();
        this.demandAreasFormGroup = new FormGroup({
            selectedCity: new FormControl(null, Validators.required),
            selectedServices: new FormControl(null, Validators.required),
            selectedDate: new FormControl(null, Validators.required),
        });
    }
    DemandAreasComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.demandAreasService.getCities().subscribe(function (cities) {
            var _a;
            (_a = _this.cities).push.apply(_a, cities);
        });
        this.mapProperties = {
            center: new google.maps.LatLng(12.998337399998206, 77.62443977505797),
            zoom: 10,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            clickableIcons: false,
            gestureHandling: "cooperative",
        };
        this.map = new google.maps.Map(this.gmap.nativeElement, this.mapProperties);
        this.maxDate.setDate(this.maxDate.getDate() + 6);
    };
    DemandAreasComponent.prototype.getServicesForSelectedCity = function () {
        var _this = this;
        this.demandAreasFormGroup.patchValue({ selectedServices: null });
        this.services = [];
        var selectedCity = this.demandAreasFormGroup.get("selectedCity").value;
        if (selectedCity) {
            this.map.setCenter(new google.maps.LatLng(selectedCity.latitude, selectedCity.longitude));
            this.entityService.getServicesPerCity(selectedCity._id).subscribe(function (res) {
                var services = res["data"];
                services.map(function (service) {
                    _this.services.push({
                        value: service["service"]["_id"],
                        name: service["service"]["name"],
                    });
                });
            });
        }
    };
    DemandAreasComponent.prototype.submit = function () {
        var _this = this;
        this.times = [];
        this.demandAreas = [];
        this.currentSelectedTime = null;
        this.setAllPolygons(null);
        this.polygons = [];
        var city = this.demandAreasFormGroup.get("selectedCity").value;
        var services = this.demandAreasFormGroup.get("selectedServices").value;
        var date = this.datePipe.transform(this.demandAreasFormGroup.get("selectedDate").value, "dd-MM-yyyy");
        var time = "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23";
        if (!city) {
            this.openAlertDialog("Please select city to proceed");
            return;
        }
        if (!services || services.length == 0) {
            this.openAlertDialog("Please select services to proceed");
            return;
        }
        if (!date) {
            this.openAlertDialog("Please select date to proceed");
            return;
        }
        this.demandAreasService
            .fetchDemandAreas(city._id, services, date, time)
            .subscribe({
            next: function (res) {
                if (res["demandAreas"].length == 0) {
                    _this.alertText =
                        "No Demand Areas found for your selection";
                    _this.dialog.open(_this.alertDialog);
                }
                res["demandAreas"].map(function (demandArea) {
                    _this.demandAreas.push(demandArea);
                    _this.times.push(demandArea["time"]);
                });
            },
            error: function () {
                _this.openAlertDialog("NDL service might be down, please try again later");
                return;
            },
        });
    };
    DemandAreasComponent.prototype.plotHexesForTime = function (selection) {
        var _this = this;
        this.currentSelectedTime = selection;
        this.setAllPolygons(null);
        this.polygons = [];
        var demandArea = this.demandAreas.filter(function (demandArea) { return demandArea["time"] === selection; });
        demandArea[0]["areas"].map(function (area) {
            var points = [];
            area["hexes"].map(function (hex) {
                points.push(hex["points"]);
            });
            _this.polygons.push(new google.maps.Polygon({
                paths: points,
                fillOpacity: 0.4,
                fillColor: "#FDA13A",
                strokeOpacity: 0,
            }));
        });
        this.setAllPolygons(this.map);
        this.map.setZoom(13);
    };
    DemandAreasComponent.prototype.setAllPolygons = function (map) {
        this.polygons.map(function (polygon) {
            polygon.setMap(map);
        });
    };
    DemandAreasComponent.prototype.openAlertDialog = function (alertMessage) {
        this.alertText = alertMessage;
        this.dialog.open(this.alertDialog);
    };
    return DemandAreasComponent;
}());
export { DemandAreasComponent };
