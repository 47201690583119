var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MerchantService } from '../merchant.service';
import { CustomerDetailsService } from '../../customer-details/customer-details.service';
var MerchantDetailsComponent = /** @class */ (function () {
    function MerchantDetailsComponent(route, toasterService, merchantService, customerDetailsService) {
        this.route = route;
        this.toasterService = toasterService;
        this.merchantService = merchantService;
        this.customerDetailsService = customerDetailsService;
        this.merchantMobile = '';
        this.merchantId = '';
        this.merchantUserId = '';
        this.merchantCity = '';
        this.merchantFound = false;
        this.toggleableDetails = 'Order Details';
    }
    MerchantDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.getMerchantInfo();
        });
        this.getMerchantInfo();
    };
    MerchantDetailsComponent.prototype.getMerchantInfo = function () {
        var _this = this;
        this.toggleableDetails = 'Order Details';
        this.merchantMobile = this.route.snapshot.params.mobile;
        this.merchantService.getMerchantByMobile(this.merchantMobile).subscribe(function (result) {
            _this.merchantFound = true;
            _this.merchantId = result['data']._id;
            _this.merchantCity = result['data'].location && result['data'].location.city;
            _this.generalInfo = __assign({}, _this.generalInfo, result['data']);
            _this.accountInfo = __assign({}, _this.accountInfo, result['data']);
            _this.walletInfo = __assign({}, _this.walletInfo, result['data']);
        }, function (error) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Merchant not found",
            }));
        });
        this.customerDetailsService.getCustomerByMobile(this.merchantMobile).subscribe(function (result) {
            if (result && result['data']) {
                _this.accountInfo = __assign({}, _this.accountInfo, { appVersion: result['data'].appVersion });
                _this.generalInfo = __assign({}, _this.generalInfo, { userId: result['data']._id });
                _this.walletInfo = __assign({}, _this.walletInfo, { userId: result['data']._id });
                _this.merchantUserId = result['data']._id;
            }
        });
    };
    MerchantDetailsComponent.prototype.headersToggled = function (toggledHeader) {
        this.toggleableDetails = toggledHeader;
    };
    return MerchantDetailsComponent;
}());
export { MerchantDetailsComponent };
