export class Campaign {
  constructor(public name?: string, public count?: number) {}
}

export class HandHoldingCaptain {
  constructor(
    public captainId?: string,
    public campaigns?: Campaign[],
    public connectedCallCount?: number,
    public createdAt?: string
  ) {}
}
