import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from 'ng5-slider';

@Component({
  selector: 'range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.css']
})

export class RangeSliderComponent implements OnInit, OnChanges {
  @Input() rangeData: any;
  @Output() selectedRange: EventEmitter<any> = new EventEmitter();
  @Input() value: any = {
    from: '',
    to: '',
  };
  sliderForm: FormGroup;
  options: Options;

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    this.ngOnInit();
  }
  ngOnInit() {
    // this.rangeData = {
    //     type : 'range-slider',
    //     title : 'Cashback',
    //     values : [0, 50],
    //     default : '',
    //     key : 'cashback-range'
    //   };
    this.value = this.value || {};
    this.rangeData.values[0] = parseInt(this.rangeData.values.from, 10);
    this.rangeData.values[1] = parseInt(this.rangeData.values.to, 10);
      this.options = {
        floor: this.rangeData.values[0],
        ceil: this.rangeData.values[1]
      };

      this.sliderForm = new FormGroup({
        sliderControl: new FormControl([this.value.from || this.rangeData.values.from, this.value.to || this.rangeData.values.to])
      });
  }

  resetForm(): void {
    this.sliderForm.reset({sliderControl: [20, 80]});
  }

  chosenOptions() {
    const emitSelections = {
      [this.rangeData.key] : {from : this.sliderForm.value.sliderControl[0], to : this.sliderForm.value.sliderControl[1]}
    };
    this.selectedRange.emit(emitSelections);
  }
}
