var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ENTITY_TYPES, OrderService } from 'src/app/order-service.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { CustomerOrderDetailsService } from './customer-order-details.service';
import { FilterService, SearchableDropDownFilterInput, SingleSearchableDropdown, QuickFilters } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { DataService } from '../../data.service';
import { FraudService } from '../../fraud-component/fraud-view.service';
import { Router } from '@angular/router';
var currentDate = new Date();
var startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
export var DEFAULT_DATE = {
    FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)),
    TO_DATE: currentDate
};
function defaultFiters() {
    var startDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)));
    var endDuration = HelperService.getDateOnlyV1(currentDate);
    return {
        startDuration: startDuration,
        endDuration: endDuration,
        cities: [],
        services: [],
        status: 'all'
    };
}
var CustomerOrderDetailsComponent = /** @class */ (function () {
    function CustomerOrderDetailsComponent(orderService, filterService, entityService, customerOrderDetailsService, dataService, router, fraudService) {
        this.orderService = orderService;
        this.filterService = filterService;
        this.entityService = entityService;
        this.customerOrderDetailsService = customerOrderDetailsService;
        this.dataService = dataService;
        this.router = router;
        this.fraudService = fraudService;
        this.lastOrderDate = new EventEmitter();
        this.toggleHeaders = new EventEmitter();
        this.fromDate = DEFAULT_DATE.FROM_DATE;
        this.toDate = DEFAULT_DATE.TO_DATE;
        this.filtersApplied = defaultFiters();
        this.cities = [];
        this.services = [];
        this.statusList = [
            'all',
            'new',
            'customerCancelled',
            'onTheWay',
            'arrived',
            'started',
            'dropped',
            'expired',
            'aborted'
        ];
    }
    CustomerOrderDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.customerMobile) {
            this.customerMobile = changes.customerMobile.currentValue;
            if (this.customerMobile) {
                this.defineOrderTable(this.customerMobile, DEFAULT_DATE.FROM_DATE, DEFAULT_DATE.TO_DATE, this.filtersApplied);
            }
        }
    };
    CustomerOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defineOrderTable(this.customerMobile);
        this.entityService.getCities().subscribe(function (cities) {
            var _a;
            (_a = _this.cities).push.apply(_a, cities);
        });
    };
    CustomerOrderDetailsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CustomerOrderDetailsComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.dataService.searchUserCount.subscribe(function (result) {
            if (!_this.currentNumber || (_this.currentNumber === result)) {
                _this.filterService.appliedFilters = _this.filtersApplied;
            }
            else {
                _this.filterService.appliedFilters = {};
                _this.filtersApplied = defaultFiters();
            }
            _this.currentNumber = result;
        });
        this.orderTable = new Table({
            // tableHeader: 'Order Details',
            toggleableHeaders: this.customerDetailsToggleableHeaders,
            selectedHeader: 0,
            headers: {
                dateTime: 'Date \n Time',
                orderIdCity: 'Order Id \n City',
                pickupDrop: 'Pickup \n Drop',
                captainName: 'Captain Name',
                offerTypeCode: 'Offer Type \n Offer Code',
                discountCashback: 'Discount \n Cashback',
                fare: 'Fare',
                adjustment: 'Total Adjustment',
                status: 'Status',
                ratings: 'Ratings'
            },
            pagination: new Pagination(pageNumber, 10),
            config: {
                refresh: true,
            },
            onRefresh: function () {
                _this.orderTable = null;
                _this.ngOnInit();
            },
            appliedFilters: this.filtersApplied,
            filterComponents: [
                new QuickFilters({
                    key: 'duration',
                    default: '',
                    title: 'Duration',
                }),
                new SearchableDropDownFilterInput({
                    key: 'cities',
                    title: 'Cities',
                    values: this.cities,
                    onChange: function (value) {
                        _this.entityService.getServices(value).subscribe(function (services) {
                            var _a;
                            _this.services.splice(0);
                            (_a = _this.services).push.apply(_a, services);
                        });
                    }
                }),
                new SearchableDropDownFilterInput({
                    key: 'services',
                    title: 'Services',
                    values: this.services,
                }),
                new SingleSearchableDropdown({
                    key: 'status',
                    title: 'Status',
                    values: this.statusList,
                }),
            ],
            onFilter: function () {
                var timeSlot = _this.filterService.appliedFilters.duration || ['', ''];
                var duration = [];
                if (!Array.isArray(timeSlot)) {
                    duration.push(timeSlot['start']);
                    duration.push(timeSlot['end']);
                }
                else {
                    duration = timeSlot;
                }
                var startDuration = duration[0];
                var endDuration = duration[1];
                var cities = _this.filterService.appliedFilters.cities;
                var services = _this.filterService.appliedFilters.services;
                var status = _this.filterService.appliedFilters.status;
                var filtersApplied = {
                    duration: {
                        start: startDuration,
                        end: endDuration,
                    },
                    startDuration: startDuration,
                    endDuration: endDuration,
                    cities: cities,
                    services: services,
                    status: status,
                };
                Object.assign(_this.filtersApplied, filtersApplied);
                var isValidNumber = function (value) {
                    return ((parseInt(value, 10) === 0) || value);
                };
                if ((startDuration && endDuration) || cities || services) {
                    startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
                    endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
                    return _this.defineOrderTable(_this.customerMobile, startDuration, endDuration, _this.filtersApplied);
                }
                return _this.defineOrderTable(_this.customerMobile, undefined, undefined, _this.filtersApplied);
            },
            data: []
        });
    };
    CustomerOrderDetailsComponent.prototype.onPaginationChange = function (event) {
        this.fillDataToTable(undefined, undefined, this.filtersApplied);
    };
    CustomerOrderDetailsComponent.prototype.getOrderList = function (customerMobile, fromDate, toDate, filters) {
        this.filterCount = 0;
        var filterKeys = Object.keys(this.filtersApplied);
        var ignoreKeys = ['startDuration', 'endDuration'];
        var dateFilter = [];
        if (this.filtersApplied && this.filtersApplied['duration']) {
            if (this.filtersApplied['duration'].start) {
                dateFilter.push(this.filtersApplied['duration'].start);
            }
            if (this.filtersApplied['duration'].end) {
                dateFilter.push(this.filtersApplied['duration'].end);
            }
        }
        if (dateFilter.length > 0) {
            this.filterCount++;
        }
        for (var i = 0; i < filterKeys.length; i++) {
            if (Array.isArray(this.filtersApplied[filterKeys[i]]) && (this.filtersApplied[filterKeys[i]]).length > 0) {
                this.filterCount++;
            }
            else {
                if (this.filtersApplied[filterKeys[i]] && ignoreKeys.indexOf(filterKeys[i]) === -1
                    && (this.filtersApplied[filterKeys[i]]).length > 0) {
                    this.filterCount++;
                }
            }
        }
        return this.orderService.getOrdersForUser(this.customerId, this.orderTable.pagination, fromDate, toDate, ENTITY_TYPES.CUSTOMER, filters);
    };
    CustomerOrderDetailsComponent.prototype.calculateAdjustmentsByOrderId = function (adjustments) {
        var STRING = {
            CREDIT: 'credit',
            ADJUSTMENTS: 'adjustment',
            DEBIT: 'debit'
        };
        var adjustmentsHistory = {};
        var totalAdjustment = {};
        function toBeAdded(adjustment) {
            return adjustment.transactionType === STRING.CREDIT ? true : false;
        }
        function isProperTransactionType(adjustment) {
            return adjustment.title === STRING.ADJUSTMENTS &&
                (adjustment.transactionType === STRING.CREDIT || adjustment.transactionType === STRING.DEBIT);
        }
        adjustments.forEach(function (adjustment) {
            if (!adjustment) {
                return;
            }
            if (isProperTransactionType(adjustment)) {
                totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] || 0;
                if (toBeAdded(adjustment)) {
                    totalAdjustment[adjustment.orderId] += adjustment.originalAmount;
                }
                else {
                    totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] - adjustment.originalAmount;
                }
            }
        });
        for (var adjustment in totalAdjustment) {
            if (!this.isEmpty(totalAdjustment[adjustment])) {
                totalAdjustment[adjustment] = totalAdjustment[adjustment].toString();
            }
        }
        return totalAdjustment;
    };
    CustomerOrderDetailsComponent.prototype.fillDataToTable = function (fromDate, toDate, filters) {
        var _this = this;
        if (this.customerMobile) {
            this.orderTable.dataWillLoad();
            this.getOrderList(this.customerMobile, fromDate, toDate, filters).subscribe(function (_a) {
                var orders = _a.orders, count = _a.count;
                return __awaiter(_this, void 0, void 0, function () {
                    function createTableData(value, className, type, flag, hoverData) {
                        if (flag === void 0) { flag = false; }
                        return new TableData({
                            data: value,
                            type: type ? type : TableDataTypes.DATA,
                            flag: flag,
                            hoverData: hoverData ? hoverData : null,
                            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                            onClick: function (data) {
                                var newRelativeUrl = self.router.createUrlTree(["/order/" + data._id.data]);
                                var baseUrl = window.location.href.replace(self.router.url, '');
                                window.open(baseUrl + newRelativeUrl, '_blank');
                            }
                        });
                    }
                    var orderIds, self, adjustmentsHistory, orderIdss, adjustments, orderIdToAdjustmentsMap, _b;
                    var _this = this;
                    return __generator(this, function (_c) {
                        switch (_c.label) {
                            case 0:
                                orderIds = [];
                                this.sendInfo = { lastOrderDate: (orders && orders[0] && orders[0].date) ? HelperService.dateString(orders[0].date) : ' - ' };
                                this.lastOrderDate.emit(this.sendInfo);
                                self = this;
                                adjustmentsHistory = {};
                                orderIdss = orders.map(function (o) { return o._id; });
                                return [4 /*yield*/, this.customerOrderDetailsService.getAdjustmentAmounts(orderIdss, this.customerId).toPromise()];
                            case 1:
                                adjustments = _c.sent();
                                orderIdToAdjustmentsMap = this.calculateAdjustmentsByOrderId(adjustments);
                                _b = this;
                                return [4 /*yield*/, this.fraudService.checkFraudOrders({ orders: orderIdss }).toPromise()];
                            case 2:
                                _b.fraudOrdersList = _c.sent();
                                this.orders = orders.map(function (order, i) {
                                    var orderId = order._id;
                                    orderIds.push(orderId);
                                    var self = _this;
                                    var orderDate = new Date(order.date);
                                    var couponStatus = order.couponCode && order.couponApplied;
                                    var offerStatus = order.offerCode && order.offerApplied;
                                    var flag = _this.fraudOrdersList.orders[orderId] ? true : false;
                                    return {
                                        _id: createTableData(orderId),
                                        dateTime: createTableData(HelperService.dateString(orderDate) + '\n' + order.orderTime, null, null, flag),
                                        orderIdCity: new TableData({
                                            data: order.orderId + '\n' + order.city,
                                            type: TableDataTypes.DATA,
                                            className: 'cursor-pointer whiteSpacePre',
                                            onClick: function (data) {
                                                var newRelativeUrl = self.router.createUrlTree(["/order/" + data._id.data]);
                                                var baseUrl = window.location.href.replace(self.router.url, '');
                                                window.open(baseUrl + newRelativeUrl, '_blank');
                                            }
                                        }),
                                        pickupDrop: createTableData(order.pickup + '\n' + order.drop),
                                        offerTypeCode: createTableData((order.offerType ? order.offerType + '\n' : '') +
                                            (order.offerCode ? (order.offerCode) : (order.couponCode ? (order.couponCode) : '')), offerStatus || couponStatus ? 'status-green whiteSpacePre' : 'status-red whiteSpacePre'),
                                        discountCashback: createTableData(order.discount + '\n' + order.cashback, 'preserve-whitespace'),
                                        captainName: createTableData(order.riderName),
                                        fare: createTableData(order.earnings),
                                        ratings: createTableData(order.riderRating),
                                        adjustment: createTableData(orderIdToAdjustmentsMap[orderId]),
                                        status: createTableData(order.status, 'capitalize whiteSpacePre', null, false, order.cancelReason || null)
                                    };
                                });
                                this.orderTable.data = this.orders;
                                this.orderTable.filterCount = this.filterCount;
                                this.orderTable.pagination.count = count;
                                return [2 /*return*/];
                        }
                    });
                });
            });
            if (!this.sendInfo) {
                this.lastOrderDate.emit('empty');
            }
        }
    };
    CustomerOrderDetailsComponent.prototype.calculateAdjustment = function (adjustments) {
        if (adjustments && adjustments.length > 0) {
            var finalAdjustment = 0;
            for (var adj in adjustments) {
                if (adjustments[adj]) {
                    switch (adjustments[adj].type) {
                        case 'credit':
                            finalAdjustment += adjustments[adj].amount;
                            break;
                        case 'debit':
                            finalAdjustment -= adjustments[adj].amount;
                            break;
                        default: break;
                    }
                }
            }
            return (finalAdjustment > 0 ? '+' + finalAdjustment.toString() : finalAdjustment.toString());
        }
    };
    CustomerOrderDetailsComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    CustomerOrderDetailsComponent.prototype.defineOrderTable = function (customerMobile, fromDate, toDate, filters) {
        if (fromDate === void 0) { fromDate = DEFAULT_DATE.FROM_DATE; }
        if (toDate === void 0) { toDate = DEFAULT_DATE.TO_DATE; }
        if (filters === void 0) { filters = this.filtersApplied; }
        this.declareTable();
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.fillDataToTable(fromDate, toDate, filters);
    };
    return CustomerOrderDetailsComponent;
}());
export { CustomerOrderDetailsComponent };
