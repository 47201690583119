/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./captain-failed-orders.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../table-scrollable/table-scrollable.component.ngfactory";
import * as i3 from "../../table-scrollable/table-scrollable.component";
import * as i4 from "../../filter.service";
import * as i5 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/common";
import * as i9 from "./captain-failed-orders.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../toaster.service";
import * as i12 from "../../order-service.service";
import * as i13 from "../../entity.service";
import * as i14 from "@angular/router";
import * as i15 from "../../data.service";
import * as i16 from "./captain-failed-orders.service";
import * as i17 from "../../svo.service";
var styles_CaptainFailedOrdersComponent = [i0.styles];
var RenderType_CaptainFailedOrdersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CaptainFailedOrdersComponent, data: {} });
export { RenderType_CaptainFailedOrdersComponent as RenderType_CaptainFailedOrdersComponent };
function View_CaptainFailedOrdersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-scrollable", [], null, [[null, "paginationChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paginationChange" === en)) {
        var pd_0 = (_co.onPaginationChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TableScrollableComponent_0, i2.RenderType_TableScrollableComponent)), i1.ɵdid(1, 114688, null, 0, i3.TableScrollableComponent, [i4.FilterService], { table: [0, "table"] }, { paginationChange: "paginationChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orderTable; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CaptainFailedOrdersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-scrollable", [], null, null, null, i2.View_TableScrollableComponent_0, i2.RenderType_TableScrollableComponent)), i1.ɵdid(1, 114688, null, 0, i3.TableScrollableComponent, [i4.FilterService], { table: [0, "table"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dailyPingsTable; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CaptainFailedOrdersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-scrollable", [], null, null, null, i2.View_TableScrollableComponent_0, i2.RenderType_TableScrollableComponent)), i1.ɵdid(1, 114688, null, 0, i3.TableScrollableComponent, [i4.FilterService], { table: [0, "table"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hourlyPingsTable; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CaptainFailedOrdersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["style", "cursor: pointer;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirectToCaptainDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Captain details "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaIconService], { iconProp: [0, "iconProp"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["style", "font-weight: bold;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Failed orders "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaptainFailedOrdersComponent_1)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["_ngcontent-c11", ""], ["class", "height-15"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaptainFailedOrdersComponent_2)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["_ngcontent-c11", ""], ["class", "height-15"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CaptainFailedOrdersComponent_3)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.breadcrumb; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.orderTable && (_co.orderTable.state === "loaded")); _ck(_v, 8, 0, currVal_2); var currVal_3 = (_co.dailyPingsTable && (_co.dailyPingsTable.state === "loaded")); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.hourlyPingsTable && (_co.hourlyPingsTable.state === "loaded")); _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_0); }); }
export function View_CaptainFailedOrdersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-captain-failed-orders", [], null, null, null, View_CaptainFailedOrdersComponent_0, RenderType_CaptainFailedOrdersComponent)), i1.ɵdid(1, 114688, null, 0, i9.CaptainFailedOrdersComponent, [i10.MatDialog, i11.ToasterService, i12.OrderService, i4.FilterService, i13.EntityService, i14.ActivatedRoute, i15.DataService, i14.Router, i16.CaptainFailedOrdersService, i17.SvoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CaptainFailedOrdersComponentNgFactory = i1.ɵccf("app-captain-failed-orders", i9.CaptainFailedOrdersComponent, View_CaptainFailedOrdersComponent_Host_0, {}, {}, []);
export { CaptainFailedOrdersComponentNgFactory as CaptainFailedOrdersComponentNgFactory };
