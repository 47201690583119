/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./invoice-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card-table/card-table.component.ngfactory";
import * as i3 from "../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./invoice-details.component";
import * as i6 from "./invoice-details.service";
import * as i7 from "../toaster.service";
import * as i8 from "@angular/common";
var styles_InvoiceDetailsComponent = [i0.styles];
var RenderType_InvoiceDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvoiceDetailsComponent, data: {} });
export { RenderType_InvoiceDetailsComponent as RenderType_InvoiceDetailsComponent };
export function View_InvoiceDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, [[null, "buttonIsClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonIsClicked" === en)) {
        var pd_0 = (_co.buttonClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, { buttonIsClicked: "buttonIsClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InvoiceDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invoice-details", [], null, null, null, View_InvoiceDetailsComponent_0, RenderType_InvoiceDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i5.InvoiceDetailsComponent, [i4.Router, i4.ActivatedRoute, i6.InvoiceDetailsService, i7.ToasterService, i8.DecimalPipe], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvoiceDetailsComponentNgFactory = i1.ɵccf("app-invoice-details", i5.InvoiceDetailsComponent, View_InvoiceDetailsComponent_Host_0, { rateCard: "rateCard", invoiceDetails: "invoiceDetails", emailInvoice: "emailInvoice", correlationIds: "correlationIds" }, {}, []);
export { InvoiceDetailsComponentNgFactory as InvoiceDetailsComponentNgFactory };
