import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ToasterService } from 'src/app/toaster.service'
import { Toaster, ToasterType } from 'src/app/shared/types'
import { HelperService } from 'src/app/helper.service'

const mobileNumberPattern = new RegExp(/^[1-9]\d{9}$/)

@Component({
  selector: 'app-manager-search',
  templateUrl: './manager-search.component.html',
  styleUrls: ['./manager-search.component.css'],
})
export class ManagerSearchComponent implements OnInit {
  mobile = ''
  public enteredNumber = false
  public focus = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.firstChild
      ? this.route.snapshot.firstChild.paramMap.get('mobile')
      : null
    if (!id) {
      return
    }
    this.mobile = HelperService.decode(id)
    this.searchMobileNumber()
  }

  searchMobileNumber() {
    if (!mobileNumberPattern.test(this.mobile)) {
      this.toasterService.showToaster(
        new Toaster({
          type: ToasterType.WARNING,
          message: `Please enter a valid 10 digit mobile number`,
          config: {},
        })
      )
      return
    }
    const id = HelperService.encode(this.mobile)
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.router.navigate([`/manager/${id}`])
  }

  enteredMobile() {
    if (this.mobile.length > 0) {
      this.enteredNumber = true
    } else {
      this.enteredNumber = false
    }
  }
}
