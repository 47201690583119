import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { CaptainRedeemService } from 'src/app/captain-redeem-service';

@Component({
  selector: 'app-captain-accessFee-details',
  templateUrl: './captain-accessFee-details.component.html',
  styleUrls: ['./captain-accessFee-details.component.css']
})
export class CaptainAccessFeeDetails implements OnInit {
  @Input() captainId: string;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();

  captainAccessFeeTransactions: Table;
  
  dataLoaded = false;
  perPage = 10;
  firstElement = ""
  lastElement = ""
  next = ""
  prev = ""
  statusList = ["success","failed","pending","initiated"]
  transactionTypes = ["platformAccessFee"]

  constructor( private redeemService: CaptainRedeemService) { }

  ngOnInit() {
      this.declareTable();
  }

  declareTable() {
      this.captainAccessFeeTransactions = new Table({
          toggleableHeaders: this.captainDetailsToggleableHeaders,
          headers: {
              NumberOfRides: 'Rides and Date Info',
              DeductionStatus: 'Deduction Status',
              TransactionDate: 'Transaction Date',
              DeductionAmount: 'Deduction Amoount ',
              Source: 'Source',
              transactionId: 'Transaction Id'
          },
          pagination: new Pagination(0, this.perPage, 30, [10,15,20], 0, true),
          selectedHeader: 11,
          defaultError: 'No access fee transactions found',
          onRefresh: () => {
              this.captainAccessFeeTransactions = null;
              this.ngOnInit();
          },
          data: []
      });
      this.getAccessFeeTransactions();
  }
  async getAccessFeeTransactions() {
    const data = {
      "statuses":this.statusList,
      "transactionType": this.transactionTypes
    }
      this.dataLoaded = false;
      this.redeemService.getWalletCaptainPassbookFromWallet(this.captainId, data, this.perPage, this.next, this.prev)
      .then((response) => {
              const transactionArray = response && response.data;
              this.captainAccessFeeTransactions.data = transactionArray.map((transaction) => {
                this.firstElement =  transactionArray[0].id
                this.lastElement = transactionArray[transactionArray.length - 1].id;
                return {
                    NumberOfRides: this.createTableData(transaction.remark),
                    DeductionStatus: this.createTableData(transaction.status),
                    TransactionDate: this.createTableData(transaction.date),
                    DeductionAmount: this.createTableData(transaction.amount),
                    Source: this.createTableData(transaction.paymentMode),
                    transactionId: this.createTableData(transaction.id),
                };
                  
              });
              this.dataLoaded = true;
          }).catch(err => {
              console.log(err);
              this.dataLoaded = true;
          });
  }

  
  dateString(epoch: number): string {
      return new Date(epoch * 1000).toLocaleString();
  }

  createTableData(value: string, className?: string, type?: TableDataTypes, flag = false, hoverData = null) {
      return new TableData({
          data: value,
          type: type ? type : TableDataTypes.DATA,
          flag: flag,
          hoverData: hoverData,
          className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
      });
  }

  changeInHeader(header: string) {
      this.toggleHeaders.emit(header);
  }

  onPaginationChange(event: { perPage: number, previousPageIndex: number, pageNo: number }) {
    if(event.pageNo < event.previousPageIndex){
      this.next = ""
      this.prev = this.firstElement
    }else if(event.pageNo > event.previousPageIndex){
      this.prev = ""
      this.next = this.lastElement
    }
    this.getAccessFeeTransactions();
  }
  
}

