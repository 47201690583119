import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.css']
})
export class CardLayoutComponent implements OnInit, OnChanges {
  @Input() info: any;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }
  dataLoaded = false;
  ngOnChanges(changes: SimpleChanges) {
    this.dataLoaded = false;
    if (changes.info && changes.info.currentValue && changes.info.currentValue.data) {
      this.dataLoaded = true;
    }
  }

  emitSelection(value) {
    this.change.emit(value);
  }

  ngOnInit() {
  }

}
