import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../loader.service';
declare var $;

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  loading =  true;
  constructor(
    public loaderService: LoaderService,
  ) { }

  ngOnInit() {
  }

}
