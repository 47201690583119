import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HorizontalList} from '../../shared/horizontal-list/horizontal-list.component';
import {TableData, TableDataTypes} from '../../shared/types';
import {PerformanceCard} from '../../shared/types/card.types';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { CaptainService } from 'src/app/captain.service';

@Component({
  selector: 'app-single-view-of-captain',
  templateUrl: './single-view-of-captain.component.html',
  styleUrls: ['./single-view-of-captain.component.css']
})
export class SingleViewOfCaptainComponent implements OnInit {

  dataLoaded: boolean;
  @Input() captainId: string;
  @Input() riderId: string;
  horizontalList: HorizontalList;
  @Output()
  captainLevelDisplayName: EventEmitter<any> = new EventEmitter();
  data = {};
  captainLevelData = {};
  levelsMap = {};
  validServicesToShowDapr = ['auto', 'link', 'bike', 'app'];

  getProcessedWeeklyData(captainLevelData, levelsData) {
    return {
      averageRating: {
        displayName: 'Average Rating',
        value: captainLevelData && captainLevelData.calendarWeekRealTime && captainLevelData.calendarWeekRealTime.avgRating || 'N/A',
        targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.averageRating || {})
      },
      weeklyOrders: {
        displayName: 'Weekly Orders',
        value: captainLevelData && captainLevelData.calendarWeekRealTime &&  captainLevelData.calendarWeekRealTime.netRides || 'N/A',
        targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.weeklyOrders)
      },
      apr: {
        displayName : 'Acceptance Rate',
        value: captainLevelData
            && captainLevelData.calendarWeekRealTime && captainLevelData.calendarWeekRealTime.apr || 'N/A',
        targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.apr || {})
      },
      ltr: {
        displayName: 'Life Time Rides',
        value: captainLevelData && captainLevelData.ltr || 'N/A',
        targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.ltr || {})
      },
      coins: {
        displayName: 'Coins For Level Movement',
        value: '',
        targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.coins || {})
      }
    };
  }

  getRepresentableTooltipFromTargets(targets) {
    if (!targets || Object.keys(targets).length === 0) { return ''; }
    let tooltipValue = '';
    const keys = Object.keys(targets);
    for (let i = 0; i < keys.length; i++) {
      tooltipValue += `${keys[i]} : ${targets[keys[i]]}`;
      if (i < (keys.length - 1)) {
        tooltipValue += '\n';
      }
    }
    return tooltipValue;
  }

  getCaptainLevelsData(captainId) {
    const url = `${environment.server}/api/svo/captain/level/${captainId}`;
    return this.httpClient.get(url).map(res => {
      return res;
    });
  }

  getLevelTargetsData(captainId) {
    const url = `${environment.server}/api/b2b/getCaptainLevelConfigurations/${captainId}`;
    return this.httpClient.get(url).map(res => {
      return res;
    });
  }

  getDAPRForLast20AcceptedPings(riderId, serviceDetailArray){
    const serviceDetailIdArray = serviceDetailArray.length === 0 ? [] : serviceDetailArray.join(",");
    const url = `${environment.server}/api/svo/captain/getDaprLast20Pings/${riderId}?serviceDetailIds=${serviceDetailIdArray}`;
    return this.httpClient.get(url).map(res => {
      return res;
    });
  }

  processLevelsData(levelsData) {
    const processedLevelsData = {
      currentLevel: levelsData && levelsData.currentLevel || 'N/A',
      currentLevelDisplayName: '',
      targets: {}
    };
    if (levelsData && levelsData.details) {
      processedLevelsData.targets = this.processTargetLevelsData(levelsData.details);
      if (Object.keys(this.levelsMap).length > 0 && processedLevelsData.currentLevel !== 'N/A') {
        processedLevelsData.currentLevelDisplayName = this.levelsMap[processedLevelsData.currentLevel];
      }
    }

    return processedLevelsData;
  }

  getRepresentableValueFromTargetValue(value) {
    if (!value || !value.value) { return 'N/A'; }
    if (value.valueType === 'absolute') {
      return `${value.comparisonFactor || ''} ${value.value}`;
    }
    if (value.valueType === 'percentage') {
      return `${value.comparisonFactor || ''} ${value.value}%`;
    }
    return 'N/A';
  }

  processTargetLevelsData(levels) {
    const targets = {};
    const coinsMap = {};
    for (const level of levels) {
      this.levelsMap[level.levelNumber] = level.levelDetails.displayName;
      coinsMap[level.levelDetails.displayName] = level.coins;
      // tslint:disable-next-line:forin
      for (const target in level.targets) {
        if (!targets[target]) {
          targets[target] = {};
        }
        targets[target][level.levelDetails.displayName] =
           this.getRepresentableValueFromTargetValue(level.targets[target]);
      }
    }
    targets['coins'] = coinsMap;
    return targets;
  }

  filterServiceDetailIds(serviceDetails: String[], validServicesToShowDapr:String[]) {
    let serviceDetailIdArray = [];
      serviceDetails && serviceDetails['services'].forEach(function(service){
          if(validServicesToShowDapr.includes(service['serviceName'].toLowerCase())){
            serviceDetailIdArray.push(service['serviceDetailId'])
          }
      })
      return serviceDetailIdArray;
  }

  constructor(public httpClient: HttpClient, private captainService : CaptainService) {
    this.httpClient = httpClient;
    // this.processData(this.data);
  }

  ngOnInit() {
    this.dataLoaded = false;
    if (this.horizontalList && this.horizontalList.table) {
      this.makeTableNull();
    }
    this.defineList(this.riderId);
  }

  private makeTableNull() {
    this.horizontalList.table = null;
    this.horizontalList = null;
  }
  defineList(captainId: string) {
    this.getCaptainLevelsData(captainId).subscribe((captainLevelsData: any) => {
      this.captainLevelData = captainLevelsData.data;
      this.getLevelTargetsData(captainId).subscribe((captainLevelTargets: any) => {
        if (captainLevelTargets && captainLevelTargets.data && captainLevelTargets.data.code === 200) {
          this.data = captainLevelTargets.data.data;
          this.dataLoaded = true;
          let DAPR = "N/A";
          let DAPRTooltip = "";
          const levelsData = this.processLevelsData(this.data);
          this.captainService.getUserServices(this.riderId, false).subscribe((serviceDetailIds:any) => {
          const serviceDetailArray = this.filterServiceDetailIds(serviceDetailIds, this.validServicesToShowDapr);
          if(serviceDetailArray.length <= 0){
            DAPR = "N/A";
            this.mapData(levelsData, DAPR, DAPRTooltip);
          }else{
            this.getDAPRForLast20AcceptedPings(this.riderId, serviceDetailArray).subscribe((result: any) => {
              if(result && result.data && result.data.found == true && result.data.daprByService){
                const acceptedPings = result.data.daprByService[serviceDetailArray[0]]? result.data.daprByService[serviceDetailArray[0]].acceptedPings : 0;
                const dapr = result.data.daprByService[serviceDetailArray[0]].dapr ? (result.data.daprByService[serviceDetailArray[0]].dapr) * 100 : 0;
                  if(acceptedPings >= 20){
                      DAPR = dapr + '%'
                      DAPRTooltip = "basis last 20 accepted pings"
                  }else{
                      DAPR = "N/A"
                      DAPRTooltip = "Accepted pings less than 20"
                  }
              }
              this.mapData(levelsData, DAPR, DAPRTooltip);
            })
          }
        });
      }
    });
  });
}
mapData(levelsData, DAPR, DAPRTooltip){
  const weeklyData = this.getProcessedWeeklyData(this.captainLevelData, levelsData);
  if (levelsData && levelsData.currentLevelDisplayName) {
    this.captainLevelDisplayName.emit(levelsData.currentLevelDisplayName);
  }
  const commonAttrs = {
    popover: true,
    popoverIcon: 'info',
    style: {
      'color': 'black',
      'font-size': '26px !imporatant'
    }
  };
  const data = [
    {
      label: weeklyData.weeklyOrders.displayName,
      value: {
        toString: () => {
          return `${weeklyData.weeklyOrders.value}`;
        },
        ...commonAttrs,
        popoverIconTooltip: weeklyData.weeklyOrders.targets,
      },
      type: TableDataTypes.PERFORMANCE_CARD
    },
    {
      label: weeklyData.ltr.displayName,
      value: {
        toString: () => {
          return `${weeklyData.ltr.value}`;
        },
        ...commonAttrs,
        popoverIconTooltip: weeklyData.ltr.targets,
      },
      type: TableDataTypes.PERFORMANCE_CARD
    },
    {
      label: weeklyData.averageRating.displayName,
      value: {
        toString: () => {
          return `${weeklyData.averageRating.value}`;
        },
        ...commonAttrs,
        popoverIconTooltip: weeklyData.averageRating.targets,
      },
      type: TableDataTypes.PERFORMANCE_CARD
    },
    {
      label: weeklyData.apr.displayName,
      value: {
        toString: () => {
          return `${weeklyData.apr.value} %`;
        },
        ...commonAttrs,
        popoverIconTooltip: weeklyData.apr.targets,
      },
      type: TableDataTypes.PERFORMANCE_CARD
    },
    {
      label: 'DAPR',
      value: {
        toString: () => {
          return DAPR;
        },
        ...commonAttrs,
        popoverIconTooltip: DAPRTooltip,
      },
      type: TableDataTypes.PERFORMANCE_CARD
    },
    {
      label: weeklyData.coins.displayName,
      value: {
        toString: () => {
          return `N`;
        },
        ...commonAttrs,
        popoverIconTooltip: weeklyData.coins.targets,
      },
      type: TableDataTypes.PERFORMANCE_CARD
    }
  ];

  const dataTable = [data, []];

  const tds = dataTable.map(records => {
    return records.map(record => {
      return new TableData({
        type: record.type,
        performanceCard: new PerformanceCard({
          label: record.label,
          value: record.value,
          style: {
            height: '127px',
            width: '176px'
          }
        })
      });
    });
  });

  this.horizontalList = new HorizontalList({
    header: 'Captain Levels Data',
    tableData: tds,
    config: {
      refresh: true,
      filter: false,
    },
    events: {
      onRefresh: () => {
        this.ngOnInit();
      }
    }
  });
}
}
