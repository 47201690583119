import { Injectable } from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { environment } from "src/environments/environment";
import {Observable, throwError} from 'rxjs';
import { PingData } from "./shared/types/svo.types";
import {catchError} from 'rxjs/operators';

@Injectable()
export class SvoService {
  constructor(public httpClient: HttpClient) {}

  async getAverageCaptainRatings(id: any) {
    const url = `${environment.server}/api/svo/captain/${id}/rideratings/lifetime`;
    const result = await this.httpClient.get(url).toPromise();
    return result;
  }

  async getAverageCustomerRatings(id: any) {
    const url = `${environment.server}/api/svo/customer/${id}/rideratings/lifetime`;
    const result = await this.httpClient.get(url).toPromise();
    return result;
  }

  async getCaptainSegment(id: any) {
    const url = `${environment.server}/api/svo/captain/${id}/segment`;
    const result = await this.httpClient.get(url).toPromise();
    return result;
  }

  async getCaptainSafetySegment(id: any) {
    const url = `${environment.server}/api/svo/captain/${id}/safetysegment`;
    return await this.httpClient.get(url).toPromise();
  }

  getTotalPings(captainIds: string[]): Observable<PingData> {
    const url = `${environment.server}/api/svo/captain/pings`;
    const params = new HttpParams().set("captainIds", captainIds.join(","));
    return this.httpClient.get<PingData>(url, { params });
  }

  async getCustomerSegment(id: string) {
    const url = `${environment.server}/api/svo/customer/segment/${id}`;
    return await this.httpClient.get(url).toPromise();
  }

  async getCaptainPerformance(id: string) {
    const url = `${environment.server}/api/svo/captain/performance/${id}`;
    return await this.httpClient.get(url).toPromise();
  }

}
