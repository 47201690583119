<app-page-header title="Merchant details"></app-page-header>
<div *ngIf="merchantFound">
  <div class="row">
    <div class="col-4">
      <app-merchant-general-info [data]="generalInfo"></app-merchant-general-info>
    </div>
    <div class="col-4">
      <app-merchant-account [data]="accountInfo"></app-merchant-account>
    </div>
    <div class="col-4">
      <app-merchant-wallet [data]="walletInfo"></app-merchant-wallet>
    </div>
  </div>
  <div class="height-15"></div>
  <!-- <div class="row">
    <div class="col">
      <app-merchant-stat></app-merchant-stat>
    </div>
  </div> -->
  <div class="height-15"></div>
  <div class="row">
    <div class="col">
      <app-merchant-order-details *ngIf="toggleableDetails === 'Order Details'" class="width-100"
        [merchantId]="merchantUserId" [merchantCity]="merchantCity" (toggleHeaders)="headersToggled($event)">
      </app-merchant-order-details>
      <app-merchant-transaction-details (toggleHeaders)="headersToggled($event)"
        *ngIf="toggleableDetails === 'Transaction Details'" [merchantId]="merchantId" [merchantUserId]="merchantUserId">
      </app-merchant-transaction-details>
    </div>
  </div> 
</div>