<div class = "wrapper">
    <div class = "invoice-header">Payment Summary</div>
    <div class = "invoice-id">{{rideId}}</div>
    <div class = "invoice-info-wrap">
        <div class = "invoice-label">Ride ID</div>
        <div class = "invoice-value">{{rideId}}</div>
    </div>
    <div class = "invoice-info-wrap">
        <div class = "invoice-label">Time of Ride</div>
        <div class = "invoice-value">{{tripDate}}</div>
    </div>

    <div class = "sucess-wrap">
        <div class = "total-header">Total</div>
        <div class = "total-cost">{{total}}</div>
    </div>

     <div class = "distance-wrap">
        <div class = "distance-map-wrap">
            <img class = "distance-image" src = "assets/images/invoice/map.png" />
        </div>
        <div class = "distance-info-wrap">
            <div class = "map-wrap">
                <div class = "distance-value">{{distance}}</div>
                <div class = "distance-label">DISTANCE</div>
            </div>
            <div class = "right-distance-wrap">
                <div class = "distance-value">{{duration}}</div>
                <div class = "distance-label">DURATION</div>
            </div>
        </div>
    </div>
    <div class = "location-wrap">
        <div class = "location-point pickup-point">
            <div class = "location-point-wrap">
                <img class = "pickup-drop-img" src = "assets/images/invoice/pickup.png" />
            </div>
            <div class = "location">{{pickupLocation}}</div>
        </div>
        <div class = "location-connector"></div>
        <div class = "location-point drop-point">
            <div class = "location-point-wrap">
                <img class = "pickup-drop-img" src = "assets/images/invoice/drop.png" />
            </div>
            <div class = "location">{{dropLocation}}</div>
        </div>
    </div>

    <div class = "billdetails-wrap">
        <div class = "invoice-header">Bill Details</div>
        <div class = "invoice-info-wrap">
            <div *ngIf="['c2c', 'sme', 'bfs'].includes(service); else rideBlock" class = "invoice-label">Courier Charges</div>
            <ng-template #rideBlock>
                <div class = "invoice-label">Ride Charge</div>
            </ng-template>
            <div class = "invoice-value">{{rideCharge}}</div>
        </div>
        <div class = "invoice-info-wrap bottom-divider">
            <div class = "invoice-label">Booking Fees & Convenience Charges</div>
            <div class = "invoice-value">{{bookingAndConvenienceFee}}</div>
        </div>
        <div class = "invoice-info-wrap" *ngIf="showPreviousDue()">
            <div class = "invoice-label red-text">Previous Due</div>
            <div class = "invoice-value red-text">{{prevDue}}</div>
        </div>
        <div class = "invoice-info-wrap" *ngIf="showRapidoCoinDiscount()">
            <div class = "invoice-label green-text">Rapido Coin Discount</div>
            <div class = "invoice-value green-text">{{coinTotal}}</div>
        </div>
        <div class = "invoice-info-wrap" *ngIf="showDiscount()">
            <div class = "invoice-label green-text">Discount</div>
            <div class = "invoice-value green-text">{{discount}}</div>
        </div>
        <div class="top-divider" *ngIf="showDiscount() || showRapidoCoinDiscount() || showPreviousDue()"></div>
        <div class = "invoice-info-wrap">
            <div class = "invoice-header">Total Amount</div>
            <div class = "invoice-header">{{total}}</div>
        </div>
        <div class = "invoice-info-wrap">
            <div class = "sub-info">(Inclusive of Taxes)</div>
        </div>
    </div>

    <div *ngIf="!isCorporateOrder && !coinUsedOnly" class = "paymentinfo-wrap">
        <div class = "invoice-header">You Paid Using</div>
         <div *ngIf="cash" class = "invoice-info-wrap">
            <div class = "invoice-label">Cash</div>
            <div class = "invoice-value">{{cash}}</div>
        </div>
        <div *ngIf="rapido" class = "invoice-info-wrap">
            <div class = "invoice-label">Rapido Wallet</div>
            <div class = "invoice-value">{{rapido}}</div>
        </div>
        <div *ngIf="paytm" class = "invoice-info-wrap">
            <div class = "invoice-label">Paytm</div>
            <div class = "invoice-value">{{paytm}}</div>
        </div>
        <div *ngIf="mobikwik" class = "invoice-info-wrap">
            <div class = "invoice-label">Mobikwik</div>
            <div class = "invoice-value">{{mobikwik}}</div>
        </div>
        <div *ngIf="freecharge" class = "invoice-info-wrap">
            <div class = "invoice-label">Freecharge</div>
            <div class = "invoice-value">{{freecharge}}</div>
        </div>
        <div *ngIf="lazypay" class = "invoice-info-wrap">
            <div class = "invoice-label">Lazypay</div>
            <div class = "invoice-value">{{lazypay}}</div>
        </div>
         <div *ngIf="simpl" class = "invoice-info-wrap">
            <div class = "invoice-label">Simpl</div>
            <div class = "invoice-value">{{simpl}}</div>
        </div>
         <div *ngIf="gpay" class = "invoice-info-wrap">
            <div class = "invoice-label">Google Pay</div>
            <div class = "invoice-value">{{gpay}}</div>
        </div>
        <div *ngIf="amazonpay" class = "invoice-info-wrap">
            <div class = "invoice-label">Amazon Pay</div>
            <div class = "invoice-value">{{amazonpay}}</div>
        </div>
        <div *ngIf="upi" class = "invoice-info-wrap">
            <div class = "invoice-label">UPI</div>
            <div class = "invoice-value">{{upi}}</div>
        </div>
        <div *ngIf="qrcode" class="invoice-info-wrap">
            <div class="invoice-label">QR Pay</div>
            <div class="invoice-value">{{qrcode}}</div>
        </div>
    </div>
    <div *ngIf="coinUsedOnly" class = "coins-only-msg">
        Rapido Coins have been used for this Ride
    </div>

</div>