import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FRAUDTYPES, FRAUDTAGS, FRAUDCAUSES } from '../shared/constants';
import { Table } from '../shared/types';

@Component({
  selector: 'app-prevdue-dialog',
  templateUrl: './prevdue-dialog.component.html',
  styleUrls: ['./prevdue-dialog.component.css']
})
export class PrevDueDialogComponent implements OnInit {

  prevdueTable: Table;
  public updatedFraudData: any;
  prevTransactons: any[];

  constructor(
    public dialogRef: MatDialogRef<PrevDueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.declareTable() 
  }

  declareTable() {
    this.prevdueTable = new Table({
      tableHeader: this.data.title,
      headers: {
        date: 'Date',
        orderId: 'Order Id',
        source: 'Source',
        type: "Type",
        amount: "Due",
      },
      config: {
        refresh: false,
        filter: false
      }
    })


    this.prevTransactons = this.data.prevDueTransactons.map((transaction) => {
      return {
        date : transaction.date,
        orderId : transaction.orderId,
        source : transaction.source,
        type: transaction.type,
        amount : transaction.amount
      }
    })

    this.prevdueTable.data = this.prevTransactons;
  }

  hideDalog(){
    this.dialogRef.close()
  }

}
