import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-expandable-table',
  templateUrl: './expandable-table.component.html',
  styleUrls: ['./expandable-table.component.css']
})

export class ExpandableTableComponent implements OnInit, OnChanges {
  public expandable = true;
  public viewMore = true;
  public viewDetails = 'View more';
  @Input() info: any;
  @Input() pageRefreshed: any;
  @Output() toggleExpansion: EventEmitter<any> = new EventEmitter<any>();
  @Output() loadData: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();
  toggleState = 'vm';
  displayedColumns = [];
  constructor() { }

  buttonClicked(button) {
    this.viewDetails = 'View more';
    this.viewMore = true;
    this.buttonClick.emit(button);
  }
  toggleStatus() {
    this.viewMore = !this.viewMore;
    if (this.viewMore) {
      this.viewDetails = 'View more';
      this.toggleState = 'vm';
    } else {
      this.viewDetails = 'Show less';
      this.toggleState = 'sl';
    }
    this.toggleExpansion.emit(this.toggleState);
  }

  ngOnInit() {
  }

  redirectToUrl(link) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (change) {
      if (change.info && change.info.currentValue) {
        if (change.info.currentValue.dataFetched && !change.info.currentValue.loadOnRequest && !change.info.currentValue.accessDenied) {
          this.displayedColumns =  this.info && this.info.headers ? Object.keys(this.info.headers) : [];
        }
      }
      if (change.pageRefreshed && change.pageRefreshed.currentValue) {
        this.viewDetails = 'View more';
        this.viewMore = true;
      }
    }
  }

  loadDataNow() {
    this.loadData.emit();
  }

  // case 1: data found and displayed
  // case 2: data not found so show empty msg
  // case 3: data load on request
  // case 4: data access restricted (show cant access card)
}
