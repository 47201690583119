import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as i0 from "@angular/core";
export var MatchStatus = {
    UPCOMING: 'upcoming',
    ONGOING: 'ongoing',
    LEADERBOARD: 'leaderBoard',
    ENDED: 'ended',
};
var DataService = /** @class */ (function () {
    function DataService() {
        this.MatchStatus = MatchStatus;
        this.userPermissions = new BehaviorSubject({});
        this.permissions = this.userPermissions.asObservable();
        this.currentEntitiyDetails = {};
        this.searchCount = new BehaviorSubject({});
        this.searchUserCount = this.searchCount.asObservable();
    }
    DataService.prototype.updatePermissions = function (roles) {
        this.userPermissions.next(roles);
    };
    DataService.prototype.setCurrentEntity = function (entity) {
        this.currentEntitiyDetails = entity;
    };
    DataService.prototype.getCurrentEntity = function () {
        return this.currentEntitiyDetails;
    };
    DataService.prototype.updateSearchCount = function (number) {
        this.searchCount.next(number);
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
