/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manager-profile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/page-header/page-header.component.ngfactory";
import * as i3 from "../shared/page-header/page-header.component";
import * as i4 from "./manager-general-info/manager-general-info.component.ngfactory";
import * as i5 from "./manager-general-info/manager-general-info.component";
import * as i6 from "./manager-general-info/manager-general-info.service";
import * as i7 from "./manager-rapido-account/manager-rapido-account.component.ngfactory";
import * as i8 from "./manager-rapido-account/manager-rapido-account.component";
import * as i9 from "../toaster.service";
import * as i10 from "./manager-rapido-account/manager-rapido-account.service";
import * as i11 from "./manager-profile.component";
import * as i12 from "@angular/router";
import * as i13 from "./manager-profile.service";
var styles_ManagerProfileComponent = [i0.styles];
var RenderType_ManagerProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManagerProfileComponent, data: {} });
export { RenderType_ManagerProfileComponent as RenderType_ManagerProfileComponent };
export function View_ManagerProfileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-header", [["title", "Partner Manager details"]], null, null, null, i2.View_PageHeaderComponent_0, i2.RenderType_PageHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.PageHeaderComponent, [], { title: [0, "title"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-manager-general-info", [], null, null, null, i4.View_ManagerGeneralInfoComponent_0, i4.RenderType_ManagerGeneralInfoComponent)), i1.ɵdid(5, 638976, null, 0, i5.ManagerGeneralInfoComponent, [i6.ManagerGeneralInfoService], { data: [0, "data"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-manager-rapido-account", [], null, null, null, i7.View_ManagerRapidoAccountComponent_0, i7.RenderType_ManagerRapidoAccountComponent)), i1.ɵdid(8, 638976, null, 0, i8.ManagerRapidoAccountComponent, [i9.ToasterService, i10.ManagerRapidoAccountService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Partner Manager details"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.managerProfile; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.managerProfile; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ManagerProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-manager-profile", [], null, null, null, View_ManagerProfileComponent_0, RenderType_ManagerProfileComponent)), i1.ɵdid(1, 114688, null, 0, i11.ManagerProfileComponent, [i12.Router, i12.ActivatedRoute, i9.ToasterService, i13.ManagerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManagerProfileComponentNgFactory = i1.ɵccf("app-manager-profile", i11.ManagerProfileComponent, View_ManagerProfileComponent_Host_0, {}, {}, []);
export { ManagerProfileComponentNgFactory as ManagerProfileComponentNgFactory };
