import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client.service";
var CaptainOrderDetailsService = /** @class */ (function () {
    function CaptainOrderDetailsService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getAdjustments = this.server + '/api/payments/getCaptainAdjustmentsBatch';
        this.getAdjustmentsForOrders = this.server + '/api/payments/getAdjustmentsBatch';
    }
    CaptainOrderDetailsService.prototype.getAdjustmentAmounts = function (orders, id) {
        return this.http.post(this.getAdjustments, { orders: orders, id: id }).map(function (res) {
            var details = res['data'] && res['data']['data'] && res['data']['data']['riderPaymentDetails'] ?
                res['data']['data']['riderPaymentDetails'] : [];
            return details;
        })
            .pipe(catchError(this.handleError));
    };
    CaptainOrderDetailsService.prototype.getOrderAdjustments = function (orderIds) {
        return this.http.post(this.getAdjustmentsForOrders, { orderIds: orderIds })
            .pipe(catchError(this.handleError));
    };
    CaptainOrderDetailsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CaptainOrderDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function CaptainOrderDetailsService_Factory() { return new CaptainOrderDetailsService(i0.inject(i1.MyHttpClient)); }, token: CaptainOrderDetailsService, providedIn: "root" });
    return CaptainOrderDetailsService;
}());
export { CaptainOrderDetailsService };
