    <ng-container *ngIf="table">
    <div class="card" [ngStyle]="table.style">
        <div class="card-header row margin-0" style="padding-left: 16px;" [ngStyle]="table.config.headerStyle" *ngIf="table.config.header">
          <div class="col-md-6">
            <h3 class="card-title" (click)="table.tableHeader?.onClick && table.tableHeader.onClick()" [ngStyle]="table.tableHeader?.style && table.tableHeader?.style()">
              {{table.tableHeader}}<div *ngIf="table.tableHeader?.redirect && table.tableHeader.redirect" style="display: inline;">&#8658;</div>
            </h3>
          </div>
            <div class="col-md-6">
              <div class="row direction-rtl">
                <div *ngIf="table.config.filter" class="cursor-pointer filter-button" (click)="onFilter()">
                  <span class="is-flex margin-sides-5">
                    <mat-icon  class="filter-icon">filter_list</mat-icon>Filters
                  </span>
                </div>
                <div class="filter-applied-stats" *ngIf="table && table.filterCount && table.filterCount > 0">
                  <span class="filter">{{table.filterCount}}</span>
                </div>
                <fa-icon *ngIf="table.config.refresh" class="cursor-pointer margin-icons" (click)="onRefresh('refresh')" [icon]="faRefresh"></fa-icon>
                <div *ngIf="table.isElipsesAvailable()" class="cursor-pointer margin-icons">
                    <button class="margin-right-28" mat-icon-button [matMenuTriggerFor]="ellipsesMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #ellipsesMenu="matMenu">
                        <button  *ngFor="let menu of table.config.ellipses" mat-menu-item (click)="menu.onClick()">
                          <span>{{menu.label}}</span>
                        </button>
                    </mat-menu>
                </div>
                <div *ngIf="table.config.search" class="form-group col-md-4 padding-right-20">
                    <div class="input-icon mb-3">
                      <input type="text" class="form-control" (input)="onSearch($event.target.value)" placeholder="Search">
                      <span class="input-icon-addon">
                        <i class="fe fe-search"></i>
                      </span>
                    </div>
                </div>
              </div>
            </div>
        </div>
      <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter text-nowrap card-table" [ngClass]="trClass">
          <thead *ngIf="table.config.colName">
            <tr>
              <th *ngFor="let header of displayedColumns">{{table.headers[header]}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of table.data; let i=index">
              <ng-container *ngFor="let header of displayedColumns">
                <ng-container *ngIf="row[header]">
                <ng-container [ngSwitch]="row[header].type">
                    <td *ngSwitchCase="TableDataTypes.DATA" [ngStyle]="row[header].style" [class]="row[header].className">
                      <p class="text-inherit">
                      <span [class]="row[header].spanClassName" (click)="row[header].onClick()">
                        {{row[header].data}}
                        <span class="icon-alignment" tooltipPosition="bottom" pTooltip="{{row[header].data.toHoverData()}}" *ngIf="row[header].data.toHoverData">
                          <i class="material-icons-outlined card-info cursor-pointer icon-placement">
                            info
                          </i>
                        </span>
                      </span>
                    </p>
                  </td>
                    <td *ngSwitchCase="TableDataTypes.ACTION" class="cursor-pointer">
                        <div class="item-action dropdown">
                          <a href="javascript:void(0)" data-toggle="dropdown" class="icon"><i class="fe fe-more-vertical"></i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a href="javascript:void(0)" class="dropdown-item" *ngFor="let action of row[header]?.actions?.actions"><i class="dropdown-icon fe fe-tag"></i> {{action.text}} </a>
                          </div>
                        </div>
                      </td>
                      <ng-container *ngSwitchCase="TableDataTypes.BUTTON">
                          <button [class]="row[header]?.button?.className" (click)="doAction(i, row[header]?.button?.onClick)">{{row[header]?.button?.label}}</button>
                      </ng-container>
                      <ng-container *ngSwitchCase="TableDataTypes.CARD">
                        <td class="cursor-pointer" [ngStyle]="row[header].style">
                          <span>
                            <app-thumbnail-card [card]="row[header].card"></app-thumbnail-card>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container *ngSwitchCase="TableDataTypes.PERFORMANCE_CARD">
                        <td class="cursor-pointer">
                          <span>
                            <app-card [card]="row[header].performanceCard"></app-card>
                          </span>
                        </td>
                      </ng-container>
                      <ng-container *ngSwitchCase="TableDataTypes.FRAUD_CARD">
                        <td class="cursor-pointer no-border">
                          <span>
                            <app-fraud-card [card]="row[header].performanceCard"></app-fraud-card>
                          </span>
                        </td>
                      </ng-container>
                    <td ng-container *ngSwitchCase="TableDataTypes.KYC_CARD" style="padding:0px">
                            <!-- border=1 frame=hsides rules=rows -->
                          <div *ngFor="let info of row[header].data">
            <mat-expansion-panel>
                  <mat-expansion-panel-header style="padding-left: 22px;padding-right: 22px;">
                      <div class="flex-row">
                          <img [src]="info.imageUrl" class="display-inline-block" style="margin-right: 10%">
                          <div class="text-default make-text-break clip-text-break font-size-18 display-inline-block">{{info.matExpansionPanelHeader}}</div>
                      </div>
              </mat-expansion-panel-header>
                <div *ngFor="let content of info.matExpansionPanelContent" style="width: 100%;">
                    <div class="flex-column">
                    <div class="horizontal-rule" style="width: 100%;"></div>
                       <div class="flex-row" style="padding: 4% 10%;">
                           <span class="{{info.className}}" [ngClass]="info.redirectClass" (click)="redirectRoute(info)" style="margin-right: 10%;">{{content.header}}: </span>
                            <span class="{{info.className}}" [ngClass]="info.redirectClass" (click)="redirectRoute(info)">{{content.value}}</span>
                       </div>
                    </div>
                </div>
                <div class="horizontal-rule"></div>
            </mat-expansion-panel>
                          </div>
                        </td>
                      <td *ngSwitchCase="TableDataTypes.CHIP">
                        <div [ngStyle]="row[header].style" class="display-flex ">
                          <div class="user-selector width-130 clip-text"
                               *ngIf="row[header] && row[header].data.length > 0">
                            <span  pTooltip="{{ row[header].data[0].name }}" tooltipPosition="bottom"> 
                                  <span> {{ row[header].data[0].name || '-' }} </span>
                            </span>
                          </div>
                          <span *ngIf="row[header] && row[header].data.length > 0" 
                              class="btn-success badge"
                              (click)="viewCrossUtilizationSelectors()" pTooltip="View all selectors"
                              tooltipPosition="bottom">
                              +  {{ row[header].data.length - 1 }}
                          </span>
                          <span *ngIf="row[header] && row[header].data.length === 0">
                              {{'N/A'}}
                          </span>
                        </div>
                    </td>
                    <td *ngSwitchCase="TableDataTypes.CHIPS">
                      <div [ngStyle]="row[header].style" class="display-flex ">
                        <div class="user-selector width-130 clip-text"
                             *ngIf="row[header] && row[header].data.length > 0">
                          <span  pTooltip="{{ row[header].data[0].name }}" tooltipPosition="bottom"> 
                                <span> {{ row[header].data[0] || '-' }} </span>
                          </span>
                        </div>
                        <span *ngIf="row[header] && row[header].data.length > 0" 
                            class="btn-success badge"
                            (click)="viewMoreChips(row[header])" pTooltip="View all"
                            tooltipPosition="bottom">
                            +  {{ row[header].data.length - 1 }}
                        </span>
                      </div>
                    <div class="na-chip" *ngIf="row[header] && row[header].data.length === 0">
                        <span>{{'N/A'}}</span>
                    </div>
                  </td>
                    <td *ngSwitchDefault><a href="" class="text-inherit">-</a></td>
                </ng-container>
              </ng-container>
              </ng-container>
            </tr>
            <tr class="color-red make-item-center" *ngIf="!table.data.length">
              No Data Available
            </tr>
          </tbody>
        </table>
      </div>
      <app-pagination *ngIf="table.pagination"></app-pagination>
    </div>
  </ng-container>
  <ng-container *ngIf="!this.table">
  <div>
    <bullet-list-content-loader></bullet-list-content-loader>
    <bullet-list-content-loader></bullet-list-content-loader>
      </div>
  </ng-container>
