import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client.service";
var CustomerGeneralInfoService = /** @class */ (function () {
    function CustomerGeneralInfoService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.removeDevice = this.server + '/api/removeImei';
        this.logoutCust = this.server + '/api/logoutCustomer';
        this.activate = this.server + '/api/activateCustomer';
        this.deactivate = this.server + '/api/deactivateCustomer';
        this.referee = this.server + '/api/getRefereeDetails';
        this.toggleCustomerStatus = this.server + '/api/toggleCustomerStatus';
    }
    CustomerGeneralInfoService.prototype.removeDeviceId = function (deviceId) {
        var payload = {
            deviceId: deviceId
        };
        return this.http.post(this.removeDevice, payload).pipe(catchError(this.handleError));
    };
    CustomerGeneralInfoService.prototype.logoutCustomer = function (userId) {
        var payload = {
            userId: userId
        };
        return this.http.post(this.logoutCust, payload).pipe(catchError(this.handleError));
    };
    CustomerGeneralInfoService.prototype.changeCustomerStatus = function (body) {
        return this.http.post(this.toggleCustomerStatus, body).pipe(catchError(this.handleError));
    };
    CustomerGeneralInfoService.prototype.getRefereeDetails = function (id, mobile) {
        return this.http.get(this.referee + '/' + id + '/CUSTOMER/' + mobile).pipe(catchError(this.handleError));
    };
    CustomerGeneralInfoService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CustomerGeneralInfoService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerGeneralInfoService_Factory() { return new CustomerGeneralInfoService(i0.inject(i1.MyHttpClient)); }, token: CustomerGeneralInfoService, providedIn: "root" });
    return CustomerGeneralInfoService;
}());
export { CustomerGeneralInfoService };
