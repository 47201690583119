/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manager-general-info.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../card-layout/card-layout.component.ngfactory";
import * as i3 from "../../card-layout/card-layout.component";
import * as i4 from "./manager-general-info.component";
import * as i5 from "./manager-general-info.service";
var styles_ManagerGeneralInfoComponent = [i0.styles];
var RenderType_ManagerGeneralInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManagerGeneralInfoComponent, data: {} });
export { RenderType_ManagerGeneralInfoComponent as RenderType_ManagerGeneralInfoComponent };
export function View_ManagerGeneralInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-layout", [], null, null, null, i2.View_CardLayoutComponent_0, i2.RenderType_CardLayoutComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardLayoutComponent, [], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ManagerGeneralInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-manager-general-info", [], null, null, null, View_ManagerGeneralInfoComponent_0, RenderType_ManagerGeneralInfoComponent)), i1.ɵdid(1, 638976, null, 0, i4.ManagerGeneralInfoComponent, [i5.ManagerGeneralInfoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManagerGeneralInfoComponentNgFactory = i1.ɵccf("app-manager-general-info", i4.ManagerGeneralInfoComponent, View_ManagerGeneralInfoComponent_Host_0, { data: "data" }, {}, []);
export { ManagerGeneralInfoComponentNgFactory as ManagerGeneralInfoComponentNgFactory };
