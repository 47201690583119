import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tax-invoice',
  templateUrl: './tax-invoice.component.html',
  styleUrls: ['./tax-invoice.component.css']
})
export class TaxInvoiceComponent implements OnInit {
    @Input() data: any;
    rideId: any;
    pickupLocationAddress: any;
    invoiceNumber: any;
    invoiceDate: any;
    riderTaxCategory: any;
    rapidoTaxCategory: any;
    placeOfSupply: any;
    gstTin: any;
    convenienceFeeWithoutTax: string;
    rapidoAddress: any;
    customerName: any;
    bookingFeeWithoutTax: any;
    finalAmount: string;
    cgst: string;
    cgstVal: string;
    sgst: string;
    igst: string;
    sgstVal: string;
    igstVal: string;
    discount: string;
    service: string;
    merchantName: string;
    merchantAddress: string;
    merchantGstNumber: string;
    isCorporateOrder: boolean;
    clientName: string;
    coinWithoutTax: string;

    constructor() { }

    ngOnInit() {
        this.formatData();
    }

    getPercentage = (val: any) => {
        return Number(Number(val * 100).toFixed(2));
    }

    formatData = () => {
        const data = this.data;
        this.rideId = data.rideId;
        this.invoiceNumber = data.invoiceNumber;
        this.invoiceDate = data.invoiceDate;
        this.rapidoTaxCategory = data.rapidoTaxCategory;
        this.placeOfSupply = data.placeOfSupply;
        this.gstTin = data.gstTin;
        this.pickupLocationAddress = data.pickupLocationAddress;
        this.convenienceFeeWithoutTax = `₹ ${data.convenienceFeeWithoutTax}`;
        this.rapidoAddress = data.rapidoAddress;
        this.customerName = data.customerName;
        this.bookingFeeWithoutTax = `₹ ${data.bookingFeeWithoutTax}`;
        this.finalAmount = data.finalAmount && `₹ ${data.finalAmount.total}` || '0';
        this.cgst = `(${this.getPercentage(data.tax.cgst)}%)`;
        this.sgst = `(${this.getPercentage(data.tax.sgst)}%)`;
        this.igst = `(${this.getPercentage(data.tax.igst)}%)`;
        this.cgstVal = data.finalAmount && `₹ ${data.finalAmount.cgst}` || '0';
        this.sgstVal = data.finalAmount && `₹ ${data.finalAmount.sgst}` || '0';

        this.igstVal = data.finalAmount && `₹ ${data.finalAmount.igst}` || '0';
        this.discount = data.discountWithoutTax && `₹ ${data.discountWithoutTax}` || '0';
        this.coinWithoutTax = data.coinWithoutTax && `₹ ${data.coinWithoutTax}` || '0';
        this.service = data.service.toLowerCase();
        this.merchantName = data.merchantName;
        this.merchantAddress = data.merchantAddress;
        this.merchantGstNumber = data.merchantGstNumber;
        this.isCorporateOrder = data.isCorporateOrder;
        this.clientName = data.clientName;
    }

    showDiscount = () => {
        const discount = parseFloat(this.data.discountWithoutTax);
        if (discount !== 0) {
            return true;
        }
        return false;
    }

    showRapidoCoinDiscount = () => {
        const coinWithoutTax = parseFloat(this.data.coinWithoutTax);
        if (coinWithoutTax !== 0) {
            return true;
        }
        return false;
    }
}
