import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityService } from '../entity.service';
import { SvoService } from '../svo.service';
@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService {

  constructor(private http: MyHttpClient,
    private entityService: EntityService,
    private svoService: SvoService) { }
  server = `${environment.server}`;
  getCustomer = this.server + '/api/getCustomerByMobile';
  getWalletBalances = this.server + '/api/getWalletBalances';
  debitPreference = this.server + '/api/debitPreference'
  getPrevDueTransactions = this.server + '/api/getPrevDueTransactions';
  getPassDetails = this.server + '/api/getPassDetails';
  getLastLogin = this.server + '/api/getLastLoginDate';
  getReferrals = this.server + '/api/referralDetails';
  getTicketsUrl = this.server + '/api/tickets';
  paymentUrl = environment.server + '/api/payments';

  getCustomerByMobile(mobile) {
    return this.http.get(this.getCustomer + '/' + mobile).pipe(catchError(this.handleError));
  }

  getCustomerWallets(customerDetails, walletList) {
    const payload = {
      user : customerDetails,
      wallets : walletList
    };
    return this.http.post(this.getWalletBalances, payload).pipe(catchError(this.handleError));
  }
  getDebitPreference(customerDetails){
    const payload = {
      user : customerDetails,
    };
    return this.http.post(this.debitPreference, payload).pipe(catchError(this.handleError));
  }

  getPassInfo(userId) {
    return this.http.get(this.getPassDetails + '/' + userId).pipe(catchError(this.handleError));
  }

  getLastLoginDate(userId) {
    return this.http.get(this.getLastLogin + '/' + userId).pipe(catchError(this.handleError));
  }

  getReferralDetails(userId) {
    return this.entityService.getReferralDetailsV2(
      userId,
      this.entityService.USER_TYPE.CUSTOMER
    )
    .map((res: any) => {
      res.referral = res.result || {};
      return [res.referral.referralCode];
    })
    // return this.http.get(this.getReferrals + '/' + userId).pipe(catchError(this.handleError));
  }

  getTickets( id ){
    return this.http.get(this.getTicketsUrl + '/' + id).pipe(catchError(this.handleError));
  }

  customerRefundDetails(refundObj, userId) {
    const requestBody = {
        refundObj: refundObj,
        userId: userId
    };
    return this.http.post(this.paymentUrl + '/refund', requestBody).pipe(catchError(this.handleError));
}

customerDebitDetails(debitObject, userId) {
    const requestBody = {
        debitObject: debitObject,
        userId: userId
    };
    return this.http.post(this.paymentUrl + '/debit', requestBody).pipe(catchError(this.handleError));
}
  getPreviousDueTransactions(userData, userId){
    const payload = {
      user : userData,
      userId : userId
    };
    return this.http.post(this.getPrevDueTransactions, payload).pipe(catchError(this.handleError));
  }

  async getCustomerSegment(id: string) {
    return await this.svoService.getCustomerSegment(id);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
