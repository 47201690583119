<!-- <div class="modal fade" id="loadMe" tabindex="-1" role="dialog" aria-labelledby="loadMeLabel">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="loader"></div>
          <div clas="loader-txt">
            <p>Check out this slick bootstrap spinner modal. <br><br><small>We are addicted to Bootstrap... #love</small></p>
          </div>
        </div>
      </div>
    </div>
  </div> -->


  <div class="my-container">
      <ng-template #customLoadingTemplate>
          <div class="custom-class">
              <!-- <h3>
                  Loading...
              </h3> -->
              <!-- <button (click)="showAlert()">
                  Click me!
              </button> -->
          </div>
      </ng-template>

      <!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading> -->
      <ngx-loading [show]="this.loaderService.loading" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  </div>
