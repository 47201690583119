import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-merchant-account',
  templateUrl: './merchant-account.component.html',
  styleUrls: ['./merchant-account.component.css']
})
export class MerchantAccountComponent implements OnInit, OnChanges {
  @Input() data;
  info = {};
  constructor() { }

  ngOnInit() {
    this.getAccountInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getAccountInfo();
  }

  getAccountInfo() {
    if (this.data) {
      this.info = {
        header: 'Rapido account',
        dataFetched: true,
        data: [
          {header: 'Registration Date', value: this.data.registeredDate || ''},
          {header: 'Registered City', value: this.data.location && this.data.location.city || ''},
          {header: 'Current Status', className: this.data.status === 'active' ? 'green-badge' : 'red-badge',
            value: this.data.status === 'active' ? 'Active' : 'Inactive'},
          {header: 'Pass Details', value:  ''},
          {header: 'App version', value:  this.data.appVersion}
        ]
      };
    } else {
      this.info = {
        header: 'Rapido account',
        dataFetched: false,
        data: []
      };
    }
  }
}
