import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { faRedo, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FilterService } from 'src/app/filter.service';

@Component({
  selector: 'app-card-stats',
  templateUrl: './card-stats.component.html',
  styleUrls: ['./card-stats.component.css']
})
export class CardStatsComponent implements OnInit, OnChanges {
  faRefresh = faRedo;
  faFilter = faFilter;

  public todayDate = this.formatDate(new Date());
  @Input() info: any;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() filters: EventEmitter<any> = new EventEmitter<any>();
  @Input() withoutFilter = false;
  dataLoaded = false;
  constructor(public filterService: FilterService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.dataLoaded = false;
    if (changes.info && changes.info.currentValue && changes.info.currentValue.dataFetched) {
      this.dataLoaded = true;
    }
  }

  ngOnInit() {
  }

  refreshData() {
    this.dataLoaded = false;
    this.refresh.emit();
  }

  onFilter() {
    this.filterService.defineFilters(this.info.filters, (filters) => {
      const dates = {};
      if (this.filterService && this.filterService.appliedFilters) {
        if (Array.isArray(this.filterService.appliedFilters.statFilter)) {
          dates['start'] = this.filterService.appliedFilters.statFilter[0];
          dates['end'] = this.filterService.appliedFilters.statFilter[1];
          this.filterService.appliedFilters.statFilter = dates;
        }
      }
      this.filters.emit(this.filterService.appliedFilters);
    });
    this.filterService.filterToggle();
  }

  formatDate(date) {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }
}
