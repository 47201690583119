import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Table, Pagination } from 'src/app/shared/types';
import { EntityService } from 'src/app/entity.service';

@Component({
  selector: 'app-customer-activities',
  templateUrl: './customer-activities.component.html',
  styleUrls: ['./customer-activities.component.css']
})
export class CustomerActivitiesComponent implements OnInit {
  dataLoaded = true;
  @Input() customerId: string;
  @Input() customerDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  
  activityLogTable: Table;
  constructor(
    public entityService: EntityService,
  ) {
   
   }

  async getData({ customerId }) {
    const activities: any = await this.entityService.getCustomerActivationDeactivateLogs(customerId).toPromise();
    this.activityLogTable.data = activities;
  }

  onPaginationChange(event) {
    console.log(event);
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  ngOnInit() {
    this.declareTable();
    this.getData({
      customerId: this.customerId,
    });
  }

  declareTable() {
    this.activityLogTable = new Table({
      headers: {
        date: 'Date',
        time: 'time',
        status: 'Status',
        reason: 'Reason',
        // agentName: 'Agent Name',
        agentName: 'Agent Name',
        agentEmail: 'Agent Email'
      },
      pagination: new Pagination(null, null, null, null,0, false),
      selectedHeader: 2,
      defaultError: 'No Logs Found',
      toggleableHeaders: this.customerDetailsToggleableHeaders,
    });
  }

}
