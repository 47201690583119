import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MyHttpClient } from "../http-client.service";
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var DemandAreasService = /** @class */ (function () {
    function DemandAreasService(http) {
        this.http = http;
        this.server = environment.server;
        this.demandAreasUrl = this.server + "/api/ndl/demandAreas";
    }
    DemandAreasService.prototype.fetchDemandAreas = function (city, services, date, time) {
        var url = this.demandAreasUrl + "?city=" + city + "&services=" + services + "&date=" + date + "&time=" + time;
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    DemandAreasService.prototype.getCities = function () {
        var url = this.server + "/api/get/cities";
        return this.http.get(url).map(function (res) { return res.data.cities; }).pipe(catchError(this.handleError));
    };
    DemandAreasService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    DemandAreasService.ngInjectableDef = i0.defineInjectable({ factory: function DemandAreasService_Factory() { return new DemandAreasService(i0.inject(i1.MyHttpClient)); }, token: DemandAreasService, providedIn: "root" });
    return DemandAreasService;
}());
export { DemandAreasService };
