import { OnInit } from '@angular/core';
import { ICON_MAPPING } from '../shared/side-nav/side-nav.component';
import { Table } from '../shared/types';
import { ActivatedRoute, Router } from '@angular/router';
import { CaptainService } from '../captain.service';
import { FilterService } from '../filter.service';
import { HelperService } from '../helper.service';
import { EntityService } from 'src/app/entity.service';
var CaptainDetailsComponent = /** @class */ (function () {
    function CaptainDetailsComponent(route, router, captainService, filterService, entityService) {
        this.route = route;
        this.router = router;
        this.captainService = captainService;
        this.filterService = filterService;
        this.entityService = entityService;
        this.toggleableDetails = 'Order Details';
        this.captainDetailsToggleableHeaders = [
            'Order Details',
            'Activity Logs',
            'Bulk Action Logs',
            'Ticket Details',
            'Rapido Pay Logs',
            'Complaints By Customer',
            'Subscription Details',
            'Customer Complaints against Captain',
            'Call Logs',
            'FM Breaches',
            'Propagation Events',
            'Access Fee Transactions'
        ];
        this.ICON_MAPPING = ICON_MAPPING;
        this.dataReceived = false;
        this.filterComponents = [];
        this.showFilters = false;
    }
    CaptainDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defineFilters();
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        var id = this.route.snapshot.paramMap.get('id');
        if (!id) {
            id = this.route.snapshot.queryParamMap.get('phone');
        }
        if (!id) {
            return;
        }
        this.id = HelperService.decode(id);
        this.entityService.getEntityByNumber(this.id).subscribe(function (data) {
            _this.captainDetails = data;
            _this.deviceId = data.device && data.device.deviceId;
            _this.captainId = data && data['userId'] && data['userId']['_id'];
            _this.userId = data && data['userId'] && data['userId']['_id'] || '';
            _this.mode = data.modeId && data.modeId.mode;
            _this.captainService.captainLiveStat(_this.captainId)
                .toPromise()
                .then(function (data) {
                _this.liveLocation = data.riderLocation && data.riderLocation.location;
            })
                .finally(function () {
                var _a;
                var lat, lng;
                if (_this.liveLocation) {
                    _a = _this.liveLocation, lng = _a[0], lat = _a[1];
                }
                _this.captainService.getCaptainAllRateCards(_this.userId, lat, lng).subscribe(function (rateCardData) {
                    if (Object.keys(rateCardData).length) {
                        _this.captainAllRateCard = rateCardData;
                    }
                });
            });
        });
    };
    CaptainDetailsComponent.prototype.captainLevelDisplayName = function (name) {
        this.captainLevelName = name;
    };
    CaptainDetailsComponent.prototype.headersToggled = function (toggledHeader) {
        this.toggleableDetails = toggledHeader;
    };
    CaptainDetailsComponent.prototype.defineFilters = function () {
        this.filterComponents = [
            {
                type: 'searchable-dropdown',
                title: 'City',
                values: ['a', 'b', 'c'],
                default: '',
                key: 'incentive-city'
            },
            {
                type: 'searchable-dropdown',
                title: 'Services',
                values: ['a', 'b', 'c'],
                default: '',
                key: 'incentive-services'
            },
            {
                type: 'calendar',
                title: '',
                values: [],
                default: '',
                key: 'incentive-dates'
            },
            {
                type: 'radio-button',
                title: 'Status',
                values: ['Both', 'Active', 'Inactive'],
                default: '',
                key: 'incentive-status'
            }
        ];
        this.dataReceived = true;
    };
    // filterToggle() {
    //   this.showFilters = !this.showFilters;
    // }
    CaptainDetailsComponent.prototype.listen = function (events) {
        // console.log(events);
    };
    CaptainDetailsComponent.prototype.clearFilters = function () {
        var _this = this;
        this.dataReceived = false;
        setTimeout(function () {
            _this.dataReceived = true;
        }, 200);
    };
    CaptainDetailsComponent.prototype.applyFilters = function (event) {
        // console.log(event);
    };
    return CaptainDetailsComponent;
}());
export { CaptainDetailsComponent };
