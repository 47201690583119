var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { OrderService } from 'src/app/order-service.service';
import { CaptainFailedOrdersService } from './captain-failed-orders.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { FilterService, DateFilterInput, SearchableDropDownFilterInput, SingleSearchableDropdown } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { RevokeFailedOrderComponent } from './revoke-failed-order/revoke-failed-order.component';
// import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { SvoService } from '../../svo.service';
var currentDate = moment(new Date()).utcOffset('+0530').format('YYYY-MM-DD');
function defaultFiters() {
    return {
        startDuration: currentDate,
        endDuration: currentDate,
        cities: '',
        service: '',
        failedOrderType: ''
    };
}
var CaptainFailedOrdersComponent = /** @class */ (function () {
    function CaptainFailedOrdersComponent(dialog, toasterService, orderService, filterService, entityService, route, dataService, router, captainFailedOrdersService, svoService) {
        this.dialog = dialog;
        this.toasterService = toasterService;
        this.orderService = orderService;
        this.filterService = filterService;
        this.entityService = entityService;
        this.route = route;
        this.dataService = dataService;
        this.router = router;
        this.captainFailedOrdersService = captainFailedOrdersService;
        this.svoService = svoService;
        this.serviceMap = {};
        this.breadcrumb = faChevronRight;
        this.filtersApplied = defaultFiters();
        this.services = [];
        this.cashback = { from: '0', to: '100' };
        this.rating = { from: '0', to: '5' };
        this.failedOrderTypes = [
            'Rider Busy',
            'Rider Rejected',
            'Customer Cancelled',
            'Aborted'
        ];
        this.failedOrderTypeMap = {
            'Rider Busy': 'rider_busy',
            'Rider Rejected': 'rider_reject',
            'Customer Cancelled': 'customer_cancelled',
            'Rider Cancelled': 'rider_cancelled',
            'Aborted': 'aborted'
        };
        this.orderTypes = [
            'app',
            'auto',
            'bfs',
            'c2c',
            'delivery',
            'hire',
            'premium',
            'sme'
        ];
        this.filtersApplicable = {
            startDuration: 'startDate',
            endDuration: 'endDate',
            orderTypes: 'orderTypes',
            failedOrderType: 'events',
            service: 'serviceDetails',
        };
        this.currentPage = 0;
        this.limit = 10;
    }
    CaptainFailedOrdersComponent.prototype.ngOnInit = function () {
        var _this = this;
        // const fromDate = this.route.snapshot.queryParamMap.get('fd');
        // const toDate = this.route.snapshot.queryParamMap.get('td');
        // if (fromDate && toDate) {
        //   this.filtersApplied.startDuration = fromDate;
        //   this.filtersApplied.endDuration = toDate;
        // }
        this.riderMobile = this.route.snapshot.params.mobile ? this.route.snapshot.params.mobile : '';
        var riderMobile = HelperService.decode(this.riderMobile);
        this.entityService.getEntityByNumber(riderMobile).subscribe(function (resp) {
            if (resp) {
                if (resp.userId._id) {
                    _this.riderId = resp.userId._id;
                    _this.defineOrderTable();
                    _this.definePingTables();
                }
                if (resp.city && resp.city._id) {
                    _this.riderCity = resp.city._id;
                    _this.entityService.getServicesCityWise(_this.riderCity).subscribe(function (services) {
                        services = services['data'];
                        _this.services.splice(0);
                        for (var service in services) {
                            if (services[service] && services[service]._id && services[service].service && services[service].service.active) {
                                _this.services.push(services[service].service.name);
                                _this.serviceMap[services[service].service.name] = services[service]._id;
                            }
                        }
                    });
                }
            }
        });
        // this.riderId = this.route.snapshot.params.id ? HelperService.decode(this.route.snapshot.params.id) : '';
    };
    CaptainFailedOrdersComponent.prototype.redirectToCaptainDetails = function () {
        this.router.navigate([
            'captain/' + this.riderMobile
        ]);
    };
    CaptainFailedOrdersComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.dataService.searchUserCount.subscribe(function (result) {
            if (!_this.currentNumber || (_this.currentNumber === result)) {
                _this.filterService.appliedFilters = _this.filtersApplied;
            }
            else {
                _this.filterService.appliedFilters = {};
                _this.filtersApplied = defaultFiters();
            }
            _this.currentNumber = result;
        });
        this.orderTable = new Table({
            tableHeader: 'Failed Orders',
            headers: {
                dateTime: 'Order Date & Time',
                orderId: 'Order Id',
                pickupLocation: 'Pickup',
                service: 'Service',
                eventDateTime: 'Event Date & Time',
                orderStatus: 'Order Status',
                getInfo: 'Get Info'
            },
            pagination: new Pagination(pageNumber, 10),
            config: {
                refresh: true,
            },
            state: 'loading',
            onRefresh: function () {
                _this.orderTable = null;
                _this.ngOnInit();
            },
            appliedFilters: this.filtersApplied,
            filterComponents: [
                new DateFilterInput({
                    key: 'duration',
                    default: '',
                    title: 'Duration'
                }),
                new SearchableDropDownFilterInput({
                    key: 'service',
                    title: 'Services',
                    values: this.services,
                }),
                new SearchableDropDownFilterInput({
                    key: 'failedOrderType',
                    title: 'Failed Order Type',
                    values: this.failedOrderTypes,
                }),
                new SingleSearchableDropdown({
                    key: 'orderTypes',
                    title: 'Order Type',
                    values: this.orderTypes,
                })
            ],
            onFilter: function () {
                var timeSlot = _this.filterService.appliedFilters.duration || [currentDate, currentDate];
                var duration = [];
                if (!Array.isArray(timeSlot)) {
                    duration.push(timeSlot['start']);
                    duration.push(timeSlot['end']);
                }
                else {
                    duration = timeSlot;
                }
                var startDuration = duration[0] || currentDate;
                var endDuration = duration[1] || currentDate;
                var cities = _this.filterService.appliedFilters.cities;
                var service = _this.filterService.appliedFilters.service;
                var failedOrderType = _this.filterService.appliedFilters.failedOrderType;
                var orderTypes = _this.filterService.appliedFilters.orderTypes;
                var filtersApplied = {
                    duration: {
                        start: startDuration,
                        end: endDuration,
                    },
                    startDuration: startDuration,
                    endDuration: endDuration,
                    cities: cities,
                    service: service,
                    failedOrderType: failedOrderType,
                    orderTypes: orderTypes
                };
                Object.assign(_this.filtersApplied, filtersApplied);
                var isValidNumber = function (value) {
                    return ((parseInt(value, 10) === 0) || value);
                };
                if ((startDuration && endDuration)) {
                    startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
                    endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
                    return _this.defineOrderTable(_this.filtersApplied);
                }
                return _this.defineOrderTable(_this.filtersApplied);
            },
            data: []
        });
    };
    CaptainFailedOrdersComponent.prototype.onPaginationChange = function (event) {
        this.currentPage = event.pageNo;
        this.fillDataToTable(this.filtersApplied);
    };
    CaptainFailedOrdersComponent.prototype.getOrderList = function (filters) {
        var finalFilters = {};
        this.filterCount = 0;
        for (var filter in filters) {
            if (filters[filter] && this.filtersApplicable[filter]) {
                if (filter === 'failedOrderType') {
                    finalFilters[this.filtersApplicable[filter]] = [];
                    for (var _i = 0, _a = filters[filter]; _i < _a.length; _i++) {
                        var orderType = _a[_i];
                        finalFilters[this.filtersApplicable[filter]].push(this.failedOrderTypeMap[orderType]);
                    }
                }
                else if (filter === 'orderTypes') {
                    finalFilters[this.filtersApplicable[filter]] = [filters[filter]];
                }
                else if (filter === 'service') {
                    if (filters[filter].length) {
                        finalFilters[this.filtersApplicable[filter]] = [];
                        for (var service in filters[filter]) {
                            if (filters[filter][service]) {
                                finalFilters[this.filtersApplicable[filter]].push(this.serviceMap[filters[filter][service]]);
                            }
                        }
                    }
                }
                else {
                    finalFilters[this.filtersApplicable[filter]] = filters[filter];
                }
                this.filterCount++;
            }
        }
        finalFilters['limit'] = this.limit;
        finalFilters['skip'] = this.limit * this.currentPage;
        return this.captainFailedOrdersService.getFailedOrders(this.riderId, finalFilters);
    };
    CaptainFailedOrdersComponent.prototype.fillDataToTable = function (filters) {
        var _this = this;
        if (this.riderId) {
            this.orderTable.dataWillLoad();
            this.getOrderList(filters).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
                function createTableData(value, className) {
                    return new TableData({
                        data: value,
                        type: TableDataTypes.DATA,
                        className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                        onClick: function (data) {
                            self.router.navigate(["/order/" + data._id.data]);
                        }
                    });
                }
                var orderIds, self, adjustmentsHistory;
                var _this = this;
                return __generator(this, function (_a) {
                    orderIds = [];
                    self = this;
                    adjustmentsHistory = {};
                    if (response && response['data'] && response['data'].data) {
                        this.orders = response['data'].data.map(function (order, i) {
                            var orderId = order.orderId;
                            orderIds.push(orderId);
                            var self = _this;
                            var orderDate = new Date(order.orderCreatedDate);
                            return {
                                _id: createTableData(orderId),
                                dateTime: createTableData(HelperService.dateString(orderDate) + ', ' + order.orderTime),
                                orderId: new TableData({
                                    data: order.uniqueId,
                                    type: TableDataTypes.DATA,
                                    className: 'cursor-pointer whiteSpacePre',
                                    onClick: function (data) {
                                        self.router.navigate(["/order/" + data._id.data]);
                                    }
                                }),
                                pickupLocation: createTableData(order.pickAddress),
                                service: createTableData(order.serviceName),
                                eventDateTime: createTableData(HelperService.dateString(order.eventDate) + ', ' + order.eventTime),
                                orderStatus: createTableData(_this.formatEventName(order.event)),
                                getInfo: new TableData({
                                    data: 'Get Info',
                                    type: TableDataTypes.ACTION,
                                    showIcon: order.isRevoked ? 'check_circle' : '',
                                    className: order.isRevoked ?
                                        'cursor-pointer whiteSpacePre' : 'cursor-pointer whiteSpacePre',
                                    spanClass: 'revoke-button',
                                    iconClass: order.isRevoked ? 'revoke-icon color-kermit-green' : '',
                                    onClick: function (data) {
                                        var orderId = data._id && data._id.data;
                                        _this.captainFailedOrdersService.getActivityLog(_this.riderId, orderId).subscribe(function (res) {
                                            if (res && res['data'] && res['data'].data && res['data'].data.length) {
                                                var eventCount = {
                                                    isRevoked: order.isRevoked,
                                                    revokedBy: order.revokedBy || '',
                                                    revokedOn: order.revokedOn || '',
                                                    orderId: order.orderId,
                                                    event: order.event,
                                                    eventDate: order.eventDate,
                                                    orderCreatedDate: order.orderCreatedDate,
                                                    currentUser: _this.getCurrentUser(),
                                                    riderId: _this.riderId
                                                };
                                                var dialogRef = _this.dialog.open(RevokeFailedOrderComponent, {
                                                    width: '900px',
                                                    data: { activityLog: res['data'].data, eventCount: eventCount, accesses: _this.getRevokeAccesses() }
                                                });
                                                dialogRef.afterClosed().subscribe(function (response) {
                                                    _this.defineOrderTable();
                                                });
                                            }
                                            else {
                                                _this.toasterService.showToaster(new Toaster({
                                                    type: ToasterType.WARNING,
                                                    message: "Cannot find logs for this record",
                                                }));
                                            }
                                        });
                                    }
                                }),
                            };
                        });
                        this.orderTable.pagination.count = response['data'] && response['data']['total'] || response['data'].data.length;
                        this.orderTable.pagination.pageSizeOptions = [10];
                        this.orderTable.pagination.perPage = this.limit;
                    }
                    this.orderTable.data = this.orders;
                    this.orderTable.filterCount = this.filterCount;
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            console.log('Something went wrong');
        }
    };
    CaptainFailedOrdersComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    CaptainFailedOrdersComponent.prototype.formatEventName = function (name) {
        var event = name.split('_');
        if (event.length > 1) {
            var str = event.join(' ');
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
        else {
            return name;
        }
    };
    CaptainFailedOrdersComponent.prototype.defineOrderTable = function (filters) {
        if (filters === void 0) { filters = this.filtersApplied; }
        this.declareTable();
        this.fillDataToTable(filters);
    };
    CaptainFailedOrdersComponent.prototype.definePingTables = function () {
        var _this = this;
        this.dailyPingsTable = new Table({
            tableHeader: 'Daily Pings',
            pagination: new Pagination(0, 5),
            headers: {
                orderStatus: 'Order Status',
                count: 'Count',
                performance: 'Performance'
            },
            config: {
                refresh: true,
            },
            state: 'loading',
            onRefresh: function () {
                _this.dailyPingsTable = null;
                _this.reloadPingsData();
            },
            filterComponents: [],
            data: []
        });
        var generateObject = function (n) { return Array.from({ length: n }, function (_, i) { return i; }).reduce(function (obj, num) {
            var key = "" + num;
            if (key.length === 1) {
                key = "0" + key;
            }
            obj["h_" + key] = "" + num;
            return obj;
        }, {}); };
        var header = generateObject(24);
        this.hourlyPingsTable = new Table({
            tableHeader: 'Hourly Pings',
            pagination: new Pagination(0, 5),
            headers: __assign({ orderStatus: 'Order Status' }, header),
            config: {
                refresh: true,
            },
            state: 'loading',
            onRefresh: function () {
                _this.hourlyPingsTable = null;
                _this.reloadPingsData();
            },
            filterComponents: [],
            data: []
        });
        this.fillDataToPingTables();
    };
    CaptainFailedOrdersComponent.prototype.reloadPingsData = function () {
        this.definePingTables();
    };
    CaptainFailedOrdersComponent.prototype.fillDataToPingTables = function () {
        var _this = this;
        this.dailyPingsTable.dataWillLoad();
        this.hourlyPingsTable.dataWillLoad();
        this.svoService.getCaptainPerformance(this.riderId).then(function (response) {
            var data = response.data;
            function createTableData(value, className) {
                return new TableData({
                    data: value,
                    type: TableDataTypes.DATA
                });
            }
            var total = data.daily.customer_cancelled + data.daily.rider_cancelled + data.daily.rider_busy + data.daily.rider_rejected;
            total = total + data.daily.dropped + data.daily.aborted + data.daily.switch_rider;
            var dailyPingsData = [
                {
                    orderStatus: createTableData('Customer Cancelled'),
                    count: createTableData(data.daily.customer_cancelled),
                },
                {
                    orderStatus: createTableData('Rider Cancelled'),
                    count: createTableData(data.daily.rider_cancelled),
                },
                {
                    orderStatus: createTableData('Rider Busy'),
                    count: createTableData(data.daily.rider_busy),
                },
                {
                    orderStatus: createTableData('Rider Rejected'),
                    count: createTableData(data.daily.rider_rejected),
                },
                {
                    orderStatus: createTableData('Dropped'),
                    count: createTableData(data.daily.dropped),
                },
                {
                    orderStatus: createTableData('Aborted'),
                    count: createTableData(data.daily.aborted),
                },
                {
                    orderStatus: createTableData('Captain Switch'),
                    count: createTableData(data.daily.switch_rider),
                }
            ];
            _this.dailyPingsTable.data = dailyPingsData.map(function (val) {
                console.log(val.count.data, total);
                val.performance = createTableData(((val.count.data / total) * 100).toFixed(2) + "%");
                return val;
            });
            var hourlyData = data.hourly.reduce(function (acc, val) {
                acc['aborted']["h_" + val.hh] = val.aborted;
                acc['customer_cancelled']["h_" + val.hh] = val.customer_cancelled;
                acc['dropped']["h_" + val.hh] = val.dropped;
                acc['rider_busy']["h_" + val.hh] = val.rider_busy;
                acc['rider_cancelled']["h_" + val.hh] = val.rider_cancelled;
                acc['rider_rejected']["h_" + val.hh] = val.rider_rejected;
                acc['switch_rider']["h_" + val.hh] = val.switch_rider;
                return acc;
            }, {
                'aborted': {},
                'customer_cancelled': {},
                'dropped': {},
                'rider_busy': {},
                'rider_cancelled': {},
                'rider_rejected': {},
                'switch_rider': {}
            });
            console.log(hourlyData);
            _this.hourlyPingsTable.data = [
                __assign({ orderStatus: createTableData('Aborted') }, hourlyData['aborted']),
                __assign({ orderStatus: createTableData('Customer Cancelled') }, hourlyData['customer_cancelled']),
                __assign({ orderStatus: createTableData('Dropped') }, hourlyData['dropped']),
                __assign({ orderStatus: createTableData('Rider Busy') }, hourlyData['rider_busy']),
                __assign({ orderStatus: createTableData('Rider Cancelled') }, hourlyData['rider_cancelled']),
                __assign({ orderStatus: createTableData('Rider Rejected') }, hourlyData['rider_rejected']),
                __assign({ orderStatus: createTableData('Captain Switch') }, hourlyData['switch_rider'])
            ];
        });
        this.dailyPingsTable.pagination.count = 5;
        this.dailyPingsTable.pagination.pageSizeOptions = [10];
        this.dailyPingsTable.pagination.perPage = 5;
    };
    CaptainFailedOrdersComponent.prototype.getCurrentUser = function () {
        return this.accessCookie('name');
    };
    CaptainFailedOrdersComponent.prototype.getRevokeAccesses = function () {
        var permissions = this.accessCookie('accessRoles');
        return permissions.split(',');
    };
    CaptainFailedOrdersComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    return CaptainFailedOrdersComponent;
}());
export { CaptainFailedOrdersComponent };
