import { ToasterService } from 'src/app/toaster.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Toaster, ToasterType } from 'src/app/shared/types';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FRAUDTYPES, FRAUDTAGS, FRAUDCAUSES } from '../shared/constants';

@Component({
  selector: 'app-fraud-dialog',
  templateUrl: './fraud-dialog.component.html',
  styleUrls: ['./fraud-dialog.component.css']
})
export class FraudDialogComponent implements OnInit {
  public allowDelete: boolean = false;
  public fraudTypes = FRAUDTYPES;
  public dropDownFraudType = {
    values: Object.keys(this.fraudTypes).map(item => this.fraudTypes[item]),
    title: 'Fraud Types',
    key: 'types'
  };

  public dropDownFraudTags = {
    values: FRAUDTAGS,
    title: 'Fraud Tags',
    key: 'tags'
  };

  public dropDownFraudCause = {
    values: FRAUDCAUSES,
    title: 'Fraud causes',
    key: 'causes'
  };

  public component = {
    types: [],
    tags: [],
    causes: []
  };

  public updatedFraudData: any;
  public permissions : any;
  public REMOVE_SPD_FRAUD : string = 'remove_spd_fraud';
  public SPD_FRAUD_KEY : string = 'spdFraud';

  constructor(
    public dialogRef: MatDialogRef<FraudDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toasterService: ToasterService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.component.tags = this.data['fraudDetails'] ?  this.data['fraudDetails']['tags'] : [];
    this.component.causes = this.data['fraudDetails'] ? this.data['fraudDetails']['causes'] : [];
    this.component.types = this.data.fraudDetails.types;

    this.updatedFraudData = {
      tags: this.component.tags,
      causes: this.component.causes,
      types: this.getFraudTypesKeysFromValue(this.component.types)
    }

    this.checkIfAllowDelete();
    this.permissions = this.accessCookie('accessRoles');
  }

  checkIfAllowDelete() {
    if(hasInitialData(this.component)) {
      this.allowDelete = false;
    } else if ((this.updatedFraudData.types && this.updatedFraudData.types.length === 0)
      && ( this.updatedFraudData.tags && this.updatedFraudData.tags.length === 0) 
      && (this.updatedFraudData.causes && this.updatedFraudData.causes.length === 0)
    ) {
      this.allowDelete = false;
    } else {
      this.allowDelete = true;
    }

    function hasInitialData(component) {
      return component.tags.length === 0 && component.causes.length === 0 && Object.keys(component.types).length === 0;
    }
  }

  getFraudTypesKeysFromValue(valArr) {
    let keys = [];

    keys = valArr.map((val) => {
      let key;

      Object.keys(this.fraudTypes).forEach(type => {
        if(this.fraudTypes[type] === val) {
          key = type;
        }
      });

      return key;
    });

    return keys;
  }

  getTypeKeys(KeyArr) {
    let keys = [];

    keys = KeyArr.map((val) => {
      let key;

      Object.keys(this.fraudTypes).forEach(type => {
        if(type === val) {
          key = type;
        }
      });

      return key;
    });

    return keys;
  }

  updateFrauds() {
    if(this.permissions && this.isEligibleToUpdate()){
      this.dialogRef.close({ updateData: this.updatedFraudData, action: 'update' });
    } else {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `You are not authorized to remove SPD Fraud type`,
        delay: 3000
      }));
      this.dialogRef.close({});
    }
  }

  deleteFrauds() {
    if (this.permissions && this.isEligibleToDelete()) {
      this.dialogRef.close({ updateData: this.updatedFraudData, action: 'delete' });
    } else {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `You are not authorized to remove SPD Fraud type`,
        delay: 3000
      }));
      this.dialogRef.close({});
    }
  }

  captureSelectedOptions($evt) {
    let updatedKey = Object.keys($evt)[0];

    switch(updatedKey) {
      case 'types':
        this.updatedFraudData[updatedKey] = this.getFraudTypesKeysFromValue($evt[updatedKey]);
        break;
      default:
        this.updatedFraudData[updatedKey] = $evt[updatedKey]
    }

    this.checkIfAllowDelete();
  }

  clear() {
    this.component = {
      types: [],
      tags: [],
      causes: []
    };

    this.updatedFraudData = {
      tags: this.component.tags,
      causes: this.component.causes,
      types: this.getFraudTypesKeysFromValue(this.component.types)
    }

    this.checkIfAllowDelete();
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

  isEligibleToDelete(){
    const fraudTypes = this.data.fraudDetails.types;
    if(fraudTypes){
      return !(fraudTypes.includes(FRAUDTYPES.spdFraud) && !this.permissions.split(',').includes(this.REMOVE_SPD_FRAUD));
    }else {
      return true;
    }
  }

  isEligibleToUpdate(){
    const fraudTypes = this.data.fraudDetails.types;
    const updatedFraudTypes = this.updatedFraudData.types;
    if(fraudTypes && updatedFraudTypes){
      return !(fraudTypes.includes(FRAUDTYPES.spdFraud) && !updatedFraudTypes.includes(this.SPD_FRAUD_KEY) && !this.permissions.split(',').includes(this.REMOVE_SPD_FRAUD));
    }else{
      return true;
    }
  }
}
