<div>
  <div class="row order-details-header is-flex">
    <div *ngIf="uniqueId" class="col-md-4 is-flex">
      OrderId #{{uniqueId}}
      <fa-icon class="cursor-pointer margin-icons refresh" *ngIf= "!isRefreshing" (click)="refreshPage(true)" [icon]="faRefresh"></fa-icon>
      <p class="refreshing" *ngIf= "isRefreshing"> Refreshing . . . </p>
    </div>


    <div style="display: flex; margin-left: 130px;">
      <div *ngIf="otp">
        START PIN - {{otp}}
      </div>
      <div *ngIf="!otp">
        START PIN - INACTIVE
      </div>
      <div *ngIf="dropOtp" style="margin-left: 40px;">
        DROP PIN - {{dropOtp}}
      </div>
      <div *ngIf="!dropOtp" style="margin-left: 40px;">
        DROP PIN - {{dropOtpStatus}}
      </div>
      <div *ngIf="deliveryId" style="margin-left: 70px;">
        {{clientName || 'ClientId'}} #{{deliveryId}}
      </div>
    </div>


    <!-- todo1485 -->
    <div *ngIf="correlationIds" class="correlation col-md-4"> 
      <div class="correlation-data">
        <div tooltipPosition="bottom" pTooltip="{{correlationIds.join(', ')}}"><span><i *ngIf="showTxnInfoModal" (click)="popupClick()" class="material-icons-outlined card-info cursor-pointer">info</i>Correlation Id : </span>{{correlationIds.join(', ')}}</div>
        <div tooltipPosition="bottom" pTooltip="{{correlationTs.join(', ')}}">Generated At: {{correlationTs.join(', ')}}</div>
      </div>
      <div>
        <i class="material-icons-outlined card-info cursor-pointer copy-icon"
          *ngIf="correlationIds"  (click)="copyCorrelationIds()"
          pTooltip="Copy to clipboard" tooltipPosition="bottom">
          copy
        </i>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col" *ngIf="isBatchable">
      <div class="row">
        <div class="col">
          <img src="../../assets/svgImages/batchedOrders.svg">
          <span class="batching">
            Batched Orders
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button mat-button [matMenuTriggerFor]="menu" class="menu-trigger">
            OrderID #{{orderId}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="goToOrderPage(order.id)" mat-menu-item *ngFor="let order of batchingOrderDetails; let i = index">
              <div class="flex-spaced">
                <div class="margin-right-25" [ngClass]="{'bold': order.id === orderId}">
                  {{i + 1}}. Order {{order.id}}
                </div>
                <div class="status-badge capitalize {{order.statusClass}}">
                    {{order.status}}
                </div>
              </div>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  <div class="row padding-bottom-16"> 
    <div class="col">
      <app-timeline-view [orderTimeLineStatus]="orderTimeLine" [dropOTP]="otp" [serviceObj]="serviceObj" 
        [deviceId]="deviceId" [isBatchable]="isBatchable" [captainDetails]="captainTimelineDetails" [customerDetails]="custDetails" [pickupLocation]="pickupLocation" [dispatch]="dispatch"
        [timeLineDetails]="timeLineDetails" [statusMap]="statusMap"
        [reAssignDetails]="reAssignDetails" [b2bConfig]="b2bConfig" [FNDCase]="FNDCase"
        [microCorridorDetails]="microCorridorDetails" [isDropOrderEnabled]="isDropOrderEnabled" (refresh)="refreshPage(false)">
      </app-timeline-view>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-3">
      <app-cust-order-details [custDetails]="custDetails"></app-cust-order-details>
    </div>
    <div class="col-md-3">
      <app-capt-order-details [captDetails]="captDetails"></app-capt-order-details>
    </div>
    <!-- todo1485 -->
    <div class="col-md-3">
      <app-invoice-details [rateCard]="rateCard" [invoiceDetails]="invoiceDetails" [emailInvoice]="emailInvoice" [correlationIds]="correlationIds"></app-invoice-details>
    </div>
    <!-- todo1485 -->
    <div class="col-md-3">
      <app-captain-invoice-details [orderType]="orderType" [captainRateCard]="captainRateCard" [captainInvoiceDetails]="captainInvoiceDetails"></app-captain-invoice-details>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-dist-time-details [distTimeDetails]="distTime"></app-dist-time-details>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-fraud-view (fraudUpdated)="this.refreshPage(true)"></app-fraud-view>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-fare-estimate-log [fareEstimateLogs]="fareEstimateLogs" [pageRefreshed]="pageRefreshed"></app-fare-estimate-log>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-ride-tracker-map [riderIdForOrder]="riderId" [mapData]="mapData" [cancellationInformation]="cancellationInformation" [showOrderLogs]="showOrderLogsOnMaps" [orderStatus]="orderStatus" [orderCityId]="orderCityId" (fullViewMap)="fullViewMap($event)"></app-ride-tracker-map>
    </div>
  </div>

  <div class="row padding-bottom-16" *ngIf="showPackageCard">
    <app-c2c-sender-details [packageDetails]="packageDetails" class="col-md-6"></app-c2c-sender-details>
    <app-c2c-receiver-details [packageDetails]="packageDetails" class="col-md-6"></app-c2c-receiver-details>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-cust-adjustment (adjustmentDone)="refreshPage(null)"  [walletInfo]="walletInfo" [customerAdjDetails]="customerAdjDetails" [orderStatus]="orderStatus" [coinPreference]="coinPreference"></app-cust-adjustment>
    </div>
    <div class="col-md-12">
      <app-capt-adjustment (adjustmentDone)="refreshPage(null)" [captainAdjDetails]="captainAdjDetails" [orderStatus]="orderStatus" [distanceDetails]="distanceDetails"></app-capt-adjustment>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-adjustment-history [offlineRechargeInfo]="offlineRechargeInfo" [custAdjustments]="custAdjustments" [captAdjustments]="captAdjustments"
              [pageRefreshed]="pageRefreshed" [prevDuesAdjustments]="prevDuesAdjustments"></app-adjustment-history>
    </div>
  </div>

  <div class="row padding-bottom-16">
    <div class="col-md-12">
      <app-cust-activity-history [custActHistory]="custActHistory"></app-cust-activity-history>
    </div>
  </div>


  <div class="row padding-bottom-16">
    <div class="col-md-6">
      <app-order-log [orderLogDetails]="orderLogDetails" (showOnMaps)="showLogsOnMap()"
        [statusMap]="statusMap" [orderCityId]="orderCityId" [pageRefreshed]="pageRefreshed"></app-order-log>
    </div>
    <div class="col-md-6">
      <app-mapped-captains [mapRiderDetails]="mapRiderDetails"></app-mapped-captains>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-rating-details [ratingDetails]="ratingDetails"></app-rating-details>
    </div>
  </div>
</div>
