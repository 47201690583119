<head>
    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>
  <h1 mat-dialog-title class="dialog-title margin-0" [ngStyle]="{'color': data.type === 'order-details'? '#cd1f1e': 'black'}">{{data.title}}</h1>
  <div class="margin-10-0">
      <mat-label  *ngIf="data.type === 'order-details'" class="font-size-14">{{data.message}}</mat-label>
  </div>
  
  <div mat-dialog-content  *ngIf="data.type === 'customer-details'">
    <mat-form-field>
      <mat-label>Reason for {{data.reason}}</mat-label>
      <mat-select [(ngModel)]="selectedReason" style="width: 200px;" (selectionChange)="checkReasons(selectedReason)">
        <mat-option *ngFor="let reason of data.options" [value]="reason">
          {{reason}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <form *ngIf="selectedReason === 'Other'">
      <mat-form-field class="example-full-width">
        <input matInput [(ngModel)]="otherReason" name="otherReason" placeholder="Please provide explanation">
      </mat-form-field>
    </form>
  </div>
  
  <div  *ngIf="data.type === 'order-details'">
      <ng-select class="test" appendTo="body" placeholder="Select reason" [(ngModel)]="selectedReason" (click)="checkReasons(selectedReason)">
          <ng-option *ngFor="let reason of data.options" [value]="reason">{{reason}}</ng-option>
       </ng-select>
       <form *ngIf="selectedReason === 'Other'">
          <input type=“text” class="text-box" pInputText name="otherReason" [(ngModel)]="otherReason" placeholder="Please provide explanation" onblur="this.placeholder='Please provide explanation'"
          onfocus="this.placeholder=''"/>
        </form>
  </div>
  
  <div *ngIf="showReasonAlert" class="reason-alert">
      <i class="material-icons-outlined info">info</i> 
      <label class="font-size-12 color-rusty-red margin-left-5">{{data.errorMessage}}</label>
  </div>
  
  <div *ngIf="otherReasonAlert" class="reason-alert">
    <i class="material-icons-outlined info">info</i> 
    <label class="font-size-12 color-rusty-red margin-left-5">Provide an explanation</label>
  </div>

  <div *ngIf="data.type === 'image'">
    <img src="{{data.imageUrl}}">
  </div>
  
  <div mat-dialog-actions class="button-flex" *ngIf="data.type !=='image'" >
      <button class="dialog-cancel" (click)="onNoClick()">Cancel</button>
      <button class="proceed font-size-13 font-weight-600" (click)="verifyAndSendOption()">Proceed</button>
  </div>