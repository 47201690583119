import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client.service";
var CustomerOrderDetailsService = /** @class */ (function () {
    function CustomerOrderDetailsService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getRefund = this.server + '/api/getRefundAmount'; // make this configurable
        this.getAdjustments = this.server + '/api/payments/getCustomerAdjustments';
    }
    CustomerOrderDetailsService.prototype.getRefundAmount = function (orders, id) {
        return this.http.post(this.getRefund + '/' + id, orders).pipe(catchError(this.handleError));
    };
    CustomerOrderDetailsService.prototype.getAdjustmentAmounts = function (orders, id) {
        return this.http.post(this.getAdjustments + '/' + id, orders).map(function (res) {
            var details = res['data'] && res['data'].response && res['data'].response.data &&
                res['data'].response.data.list ? res['data'].response.data.list : [];
            return details;
        })
            .pipe(catchError(this.handleError));
    };
    CustomerOrderDetailsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CustomerOrderDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerOrderDetailsService_Factory() { return new CustomerOrderDetailsService(i0.inject(i1.MyHttpClient)); }, token: CustomerOrderDetailsService, providedIn: "root" });
    return CustomerOrderDetailsService;
}());
export { CustomerOrderDetailsService };
