import { Injectable } from '@angular/core';

@Injectable()
export class CaptainInvoiceHelperService {    

    constructor() { }
    surgeFareTooltip = "Surge Fare includes all surges - Rain, Night, Location Based Surge. Surge Fare is systematically given for orders where it is applicable";
    longPickupTooltip = "Long Pick Fare is systematically given for orders where it is applicable.";
    rapidoCommissionTooltip = "A certain percentage of the total of order earning order, surge, long up fare is deducted as Rapido’s commission. The value of the percentage is defined in the Rate Card";
    gstTooltip = "GST is taxes paid to the government for the order";
    tdsTooltip = "TDS is paid to the Govt. To avoid TDS deduction please upload Aadhar/Pan in Profile";
    modeOfPaymentTooltip = "Amount deducted from wallet is caculated based on : cash collected - previous dues of customer - deductions for Rapido’s cut";

    mapBikeNewEarnings(earningsBreakup, data){
        let orderEarningsDropdown = [], deductionsDropdown = [], extrasDropdown = [], modeOfpaymentDropdown = [],
        baseFare = earningsBreakup.orderBreakup.baseFare ? earningsBreakup.orderBreakup.baseFare : 0, 
        distanceFareInfoLabel = earningsBreakup.orderBreakup.distanceFareInfoLabel ? earningsBreakup.orderBreakup.distanceFareInfoLabel : "",
        distanceFare = earningsBreakup.orderBreakup.distanceFare ? earningsBreakup.orderBreakup.distanceFare : 0,
        timeFareInfoLabel = earningsBreakup.orderBreakup.timeFareInfoLabel ? earningsBreakup.orderBreakup.timeFareInfoLabel : "",
        timeFare = earningsBreakup.orderBreakup.timeFare ? earningsBreakup.orderBreakup.timeFare : 0,
        bidDelta = earningsBreakup.orderBreakup.bidDelta,

        platformFee = earningsBreakup.orderBreakup.platformFee ? earningsBreakup.orderBreakup.platformFee : 0, 
        minimumFare = earningsBreakup.orderBreakup.minimumFare ? earningsBreakup.orderBreakup.minimumFare : 0,
        orderFare = earningsBreakup.orderBreakup.orderFare ? earningsBreakup.orderBreakup.orderFare : 0, 
        totalOrderEarnings = earningsBreakup.orderBreakup.total ? earningsBreakup.orderBreakup.total : 0,
        nightFare = earningsBreakup && earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.nightFare ? earningsBreakup.orderBreakup.nightFare : 0,

        surgeFare = earningsBreakup.orderBreakup.surgeFare ? earningsBreakup.orderBreakup.surgeFare : 0,
        longPickupFare = earningsBreakup.orderBreakup.longPickupFare ? earningsBreakup.orderBreakup.longPickupFare : 0,
        longPickUpInfoLabel = earningsBreakup.orderBreakup.longPickUpInfoLabel ? earningsBreakup.orderBreakup.longPickUpInfoLabel : "",
        extraFareValue = earningsBreakup.orderBreakup.extraFare ? earningsBreakup.orderBreakup.extraFare : 0,
        extraFareLabel = earningsBreakup.orderBreakup.extraFareLabel ? earningsBreakup.orderBreakup.extraFareLabel : '',
        pickupFare = earningsBreakup.orderBreakup.pickupFare ? earningsBreakup.orderBreakup.pickupFare : 0,

        rapidoCommission = earningsBreakup.orderBreakup.rapidoCommission ? earningsBreakup.orderBreakup.rapidoCommission : 0, 
        gst = earningsBreakup.orderBreakup.gst ? earningsBreakup.orderBreakup.gst : 0, 
        conveyanceFee = earningsBreakup.orderBreakup.conveyanceFee ? earningsBreakup.orderBreakup.conveyanceFee : 0,
        tdsPenalty = earningsBreakup.orderBreakup.tdsPenalty ? earningsBreakup.orderBreakup.tdsPenalty : 0,

        tips = earningsBreakup.orderBreakup.tip > 0 ? earningsBreakup.orderBreakup.tip : 0,
        adjustments = earningsBreakup.orderBreakup.adjustments ? earningsBreakup.orderBreakup.adjustments : 0, 
        penalty = earningsBreakup.orderBreakup.penalty ? earningsBreakup.orderBreakup.penalty : 0,

        cashCollected = earningsBreakup.orderBreakup.cashCollected ? earningsBreakup.orderBreakup.cashCollected : 0, 
        amountDeductedKey = earningsBreakup.orderBreakup.moneyDeductedKey ? earningsBreakup.orderBreakup.moneyDeductedKey : "", 
        amountDeductedValue = earningsBreakup.orderBreakup.moneyDeductedValue ? earningsBreakup.orderBreakup.moneyDeductedValue : 0,
        amountToGetInWallet = earningsBreakup.orderBreakup.amountToGetInWallet ? earningsBreakup.orderBreakup.amountToGetInWallet : 0,
        modeOfPayment = earningsBreakup.orderBreakup.paymentType ? earningsBreakup.orderBreakup.paymentType : '',
        prevDue = earningsBreakup.orderBreakup.prevDue ? earningsBreakup.orderBreakup.prevDue : 0,
        isAmountDeducted = earningsBreakup.orderBreakup.isAmountDeducted,

        deductionsFare = (parseFloat(rapidoCommission) + parseFloat(conveyanceFee) + parseFloat(gst) + parseFloat(tdsPenalty)).toFixed(2);
        if(minimumFare){
          orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Minimum Fare ', value: '₹' + minimumFare });
        }else{
          orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Base Fare ', value: '₹' + baseFare });
          orderEarningsDropdown.push({headerClass: 'mat-expansion-color', header: 'Distance Fare ' + distanceFareInfoLabel, value: '₹' + distanceFare });
          orderEarningsDropdown.push({headerClass: 'mat-expansion-color', header: 'Time Fare ' + timeFareInfoLabel, value:'₹' +  timeFare });
        }
        orderEarningsDropdown.push({headerClass: 'mat-expansion-color', header: 'Platform Fare', value: '₹' + platformFee });

        if(extraFareLabel){
          extrasDropdown.push({headerClass: 'mat-expansion-color', isInfo:true, toolTip:this.surgeFareTooltip, header: 'Surge Fare ', value:'₹' +  surgeFare });
          if (pickupFare > 0) {
            extrasDropdown.push({ headerClass: 'mat-expansion-color', isInfo: false, header: 'Pickup Fare', value: '₹' + pickupFare })
          } else {
            extrasDropdown.push({ headerClass: 'mat-expansion-color', isInfo: true, toolTip: this.longPickupTooltip, header: 'Long Pickup Fare ' + longPickUpInfoLabel, value: '₹' + longPickupFare })
          }
          
        }
        deductionsDropdown.push({headerClass: 'mat-expansion-color', isInfo:true, toolTip:this.rapidoCommissionTooltip, header: "Rapido's Commission", value: '₹' + rapidoCommission });
        deductionsDropdown.push({headerClass: 'mat-expansion-color', isInfo:true, toolTip:this.gstTooltip, header: 'GST collected', value: '₹' + gst });
        deductionsDropdown.push({headerClass: 'mat-expansion-color', isInfo:false, header: 'Conveyance Fee', value: '₹' + conveyanceFee });
        deductionsDropdown.push({headerClass: 'mat-expansion-color', isInfo:true, toolTip:this.tdsTooltip, header: 'TDS', value: '₹' + tdsPenalty });

        if(modeOfPayment == "cash"){
          modeOfpaymentDropdown.push({headerClass: 'mat-expansion-color', isInfo:false, header: 'Total Cash Collected', value: '₹'+cashCollected});
          modeOfpaymentDropdown.push({headerClass: 'mat-expansion-color', isInfo:isAmountDeducted, toolTip:this.modeOfPaymentTooltip, header: amountDeductedKey, value: '₹'+amountDeductedValue})
          modeOfpaymentDropdown.push({headerClass: 'mat-expansion-color', isInfo:false, header: "Previous Dues owed by Customer to Rapido", value: '₹'+prevDue});
        }else{
          modeOfpaymentDropdown.push({headerClass: 'mat-expansion-color', isInfo:false, header: amountDeductedKey, value: '₹'+amountDeductedValue});
        }
        
        data.push({headerClass: 'header-card-color', matExpansionPanelContent:orderEarningsDropdown, value:'₹' + orderFare, isExpansion: true, matExpansionPanelHeader:"Order Earnings"});
        if(bidDelta != undefined){
          data.push({headerClass: 'header-card-color', isExpansion: false, header:"Bid Delta", value : '₹'+bidDelta});
        }
        if(extraFareLabel){
           data.push({headerClass: 'header-card-color', matExpansionPanelContent:extrasDropdown, value:'₹' + extraFareValue, isExpansion: true, matExpansionPanelHeader:extraFareLabel});
        }
        if(nightFare){
          data.push({headerClass: 'header-card-color', isExpansion:false, header:"Night Fare", value : '₹'+nightFare});
        }
        data.push({headerClass: 'header-card-color', matExpansionPanelContent:deductionsDropdown, value:'₹' + deductionsFare, isExpansion: true, matExpansionPanelHeader:"Deductions"});
        data.push({headerClass: 'header-card-color', isExpansion:false, header:"Tips", value : '₹'+tips});
        data.push({headerClass: 'header-card-color', isExpansion:false, header:"Adjustments", value: '₹'+adjustments });
        data.push({headerClass: 'header-card-color', isExpansion:false, header:"Penalty", value: '₹'+penalty });
        data.push({headerClass: 'header-card-color', isExpansion:false, header:"Total Captain Earnings", value: '₹'+totalOrderEarnings});
        data.push({headerClass: 'header-card-color', matExpansionPanelContent: modeOfpaymentDropdown, value: modeOfPayment, isExpansion: true, matExpansionPanelHeader:"Mode of Payment"});
    }

    mapBasedOnOldEarnings(earningsBreakup){
      let lmFare = 0, fmFare = 0, totalOrderEarning = 0, orderEarning = 0, nightFare = 0, totalSurge = 0, preCommEarnings = 0, tip = 0, platformCharges = 0, timeFare = 0, baseFare = 0, distanceFare = 0, waitingFare = 0, 
      cashCollected = 0, minimumFare = 0, cancelationIncentive = 0, pickupFare = 0, activeUpiId, cancelationIncentiveRemarks = "Eligible", tollFare=0, bidDelta, parkingCharges = 0;
      lmFare = earningsBreakup.lastMile && earningsBreakup.lastMile.lmFare || 0;
      fmFare = earningsBreakup.firstMile && earningsBreakup.firstMile.fmFare || 0;
      totalOrderEarning = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.totalOrderEarning || 0;
      orderEarning = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.orderEarning || 0;
      totalSurge = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.surgeBreakup && earningsBreakup.orderBreakup.surgeBreakup.totalSurge || 0;
      preCommEarnings = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.preCommissionOrderEarning || 0;
      platformCharges = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.platformCharges || 0;
      timeFare = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.timeFare || 0;
      baseFare = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.baseFare || 0;
      distanceFare = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.distanceFare || 0;
      waitingFare = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.waitingFare || 0;
      minimumFare = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.minimumFare || 0;
      cashCollected = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.cashCollected || 0;
      cancelationIncentive = earningsBreakup.cancelationFare && earningsBreakup.cancelationFare.amount || 0;
      nightFare = earningsBreakup.nightFare && earningsBreakup.nightFare.amount || 0;
      pickupFare = earningsBreakup.pickupFare && earningsBreakup.pickupFare || 0,
      tollFare = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.tollFare || 0;
      bidDelta = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.bidDelta;
      parkingCharges = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.parkingCharges || 0;
      activeUpiId = earningsBreakup.orderBreakup && earningsBreakup.orderBreakup.activeUpiId;
        return {
            lmFare, 
            fmFare,
            totalOrderEarning, orderEarning, 
            totalSurge, preCommEarnings, tip, 
            platformCharges,timeFare, 
            baseFare, distanceFare, waitingFare, 
            cashCollected, minimumFare,
            cancelationIncentive, 
            cancelationIncentiveRemarks,
            nightFare,
            pickupFare,
            tollFare,
            bidDelta,
            parkingCharges,
            activeUpiId
        }
    }

    mapAutoEarnings(earningsBreakup, data){
        let orderEarningsDropdown = [],
        distanceFareInfoLabel = earningsBreakup.orderBreakup.totalDistance ? earningsBreakup.orderBreakup.totalDistance : "",
        distanceFare = earningsBreakup.orderBreakup.distanceFare ? earningsBreakup.orderBreakup.distanceFare : 0,
        timeFareInfoLabel = earningsBreakup.orderBreakup.totalTime ? earningsBreakup.orderBreakup.totalTime : "",
        timeFare = earningsBreakup.orderBreakup.timeFare ? earningsBreakup.orderBreakup.timeFare : 0,
        bidDelta = earningsBreakup.orderBreakup.bidDelta,

        surgeFare = earningsBreakup.orderBreakup.surgeFare ? earningsBreakup.orderBreakup.surgeFare : 0,
        waitingFare = earningsBreakup.orderBreakup.waitTimeFare ? earningsBreakup.orderBreakup.waitTimeFare : '₹'+0,
        waitingTime = earningsBreakup.orderBreakup.waitTime ? earningsBreakup.orderBreakup.waitTime : '',
        longPickUpFare = earningsBreakup.orderBreakup.longPickupFare ? earningsBreakup.orderBreakup.longPickupFare : '₹'+0,
        longPickUpDistance = earningsBreakup.orderBreakup.longPickupDistance ? earningsBreakup.orderBreakup.longPickupDistance : '',
        minimumFare = earningsBreakup.orderBreakup.minimumFare ? earningsBreakup.orderBreakup.minimumFare : 0,
        pickupFare = earningsBreakup.orderBreakup.pickupFare ? earningsBreakup.orderBreakup.pickupFare : 0,

        orderFare = earningsBreakup.orderBreakup.captainEarnings ? earningsBreakup.orderBreakup.captainEarnings : 0,
        nightFare = earningsBreakup.orderBreakup.nightFare ? earningsBreakup.orderBreakup.nightFare : 0,

        gst = earningsBreakup.orderBreakup.gst ? earningsBreakup.orderBreakup.gst : 0,
        tds = earningsBreakup.orderBreakup.tds ? earningsBreakup.orderBreakup.tds : 0,
        customerBill = earningsBreakup.orderBreakup.customerBill ? earningsBreakup.orderBreakup.customerBill : 0,
        prevDue = earningsBreakup.orderBreakup.prevDue ? earningsBreakup.orderBreakup.prevDue : 0,
        totalCaptainEarnings = earningsBreakup.orderBreakup.totalCaptainEarnings ? earningsBreakup.orderBreakup.totalCaptainEarnings : 0,
        tip = earningsBreakup.orderBreakup.tip ? earningsBreakup.orderBreakup.tip : '₹'+0,
        adjustments = earningsBreakup.orderBreakup.adjustments ? earningsBreakup.orderBreakup.adjustments : '₹'+ 0,
        penalty = earningsBreakup.orderBreakup.penalty ? earningsBreakup.orderBreakup.penalty : '₹'+ 0,
        paymentMode = earningsBreakup.orderBreakup.paymentMode ? '('+earningsBreakup.orderBreakup.paymentMode+')' : '',
        paidToRapido = earningsBreakup.orderBreakup.paidToRapido ? earningsBreakup.orderBreakup.paidToRapido : 0,
        tollFare = earningsBreakup.orderBreakup.tollFare ? earningsBreakup.orderBreakup.tollFare :0,
        activeUpiId = earningsBreakup.orderBreakup.activeUpiId ? earningsBreakup.orderBreakup.activeUpiId:'';
        if(earningsBreakup.orderBreakup.cancellationFare >= 0){
          data.push({headerClass: 'mat-expansion-color' , header: 'Cancellation Fare ', value: '₹' + earningsBreakup.orderBreakup.cancellationFare });
        }else{
          if(minimumFare){
            orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Minimum Fare ', value: '₹' + minimumFare });
          }else{
            orderEarningsDropdown.push({headerClass: 'mat-expansion-color', header: 'Distance Fare ' + distanceFareInfoLabel, value: '₹' + distanceFare });
            orderEarningsDropdown.push({headerClass: 'mat-expansion-color', header: 'Time Fare ' + timeFareInfoLabel, value:'₹' +  timeFare });
          }

          longPickUpDistance && orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Long Pickup Fare ' + longPickUpDistance, value: longPickUpFare });
          pickupFare && orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Pickup Fare', value: '+₹' + pickupFare });
          surgeFare && orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Surge Fare ', value: '+₹' + surgeFare });
          waitingFare && orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Wait Time Charges ' + waitingTime, value: waitingFare });

          nightFare && orderEarningsDropdown.push({headerClass: 'mat-expansion-color' , header: 'Night Fare ', value: '+₹' + nightFare })

          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Total Earnings ', value: '₹'+totalCaptainEarnings});
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Customer Bill'+ paymentMode, value: '₹'+customerBill});
          data.push({headerClass: 'header-card-color', matExpansionPanelContent:orderEarningsDropdown, value:'₹' + orderFare, isExpansion: true, matExpansionPanelHeader:"Order Earnings"});
          if(bidDelta != undefined){
            data.push({headerClass: 'header-card-color', isExpansion:false, header:'Bid Delta', value : '₹'+bidDelta});
          }
          if(paidToRapido > 0){
            data.push({headerClass: 'header-card-color', isExpansion:false, header:'Paid To Rapido', value : '₹'+paidToRapido});
          }else{
            const paidByRapido = paidToRapido < 0 ? `+₹${Math.abs(paidToRapido)}` : '₹0';
            data.push({headerClass: 'header-card-color', isExpansion:false, header:'Paid by Rapido', value : paidByRapido});
          }
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'GST (Paid to government)', value : '₹'+gst});
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Tip', value : tip});
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Toll Fare', value : '₹'+tollFare});
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Adjustments', value: adjustments });
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Penalty', value: penalty });
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'TDS penalty', value : tds});
          data.push({headerClass: 'header-card-color', isExpansion:false, header:'Previous due balance', value : '₹'+prevDue});
          activeUpiId && data.push({headerClass: 'header-card-color', isExpansion:false, header:'activeUpiId', value : activeUpiId});
        }
    }
}