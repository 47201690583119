<div class="heading">
    <span (click)="redirectToCaptainDetails()" style="cursor: pointer;">
        Captain details
    </span>
    <fa-icon [icon]="breadcrumb"></fa-icon>
    <span style="font-weight: bold;">
        Fraud orders: {{ fraudType }}
    </span>
</div>
<app-table-scrollable *ngIf="orderTable && orderTable.state === 'loaded'" 
    [table]="orderTable" 
    [filterNotRequired]="true"
    (paginationChange)="onPaginationChange($event)"
>
</app-table-scrollable>
