/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./order-search.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../shared/single-select-dropdown/single-select-dropdown.component.ngfactory";
import * as i5 from "../shared/single-select-dropdown/single-select-dropdown.component";
import * as i6 from "@angular/router";
import * as i7 from "./order-search.component";
import * as i8 from "../toaster.service";
import * as i9 from "../order-service.service";
import * as i10 from "../loader.service";
var styles_OrderSearchComponent = [i0.styles];
var RenderType_OrderSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrderSearchComponent, data: {} });
export { RenderType_OrderSearchComponent as RenderType_OrderSearchComponent };
export function View_OrderSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "row side-padding-15 is-flex search-container  "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "div", [["class", "form-group has-feedback is-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "form-group has-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "input", [["class", "form-control width-250"], ["id", "search"], ["onblur", "this.placeholder = 'Search by Customer number'"], ["onfocus", "this.placeholder = ''"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup.enter"], [null, "focusout"], [null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchKeyword = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        var pd_5 = (_co.search() !== false);
        ad = (pd_5 && ad);
    } if (("focusout" === en)) {
        var pd_6 = ((_co.focus = false) !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        var pd_7 = ((_co.focus = true) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "padding-left-30": 0 }), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(14, { "glyphicon glyphicon-search form-control-feedback": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 1, "label", [["class", "search-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.search() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["style", "\n    width: 173px;\n    margin-top: -20px;\n  "]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "single-select-dropdown", [], null, [[null, "selectedDropDown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedDropDown" === en)) {
        var pd_0 = (_co.filterTypeSelect.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SingleSelectDropdownComponent_0, i4.RenderType_SingleSelectDropdownComponent)), i1.ɵdid(19, 114688, null, 0, i5.SingleSelectDropdownComponent, [], { singleDropdownData: [0, "singleDropdownData"], selectedValues: [1, "selectedValues"] }, { selectedDropDown: "selectedDropDown" }), (_l()(), i1.ɵeld(20, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(21, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "form-control width-250"; var currVal_9 = _ck(_v, 6, 0, ((_co.focus === false) && !_co.enteredValue)); _ck(_v, 5, 0, currVal_8, currVal_9); var currVal_10 = _co.searchKeyword; _ck(_v, 9, 0, currVal_10); var currVal_11 = _ck(_v, 14, 0, ((_co.focus === false) && !_co.enteredValue)); _ck(_v, 13, 0, currVal_11); var currVal_12 = _co.filterTypeSelect; var currVal_13 = _co.SEARCH_BY.ORDER_ID; _ck(_v, 19, 0, currVal_12, currVal_13); _ck(_v, 21, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_OrderSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-order-search", [], null, null, null, View_OrderSearchComponent_0, RenderType_OrderSearchComponent)), i1.ɵdid(1, 114688, null, 0, i7.OrderSearchComponent, [i6.Router, i8.ToasterService, i9.OrderService, i10.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrderSearchComponentNgFactory = i1.ɵccf("app-order-search", i7.OrderSearchComponent, View_OrderSearchComponent_Host_0, {}, {}, []);
export { OrderSearchComponentNgFactory as OrderSearchComponentNgFactory };
