import { Component, OnInit, Input } from '@angular/core';
import { ThumbnailCard } from '../types';
import { ThumbnailCardTypes } from '../types/thumnail-card.types';

@Component({
  selector: 'app-thumbnail-card',
  templateUrl: './thumbnail-card.component.html',
  styleUrls: ['./thumbnail-card.component.css']
})
export class ThumbnailCardComponent implements OnInit {
  @Input() card: ThumbnailCard;
  thumnailCardTypes = ThumbnailCardTypes;
  constructor() { }
  isMinimalType() {
    return this.card.type === ThumbnailCardTypes.MINIMAL_SQUARE ||
    this.card.type === ThumbnailCardTypes.MINIMAL_THUMBNAIL;
  }

  get thumbnailPartStyle() {
    // console.log(this.card.config)
    // return this.card.config.thumbnailImageStyle;
    if (this.isMinimalType()) {
      const style = Object.assign(this.card.config.thumbnailImageStyle || {}, this.card.config.thumbnailPartStyle || {})
      return style;
    }
    return this.card.config.thumbnailPartStyle;
    // if (this.card.config.thumnailPart) {
    //   const style = {
    //     width: `${100 * (this.card.config.thumnailPart / 12)}%`,
    //   };
    //   return style;
    // }
    // if (this.card.config.thumbnailPartStyle) {
    //   return this.card
    // }
  }

  ngOnInit() {
    // console.log(this.card.config.thumbnailImageStyle)
  }

}
