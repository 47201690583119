import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RiderService } from './mapped-captains.service';

@Component({
  selector: 'app-mapped-captains',
  templateUrl: './mapped-captains.component.html',
  styleUrls: ['./mapped-captains.component.css']
})
export class MappedCaptainsComponent implements OnInit, OnChanges {

  public mappedRiders = [];
  mappedRiderId: any;
  @Input() mapRiderDetails: any;
  dataLoaded = false;
  dataFetched = false;

  constructor( private riderService: RiderService) { }

  ngOnInit() {
   }

   ngOnChanges(changes: SimpleChanges) {

    if (changes && changes.mapRiderDetails && changes.mapRiderDetails.currentValue) {
      if (!changes.mapRiderDetails.currentValue.dataFetched) {
        this.emptyCard();
      } else {
        this.constructCard();
      }
    }
   }

   emptyCard() {
     this.dataLoaded = true;
     this.dataFetched = false;
  }

  constructCard() {
    this.mappedRiderId = this.mapRiderDetails.mappedRiderId;
    let riderExists = false, riderIndex;
       this.riderService.fetchRiderDetails(this.mappedRiderId).subscribe(response => {
         if (response['data'] && response['data'].rider) {
           this.mappedRiders = response['data'].rider;
           for (const index in this.mappedRiders) {
             if (this.mappedRiders[index] && this.mappedRiders[index].lastName) {
               this.mappedRiders[index].name = this.mappedRiders[index].firstName + ' ' + this.mappedRiders[index].lastName;
             } else {
              this.mappedRiders[index].name = this.mappedRiders[index].firstName;
             }
           }
           this.dataLoaded = true;
           if (this.mappedRiders.length > 0) {
             this.dataFetched = true;
           }
           for (const rider in this.mappedRiders) {
             if (this.mappedRiders[rider] && this.mappedRiders[rider]['_id']) {
              riderExists = true;
              while (riderExists) {
                riderIndex = this.mappedRiderId.indexOf(this.mappedRiders[rider]['_id']);
                if (riderIndex >= 0) {
                  this.mappedRiderId[riderIndex] = this.mappedRiders[rider];
                } else {
                  riderExists = false;
                }
              }
             }
           }
           for (const rider in this.mappedRiderId) {
             if (this.mappedRiderId[rider] && typeof(this.mappedRiderId[rider]) !== 'object') {
               this.mappedRiderId[rider] = {'_id' : this.mappedRiderId[rider]};
             }
           }
         }
       });
  }
}
