import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable()
export class CaptainRedeemService {
    constructor(public httpClient: HttpClient) { }

    async getWalletCaptainPassbookFromWallet(id: string, requestBody: Object, pageSize: number, next: string, prev: string ): Promise<any> {
        const url = `${environment.server}/api/riderwallet/captainPassbook`;
        const result = await this.httpClient.get(url, {
            params: { riderId:id.toString(), requestBody:JSON.stringify(requestBody), pageSize: pageSize.toString(), next: next.toString(), prev:prev.toString() }
        }).toPromise();
        return result;
    }

    async getAutopayStatusDetails(captainId: string, serviceDetailIds: Array<string>, latitude: number, longitude:number) {
        const url = `${environment.server}/api/autopay/statusInfo`;
        const result = await this.httpClient.get(url, {
            params: { riderId:captainId.toString(), serviceDetailIds:JSON.stringify(serviceDetailIds), lat:latitude.toString(), lng:longitude.toString() }
        }).toPromise();
        return result;
      }
}
