/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-account.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../card-table/card-table.component.ngfactory";
import * as i3 from "../../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./customer-account.component";
var styles_CustomerAccountComponent = [i0.styles];
var RenderType_CustomerAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerAccountComponent, data: {} });
export { RenderType_CustomerAccountComponent as RenderType_CustomerAccountComponent };
export function View_CustomerAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, null, null, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustomerAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-account", [], null, null, null, View_CustomerAccountComponent_0, RenderType_CustomerAccountComponent)), i1.ɵdid(1, 638976, null, 0, i5.CustomerAccountComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerAccountComponentNgFactory = i1.ɵccf("app-customer-account", i5.CustomerAccountComponent, View_CustomerAccountComponent_Host_0, { data: "data" }, {}, []);
export { CustomerAccountComponentNgFactory as CustomerAccountComponentNgFactory };
