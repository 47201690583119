export class Ping {
  constructor(
    public found?: boolean,
    public captainId?: string,
    public ltr?: number,
    public totalPings?: number,
    public totalAcceptedPings?: number) { }
}

export class PingResponse {
  public response?: Ping[];
}

export class PingData {
  constructor(
    public status?: number,
    public data?: PingResponse) { }
}