export var RoundLevel;
(function (RoundLevel) {
    RoundLevel[RoundLevel["ONE"] = 0] = "ONE";
    RoundLevel[RoundLevel["TWO"] = 1] = "TWO";
})(RoundLevel || (RoundLevel = {}));
export var defaultCardConfig = {
    imageRound: false,
    roundLevel: RoundLevel.ONE,
};
export var ThumbnailCardTypes;
(function (ThumbnailCardTypes) {
    ThumbnailCardTypes[ThumbnailCardTypes["SQUARE_IMAGE"] = 0] = "SQUARE_IMAGE";
    ThumbnailCardTypes[ThumbnailCardTypes["ROUND_IMAGE"] = 1] = "ROUND_IMAGE";
    ThumbnailCardTypes[ThumbnailCardTypes["MINIMAL_THUMBNAIL"] = 2] = "MINIMAL_THUMBNAIL";
    ThumbnailCardTypes[ThumbnailCardTypes["MINIMAL_SQUARE"] = 3] = "MINIMAL_SQUARE";
})(ThumbnailCardTypes || (ThumbnailCardTypes = {}));
var ThumbnailCardConfig = /** @class */ (function () {
    function ThumbnailCardConfig(config) {
        if (config === void 0) { config = {}; }
        if (config.imageRound) {
            // console.log(config);
        }
        Object.assign(this, defaultCardConfig, config);
    }
    return ThumbnailCardConfig;
}());
export { ThumbnailCardConfig };
var ThumbnailCard = /** @class */ (function () {
    function ThumbnailCard(config) {
        this.type = ThumbnailCardTypes.SQUARE_IMAGE;
        this.style = {};
        Object.assign(this, config);
        this.config = new ThumbnailCardConfig(this.config);
    }
    Object.defineProperty(ThumbnailCard.prototype, "imageStyle", {
        get: function () {
            return "url(" + this.imageUrl + ")";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailCard.prototype, "thumbnailPartClass", {
        get: function () {
            this.config.thumnailPart = this.config.thumnailPart || 2;
            if (this.config.thumnailPart) {
                return "col-md-" + this.config.thumnailPart + " make-item-center";
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailCard.prototype, "infoPartClass", {
        get: function () {
            this.config.infoPart = this.config.infoPart || 10;
            if (this.config.infoPart) {
                return "col-md-" + this.config.infoPart;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThumbnailCard.prototype, "avatarClass", {
        get: function () {
            var baseClass = "avatar-md mr-3 svgIcon";
            switch (this.config.roundLevel) {
                case RoundLevel.ONE: {
                    // baseClass += ` level-1-round`;
                    break;
                }
                case RoundLevel.TWO: {
                    baseClass += " level-2-round";
                    break;
                }
            }
            if (this.type === ThumbnailCardTypes.MINIMAL_SQUARE) {
                baseClass += " remove-border-radius";
            }
            return baseClass;
        },
        enumerable: true,
        configurable: true
    });
    return ThumbnailCard;
}());
export { ThumbnailCard };
