<div *ngIf="!dataLoaded" style="height: 125px">
    <div class="row">
        <div class="col-md-6">
            <bullet-list-content-loader></bullet-list-content-loader>
        </div>
        <div class="col-md-6">
            <bullet-list-content-loader></bullet-list-content-loader>
        </div>
    </div>
</div>

<div class="mapped-captains">
    <div class="order-title margin-10-24">Mapped captain</div>
    <div class="line margin-top-10"></div>
    <div *ngIf="dataLoaded && dataFetched" class="height-80 overflow-y"> <!--class="mapped-captains-number"-->
        <div class="row">
            <div class="mapped-rider-number" *ngFor="let rider of mappedRiderId; let i = index">
                <span tooltipPosition="bottom" pTooltip="Name : {{rider.name || 'N/A'}} {{'\n'}} mobile : {{rider.mobile || 'N/A'}}">
                    {{i + 1}}. {{rider._id}}
                </span>
              </div>           
        </div>
    </div>

    <div class="min-height-98 center-items is-flex" *ngIf="dataLoaded && !dataFetched"> 
            No data found ¯\_(ツ)_/¯
    </div>
</div>