/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./merchant-order-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../table-scrollable/table-scrollable.component.ngfactory";
import * as i3 from "../../table-scrollable/table-scrollable.component";
import * as i4 from "../../filter.service";
import * as i5 from "@angular/common";
import * as i6 from "./merchant-order-details.component";
import * as i7 from "../../order-service.service";
import * as i8 from "../../entity.service";
import * as i9 from "../../customer-details/customer-order-details/customer-order-details.service";
import * as i10 from "../../data.service";
import * as i11 from "@angular/router";
import * as i12 from "../merchant.service";
var styles_MerchantOrderDetailsComponent = [i0.styles];
var RenderType_MerchantOrderDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MerchantOrderDetailsComponent, data: {} });
export { RenderType_MerchantOrderDetailsComponent as RenderType_MerchantOrderDetailsComponent };
function View_MerchantOrderDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-scrollable", [], null, [[null, "headerToggled"], [null, "paginationChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("headerToggled" === en)) {
        var pd_0 = (_co.changeInHeader($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChange" === en)) {
        var pd_1 = (_co.onPaginationChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TableScrollableComponent_0, i2.RenderType_TableScrollableComponent)), i1.ɵdid(1, 114688, null, 0, i3.TableScrollableComponent, [i4.FilterService], { table: [0, "table"] }, { paginationChange: "paginationChange", headerToggled: "headerToggled" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orderTable; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantOrderDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MerchantOrderDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.orderTable.state === "loaded"); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MerchantOrderDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-merchant-order-details", [], null, null, null, View_MerchantOrderDetailsComponent_0, RenderType_MerchantOrderDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i6.MerchantOrderDetailsComponent, [i7.OrderService, i4.FilterService, i8.EntityService, i9.CustomerOrderDetailsService, i10.DataService, i11.Router, i12.MerchantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MerchantOrderDetailsComponentNgFactory = i1.ɵccf("app-merchant-order-details", i6.MerchantOrderDetailsComponent, View_MerchantOrderDetailsComponent_Host_0, { merchantId: "merchantId", merchantCity: "merchantCity" }, { toggleHeaders: "toggleHeaders" }, []);
export { MerchantOrderDetailsComponentNgFactory as MerchantOrderDetailsComponentNgFactory };
