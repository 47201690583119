import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { NgxLoadingModule } from 'ngx-loading'

import { AppComponent } from './app.component'
import { SidenavContentComponent } from './sidenav-content/sidenav-content.component'
import * as _ from 'lodash'
import { DemoMaterialModule } from './material-module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SidenavListComponent } from './sidenav-list/sidenav-list.component'
// import { FilterSidenavComponent } from './filter-sidenav/filter-sidenav.component';
import { SharedModule } from './shared/shared.module'
import { CaptainDetailsModule } from './captain-details/captain-details.module'
import { RouterModule, Routes } from '@angular/router'
import { CaptainDetailsComponent } from './captain-details/captain-details.component'
import { CustomerDetailsComponent } from './customer-details/customer-details.component'
import { CustomerGeneralInfoComponent } from './customer-details/customer-general-info/customer-general-info.component'
import { CustomerStatsComponent } from './customer-details/customer-stats/customer-stats.component'
import { CustomerSearchComponent } from './customer-details/customer-search/customer-search.component'
import { CustomerAccountComponent } from './customer-details/customer-account/customer-account.component'
import { CustomerOrderDetailsComponent } from './customer-details/customer-order-details/customer-order-details.component'
import { CustomerTransactionDetailsComponent } from './customer-details/customer-transaction-details/customer-transaction-details.component'
import { CustomerWalletComponent } from './customer-details/customer-wallet/customer-wallet.component'
import { CustomerActivitiesComponent } from './customer-details/customer-activities/customer-activities.component'
import { CardLayoutComponent } from './card-layout/card-layout.component'
import { CardTableComponent } from './card-table/card-table.component'
import { CardStatsComponent } from './card-stats/card-stats.component'
import { TableScrollableComponent } from './table-scrollable/table-scrollable.component'
import { TimelineViewComponent } from './timeline-view/timeline-view.component'
import { FraudViewComponent } from './fraud-component/fraud-view.component'
import { MyHttpClient } from './http-client.service'
import { EntityService } from './entity.service'
import { CaptainService } from './captain.service'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { CaptainSearchComponent } from './captain-details/captain-search/captain-search.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ContentLoaderModule } from '@netbasal/ngx-content-loader'
import { ToastrModule } from 'ngx-toastr'
import { Ng5SliderModule } from 'ng5-slider'
import { PrimengModule } from './primeng.module'
import { SigninComponent } from './signin/signin.component'
import { AuthGuardService } from './auth-guard.service'
import { SigninService } from './signin/signin.service'
import { DashboardComponent } from './dashboard/dashboard.component'
import { AuthInterceptor } from './auth.interceptor'
import { DialogComponent } from './dialog/dialog.component'
import { FraudDialogComponent } from './fraud-dialog/fraud-dialog.component'
import { PrevDueDialogComponent } from './prevdue-dialog/prevdue-dialog.component'
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component'
import { OrderDetailsComponent } from './order-details/order-details.component'
import { CustOrderDetailsComponent } from './cust-order-details/cust-order-details.component'
import { CaptOrderDetailsComponent } from './capt-order-details/capt-order-details.component'
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component'
import { CaptainInvoiceDetailsComponent } from './captain-invoice-details/captain-invoice-details.component'
import { DistTimeDetailsComponent } from './dist-time-details/dist-time-details.component'
import { FareEstimateLogComponent } from './fare-estimate-log/fare-estimate-log.component'
import { OrderLogComponent } from './order-log/order-log.component'
import { MappedCaptainsComponent } from './mapped-captains/mapped-captains.component'
import { RideTrackerMapComponent } from './ride-tracker-map/ride-tracker-map.component'
import { CustAdjustmentComponent } from './cust-adjustment/cust-adjustment.component'
import { CaptAdjustmentComponent } from './capt-adjustment/capt-adjustment.component'
import { AdjustmentHistoryComponent } from './adjustment-history/adjustment-history.component'
import { CustActivityHistoryComponent } from './cust-activity-history/cust-activity-history.component'
import { RatingDetailsComponent } from './rating-details/rating-details.component'
import { OrderStatusComponent } from './order-status/order-status.component'
import { ExpandableTableComponent } from './expandable-table/expandable-table.component'
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { AdjustmentFormsComponent } from './adjustment-forms/adjustment-forms.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { LoaderComponent } from './loader/loader.component'
import { LoaderService } from './loader.service'
import { FullViewMapComponent } from './full-view-map/full-view-map.component'
import { CaptainFailedOrdersComponent } from './captain-details/captain-failed-orders/captain-failed-orders.component'
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { CaptainFraudOrdersComponent } from './captain-details/captain-fraud-orders/captain-fraud-orders.component'
import { C2cOrderDetailsComponent } from './c2c-order-details/c2c-order-details.component'
import { C2cSenderDetailsComponent } from './c2c-sender-details/c2c-sender-details.component'
import { C2cReceiverDetailsComponent } from './c2c-receiver-details/c2c-receiver-details.component'
import { CustomerInvoiceDetailsComponent } from './customer-invoice-details/customer-invoice-details.component'
import { CustomerInvoiceDetailsService } from './customer-invoice-details/customer-invoice-details.service'
import { PaymentSummaryComponent } from './customer-invoice-details/payment-summary/payment-summary.component'
import { BillOfSupplyComponent } from './customer-invoice-details/bill-of-supply/bill-of-supply.component'
import { TaxInvoiceComponent } from './customer-invoice-details/tax-invoice/tax-invoice.component'
import { MerchantProfileComponent } from './merchant-profile/merchant-profile.component'
import { MerchantSearchComponent } from './merchant-profile/merchant-search/merchant-search.component'
import { MerchantDetailsComponent } from './merchant-profile/merchant-details/merchant-details.component'
import { MerchantGeneralInfoComponent } from './merchant-profile/merchant-general-info/merchant-general-info.component'
import { MerchantAccountComponent } from './merchant-profile/merchant-account/merchant-account.component'
import { MerchantWalletComponent } from './merchant-profile/merchant-wallet/merchant-wallet.component'
import { MerchantStatComponent } from './merchant-profile/merchant-stat/merchant-stat.component'
import { MerchantOrderDetailsComponent } from './merchant-profile/merchant-order-details/merchant-order-details.component'
import { MerchantTransactionDetailsComponent } from './merchant-profile/merchant-transaction-details/merchant-transaction-details.component'
import { CustomerAdjustmentComponent } from './customer-details/customer-adjustment/customer-adjustment.component'
import { OrderSearchComponent } from './order-search/order-search.component'
import { CaptainSuspensionDailogComponent } from './captain-suspension/captain-suspension-dailog/captain-suspension-dailog.component'
import { CustSubscriptionDetailsComponent } from './customer-details/cust-subscription-details/cust-subscription-details.component'
import { OrderActivityLogComponent } from './order-activity-log/order-activity-log.component'
import { SvoService } from './svo.service'
import { EventService } from './event.service'
import { CustomerTicketsComponent } from './customer-details/customer-tickets/customer-tickets.component'
import { CustomerComplaintsComponent } from './customer-details/customer-complaints/customer-complaints.component'
import { RevokeFailedOrderComponent } from './captain-details/captain-failed-orders/revoke-failed-order/revoke-failed-order.component'
import { AppConfigService } from './appConfig.service'
import { RevokeSuspensionDialogComponent } from './captain-suspension/revoke-suspension/revoke-suspension-dialog.component'
import { PaymentStatusComponent } from './payment-status/payment-status.component'
import { ManagerProfileComponent } from './manager-profile/manager-profile.component'
import { ManagerSearchComponent } from './manager-profile/manager-search/manager-search.component'
import { ManagerService } from './manager-profile/manager-profile.service'
import { DecimalPipe } from '@angular/common'
import { ManagerGeneralInfoComponent } from './manager-profile/manager-general-info/manager-general-info.component'
import { ManagerGeneralInfoService } from './manager-profile/manager-general-info/manager-general-info.service'
import { ManagerRapidoAccountService } from './manager-profile/manager-rapido-account/manager-rapido-account.service'
import { ManagerRapidoAccountComponent } from './manager-profile/manager-rapido-account/manager-rapido-account.component'
import { DemandAreasComponent } from './demand-areas/demand-areas.component'
import {KycService} from './kyc.service';
import { TollInfoComponent } from './toll-info/toll-info.component'

const routes: Routes = [
  {
    path: 'login',
    component: SigninComponent,
  },
  {
    path: '',
    component: SidenavContentComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'captain',
        // canActivate: [AuthGuardService],
        component: CaptainSearchComponent,
        children: [
          {
            path: 'details',
            component: CaptainDetailsComponent,
          },
          {
            path: ':id',
            component: CaptainDetailsComponent,
          },
        ],
      },
      {
        path: 'failedOrders/:mobile',
        component: CaptainFailedOrdersComponent,
      },
      {
        path: 'fraudOrders/:mobile',
        component: CaptainFraudOrdersComponent,
      },
      {
        path: 'customer',
        // canActivate: [AuthGuardService],
        component: CustomerSearchComponent,
        children: [
          {
            path: 'details',
            component: CustomerDetailsComponent,
          },
          {
            path: ':mobile',
            component: CustomerDetailsComponent,
          },
        ],
      },
      {
        path: 'merchant',
        component: MerchantSearchComponent,
        children: [
          {
            path: ':mobile',
            component: MerchantDetailsComponent,
          },
        ],
      },
      {
        path: 'order',
        component: OrderSearchComponent,
        children: [
          {
            path: ':id',
            component: OrderDetailsComponent,
          },
        ],
      },
      {
        path: 'manager',
        component: ManagerSearchComponent,
        children: [
          {
            path: ':mobile',
            component: ManagerProfileComponent,
          },
        ],
      },
      {
        path: 'demand-areas',
        component: DemandAreasComponent
      },
      {
        path: 'invoice-details/:orderId/:orderStatus/:customerId',
        component: CustomerInvoiceDetailsComponent,
      },
    ],
  },
]

@NgModule({
  declarations: [
    DashboardComponent,
    AppComponent,
    SidenavContentComponent,
    SidenavListComponent,
    // FilterSidenavComponent,
    CustomerDetailsComponent,
    CustomerGeneralInfoComponent,
    CustomerStatsComponent,
    CustomerSearchComponent,
    CustomerAccountComponent,
    CustomerOrderDetailsComponent,
    CustomerTransactionDetailsComponent,
    CustomerWalletComponent,
    CustomerActivitiesComponent,
    CardLayoutComponent,
    CardTableComponent,
    // CardStatsComponent,
    // TableScrollableComponent,
    TimelineViewComponent,
    FraudViewComponent,
    SigninComponent,
    DialogComponent,
    CustomTooltipComponent,
    OrderDetailsComponent,
    CustOrderDetailsComponent,
    CaptOrderDetailsComponent,
    InvoiceDetailsComponent,
    CaptainInvoiceDetailsComponent,
    DistTimeDetailsComponent,
    FareEstimateLogComponent,
    OrderLogComponent,
    MappedCaptainsComponent,
    RideTrackerMapComponent,
    CustAdjustmentComponent,
    CaptAdjustmentComponent,
    AdjustmentHistoryComponent,
    CustActivityHistoryComponent,
    RatingDetailsComponent,
    ExpandableTableComponent,
    OrderStatusComponent,
    ConfirmDialogComponent,
    AdjustmentFormsComponent,
    LoaderComponent,
    FullViewMapComponent,
    CaptainFailedOrdersComponent,
    CustomDialogComponent,
    CaptainFraudOrdersComponent,
    C2cOrderDetailsComponent,
    C2cReceiverDetailsComponent,
    C2cSenderDetailsComponent,
    CustomerInvoiceDetailsComponent,
    PaymentSummaryComponent,
    BillOfSupplyComponent,
    TaxInvoiceComponent,
    MerchantProfileComponent,
    MerchantSearchComponent,
    MerchantDetailsComponent,
    MerchantGeneralInfoComponent,
    MerchantAccountComponent,
    MerchantWalletComponent,
    MerchantStatComponent,
    MerchantOrderDetailsComponent,
    MerchantTransactionDetailsComponent,
    FraudDialogComponent,
    CustomerAdjustmentComponent,
    PrevDueDialogComponent,
    OrderSearchComponent,
    CaptainSuspensionDailogComponent,
    RevokeSuspensionDialogComponent,
    CustSubscriptionDetailsComponent,
    OrderActivityLogComponent,
    CustomerTicketsComponent,
    CustomerComplaintsComponent,
    PaymentStatusComponent,
    ManagerProfileComponent,
    ManagerSearchComponent,
    ManagerGeneralInfoComponent,
    ManagerRapidoAccountComponent,
    DemandAreasComponent,
    TollInfoComponent
  ],
  imports: [
    BrowserModule,
    NgxLoadingModule.forRoot({}),
    DemoMaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CaptainDetailsModule,
    DemoMaterialModule,
    RouterModule.forRoot(routes),
    FontAwesomeModule,
    ContentLoaderModule,
    ToastrModule.forRoot(),
    Ng5SliderModule,
    PrimengModule,
    NgSelectModule,
    NgbModule,
    Ng2SearchPipeModule,
  ],
  entryComponents: [
    DialogComponent,
    ConfirmDialogComponent,
    CustomDialogComponent,
    RevokeFailedOrderComponent,
    FraudDialogComponent,
    PrevDueDialogComponent,
    CaptainSuspensionDailogComponent,
    RevokeSuspensionDialogComponent,
    OrderActivityLogComponent,
    PaymentStatusComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MyHttpClient,
    CaptainService,
    EntityService,
    EventService,
    SigninService,
    LoaderService,
    SvoService,
    AppConfigService,
    CustomerInvoiceDetailsService,
    ManagerService,
    ManagerGeneralInfoService,
    ManagerRapidoAccountService,
    DecimalPipe,
    KycService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
