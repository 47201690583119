<div class="row">
  <div class="col-md-6">
    <single-select-dropdown class="selectDropdownHeight" (selectedDropDown)="serviceFilter.onChange($event)"
      [singleDropdownData]="serviceFilter" [selectedValues]="selectedService">
    </single-select-dropdown>
  </div>
  <div class="col-md-6">
    <single-select-dropdown class="selectDropdownHeight" (selectedDropDown)="pricingStrategyFilter.onChange($event)"
      [singleDropdownData]="pricingStrategyFilter" [selectedValues]="selectedStrategy">
    </single-select-dropdown>
  </div>
</div>

<mat-accordion *ngIf = "selectedRateCard" displayMode="flat" style="border-radius: 0px!important;">

  <mat-expansion-panel class="mat-elevation-z0 background-green" disabled="true">
    <mat-expansion-panel-header>
      <mat-panel-title><b>{{selectedService}} Rate Card</b></mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</mat-accordion>
<div *ngIf = "selectedRateCard" class="horizontal-rule"></div>
<div *ngIf = "selectedRateCard" class="scrollable-y rateCardHeight">
  <mat-accordion displayMode="flat" *ngFor = "let info of selectedRateCard.fareSplit">
    <mat-expansion-panel *ngIf="info.isExpandable" class="mat-elevation-z0" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title><b>{{info.text}}</b></mat-panel-title>
      </mat-expansion-panel-header>
      <div class="horizontal-rule"></div>
      <ng-container *ngFor = "let subInfo of info.options">
          <mat-expansion-panel *ngIf = "subInfo.isExpandable" class="mat-elevation-z0" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title><p>{{subInfo.text}}<br><i class = "subText">{{subInfo.subText}}</i></p></mat-panel-title>
              <mat-panel-description style="text-align: right;"><p>{{subInfo.value}}<br><i class = "subText">{{subInfo.subValue}}</i></p></mat-panel-description>
            </mat-expansion-panel-header>
            <div class="horizontal-rule"></div>
          <mat-expansion-panel class="mat-elevation-z0" disabled = "true" *ngFor = "let data of subInfo.options">
            <mat-expansion-panel-header>
              <mat-panel-title><p>{{data.text}}<br><i class = "subText">{{data.subText}}</i></p></mat-panel-title>
              <mat-panel-description style="text-align: right;"><p>{{data.value}}<br><i class = "subText">{{data.subValue}}</i></p></mat-panel-description>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
          <div class="horizontal-rule"></div>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf = "!subInfo.isExpandable" class="mat-elevation-z0" disabled = "true">
            <mat-expansion-panel-header>
              <mat-panel-title><p>{{subInfo.text}}<br><i class = "subText">{{subInfo.subText}}</i></p></mat-panel-title>
              <mat-panel-description style="text-align: right;"><p>{{subInfo.value}}<br><i class = "subText">{{subInfo.subValue}}</i></p></mat-panel-description>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
      </ng-container>
    </mat-expansion-panel>
    <div class="horizontal-rule"></div>
    
  </mat-accordion>
</div>