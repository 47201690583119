import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {REVOKE, SERVICE_GROUPS} from 'src/app/shared/constants';
import { CaptainService } from 'src/app/captain.service';
import { AppConfigService } from 'src/app/appConfig.service';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {Toaster, ToasterType} from '../../shared/types';

@Component({
  selector: 'app-revoke-suspension-dialog',
  templateUrl: './revoke-suspension-dialog.component.html',
  styleUrls: ['./revoke-suspension-dialog.component.css']
})
export class RevokeSuspensionDialogComponent implements OnInit {

    public serviceGroups = [];
    public suspensionReasons: [];
    public suspensionP0RevokeReasons: [];
    suspensionConfig : any;
    showReasonAlert: boolean;
    selectedServiceGroup: string;
    suspensionReason: string;
    suspendHours: string;

    suspensionPriority: object;
    suspendedReason: object;
    filteredSuspensionReasons: any;

    hasP0SuspensionRevocationPermission: false;

    suspendedUntil : string;
    constructor(
        public dialog: MatDialog,
        public appConfigService: AppConfigService,
        public captainService: CaptainService,
        public dialogRef: MatDialogRef<RevokeSuspensionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    async ngOnInit() {
        this.suspensionConfig = await this.appConfigService.getSuspensionConfig();
        this.suspensionReasons = JSON.parse(this.suspensionConfig.revokeSuspensionReasons) as [];
        this.suspensionP0RevokeReasons = JSON.parse(this.suspensionConfig.revokeP0SuspensionReasons) as [];
        this.serviceGroups = this.data.serviceGroups;
        this.suspensionPriority = this.data.suspensionPriority;
        this.suspendedReason = this.data.suspendedReason;
        this.suspendedUntil = this.data.suspendedUntil;
        this.hasP0SuspensionRevocationPermission = this.data.hasP0SuspensionRevocationPermission;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onProceed = () => {
        const { selectedServiceGroup, suspensionReason } = this;
        if (!selectedServiceGroup || !suspensionReason) {
            this.showReasonAlert = true;
        } else if (selectedServiceGroup && suspensionReason) {
            this.showReasonAlert = false;
            const paramObj = {
                serviceGroup: selectedServiceGroup.toLowerCase(),
                suspensionReason
            };
            this.dialogRef.close(paramObj);
        }

    }
    onServiceChange() {

        if (this.suspensionPriority[this.selectedServiceGroup] == 0 ) {
            this.filteredSuspensionReasons = this.suspensionP0RevokeReasons;
        } else {
            this.filteredSuspensionReasons = this.suspensionReasons;
        }

    }

    hasSuspensionRevokeAccess() {

        if (this.suspensionPriority[this.selectedServiceGroup] != 0 ) {
            return true;
        }

        return this.hasP0SuspensionRevocationPermission;
    }

    revokeSuspension() {
        if (!this.suspensionReason) {
            this.showReasonAlert = true;
            return;
        }
        this.showReasonAlert = false;
        const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '335px',
            data: { title: 'Are you sure you want to revoke the suspension?',
                type: 'revoke',
                buttonText: 'Yes',
                reason: this.suspensionReason
            }
        });

        confirmDialogRef.afterClosed().subscribe(confirmResult => {
            if (confirmResult) {
                this.onProceed();
            }
        });
    }
}
