<div class="order-timeline">
  <div class="row">
    <div class="col-md-3">
      <div class="order-title margin-10-12">Order timeline</div>
    </div>
    <div style="align-items: center; text-align: center;" class="col-md-3 margin-10-12">
      <span class="display-inline-block font-size-14 font-weight-600">Service Type :</span>
      <span class="font-size-14 font-weight-600" style="text-transform: capitalize; padding-left: 1%">
        {{serviceType}}
      </span>
    </div>
    <div style="align-items: center; text-align: center;" class="col-md-3 margin-10-12">
      <span class="display-inline-block font-size-14 font-weight-600">City :</span>
      <span class="font-size-14 font-weight-600" style="text-transform: capitalize; padding-left: 1%">
        {{city}}
      </span>
    </div>
    <div class="col-md-2 view" *ngIf="dataFetched">
      <span class="view-details" (click)="toggleStatus()">{{viewDetails}}</span>
      <i class="material-icons arrow-up" (click)="toggleStatus()" *ngIf="viewMore">arrow_drop_up</i>
      <i class="material-icons arrow-up" (click)="toggleStatus()" *ngIf="!viewMore">arrow_drop_down</i>
    </div>
  </div>

  <div class="line"></div>

  <div *ngIf="dataFetched">
    <div class="row" [hidden]="!showTimeline">
      <div class="col-md-12 order-timeline-wrapper">
          <app-order-status [statusDetails]="orderTimeLine" [FNDcase]="FNDCase" [statusMap]="statusMap"></app-order-status>
      </div>
    </div>
    <div class="line" *ngIf="showTimeline"></div>

    <div class="row padding-bottom-18">
      <div class="col-md-3">
        <span class="margin-left-12 display-inline-block margin-top-13 font-size-14 font-weight-600">Order tags : 
          <span [ngStyle]="{'color' : orderTags.indexOf('Nil') === -1 ? '#ffffff' : 'black'}" 
            [ngClass]="{'order-tags' : orderTags.indexOf('Nil') === -1}" *ngFor="let tag of orderTags">{{tag}}</span>
        </span><br>
        <span class="margin-left-12 display-inline-block margin-top-8 font-size-14 font-weight-600">Fraud tags :
          <span [ngStyle]="{'color' : fraudTags.indexOf('Nil') === -1 ? '#ffffff' : 'black'}" 
            [ngClass]="{'fraud-tags' : fraudTags.indexOf('Nil') === -1}" *ngFor="let tag of fraudTags">{{tag}}</span>
        </span>
      </div>

      <div *ngIf="currentStatus" class="col-md-2 margin-top-13" style="display: flex; align-items: center;">
        <span>Status : </span>
        <div class="status-indicator display-inline-block background-color-cd1f1e"
        [ngStyle]="{'background-color': statusRed.indexOf(currentStatus) === -1 ? 
                    '#5fba00' : '#cd1f1e'}" class="pulse"></div>
        <span class="font-size-13 font-weight-600"
          [ngStyle]="{'color': statusRed.indexOf(currentStatus) === -1 ? 
                      '#5fba00' : '#cd1f1e'}">{{currentStatus}}</span>
        <i class="material-icons edit-icon font-size-16 cursor-pointer"
          (click)="changeStatus(currentStatus)" *ngIf="this.allowStatusChangeFrom[currentStatus]">create</i>

      </div>

      <div *ngIf="propagationType" class="col-md-2 margin-top-13" style="display: flex; align-items: center;">
        <span>Propagation Type: </span>
        <span class="font-size-13 font-weight-600" style="text-transform: capitalize; padding-left: 1%">
          {{propagationType}}
        </span>
      </div>
      <div *ngIf="microCorridor" class="col-md-2 margin-top-13" style="display: flex; align-items: center;">
        <span>Micro Corridor: </span>
        <span class="font-size-13 font-weight-600" style="text-transform: capitalize; padding-left: 1%">
          {{microCorridor}}
        </span>
      </div>
      <div class="col time-line-button">
            <button *ngIf= "isDropOrderEnabled" class="drop-order font-size-13 font-weight-600 margin-right-12" (click)="partialDropOrder()"
              >Drop Order
            </button>
            <button class="activity-log color-cd1f1e font-size-13 font-weight-600 margin-right-12" (click)="getActivityLog()"
              [ngStyle]="{'color': '#343A40','border': '1px solid #343A40'}">Activity Log
            </button>
            <button class="abort color-cd1f1e font-size-13 font-weight-600 margin-right-12" (click)="abort()"
            [ngStyle]="{'color': abortRide ? '#343A40' : '#a0a0a0',
            'border': abortRide ? '1px solid #343A40': 'solid 1px #a0a0a0'}">Abort
            </button>
            <button class="abort color-cd1f1e font-size-13 font-weight-600 margin-right-12" (click)="cancel()"
            [ngStyle]="{'color': cancelRide ? '#343A40' : '#a0a0a0',
            'border': cancelRide ? '1px solid #343A40': 'solid 1px #a0a0a0'}">Cancel
            </button>
          <button class="reassign font-size-13 font-weight-600" (click)="reAssignCaptain()"
            [ngStyle]="{'background-color': isReassignable ? '#467fcf' : ' #ffffff',
                        'color': isReassignable ? '#ffffff' : '#a0a0a0',
                        'border': isReassignable ? '1px solid #467fcf': 'solid 1px #a0a0a0'}">Re-assign
          </button>
      </div>
    </div>
  </div>

  <div *ngIf="!dataFetched" class="noDataWrapper">
    <span>No data found ¯\_(ツ)_/¯</span>
  </div>

</div>