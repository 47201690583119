import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CaptainComplaintsService} from '../../captain-complaints-by-customer.service';
import {Router} from '@angular/router';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';

@Component({
  selector: 'app-captain-complaints-by-customer',
  templateUrl: './captain-complaints-by-customer.component.html',
  styleUrls: ['./captain-complaints-by-customer.component.css']
})
export class CaptainComplaintsByCustomerComponent implements OnInit {

  constructor(
      public captainComplaintsService: CaptainComplaintsService,
      private router: Router,
  ) { }

  @Input() captainId: any;
  @Input() captainDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter<any>();
  captainComplaintsByCustomer: Table;
  dataLoaded = false;

  async ngOnInit() {
    await this.declareTable();
  }

  async declareTable(pageNumber = 0) {
    this.captainComplaintsByCustomer = new Table({
      // tslint:disable-next-line:max-line-length
      toggleableHeaders: this.captainDetailsToggleableHeaders,
      headers: {
        complaint: 'Complaint',
        complaintsToday: 'Complaints Today',
        complaintsLast3Days: 'Complaints Last 3 Days',
        complaintsLast7Days: 'Complaints Last 7 Days',
        complaintsLast15Days: 'Complaints Last 15 Days',
        complaintsLast30Days: 'Complaints Last 30 Days',
        day: 'Created Date',
      },
      pagination: new Pagination(pageNumber, 10),
      selectedHeader: 5,
      defaultError: 'No Complaints Found',
      onRefresh: () => {
        this.captainComplaintsByCustomer = null;
        this.ngOnInit();
      },
      data: []
    });
    await this.getCaptainComplaintsByCustomer();
  }

  async getCaptainComplaintsByCustomer() {
    this.dataLoaded = false;
    try {
      const response: any = await this.captainComplaintsService.fetchComplaintsByCustomer(this.captainId).toPromise();
      this.dataLoaded = true;
      if (response && response.data.found) {
        this.captainComplaintsByCustomer.data = this.formatCustomerComplaints(response.data.complaints);
      }
    } catch (error) {
      console.error('Error while fetching complaints for customer');
      console.error(error);
      this.dataLoaded = true;
    }
  }

  formatCustomerComplaints(customerComplaints = []) {
    const formattedComplaints = [];
    customerComplaints.map((item) => {
      const complaint = {
        complaint: this.createTableData(item.subReason || ''),
        complaintsToday: this.createTableData(item.complaintsToday || ''),
        complaintsLast3Days: this.createTableData(item.complaintsLast3Days || ''),
        complaintsLast7Days: this.createTableData(item.complaintsLast7Days || ''),
        complaintsLast15Days: this.createTableData(item.complaintsLast15Days || ''),
        complaintsLast30Days: this.createTableData(item.complaintsLast30Days || ''),
        day: this.createTableData(item.day),
      };
      formattedComplaints.push(complaint);
    });
    this.captainComplaintsByCustomer.data = formattedComplaints;
    return formattedComplaints;
  }

  createTableData(value, className?, type?, flag = false, hoverData?) {
    return new TableData({
      data: value,
      type: type ? type : TableDataTypes.DATA,
      flag: flag,
      hoverData: hoverData ? hoverData : null,
      className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  onPaginationChange(event) {
    this.getCaptainComplaintsByCustomer();
  }
}

