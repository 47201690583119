import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { CardComponent } from './card/card.component';
import { CardGroupComponent } from './card-group/card-group.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { ThumbnailCardComponent } from './thumbnail-card/thumbnail-card.component';
import { HorizontalListComponent } from './horizontal-list/horizontal-list.component';
import { FilterSidenavComponent } from './filter-sidenav/filter-sidenav.component';
import { SingleSelectDropdownComponent } from './single-select-dropdown/single-select-dropdown.component';
import { RadioButtonsComponent } from './radio-buttons/radio-buttons.component';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { CalendarComponent } from './calendar/calendar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SideNavComponent } from './side-nav/side-nav.component';
import { DemoMaterialModule } from '../material-module';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { PaginationComponent } from './pagination/pagination.component';
import { TableScrollableComponent } from '../table-scrollable/table-scrollable.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ToasterComponent } from './toaster/toaster.component';
import { PrimengModule } from '../primeng.module';
import { InputBoxComponent } from './input-box/input-box.component';
import { CardStatsComponent } from '../card-stats/card-stats.component';
import { FraudCardComponent } from './fraud-card/fraud-card.component';
import { QuickFiltersComponent } from './quick-filters/quick-filters.component';

@NgModule({
  declarations: [
    TableComponent,
    CardComponent,
    CardGroupComponent,
    PageHeaderComponent,
    ThumbnailCardComponent,
    HorizontalListComponent,
    FilterSidenavComponent,
    SingleSelectDropdownComponent,
    RadioButtonsComponent,
    SearchableDropdownComponent,
    CalendarComponent,
    SideNavComponent,
    PaginationComponent,
    TableScrollableComponent,
    RangeSliderComponent,
    ToasterComponent,
    InputBoxComponent,
    CardStatsComponent,
    FraudCardComponent,
    QuickFiltersComponent
],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DemoMaterialModule,
    ContentLoaderModule,
    FontAwesomeModule,
    Ng5SliderModule,
    PrimengModule
  ],
  exports: [
    TableComponent,
    CardComponent,
    CardGroupComponent,
    PageHeaderComponent,
    ThumbnailCardComponent,
    HorizontalListComponent,
    FilterSidenavComponent,
    SingleSelectDropdownComponent,
    RadioButtonsComponent,
    InputBoxComponent,
    SearchableDropdownComponent,
    CalendarComponent,
    SideNavComponent,
    TableScrollableComponent,
    ToasterComponent,
    CardStatsComponent
  ]
})
export class SharedModule { }
