<div *ngIf="card.visible" class="card" [ngStyle]="cardStyle">
    <div (click)="card?.onClick()" class="card-body p-3 text-center" [ngStyle]="card.style">
      <div [class]="hikeClass">
        <ng-container *ngIf="card.hikePercent">
          {{card.displayPercentValue}}%
          <i *ngIf="card.isPositivehike" class="fe fe-chevron-up" style="
          color: white;
      "></i>
          <i *ngIf="!card.isPositivehike" class="fe fe-chevron-down" style="
          color: white;
      "></i>
        </ng-container>
        <ng-container *ngIf="!card.hikePercent">
            <i *ngIf="card.isPositivehike" class="fe fe-chevron-up" style="
            color: white;
        "></i>

            <mat-icon *ngIf="card?.value?.popover && card?.value?.popoverIcon" style="font-size: 15px !important;" [ngClass]="card.value && card.value.className" [ngStyle]="card.value && card.value.style" title="{{card.value && card.value.popoverIconTooltip}}">
                {{card.value && card.value.popoverIcon}}
            </mat-icon>


        </ng-container>
      </div>
      <div class="h1 m-0 clip-text" [attr.title]="card.value">
          <!-- <mat-icon class="yellow-star">star_rate</mat-icon> -->
          <mat-icon *ngIf="card?.value?.icon" style="
          font-size: 20px !important;
      " [ngClass]="card.value && card.value.className" [ngStyle]="card.value && card.value.style">{{card.value && card.value.icon}}</mat-icon>
          <!-- <mat-icon [class]="card.value && card.value.className">{{card.value && card.value.icon}}</mat-icon> -->
          <!-- <mat-icon class="yellow-star">{{card.value && card.value.icon}}</mat-icon> -->
        {{card.value || 'N/A'}}
      </div>

      <div class="text-muted mb-4 clip-text" [attr.title]="card.label">{{card.label}}</div>
    </div>
  </div>
