var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { environment } from '../../environments/environment';
import { OrderDetailsService } from '../order-details/order-details.service';
var C2cOrderDetailsComponent = /** @class */ (function () {
    function C2cOrderDetailsComponent(dialog, orderDetailsService) {
        var _this = this;
        this.dialog = dialog;
        this.orderDetailsService = orderDetailsService;
        this.showPopUp = false;
        this.categoriesToShow = [];
        this.showCopied = false;
        this.allImages = [];
        this.compare2Arrays = function (arr1, arr2) { return arr1.every(function (item) { return arr2.includes(item); }); };
        this.getPackageImageTitle = function () {
            if (_this.data.orderType === 'bfs') {
                return 'Package & Invoice Images';
            }
            return 'Package Images';
        };
        this.getItemCategoryTitle = function () {
            if (_this.data.orderType === 'bfs') {
                return 'Items';
            }
            return 'Item Category';
        };
    }
    C2cOrderDetailsComponent.prototype.ngOnInit = function () {
        if (this.data && this.data.imageUrl) {
            this.getImageUrls(this.data.imageUrl);
        }
        if (this.data && this.data.itemCategory) {
            if (this.data.itemCategory <= 2) {
                this.categoriesToShow = this.data.itemCategory;
            }
            else {
                this.categoriesToShow = this.data.itemCategory.slice(0, 2);
                this.additionalCategories = this.data.itemCategory.slice(2, this.data.itemCategory.length);
                this.tooltipData();
            }
        }
        // To show main catefories
        if (this.data && this.data.mainCategory) {
            if (this.data.mainCategory <= 2) {
                this.mainCategoryToShow = this.data.mainCategory;
            }
            else {
                this.mainCategoryToShow = this.data.mainCategory.slice(0, 2);
                this.additionalMainCategories = this.data.mainCategory.slice(2, this.data.mainCategory.length);
                this.tooltipDataForMainCategory();
            }
        }
    };
    C2cOrderDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.data.previousValue && changes.data.currentValue && changes.data.previousValue.imageUrl && changes.data.currentValue.imageUrl) {
            var isPreviousAndCurrentImageSame = this.compare2Arrays(changes.data.previousValue.imageUrl, changes.data.currentValue.imageUrl);
            if (!isPreviousAndCurrentImageSame) {
                this.getImageUrls(this.data.imageUrl);
            }
        }
        if (this.data && this.data.itemCategory) {
            if (this.data.itemCategory <= 2) {
                this.categoriesToShow = this.data.itemCategory;
            }
            else {
                this.categoriesToShow = this.data.itemCategory.slice(0, 2);
                this.additionalCategories = this.data.itemCategory.slice(2, this.data.itemCategory.length);
                this.tooltipData();
            }
        }
        // To show main catefories
        if (this.data && this.data.mainCategory) {
            if (this.data.mainCategory <= 2) {
                this.mainCategoryToShow = this.data.mainCategory;
            }
            else {
                this.mainCategoryToShow = this.data.mainCategory.slice(0, 2);
                this.additionalMainCategories = this.data.mainCategory.slice(2, this.data.mainCategory.length);
                this.tooltipDataForMainCategory();
            }
        }
    };
    C2cOrderDetailsComponent.prototype.tooltipData = function () {
        this.additionalCategoriesToolTip = 'Item-Details' + "<div class='dashed-top-border'>" + this.tableData() + '</div>';
    };
    C2cOrderDetailsComponent.prototype.tableData = function () {
        var data = '';
        for (var _i = 0, _a = this.additionalCategories; _i < _a.length; _i++) {
            var values = _a[_i];
            if (values) {
                data += '<div>' + values + '</div>';
            }
        }
        return data;
    };
    C2cOrderDetailsComponent.prototype.openPopup = function (text) {
        if (text) {
            this.popUpText = text;
        }
        else {
            this.popUpText = 'N/A';
        }
        this.showPopUp = true;
    };
    C2cOrderDetailsComponent.prototype.closePopup = function () {
        this.showPopUp = false;
        this.popUpText = '';
    };
    C2cOrderDetailsComponent.prototype.createImageFromBlob = function (image) {
        var _this = this;
        var reader = new FileReader();
        reader.addEventListener('load', function () {
            _this.allImages.push(reader.result);
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    };
    C2cOrderDetailsComponent.prototype.getImageUrl = function (url) {
        var _this = this;
        var customUrl = environment.s3RapidoUrl + '/' + this.data.orderType + '/' + url;
        this.orderDetailsService.getImageFromS3(customUrl).subscribe(function (result) {
            _this.createImageFromBlob(result);
        });
    };
    C2cOrderDetailsComponent.prototype.getImageUrls = function (arrayOfImages) {
        return __awaiter(this, void 0, void 0, function () {
            var promises, image, customUrl;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = [];
                        for (image in arrayOfImages) {
                            if (arrayOfImages[image]) {
                                customUrl = environment.s3RapidoUrl + '/' + this.data.orderType + '/' + arrayOfImages[image];
                                promises.push(this.orderDetailsService.getImageFromS3(customUrl).toPromise().then(function (result) {
                                    var imageUrl = _this.createImageFromBlob(result);
                                }));
                            }
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    C2cOrderDetailsComponent.prototype.copyTrackUrl = function () {
        var _this = this;
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', (_this.data.trackUrl));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        this.showCopied = true;
        setTimeout(function () {
            _this.showCopied = false;
        }, 1000);
    };
    C2cOrderDetailsComponent.prototype.openDialog = function (url) {
        if (url) {
            var dialogRef = this.dialog.open(DialogComponent, {
                width: '400px',
                data: {
                    type: 'image',
                    imageUrl: url
                }
            });
        }
    };
    C2cOrderDetailsComponent.prototype.tooltipDataForMainCategory = function () {
        this.additionaMainlCategoriesToolTip = 'Category-Details' + "<div class='dashed-top-border'>" + this.getMainCategoryData() + '</div>';
    };
    C2cOrderDetailsComponent.prototype.getMainCategoryData = function () {
        var data = '';
        for (var _i = 0, _a = this.additionalMainCategories; _i < _a.length; _i++) {
            var values = _a[_i];
            if (values) {
                data += '<div>' + values + '</div>';
            }
        }
        return data;
    };
    return C2cOrderDetailsComponent;
}());
export { C2cOrderDetailsComponent };
