import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityService } from '../entity.service';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
import * as i2 from "../entity.service";
var MerchantService = /** @class */ (function () {
    function MerchantService(http, entityService) {
        this.http = http;
        this.entityService = entityService;
        this.server = "" + environment.server;
        this.getMerchantUrl = this.server + '/api/getMerchantByMobile';
        this.logoutMerchantUrl = this.server + '/api/logoutMerchant';
        this.getWalletBalances = this.server + '/api/getWalletBalance/merchant';
        this.merchantTxnUrl = this.server + '/api/getMerchantTransactions';
        this.merchantOrders = this.server + '/api/merchantOrders';
    }
    MerchantService.prototype.getMerchantByMobile = function (mobile) {
        return this.http.get(this.getMerchantUrl + '/' + mobile).pipe(catchError(this.handleError));
    };
    MerchantService.prototype.logoutMerchant = function (userId) {
        return this.http.post(this.logoutMerchantUrl, { userId: userId }).pipe(catchError(this.handleError));
    };
    MerchantService.prototype.getMerchantWallets = function (merchantDetails, merchantId) {
        var payload = {
            user: merchantDetails,
            merchantId: merchantId
        };
        return this.http.post(this.getWalletBalances, payload).pipe(catchError(this.handleError));
    };
    MerchantService.prototype.getMerchantTxns = function (groupEntityId, limit, offset, merchantId) {
        var payload = { limit: limit, offset: offset, groupEntityId: groupEntityId, merchantId: merchantId };
        return this.http.post(this.merchantTxnUrl, payload).pipe(catchError(this.handleError));
    };
    MerchantService.prototype.getMerchantOrders = function (payload) {
        return this.http.post(this.merchantOrders, payload).pipe(catchError(this.handleError));
    };
    MerchantService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    MerchantService.ngInjectableDef = i0.defineInjectable({ factory: function MerchantService_Factory() { return new MerchantService(i0.inject(i1.MyHttpClient), i0.inject(i2.EntityService)); }, token: MerchantService, providedIn: "root" });
    return MerchantService;
}());
export { MerchantService };
