import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var CaptainAdjustmentService = /** @class */ (function () {
    function CaptainAdjustmentService(http) {
        this.http = http;
        this.server = environment.server;
        this.orderBaseUrl = this.server + '/api/orders';
    }
    CaptainAdjustmentService.prototype.captainAdjustmentDetails = function (orderId, adjustmentObj) {
        var requestBody = {
            adjustmentObj: adjustmentObj
        };
        return this.http.post(this.orderBaseUrl + '/' + orderId + '/captainRemarks', requestBody).pipe(catchError(this.handleError));
    };
    CaptainAdjustmentService.prototype.captainRefundDetails = function (orderId, adjustmentObj, role) {
        var requestBody = {
            adjustmentObj: adjustmentObj,
            role: role
        };
        return this.http.post(this.orderBaseUrl + '/' + orderId + '/refund', requestBody).pipe(catchError(this.handleError));
    };
    CaptainAdjustmentService.prototype.setUserDistance = function (orderId, updateKmObj) {
        var requestBody = {
            updateKmObj: updateKmObj
        };
        return this.http.post(this.orderBaseUrl + '/' + orderId + '/updateDistance', requestBody).pipe(catchError(this.handleError));
    };
    CaptainAdjustmentService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CaptainAdjustmentService.ngInjectableDef = i0.defineInjectable({ factory: function CaptainAdjustmentService_Factory() { return new CaptainAdjustmentService(i0.inject(i1.MyHttpClient)); }, token: CaptainAdjustmentService, providedIn: "root" });
    return CaptainAdjustmentService;
}());
export { CaptainAdjustmentService };
