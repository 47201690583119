import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { environment } from '../../environments/environment';
import { OrderDetailsService } from '../order-details/order-details.service';
@Component({
  selector: 'app-c2c-order-details',
  templateUrl: './c2c-order-details.component.html',
  styleUrls: ['./c2c-order-details.component.css']
})
export class C2cOrderDetailsComponent implements OnInit, OnChanges {
  @Input() data: any;
  public popUpText;
  public showPopUp = false;
  public categoriesToShow = [];
  public additionalCategories;
  public additionalCategoriesToolTip;
  public showCopied = false;
  public completeImageUrl;
  public allImages = [];
    mainCategoryToShow: any;
    additionalMainCategories: any;
    additionaMainlCategoriesToolTip: string;
  constructor(
    public dialog: MatDialog,
    public orderDetailsService: OrderDetailsService
  ) { }
  ngOnInit() {
    if (this.data && this.data.imageUrl) {
      this.getImageUrls(this.data.imageUrl);
    }
    if (this.data && this.data.itemCategory) {
      if (this.data.itemCategory <= 2) {
        this.categoriesToShow = this.data.itemCategory;
      } else {
        this.categoriesToShow = this.data.itemCategory.slice(0, 2);
        this.additionalCategories = this.data.itemCategory.slice(2, this.data.itemCategory.length);
        this.tooltipData();
      }
    }

    // To show main catefories
    if (this.data && this.data.mainCategory) {
        if (this.data.mainCategory <= 2) {
            this.mainCategoryToShow = this.data.mainCategory;
        } else {
            this.mainCategoryToShow = this.data.mainCategory.slice(0, 2);
            this.additionalMainCategories = this.data.mainCategory.slice(2, this.data.mainCategory.length);
            this.tooltipDataForMainCategory();
        }
    }
  }

    compare2Arrays = (arr1, arr2) => arr1.every(item => arr2.includes(item));
    
    ngOnChanges(changes: SimpleChanges) {
   
        if ( changes.data.previousValue && changes.data.currentValue && changes.data.previousValue.imageUrl && changes.data.currentValue.imageUrl) {
            const isPreviousAndCurrentImageSame = this.compare2Arrays(changes.data.previousValue.imageUrl, changes.data.currentValue.imageUrl);
          if (!isPreviousAndCurrentImageSame) {
              this.getImageUrls(this.data.imageUrl);
          }
        }

        if (this.data && this.data.itemCategory) {
        if (this.data.itemCategory <= 2) {
            this.categoriesToShow = this.data.itemCategory;
        } else {
            this.categoriesToShow = this.data.itemCategory.slice(0, 2);
            this.additionalCategories = this.data.itemCategory.slice(2, this.data.itemCategory.length);
            this.tooltipData();
        }
        }

        // To show main catefories
        if (this.data && this.data.mainCategory) {
            if (this.data.mainCategory <= 2) {
                this.mainCategoryToShow = this.data.mainCategory;
            } else {
                this.mainCategoryToShow = this.data.mainCategory.slice(0, 2);
                this.additionalMainCategories = this.data.mainCategory.slice(2, this.data.mainCategory.length);
                this.tooltipDataForMainCategory();
            }
        }
  }

  tooltipData() {
    this.additionalCategoriesToolTip = 'Item-Details' + `<div class='dashed-top-border'>` + this.tableData() + '</div>';
  }

  tableData() {
    let data = '';
    for (const values of this.additionalCategories) {
      if (values) {
        data += '<div>' + values + '</div>';
       }
    }
    return data;
  }

  openPopup(text) {
    if (text) {
      this.popUpText = text;
    } else {
      this.popUpText = 'N/A';
    }
    this.showPopUp = true;
  }

  closePopup() {
    this.showPopUp = false;
    this.popUpText = '';
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.allImages.push(reader.result);
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getImageUrl(url) {
    const customUrl = environment.s3RapidoUrl + '/' + this.data.orderType + '/' + url;
    this.orderDetailsService.getImageFromS3(customUrl).subscribe((result: Blob) => {
      this.createImageFromBlob(result);
    });
  }

  async getImageUrls(arrayOfImages) {
    const promises = [];
    for (const image in arrayOfImages) {
      if (arrayOfImages[image]) {
        const customUrl = environment.s3RapidoUrl + '/' + this.data.orderType + '/' + arrayOfImages[image];
        promises.push( this.orderDetailsService.getImageFromS3(customUrl).toPromise().then((result: Blob) => {
          const imageUrl: any = this.createImageFromBlob(result);
        }));
      }
    }
    await Promise.all(promises);
  }

  copyTrackUrl() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.data.trackUrl));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 1000);
  }

  openDialog(url): void {
    if (url) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '400px',
        data: {
               type: 'image',
               imageUrl: url
              }
      });
    }
  }

    tooltipDataForMainCategory() {
        this.additionaMainlCategoriesToolTip = 'Category-Details' + `<div class='dashed-top-border'>` + this.getMainCategoryData() + '</div>';
    }

    getMainCategoryData() {
        let data = '';
        for (const values of this.additionalMainCategories) {
            if (values) {
                data += '<div>' + values + '</div>';
            }
        }
        return data;
    }

    getPackageImageTitle = () => {
        if (this.data.orderType === 'bfs') {
            return 'Package & Invoice Images';
        }
        return 'Package Images';
    }

    getItemCategoryTitle = () => {
        if (this.data.orderType === 'bfs') {
            return 'Items';
        }
        return 'Item Category';
    }
}
