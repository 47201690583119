/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-status.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/table/table.component.ngfactory";
import * as i3 from "../shared/table/table.component";
import * as i4 from "../filter.service";
import * as i5 from "./payment-status.component";
import * as i6 from "./payment-status.service";
import * as i7 from "@angular/material/dialog";
var styles_PaymentStatusComponent = [i0.styles];
var RenderType_PaymentStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentStatusComponent, data: {} });
export { RenderType_PaymentStatusComponent as RenderType_PaymentStatusComponent };
export function View_PaymentStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-table", [], null, null, null, i2.View_TableComponent_0, i2.RenderType_TableComponent)), i1.ɵdid(2, 638976, null, 0, i3.TableComponent, [i4.FilterService], { table: [0, "table"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doRefresh() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Refresh"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.txnListData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PaymentStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-status", [], null, null, null, View_PaymentStatusComponent_0, RenderType_PaymentStatusComponent)), i1.ɵdid(1, 114688, null, 0, i5.PaymentStatusComponent, [i6.PaymentStatusService, i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentStatusComponentNgFactory = i1.ɵccf("app-payment-status", i5.PaymentStatusComponent, View_PaymentStatusComponent_Host_0, {}, {}, []);
export { PaymentStatusComponentNgFactory as PaymentStatusComponentNgFactory };
