import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var CustOrderDetailsService = /** @class */ (function () {
    function CustOrderDetailsService(http) {
        this.http = http;
        this.server = environment.server;
        this.baseUrl = this.server + '/api/orders';
        this.subscription = this.server + '/api/getPassDetails';
    }
    CustOrderDetailsService.prototype.getPassInfo = function (userId) {
        return this.http.get(this.subscription + '/' + userId).pipe(catchError(this.handleError));
    };
    CustOrderDetailsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CustOrderDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function CustOrderDetailsService_Factory() { return new CustOrderDetailsService(i0.inject(i1.MyHttpClient)); }, token: CustOrderDetailsService, providedIn: "root" });
    return CustOrderDetailsService;
}());
export { CustOrderDetailsService };
