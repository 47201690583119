import { MyHttpClient } from '../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./http-client.service";
var UserTicketingService = /** @class */ (function () {
    function UserTicketingService(http) {
        this.http = http;
        this.server = environment.server;
        this.fetchTicketsV2Url = this.server + "/api/user/tickets/v2";
        this.fetchTicketCommentsUrl = this.server + "/api/user/tickets/v1/comments";
    }
    UserTicketingService.prototype.fetchUserTicketsV2 = function (userId, userType, query, pageNumber) {
        return this.http.get(this.fetchTicketsV2Url + "/" + userId + "?userType=" + userType + "&query=" + query + "&page=" + pageNumber).pipe(catchError(this.handleError));
    };
    UserTicketingService.prototype.fetchTicketLastComment = function (userId, ticketId, includePrivate, perPage) {
        return this.http.get(this.fetchTicketCommentsUrl + "/" + ticketId + "/" + userId + "?includePrivate=" + includePrivate + "&perPage=" + perPage).pipe(catchError(this.handleError));
    };
    UserTicketingService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        return throwError(error);
    };
    UserTicketingService.ngInjectableDef = i0.defineInjectable({ factory: function UserTicketingService_Factory() { return new UserTicketingService(i0.inject(i1.MyHttpClient)); }, token: UserTicketingService, providedIn: "root" });
    return UserTicketingService;
}());
export { UserTicketingService };
