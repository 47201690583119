import { HelperService } from './helper.service';
import { of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SigninService } from './signin/signin.service';
import { ToasterType, Toaster } from './shared/types';
import { ToasterService } from './toaster.service';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./signin/signin.service";
import * as i2 from "./toaster.service";
import * as i3 from "./data.service";
import * as i4 from "@angular/router";
var helper = new JwtHelperService();
var AuthService = /** @class */ (function () {
    function AuthService(signInService, _toastMessage, dataService, router) {
        this.signInService = signInService;
        this._toastMessage = _toastMessage;
        this.dataService = dataService;
        this.router = router;
        this.user = null;
        this._token = null;
        this.decodedToken = null;
        this.redirectUrl = null;
    }
    AuthService.prototype.setToken = function (token) {
        if (!token) {
            this._token = null;
            this.decodedToken = null;
            this.user = null;
            HelperService.setCookie({
                token: '',
                user: '',
            });
            return;
        }
        this._token = token;
        this.decodedToken = this.getDecodedToken();
        if (!this.user) {
            this.setUserInfo();
        }
    };
    Object.defineProperty(AuthService.prototype, "isUserLoggedIn", {
        get: function () {
            return this.token ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.setUserInfo = function () {
        var _this = this;
        if (!this.decodedToken) {
            return of();
        }
        return this.signInService.getUserInfo(this.decodedToken.email)
            .map(function (userDetails) {
            userDetails = userDetails && userDetails.data;
            _this.setUserDetails(userDetails);
            return userDetails;
        });
    };
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            return this._token;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.onRouteTokenUpdate = function () {
        var _this = this;
        var onSuccessfullSignIn = function (roles) {
            var cookieRoles = [], accessRoles = ['refund_access', 'debit_access', 'distance_update_access', 'customer_deactivate_access', 'revoke_suspension', 'revoke_penalty', 'revoke_blacklist', 'manager_profile_view', 'manager_search_role_edit', 'allow_p0_suspension', 'revoke_p0_suspension', 'remove_spd_fraud'];
            _this.signInService.setCookies({ roles: roles }).subscribe(function (res) {
                if (res['data'] && res['data'].data) {
                    for (var index in accessRoles) {
                        if (accessRoles[index] && res['data'].data.indexOf(accessRoles[index]) !== -1) {
                            cookieRoles.push(accessRoles[index]);
                        }
                    }
                }
                if (cookieRoles.length > 0) {
                    document.cookie = 'accessRoles=' + cookieRoles;
                }
                _this.dataService.updatePermissions(res['data']);
            });
        };
        this.signInService.getUserInfo().subscribe(function (result) {
            var response = result || {};
            response = response.data;
            _this.setUserDetails(response);
            if (response && response._id) {
                var roles = response.roles;
                document.cookie = 'Roles=' + roles;
                HelperService.setCookie({
                    name: response.email,
                    user: {
                        _id: response._id,
                        firstName: response.firstName,
                        emailId: response.email,
                    },
                });
                onSuccessfullSignIn(roles);
            }
            else {
                _this._toastMessage.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: 'User not found! Please contact support.',
                }));
            }
        }, function (err) {
            _this._toastMessage.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: err.error.message,
            }));
            _this.logOut();
        });
    };
    AuthService.prototype.isAuthenticated = function () {
        var routeToken = HelperService.routeParams().token || '';
        var existingToken = HelperService.accessCookie('token') || '';
        routeToken = routeToken.split('?')[0];
        existingToken = existingToken.split('?')[0];
        var token = routeToken || existingToken;
        // if (routeToken) {
        this.onRouteTokenUpdate();
        // }
        this.setTokenToCookie(token);
        if (!token) {
            return false;
        }
        if (routeToken) {
            var url = HelperService.removeParam(['token']);
            HelperService.redirect(url, false);
        }
        this.setToken(token);
        return true;
    };
    AuthService.prototype.clearUserCred = function () {
        this.setToken(null);
    };
    AuthService.prototype.deleteAllCookies = function () {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name_1 = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name_1 + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    };
    AuthService.prototype.logOut = function () {
        this.signInService.logoutGoogle();
        this.clearUserCred();
        this.deleteAllCookies();
        setTimeout(function () {
            HelperService.redirect(environment.oauth);
        });
    };
    AuthService.prototype.getUserDetails = function () {
        if (this.user) {
            return of(this.user);
        }
        return this.setUserInfo();
    };
    AuthService.prototype.getDecodedToken = function () {
        if (!this.token) {
            return false;
        }
        var decodedToken = helper.decodeToken(this.token);
        return decodedToken;
    };
    AuthService.prototype.setUserDetails = function (user) {
        this.user = user;
    };
    AuthService.prototype.getToken = function () {
        return HelperService.accessCookie('token');
    };
    AuthService.prototype.clearToken = function () {
        this.setToken(null);
    };
    AuthService.prototype.setTokenToCookie = function (token) {
        HelperService.setCookie({
            token: token,
        });
        // document.cookie = `token=${token}`;
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.SigninService), i0.inject(i2.ToasterService), i0.inject(i3.DataService), i0.inject(i4.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
