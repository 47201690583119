/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cust-order-details.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card-table/card-table.component.ngfactory";
import * as i3 from "../card-table/card-table.component";
import * as i4 from "@angular/router";
import * as i5 from "./cust-order-details.component";
import * as i6 from "./cust-order-details.service";
var styles_CustOrderDetailsComponent = [i0.styles];
var RenderType_CustOrderDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustOrderDetailsComponent, data: {} });
export { RenderType_CustOrderDetailsComponent as RenderType_CustOrderDetailsComponent };
export function View_CustOrderDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-table", [], null, null, null, i2.View_CardTableComponent_0, i2.RenderType_CardTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.CardTableComponent, [i4.Router], { info: [0, "info"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.info; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CustOrderDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cust-order-details", [], null, null, null, View_CustOrderDetailsComponent_0, RenderType_CustOrderDetailsComponent)), i1.ɵdid(1, 638976, null, 0, i5.CustOrderDetailsComponent, [i6.CustOrderDetailsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustOrderDetailsComponentNgFactory = i1.ɵccf("app-cust-order-details", i5.CustOrderDetailsComponent, View_CustOrderDetailsComponent_Host_0, { custDetails: "custDetails" }, {}, []);
export { CustOrderDetailsComponentNgFactory as CustOrderDetailsComponentNgFactory };
