import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-c2c-receiver-details',
  templateUrl: './c2c-receiver-details.component.html',
  styleUrls: ['./c2c-receiver-details.component.css']
})
export class C2cReceiverDetailsComponent implements OnInit, OnChanges {

  @Input() packageDetails: any;
  public receiverDetails: any;
  constructor() { }

  ngOnInit() {
      if (this.packageDetails && this.packageDetails.orderType && this.packageDetails.orderType === 'bfs') {
          this.generateBfsReceiverDetails();
      } else {
          this.generateReceiverDetails();
      }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (this.packageDetails && this.packageDetails.orderType && this.packageDetails.orderType === 'delivery') {
        this.generateB2bReceiverDetails();
      } else if (this.packageDetails && this.packageDetails.orderType && this.packageDetails.orderType === 'bfs') {
        this.generateBfsReceiverDetails();
      } else {
        this.generateReceiverDetails();
      }
      this.receiverDetails.dropOtpStatus = this.packageDetails.dropOtpStatus;

    }
  }

  generateReceiverDetails() {
    if (this.packageDetails) {
      let fullAddress = "";
      if (this.packageDetails.drop) {
        fullAddress = (this.packageDetails.drop.doorNo + ", " || "") + (this.packageDetails.drop.address || "") + 
          (this.packageDetails.drop.landmark ? " (Landmark : " + this.packageDetails.drop.landmark + ")" : "");
      }
      this.receiverDetails = {
          tableHeader: 'Receiver Details',
          name: this.packageDetails.c2c && this.packageDetails.c2c.receiver &&
            this.packageDetails.c2c.receiver.name || 'N/A',
          number: this.packageDetails.c2c && this.packageDetails.c2c.receiver &&
            this.packageDetails.c2c.receiver.phone || 'N/A',
          dropOtp: this.packageDetails.dropOtp || 'N/A',
          address: fullAddress || 'N/A',
          imageUrl: this.packageDetails.c2c.dropImages,
          dropInstructions: this.packageDetails.c2c && this.packageDetails.c2c.dropInstructions || null,
          trackUrl: this.getTrackUrl(),
          orderType: this.packageDetails.orderType,
      };
    }
  }

  generateB2bReceiverDetails() {
    if (this.packageDetails && this.packageDetails.data) {
      const data = this.packageDetails.data;
      this.receiverDetails = {
        tableHeader: 'Receiver Details',
        name: data.delivery && data.delivery.contact && data.delivery.contact.dropName || 'N/A',
        number: data.delivery && data.delivery.contact && data.delivery.contact.dropNumber || 'N/A',
        address: data.dropLocation && data.dropLocation.address || 'N/A',
        directImageUrl: data.instructions && data.instructions[0] && data.instructions[0].photoUrls || [],
        dropInstructions: data.instructions && data.instructions[0] && data.instructions[0].text || null,
        captainComments: data.instructions && data.instructions[0] && data.instructions[0].captainMessage || null,
        trackUrl: this.getTrackUrl(),
        orderType: this.packageDetails.orderType
      };
    }
  }

  getTrackUrl() {
    let encodedOrderId;
    if (this.packageDetails.orderId) {
      encodedOrderId = this.encodeOrderId(this.packageDetails.orderId);
    }
    return environment.trackUrl + encodedOrderId;
  }

  encodeOrderId(trackId) {
    const first11 = trackId.substring(0, 11);
    const lastPart = trackId.substring(11, trackId.length);
    return lastPart + first11;
  }


    getLineItems(items) {
        const itemsArray = [];
        if (items && items.length) {
            for (const item in items) {
                if (items[item] && items[item].type) {
                    itemsArray.push(items[item].type);
                }
            }
        }
        return itemsArray;
    }

    getFormattedAddress = location => {
        let address = '';
        if (location.doorNo) {
            address = `${address} ${location.doorNo}`;
        }
        if (location.landmark) {
            address = `${address} ${location.landmark}`;
        }
        return `${address} ${location.address}`;
    }

    getLineItemsOfBfs = items => items.map(item => {
        if (item.quantity) {
            return `${item.quantity} x ${item.name}`;
        }
        return item.name;
    })

    generateBfsReceiverDetails = () => {
        this.receiverDetails = {
            tableHeader: 'Receiver Details',
            name: this.packageDetails.bfs && this.packageDetails.bfs.receiver &&
                this.packageDetails.bfs.receiver.name || 'N/A',
            orderValue: this.packageDetails.bfs.orderValue || 'N/A',
            address: this.packageDetails.drop && this.getFormattedAddress(this.packageDetails.drop) || 'N/A',
            itemCategory: this.packageDetails.bfs && this.packageDetails.bfs.lineItems ?
                this.getLineItemsOfBfs(this.packageDetails.bfs.lineItems) : [],
            imageUrl: this.packageDetails.bfs.dropImages,
            orderType: this.packageDetails.orderType,
            dropInstructions: this.packageDetails.bfs && this.packageDetails.bfs.dropInstructions || null,
            mainCategory: this.packageDetails.bfs && this.packageDetails.bfs.categories ?
                this.packageDetails.bfs.categories : []
        };
    }
}
