import { OnInit } from '@angular/core';
import { ThumbnailCard } from '../types';
import { ThumbnailCardTypes } from '../types/thumnail-card.types';
var ThumbnailCardComponent = /** @class */ (function () {
    function ThumbnailCardComponent() {
        this.thumnailCardTypes = ThumbnailCardTypes;
    }
    ThumbnailCardComponent.prototype.isMinimalType = function () {
        return this.card.type === ThumbnailCardTypes.MINIMAL_SQUARE ||
            this.card.type === ThumbnailCardTypes.MINIMAL_THUMBNAIL;
    };
    Object.defineProperty(ThumbnailCardComponent.prototype, "thumbnailPartStyle", {
        get: function () {
            // console.log(this.card.config)
            // return this.card.config.thumbnailImageStyle;
            if (this.isMinimalType()) {
                var style = Object.assign(this.card.config.thumbnailImageStyle || {}, this.card.config.thumbnailPartStyle || {});
                return style;
            }
            return this.card.config.thumbnailPartStyle;
            // if (this.card.config.thumnailPart) {
            //   const style = {
            //     width: `${100 * (this.card.config.thumnailPart / 12)}%`,
            //   };
            //   return style;
            // }
            // if (this.card.config.thumbnailPartStyle) {
            //   return this.card
            // }
        },
        enumerable: true,
        configurable: true
    });
    ThumbnailCardComponent.prototype.ngOnInit = function () {
        // console.log(this.card.config.thumbnailImageStyle)
    };
    return ThumbnailCardComponent;
}());
export { ThumbnailCardComponent };
