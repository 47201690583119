import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { CaptainAdjustmentService } from './capt-adjustment.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleBasedAccessService } from '../role-based-access.service';
import { CAPTAIN_REFUND_ACCESS, CAPTAIN_DEBIT_ACCESS, CAPTAIN_UPDATE_KM,
         RIDER } from '../roles.constants';
import { AppConfigService } from '../appConfig.service';

@Component({
  selector: 'app-capt-adjustment',
  templateUrl: './capt-adjustment.component.html',
  styleUrls: ['./capt-adjustment.component.css']
})
export class CaptAdjustmentComponent implements OnInit, OnChanges {

  @Input() captainAdjDetails: any;
  @Input() distanceDetails: any;
  @Input() orderStatus: any;
  @Output() adjustmentDone: EventEmitter<any> = new EventEmitter<any>();

  orderAmount: any;
  distanceUpdated = false;
  accessRoles: any;
  roles = this.accessCookie('Roles').split(',');
  public isValidAdjustment: any;
  public refundCapReasons = [];
  public debitCapReasons = [];
  public updateKmReasons = [];
  public disableSubmit: any;
  orderId: any;
  unavailableStatuses = ['new', 'onTheWay', 'arrived', 'started'];
  statusValue = [];
  public info: any = {
    header: 'Captain Adjustment',
    headerClass: null,
    dataFetched: true,
    rows: []
  };
  public success = false;
  constructor(private toasterService: ToasterService,
              private captainAdjustmentService: CaptainAdjustmentService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              public roleBasedAccess: RoleBasedAccessService,
              private appConfigService: AppConfigService) { }

  async ngOnInit() {
    this.orderId = this.route.snapshot.params.id;
    this.accessRoles = this.accessCookie('accessRoles');
    const adjustmentConfig : any = await this.appConfigService.getAdjustmentConfigForCaptain();
    this.refundCapReasons = JSON.parse(adjustmentConfig.refundReasonsOrder);
    this.debitCapReasons = JSON.parse(adjustmentConfig.debitReasonsOrder);
    this.updateKmReasons = JSON.parse(adjustmentConfig.kmReasonsOrder);
  }

  checkStatus() {
    // Push UpdateKm if logged in user has Access for it.
    const distanceAccess = Object.keys(CAPTAIN_UPDATE_KM);
    if ((this.orderStatus && this.orderStatus === 'dropped') && (this.roles.some(distanceRole => distanceAccess.indexOf(distanceRole) >= 0))
        && this.statusValue.indexOf('Update KM') < 0) {
      this.statusValue.push('Update KM');
    }

    // Push Refund if logged in user has Access for it.
    const refundAccess = Object.keys(CAPTAIN_REFUND_ACCESS);
    if ((this.roles.some(redundRole => refundAccess.indexOf(redundRole) >= 0)) && this.statusValue.indexOf('Refund') < 0) {
      this.statusValue.push('Refund');
    }

    // Push Debit if logged in user has Access for it.
    const debitAccess = Object.keys(CAPTAIN_DEBIT_ACCESS);
    if ((this.roles.some(debitRole => debitAccess.indexOf(debitRole) >= 0)) && this.statusValue.indexOf('Debit') < 0) {
      this.statusValue.push('Debit');
    }
    this.constructData();
  }

  constructData() {
    if (this.statusValue.length > 0) {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'capt-adj-action', default: undefined, className: 'col-md-6'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: [],
            placeholder: 'Select reason', key: 'capt-adj-reason',  className: 'col-md-6'}
          ],
          [
            {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'capt-adj-amount', className: 'col-md-6'}
        ]
      ];
    } else {
      this.info = {
        header: 'Captain Adjustment',
        accessDenied: true,
        dataFetched: true
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.captainAdjDetails && changes.captainAdjDetails.currentValue ) {
      if (changes.captainAdjDetails.currentValue.deliveryOrder) {
        this.emptyCard('Not available for Delivery orders');
      } else if (changes.captainAdjDetails.currentValue.status &&
        this.unavailableStatuses.indexOf(changes.captainAdjDetails.currentValue.status) !== -1) {
          this.emptyCard('Not available for the current order status');
      } else if (!changes.captainAdjDetails.currentValue.dataFetched) {
        this.emptyCard('Cannot load adjustment');
      }
    }
    if (changes && changes.distanceDetails && changes.distanceDetails.currentValue) {
      this.distanceUpdated = changes.distanceDetails.currentValue.userUpdatedFlag;
      this.orderAmount = changes.distanceDetails.currentValue['orderAmount'] ? changes.distanceDetails.currentValue['orderAmount'] : 0;
    }
    this.checkStatus();
  }

  checkAccess(role) {
    if (this.accessRoles && this.accessRoles.indexOf(role) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  emptyCard(message?) {
    this.info = {
      header: 'Captain Adjustment',
      dataFetched: false,
      emptyMessage: message || 'No data available'
    };
  }

  changeRows(event) {
    if (event === 'Update KM') {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'capt-adj-action', default: 'Update KM', className: 'col-md-6'},
          {titleClass: 'form-title-class', title: 'Distance to update', type: 'text-input',
            placeholder: 'Enter final distance', key: 'capt-adj-distance', className: 'col-md-6'},
        ],
        [
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: this.updateKmReasons,
            placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6'}
        ]
      ];
    } else if (event === 'Others') {
      this.info['rows'][1][1] = {titleClass: 'form-title-class', title: 'Other Reason', type: 'text-input',
                                  placeholder: 'Provide an explanation', key: 'capt-adj-other-reason'};
    } else if (event === 'Refund') {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'capt-adj-action', default: event, className: 'col-md-6'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
            values: this.refundCapReasons,
            placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6'}
          ],
          [
            {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'capt-adj-amount', className: 'col-md-6'}
        ]
      ];
    } else if (event === 'Debit') {
      this.info['rows'] = [
        [
          {titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
            placeholder: 'Select status', key: 'capt-adj-action', default: event, className: 'col-md-6'},
          {titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
            values: this.debitCapReasons,
            placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6'}
          ],
          [
            {titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'capt-adj-amount', className: 'col-md-6'}
        ]
      ];
    } else {
      if (this.info['rows'][1].length > 1) {
        this.info['rows'][1].splice(this.info['rows'][1].length - 1, 1);
      }
    }
  }

  async peformAdjustment(event) {
    const userDetails = JSON.parse(this.accessCookie('user'));
    let confirmDialogRef;

    if (!event['capt-adj-action']) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please select action`,
      }));
      return;
    }
    if (!event['capt-adj-reason']) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter reason`,
      }));
      return;
    }
    if ((event['capt-adj-action'] === 'Refund' || event['capt-adj-action'] === 'Debit') && !event['capt-adj-amount']) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter valid amount`,
      }));
      return;
    }
    // if (event['capt-adj-action'] !== 'Update km' && !this.orderAmount) {
    //   this.toasterService.showToaster(new Toaster({
    //     type: ToasterType.WARNING,
    //     message: `No order amount found for this order. Contact support.`,
    //   }));
    //   return;
    // }

    // validation for Update Km
    if (event['capt-adj-action'] === 'Update KM' && (!event['capt-adj-distance'] || event['capt-adj-distance'] <= 0)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter valid distance`,
      }));
      return;
    }

    // Others Validation
    if (event['capt-adj-reason'] === 'Others' && !event['capt-adj-other-reason']) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter reason`,
      }));
      return;
    }

    // validation for Refund and debit
    if ((event['capt-adj-action'] === 'Refund' || event['capt-adj-action'] === 'Debit') && event['capt-adj-amount'] < 0) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter amount greater than 0`,
      }));
      return;
    }
    const maxAdjAmount = await this.roleBasedAccess.performAdjustment(event['capt-adj-action'], RIDER);
    let isAdmin; // admin if max distance allowed is greater than 1 else NotAdmin
    let message = '', amountEntered;
    if (event['capt-adj-action'] === 'Update KM') {
      message = ' adjust max of ' + maxAdjAmount + ' Km';
      amountEntered = event['capt-adj-distance'];
    } else {
      message = event['capt-adj-action'] + ' max Rs. ' + maxAdjAmount;
      amountEntered = event['capt-adj-amount'];
    }
    if (event['capt-adj-action'] === 'Update KM') {
      if ((Math.abs(parseInt(amountEntered, 10) - this.captainAdjDetails.finalDistance) > maxAdjAmount)) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'You are allowed to  ' + message  + '. Please enter less than ' + maxAdjAmount
        }));
        return;
      }
    } else {
      if (maxAdjAmount > 1 && (parseInt(amountEntered, 10) > maxAdjAmount)) {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'You are allowed to  ' + message  + '. Please enter less than ' + maxAdjAmount
        }));
        return;
      }
    }
    if (!(parseFloat(amountEntered) == amountEntered)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: 'Please enter a valid number'
      }));
      return;
    }

    if (event['capt-adj-action'] === 'Debit') {
      event['capt-adj-amount'] = -Math.abs(event['capt-adj-amount']);
    }

    let reason = '';
    if (event['capt-adj-reason'] === 'Others') {
      reason = 'Others : ' + event['capt-adj-other-reason'];
    } else {
      reason = event['capt-adj-reason'];
    }

    if ((event['capt-adj-action'] === 'Refund' && event['capt-adj-amount'] > maxAdjAmount)) {
      this.toasterService.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: `Please enter amount lesser than or equal to ${maxAdjAmount}`,
      }));
      return;
    }

    // call api
    if (event['capt-adj-action'] === 'Refund' || event['capt-adj-action'] === 'Debit') {
      let adjustmentType = 'refunded', role = '';
      if (this.roles.includes('CCC Admin')) {
        role = 'CCC Admin';
      }else if (this.roles.includes('Captain care centre agents')) {
        role = 'Captain care centre agents';
      }
      
      const adjustmentObj = {
        orderDate: this.captainAdjDetails.orderDate || '',
        riderId: this.captainAdjDetails.rider || '',
        city: this.captainAdjDetails.city && this.captainAdjDetails.city._id || '',
        uniqueId: this.captainAdjDetails.uniqueId || '',
        remarks: reason,
        adjustedBy: userDetails['emailId'],
        adjustedById: userDetails['_id'],
        amount: Number(event['capt-adj-amount']),
        orderId: this.orderId,
        mobile: this.captainAdjDetails.riderObj.mobile || '',
        name: this.captainAdjDetails.riderObj.name || '',
        serviceDetail: this.captainAdjDetails.serviceDetail,
        serviceId: this.captainAdjDetails.serviceId,
        service: this.captainAdjDetails.service
      };

      confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '335px',
        data: { title: 'Are you sure you want to  ' + event['capt-adj-action'] + ' ?',
                type: 'order-details',
                reason: event['capt-adj-action'],
                message: '',
                buttonText: 'Yes, ' + event['capt-adj-action']
              }
      });

      confirmDialogRef.afterClosed().subscribe(confirmResult => {
        if (confirmResult) {
           this.disableSubmit = true;
          if(event['capt-adj-action'] === 'Refund'){
            this.captainAdjustmentService.captainRefundDetails(this.orderId, adjustmentObj, role).subscribe(response => {
              if (response && response['info'] && response['info'].status === 'success') {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.SUCCESS,
                  message: `Amount ` +  adjustmentType + ` successfully`
                }));
                this.success = true;
                this.disableSubmit = false;
                this.checkStatus();
                this.adjustmentDone.emit();
              }
            }, err => {
              this.disableSubmit = false;
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: err && err.error && err.error.message || `Unable to ` + event['capt-adj-action'] + ` amount`
              }));
            });
          } else {
            this.captainAdjustmentService.captainAdjustmentDetails(this.orderId, adjustmentObj).subscribe(response => {
              adjustmentType = 'debited';
              if (response && response['info'] && response['info'].status === 'success') {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.SUCCESS,
                  message: `Amount ` +  adjustmentType + ` successfully`
                }));
                this.success = true;
                this.disableSubmit = false;
                this.checkStatus();
                this.adjustmentDone.emit();
              }
            }, err => {
              this.disableSubmit = false;
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to ` + event['capt-adj-action'] + ` amount`
              }));
            });
          } 
        } else {
          event['capt-adj-amount'] = Math.abs(event['capt-adj-amount']);
        }
      });
    } else { // update km
      if (maxAdjAmount >= 1) {
        isAdmin = true;
      } else {
        isAdmin = false;
      }
      const updateDistanceObj = {
        userRoles: this.roles,
        isAdmin: isAdmin,
        userUpdatedDistance: parseFloat(event['capt-adj-distance']),
        reason: reason,
        adjustedBy: userDetails['emailId']
      };
      confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '335px',
        data: { title: 'Are you sure you want to  update ?',
                type: 'order-details',
                reason: event['capt-adj-action'],
                message: '',
                buttonText: 'Yes, update'
              }
      });
      confirmDialogRef.afterClosed().subscribe(confirmResult => {
        if (confirmResult) {
          this.captainAdjustmentService.setUserDistance(this.orderId, updateDistanceObj).subscribe(response => {
            if (response && response['info'] && response['info'].status === 'success') {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: `Distance updated successfully`
              }));
              this.success = true;
              this.checkStatus();
              this.adjustmentDone.emit();
            } else {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: response['data'] ? response['data'] : 'Unable to update km'
              }));
            }
          }, err => {
            let errorMessage = '';
            if (err) {
              if (err['error'] && err['error'].info && err['error'].info.message) {
                errorMessage = err['error'].info.message;
              }
              if (err['error'] && err['error'].data) {
                errorMessage = err['error'].data;
              }
            }
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.WARNING,
              message: errorMessage ?  errorMessage : `Unable to update km`
            }));
          });
        }
      });
    }
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }

}
