import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TimeLineService  {
    abortUrl = environment.server + '/api/abort';
    fraudUrl = environment.server + '/api/fraudEngine';
    changeStatusUrl = environment.server + '/api/orders';
    orderActivityLog = `${environment.server}/api/eventLogs/orderActivity/logs`;
    eventLogsUrl = `${environment.server}/api/activityEvent`;

    abortOrder(abortObj) {
        const requestBody = {
            abortObj: abortObj
        };
        return this.http.post(this.abortUrl + '/abortOrder', requestBody).pipe(catchError(this.handleError));
    }

    fetchTags(orderId) {
      return this.http.get(this.fraudUrl + '/order-fraud-tags/' + orderId).pipe(catchError(this.handleError));
    }

    reAssignOrder(orderId, reAssignObj) {
      const requestBody = {
        reAssignObj: reAssignObj
      };
      return this.http.post(this.abortUrl + '/re-assign/' + orderId, requestBody).pipe(catchError(this.handleError));
    }

    pushOrderActivityEventLogs(reqObject) {
      return this.http.post(this.eventLogsUrl, reqObject).pipe(catchError(this.handleError));
    }

    // changeStatus(orderId, riderId, requestBody, status) {
    //   return this.http.post(this.changeStatusUrl + `/${orderId}/${riderId}/${status}`, requestBody).pipe(catchError(this.handleError));
    // }

    changeStatus(orderId, riderId, requestBody, status) {
      return this.http.post(
        this.changeStatusUrl + `/${orderId}/${riderId}/${status}`, requestBody
      ).pipe(catchError(this.handleError));
    }

    fetchOrderActivityLog = (orderId: any, page: Number, perPage: Number) => {
        return this.http.get(`${this.orderActivityLog}/${orderId}/${page}/${perPage}`).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(error);
    }
    constructor(private http: MyHttpClient) { }
  }
