import {Component, Input, OnInit} from '@angular/core';
import {HorizontalList} from '../../shared/horizontal-list/horizontal-list.component';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TableData, TableDataTypes} from '../../shared/types';
import {PerformanceCard} from '../../shared/types/card.types';
import {environment} from '../../../environments/environment';
import {HelperService} from '../../helper.service';
import {DateFilterInput, FilterService, QuickFilters } from '../../filter.service';

const currentDate = new Date();
const monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
function padStart(length, data) {
    return data.toString().padStart(length, 0);
}
const currentDateString = `${currentDate.getFullYear()}-${padStart(2, currentDate.getMonth() + 1)}-${padStart(2, currentDate.getDate())}`;
// tslint:disable-next-line:max-line-length
const monthStartDateString = `${monthStartDate.getFullYear()}-${padStart(2, monthStartDate.getMonth() + 1)}-${padStart(2, monthStartDate.getDate())}`;
const defaultFilters = () => {
    return {
        fromDate: new Date(monthStartDate),
        duration: [monthStartDateString, currentDateString],
        toDate: new Date(currentDate),
        startDuration: new Date(monthStartDate),
        endDuration: new Date(currentDate),
        status: 'dropped',
    };
};

@Component({
  selector: 'app-captain-performance-data',
  templateUrl: './captain-performance-data.component.html',
  styleUrls: ['./captain-performance-data.component.css']
})
export class CaptainPerformanceDataComponent implements OnInit {

  constructor(public httpClient: HttpClient, private filterService: FilterService) {
  }

  dataLoaded: boolean;
  @Input() riderId: string;
  horizontalList: HorizontalList;
  appliedFilters = defaultFilters();
  filterCount = 0;
  fromDate;
  toDate;

  ngOnInit() {
    this.fromDate = this.getFormattedDate(this.appliedFilters.fromDate);
    this.toDate = this.getFormattedDate(this.appliedFilters.toDate);
    this.dataLoaded = false;
    if (this.horizontalList && this.horizontalList.table) {
      this.makeTableNull();
    }
    const riderId = this.riderId;
    const baseUrl = `${environment.server}/api/svo/captain`;
    const queries = `${riderId}?startDate=${this.fromDate}&endDate=${this.toDate}`;
    const aprDprUrl = `${baseUrl}/aprdpr/${queries}`;
    const ciccUrl = `${baseUrl}/cicc/${queries}`;
    const ltrUrl = `${baseUrl}/rides/${riderId}`;
    Promise.all([
        this.httpClient.get(aprDprUrl).toPromise(),
        this.httpClient.get(ciccUrl).toPromise(),
        this.httpClient.get(ltrUrl).toPromise()
    ])
        .then((res: any) => {
        const aprdpr = res[0];
        const cicc = res[1];
        const ltrData = res[2];
        const aprdprFound = aprdpr.data.found;
        const ciccFound = cicc.data.found;
        const apr = aprdprFound ? (aprdpr.data.data.apr).toFixed(2) : 'N/A';
        const dpr = aprdprFound ? (aprdpr.data.data.dpr).toFixed(2) : 'N/A';
        const ccr = ciccFound ? (cicc.data.ccr).toFixed(2) : 'N/A';
        const trueDPR = ciccFound ? (cicc.data.trueDPR).toFixed(2) : 'N/A';
        const ltrFound = ltrData.data.found;
        const ltr = ltrFound ? (ltrData.data.rides).toFixed() : 'N/A';
        this.defineList(apr, dpr, ccr, trueDPR, ltr);
    }).catch(error => {
        console.error(error);
        this.defineList('N/A', 'N/A', 'N/A', 'N/A', 'N/A');
    });

  }

  getFormattedBackDate(daysAgo) {
    if (daysAgo < 0) {
      throw new Error('daysAgo can\'t be negative');
    }
    const dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - daysAgo);
    return this.getFormattedDate(dateObj);
  }

  getFormattedDate(dateObj) {
    const date = dateObj.getDate();
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();
    return `${year}-${month + 1}-${date}`;
  }

  private makeTableNull() {
    this.horizontalList.table = null;
    this.horizontalList = null;
  }

  defineList(apr, dpr, ccr, trueDPR, ltr) {
      this.dataLoaded = true;
      const commonAttrs = {
          style: {
              'color': 'black',
              'font-size': '26px !imporatant'
          }
      };
      const data = [
          {
              label: 'APR',
              value: {
                  toString: () => {
                      return `${apr}`;
                  },
                  ...commonAttrs,
              },
              type: TableDataTypes.PERFORMANCE_CARD
          },
          {
              label: 'DPR',
              value: {
                  toString: () => {
                      return `${dpr}`;
                  },
                  ...commonAttrs,
              },
              type: TableDataTypes.PERFORMANCE_CARD
          },
          {
              label: 'True DPR',
              value: {
                  toString: () => {
                      return `${trueDPR}`;
                  },
                  ...commonAttrs,
              },
              type: TableDataTypes.PERFORMANCE_CARD
          },
          {
              label: 'LTR',
              value: {
                  toString: () => {
                      return `${ltr}`;
                  },
                  ...commonAttrs,
              },
              type: TableDataTypes.PERFORMANCE_CARD
          }
      ];
      const dataTable = [data, []];
      const tds = dataTable.map(records => {
          return records.map(record => {
              return new TableData({
                  type: record.type,
                  performanceCard: new PerformanceCard({
                      label: record.label,
                      value: record.value,
                      style: {
                          height: '127px',
                          width: '176px'
                      }
                  })
              });
          });
      });
      this.horizontalList = new HorizontalList({
          header: {
              toString: () => {
                  const prefix = 'Captain Performance Data ';
                  let startDate, endDate;
                  if (this.appliedFilters.duration && this.appliedFilters.duration instanceof Array) {
                      startDate = this.appliedFilters.duration[0];
                      endDate = this.appliedFilters.duration[1];
                  } else {
                      startDate = this.appliedFilters.duration['start'];
                      endDate = this.appliedFilters.duration['end'];
                  }

                  if (startDate === endDate)  {
                      return `${prefix}Today, ${HelperService.dateString(new Date())}`;
                  } else {
                      // tslint:disable-next-line:max-line-length
                      return `${prefix}${HelperService.dateString(this.appliedFilters.fromDate)} - ${HelperService.dateString(this.appliedFilters.toDate)}`;
                  }
              }
          },
          tableData: tds,
          filterCount: this.filterCount,
          config: {
              refresh: true,
              filter: true,
          },
          events: {
              onRefresh: () => {
                  this.ngOnInit();
              },
              onFilter: () => {
                  this.filterCount = 0;
                  this.horizontalList.table.appliedFilters = this.filterService.appliedFilters;
                  const duration = DateFilterInput.parseValue(this.filterService.appliedFilters.duration);
                  if (duration.startDuration || duration.endDuration) {
                      this.filterCount++;
                  }
                  let appliedFilters: any = {};
                  if (duration.startDuration && duration.endDuration) {
                      const stringDuration = this.filterService.appliedFilters.duration;
                      appliedFilters = {
                          duration: {
                              start: stringDuration[0],
                              end: stringDuration[1],
                          },
                          fromDate: duration.startDuration,
                          toDate: duration.endDuration,
                          startDuration: duration.startDuration,
                          endDuration: duration.endDuration,
                      };
                      if (stringDuration instanceof Array) {
                          appliedFilters.duration = {
                              start: stringDuration[0],
                              end: stringDuration[1],
                          };
                      } else {
                          appliedFilters.duration = {
                              start: stringDuration.start,
                              end: stringDuration.end,
                          };
                      }
                  } else {
                      appliedFilters = defaultFilters();
                  }
                  Object.assign(this.appliedFilters, appliedFilters);
                  this.filterService.filterDefaultValueChange.next(appliedFilters);
                  this.ngOnInit();
              }},
              filterComponents: [
                  new QuickFilters({
                      key: 'duration',
                      default: '',
                      title: 'Duration',
                  })
              ],
              appliedFilters: HelperService.copyObj(this.appliedFilters),
      });
  }



}
