<div class  = "invoice-wrapper">
    <div class = "header-wrap">
        <div>
            <span class = "invoice-header">Invoices Details</span>
        </div>
        <div class = "search-wrapper">
            <div class = "input-wrapper">
                <input
                    [(ngModel)] = "email"
                    class = "search-email-input"
                    type = "text"
                    placeholder = "Enter customer email address"
                    autofocus
                    (keyup.enter)="sendInvoice()" 
                />
            </div>
            <div class = "send-btn-wrapper">
                <button class = "send-invoice-btn" (click)="sendInvoice()">Send Invoice</button>
            </div>
        </div>
    </div>

    <div class = "invoice-content-wrap">
        <div class = "error-message" *ngIf="errorMessage">{{errorMessage}}</div>
        <div *ngIf="paymentSummary" class = "invoice-content payment-summary"> 
            <app-payment-summary [data] = "paymentSummary"></app-payment-summary>
        </div>
        <div *ngIf="billOfSupply" class = "invoice-content bill-of-supply"> 
            <app-bill-of-supply [data] = "billOfSupply"></app-bill-of-supply>
        </div>
        <div *ngIf="taxInvoice" class = "invoice-content tax-invoice"> 
            <app-tax-invoice [data] = "taxInvoice"></app-tax-invoice>
        </div>
    </div>
</div>
