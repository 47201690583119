/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/toolbar/typings/index.ngfactory";
import * as i2 from "@angular/material/toolbar";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "./signin.service";
import * as i12 from "@angular/common/http";
import * as i13 from "../toaster.service";
import * as i14 from "./signin.component";
import * as i15 from "../auth.service";
import * as i16 from "@angular/router";
import * as i17 from "../loader.service";
import * as i18 from "../data.service";
var styles_SigninComponent = [];
var RenderType_SigninComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SigninComponent, data: {} });
export { RenderType_SigninComponent as RenderType_SigninComponent };
function View_SigninComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Your internet connection is slow. "]))], null, null); }
function View_SigninComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Your're offline. Check your Internet connection. "]))], null, null); }
export function View_SigninComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "mat-toolbar", [["class", "mat-toolbar"]], [[2, "mat-toolbar-multiple-rows", null], [2, "mat-toolbar-single-row", null]], null, null, i1.View_MatToolbar_0, i1.RenderType_MatToolbar)), i0.ɵdid(1, 4243456, null, 1, i2.MatToolbar, [i0.ElementRef, i3.Platform, i4.DOCUMENT], null, null), i0.ɵqud(603979776, 1, { _toolbarRows: 1 }), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "img", [["alt", ""], ["class", "d-inline-block align-top"], ["height", "30"], ["src", "../../assets/Rapido.png"], ["width", "30"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "span", [["class", "rapido-form-field"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Rapido"])), (_l()(), i0.ɵeld(6, 0, null, null, 12, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 11, "div", [["class", "secondary-container shadow-all"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SigninComponent_1)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SigninComponent_2)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 6, "button", [["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.socialSignIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i6.MatButton, [i0.ElementRef, i3.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(14, 0, null, 0, 1, "span", [["style", "padding:10px;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sign in with Google"])), (_l()(), i0.ɵeld(16, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i0.ɵdid(17, 9158656, null, 0, i10.MatIcon, [i0.ElementRef, i10.MatIconRegistry, [8, null], [2, i10.MAT_ICON_LOCATION]], null, null), (_l()(), i0.ɵted(-1, 0, ["https"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.slowInternet; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.noInternet; _ck(_v, 11, 0, currVal_3); var currVal_6 = "accent"; _ck(_v, 13, 0, currVal_6); _ck(_v, 17, 0); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 1)._toolbarRows.length > 0); var currVal_1 = (i0.ɵnov(_v, 1)._toolbarRows.length === 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_7 = i0.ɵnov(_v, 17).inline; var currVal_8 = (((i0.ɵnov(_v, 17).color !== "primary") && (i0.ɵnov(_v, 17).color !== "accent")) && (i0.ɵnov(_v, 17).color !== "warn")); _ck(_v, 16, 0, currVal_7, currVal_8); }); }
export function View_SigninComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-signin", [], null, null, null, View_SigninComponent_0, RenderType_SigninComponent)), i0.ɵprd(512, null, i11.SigninService, i11.SigninService, [i12.HttpClient]), i0.ɵprd(512, null, i13.ToasterService, i13.ToasterService, []), i0.ɵdid(3, 114688, null, 0, i14.SigninComponent, [i11.SigninService, i15.AuthService, i16.Router, i13.ToasterService, i17.LoaderService, i18.DataService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var SigninComponentNgFactory = i0.ɵccf("app-signin", i14.SigninComponent, View_SigninComponent_Host_0, {}, {}, []);
export { SigninComponentNgFactory as SigninComponentNgFactory };
