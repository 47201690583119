var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HelperService } from './helper.service';
var MyHttpClient = /** @class */ (function () {
    function MyHttpClient(http) {
        this.http = http;
        this._headers = new HttpHeaders().set('Content-Type', 'application/json');
    }
    MyHttpClient.prototype.setHeaderData = function () {
        //populates data for headers with latest cookies
        this.cookies = document.cookie.split(';');
        if (this.cookies) {
            this.userCookie = HelperService.accessCookie('user');
            // this.userCookie = this.cookies.find(function (item) {
            //   return item.includes('_id');
            // })
        }
        if (this.userCookie) {
            this.userIdCookie = Object.values(JSON.parse(this.userCookie))[0];
        }
        if (this.userIdCookie) {
            this.userId = '{"_id":"' + this.userIdCookie + '"}';
        }
    };
    MyHttpClient.prototype.get = function (url) {
        this.setHeaderData();
        var headers = this._headers
            .append('user', this.userId ? this.userId : '')
            .append('authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzliMjAxMGQ3OTRiOTMwMzdlMmUyNDciLCJpYXQiOjE1NTQ5NjU3NDgsImV4cCI6MTU1NDk5NDU0OH0.YYghR3VNuY2hYLNeHe6nKA4odSFvMMYDUcvKJ97A_gI')
            .append('Expires', '0')
            .append('Cache-control', 'no-cache')
            .append('Cache-control', 'no-store')
            .append('Pragma', 'no-cache');
        return this.http.get(url, { headers: headers });
    };
    MyHttpClient.prototype.post = function (url, data, config) {
        if (config === void 0) { config = {}; }
        this.setHeaderData();
        var headers = this._headers
            .append('user', this.userId ? this.userId : '')
            .append('Expires', '0')
            .append('Cache-control', 'no-cache')
            .append('Cache-control', 'no-store')
            .append('Pragma', 'no-cache');
        return this.http.post(url, data, __assign({ headers: headers }, config));
    };
    return MyHttpClient;
}());
export { MyHttpClient };
