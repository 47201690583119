<h1 mat-dialog-title class="dialog-title text-align-centre" [ngStyle]="{color: (data.reason === 'abort' || data.reason === 'Debit')? '#cd1f1e' : '#d88b0a'}">{{data.title}}</h1>
<div *ngIf="data.type != 'revoke'" class="text-align-centre">
  <mat-label class="font-size-15">{{data.message}}</mat-label>
</div>

<div *ngIf="data.type == 'revoke'" class="text-align-centre">
    <p class="font-weight-bold">Reason for Revoking </p>
    <mat-label class="font-size-15">{{data.reason}}</mat-label>
</div>

<div *ngIf="data.type == 'p0Suspension'" >
  <p class="font-size-15" ><b>Service Group:</b> {{data.serviceGroup}}</p>
  <p class="font-size-15"><b>Suspension Reason:</b> {{data.reason}}</p>
  <p class="font-size-15"><b>Suspension Action:</b> {{data.suspensionAction}}</p>
</div>

<div *ngIf="data.type == 'nonP0Suspension'" >
  <p class="font-size-15" ><b>Service Group:</b> {{data.serviceGroup}}</p>
  <p class="font-size-15"><b>Suspension Reason:</b> {{data.reason}}</p>
  <p class="font-size-15"><b>Suspension Duration:</b> {{data.suspensionDuration}}</p>
</div>

<div mat-dialog-actions class="button-flex padding-17-0 ">
    <button class="dialog-cancel" (click)="onNoClick()">Cancel</button>
    <button class="font-size-13 font-weight-600 border-radius-3 color-ffffff confirm ml-3" (click)="abortOrder()"
      [ngStyle]="{'background-color': (data.reason === 'abort' || data.reason ===  'Debit') ? '#d0021b' : (data.type === 'p0Suspension' || data.type === 'nonP0Suspension')? '#467fcf' :'#64b20b',
      'border': (data.reason === 'abort' || data.reason ===  'Debit') ? '1px solid #d0021b': (data.type === 'p0Suspension' || data.type === 'nonP0Suspension')? '#467fcf' :'solid 1px #64b20b'}">{{data.buttonText}}</button>
</div>
