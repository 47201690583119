<div>
  <!-- <div class="padding-sides-10"> -->
  <div class="boxed-border width-100">
    <div class="row">
      <div class="col">
        <div *ngIf="table.tableHeader" class="bigger-title">{{table.tableHeader}}</div>
        <div *ngIf="table.toggleableHeaders" class="bigger-title row">
          <div *ngFor="let header of table.toggleableHeaders; let i = index">
            <span (click)="toggleChange(header)" class="toggle-headers" [ngClass]="{'currently-selected-header': i == table.selectedHeader}">
              {{header}}
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="pull-right row padding-10 margin-right-12">
            <fa-icon class="cursor-pointer margin-icons" (click)="onRefresh()" [icon]="faRefresh"></fa-icon>
            <div *ngIf="!filterNotRequired" class="cursor-pointer filter-button" (click)="onFilter()">
              <span class="is-flex margin-sides-5">
                <mat-icon class="filter-icon">filter_list</mat-icon>Filters
              </span>
            </div>
            <div class="filter-applied" *ngIf="table && table.filterCount && table.filterCount > 0">
              <span class="filter">{{table.filterCount}}</span>
            </div>
        </div>
      </div>
    </div>
    <div class="line" *ngIf="table.data.length == 0"></div>
    <table *ngIf=" table.data.length > 0" mat-table  [dataSource]="table.data" class="boxed-border width-100 mat-elevation-z8" multiTemplateDataRows>
      <ng-container *ngFor="let colKey of displayedColumns" [matColumnDef]="colKey">
        <th class="table-overflow center-align padding-30" mat-header-cell *matHeaderCellDef> {{table.headers[colKey]}}
        </th>
        <td pTooltip="{{element[colKey] && element[colKey]?.type != TableDataTypes.ACTION && !element[colKey]?.stopHover ? (element[colKey].hoverData || element[colKey].data)  : ''}}" [ngClass]="element[colKey]?.className"
          (click)="callFnc(i, element[colKey]?.onClick)" tooltipPosition="bottom"
          class="center-align text-overflow-table padding-5"
             mat-cell *matCellDef="let element; let i=dataIndex">
          <ng-container *ngIf="element[colKey]?.type == TableDataTypes.DATA" (click)="callFnc(i, element[colKey]?.onClick)">
            <span class="icon-alignment revoke-info" pTooltip="{{element[colKey]?.information}}" tooltipPosition="bottom">
              <i *ngIf="element[colKey]?.information" class="material-icons-outlined card-info cursor-pointer" style="font-size: 18px; margin-right: 10px;">
                info
              </i>
            </span>
            <span [ngClass]="{'center-icon' : table.headers[colKey] === 'Ratings'}">
              <mat-icon *ngIf="table.headers[colKey] === 'Ratings' && element[colKey].data !== ' - '" class="yellow-star">star_rate</mat-icon>
              {{element[colKey].data || ' - '}}
            </span>
            <div *ngIf="element[colKey]?.flag == true" class="slanted-div">
              <span class="fraud">Fraud</span>
            </div>
            <div *ngIf="element[colKey]?.suspendFlag == true" class="slanted-div2">
              <span class="suspend">Suspended</span>
            </div>
          </ng-container>

          <ng-container *ngIf="element[colKey]?.type == TableDataTypes.BUTTON">
            <button [class]="element[colKey]?.button?.className" (click)="doAction(i, element[colKey]?.button?.onClick)">{{element[colKey]?.button?.label}}</button>
          </ng-container>
          <div *ngIf="element[colKey]?.type == TableDataTypes.ACTION" class="row">
            <div [ngClass]="element[colKey]?.spanClass" class="offset-md-3 col-md-2">
              <button class="reinit-css" [ngClass]="element[colKey]?.className" mat-icon-button>
                <span class="center-flex">
                  <mat-icon *ngIf="element[colKey]?.showIcon" [ngClass]="element[colKey]?.iconClass">{{element[colKey]?.showIcon}}</mat-icon>  
                  {{element[colKey].data}}
                </span>
              </button>
            </div>
            <div class="icon-alignment revoke-info" pTooltip="{{element[colKey]?.information}}" tooltipPosition="bottom">
              <i *ngIf="element[colKey]?.information" class="material-icons-outlined card-info cursor-pointer" style="font-size: 18px;">
                info
              </i>
            </div>
          </div>
          </td>
        </ng-container>
        <ng-container *ngIf="isCustomerComplaintsTable" matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="innerTableDisplayedColumns.length">
            <div class="example-element-detail"
                 [@detailExpand]="element ==  expandedElement ? 'expanded' : 'collapsed'">
                 <table mat-table [dataSource]="getInnerTableDataSource(element)" class="mat-elevation-z8">

                  <ng-container *ngFor="let columnKey of innerTableDisplayedColumns" [matColumnDef]="columnKey">
                    <th mat-header-cell *matHeaderCellDef class="table-overflow center-align no-bottom-border" [ngClass]="columnKey == 'reason' ? 'padding-right-40' : 'padding-30'"  style="visibility: hidden;"> {{table.headers[columnKey]}} </th>
                    <td mat-cell *matCellDef="let element" class="center-align text-overflow-table" [ngClass]="columnKey == 'reason' ? 'padding-right-40' : 'padding-5'" > {{element[columnKey]}}</td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="innerTableDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: innerTableDisplayedColumns;"></tr>
                </table>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="isCustomerComplaintsTable">
          <tr mat-header-row class="preserve-whitespace" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="changeOnHover example-element-row"
          [class.example-expanded-row]="expandedElement === element  ? 'expanded' : 'collapsed'"
          (click)="expandedElement = expandedElement === element ? null : element"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </ng-container>
        
        <ng-container *ngIf="!isCustomerComplaintsTable">
        <tr mat-header-row class="preserve-whitespace" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="changeOnHover"></tr>
        </ng-container>

      </table>
      <mat-paginator *ngIf="table.data.length > 0 && table.pagination.enable" [pageSize]="table.pagination.perPage" [pageSizeOptions]="table.pagination.pageSizeOptions" [length]="table.pagination.count" [pageIndex]="table.pagination.pageNo" (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
      <div *ngIf="table.data.length === 0" class="height-250 padding-top-90 center-content is-flex color-red">
        <p *ngIf="!table.defaultError">
          No orders found for this user
        </p>
        <p *ngIf="table.defaultError">
          {{table.defaultError}}
        </p>
      </div>
    </div>
</div>
