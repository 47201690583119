var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { CaptainRedeemService } from 'src/app/captain-redeem-service';
var CaptainAccessFeeDetails = /** @class */ (function () {
    function CaptainAccessFeeDetails(redeemService) {
        this.redeemService = redeemService;
        this.toggleHeaders = new EventEmitter();
        this.dataLoaded = false;
        this.perPage = 10;
        this.firstElement = "";
        this.lastElement = "";
        this.next = "";
        this.prev = "";
        this.statusList = ["success", "failed", "pending", "initiated"];
        this.transactionTypes = ["platformAccessFee"];
    }
    CaptainAccessFeeDetails.prototype.ngOnInit = function () {
        this.declareTable();
    };
    CaptainAccessFeeDetails.prototype.declareTable = function () {
        var _this = this;
        this.captainAccessFeeTransactions = new Table({
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            headers: {
                NumberOfRides: 'Rides and Date Info',
                DeductionStatus: 'Deduction Status',
                TransactionDate: 'Transaction Date',
                DeductionAmount: 'Deduction Amoount ',
                Source: 'Source',
                transactionId: 'Transaction Id'
            },
            pagination: new Pagination(0, this.perPage, 30, [10, 15, 20], 0, true),
            selectedHeader: 11,
            defaultError: 'No access fee transactions found',
            onRefresh: function () {
                _this.captainAccessFeeTransactions = null;
                _this.ngOnInit();
            },
            data: []
        });
        this.getAccessFeeTransactions();
    };
    CaptainAccessFeeDetails.prototype.getAccessFeeTransactions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            var _this = this;
            return __generator(this, function (_a) {
                data = {
                    "statuses": this.statusList,
                    "transactionType": this.transactionTypes
                };
                this.dataLoaded = false;
                this.redeemService.getWalletCaptainPassbookFromWallet(this.captainId, data, this.perPage, this.next, this.prev)
                    .then(function (response) {
                    var transactionArray = response && response.data;
                    _this.captainAccessFeeTransactions.data = transactionArray.map(function (transaction) {
                        _this.firstElement = transactionArray[0].id;
                        _this.lastElement = transactionArray[transactionArray.length - 1].id;
                        return {
                            NumberOfRides: _this.createTableData(transaction.remark),
                            DeductionStatus: _this.createTableData(transaction.status),
                            TransactionDate: _this.createTableData(transaction.date),
                            DeductionAmount: _this.createTableData(transaction.amount),
                            Source: _this.createTableData(transaction.paymentMode),
                            transactionId: _this.createTableData(transaction.id),
                        };
                    });
                    _this.dataLoaded = true;
                }).catch(function (err) {
                    console.log(err);
                    _this.dataLoaded = true;
                });
                return [2 /*return*/];
            });
        });
    };
    CaptainAccessFeeDetails.prototype.dateString = function (epoch) {
        return new Date(epoch * 1000).toLocaleString();
    };
    CaptainAccessFeeDetails.prototype.createTableData = function (value, className, type, flag, hoverData) {
        if (flag === void 0) { flag = false; }
        if (hoverData === void 0) { hoverData = null; }
        return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
        });
    };
    CaptainAccessFeeDetails.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CaptainAccessFeeDetails.prototype.onPaginationChange = function (event) {
        if (event.pageNo < event.previousPageIndex) {
            this.next = "";
            this.prev = this.firstElement;
        }
        else if (event.pageNo > event.previousPageIndex) {
            this.prev = "";
            this.next = this.lastElement;
        }
        this.getAccessFeeTransactions();
    };
    return CaptainAccessFeeDetails;
}());
export { CaptainAccessFeeDetails };
