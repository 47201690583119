import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Table, TableDataTypes } from '../types';
import { FilterService } from 'src/app/filter.service';
import { faRedo, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
var STRING = {
    EMPTY: '',
    FUNCTION: 'function',
};
var TableComponent = /** @class */ (function () {
    function TableComponent(filterService) {
        this.filterService = filterService;
        this.TableDataTypes = TableDataTypes;
        this.paginationChange = new EventEmitter();
        this.showCUSelectors = new EventEmitter();
        this.showChips = new EventEmitter();
        this.headers = [];
        this.faRefresh = faRedo;
        this.faEllipsis = faEllipsisV;
        // this.defineFilters();
    }
    Object.defineProperty(TableComponent.prototype, "trClass", {
        get: function () {
            if (this.table && this.table.config && this.table.config.noColorOnTdOnHover) {
                return "table-hover-without-color";
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    TableComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.table) {
            this.ngOnInit();
        }
    };
    TableComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.table) {
            return;
        }
        this.displayedColumns = Object.keys(this.table.headers);
        this.headers = this.displayedColumns.map(function (col) { return _this.table.headers[col]; });
    };
    TableComponent.prototype.toInt = function (data) {
        return parseInt(data, 10);
    };
    TableComponent.prototype.doAction = function (index, cb) {
        if (cb && typeof cb === STRING.FUNCTION) {
            cb(this.table.realData[index]);
        }
    };
    TableComponent.prototype.debounceSearch = function () {
    };
    TableComponent.prototype.onSearch = function (searchString) {
        this.table.emitSearch(searchString);
    };
    TableComponent.prototype.onRefresh = function () {
        this.table.emitRefresh();
    };
    TableComponent.prototype.viewCrossUtilizationSelectors = function () {
        this.showCUSelectors.emit();
    };
    TableComponent.prototype.viewMoreChips = function (header) {
        this.showChips.emit(header);
    };
    TableComponent.prototype.assignAppliedFilters = function (newFilter) {
        var _this = this;
        if (newFilter === void 0) { newFilter = {}; }
        Object.keys(this.filterService.appliedFilters).forEach(function (key) {
            delete _this.filterService.appliedFilters[key];
        });
        this.filterService.filterValueChange.next(newFilter);
        Object.assign(this.filterService.appliedFilters, newFilter);
    };
    TableComponent.prototype.onFilter = function () {
        var _this = this;
        this.assignAppliedFilters(this.table.appliedFilters);
        this.filterService.filterDataChange.next(this.table.filterComponents);
        this.filterService.defineFilters(this.table.filterComponents, function (filters) {
            _this.table.emitFilter(filters);
        });
        this.filterService.filterToggle();
    };
    TableComponent.prototype.redirectRoute = function (info) {
        if (info && info.route) {
            var link = '/' + info.type + '/' + info.route;
            window.open(link, '_blank');
        }
    };
    return TableComponent;
}());
export { TableComponent };
