import { isObject } from 'util';
import { Pagination } from './pagination.types';
import { Button } from './button.types';
import { ThumbnailCard } from '.';
import { PerformanceCard } from './card.types';
export interface ILabelAction {
  label: string;
  onClick: () => void;
}
export interface ITableAction {
  text: string;
  onClick: () => void;
}

export class TableAction {
  text: string;
  onClick: () => {};
  constructor(action: ITableAction) {
    Object.assign(this, action);
  }
}


export enum TableDataTypes {
  DATA,
  ACTION,
  BUTTON,
  CARD,
  PERFORMANCE_CARD,
  FRAUD_CARD,
  CHIP,
  CHIPS,
  FRAUD,
  KYC_CARD ,
}

export class TableActions {
  constructor(public actions: TableAction[]) {
  }
}


export interface ITableData {
  type: TableDataTypes;
  flag?: boolean;
  suspendFlag?: boolean;
  data?: any;
  actions?: TableActions;
  button?: Button;
  className?: string;
  style?: any;
  tdClassName?: string;
  spanClassName?: string;
  showIcon?: string;
  spanClass?: string;
  iconClass?: string;
  stopHover?: Boolean;
  information?: string;
  card ?: ThumbnailCard;
  hoverData?: string;
  performanceCard ?: PerformanceCard;
  onClick?: (data: any) => void;
}

export class TableData {
  type: TableDataTypes;
  data?: string;
  actions?: TableActions;
  button?: Button;
  className?: string;
  tdClassName?: string;
  spanClassName?: string;
  card ?: ThumbnailCard;
  style?: any;
  stopHover?: Boolean;
  hoverData?: string;
  onClick?: (data: any) => void;
  constructor(data: ITableData) {
    Object.assign(this, data);
    this.onClick = this.onClick || ((data) => {});
  }
  valueOf() {
    switch (this.type) {
      case TableDataTypes.DATA: {
        return this.data;
      }
      // case TableDataTypes.BUTTON: {
      //   return this.button;
      // }
      // case TableDataTypes.ACTION: {
      //   return this.actions;
      // }
    }
    return this;
  }
  toString() {
    return this.valueOf();
  }
}

export enum TABLE_SPAN_CLASS {
  ACTIVE_BUTTON = 'active-lable',
  INACTIVE_BUTTON = 'inactive-lable',
  WARNING_BUTTON = 'warning-lable',
}

export interface ITableConfig {
  header?: boolean;
  headerStyle?: any;
  colName?: boolean;
  noColorOnTdOnHover?: boolean;
  search?: boolean;
  filter?: boolean;
  refresh?: boolean;
  ellipses?: any;
}

const defaultConfig = {
  header: true,
  colName: true,
  search: false,
  filter: false,
  refresh: true,
}
export class TableConfig {
  header: boolean;
  headerStyle?: any;
  colName: boolean;
  noColorOnTdOnHover?: boolean;
  search: boolean;
  filter: boolean;
  refresh: boolean;
  ellipses?: ILabelAction[];
  constructor(config: ITableConfig = {}) {
    Object.assign(this, defaultConfig, config);
  }
}

export interface ITable {
  pagination?: Pagination;
  data?: any[];
  expandedElement?: any;
  headers: any;
  defaultError?: String;
  tableHeader?: any;
  toggleableHeaders?: any[];
  selectedHeader?: number;
  config?: ITableConfig;
  dynamicClass?: any;
  state?: string;
  filterComponents?: any;
  appliedFilters?: any;
  filterCount?: any;
  style?: any;
  onSearch?: (searchObj: any) => void;
  onFilter?: () => void;
  onRefresh?: () => void;

}

export class Table {
  pagination: Pagination;
  toggleableHeaders: any[];
  selectedHeader: number;
  // = new Pagination();
  private _data = [];
  headers: any = {};
  tableHeader: any;
  realData: any[] = [];
  config: TableConfig;
  dynamicClass: any = {};
  filterComponents?: any;
  style:any = {};
  appliedFilters?: any;
  filterCount?: any;
  defaultError?: String;
  // ellipses?: ILabelAction[];
  state?: string = Table.TableStates.LOADING;
  _onSearch = [(searchObj: any) => {
    console.log(searchObj);
    return;
  }];
  _onRefresh = [()=>{
    return;
  }];
  _onFilter = [(filters: any) => {
    return;
  }];
  set onSearch(fn: (searchObj: any) => void) {
    const alreadyExists = this._onSearch.find(searchFn => searchFn === fn);
    if (alreadyExists) {
      return;
    }
    this._onSearch.push(fn);
  }
  set onRefresh(fn: () => void) {
    const alreadyExists = this._onRefresh.find(refreshFn => refreshFn === fn);
    if (alreadyExists) {
      return;
    }
    this._onRefresh.push(fn);
  }
  set onFilter(fn: () => void) {
    const alreadyExists = this._onFilter.find(filterFn => filterFn === fn);
    if (alreadyExists) {
      return;
    }
    this._onFilter.push(fn);
  }
  emitSearch(searchString) {
    this._onSearch.forEach(search => {
      if (!search || typeof search !== 'function') {
        return;
      }
      search({
        searchString
      });
    });
  }
  emitRefresh() {
    this._onRefresh.forEach(refresh => {
      if (!refresh || typeof refresh !== 'function') {
        return;
      }
      refresh();
    });
  }
  emitFilter(filters: any) {
    this._onFilter.forEach(filter => {
      if (!filter || typeof filter !== 'function') {
        return;
      }
      filter(filters);
    });
  }
  isElipsesAvailable() {
    if (this.config.ellipses && this.config.ellipses.length) {
      return true;
    }
    return false;
  }

  dataWillLoad() {
    this.state = Table.TableStates.LOADING;
  }
  // onSearch?: (searchObj: any) => void;
  set data(rows: any[]) {
    this.realData = rows;
    this._data = rows.map(row => {
      Object.keys(row).map(key => {
        const dataValue = row[key];
        if (!isObject(dataValue)) {
          row[key] = new TableData({
            data: row[key],
            type: TableDataTypes.DATA,
            className: this.dynamicClass[key],
          });
        } else if (dataValue instanceof TableData) {
          switch (dataValue.type) {
            case TableDataTypes.ACTION: {
              row[key] = dataValue;
              break;
            }
            case TableDataTypes.BUTTON: {
              row[key] = dataValue;
              break;
            }
            case TableDataTypes.DATA: {
              row[key] = dataValue;
              break;
            }
            // case TableDataTypes.CARD: {

            // }
          }
        }
      });
      return row;
    });
    this.state = Table.TableStates.LOADED;
  }
  get data() {
    return this._data;
  }
  constructor(data: ITable) {
    Object.assign(this, data);
    // this.pagination = data.pagination || new Pagination();
    this.config = new TableConfig(this.config);
    // this.state = Table.TableStates.LOADING;
  }
  static get TableStates() {
    return {
      LOADING : 'loading',
      LOADED: 'loaded',
      ERRORED: 'errored',
    };
  }
}


