/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fare-estimate-log.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../expandable-table/expandable-table.component.ngfactory";
import * as i3 from "../expandable-table/expandable-table.component";
import * as i4 from "./fare-estimate-log.component";
import * as i5 from "./fare-estimate-log.service";
import * as i6 from "../entity.service";
var styles_FareEstimateLogComponent = [i0.styles];
var RenderType_FareEstimateLogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FareEstimateLogComponent, data: {} });
export { RenderType_FareEstimateLogComponent as RenderType_FareEstimateLogComponent };
export function View_FareEstimateLogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expandable-table", [], null, [[null, "toggleExpansion"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggleExpansion" === en)) {
        var pd_0 = (_co.toggleState($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ExpandableTableComponent_0, i2.RenderType_ExpandableTableComponent)), i1.ɵdid(1, 638976, null, 0, i3.ExpandableTableComponent, [], { info: [0, "info"], pageRefreshed: [1, "pageRefreshed"] }, { toggleExpansion: "toggleExpansion" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableData; var currVal_1 = _co.pageRefreshed; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FareEstimateLogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fare-estimate-log", [], null, null, null, View_FareEstimateLogComponent_0, RenderType_FareEstimateLogComponent)), i1.ɵdid(1, 638976, null, 0, i4.FareEstimateLogComponent, [i5.FareEstimateLogService, i6.EntityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FareEstimateLogComponentNgFactory = i1.ɵccf("app-fare-estimate-log", i4.FareEstimateLogComponent, View_FareEstimateLogComponent_Host_0, { fareEstimateLogs: "fareEstimateLogs", pageRefreshed: "pageRefreshed" }, {}, []);
export { FareEstimateLogComponentNgFactory as FareEstimateLogComponentNgFactory };
