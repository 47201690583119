import { OnInit } from '@angular/core';
import { Table, TableData } from '../types';
var defaultHorizontalListConfig = {
    showHeaderBar: true
};
var HorizontalList = /** @class */ (function () {
    function HorizontalList(config) {
        this.DEFAULT_CONFIG = {
            showHeaderBar: true,
            colName: false,
            filter: false,
            refresh: false,
            noColorOnTdOnHover: true,
            headerStyle: {
                'border-bottom': 0,
            }
        };
        this.DEFAULT_EVENTS = {
            onSearch: function (searchObj) { return console.log(searchObj); },
            onFilter: function () { return console.log('filter'); },
            onRefresh: function () { return console.log('refresh'); },
        };
        this.config = config.config || {};
        Object.assign(this, config);
        var tableConfig = Object.assign({}, this.DEFAULT_CONFIG, config.config);
        var tableEvents = Object.assign({}, this.DEFAULT_EVENTS, config.events);
        var mappedHeaders = config.tableData.map(function (data, index) {
            return data.map(function (item, index) {
                var _a;
                return _a = {},
                    _a[index] = index,
                    _a;
            });
        }).reduce(function (acc, header) {
            return Object.assign(acc, header);
        }, {});
        var mappedData = config.tableData.map(function (data) {
            // const row = {};
            return data.reduce(function (acc, item, index) {
                acc[index] = item;
                return acc;
            }, {});
        });
        this.table = new Table({
            tableHeader: config.header,
            config: tableConfig,
            onFilter: tableEvents.onFilter,
            onRefresh: tableEvents.onRefresh,
            onSearch: tableEvents.onSearch,
            headers: mappedHeaders,
            data: mappedData,
            // data: [mappedData],
            filterComponents: config.filterComponents,
            appliedFilters: config.appliedFilters,
            filterCount: config.filterCount,
        });
    }
    Object.defineProperty(HorizontalList.prototype, "config", {
        get: function () {
            return this._config;
        },
        set: function (config) {
            this._config = Object.assign({}, defaultHorizontalListConfig, config);
        },
        enumerable: true,
        configurable: true
    });
    return HorizontalList;
}());
export { HorizontalList };
var HorizontalListComponent = /** @class */ (function () {
    function HorizontalListComponent() {
    }
    HorizontalListComponent.prototype.ngOnInit = function () {
    };
    return HorizontalListComponent;
}());
export { HorizontalListComponent };
