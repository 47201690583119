import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrderDetailsService {
    server = environment.server;
    baseOrderUrl = this.server + '/api/orders';
    tipUrl = this.server + '/api/tip';
    getCustAdj = this.server + '/api/payments/getCustomerAdjustments';
    getCaptAdj = this.server + '/api/payments/getCaptainAdjustments';
    prevDuesTransactions = this.server + '/api/payments/getPrevDuesTransactions';
    cancelUrl = this.server + '/api/orderOrchestartor/cancel';
    fareEstimatesUrl = this.server + '/api/getFareEstimates';
    getCorrelationData = this.server + '/api/getCorrelationIds';
    getOfflineRechargeUrl = this.server + '/api/payments/getOfflineRecharge';
    s3ImageFetch = this.server + '/api/fetchImageFromS3';
    b2bOrderDetails = this.server + '/api/b2b/deliveryOrders/';
    batchedOrders = this.server + '/api/batchingInfo/';
    ordersV2 = this.server + '/api/v2/orders/';
    orderPromise = this.server + '/api/orderOrchestartor/orderAcceptPromise';

    fetchOrderDetails(orderId) {
      return this.http.get(this.baseOrderUrl + '/' + orderId + '/details').pipe(catchError(this.handleError));
    }

   
    cancel(orderDetails) {
      return this.http.post(this.cancelUrl, orderDetails).pipe(catchError(this.handleError));
    }
    
    fetchOrderDetailsV2(orderId, qos) {
      return this.http.get(`${this.ordersV2}/${orderId}?qos=${qos}`).pipe(catchError(this.handleError));
    }

    fetchOrderAcceptPromise(orderId) {
      return this.http.get(`${this.orderPromise}/${orderId}`).pipe(catchError(this.handleError));
    }

    fetchCaptainRateCard(orderId) {
      return this.http.get(`${this.server}/api/order/${orderId}/captainRateCard`).pipe(catchError(this.handleError));
    }

    fetchDetailedOrderEarnings(orderId) {
      return this.http.get(this.server + '/api/captain/earnings/' + orderId).pipe(catchError(this.handleError));
    }

    fetchInvoiceReceiptDetails(orderId) {
      return this.http.get(this.server + '/api/invoice/receipt/' + orderId).pipe(catchError(this.handleError));
    }

    fetchB2bOrderDetails(orderId) {
      return this.http.get(this.b2bOrderDetails + orderId).pipe(catchError(this.handleError));
    }

    getTip(orderId, riderId, paymentDate) {
      return this.http.get(this.tipUrl + '/' + orderId + '/' + riderId + '/' + paymentDate).pipe(catchError(this.handleError));
    }

    getCustomerAdjustments(orders, id) {
      return this.http.post(this.getCustAdj + '/' + id, orders).pipe(catchError(this.handleError));
    }
    getPrevDuesTransactions(userId){
      const body = {
        userId: userId,
        showAllTransactions: true
      }
      return this.http.post(this.prevDuesTransactions, body).pipe(catchError(this.handleError));
    }

    getCaptainAdjustments(orderId, riderId) {
      const body = {
        orderId: orderId,
        riderId: riderId
      };
      return this.http.post(this.getCaptAdj, body).pipe(catchError(this.handleError));
    }

    getFareEstimates(serviceId, requestIds, userId) {
      const body = {
        serviceId: serviceId,
        requestIds: requestIds,
        userId: userId
      };
      return this.http.post(this.fareEstimatesUrl, body).pipe(catchError(this.handleError));
    }

    getCorrelationIds(orderId) {
      const payload = {orderId};
      return this.http.post(this.getCorrelationData, payload).pipe(catchError(this.handleError));
    }

    getOfflineRecharge(customerId, riderId, orderDate) {
      return this.http.post(this.getOfflineRechargeUrl, {
        customerId,
        riderId,
        orderDate,
      }).pipe(catchError(this.handleError));
    }

    getImageFromS3(imageUrl) {
      return this.http.post(this.s3ImageFetch, {imageUrl}, { responseType: 'blob' }).pipe(catchError(this.handleError));
    }

    getBatchingInfo(batchId) {
      return this.http.get(this.batchedOrders + batchId).pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(error);
    }
    constructor(private http: MyHttpClient) { }
  }
