import * as i0 from "@angular/core";
var TollsService = /** @class */ (function () {
    function TollsService() {
        this.tollInfoWindow = new google.maps.InfoWindow({});
    }
    TollsService.prototype.constructTollPolylines = function (tolls, map) {
        var _this = this;
        var tollPolylines = [];
        var _loop_1 = function (toll) {
            var tollStartCoordinates = [
                {
                    lat: toll.geolayer_geojson_start.features[0].geometry.coordinates[0][1],
                    lng: toll.geolayer_geojson_start.features[0].geometry.coordinates[0][0]
                },
                {
                    lat: toll.geolayer_geojson_start.features[0].geometry.coordinates[1][1],
                    lng: toll.geolayer_geojson_start.features[0].geometry.coordinates[1][0]
                }
            ];
            var tollStartPolyline = new google.maps.Polyline({
                path: tollStartCoordinates,
                geodesic: true,
                strokeColor: "#009100",
                strokeOpacity: 1.0,
                strokeWeight: 8,
            });
            var startTollLineContent = "Toll line: " + toll.geolayer_geojson_start.features[0].properties.name + "<br>Type: Entry";
            tollStartPolyline.addListener('click', function (event) {
                _this.tollInfoWindow.setContent(startTollLineContent);
                _this.tollInfoWindow.setPosition(event.latLng);
                _this.tollInfoWindow.open(map);
            });
            var tollEndCoordinates = [
                {
                    lat: toll.geolayer_geojson_end.features[0].geometry.coordinates[0][1],
                    lng: toll.geolayer_geojson_end.features[0].geometry.coordinates[0][0]
                },
                {
                    lat: toll.geolayer_geojson_end.features[0].geometry.coordinates[1][1],
                    lng: toll.geolayer_geojson_end.features[0].geometry.coordinates[1][0]
                }
            ];
            var tollEndPolyline = new google.maps.Polyline({
                path: tollEndCoordinates,
                geodesic: true,
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 8,
            });
            var endTollLineContent = "Toll line: " + toll.geolayer_geojson_end.features[0].properties.name + "<br>Type: Exit";
            tollEndPolyline.addListener('click', function (event) {
                _this.tollInfoWindow.setContent(endTollLineContent);
                _this.tollInfoWindow.setPosition(event.latLng);
                _this.tollInfoWindow.open(map);
            });
            tollPolylines.push(tollStartPolyline);
            tollPolylines.push(tollEndPolyline);
        };
        for (var _i = 0, tolls_1 = tolls; _i < tolls_1.length; _i++) {
            var toll = tolls_1[_i];
            _loop_1(toll);
        }
        return tollPolylines;
    };
    TollsService.prototype.addTollsToMap = function (tolls, map) {
        var tollPolylines = this.constructTollPolylines(tolls, map);
        for (var _i = 0, tollPolylines_1 = tollPolylines; _i < tollPolylines_1.length; _i++) {
            var tollPolyline = tollPolylines_1[_i];
            tollPolyline.setMap(map);
        }
        return tollPolylines;
    };
    TollsService.prototype.removeTollsFromMap = function (map, tollPolylines) {
        if (tollPolylines && tollPolylines.length > 0) {
            for (var _i = 0, tollPolylines_2 = tollPolylines; _i < tollPolylines_2.length; _i++) {
                var tollPolyline = tollPolylines_2[_i];
                tollPolyline.setMap(null);
            }
        }
        this.tollInfoWindow.close();
    };
    TollsService.prototype.fetchTollLinesFromTolls = function (tolls) {
        var entryTollLines = [];
        var exitTollLines = [];
        for (var _i = 0, tolls_2 = tolls; _i < tolls_2.length; _i++) {
            var toll = tolls_2[_i];
            entryTollLines.push(toll.geolayer_geojson_start.features[0].properties.name);
            exitTollLines.push(toll.geolayer_geojson_end.features[0].properties.name);
        }
        return {
            entryTollLines: entryTollLines,
            exitTollLines: exitTollLines
        };
    };
    TollsService.prototype.fetchAssociatedTollFromTollLines = function (entryTollLine, exitTollLine, tolls) {
        for (var _i = 0, tolls_3 = tolls; _i < tolls_3.length; _i++) {
            var toll = tolls_3[_i];
            if ((toll.geolayer_geojson_start.features[0].properties.name == entryTollLine) &&
                (toll.geolayer_geojson_end.features[0].properties.name == exitTollLine)) {
                return toll;
            }
        }
        return null;
    };
    TollsService.ngInjectableDef = i0.defineInjectable({ factory: function TollsService_Factory() { return new TollsService(); }, token: TollsService, providedIn: "root" });
    return TollsService;
}());
export { TollsService };
