

import { Component, OnInit, Input } from '@angular/core';
import { OrderService } from 'src/app/order-service.service';
import { CaptainFailedOrdersService } from './captain-failed-orders.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { FilterService, DateFilterInput, TextFilterInput,
  SearchableDropDownFilterInput, RangeSliderInput, SingleSearchableDropdown } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Toast } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { RevokeFailedOrderComponent } from './revoke-failed-order/revoke-failed-order.component';
// import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import {SvoService} from '../../svo.service';
import {Observable} from 'rxjs/Observable';
const currentDate = moment(new Date()).utcOffset('+0530').format('YYYY-MM-DD');

function defaultFiters() {
  return {
    startDuration: currentDate,
    endDuration: currentDate,
    cities: '',
    service: '',
    failedOrderType: ''
  };
}
@Component({
  selector: 'app-captain-failed-orders',
  templateUrl: './captain-failed-orders.component.html',
  styleUrls: ['./captain-failed-orders.component.css']
})
export class CaptainFailedOrdersComponent implements OnInit {
  serviceMap = {};
  orderTable: Table;
    dailyPingsTable: Table;
    hourlyPingsTable: Table;
  breadcrumb = faChevronRight;
  currentNumber: any;
  filtersApplied = defaultFiters();
  services: any = [];
  orders: any[];
  cashback = {from : '0', to: '100'};
  rating = {from : '0', to : '5'};
  failedOrderTypes = [
    'Rider Busy',
    'Rider Rejected',
    'Customer Cancelled',
    'Aborted'
  ];
  failedOrderTypeMap = {
    'Rider Busy': 'rider_busy',
    'Rider Rejected': 'rider_reject',
    'Customer Cancelled': 'customer_cancelled',
    'Rider Cancelled': 'rider_cancelled',
    'Aborted': 'aborted'
  };
  orderTypes = [
    'app',
    'auto',
    'bfs',
    'c2c',
    'delivery',
    'hire',
    'premium',
    'sme'
  ];
  filterCount: any;
  public riderId: string;
  public riderCity: string;
  public riderMobile: string;
  public filtersApplicable = {
    startDuration: 'startDate',
    endDuration: 'endDate',
    orderTypes: 'orderTypes',
    failedOrderType: 'events',
    service: 'serviceDetails',
  };
  currentPage = 0;
  limit = 10;
  constructor(
    public dialog: MatDialog,
    private toasterService: ToasterService,
    public orderService: OrderService,
    public filterService: FilterService,
    public entityService: EntityService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    public captainFailedOrdersService: CaptainFailedOrdersService,
    public svoService: SvoService,
  ) { }

  ngOnInit() {
    // const fromDate = this.route.snapshot.queryParamMap.get('fd');
    // const toDate = this.route.snapshot.queryParamMap.get('td');
    // if (fromDate && toDate) {
    //   this.filtersApplied.startDuration = fromDate;
    //   this.filtersApplied.endDuration = toDate;
    // }
    this.riderMobile = this.route.snapshot.params.mobile ? this.route.snapshot.params.mobile : '';
    const riderMobile = HelperService.decode(this.riderMobile);
    this.entityService.getEntityByNumber(riderMobile).subscribe(resp => {
      if (resp) {
        if (resp.userId._id) {
          this.riderId = resp.userId._id;
          this.defineOrderTable();
            this.definePingTables();
        }
        if (resp.city && resp.city._id) {
          this.riderCity = resp.city._id;
          this.entityService.getServicesCityWise(this.riderCity).subscribe(services => {
            services = services['data'];
            this.services.splice(0);
            for (const service in services) {
              if (services[service] && services[service]._id && services[service].service && services[service].service.active) {
                this.services.push(services[service].service.name);
                this.serviceMap[services[service].service.name] = services[service]._id;
              }
            }
          });
        }
      }
    });

    // this.riderId = this.route.snapshot.params.id ? HelperService.decode(this.route.snapshot.params.id) : '';
  }

  redirectToCaptainDetails() {
    this.router.navigate([
      'captain/' + this.riderMobile
    ]);
  }

  private declareTable(pageNumber = 0) {

    this.dataService.searchUserCount.subscribe(result => {
      if (!this.currentNumber || (this.currentNumber === result)) {
        this.filterService.appliedFilters = this.filtersApplied;
      } else {
        this.filterService.appliedFilters = {};
        this.filtersApplied = defaultFiters();
      }
      this.currentNumber = result;
    });
    this.orderTable = new Table({
      tableHeader: 'Failed Orders',
      headers: {
        dateTime: 'Order Date & Time',
        orderId: 'Order Id',
        pickupLocation: 'Pickup',
        service: 'Service',
        eventDateTime: 'Event Date & Time',
        orderStatus: 'Order Status',
        getInfo: 'Get Info'
      },
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      state: 'loading',
      onRefresh: () => {
        this.orderTable = null;
        this.ngOnInit();
      },
      appliedFilters: this.filtersApplied,
      filterComponents: [
        new DateFilterInput({
          key: 'duration',
          default: '',
          title: 'Duration'
        }),
        new SearchableDropDownFilterInput({
          key : 'service',
          title: 'Services',
          values: this.services,
        }),
        new SearchableDropDownFilterInput({
          key : 'failedOrderType',
          title: 'Failed Order Type',
          values: this.failedOrderTypes,
        }),
        new SingleSearchableDropdown({
          key : 'orderTypes',
          title: 'Order Type',
          values: this.orderTypes,
        })
      ],
      onFilter: () => {
        const timeSlot = this.filterService.appliedFilters.duration || [currentDate, currentDate];
        let duration = [];
        if (!Array.isArray(timeSlot)) {
          duration.push(timeSlot['start']);
          duration.push(timeSlot['end']);
        } else {
          duration = timeSlot;
        }
        let startDuration = duration[0] || currentDate;
        let endDuration = duration[1] || currentDate;
        const cities = this.filterService.appliedFilters.cities;
        const service = this.filterService.appliedFilters.service;
        const failedOrderType = this.filterService.appliedFilters.failedOrderType;
        const orderTypes = this.filterService.appliedFilters.orderTypes;
        const filtersApplied = {
          duration: {
            start: startDuration,
            end: endDuration,
          },
          startDuration,
          endDuration,
          cities,
          service,
          failedOrderType,
          orderTypes
        };
        Object.assign(this.filtersApplied, filtersApplied);

        const isValidNumber = (value) => {
          return ((parseInt(value, 10) === 0) || value);
        };
        if ((startDuration && endDuration)) {
          startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
          endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
          return this.defineOrderTable(this.filtersApplied);
        }
        return this.defineOrderTable(this.filtersApplied);
      },
      data: []
    });
  }

  onPaginationChange(event) {
    this.currentPage = event.pageNo;
    this.fillDataToTable(this.filtersApplied);
  }


  getOrderList(filters?: any) {
    const finalFilters = {};
    this.filterCount = 0;
    for (const filter in filters) {
      if (filters[filter] && this.filtersApplicable[filter]) {
        if (filter === 'failedOrderType') {
          finalFilters[this.filtersApplicable[filter]] = [];
          for (const orderType of filters[filter]) {
            finalFilters[this.filtersApplicable[filter]].push(this.failedOrderTypeMap[orderType]);
          }
        } else if (filter === 'orderTypes') {
          finalFilters[this.filtersApplicable[filter]] = [filters[filter]];
        } else if (filter === 'service') {
          if (filters[filter].length) {
            finalFilters[this.filtersApplicable[filter]] = [];
            for (const service in filters[filter]) {
              if (filters[filter][service]) {
                finalFilters[this.filtersApplicable[filter]].push(this.serviceMap[filters[filter][service]]);
              }
            }
          }
        } else {
          finalFilters[this.filtersApplicable[filter]] = filters[filter];
        }
        this.filterCount++;
      }
    }
    finalFilters['limit'] = this.limit;
    finalFilters['skip'] = this.limit * this.currentPage;
    return this.captainFailedOrdersService.getFailedOrders(this.riderId, finalFilters);
  }

  fillDataToTable(filters?: any) {
    if (this.riderId) {
      this.orderTable.dataWillLoad();
      this.getOrderList(filters).subscribe(async(response) => {
        const orderIds = [];
        const self = this;
        const adjustmentsHistory = {};
        function createTableData(value, className?) {
          return new TableData({
            data: value,
            type: TableDataTypes.DATA,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
            onClick: (data) => {
              self.router.navigate([`/order/${data._id.data}`]);
            }
          });
        }

        if (response && response['data'] && response['data'].data) {
          this.orders = response['data'].data.map((order, i) => {
            const orderId = order.orderId;
            orderIds.push(orderId);
            const self = this;
            const orderDate = new Date(order.orderCreatedDate);

            return {
              _id: createTableData(orderId),
              dateTime: createTableData(HelperService.dateString(orderDate) + ', ' + order.orderTime),
              orderId: new TableData({
                data: order.uniqueId,
                type: TableDataTypes.DATA,
                className: 'cursor-pointer whiteSpacePre',
                onClick: (data) => {
                  self.router.navigate([`/order/${data._id.data}`]);
                }
              }),
              pickupLocation: createTableData(order.pickAddress),
              service: createTableData(order.serviceName),
              eventDateTime: createTableData(HelperService.dateString(order.eventDate) + ', ' + order.eventTime),
              orderStatus: createTableData(this.formatEventName(order.event)),
              getInfo: new TableData({
                data: 'Get Info',
                type: TableDataTypes.ACTION,
                showIcon: order.isRevoked ? 'check_circle' : '',
                className: order.isRevoked ?
                  'cursor-pointer whiteSpacePre' : 'cursor-pointer whiteSpacePre',
                spanClass: 'revoke-button',
                iconClass: order.isRevoked ? 'revoke-icon color-kermit-green' : '',
                onClick: (data) => {
                    const orderId = data._id && data._id.data;
                  this.captainFailedOrdersService.getActivityLog(this.riderId, orderId).subscribe(res => {
                    if (res && res['data'] && res['data'].data && res['data'].data.length) {
                        const eventCount = {
                        isRevoked: order.isRevoked,
                        revokedBy: order.revokedBy || '',
                        revokedOn: order.revokedOn || '',
                        orderId: order.orderId,
                        event : order.event,
                        eventDate : order.eventDate,
                        orderCreatedDate : order.orderCreatedDate,
                        currentUser : this.getCurrentUser(),
                        riderId : this.riderId
                        };
                      const dialogRef = this.dialog.open(RevokeFailedOrderComponent, {
                        width: '900px',
                        data: {activityLog: res['data'].data, eventCount, accesses : this.getRevokeAccesses()}
                      });
                      dialogRef.afterClosed().subscribe(response => {
                        this.defineOrderTable();
                      });
                    } else {
                      this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: `Cannot find logs for this record`,
                      }));
                    }
                  });
                }
              }),
            };
          });
          this.orderTable.pagination.count = response['data'] && response ['data']['total'] || response['data'].data.length;
          this.orderTable.pagination.pageSizeOptions = [10];
          this.orderTable.pagination.perPage = this.limit;
        }
        this.orderTable.data = this.orders;
        this.orderTable.filterCount = this.filterCount;
      });

    } else {
      console.log('Something went wrong');
    }
  }
  isEmpty(value) {
    return (value == null || value.length === 0);
  }

  formatEventName(name) {
    const event = name.split('_');
    if (event.length > 1) {
      const str = event.join(' ');
      return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
    } else {
      return name;
    }
  }

  defineOrderTable(filters = this.filtersApplied) {
    this.declareTable();
    this.fillDataToTable(filters);
  }

    definePingTables() {
        this.dailyPingsTable = new Table({
            tableHeader: 'Daily Pings',
            pagination: new Pagination(0, 5),
            headers: {
                orderStatus: 'Order Status',
                count: 'Count',
                performance: 'Performance'
            },
            config: {
                refresh: true,

            },
            state: 'loading',
            onRefresh: () => {
                this.dailyPingsTable = null;
                this.reloadPingsData();
            },
            filterComponents: [],
            data: []
        });

        const generateObject = n => Array.from({length: n}, (_, i) => i).reduce((obj, num) => {
            let key = `${num}`;
            if (key.length === 1) {
                key = `0${key}`;
            }

            obj[`h_${key}`] = `${num}`;
            return obj;
        }, {});

        const header = generateObject(24);

        this.hourlyPingsTable = new Table({
            tableHeader: 'Hourly Pings',
            pagination: new Pagination(0, 5),
            headers: {
                orderStatus: 'Order Status',
                ...header,
            },
            config: {
                refresh: true,
            },
            state: 'loading',
            onRefresh: () => {
                this.hourlyPingsTable = null;
                this.reloadPingsData();
            },
            filterComponents: [],
            data: []
        });

        this.fillDataToPingTables();
    }


    reloadPingsData() {
        this.definePingTables();
    }

    fillDataToPingTables() {
        this.dailyPingsTable.dataWillLoad();
        this.hourlyPingsTable.dataWillLoad();
        this.svoService.getCaptainPerformance(this.riderId).then((response: any) => {
            const data = response.data;

            function createTableData(value, className?) {
                return new TableData({
                    data: value,
                    type: TableDataTypes.DATA
                });
            }

            let total = data.daily.customer_cancelled + data.daily.rider_cancelled + data.daily.rider_busy + data.daily.rider_rejected;
            total = total + data.daily.dropped + data.daily.aborted + data.daily.switch_rider;
            const dailyPingsData: any = [
                {
                    orderStatus: createTableData('Customer Cancelled'),
                    count: createTableData(data.daily.customer_cancelled),
                },
                {
                    orderStatus: createTableData('Rider Cancelled'),
                    count: createTableData(data.daily.rider_cancelled),
                },
                {
                    orderStatus: createTableData('Rider Busy'),
                    count: createTableData(data.daily.rider_busy),
                },
                {
                    orderStatus: createTableData('Rider Rejected'),
                    count: createTableData(data.daily.rider_rejected),
                },
                {
                    orderStatus: createTableData('Dropped'),
                    count: createTableData(data.daily.dropped),
                },
                {
                    orderStatus: createTableData('Aborted'),
                    count: createTableData(data.daily.aborted),
                },
                {
                    orderStatus: createTableData('Captain Switch'),
                    count: createTableData(data.daily.switch_rider),
                }
            ];

            this.dailyPingsTable.data = dailyPingsData.map((val) => {
                console.log(val.count.data, total);
                val.performance = createTableData(`${((val.count.data / total) * 100).toFixed(2)}%`);
                return val;
            });

            const hourlyData = data.hourly.reduce((acc, val) => {
                acc['aborted'][`h_${val.hh}`] = val.aborted;
                acc['customer_cancelled'][`h_${val.hh}`] = val.customer_cancelled;
                acc['dropped'][`h_${val.hh}`] = val.dropped;
                acc['rider_busy'][`h_${val.hh}`] = val.rider_busy;
                acc['rider_cancelled'][`h_${val.hh}`] = val.rider_cancelled;
                acc['rider_rejected'][`h_${val.hh}`] = val.rider_rejected;
                acc['switch_rider'][`h_${val.hh}`] = val.switch_rider;
                return acc;
            }, {
                'aborted': {},
                'customer_cancelled': {},
                'dropped': {},
                'rider_busy': {},
                'rider_cancelled': {},
                'rider_rejected': {},
                'switch_rider': {}
            });

            console.log(hourlyData);

            this.hourlyPingsTable.data = [
                {
                    orderStatus: createTableData('Aborted'),
                    ...hourlyData['aborted']
                },
                {
                    orderStatus: createTableData('Customer Cancelled'),
                    ...hourlyData['customer_cancelled']
                },
                {
                    orderStatus: createTableData('Dropped'),
                    ...hourlyData['dropped']
                },
                {
                    orderStatus: createTableData('Rider Busy'),
                    ...hourlyData['rider_busy']
                },
                {
                    orderStatus: createTableData('Rider Cancelled'),
                    ...hourlyData['rider_cancelled']
                },
                {
                    orderStatus: createTableData('Rider Rejected'),
                    ...hourlyData['rider_rejected']
                },
                {
                    orderStatus: createTableData('Captain Switch'),
                    ...hourlyData['switch_rider']
                }
            ];
        });
        this.dailyPingsTable.pagination.count = 5;
        this.dailyPingsTable.pagination.pageSizeOptions = [10];
        this.dailyPingsTable.pagination.perPage = 5;
    }

  getCurrentUser() {
    return this.accessCookie('name');
  }

  getRevokeAccesses() {
      const permissions = this.accessCookie('accessRoles');
    return permissions.split(',');
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }
}
