var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
var HelperService = /** @class */ (function () {
    function HelperService() {
    }
    HelperService.copyObj = function (obj) {
        if (obj === void 0) { obj = {}; }
        return _.cloneDeep(obj);
    };
    HelperService.getQueryString = function (find, select) {
        var _this = this;
        if (find === void 0) { find = {}; }
        if (select === void 0) { select = []; }
        var keywords = Object.keys(find);
        var specialOperator = [
            'sort',
            'limit',
            'offset',
        ];
        var queryString = keywords.reduce(function (acc, keyword, index) {
            if (keyword === 'status' && find[keyword] === 'all') {
                acc = acc;
                return acc;
            }
            var findCondition = find[keyword];
            if (specialOperator.includes(keyword)) {
                acc += keyword + "=" + findCondition;
            }
            else if (findCondition instanceof Array) {
                acc += keyword + "__in=" + findCondition.join(',');
            }
            else if (typeof findCondition === 'object') {
                acc += _this.makeConditionQuery(keyword, findCondition);
            }
            else {
                acc += keyword + "__eq=" + findCondition;
            }
            acc += "&";
            return acc;
        }, '');
        if (select && select.length) {
            queryString += "select=" + select.join(',');
        }
        return queryString;
    };
    HelperService.queryString = function (query) {
        if (query === void 0) { query = {}; }
        var keywords = Object.keys(query);
        var queryString = keywords.reduce(function (acc, keyword) {
            var findCondition = query[keyword];
            // if (specialOperator.includes(keyword)) {
            //   acc += `${keyword}=${findCondition}`;
            // } else if (findCondition instanceof Array) {
            //   acc += `${keyword}__in=${findCondition.join(',')}`;
            // } else if (typeof findCondition === 'object' ) {
            //   acc += this.makeConditionQuery(keyword, findCondition);
            // } else {
            acc += keyword + "=" + findCondition;
            // }
            acc += "&";
            return acc;
        }, '');
        return queryString;
    };
    HelperService.makeConditionQuery = function (parent, condition) {
        var conditionOperators = Object.keys(condition);
        var conditionQueryString = conditionOperators.map(function (cond) {
            return parent + "__" + cond + "=" + condition[cond];
        });
        return conditionQueryString.join('&');
    };
    HelperService.timestampToReadableFormat = function (timeStamp) {
        var date = new Date(timeStamp);
        var timeString = date.toLocaleTimeString().split(':').splice(0, 2).join(':');
        return timeString + " " + HelperService.dateString(date);
    };
    HelperService.isValidDate = function (date) {
        return (date && date.getTime()) ? true : false;
    };
    HelperService.getDateOnly = function (date) {
        var monthDate = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return year + "-" + month + "-" + monthDate.toString().padStart(2, '0');
    };
    HelperService.getDateOnlyV1 = function (date) {
        var monthDate = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return year + "-" + month.toString().padStart(2, '0') + "-" + monthDate.toString().padStart(2, '0');
    };
    HelperService.setCookie = function (data) {
        if (data === void 0) { data = {}; }
        Object.keys(data).map(function (key) {
            var value = data[key];
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            document.cookie = key + "=" + value + ";path=/";
        });
    };
    HelperService.regexString = function (string) {
        return string + "$/i";
    };
    HelperService.roundNumber = function (num) {
        // const roundTwoDecimal = (num) => {
        return Math.round(num * 100) / 100;
        //  };
    };
    HelperService.getOrderDateyUniqueId = function (uniqueId) {
        return __awaiter(this, void 0, void 0, function () {
            var ts, date;
            return __generator(this, function (_a) {
                if (uniqueId.length !== 19) {
                    return [2 /*return*/, null];
                }
                ts = uniqueId.replace("RD", "");
                if (!Date.parse(ts)) {
                    ts = parseInt(ts.toString().split('').reverse().splice(4).reverse().join(''));
                    if (!Date.parse(new Date(ts).toString())) {
                        return [2 /*return*/, null];
                    }
                }
                date = HelperService.getDateOnlyV1(new Date(ts));
                return [2 /*return*/, date];
            });
        });
    };
    HelperService.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            var temp = allCookieArray[i].trim();
            if (temp.indexOf(name) === 0) {
                return temp.substring(name.length, temp.length);
            }
        }
        return '';
    };
    HelperService.dateString = function (date) {
        if (date && Date.parse(date)) {
            return new Date(date).toDateString();
        }
        return 'N/A';
    };
    HelperService.getCurrentUrl = function () {
        return window.location.href;
    };
    HelperService.getCurrentPath = function () {
        return window.location.pathname;
    };
    HelperService.removeParam = function (params) {
        if (params === void 0) { params = []; }
        var baseUrl = window.location.origin;
        var pathname = window.location.pathname;
        var routeParams = this.routeParams();
        var filteredParams = Object.keys(routeParams).reduce(function (acc, param) {
            if (!params.includes(param)) {
                acc[param] = routeParams[param];
            }
            return acc;
        }, {});
        var queryString = HelperService.queryString(filteredParams);
        return queryString ?
            "" + baseUrl + pathname + "?" + HelperService.queryString(filteredParams) : "" + baseUrl + pathname;
    };
    HelperService.routeParams = function () {
        return decodeURIComponent(window.location.search.substring(1)).split('&')
            .reduce(function (acc, keyValue) {
            var data = keyValue.split('=');
            acc[data[0]] = data[1];
            return acc;
        }, {});
    };
    HelperService.getBaseRouteFromUrl = function (url) {
        return url.split('/').splice(0, 3).join('/');
    };
    HelperService.minutesToReadableHourMinuteFormat = function (minutes) {
        var date = new Date('1-1-2019');
        date.setMinutes(minutes);
        var readableString = '';
        if (date.getHours()) {
            readableString = date.getHours() + " hours";
        }
        if (date.getMinutes()) {
            readableString += " " + date.getMinutes() + " min";
        }
        return readableString.trim();
    };
    HelperService.timeStampToDateOnly = function (date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    };
    HelperService.encode = function (data) {
        return btoa(btoa(data));
    };
    HelperService.endcodeToLevel = function (data, encodeLevel) {
        encodeLevel = parseInt(encodeLevel, 10) || 0;
        for (var i = 0; i < encodeLevel; i++) {
            data = HelperService.encode(data);
        }
        return data;
    };
    HelperService.decode = function (data) {
        try {
            return atob(atob(data));
        }
        catch (err) {
            console.log(err);
            return data;
        }
    };
    HelperService.resetTimeTo = function (date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    };
    HelperService.redirect = function (url, withReferer) {
        if (withReferer === void 0) { withReferer = true; }
        var referrer = document.URL;
        var finalUrl = withReferer ? url + "?referrer=" + referrer : url;
        window.location.replace(finalUrl);
    };
    HelperService.redirectToOldDashboard = function (relativeRoute, queryParams) {
        if (queryParams === void 0) { queryParams = {}; }
        var basePath = environment.rapido_dashboard;
        // const token = HelperService.accessCookie('token');
        var queryString = HelperService.queryString(Object.assign({}, queryParams));
        var path = basePath + "/" + relativeRoute + "?" + queryString;
        // const path = `${basePath}/${relativeRoute}?token=${token}`;
        // window.location.replace(path);
        window.open(path, '_blank');
    };
    HelperService.redirectToNewPage = function (url) {
        window.open(url, '_blank');
    };
    // static redirect(url) {
    //   window.location.replace(url);
    // }
    HelperService.openInMap = function (lat, lng) {
        if (lat && lng) {
            var googleUrl = "https://maps.google.com/?q=" + lat + "," + lng;
            this.redirectToNewPage(googleUrl);
        }
    };
    HelperService.convert24to12hrFormat = function (time) {
        var timeIn12HrFormat = time;
        var meridian = 'am';
        if (time) {
            var parts = time.split(':');
            if (parts.length === 2) {
                if (parseInt(parts[0], 10)) {
                    if (parseInt(parts[0], 10) > 12) {
                        parts[0] = parseInt(parts[0], 10) - 12;
                        meridian = 'pm';
                    }
                    else if (parseInt(parts[0], 10) === 12) {
                        meridian = 'pm';
                    }
                }
            }
            timeIn12HrFormat = parts.join(':') + ' ' + meridian;
        }
        return timeIn12HrFormat;
    };
    HelperService.debounce = function (func, delay) {
        if (delay === void 0) { delay = 3000; }
        var debounceTimer;
        return function () {
            var context = this;
            var args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(function () { return func.apply(context, args); }, delay);
        };
    };
    return HelperService;
}());
export { HelperService };
