var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { UserComplaintsService } from '../../customer-complaints.service';
import { Pagination, Table, TableData, TableDataTypes } from 'src/app/shared/types';
import { Router } from '@angular/router';
var CustomerComplaintsComponent = /** @class */ (function () {
    function CustomerComplaintsComponent(ComplaintsService, router) {
        this.ComplaintsService = ComplaintsService;
        this.router = router;
        this.toggleHeaders = new EventEmitter();
        this.dataLoaded = false;
        this.innerTableDataSource = [];
        this.innerTableDisplayedColumns = ['reason', 'subReason', 'complaintsToday', 'complaintsLast3Days', 'complaintsLast7Days', 'complaintsLast15Days', 'complaintsLast30Days', 'createdDate'];
    }
    CustomerComplaintsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.declareTable()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerComplaintsComponent.prototype.declareTable = function (pageNumber) {
        if (pageNumber === void 0) { pageNumber = 0; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.customerComplaints = new Table({
                            toggleableHeaders: this.customerDetailsToggleableHeaders,
                            headers: {
                                reason: 'Reason',
                                subReason: 'Sub Reason',
                                complaintsToday: 'Complaints Today',
                                complaintsLast3Days: 'Complaints Last 3 Days',
                                complaintsLast7Days: 'Complaints Last 7 Days',
                                complaintsLast15Days: 'Complaints Last 15 Days',
                                complaintsLast30Days: 'Complaints Last 30 Days',
                                day: 'Created Date',
                            },
                            pagination: new Pagination(pageNumber, 10),
                            selectedHeader: 5,
                            defaultError: 'No Complaints Found',
                            onRefresh: function () {
                                _this.customerComplaints = null;
                                _this.ngOnInit();
                            },
                            data: []
                        });
                        return [4 /*yield*/, this.getComplaints()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CustomerComplaintsComponent.prototype.getComplaints = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.dataLoaded = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.ComplaintsService.fetchCustomerComplaints(this.customerId).toPromise()];
                    case 2:
                        response = _a.sent();
                        this.dataLoaded = true;
                        if (response && response.data.found) {
                            this.customerComplaints.data = this.formatCustomerComplaints(response.data.complaints);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log('Error while fetching complaints for customer---', error_1);
                        this.dataLoaded = true;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerComplaintsComponent.prototype.formatCustomerComplaints = function (customerComplaints) {
        var _this = this;
        if (customerComplaints === void 0) { customerComplaints = []; }
        var innerTableData = [];
        var formatedComplaints = [];
        var complaint = {};
        customerComplaints = this.reStructure(customerComplaints);
        customerComplaints.map(function (reason) {
            var _a;
            var totalComplaintsToday = 0;
            var totalComplaintsLast3Days = 0;
            var totalComplaintsLast7Days = 0;
            var totalComplaintsLast15Days = 0;
            var totalComplaintsLast30Days = 0;
            innerTableData = [];
            var r = Object.keys(reason);
            reason[r].forEach(function (item) {
                totalComplaintsToday += item.complaintsToday;
                totalComplaintsLast3Days += item.complaintsLast3Days;
                totalComplaintsLast7Days += item.complaintsLast7Days;
                totalComplaintsLast15Days += item.complaintsLast15Days;
                totalComplaintsLast30Days += item.complaintsLast30Days;
                complaint = {
                    reason: _this.createTableData(r[0] || ''),
                    subReason: "Total",
                    complaintsToday: _this.createTableData(totalComplaintsToday || ''),
                    complaintsLast3Days: _this.createTableData(totalComplaintsLast3Days || ''),
                    complaintsLast7Days: _this.createTableData(totalComplaintsLast7Days || ''),
                    complaintsLast15Days: _this.createTableData(totalComplaintsLast15Days || ''),
                    complaintsLast30Days: _this.createTableData(totalComplaintsLast30Days || ''),
                    day: '',
                };
                innerTableData.push({
                    reason: '-',
                    subReason: item.subReason || '-',
                    complaintsToday: item.complaintsToday || '-',
                    complaintsLast3Days: item.complaintsLast3Days || '-',
                    complaintsLast7Days: item.complaintsLast7Days || '-',
                    complaintsLast15Days: item.complaintsLast15Days || '-',
                    complaintsLast30Days: item.complaintsLast30Days || '-',
                    createdDate: item.day
                });
            });
            _this.innerTableDataSource.push((_a = {}, _a[r] = innerTableData, _a));
            formatedComplaints.push(complaint);
        });
        this.customerComplaints.data = formatedComplaints;
        return formatedComplaints;
    };
    CustomerComplaintsComponent.prototype.reStructure = function (complains) {
        var _this = this;
        // This code re-structures the complains array received from svo reason-wise. 
        // Example : [reason1 : {response from svo without reason}, reason2: {}]
        var result = [];
        complains.forEach(function (c) {
            var _a;
            var reason = c.reason;
            delete c.reason;
            var filteredReason = _this.checkIfReasonExists(result, reason);
            if (filteredReason && Object.keys(filteredReason).length > 0) {
                filteredReason[reason].push(c);
            }
            else {
                result.push((_a = {}, _a[reason] = [c], _a));
            }
        });
        return result;
    };
    CustomerComplaintsComponent.prototype.checkIfReasonExists = function (arr, reason) {
        var filteredReasons = arr.filter(function (obj) { return obj.hasOwnProperty(reason); });
        return filteredReasons[0];
    };
    CustomerComplaintsComponent.prototype.createTableData = function (value, className, type, flag, hoverData) {
        if (flag === void 0) { flag = false; }
        return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData ? hoverData : null,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
        });
    };
    CustomerComplaintsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CustomerComplaintsComponent.prototype.onPaginationChange = function (event) {
        this.getComplaints();
    };
    return CustomerComplaintsComponent;
}());
export { CustomerComplaintsComponent };
