var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { OrderLogService } from './order-log-service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
var OrderLogComponent = /** @class */ (function () {
    function OrderLogComponent(orderLogService, toasterService) {
        this.orderLogService = orderLogService;
        this.toasterService = toasterService;
        this.dataRows = [];
        this.showOnMaps = new EventEmitter();
        this.riderName = '';
        this.riderNumber = '';
        this.orderLogFetched = false;
        this.monthMap = {
            1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
            7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
        };
        this.dataLoaded = false;
        this.days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.eventNames = [
            'requested', 'captains_mapped', 'accepted', 'arrived', 'started', 'dropped', 'customer_cancelled',
            'captain_rejected', 'captain_cancelled', 'expired', 'aborted', 'dropChanged', 'captain_busy'
        ];
    }
    OrderLogComponent.prototype.ngOnInit = function () {
    };
    OrderLogComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.pageRefreshed && changes.pageRefreshed.currentValue) {
            this.dataRows = [];
        }
        if (changes && changes.orderLogDetails && changes.orderLogDetails.currentValue) {
            if (!changes.orderLogDetails.currentValue.dataFetched) {
                this.emptyCard();
            }
            else {
                this.orderLogFetched = true;
                this.riderLocationDetails();
            }
        }
    };
    OrderLogComponent.prototype.emptyCard = function () {
        this.dataLoaded = true;
        this.tableData = {
            tableHeader: 'Order log',
            tableHeaderClass: 'margin-9-16',
            headers: null,
            data: null,
            dataFetched: false,
            emptyMessage: 'No order logs found for this order'
        };
    };
    OrderLogComponent.prototype.buttonClicked = function (button) {
        if (button === 'Show on maps') {
            this.tableData = {
                tableHeader: 'Order log',
                tableHeaderClass: 'margin-9-16',
                expandTable: true,
                headers: { timeStamp: 'Timestamp', status: 'Status', riderDetails: 'Rider Details', location: 'Location', otherInfo: 'Other Info' },
                data: [this.dataRows[0]],
                button: { data: 'Hide from maps', classButton: 'show-on-map' },
                title: ['riderDetails', 'location'],
                dataFetched: true,
                tableHeight: 'max-height-275',
                tooltipPosition: 'left'
            };
            this.showOnMaps.emit();
        }
        else if (button === 'Hide from maps') {
            this.tableData = {
                tableHeader: 'Order log',
                tableHeaderClass: 'margin-9-16',
                expandTable: true,
                headers: { timeStamp: 'Timestamp', status: 'Status', riderDetails: 'Rider Details', location: 'Location', otherInfo: 'Other Info' },
                data: [this.dataRows[0]],
                // button: {data: 'Show on maps', classButton: 'show-on-map'},
                title: ['riderDetails', 'location'],
                dataFetched: true,
                tableHeight: 'max-height-275',
                tooltipPosition: 'left'
            };
            this.showOnMaps.emit();
        }
    };
    OrderLogComponent.prototype.getProperDate = function (timeStamp) {
        var timeInfo = new Date(timeStamp).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
        var today = new Date(timeStamp);
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear().toString().substr(-2);
        if (dd < 10) {
            dd = '0' + dd;
        }
        var dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
        return (dateInfo + '\n' + timeInfo);
    };
    OrderLogComponent.prototype.constructCard = function () {
        this.tableData = {
            tableHeader: 'Order log',
            tableHeaderClass: 'margin-9-16',
            expandTable: (this.dataRows.length) > 1 ? true : false,
            headers: { timeStamp: 'Timestamp', status: 'Status', riderDetails: 'Rider Details', location: 'Location', otherInfo: 'Other Info' },
            data: [this.dataRows[0]],
            // button: {data: 'Show on maps', classButton: 'show-on-map'},
            title: ['riderDetails', 'location', 'timeStamp'],
            dataFetched: true,
            tableHeight: 'max-height-275',
            tooltipPosition: 'below'
        };
        this.dataLoaded = true;
    };
    OrderLogComponent.prototype.riderLocationDetails = function () {
        var _this = this;
        if (this.orderLogFetched) {
            this.orderLogs = this.orderLogDetails.orderLogs;
            var filteredOrderLogs = [];
            for (var index = 0; index < this.orderLogs.length; index++) {
                if (this.eventNames.indexOf(this.orderLogs[index].eventName) != -1) {
                    filteredOrderLogs.push(this.orderLogs[index]);
                }
                else if (this.orderLogs[index].eventName == 'order_updated' && this.orderLogs[index].updateType == 'dropLocation') {
                    this.orderLogs[index].eventName = 'dropChanged';
                    filteredOrderLogs.push(this.orderLogs[index]);
                }
            }
            this.orderLogs = filteredOrderLogs;
            if (this.orderLogs) {
                this.orderLogs.map(function (orderLog) {
                    orderLog.cityid = _this.orderCityId;
                    orderLog.location = orderLog.captainLocation || orderLog.customerLocation;
                });
                // add change drop if exist in correct pos
                if (this.destChange) {
                    var data = this.destChange;
                    var changeDropInfo = data['data'];
                    for (var index = 0; index < changeDropInfo.length; index++) {
                        if (changeDropInfo[index].update_type
                            && changeDropInfo[index].update_type === 'dropChanged') {
                            var changeDrop = {};
                            changeDrop['currStatus'] = 'dropChanged';
                            changeDrop['createdOn'] = changeDropInfo[index].updatedAt;
                            changeDrop['additionalInfo'] = '';
                            changeDrop['location'] = { lat: changeDropInfo[index].new_lat_lng.lat,
                                lng: changeDropInfo[index].new_lat_lng.lng
                            };
                            this.orderLogs.push(changeDrop);
                        }
                    }
                }
                this.orderLogService.fetchRiderLocation(this.orderLogs).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
                    var orderLogDetails, redirectUrl, mapsUrl, otherInfo, riderName, riderMobile, riderDetails, log, riderRedirectUrl;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!response) return [3 /*break*/, 2];
                                orderLogDetails = response;
                                redirectUrl = void 0;
                                mapsUrl = 'https://www.google.com/maps/?q=';
                                otherInfo = '', riderName = '', riderMobile = '', riderDetails = '';
                                return [4 /*yield*/, this.fetchRiderDetails()];
                            case 1:
                                _a.sent();
                                for (log in orderLogDetails) {
                                    if (orderLogDetails[log]) {
                                        otherInfo = '';
                                        if (orderLogDetails[log].additionalInfo) {
                                            otherInfo = orderLogDetails[log].additionalInfo.split(':')[1] + '% battery';
                                        }
                                        if (orderLogDetails[log].location && orderLogDetails[log].location.lat && orderLogDetails[log].location.lng) {
                                            redirectUrl = mapsUrl + orderLogDetails[log].location.lat + ',' + orderLogDetails[log].location.lng;
                                        }
                                        if (this.orderLogs && this.orderLogs[log] && this.orderLogs[log].captainId) {
                                            if (this.orderLogs[log].eventName && this.orderLogs[log].eventName !== 'aborted') {
                                                if (this.orderLogs[log].captain.firstName) {
                                                    riderName = this.orderLogs[log].captain.firstName;
                                                }
                                                if (this.orderLogs[log].captain.lastName) {
                                                    riderName += this.orderLogs[log].captain.lastName;
                                                }
                                                riderMobile = this.orderLogs[log].captain.mobile ? this.orderLogs[log].captain.mobile : '';
                                                this.riderName = riderName;
                                                this.riderNumber = riderMobile;
                                            }
                                            else {
                                                riderName = this.riderName;
                                                riderMobile = this.riderNumber;
                                            }
                                        }
                                        if (riderName && riderMobile) {
                                            riderDetails = riderName + '\n' + riderMobile;
                                        }
                                        else if (riderName && !riderMobile) {
                                            riderDetails = riderName;
                                        }
                                        else if (!riderName && riderMobile) {
                                            riderDetails = riderMobile;
                                        }
                                        else {
                                            riderDetails = '';
                                        }
                                        riderRedirectUrl = '/captain/' + riderMobile;
                                        this.dataRows.push({
                                            timeStamp: { data: orderLogDetails[log].updatedAt },
                                            status: { data: this.statusMap[orderLogDetails[log].eventName] ? this.statusMap[orderLogDetails[log].eventName] : '' },
                                            riderDetails: { data: riderDetails, redirectLink: riderRedirectUrl, className: riderRedirectUrl ? 'web-link' : '' },
                                            location: { data: orderLogDetails[log].locationName ? orderLogDetails[log].locationName.trim() : redirectUrl ? 'View Map' : '',
                                                redirectLink: redirectUrl, className: redirectUrl ? 'web-link' : '' },
                                            otherInfo: { data: otherInfo },
                                        });
                                    }
                                }
                                this.populateOrderLogs();
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); }, function (error) {
                    _this.dataLoaded = false;
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: 'Unable to fetch order log details',
                    }));
                });
            }
        }
    };
    OrderLogComponent.prototype.fetchRiderDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var captainIds, captainDetails, captainDetailsWithId, log, response, index, rider, log;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        captainIds = [];
                        captainDetailsWithId = {};
                        for (log in this.orderLogs) {
                            if (this.orderLogs && this.orderLogs[log] && this.orderLogs[log].captainId) {
                                captainIds.push(this.orderLogs[log].captainId);
                            }
                        }
                        if (!(captainIds.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.orderLogService.fetchRiderDetails(captainIds).toPromise()];
                    case 1:
                        response = _a.sent();
                        if (response['data'] && response['data'].rider) {
                            captainDetails = response['data'].rider;
                            for (index in captainDetails) {
                                if (captainDetails[index] && captainDetails[index].lastName) {
                                    captainDetails[index].name = captainDetails[index].firstName + ' ' + captainDetails[index].lastName;
                                }
                                else {
                                    captainDetails[index].name = captainDetails[index].firstName;
                                }
                            }
                            for (rider in captainDetails) {
                                if (captainDetails[rider] && captainDetails[rider]['_id']) {
                                    captainDetailsWithId[captainDetails[rider]['_id']] = captainDetails[rider];
                                }
                            }
                            for (log in this.orderLogs) {
                                if (this.orderLogs && this.orderLogs[log] && this.orderLogs[log].captainId) {
                                    this.orderLogs[log].captain = captainDetailsWithId[this.orderLogs[log].captainId];
                                }
                            }
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    OrderLogComponent.prototype.populateOrderLogs = function () {
        this.dataRows.sort(this.compare);
        for (var log in this.dataRows) {
            if (this.isValidTimeStamp(log)) {
                this.dataRows[log].timeStamp.data = this.getProperDate(this.dataRows[log].timeStamp.data);
            }
        }
        this.orderLogFetched = false;
        this.constructCard();
    };
    OrderLogComponent.prototype.isValidTimeStamp = function (log) {
        return this.dataRows[log] && this.dataRows[log].timeStamp && this.dataRows[log].timeStamp.data && typeof this.dataRows[log].timeStamp.data != "string";
    };
    OrderLogComponent.prototype.compare = function (a, b) {
        if (new Date(a.timeStamp.data) > new Date(b.timeStamp.data)) {
            return -1;
        }
        if (new Date(a.timeStamp.data) < new Date(b.timeStamp.data)) {
            return 1;
        }
        return 0;
    };
    OrderLogComponent.prototype.toggleState = function (status) {
        if (status === 'sl') {
            this.tableData = __assign({}, this.tableData, { data: this.dataRows });
        }
        else if (status === 'vm') {
            this.tableData = __assign({}, this.tableData, { data: [this.dataRows[0]] });
        }
    };
    return OrderLogComponent;
}());
export { OrderLogComponent };
