import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TollsService } from '../tolls.service';

@Component({
  selector: 'app-toll-info',
  templateUrl: './toll-info.component.html',
  styleUrls: ['./toll-info.component.css']
})

export class TollInfoComponent implements OnInit {
  @Input() entryTollLines: any;
  @Input() exitTollLines: any;
  @Input() tolls: any;

  associatedTollFound: boolean = false;
  currentAssociatedToll: any;
  entryExitTollLinesSelected: boolean = false;

  tollsFormGroup = new FormGroup({
    selectedEntryTollLine: new FormControl(null, Validators.required),
    selectedExitTollLine: new FormControl(null, Validators.required),
  })

  constructor(
    private tollService: TollsService
  ) { }

  ngOnInit() { }

  onTollLineChange() {
    const selectedEntryToll = this.tollsFormGroup.get("selectedEntryTollLine").value;
    const selectedExitToll = this.tollsFormGroup.get("selectedExitTollLine").value;
    if (selectedEntryToll && selectedExitToll) {
      this.entryExitTollLinesSelected = true;
      const toll = this.tollService.fetchAssociatedTollFromTollLines(selectedEntryToll,
        selectedExitToll, this.tolls);
      if (toll) {
        this.associatedTollFound = true;
        this.currentAssociatedToll = toll;
        return;
      } else {
        this.entryExitTollLinesSelected = true;
        this.associatedTollFound = false;
        return;
      }
    }
    this.associatedTollFound = false;
    this.currentAssociatedToll = false;
    this.entryExitTollLinesSelected = false;
    return;
  }
}