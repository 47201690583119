var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { OnInit } from '@angular/core';
import { TableData, ThumbnailCard, TableDataTypes } from 'src/app/shared/types';
import { ThumbnailCardTypes, RoundLevel } from 'src/app/shared/types/thumnail-card.types';
import { RecordTable } from 'src/app/shared/types/record-table.types';
import { CaptainService } from 'src/app/captain.service';
import { EntityService } from 'src/app/entity.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { CaptainSuspensionDailogComponent } from 'src/app/captain-suspension/captain-suspension-dailog/captain-suspension-dailog.component';
import { RevokeSuspensionDialogComponent } from 'src/app/captain-suspension/revoke-suspension/revoke-suspension-dialog.component';
import { DASHBOARD_SOURCE, ROLE_RIDER, SUSPENDED_STATUS, ACTIVE_STATUS, BLACKLIST, REVOKE } from 'src/app/shared/constants';
import { SvoService } from 'src/app/svo.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/appConfig.service';
import { KycService } from '../../kyc.service';
var IMAGES = {
    email: '/assets/svgImages/email_id_circle.svg',
    id: '/assets/svgImages/profile_circle.svg',
    status: '/assets/svgImages/profile_circle.svg',
    referral: '/assets/svgImages/referral_circle.svg',
    langularPreference: '/assets/svgImages/lang_pref_circle.svg',
    device: '/assets/svgImages/last_used_device_circle.svg',
    vehicle: '/assets/svgImages/vehicle.svg',
    pocSegment: '/assets/svgImages/profile_circle.svg',
    kyc: '/assets/svgImages/profile_circle.svg'
};
var STATUS_MAPPING = {
    '2': 'OnDuty',
    '2-6': 'onDutyOnWay',
    '4': 'Logout',
    '5': 'Unreachable',
    '6': 'onTheWay',
    '7': 'arrived',
    '8': 'started',
    '8-6': 'startedOnWay',
    '8-2': 'startedOnDuty',
    '9': 'offline'
};
var CAPTAIN_STATUS = {
    ACTIVE: 'Active',
    IN_ACTIVE: 'InActive',
    BLACKLISTED: 'Blacklisted'
};
var STATUS_CLASS_MAPPING = (_a = {},
    _a[CAPTAIN_STATUS.ACTIVE] = 'active-lable',
    _a[CAPTAIN_STATUS.IN_ACTIVE] = 'inactive-lable',
    _a[CAPTAIN_STATUS.BLACKLISTED] = 'blacklisted-lable',
    _a);
var SEGMENT_MAPPING = {
    'LP': 'Low performing',
    'MP': 'Mid Performing',
    'HP': 'High Performing',
    'UHP': 'Ultra High Performing',
};
var SAFETY_SEGMENT_MAPPING = {
    'UHR': 'Ultra High Risk',
    'HR': 'High Risk',
    'MR': 'Medium Risk',
    'LR': 'Low Risk',
    'NC': 'No Coverage',
};
var CaptainGeneralInfoComponent = /** @class */ (function () {
    function CaptainGeneralInfoComponent(dialog, captainService, appConfigService, entityService, svoService, kycService, toasterService) {
        var _this = this;
        this.dialog = dialog;
        this.captainService = captainService;
        this.appConfigService = appConfigService;
        this.entityService = entityService;
        this.svoService = svoService;
        this.kycService = kycService;
        this.toasterService = toasterService;
        this.revokeServiceGroups = [];
        this.isBlacklisted = false;
        this.blacklistedAt = null;
        this.userType = 'RIDER';
        this.suspensionPriority = {};
        this.suspendedUntil = {};
        this.suspendedReason = {};
        this.suspendCaptain = function () {
            var dialogRef = _this.dialog.open(CaptainSuspensionDailogComponent, {
                width: '525px',
                data: {
                    title: "Please Select Service and Reason for Suspension"
                }
            });
            dialogRef.afterClosed().subscribe(function (suspensionData) {
                var userDetails = _this.accessCookie('user');
                userDetails = JSON.parse(userDetails);
                var reason = (suspensionData.suspensionReason == 'Other') ? suspensionData.suspensionOtherReason : suspensionData.suspensionReason;
                var requestBody = {
                    'userId': _this.details.userId || _this.details.riderId,
                    'role': ROLE_RIDER,
                    'subServiceType': suspensionData.serviceGroup,
                    'status': SUSPENDED_STATUS,
                    'activateAfterMins': Number(suspensionData.minutes),
                    reason: reason,
                    'source': DASHBOARD_SOURCE,
                    'modifiedBy': userDetails['_id'] || '',
                };
                if (suspensionData.selectedSuspensionType == 'p0Issues') {
                    var permissions = _this.accessCookie('accessRoles');
                    if (!permissions.split(',').includes(REVOKE.P0_SUSPEND_PERMISSION)) {
                        _this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "You are not authorized to perform P0 suspension",
                            delay: 3000
                        }));
                        return;
                    }
                    requestBody['priority'] = '0';
                }
                if (!_this.details.userId && !_this.details.riderId) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "Unable suspend the captain due to tech issues",
                    }));
                    return;
                }
                _this.captainService.suspendCaptain(requestBody).subscribe(function (data) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.SUCCESS,
                        message: "Captain has been suspended successfully!",
                        delay: 3000
                    }));
                }, function (error) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "Unable suspend the captain",
                        delay: 3000
                    }));
                });
            });
        };
        this.revokeSuspension = function () {
            if (!_this.hasSuspensionRevokeAccess()) {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "You don't have access to revoke captain suspension",
                    delay: 3000
                }));
                return;
            }
            var dialogRef = _this.dialog.open(RevokeSuspensionDialogComponent, {
                width: '525px',
                data: {
                    title: "Please Select Service and Reason for Revoking Suspension",
                    serviceGroups: _this.revokeServiceGroups,
                    suspensionPriority: _this.suspensionPriority,
                    suspendedReason: _this.suspendedReason,
                    suspendedUntil: _this.suspendedUntil,
                    hasP0SuspensionRevocationPermission: _this.hasP0SuspensionRevokeAccess()
                }
            });
            dialogRef.afterClosed().subscribe(function (suspensionData) {
                var userDetails = _this.accessCookie('user');
                userDetails = JSON.parse(userDetails);
                var requestBody = {
                    'userId': _this.details.userId || _this.details.riderId,
                    'role': ROLE_RIDER,
                    'subServiceType': [suspensionData.serviceGroup],
                    'status': ACTIVE_STATUS,
                    'reason': suspensionData.suspensionReason,
                    'source': DASHBOARD_SOURCE,
                    'modifiedBy': userDetails['_id'] || '',
                    'byPassChecks': {
                        'lms': true,
                        'validTillTime': true
                    }
                };
                if (!_this.details.userId && !_this.details.riderId) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "Unable to revoke captain suspension due to tech issues",
                    }));
                    return;
                }
                _this.captainService.unsuspendCaptain(requestBody).subscribe(function (data) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.SUCCESS,
                        message: "Captain's suspension has been revoked successfully!",
                    }));
                }, function (error) {
                    _this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "Unable to revoke captain suspension",
                    }));
                });
            });
        };
        this.revokeBlacklist = function (riderId) {
            var confirmDialogRef = _this.dialog.open(ConfirmDialogComponent, {
                width: '335px',
                data: { title: 'Are you sure you want to revoke blacklist for this captain?',
                    type: 'order-details',
                    buttonText: 'Yes, revoke'
                }
            });
            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                if (confirmResult) {
                    _this.entityService.unblockCaptain(_this.device).subscribe(function (result) {
                        _this.toasterService.showToaster(new Toaster({
                            type: ToasterType.SUCCESS,
                            message: "Captain has been unblocked successfully!",
                        }));
                        _this.getBlacklistStatus();
                    }, function (error) {
                        _this.toasterService.showToaster(new Toaster({
                            type: ToasterType.WARNING,
                            message: "Something went wrong. Please try again.",
                        }));
                    });
                }
            });
        };
    }
    CaptainGeneralInfoComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var withEarnings, withReferralDetails, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        withEarnings = false;
                        withReferralDetails = true;
                        this.captainService.captainDetails(this.mobile, null, null, null, withEarnings, withReferralDetails).subscribe(function (details) { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b, _c, _d;
                            var _this = this;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        this.details = details;
                                        this.captainId = this.details.userId;
                                        _a = this;
                                        return [4 /*yield*/, this.getCaptainSegment()];
                                    case 1:
                                        _a.segmentDetails = _e.sent();
                                        _b = this;
                                        return [4 /*yield*/, this.getCaptainNudgeDetails()];
                                    case 2:
                                        _b.nudgeData = _e.sent();
                                        this.segment = this.segmentDetails && this.segmentDetails['segment'] === 'HH' ? '(HH)' : '';
                                        details.pocSegment = this.segmentDetails ? this.details.mode === 'Bike' ? this.segmentDetails['pocSegment'] : this.segmentDetails['autoPcSegment'] : '';
                                        _c = this;
                                        return [4 /*yield*/, this.getCaptainSafetySegment()];
                                    case 3:
                                        _c.safetySegmentDetails = _e.sent();
                                        _d = details;
                                        return [4 /*yield*/, this.mapSafetySegment(this.safetySegmentDetails)];
                                    case 4:
                                        _d.safetySegment = _e.sent();
                                        return [4 /*yield*/, this.entityService.getRefereeDetails(this.captainId, this.userType, this.mobile).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                                var details_1;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (response && response.result && response.result.referrerUserDetails) {
                                                                details_1 = response.result.referrerUserDetails;
                                                                this.refereeDetails = details_1.name + "   " + details_1.mobile + "   " + details_1.referralCode;
                                                            }
                                                            return [4 /*yield*/, this.generateCaptainDetailsTable(details)];
                                                        case 1:
                                                            _a.sent();
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); })];
                                    case 5:
                                        _e.sent();
                                        this.getBlacklistStatus();
                                        details.segment = this.segment;
                                        this.captainService.getUserServices(this.captainId, true)
                                            .subscribe(function (serviceGroupStatusMap) {
                                            console.log('ServiceGroupStatusMap to check the suspension status: ', serviceGroupStatusMap);
                                            for (var property in serviceGroupStatusMap) {
                                                if (serviceGroupStatusMap[property] === SUSPENDED_STATUS) {
                                                    _this.revokeServiceGroups.push(property.toUpperCase());
                                                }
                                            }
                                            if (serviceGroupStatusMap['priority']) {
                                                for (var subServiceType in serviceGroupStatusMap['priority']) {
                                                    _this.suspensionPriority[subServiceType.toUpperCase()] = serviceGroupStatusMap['priority'][subServiceType];
                                                }
                                            }
                                            if (serviceGroupStatusMap['suspendedReason']) {
                                                for (var subServiceType in serviceGroupStatusMap['suspendedReason']) {
                                                    _this.suspendedReason[subServiceType.toUpperCase()] = serviceGroupStatusMap['suspendedReason'][subServiceType];
                                                }
                                            }
                                            if (serviceGroupStatusMap['suspendedUntil']) {
                                                for (var subServiceType in serviceGroupStatusMap['suspendedUntil']) {
                                                    var suspendedUntilTime = 'NA';
                                                    if (serviceGroupStatusMap['suspendedUntil'][subServiceType]) {
                                                        suspendedUntilTime = moment(serviceGroupStatusMap['suspendedUntil'][subServiceType]).format('YYYY-MM-DD HH:mmss');
                                                    }
                                                    _this.suspendedUntil[subServiceType.toUpperCase()] = suspendedUntilTime;
                                                }
                                            }
                                        }, function (error) {
                                            console.error('Error while fetching revoke suspension', error);
                                        });
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        _a = this;
                        return [4 /*yield*/, this.appConfigService.getSuspensionConfig()];
                    case 1:
                        _a.suspensionConfig = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.getCaptainSegment = function () {
        return __awaiter(this, void 0, void 0, function () {
            var s;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.svoService.getCaptainSegment(this.captainId)];
                    case 1:
                        s = _a.sent();
                        return [2 /*return*/, s];
                }
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.getCaptainSafetySegment = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.svoService.getCaptainSafetySegment(this.captainId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.mapPOCSegment = function (pocSegment) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (SEGMENT_MAPPING[pocSegment.split('_')[0]]) {
                    return [2 /*return*/, SEGMENT_MAPPING[pocSegment.split('_')[0]]];
                }
                else {
                    return [2 /*return*/, 'N/A'];
                }
                return [2 /*return*/];
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.mapSafetySegment = function (safetySegmentDetails) {
        return __awaiter(this, void 0, void 0, function () {
            var safetySegment;
            return __generator(this, function (_a) {
                if (safetySegmentDetails) {
                    if (safetySegmentDetails['safety_profile'] && safetySegmentDetails['safety_profile'] != '' && SAFETY_SEGMENT_MAPPING[safetySegmentDetails['safety_profile']]) {
                        safetySegment = SAFETY_SEGMENT_MAPPING[safetySegmentDetails['safety_profile']];
                        if (safetySegmentDetails['safety_proximity'] && safetySegmentDetails['safety_proximity'] != '') {
                            safetySegment = safetySegment + (" (Proximity:" + safetySegmentDetails['safety_proximity'] + ")");
                        }
                        else {
                            safetySegment = safetySegment + " (Proximity:None)";
                        }
                        return [2 /*return*/, safetySegment];
                    }
                    else {
                        return [2 /*return*/, 'N/A'];
                    }
                }
                else {
                    return [2 /*return*/, 'N/A'];
                }
                return [2 /*return*/];
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.getBlacklistStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.entityService.isBlacklisted(this.device, this.captainId).subscribe(function (result) {
                    _this.isBlacklisted = result['blacklisted'] || false;
                    _this.blacklistedAt = result['updatedAt'] ? moment(new Date(0).setUTCSeconds(result['updatedAt'])).format('DD-MM-YYYY hh:mm a') : null;
                    _this.generateCaptainDetailsTable(_this.details);
                });
                return [2 /*return*/, false];
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    CaptainGeneralInfoComponent.prototype.hasBlacklistRevokeAccess = function () {
        var permissions = this.accessCookie('accessRoles');
        return permissions.split(',').includes(BLACKLIST.REVOKE_BLACKLIST);
    };
    CaptainGeneralInfoComponent.prototype.hasSuspensionRevokeAccess = function () {
        var permissions = this.accessCookie('accessRoles');
        return permissions.split(',').includes(REVOKE.SUSPEND_PERMISSION);
    };
    CaptainGeneralInfoComponent.prototype.hasP0SuspensionRevokeAccess = function () {
        var permissions = this.accessCookie('accessRoles');
        return permissions.split(',').includes(REVOKE.P0_SUSPEND_REVOKE_PERMISSION);
    };
    CaptainGeneralInfoComponent.prototype.redeemForCaptain = function (riderId, shiftName, city) {
        var _this = this;
        var user = this.accessCookie('user');
        user = JSON.parse(user);
        this.captainService.redeemCaptain(riderId, shiftName, city, user['_id']).subscribe(function (response) {
            if (response['info'] === 'success') {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.SUCCESS,
                    message: 'Redeem enabled',
                }));
            }
            else {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: 'Could not enable redeem',
                }));
            }
        }, function (err) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: err && err.error && err.error.message && err.error.message.message ?
                    err.error.message.message : 'Could not enable redeem',
            }));
        });
    };
    CaptainGeneralInfoComponent.prototype.logoutCaptain = function (riderId) {
        var _this = this;
        this.captainService.logoutCaptain(riderId).subscribe(function (response) {
            if (response && response['data'] &&
                response['data'].status && response['data'].status === 'success') {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.SUCCESS,
                    message: response['message'],
                }));
            }
            else {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: response['message'],
                }));
            }
        });
    };
    CaptainGeneralInfoComponent.prototype.getCaptainStatus = function (details) {
        return this.isBlacklisted ? CAPTAIN_STATUS.BLACKLISTED : (details.active ? CAPTAIN_STATUS.ACTIVE : CAPTAIN_STATUS.IN_ACTIVE);
    };
    CaptainGeneralInfoComponent.prototype.getCaptainNudgeDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var s;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.kycService.getCaptainNudgeDetails(this.captainId)];
                    case 1:
                        s = _a.sent();
                        return [2 /*return*/, s];
                }
            });
        });
    };
    CaptainGeneralInfoComponent.prototype.generateCaptainDetailsTable = function (details) {
        var _this = this;
        var vehicleAdditionalInfo = '';
        if (details && details.vehicleDetails) {
            if (details.vehicleDetails.color) {
                vehicleAdditionalInfo += 'Color : ' + details.vehicleDetails.color + '\n';
            }
            if (details.vehicleDetails.make) {
                vehicleAdditionalInfo += 'Make : ' + details.vehicleDetails.make + '\n';
            }
        }
        if (details.license && details.license.number) {
            vehicleAdditionalInfo += 'DL number : ' + details.license.number;
        }
        var thumbnailImageStyle = {
            'border-radius': '30px',
            'margin-bottom': '-12px',
            'margin-top': '-12px',
            'background-size': 'contain'
        };
        var tdStyle = {
            'padding': '0px 20px'
        };
        this.menuOptions = {
            ellipses: [
                {
                    label: 'Enable Redeem',
                    onClick: function () {
                        _this.redeemForCaptain(_this.details.riderId, _this.details.shift, _this.captainDetails.city.displayName);
                    }
                },
                {
                    label: 'Suspend',
                    onClick: function () {
                        _this.suspendCaptain();
                    }
                },
                {
                    label: 'Revoke Suspension',
                    onClick: function () {
                        _this.revokeSuspension();
                    }
                },
                {
                    label: 'Logout Captain',
                    onClick: function () {
                        _this.logoutCaptain(_this.details.riderId);
                    }
                }
            ]
        };
        if (this.hasBlacklistRevokeAccess() && this.isBlacklisted) {
            this.menuOptions['ellipses'].push({
                label: 'Un-blacklist Captain',
                onClick: function () {
                    _this.revokeBlacklist(_this.details.riderId);
                }
            });
        }
        // @ts-ignore
        var captainDetails = [
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.id,
                    title: details.name + " " + details.segment,
                    data: details.mobileNumber,
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: tdStyle,
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.status,
                    title: 'Status',
                    data: {
                        toString: function () {
                            return _this.getCaptainStatus(details);
                        },
                        className: STATUS_CLASS_MAPPING[this.getCaptainStatus(details)],
                    },
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    },
                    info: this.isBlacklisted ? "" + this.blacklistedAt : null,
                }),
                type: TableDataTypes.CARD,
                style: {
                    'padding': '0px 20px'
                }
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.email,
                    title: 'Email Id',
                    data: details.emailid,
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: {
                    'padding': '0px 20px'
                }
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.vehicle,
                    title: 'Vehicle',
                    data: details.vehicleDetails.number,
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    info: vehicleAdditionalInfo,
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: {
                    'padding': '0px 20px'
                }
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.pocSegment,
                    title: 'Captain Segmentation',
                    data: details.pocSegment,
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: tdStyle,
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.pocSegment,
                    title: 'Captain Risk Segment',
                    data: details.safetySegment,
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: tdStyle,
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.referral,
                    title: 'Referral Code',
                    data: details.referralCode,
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: tdStyle,
            }),
            new TableData({
                card: new ThumbnailCard({
                    type: ThumbnailCardTypes.MINIMAL_THUMBNAIL,
                    imageUrl: IMAGES.referral,
                    title: 'Referee',
                    data: this.refereeDetails ? this.refereeDetails : 'Organic User',
                    info: 'raghu test',
                    style: {
                        'padding-top': 0,
                        'padding-bottom': 0,
                    },
                    config: {
                        imageRound: true,
                        roundLevel: RoundLevel.ONE,
                        thumbnailImageStyle: thumbnailImageStyle,
                    }
                }),
                type: TableDataTypes.CARD,
                style: tdStyle,
            }),
            new TableData({
                type: TableDataTypes.KYC_CARD,
                data: [{ headerClass: 'header-card-color',
                        matExpansionPanelContent: [{ header: 'RC',
                                value: this.nudgeData && this.nudgeData.rc ? this.nudgeData.rc : 'NA' },
                            { header: 'DL',
                                value: this.nudgeData && this.nudgeData.dl ? this.nudgeData.dl : 'NA' }],
                        isExpansion: true, matExpansionPanelHeader: 'KYC', imageUrl: IMAGES.kyc }],
                style: tdStyle
            })
        ];
        var recordTable = new RecordTable({
            data: captainDetails,
            header: {
                toString: function () {
                    return 'General Info';
                },
                style: function () {
                    return {
                        'color': '#9aa0ac',
                        'line-height': '26px',
                        'font-size': '16px',
                    };
                }
            },
            style: {
                'height': '324px',
                'overflow-x': 'scroll',
            },
            config: this.menuOptions
        });
        this.generalInfoTable = recordTable.table;
    };
    return CaptainGeneralInfoComponent;
}());
export { CaptainGeneralInfoComponent };
