import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-linear-progress',
  templateUrl: './linear-progress.component.html',
  styleUrls: ['./linear-progress.component.css']
})
export class LinearProgressComponent implements OnInit, OnChanges {

  constructor() { }
  @Input() min = 0;
  @Input() max;
  @Input() completed;
  @Input() progressStatus;
  @Input() width;

  percentage = '0%';
  classForColor;
  colorMapping = {
    'NOT_STARTED': 'grey-progress-bar',
    'STARTED': 'yellow-progress-bar',
    'COMPLETED': 'green-progress-bar',
    'EXPIRED': 'black-progress-bar',
    'ACHIEVED' : 'green-progress-bar',
    'ONGOING': 'yellow-progress-bar'
  };

  ngOnInit() {
    this.progressBarStyling();
  }

  ngOnChanges() {
    this.progressBarStyling();
  }

  progressBarStyling() {
    if (this.progressStatus !== 'NOT_STARTED') {
      this.percentage = ((this.completed / this.max) * 100) + '%';
    }
    this.classForColor = this.colorMapping[this.progressStatus];
  }

}
