import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bill-of-supply',
  templateUrl: './bill-of-supply.component.html',
  styleUrls: ['./bill-of-supply.component.css']
})
export class BillOfSupplyComponent implements OnInit {

    @Input() data: any;
    rideId: any;
    invoiceNumber: any;
    invoiceDate: any;
    state: any;
    riderTaxCategory: any;
    placeOfSupply: any;
    gstTin: any;
    captainName: any;
    bikeNumber: any;
    customerName: any;
    pickupLocationAddress: any;
    captainFee: string;
    total: string | number;
    gst: string;
    service: string;
    merchantName: string;
    merchantAddress: string;
    merchantGstNumber: string;
    isCorporateOrder: boolean;
    clientName: string;
    cgst: string;
    cgstVal: string;
    sgst: string;
    igst: string;
    sgstVal: string;
    igstVal: string;

    constructor() { }

    ngOnInit() {
        this.formatData();
    }

    getPercentage = (val: any) => {
        return Number(Number(val * 100).toFixed(2));
    }

    formatData = () => {
        const data = this.data;
        this.rideId = data.rideId;
        this.invoiceNumber = data.invoiceNumber;
        this.invoiceDate = data.invoiceDate;
        this.state = data.state;
        this.riderTaxCategory = data.riderTaxCategory;
        this.placeOfSupply = data.placeOfSupply;
        this.gstTin = data.gstTin;
        this.captainName = data.captainName;
        this.bikeNumber = data.bikeNumber;
        this.customerName = data.customerName;
        this.pickupLocationAddress = data.pickupLocationAddress;
        this.captainFee = data.riderCharge && `₹ ${data.riderCharge.withoutTax}` || '0';
        this.total = data.riderCharge && `₹ ${data.riderCharge.total}` || '0';

        this.cgst = data.tax && data.tax.captain && `(${this.getPercentage(data.tax.captain.cgst)}%)` || '(0%)';
        this.sgst = data.tax && data.tax.captain && `(${this.getPercentage(data.tax.captain.sgst)}%)` || '(0%)';
        this.igst = data.tax && data.tax.captain && `(${this.getPercentage(data.tax.captain.igst)}%)` || '(0%)';
        this.cgstVal = data.riderCharge && `₹ ${data.riderCharge.cgst}` || '0';
        this.sgstVal = data.riderCharge && `₹ ${data.riderCharge.sgst}` || '0';
        this.igstVal = data.riderCharge && `₹ ${data.riderCharge.igst}` || '0';

        this.gst = '₹ 0.00'; // GST for the bill of supply.
        this.service = data.service.toLowerCase();
        this.merchantName = data.merchantName;
        this.merchantAddress = data.merchantAddress;
        this.merchantGstNumber = data.merchantGstNumber;
        this.isCorporateOrder = data.isCorporateOrder;
        this.clientName = data.clientName;
    }
}