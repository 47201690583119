<div *ngIf="!isMinimalType()" class="card card-aside" [ngStyle]="card.style" style="
padding-top: 7px !important;
">
    <!-- <div *ngIf="!isMinimalType()" class="card card-aside row" [ngStyle]="card.style"> -->
  <!-- <div class="row"> -->
    <!-- <div [class]="card.thumbnailPartClass" > -->
        <ng-container [ngSwitch]="card.type">
          <ng-container *ngSwitchCase="thumnailCardTypes.SQUARE_IMAGE">
            <a [ngStyle]="thumbnailPartStyle"  href="javascript:void(0)" class="card-aside-column" [style.background-image]="card.imageStyle"></a>
            <!-- <a [style]="thumbnailPartStyle(card.imageStyle)"  href="javascript:void(0)" class="card-aside-column" [style.background-image]="card.imageStyle"></a> -->
          </ng-container>
          <ng-container *ngSwitchCase="thumnailCardTypes.ROUND_IMAGE">
            <div class="card-aside-column make-item-center" [ngStyle]="thumbnailPartStyle">
                <div [class]="card.avatarClass" [ngStyle]="card.config.thumbnailImageStyle" [style.background-image]="card.imageStyle"></div>
              </div>
          </ng-container>
        </ng-container>
        <!-- </div> -->
        <!-- <div [class]="card.infoPartClass"> -->
          <div class="card-body d-flex flex-column">
            <h4 *ngIf="card.title" ><p class="make-text-break clip-text-break font-size-18" style="
              margin-bottom: 0px;
          " [attr.title]="card.title">{{card.title}}</p></h4>
            <div *ngIf="card.data"><p class="make-text-break clip-text-break" [attr.title]="card.data" [ngClass]="card.data?.className">{{card.data}}</p></div>
            <!-- <div *ngIf="card.data"><p class="make-text-break">{{card.data}}</p></div> -->
            <div *ngIf="card.info" class="text-muted make-text-break">{{card.info}}</div>
          </div>
        <!-- </div> -->
      <!-- </div> -->
  </div>


  <div *ngIf="isMinimalType()" class="d-flex align-items-center pt-5 mt-auto" style="
  padding-bottom: 5px !important;
  padding-top: 0px !important;
  ">
    <div [ngStyle]="thumbnailPartStyle" [class]="card.avatarClass" [style.background-image]="card.imageStyle"></div>
    <div>
      <p class="text-default make-text-break clip-text-break font-size-18" style="
        margin-bottom: 0px;" [attr.title]="card.title">{{card.title}}
      </p>
      <div class="row" style="margin: 0">
        <div *ngIf="card.data"><p class="make-text-break clip-text-break" [attr.title]="card.data" [ngClass]="card.data?.className">{{card.data}}</p></div>
        <!-- <div *ngIf="card.data"><p class="make-text-break">{{card.data}}</p></div> -->
        <!-- <small class="d-block text-muted make-text-break clip-text-break" [attr.title]="card.info">{{card.info}}</small> -->
        <span *ngIf="card.info" class="icon-alignment preserve-whitespace" style="width: fit-content;" tooltipPosition="bottom" pTooltip="{{card.info}}">
          <i class="material-icons-outlined card-info cursor-pointer icon-placement">
            info
          </i>
        </span>
      </div>
    </div>
  </div>
