import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { faRupeeSign, faRedo } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion'

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.css']
})
export class CardTableComponent implements OnInit, OnChanges {
  faInr = faRupeeSign;
  faRefresh = faRedo;
  dataLoaded = false;
  @Input() info: any;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonIsClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() popupEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() infoIsToggled: EventEmitter<MatSlideToggleChange> = new EventEmitter<MatSlideToggleChange>();
  @Output() matExpansionIsClicked: EventEmitter<MatExpansionModule> = new EventEmitter<MatExpansionModule>();

  constructor(public _route: Router) { }

  ngOnChanges(changes: SimpleChanges) {
    this.dataLoaded = false;
    if (changes.info && changes.info.currentValue) {
      this.dataLoaded = true;
    }
  }

  refreshData() {
    this.dataLoaded = false;
    this.refresh.emit();
  }

  buttonClicked(buttonData) {
    this.buttonIsClicked.emit(buttonData);
  }

  infoToggled($event) {
    this.infoIsToggled.emit($event);
  }

  ngOnInit() {
  }

  redirectRoute(info) {
    if (info && info.route) {
      const link = '/' + info.type + '/' + info.route;
      window.open(link, '_blank');
    }
  }

  showPopup(info){
    this.popupEvent.emit(info)
  }
  
  matExpansionClicked($event){
    this.matExpansionIsClicked.emit($event);
  }
}
