import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client.service";
var CustomerTransactionDetailsService = /** @class */ (function () {
    function CustomerTransactionDetailsService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getCustomerPassbook = this.server + '/api/payments/getCustomerPassbook';
    }
    CustomerTransactionDetailsService.prototype.getCustomerPassbookDetails = function (customerId, limit, offset, userId, filters) {
        if (filters === void 0) { filters = {}; }
        return this.http.post(this.getCustomerPassbook, { customerId: customerId, limit: limit, offset: offset, userId: userId, filters: filters })
            .pipe(catchError(this.handleError));
    };
    CustomerTransactionDetailsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CustomerTransactionDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function CustomerTransactionDetailsService_Factory() { return new CustomerTransactionDetailsService(i0.inject(i1.MyHttpClient)); }, token: CustomerTransactionDetailsService, providedIn: "root" });
    return CustomerTransactionDetailsService;
}());
export { CustomerTransactionDetailsService };
