import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
// import { AdjustmentForm, FormComponents } from '../shared/types/adjustment-form.types';
@Component({
  selector: 'app-adjustment-forms',
  templateUrl: './adjustment-forms.component.html',
  styleUrls: ['./adjustment-forms.component.css']
})
export class AdjustmentFormsComponent implements OnInit, OnChanges {
  public valuesStored = [];
  public storedData: any = {};
  @Input() disableSubmit: any;
  @Input() info: any;
  @Input() success: any;
  @Output() adjustmentDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeRows: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
  if(changes && changes.success && changes.success.currentValue){
    this.resetDetails();
  }
  }

  ngOnInit() {
  }

  clearCapDetails() {
    if (this.storedData) {
      if (this.storedData['capt-adj-other-reason']) {
        this.storedData['capt-adj-other-reason'] = '';
      }
      if (this.storedData['capt-adj-amount']) {
        this.storedData['capt-adj-amount'] = '';
      }
      if (this.storedData['capt-adj-action']) {
        this.storedData['capt-adj-action'] = '';
      }
      if (this.storedData['capt-adj-reason']) {
        this.storedData['capt-adj-reason'] = '';
      }
    }
  }

  clearCustDetails() {
    if (this.storedData) {
      if (this.storedData['cust-adj-other-reason']) {
        this.storedData['cust-adj-other-reason'] = '';
      }
      if (this.storedData['cust-adj-amount']) {
        this.storedData['cust-adj-amount'] = '';
      }
      if (this.storedData['cust-adj-wallet']) {
        this.storedData['cust-adj-wallet'] = '';
      }
      if (this.storedData['cust-adj-reason']) {
        this.storedData['cust-adj-reason'] = '';
      }
      if(this.storedData['cust-adj-ticket']) {
        this.storedData['cust-adj-ticket'] = '';
      }
    }
  }

  updateData($evt, key) {
    const adjustment = ['Refund', 'Debit', 'Update KM'];
    if (adjustment.indexOf($evt[key]) >= 0) {
      // means Refund Debit Update.
      // FInd cust or cap
      if (key.includes('capt')) {
        this.clearCapDetails();
      } else if (key.includes('cust')) {
        this.clearCustDetails();
      }
    }
    if ($evt && $evt[key]) {
      if (key !== 'capt-adj-other-reason' && key !== 'cust-adj-other-reason' && key !== 'cust-adj-wallet'
          && key !== 'cust-adj-amount' && key !== 'capt-adj-amount' && key !== 'capt-adj-distance') {
        this.changeRows.emit($evt[key]);
      }
     if (($evt[key] instanceof Array && $evt[key].length === 0) ||
           (typeof($evt[key]) === 'object' && Object.keys($evt[key]) && Object.keys($evt[key]).length === 0)) {
         if (this.valuesStored.indexOf(key) !== -1) {
           this.valuesStored.splice(this.valuesStored.indexOf(key), 1);
         }
     } else if (this.valuesStored.indexOf(key) === -1) {
         this.valuesStored.push(key);
       }
    }
    if ($evt && !$evt[key]) {
     if (this.valuesStored.indexOf(key) !== -1) {
       this.valuesStored.splice(this.valuesStored.indexOf(key), 1);
     }
    }
     this.storedData[key] = $evt[key];
  }

  submitDetails() {
   this.adjustmentDetails.emit(this.storedData);
  }

  resetDetails(){
    this.storedData = {};
  }
}
