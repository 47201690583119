import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { UserTicketingService } from '../user-tickets.service';
import { CustActivityHistoryService } from './cust-activity-history.service';
import { CUSTOMER_ACTIVITY_HISTORY } from '../roles.constants';
import {FRESH_DESK_TICKETS_URL, TAGS, USER_TYPES} from 'src/app/shared/constants';

@Component({
  selector: 'app-cust-activity-history',
  templateUrl: './cust-activity-history.component.html',
  styleUrls: ['./cust-activity-history.component.css']
})
export class CustActivityHistoryComponent implements OnInit, OnChanges {

  @Input() custActHistory: any;
  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };
  dataRows = [];
  tableData: any;
  accessDenied = true;
  classStatus = {
    'New': 'status-blue',
    'Open': 'status-yellow',
    'Pending': 'status-yellow',
    'Hold': 'status-yellow',
    'Solved': 'status-green',
    'Resolved': 'status-green',
    'Closed': 'status-grey'
  };

  ticketStatus = {
      2: 'Open',
      3: 'Pending',
      4: 'Resolved',
      5: 'Closed'
  };

  constructor(private custActHistoryService: CustActivityHistoryService, private ticketService: UserTicketingService) { }

  ngOnInit() {
  }

  loadRequestCard() {
    const cookie = this.accessCookie('Roles');
    const roles = cookie.split(',');
    if ((roles.some(roleValid => CUSTOMER_ACTIVITY_HISTORY.indexOf(roleValid) >= 0))) {
      this.accessDenied = false;
    }
    this.tableData = {
      tableHeader: 'User Activity History',
      data: null,
      headers: null,
      accessDenied: this.accessDenied,
      loadOnRequest: true,
      dataFetched: true
    };
  }

  toggleState(status) {
    if (status === 'sl') {
      this.tableData = {...this.tableData, data: this.dataRows};
    } else if (status === 'vm') {
      this.tableData = {...this.tableData, data: [this.dataRows[0]]};
    }
  }

  constructCard() {
    this.tableData = {
      tableHeader: 'User Activity History',
      headers: { createdOn: 'Created On', description: 'Description', subject: 'Subject', id: '#ID', status: 'Status', userType: 'User Type'},
      data: [this.dataRows[0]],
      title: ['subject', 'description'],
      dataFetched: true,
      accessDenied: this.accessDenied,
      tableHeight: 'max-height-177',
      expandTable: this.dataRows.length > 1 ? true : false,
      loadOnRequest: false
    };
  }

  emptyCard(message?) {
    this.tableData = {
      tableHeader: 'User Activity History',
      headers: null,
      data: null,
      emptyMessage: message || 'No tickets found',
      dataFetched: false,
      accessDenied: false,
      loadOnRequest: false
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.custActHistory && changes.custActHistory.currentValue) {
      if (changes.custActHistory.currentValue.dataFetched && changes.custActHistory.currentValue.id) {
        this.getTickets();
        this.loadRequestCard();
      } else {
        this.emptyCard('Cannot load data');
      }
    }
  }

  reomveExtraWhiteSpaces = (string: any) => string.replace(/\s+/g, ' ').trim();

  getUserType(ticket) {
    const tags = ticket.tags;
    if (tags && tags.length > 0) {
      if (tags.includes(TAGS.CUSTOMER_TAG)) {
        return USER_TYPES.CUSTOMER;
      } else if (tags.includes(TAGS.CAPTAIN_TAG)) {
        return USER_TYPES.CAPTAIN;
      }
    }
  }

  getTickets() {

    if (this.custActHistory && this.custActHistory.userId && this.custActHistory.id) {
      const query = JSON.stringify({
        "booking_id": this.custActHistory.id
      });
      const userType = 'customer';

      this.ticketService.fetchUserTicketsV2(this.custActHistory.userId, userType, query, 1).subscribe(result => {
        if (result && result['tickets'] && result['tickets'].length > 0) {
          const tickets = result['tickets'];
          tickets.map(ticket => {
            const userType = this.getUserType(ticket);
            if (!(userType === USER_TYPES.CAPTAIN || userType === USER_TYPES.CUSTOMER)) {
              return;
            }
            const ticketStatus = ticket.status && this.ticketStatus[ticket.status] || 'N/A';
            const description = ticket.description_text && this.reomveExtraWhiteSpaces(ticket.description_text) || 'N/A';
            this.dataRows.push({
              id: {
                data: ticket.ticket_id ? ticket.ticket_id : 'N/A', redirectLink: ticket.ticket_id ?
                  this.getFreshDeskRedirectUrl(ticket.ticket_id) : '', className: ticket.ticket_id ? 'web-link' : ''
              },
              createdOn: {
                data: ticket.updated_at && this.getProperDate(ticket.updated_at) || 'N/A',
                className: 'preserve-whitespace'
              },
              subject: { data: ticket.subject && ticket.subject || 'N/A' },
              userType: { data: userType },
              description: { data: description },
              status: {
                data: ticketStatus, className: this.classStatus[ticketStatus] ? this.classStatus[ticketStatus] : 'status-yellow'
              }

            });
          });
          if (this.dataRows.length === 0) {
            this.emptyCard();
          } else {
            this.constructCard();
          }
        } else {
          this.emptyCard();
        }
      });
    } else {
      this.emptyCard();
    }
  }

  getProperDate(date) {
    const timeInfo = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
    const today = new Date(date);
    let dd: any = today.getDate();
    const mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear().toString().substr(-2);

    if (dd < 10) {
      dd = '0' + dd;
    }
    const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
    return (dateInfo + '\n' + timeInfo);
  }

  getProperUrl(id) {
    const newUrl = 'https://rapidodesk.zendesk.com/agent/tickets/' + id;
    return newUrl;
  }

  getFreshDeskRedirectUrl = ticketId => `${FRESH_DESK_TICKETS_URL}/${ticketId}`;

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }
}
