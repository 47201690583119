/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i3 from "ngx-loading";
import * as i4 from "./loader.component";
import * as i5 from "../loader.service";
var styles_LoaderComponent = [i0.styles];
var RenderType_LoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
function View_LoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "custom-class"]], null, null, null, null, null))], null, null); }
export function View_LoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "my-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["customLoadingTemplate", 2]], null, 0, null, View_LoaderComponent_1)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ngx-loading", [], null, null, null, i2.View_NgxLoadingComponent_0, i2.RenderType_NgxLoadingComponent)), i1.ɵdid(3, 114688, null, 0, i3.NgxLoadingComponent, [i3.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"], config: [1, "config"], template: [2, "template"] }, null), i1.ɵpod(4, { backdropBorderRadius: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaderService.loading; var currVal_1 = _ck(_v, 4, 0, "3px"); var currVal_2 = i1.ɵnov(_v, 1); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoaderComponent, [i5.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderComponentNgFactory = i1.ɵccf("app-loader", i4.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
