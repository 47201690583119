var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FareEstimateLogService } from './fare-estimate-log.service';
import { EntityService } from '../entity.service';
import { combineLatest } from 'rxjs';
var FareEstimateLogComponent = /** @class */ (function () {
    function FareEstimateLogComponent(FareEstimateLogService, EntityService) {
        this.FareEstimateLogService = FareEstimateLogService;
        this.EntityService = EntityService;
        this.dataRows = [];
        this.servicesMap = {};
    }
    FareEstimateLogComponent.prototype.toggleState = function (status) {
        if (status === 'sl') {
            this.tableData = __assign({}, this.tableData, { data: this.dataRows });
        }
        else if (status === 'vm') {
            this.tableData = __assign({}, this.tableData, { data: [this.dataRows[0]] });
        }
    };
    FareEstimateLogComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.pageRefreshed && changes.pageRefreshed.currentValue) {
            this.dataRows = [];
        }
        if (changes && changes.fareEstimateLogs && changes.fareEstimateLogs.currentValue) {
            if (changes.fareEstimateLogs.currentValue.deliveryOrder) {
                this.emptyCard('Not available for Delivery orders');
            }
            else if (changes.fareEstimateLogs.currentValue.dataFetched) {
                this.constructCard();
            }
            else {
                this.emptyCard();
            }
        }
    };
    FareEstimateLogComponent.prototype.ngOnInit = function () { };
    FareEstimateLogComponent.prototype.constructCard = function () {
        var _this = this;
        if (this.fareEstimateLogs && this.fareEstimateLogs['fareEstimateLogs'] && this.fareEstimateLogs['fareEstimateLogs'].length > 0) {
            var firstFive = this.fareEstimateLogs['fareEstimateLogs'].slice(0, 5);
            var mapsUrl = 'https://www.google.com/maps/?q=';
            var _loop_1 = function (log) {
                if (firstFive[log]) {
                    var data_1 = firstFive;
                    var recalculation_1 = '';
                    if (data_1[log].recalculationReason) {
                        recalculation_1 += 'Recalculation Reason : ' + data_1[log].recalculationReason + '\n';
                    }
                    if (data_1[log].locationProvider) {
                        recalculation_1 += 'Source : ' + data_1[log].locationProvider;
                    }
                    var offerInfo_1;
                    if (data_1[log].offerId && data_1[log].offerType != "subscription") {
                        var requests = [this_1.FareEstimateLogService.fetchLocationOffer(data_1[log].offerId),
                            this_1.FareEstimateLogService.fetchRideOffer(data_1[log].offerId)];
                        if (Object.keys(this_1.servicesMap).length == 0)
                            requests.push(this_1.EntityService.getAllServices());
                        combineLatest(requests, function (locationOffer, rideOffer, serviceMap) { return ({ locationOffer: locationOffer, rideOffer: rideOffer, serviceMap: serviceMap }); })
                            .subscribe(function (results) {
                            _this.servicesMap = __assign({}, _this.servicesMap, results.serviceMap);
                            var result = results.locationOffer || results.rideOffer;
                            if (result) {
                                offerInfo_1 = _this.offerTooltipData(result);
                                _this.constructFareEstimateLog(data_1[log], recalculation_1, offerInfo_1);
                            }
                            else {
                                _this.constructFareEstimateLog(data_1[log], recalculation_1, offerInfo_1);
                            }
                        });
                    }
                    else {
                        this_1.constructFareEstimateLog(data_1[log], recalculation_1, offerInfo_1);
                    }
                }
            };
            var this_1 = this;
            for (var log in firstFive) {
                _loop_1(log);
            }
        }
    };
    FareEstimateLogComponent.prototype.offerTooltipData = function (result) {
        var _this = this;
        var offerInfo = '<div>' +
            '<div class="left-align"><b>Name : </b><span>' + result['name'] + '</span></div>' +
            '<div class="left-align"><b>Description : </b><span>' + result['description'] + '</span></div>' +
            '<div class="left-align"><b>Offer Text : </b><span>' + result['offerText'] + '</span></div>';
        offerInfo += '<table><tr><th class="padding-sides-5">Expression</th><th class="padding-sides-5">Condition</th><th class="padding-sides-5">Limit</th></tr>';
        if (result['expressions']) {
            result['expressions'].forEach(function (expression) {
                offerInfo += '<tr><td>' + expression['expression'] + '</td><td>' + expression['condition'] + '</td><td>' + expression['maxLimit'] + '</td></tr>';
            });
        }
        offerInfo += '</table>';
        if (result['tnc']) {
            offerInfo += '<div class="left-align"><b>Terms And Conditions : </b><span>' + result['tnc'].join(', ') + '</span></div>';
        }
        if (result['city']) {
            offerInfo += '<div class="left-align"><b>City : </b><span>' + result['city'].join(', ') + '</span></div>';
        }
        var services = [];
        if (result['services']) {
            result['services'].map(function (service) { return services.push(_this.servicesMap[service].name); });
            offerInfo += '<div class="left-align"><b>Services : </b><span>' + services.join(', ') + '</span></div>';
        }
        if (Array.isArray(result['days']))
            offerInfo += '<div class="left-align"><b>Days: </b><span>' + result['days'].join(', ') + '</span></div>';
        if (result['timings'] && result['timings'][0] && result['timings'][0]['to'] && result['timings'][0]['from'])
            offerInfo += '<div class="left-align"><b>From: </b><span>' + result['timings'][0]['from'] + '</span><b> To : </b><span>' + result['timings'][0]['to'] + '</span></div>';
        '</div>';
        return offerInfo;
    };
    FareEstimateLogComponent.prototype.constructFareEstimateLog = function (dataLog, recalculation, offerInfo) {
        var mapsUrl = 'https://www.google.com/maps/?q=';
        var pickup = dataLog.pickupLocation && dataLog.pickupLocation.name ? dataLog.pickupLocation.name :
            (dataLog.pickupLocation && dataLog.pickupLocation.lat && dataLog.pickupLocation.lng ?
                dataLog.pickupLocation.lat + ', ' + dataLog.pickupLocation.lng : '');
        var drop = dataLog.dropLocation && dataLog.dropLocation.name ? dataLog.dropLocation.name :
            (dataLog.dropLocation && dataLog.dropLocation.lat && dataLog.dropLocation.lng ?
                dataLog.dropLocation.lat + ', ' + dataLog.dropLocation.lng : '');
        var pickUpUrl = dataLog.pickupLocation && dataLog.pickupLocation.lat && dataLog.pickupLocation.lng ?
            mapsUrl + dataLog.pickupLocation.lat + ',' + dataLog.pickupLocation.lng : '';
        var dropUrl = dataLog.dropLocation && dataLog.dropLocation.lat && dataLog.dropLocation.lng ?
            mapsUrl + dataLog.dropLocation.lat + ',' + dataLog.dropLocation.lng : '';
        this.dataRows.push({
            timeStamp: { data: dataLog.createdAt ? this.getTimeStamp(dataLog.createdAt) : '' },
            pickup: { data: pickup, redirectLink: pickUpUrl, className: pickUpUrl ? 'web-link' : '' },
            drop: { data: drop, redirectLink: dropUrl, className: dropUrl ? 'web-link' : '' },
            offerType: { data: dataLog.offerType ? dataLog.offerType : '' },
            offerCode: { data: dataLog.offerCode ? dataLog.offerCode : '', information: offerInfo ? offerInfo : null },
            estDistance: {
                data: dataLog.direction && !this.isEmpty(dataLog.direction.distanceInKms) ?
                    dataLog.direction.distanceInKms + ' kms' : ''
            },
            estRideTime: { data: dataLog.direction ? dataLog.direction.timeInMins + ' mins' : '' },
            estFare: {
                data: !this.isEmpty(dataLog.amount) ? '₹ ' + dataLog.amount.toFixed(2).toString() : '',
                information: recalculation && recalculation.length ? recalculation : null
            },
        });
        this.tableData = {
            tableHeader: 'Fare Estimate log',
            expandTable: this.dataRows.length > 1 ? true : false,
            headers: {
                timeStamp: 'Timestamp', pickup: 'Pickup', drop: 'Drop', offerType: 'Offer Type', offerCode: 'Offer Code',
                estDistance: 'Est. Distance', estRideTime: 'Est. Ride Time', estFare: 'Est. Fare'
            },
            data: [this.dataRows[0]],
            title: ['pickup', 'drop'],
            tableHeight: 'max-height-275',
            dataFetched: true
        };
    };
    FareEstimateLogComponent.prototype.emptyCard = function (message) {
        this.tableData = {
            tableHeader: 'Fare Estimate log',
            data: [],
            dataFetched: false,
            emptyMessage: message || 'No data found'
        };
    };
    FareEstimateLogComponent.prototype.getTimeStamp = function (date) {
        var timeInfo = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
        var today = new Date(date);
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var dateInfo = dd + '-' + mm + '-' + yyyy;
        return (dateInfo + '\n' + timeInfo);
    };
    FareEstimateLogComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    return FareEstimateLogComponent;
}());
export { FareEstimateLogComponent };
