import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { faRedo, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FilterService, QuickFilters } from 'src/app/filter.service';
import { CaptainService } from '../../captain.service';
import { ToasterType, Toaster } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
@Component({
  selector: 'app-captain-incentive-progress',
  templateUrl: './captain-incentive-progress.component.html',
  styleUrls: ['./captain-incentive-progress.component.css']
})
export class CaptainIncentiveProgressComponent implements OnInit, OnChanges {
  @Input() riderMobile;
  @Input() riderId: string;
  dailyIncentives = [];
  weeklyIncentives = [];
  faRefresh = faRedo;
  faFilter = faFilter;
  public todayDate = this.formatDate(new Date());
  constructor(
    private toasterService: ToasterService,
    public filterService: FilterService,
    public captainService: CaptainService
  ) { }
  selectedView = 'daily';
  info = {
    filters: [
      new QuickFilters({
        key: 'incentiveDuration',
        default: '',
        title: 'Duration',
        values: [this.todayDate, this.todayDate]
      })
    ]
  };
  filters = [this.todayDate, this.todayDate];

  ngOnInit() {
    this.fetchIncentiveDetails();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fetchIncentiveDetails();
  }

  changeView() {
    this.selectedView = this.selectedView === 'daily' ? 'weekly' : 'daily';
  }

  fetchIncentiveDetails() {
    let startDate, endDate;
    if (this.riderId) {
      if (Array.isArray(this.filters)) {
        startDate = this.filters[0];
        endDate = this.filters[1];
      } else {
        startDate = this.filters['start'];
        endDate = this.filters['end'];
      }
      this.captainService.incentiveProgress(this.riderId, startDate, endDate).subscribe(result => {
        if (result && result['data']) {
          if (result['data'].Daily || result['data'].Adhoc) {
            this.dailyIncentives = [...result['data'].Daily, ...result['data'].Adhoc];
          }
          if (result['data'].WeeklyFixed || result['data'].WeeklySelectable) {
            this.weeklyIncentives = [];
            for (const incentive of result['data'].WeeklyFixed) {
              if (incentive) {
                incentive.type = 'Weekly Fixed';
                this.weeklyIncentives.push(incentive);
              }
            }
            for (const incentive of result['data'].WeeklySelectable) {
              if (incentive) {
                incentive.type = 'Weekly Selectable';
                this.weeklyIncentives.push(incentive);
              }
            }
          }
        }
      }, error => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: error && error.error && error.error.metadata || `Unable to fetch incentives`,
        }));
      });
    }
  }

  refreshData() {
    this.fetchIncentiveDetails();
  }

  onFilter() {
    this.filterService.defineFilters(this.info.filters, (filters) => {
      const dates = {};
      if (this.filterService && this.filterService.appliedFilters) {
        if (Array.isArray(this.filterService.appliedFilters.incentiveDuration)) {
          dates['start'] = this.filterService.appliedFilters.incentiveDuration[0];
          dates['end'] = this.filterService.appliedFilters.incentiveDuration[1];
          this.filterService.appliedFilters.incentiveDuration = dates;
        }
      }
      this.filters = filters && filters.incentiveDuration || null;
      this.fetchIncentiveDetails();
    });
    this.filterService.filterToggle();
  }

  formatDate(date) {
    date = new Date(date);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    if (monthIndex < 10) {
      monthIndex = '0' + monthIndex;
    }
    if (day < 10) {
      day = '0' + day;
    }
    const year = date.getFullYear();
    return year + '-' + monthIndex + '-' + day;
  }

}
