var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { OrderService } from 'src/app/order-service.service';
import { CaptainFraudOrdersService } from './captain-fraud-orders.service';
import { FilterService } from 'src/app/filter.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CaptainFailedOrdersService } from '../captain-failed-orders/captain-failed-orders.service';
var PAGE_LIMIT = 10;
var CaptainFraudOrdersComponent = /** @class */ (function () {
    function CaptainFraudOrdersComponent(orderService, entityService, route, router, CaptainFraudOrdersService, captainFailedOrdersService, filterService) {
        this.orderService = orderService;
        this.entityService = entityService;
        this.route = route;
        this.router = router;
        this.CaptainFraudOrdersService = CaptainFraudOrdersService;
        this.captainFailedOrdersService = captainFailedOrdersService;
        this.filterService = filterService;
        this.breadcrumb = faChevronRight;
        this.orderIDArr = [];
    }
    CaptainFraudOrdersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.riderMobile = this.route.snapshot.params.mobile ? this.route.snapshot.params.mobile : '';
        this.filterService.showFilters = false;
        this.route.queryParams.subscribe(function (params) {
            _this.fraudOrderPayload = {
                'fromDate': params['fromDate'],
                'toDate': params['toDate'],
                'frauds': [params['frauds']]
            };
            _this.fraudType = params['frauds'];
        });
        var riderMobile = HelperService.decode(this.riderMobile);
        this.entityService.getEntityByNumber(riderMobile).subscribe(function (resp) {
            if (resp) {
                if (resp.userId._id) {
                    _this.riderId = resp.userId._id;
                    _this.defineOrderTable();
                }
            }
        });
    };
    CaptainFraudOrdersComponent.prototype.onPaginationChange = function (event) {
        this.orderTable.data = [];
        this.fillDataToTable(event.pageNo);
    };
    CaptainFraudOrdersComponent.prototype.redirectToCaptainDetails = function () {
        this.router.navigate([
            'captain/' + this.riderMobile
        ]);
    };
    CaptainFraudOrdersComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.orderTable = new Table({
            tableHeader: 'Fraud Orders',
            headers: {
                dateTime: 'Date & Time',
                orderId: 'Order Id',
                pickupLocation: 'Pickup',
                service: 'Service',
                orderStatus: 'Order Status'
            },
            pagination: new Pagination(pageNumber, PAGE_LIMIT),
            config: {
                refresh: true,
                filter: false
            },
            onRefresh: function () {
                _this.orderTable = null;
                _this.ngOnInit();
            },
            data: []
        });
    };
    CaptainFraudOrdersComponent.prototype.getBulkOrderList = function (fraudOrderPayload) {
        return this.orderService.getOrders(fraudOrderPayload);
    };
    CaptainFraudOrdersComponent.prototype.getFraudOrderIds = function (fraudOrderPayload) {
        return this.CaptainFraudOrdersService.getFraudOrders(fraudOrderPayload);
    };
    CaptainFraudOrdersComponent.prototype.fillDataToTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        var payload = {
            'limit': 50,
            'searchConditions': {
                'orderIds': this.orderIDArr[pageNumber]
            },
            'requiredFields': ['status', 'customerId', 'captain', 'pickupLocation', 'dropLocation', 'serviceDetail.service', 'orderTime', 'createdOn', 'uniqueId']
        };
        this.getBulkOrderList(payload).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
            function createTableData(value, className) {
                return new TableData({
                    data: value,
                    type: TableDataTypes.DATA,
                    className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                    onClick: function (data) {
                        HelperService.redirectToOldDashboard("/order/" + data._id.data);
                    }
                });
            }
            var orderIds;
            var _this = this;
            return __generator(this, function (_a) {
                orderIds = [];
                if (response && response['data'] && response['data'].data) {
                    this.orders = response['data'].data.map(function (order, i) {
                        var orderId = order._id;
                        var orderDate = new Date(order.orderTime.date);
                        orderIds.push(orderId);
                        return {
                            _id: createTableData(orderId),
                            dateTime: createTableData(HelperService.dateString(orderDate) + ', ' + _this.formatAMPM(new Date(order.createdOn))),
                            orderId: new TableData({
                                data: order.uniqueId,
                                type: TableDataTypes.DATA,
                                className: 'cursor-pointer whiteSpacePre',
                                onClick: function (data) {
                                    HelperService.redirectToOldDashboard("/order/" + data._id.data);
                                }
                            }),
                            pickupLocation: createTableData(order.pickupLocation.address),
                            service: createTableData(order.serviceDetail.service.name),
                            orderStatus: createTableData(_this.formatEventName(order.status))
                        };
                    });
                    this.orderTable.pagination.count = this.fraudOrderLen;
                }
                this.orderTable.data = this.orders;
                return [2 /*return*/];
            });
        }); });
    };
    CaptainFraudOrdersComponent.prototype.formatEventName = function (name) {
        var event = name.split('_');
        if (event.length > 1) {
            var str = event.join(' ');
            return str.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        }
        else {
            return name;
        }
    };
    CaptainFraudOrdersComponent.prototype.formatAMPM = function (date) {
        var strTime, hours, minutes, ampm;
        hours = date.getHours();
        minutes = date.getMinutes();
        ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    };
    CaptainFraudOrdersComponent.prototype.defineOrderTable = function () {
        var _this = this;
        this.declareTable();
        if (this.riderId) {
            this.orderTable.dataWillLoad();
            this.fraudOrderPayload['rider'] = this.riderId;
            this.getFraudOrderIds(this.fraudOrderPayload).subscribe(function (resp) { return __awaiter(_this, void 0, void 0, function () {
                var fraudOrdersArr, recordsPerPage, i, temp;
                return __generator(this, function (_a) {
                    fraudOrdersArr = resp.data['orders'].slice();
                    this.fraudOrderLen = fraudOrdersArr.length;
                    if (this.fraudOrderLen > PAGE_LIMIT) {
                        recordsPerPage = Math.ceil(this.fraudOrderLen / PAGE_LIMIT);
                        for (i = 1; i <= recordsPerPage; i++) {
                            temp = fraudOrdersArr.splice(0, PAGE_LIMIT);
                            this.orderIDArr.push(temp);
                        }
                    }
                    else {
                        this.orderIDArr.push(fraudOrdersArr);
                    }
                    this.fillDataToTable();
                    return [2 /*return*/];
                });
            }); });
        }
        else {
            console.log('Something went wrong');
        }
    };
    return CaptainFraudOrdersComponent;
}());
export { CaptainFraudOrdersComponent };
