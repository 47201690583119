import { map, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityService } from '../../entity.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../entity.service";
var ManagerGeneralInfoService = /** @class */ (function () {
    function ManagerGeneralInfoService(http, entityService) {
        this.http = http;
        this.entityService = entityService;
        this.server = "" + environment.server;
        this.getManager = this.server + '/api/getManagerByMobile';
    }
    ManagerGeneralInfoService.prototype.getManagerByMobile = function (mobile) {
        return this.http
            .get(this.getManager + '/' + mobile, {
            observe: 'response',
        })
            .pipe(timeout(3000), map(function (userDetailsResponse) {
            if (userDetailsResponse &&
                userDetailsResponse.body &&
                userDetailsResponse.body.data) {
                return userDetailsResponse.body.data;
            }
            else {
                throw Error('Unable to fetch this user, Please check the number again');
            }
        }));
    };
    ManagerGeneralInfoService.prototype.getReferralDetails = function (userId) {
        return this.entityService
            .getReferralDetailsV2(userId, this.entityService.USER_TYPE.MANAGER)
            .pipe(timeout(3000), map(function (referralDetails) {
            if (referralDetails &&
                referralDetails.result &&
                referralDetails.result.referralCode) {
                return referralDetails;
            }
            else {
                throw Error('Unable to get referral details for this user.');
            }
        }));
    };
    ManagerGeneralInfoService.ngInjectableDef = i0.defineInjectable({ factory: function ManagerGeneralInfoService_Factory() { return new ManagerGeneralInfoService(i0.inject(i1.HttpClient), i0.inject(i2.EntityService)); }, token: ManagerGeneralInfoService, providedIn: "root" });
    return ManagerGeneralInfoService;
}());
export { ManagerGeneralInfoService };
