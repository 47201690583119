import { MyHttpClient } from '../../app/http-client.service';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
import * as i2 from "@angular/common/http";
var RideTrackerMapService = /** @class */ (function () {
    function RideTrackerMapService(http, httpClient) {
        this.http = http;
        this.httpClient = httpClient;
        this.server = environment.server;
        this.actualRouteUrl = this.server + '/api/getActualRoute';
        this.orderRequestsUrl = this.server + '/api/getOrderRequests';
        this.cityInfoUrl = this.server + '/api/getCityInfo';
        this.tollsUrl = this.server + '/api/v1/geolayer/toll/list';
    }
    RideTrackerMapService.prototype.getActualRoute = function (userId, startedTime, droppedTime) {
        var body = {
            userId: userId,
            startedTime: startedTime,
            droppedTime: droppedTime
        };
        return this.http.post(this.actualRouteUrl, body).pipe(catchError(this.handleError));
    };
    RideTrackerMapService.prototype.getOrderRequests = function (requestId, userId) {
        return this.http.post(this.orderRequestsUrl, { requestId: requestId, userId: userId }).pipe(catchError(this.handleError));
    };
    RideTrackerMapService.prototype.getLiveLocation = function (captainId) {
        var url = environment.server + "/api/currentLocation/" + captainId;
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    RideTrackerMapService.prototype.getRiderGeoLocation = function (captainId) {
        var url = environment.server + "/api/rider/" + captainId + "/location";
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    RideTrackerMapService.prototype.getCityInfo = function (cityId) {
        var url = this.cityInfoUrl + "/" + cityId;
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    RideTrackerMapService.prototype.getCityTolls = function (cityId) {
        var url = environment.server + "/api/geolayers/toll/list?cityId=" + cityId;
        return this.http.get(url).pipe(catchError(this.handleError));
    };
    RideTrackerMapService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    RideTrackerMapService.ngInjectableDef = i0.defineInjectable({ factory: function RideTrackerMapService_Factory() { return new RideTrackerMapService(i0.inject(i1.MyHttpClient), i0.inject(i2.HttpClient)); }, token: RideTrackerMapService, providedIn: "root" });
    return RideTrackerMapService;
}());
export { RideTrackerMapService };
