import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader.service';

@Injectable({
  providedIn: 'root'
})
export class FraudService {

  constructor(private http: MyHttpClient, private loaderService: LoaderService) { }
  server = `${environment.server}`;
  getFraudDetailsUrl = this.server + '/api/fraudEngine/order-fraud-tags/';
  updateFraudDetialsUrl = this.server + '/api/fraudEngine/updateFraudOrderDetails';
  checkFraudOrdersUrl = this.server + '/api/fraudEngine/check/fraudOrder';

  getFraudDetails(orderId) {
    return this.http.get(this.getFraudDetailsUrl + orderId).pipe(catchError(this.handleError));
  }

  updateFraudDetails(data) {
    this.loaderService.openLoading();
    return this.http.post(this.updateFraudDetialsUrl, data)
    .pipe(catchError((error) => {
      this.loaderService.closeLoading();
      return this.handleError(error);
    })).map(res => {
      this.loaderService.closeLoading();
      return res;
    })
  }

  checkFraudOrders(data) {
    return this.http.post(this.checkFraudOrdersUrl, data).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }

    return throwError(error);
  }
}
