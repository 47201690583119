import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityService } from '../entity.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(private http: MyHttpClient,
    private entityService: EntityService) { }
  server = `${environment.server}`;
  getMerchantUrl = this.server + '/api/getMerchantByMobile';
  logoutMerchantUrl = this.server + '/api/logoutMerchant';
  getWalletBalances = this.server + '/api/getWalletBalance/merchant';
  merchantTxnUrl = this.server + '/api/getMerchantTransactions';
  merchantOrders = this.server + '/api/merchantOrders';
  getMerchantByMobile(mobile) {
    return this.http.get(this.getMerchantUrl + '/' + mobile).pipe(catchError(this.handleError));
  }

  logoutMerchant(userId) {
    return this.http.post(this.logoutMerchantUrl, {userId}).pipe(catchError(this.handleError));
  }

  getMerchantWallets(merchantDetails, merchantId) {
    const payload = {
      user : merchantDetails,
      merchantId
    };
    return this.http.post(this.getWalletBalances, payload).pipe(catchError(this.handleError));
  }

  getMerchantTxns(groupEntityId, limit, offset, merchantId) {
    const payload = {limit, offset, groupEntityId, merchantId};
    return this.http.post(this.merchantTxnUrl, payload).pipe(catchError(this.handleError));
  }

  getMerchantOrders(payload) {
    return this.http.post(this.merchantOrders, payload).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
