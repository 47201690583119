var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { OrderService } from 'src/app/order-service.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { CustomerOrderDetailsService } from '../../customer-details/customer-order-details/customer-order-details.service';
import { FilterService, TextFilterInput, SearchableDropDownFilterInput, SingleSearchableDropdown, QuickFilters } from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { MerchantService } from '../merchant.service';
var currentDate = new Date();
var startDate = new Date(2010, 1, 1);
export var DEFAULT_DATE = {
    FROM_DATE: new Date(new Date(startDate).setDate(new Date(startDate).getDate() - 1)),
    TO_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1))
};
function defaultFiters() {
    return {
        startDuration: '',
        endDuration: '',
        cities: '',
        services: '',
        orderId: '',
        status: 'all'
    };
}
var MerchantOrderDetailsComponent = /** @class */ (function () {
    function MerchantOrderDetailsComponent(orderService, filterService, entityService, customerOrderDetailsService, dataService, router, merchantService) {
        this.orderService = orderService;
        this.filterService = filterService;
        this.entityService = entityService;
        this.customerOrderDetailsService = customerOrderDetailsService;
        this.dataService = dataService;
        this.router = router;
        this.merchantService = merchantService;
        this.toggleHeaders = new EventEmitter();
        this.serviceMap = {};
        this.cityMap = {};
        this.fromDate = DEFAULT_DATE.FROM_DATE;
        this.toDate = DEFAULT_DATE.TO_DATE;
        this.filtersApplied = defaultFiters();
        this.cities = [];
        this.services = [];
        this.statusList = [
            'all',
            'new',
            'customerCancelled',
            'riderCancelled',
            'onTheWay',
            'arrived',
            'started',
            'dropped',
            'expired',
            'aborted'
        ];
    }
    MerchantOrderDetailsComponent.prototype.ngOnChanges = function (changes) {
        if (changes.merchantId) {
            if (this.merchantId) {
                this.defineOrderTable(this.merchantId, DEFAULT_DATE.FROM_DATE, DEFAULT_DATE.TO_DATE, this.filtersApplied);
            }
        }
    };
    MerchantOrderDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defineOrderTable(this.merchantId);
        this.entityService.getCities().subscribe(function (cities) {
            var _a;
            for (var city in cities) {
                if (cities[city]) {
                    _this.cityMap[cities[city].toString()] = cities[city].valueOf();
                }
            }
            (_a = _this.cities).push.apply(_a, cities);
            var fetchServicesForCity = _this.cityMap[_this.merchantCity];
            _this.entityService.getServicesPerCity(fetchServicesForCity).subscribe(function (services) {
                services = services['data'];
                _this.services.splice(0);
                for (var service in services) {
                    if (services[service] && services[service]._id && services[service].service && services[service].service.active) {
                        _this.services.push(services[service].service.name);
                        _this.serviceMap[services[service].service.name] = services[service]._id;
                    }
                }
            });
        });
    };
    MerchantOrderDetailsComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    MerchantOrderDetailsComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.dataService.searchUserCount.subscribe(function (result) {
            if (!_this.currentNumber || (_this.currentNumber === result)) {
                _this.filterService.appliedFilters = _this.filtersApplied;
            }
            else {
                _this.filterService.appliedFilters = {};
                _this.filtersApplied = defaultFiters();
            }
            _this.currentNumber = result;
        });
        this.orderTable = new Table({
            // tableHeader: 'Order Details',
            toggleableHeaders: ['Order Details', 'Transaction Details'],
            selectedHeader: 0,
            headers: {
                dateTime: 'Date \n Time',
                orderIdCity: 'Order Id \n City',
                pickupDrop: 'Pickup \n Drop',
                captainName: 'Captain Name',
                offerTypeCode: 'Offer Type \n Offer Code',
                discountCashback: 'Discount \n Cashback',
                fare: 'Fare',
                adjustment: 'Total Adjustment',
                status: 'Status',
                ratings: 'Ratings'
            },
            pagination: new Pagination(pageNumber, 10),
            config: {
                refresh: true,
            },
            onRefresh: function () {
                _this.orderTable = null;
                _this.ngOnInit();
            },
            appliedFilters: this.filtersApplied,
            filterComponents: [
                new QuickFilters({
                    key: 'duration',
                    default: '',
                    title: 'Duration',
                }),
                new TextFilterInput({
                    key: 'orderId',
                    title: 'Order ID',
                }),
                new SearchableDropDownFilterInput({
                    key: 'services',
                    title: 'Services',
                    values: this.services,
                }),
                new SingleSearchableDropdown({
                    key: 'status',
                    title: 'Status',
                    values: this.statusList,
                }),
            ],
            onFilter: function () {
                var timeSlot = _this.filterService.appliedFilters.duration || ['', ''];
                var duration = [];
                if (!Array.isArray(timeSlot)) {
                    duration.push(timeSlot['start']);
                    duration.push(timeSlot['end']);
                }
                else {
                    duration = timeSlot;
                }
                var startDuration = duration[0];
                var endDuration = duration[1];
                var cities = _this.filterService.appliedFilters.cities;
                var services = _this.filterService.appliedFilters.services;
                var orderId = _this.filterService.appliedFilters.orderId;
                // const refund = this.filterService.appliedFilters.refund;
                var cashback = _this.filterService.appliedFilters.cashback;
                var rating = _this.filterService.appliedFilters.rating;
                var status = _this.filterService.appliedFilters.status;
                var filtersApplied = {
                    duration: {
                        start: startDuration,
                        end: endDuration,
                    },
                    cashback: {
                        from: cashback && cashback.from,
                        to: cashback && cashback.to,
                    },
                    rating: {
                        from: rating && rating.from,
                        to: rating && rating.to,
                    },
                    startDuration: startDuration,
                    endDuration: endDuration,
                    cities: cities,
                    services: services,
                    orderId: orderId,
                    cashbackInput: cashback,
                    ratingInput: rating,
                    status: status,
                };
                Object.assign(_this.filtersApplied, filtersApplied);
                var isValidNumber = function (value) {
                    return ((parseInt(value, 10) === 0) || value);
                };
                if ((startDuration && endDuration) || cities || services || orderId || isValidNumber(cashback) || rating) {
                    startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
                    endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
                    return _this.defineOrderTable(_this.merchantId, startDuration, endDuration, _this.filtersApplied);
                }
                return _this.defineOrderTable(_this.merchantId, undefined, undefined, _this.filtersApplied);
            },
            data: []
        });
    };
    MerchantOrderDetailsComponent.prototype.onPaginationChange = function (event) {
        this.fillDataToTable(undefined, undefined, this.filtersApplied);
    };
    MerchantOrderDetailsComponent.prototype.getOrderList = function (customerMobile, fromDate, toDate, filters) {
        this.filterCount = 0;
        var filterKeys = Object.keys(this.filtersApplied);
        var ignoreKeys = ['startDuration', 'endDuration'];
        var dateFilter = [];
        if (this.filtersApplied && this.filtersApplied['duration']) {
            if (this.filtersApplied['duration'].start) {
                dateFilter.push(this.filtersApplied['duration'].start);
            }
            if (this.filtersApplied['duration'].end) {
                dateFilter.push(this.filtersApplied['duration'].end);
            }
        }
        if (dateFilter.length > 0) {
            this.filterCount++;
        }
        for (var i = 0; i < filterKeys.length; i++) {
            if (Array.isArray(this.filtersApplied[filterKeys[i]]) && (this.filtersApplied[filterKeys[i]]).length > 0) {
                this.filterCount++;
            }
            else if (typeof (this.filtersApplied[filterKeys[i]]) === 'object') {
                if (filters[i] && filterKeys[i] === 'rating' &&
                    ((this.filtersApplied[filterKeys[i]]['from']) || (this.filtersApplied[filterKeys[i]]['to']))) {
                    this.filterCount++;
                }
            }
            else {
                if (this.filtersApplied[filterKeys[i]] && ignoreKeys.indexOf(filterKeys[i]) === -1
                    && (this.filtersApplied[filterKeys[i]]).length > 0) {
                    this.filterCount++;
                }
            }
        }
        var filterConditions = this.getFilterConditions();
        var payload = {
            'limit': this.orderTable.pagination.perPage,
            'offset': (this.orderTable.pagination.perPage * this.orderTable.pagination.pageNo),
            'searchConditions': {
                'orderType': 'sme',
                'customer': this.merchantId
            },
            'requiredFields': 'orderDate createdOn _id serviceObj uniqueId pickupLocation dropLocation riderObj rider customer customerObj offerCode couponCode\
        discount cashback feedback status amount orderTime serviceType',
            'requireTotalCount': true
        };
        payload.searchConditions = __assign({}, payload.searchConditions, filterConditions);
        return this.merchantService.getMerchantOrders(payload);
    };
    MerchantOrderDetailsComponent.prototype.getFilterConditions = function () {
        var conditions = {};
        if (this.filtersApplied && this.filterCount > 0) {
            for (var condition in this.filtersApplied) {
                if (this.filtersApplied[condition]) {
                    switch (condition) {
                        case 'duration':
                            if (this.filtersApplied[condition].start && this.filtersApplied[condition].end) {
                                conditions['orderDate'] = { $gte: this.filtersApplied[condition].start, $lte: this.filtersApplied[condition].end };
                            }
                            break;
                        case 'services':
                            var service = [];
                            var services = this.filtersApplied[condition];
                            for (var _i = 0, services_1 = services; _i < services_1.length; _i++) {
                                var serviceName = services_1[_i];
                                if (serviceName) {
                                    service.push(this.serviceMap[serviceName + '']);
                                }
                            }
                            conditions['serviceType'] = { $in: service };
                            break;
                        case 'orderId':
                            conditions['uniqueId'] = this.filtersApplied[condition];
                            break;
                        case 'status':
                            if (this.filtersApplied[condition] !== 'all') {
                                conditions['status'] = this.filtersApplied[condition];
                            }
                            break;
                        default: break;
                    }
                }
            }
        }
        return conditions;
    };
    MerchantOrderDetailsComponent.prototype.calculateAdjustmentsByOrderId = function (adjustments) {
        var STRING = {
            CREDIT: 'credit',
            ADJUSTMENTS: 'adjustment',
            DEBIT: 'debit'
        };
        var adjustmentsHistory = {};
        var totalAdjustment = {};
        function toBeAdded(adjustment) {
            return adjustment.transactionType === STRING.CREDIT ? true : false;
        }
        function isProperTransactionType(adjustment) {
            return adjustment.title === STRING.ADJUSTMENTS &&
                (adjustment.transactionType === STRING.CREDIT || adjustment.transactionType === STRING.DEBIT);
        }
        adjustments.forEach(function (adjustment) {
            if (!adjustment) {
                return;
            }
            if (isProperTransactionType(adjustment)) {
                totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] || 0;
                if (toBeAdded(adjustment)) {
                    totalAdjustment[adjustment.orderId] += adjustment.originalAmount;
                }
                else {
                    totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] - adjustment.originalAmount;
                }
            }
        });
        for (var adjustment in totalAdjustment) {
            if (!this.isEmpty(totalAdjustment[adjustment])) {
                totalAdjustment[adjustment] = totalAdjustment[adjustment].toString();
            }
        }
        return totalAdjustment;
    };
    MerchantOrderDetailsComponent.prototype.formatAMPM = function (date) {
        var time = new Date(date);
        return (time.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' }));
    };
    MerchantOrderDetailsComponent.prototype.fillDataToTable = function (fromDate, toDate, filters) {
        var _this = this;
        if (this.merchantId) {
            this.orderTable.dataWillLoad();
            this.getOrderList(this.merchantId, fromDate, toDate, filters).subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
                function createTableData(value, className) {
                    return new TableData({
                        data: value,
                        type: TableDataTypes.DATA,
                        className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                        onClick: function (data) {
                            var newRelativeUrl = self.router.createUrlTree(["/order/" + data._id.data]);
                            var baseUrl = window.location.href.replace(self.router.url, '');
                            window.open(baseUrl + newRelativeUrl, '_blank');
                        }
                    });
                }
                var orders, orderIds, self, orderIdss, adjustments, orderIdToAdjustmentsMap;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            orders = response['data'];
                            orderIds = [];
                            self = this;
                            orderIdss = orders.map(function (o) { return o._id; });
                            return [4 /*yield*/, this.customerOrderDetailsService.getAdjustmentAmounts(orderIdss, this.merchantId).toPromise()];
                        case 1:
                            adjustments = _a.sent();
                            orderIdToAdjustmentsMap = this.calculateAdjustmentsByOrderId(adjustments);
                            this.orders = orders.map(function (order, i) {
                                var orderId = order._id;
                                orderIds.push(orderId);
                                var self = _this;
                                var orderDate = new Date(order.orderDate);
                                return {
                                    _id: createTableData(orderId),
                                    dateTime: createTableData(HelperService.dateString(orderDate) + '\n' + _this.formatAMPM(order.createdOn)),
                                    orderIdCity: new TableData({
                                        data: order.uniqueId + '\n' + order.serviceObj.city || '',
                                        type: TableDataTypes.DATA,
                                        className: 'cursor-pointer whiteSpacePre',
                                        onClick: function (data) {
                                            var newRelativeUrl = self.router.createUrlTree(["/order/" + data._id.data]);
                                            var baseUrl = window.location.href.replace(self.router.url, '');
                                            window.open(baseUrl + newRelativeUrl, '_blank');
                                        }
                                    }),
                                    pickupDrop: createTableData(order.pickupLocation.address + '\n' + order.dropLocation.address),
                                    offerTypeCode: createTableData((order.offerType || '') +
                                        (order.offerCode ? ('\n' + order.offerCode) : (order.couponCode ? ('\n' + order.couponCode) : ''))),
                                    discountCashback: createTableData(order.discount + '\n' + (order.cashback || ''), 'preserve-whitespace'),
                                    captainName: createTableData(order.riderObj && order.riderObj.name),
                                    fare: createTableData(order.amount),
                                    ratings: createTableData(order.feedback && order.feedback.riderRating || ' - '),
                                    adjustment: createTableData(orderIdToAdjustmentsMap[orderId]),
                                    status: createTableData(order.status, 'capitalize whiteSpacePre')
                                };
                            });
                            this.orderTable.data = this.orders;
                            this.orderTable.filterCount = this.filterCount;
                            this.orderTable.pagination.count = response['totalCount'];
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    MerchantOrderDetailsComponent.prototype.calculateAdjustment = function (adjustments) {
        if (adjustments && adjustments.length > 0) {
            var finalAdjustment = 0;
            for (var adj in adjustments) {
                if (adjustments[adj]) {
                    switch (adjustments[adj].type) {
                        case 'credit':
                            finalAdjustment += adjustments[adj].amount;
                            break;
                        case 'debit':
                            finalAdjustment -= adjustments[adj].amount;
                            break;
                        default: break;
                    }
                }
            }
            return (finalAdjustment > 0 ? '+' + finalAdjustment.toString() : finalAdjustment.toString());
        }
    };
    MerchantOrderDetailsComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    MerchantOrderDetailsComponent.prototype.defineOrderTable = function (customerMobile, fromDate, toDate, filters) {
        if (fromDate === void 0) { fromDate = DEFAULT_DATE.FROM_DATE; }
        if (toDate === void 0) { toDate = DEFAULT_DATE.TO_DATE; }
        if (filters === void 0) { filters = this.filtersApplied; }
        this.declareTable();
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.fillDataToTable(fromDate, toDate, filters);
    };
    return MerchantOrderDetailsComponent;
}());
export { MerchantOrderDetailsComponent };
