import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, timeout } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { EntityService } from '../../entity.service'
import { UserDetailsResponse, UserData } from '../manager-profile.types'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { ReferralDetails } from './manager-general-info.types'

@Injectable({
  providedIn: 'root',
})
export class ManagerGeneralInfoService {
  constructor(private http: HttpClient, private entityService: EntityService) {}
  server = `${environment.server}`
  getManager = this.server + '/api/getManagerByMobile'

  getManagerByMobile(mobile: string): Observable<UserData> {
    return this.http
      .get<UserDetailsResponse>(this.getManager + '/' + mobile, {
        observe: 'response',
      })
      .pipe(
        timeout(3000),
        map((userDetailsResponse: HttpResponse<UserDetailsResponse>) => {
          if (
            userDetailsResponse &&
            userDetailsResponse.body &&
            userDetailsResponse.body.data
          ) {
            return userDetailsResponse.body.data
          } else {
            throw Error(
              'Unable to fetch this user, Please check the number again'
            )
          }
        })
      )
  }

  getReferralDetails(userId: string): Observable<ReferralDetails> {
    return this.entityService
      .getReferralDetailsV2(userId, this.entityService.USER_TYPE.MANAGER)
      .pipe(
        timeout(3000),
        map((referralDetails: Pick<ReferralDetails, 'result'>) => {
          if (
            referralDetails &&
            referralDetails.result &&
            referralDetails.result.referralCode
          ) {
            return referralDetails
          } else {
            throw Error('Unable to get referral details for this user.')
          }
        })
      )
  }
}
