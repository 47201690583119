import { Component, OnInit } from '@angular/core';
import { OrderDetailsService } from './order-details.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { OrderStatusService } from '.././order-status/order-status-service';
import { FullViewMapService } from '../full-view-map.service';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomerDetailsService } from '../customer-details/customer-details.service';
import { CustomerTransactionDetailsService } from '../customer-details/customer-transaction-details/customer-transaction-details.service';
import { PaymentStatusComponent } from '../payment-status/payment-status.component';
import { MatDialog } from '@angular/material';
import { ORDERS_QOS } from '../shared/constants';
import { OrderDetailsHelper } from './order-details.helper';
import { EntityService } from '../entity.service';
import { CAPTAIN_AUTO_EARNINGS_CITY_CONFIG} from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  faRefresh = faRedo;
  isRefreshing = false;
  cancellationInformation: any;
  newOrderMapping: any = {};
  orderPromiseMapping: any = {};
  autoEarningsMapping: any = {};
  newOrderBreakup: any = {};
  orderId: any;
  riderId: any;
  uniqueId: any = null;
  deliveryId: any = null;
  clientName: any = null;
  orderType: any = null;
  orderDetails: any;
  invoiceDetailsMapping: any = {};
  order: any = {};
  pricing;
  payment;
  serviceDetail;
  abortReason: string = '';
  orderLogs: any;
  custDetails: any;
  pickupLocation: any;
  captDetails: any;
  captainDetails: any;
  rateCard: any = {};
  // This is the rate card that consumes the new api for captain rate card. 
  newCaptainRateCard: any;
  captainRateCard: any;
  invoiceDetails: any = {};
  captainInvoiceDetails: any = {};
  emailInvoice: any = {};
  distTime: any = {};
  fareEstimateLogs: any = {};
  mapData: any = {};
  showOrderLogsOnMaps = false;
  mapRiderDetails: any;
  ratingDetails: any;
  customerAdjDetails: any;
  captainAdjDetails: any;
  orderLogDetails: any;
  custAdjustments: any;
  captAdjustments: any;
  offlineRechargeInfo: any;
  orderDistance: any;
  custActHistory: any;
  captainTimelineDetails: any;
  dispatch: any = {};
  dropOtpStatus: string;
  timeLineDetails: any;
  orderStatus: any;
  statusMap: any;
  packageDetails: any;
  orderTimeLine = { dataFetched: true, data: null };
  fullViewMapToggle = false;
  distanceDetails: any;
  serviceObj: any;
  reAssignDetails: any;
  b2bConfig = null;
  correlationIds = null;
  showTxnInfoModal = false;
  correlationTs = null;
  otp: string;
  errorResponse = { dataFetched: false, data: null };
  pageRefreshed = false;
  orderCurrentStatus: any;
  packageOrderTypes = ['c2c', 'sme', 'delivery', 'bfs'];
  showPackageCard = false;
  isBatchable = false;
  batchId = null;
  FNDCase = null;
  batchingInfo = {};
  batchingOrderDetails = [];
  deviceId: any = '';
  walletInfo: any;
  microCorridorDetails: any;
  statusClassMapping = {
    new: 'yellow-status',
    accepted: 'yellow-status',
    arrived: 'blue-status',
    started: 'blue-status',
    reached: 'blue-status',
    dropped: 'green-status',
    customer_cancelled: 'red-status',
    rejected: 'red-status',
    aborted: 'red-status',
    rider_busy: 'red-status',
    unreachable: 'red-status',
    expired: 'red-status',
    rider_switch: 'yellow-status'
  };
  statusTextMapping = {
    new: 'New',
    accepted: 'Accepted',
    arrived: 'Arrived',
    started: 'Started',
    reached: 'Reached',
    dropped: 'Dropped',
    customer_cancelled: 'Customer Cancelled',
    rejected: 'Rejected',
    aborted: 'Aborted',
    rider_busy: 'Rider Busy',
    unreachable: 'Unreachable',
    expired: 'Expired',
    rider_switch: 'Rider Switched'
  };
  notAllowedToAbortStatus = [
    'aborted', 'dropped', 'customerCancelled', 'expired',
    'new', 'requested', 'captainCancelled'
  ];
  dropOtp: any;
  orderCityId: any;
  paymentMode: any;
  geoFence: any;
  isDropOrderEnabled: boolean;
  coinPreference: any;
  prevDuesAdjustments: any;
  constructor(
    public dialog: MatDialog,
    private orderDetailsService: OrderDetailsService,
    private route: ActivatedRoute,
    private fullViewMapService: FullViewMapService,
    private toasterService: ToasterService,
    private orderStatusService: OrderStatusService,
    private titleService: Title,
    private router: Router,
    private orderDetailsHelper : OrderDetailsHelper,
    private customerDetailsService: CustomerDetailsService,
    private customerTransactionDetailsService: CustomerTransactionDetailsService,
    private entityService : EntityService
  ) {
    this.titleService.setTitle('Order Details');
  }

  ngOnInit() {
    this.route.params.subscribe(data => {
      this.refreshPage(null);
    })
  }

  filterDuplicateStatus = (timelineDetails) => {
    const uniqueTimelineStatus = timelineDetails.filter(function (timelineEvent, index) {
      if (index == 0) return true;
      return timelineEvent.status !== timelineDetails[index - 1].status;
    });
    return uniqueTimelineStatus;
  }

  refreshPage(pageRefreshed) {
    this.pageRefreshed = pageRefreshed;
    this.isRefreshing = true;
    this.orderId = this.route.snapshot.params.id;
    this.statusMap = {};
    this.fareEstimateLogs = {};
    this.statusMap['requested'] = 'Requested';
    this.statusMap['new'] = 'New';
    this.statusMap['captains_mapped'] = 'New';
    this.statusMap['accepted'] = 'On the way';
    this.statusMap['arrived'] = 'Arrived';
    this.statusMap['started'] = 'Started';
    this.statusMap['dropped'] = 'Dropped';
    this.statusMap['customer_cancelled'] = 'Customer cancelled';
    this.statusMap['captain_rejected'] = 'Captain rejected';
    this.statusMap['captain_cancelled'] = 'Captain cancelled';
    this.statusMap['expired'] = 'Expired';
    this.statusMap['aborted'] = 'Aborted';
    this.statusMap['dropChanged'] = 'Drop Changed';
    this.statusMap['captain_switch'] = 'Captain Switched';
    this.statusMap['captain_busy'] = 'Captain Busy';
    this.statusMap['reached'] = 'Reached';
    this.statusMap['shipmentReadyTimeInEpoch'] = 'Restaurant SMR';
    this.statusMap['predictedShipmentReadyTimeInEpoch'] = 'Estimated SMR'
    this.fetchOrderDetails();
  }

  showLogsOnMap() {
    this.showOrderLogsOnMaps = !this.showOrderLogsOnMaps;
  }

  fullViewMap(mapInfo) {
    this.fullViewMapService.toggleFullMap(mapInfo);
  }

  copyCorrelationIds() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.correlationIds.join(', ')));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  monthMap = {
    1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun',
    7: 'Jul', 8: 'Aug', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dec'
  };

  getProperDate(timeStamp) {
    const timeInfo = new Date(timeStamp).toLocaleTimeString('en-US', {
      hour: 'numeric', hour12: true, minute: 'numeric',
      second: 'numeric'
    });
    const today = new Date(timeStamp);
    let dd: any = today.getDate();
    const mm: any = today.getMonth() + 1;

    const yyyy: any = today.getFullYear().toString().substr(-2);

    if (dd < 10) {
      dd = '0' + dd;
    }
    const dateInfo = dd + ' ' + this.monthMap[mm] + ' \'' + yyyy;
    return (dateInfo + '\n' + timeInfo);
  }

  fetchOrderDetails() {
      this.orderDetailsService.fetchOrderDetailsV2(this.orderId, ORDERS_QOS.qos1).subscribe(async orderResponse => {
        this.order = orderResponse || {};
        this.pricing = orderResponse && orderResponse['pricing'] || {};
        this.payment = orderResponse && orderResponse['payment'] || {};
        this.serviceDetail = orderResponse && orderResponse['serviceDetail'] || {};
        this.geoFence = orderResponse && orderResponse['geofence'] || {};
        this.pickupLocation = orderResponse && orderResponse['pickupLocation'] || {};

       this.fetchRequiredEventFields();
        if (this.order) {
          await this.getNewCaptainRateCard();
          await this.getInvoiceDetails();
          
          // fetching serviceDetails for the captain-v2 auto earnings
          const lat = this.order.pickupLocation.lat;
          const lng = this.order.pickupLocation.lng;
          let serviceDetailIdArray = [];
          this.entityService.getServiceDetailsForRider(this.order.captainId,lat,lng).subscribe(serviceDetails => {
            if(serviceDetails){
              const serviceDetailMap = JSON.parse(JSON.stringify(serviceDetails));
              serviceDetailIdArray = serviceDetailMap.map(item => item.serviceDetailId)
            }
          });
          const showV2AutoEarnings = this.orderDetailsHelper.isAutoV2EarningEnabled(this.serviceDetail, serviceDetailIdArray, CAPTAIN_AUTO_EARNINGS_CITY_CONFIG);

          if(this.order.status == 'dropped' && this.serviceDetail.service.displayName != 'Local'){
            this.orderDetailsService.fetchOrderAcceptPromise(this.orderId).subscribe(orderPromise => {
                this.orderPromiseMapping = this.parseOrderAcceptResponse(orderPromise, this.order.captainId);
            });
          }
          this.orderDetailsService.fetchDetailedOrderEarnings(this.orderId).subscribe(result => {
            if (result && Array.isArray(result) && result.length > 0) {
              const serviceName = this.serviceDetail && this.serviceDetail.service && this.serviceDetail.service.name || '';
              if (result[0].version == 3 && ['link','bike pink','c2c'].includes(serviceName.toLowerCase())) {
                this.orderDetailsService.fetchInvoiceReceiptDetails(this.orderId).subscribe(invoiceDetails => {
                  this.newOrderBreakup = this.parseCaptainNewEarningApiResponse(this.orderId,this.order.status, result[0], invoiceDetails, this.payment.mode, this.pricing.prevDue);
                  this.captainInvoiceDetails = {
                    deliveryOrder: this.deliveryId ? true : false,
                    dataFetched: true,
                    asPerNewEarnings: true,
                    orderServiceName: serviceName,
                    earningsBreakup: this.newOrderBreakup,
                    orderStatus: this.orderStatus,
                    orderAcceptPromiseDetails: this.orderPromiseMapping,
                    data: {
                      distance: this.pricing['distance']['finalDistance'],
                      totalTime: this.pricing['amountBreakup']['captain']['timeFare']['quantity'],
                      waitingTime: this.pricing['amountBreakup']['captain']['waitTimeCharges']['quantity'],
                      nightFareTooltip: this.newOrderBreakup['orderBreakup']['nightFare'] && this.orderDetailsHelper.getCaptainTimeBasedSlab(this.serviceDetail, this.order.events).value
                    }
                  }
                });
              }else if(showV2AutoEarnings && ['auto'].includes(serviceName.toLowerCase()) || (['autopremium'].includes(serviceName.toLowerCase()))){
                  this.autoEarningsMapping  = this.orderDetailsHelper.getFormattedEarningsBreakup(this.order, this.invoiceDetailsMapping, result[0], JSON.parse(environment.autoFlatCommissionCities));
                  this.captainInvoiceDetails = {
                    deliveryOrder: this.deliveryId ? true : false,
                    dataFetched: true,
                    asPerNewEarnings: true,
                    captainId: this.order.captainId,
                    orderServiceName: serviceName,
                    earningsBreakup: this.autoEarningsMapping,
                    orderStatus: this.orderStatus,
                    orderAcceptPromiseDetails: this.orderPromiseMapping,
                    data: {
                      distance: this.pricing['distance']['finalDistance'],
                      totalTime: this.pricing['amountBreakup']['captain']['timeFare']['quantity'],
                      waitingTime: this.pricing['amountBreakup']['captain']['waitTimeCharges']['quantity'],
                      nightFareTooltip: this.autoEarningsMapping['orderBreakup']['nightFare'] && this.orderDetailsHelper.getCaptainTimeBasedSlab(this.serviceDetail, this.order.events).value
                    }
                  }
              } else {
                let resp = this.parseCaptainEarningApiResponse(result[0])
                this.captainInvoiceDetails = {
                  deliveryOrder: this.deliveryId ? true : false,
                  dataFetched: true,
                  tip: resp['tip'],
                  asPerNewEarnings: false,
                  earningsBreakup: resp,
                  orderStatus: this.orderStatus,
                  orderServiceName: serviceName,
                  orderAcceptPromiseDetails: this.orderPromiseMapping,
                  data: {
                    distance: this.pricing['distance']['finalDistance'],
                    totalTime: this.pricing['amountBreakup']['captain']['timeFare']['quantity'],
                    waitingTime: this.pricing['amountBreakup']['captain']['waitTimeCharges']['quantity'],
                    nightFareTooltip: resp['orderBreakup']['nightFare'] && this.orderDetailsHelper.getCaptainTimeBasedSlab(this.serviceDetail, this.order.events).value
                  }
                }
              }
            }
          });

          if (this.order.orderType) {
            this.orderType = this.order.orderType;
            if (this.packageOrderTypes.indexOf(this.orderType) !== -1) {
              this.showPackageCard = true;
            }
          }
          this.orderCityId = this.order && this.order.orderContext && this.order.orderContext.quintuple &&
           this.order.orderContext.quintuple.cityId ? this.order.orderContext.quintuple.cityId : '';

          // order-status details for cust-capt
          if (this.order.status) {
            this.orderStatus = this.order.status;
          }

          if (this.orderType === 'delivery') {
            this.orderDetailsService.fetchDetailedOrderEarnings(this.orderId).subscribe(result => {
              let resp = this.parseCaptainEarningApiResponse(result[0])
              this.captainInvoiceDetails = {
                ...this.captainInvoiceDetails, dataFetched: true, earningsBreakup: resp,
                tip: resp['tip'],
                orderStatus : this.orderStatus
              };
            })
          }

          if (this.order && this.order.microCorridor) {
            this.microCorridorDetails = this.order.microCorridor;
          }

          this.getTransactionDetails(this.orderId, this.order.customer.id);

          this.deviceId = this.order && this.order.captain &&
            this.order.captain.device && this.order.captain.device.deviceId || null;
          this.isBatchable = this.order.orderMeta.isBatchable || this.isBatchable;
          this.batchId = this.order.batch && this.order.batch.batchId || null;
          if (this.isBatchable && this.batchId) {
            this.orderDetailsService.getBatchingInfo(this.batchId).subscribe(result => {
              if (result && result['data']) {
                this.batchingInfo = JSON.parse(result['data']);
                this.getBatchingDropDown();
              }
            }, err => {
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: 'Unable to fetch batching info',
              }));
            });
          }
          if (this.order.uniqueId) {
            this.uniqueId = this.order.uniqueId;
          }
          this.orderCurrentStatus = this.orderStatus || '';
          if (this.order.captainId) {
            this.riderId = this.order.captainId;
          } else if (this.orderStatus === 'customerCancelled') {
            const events = this.order.events || {};
            for (let event of events) {
              if (event['eventName'] == 'accepted') {
                this.riderId = event['captainId'] || this.riderId;
                this.captainDetails = event['captain'] || null;
              }
            }
          }
          const isExternalOTP = (this.order.otp && this.order.otp.drop && this.order.otp.drop.source === 'external');
          if (this.order.otp && !isExternalOTP) {
            this.otp = this.order.otp.pickup && this.order.otp.pickup.otp;
          }
          if (this.order.orderMeta) {
            this.dropOtpStatus = this.order.orderMeta.dropOtpEnabled ? 'ACTIVE' : 'INACTIVE';
          }
          if (this.order.otp && this.order.otp.drop) {
            this.dropOtp = this.order.otp.drop.otp;
          }

          if (this.order.events) {
            this.timeLineDetails = this.order.events;
          }

          if (this.order.delivery) {
            let deliveryInfo = this.order.delivery;
            if (deliveryInfo.config) {
              this.b2bConfig = deliveryInfo.config;
            }

            if (deliveryInfo.clientOrderId) {
              this.deliveryId = deliveryInfo.clientOrderId;
            }

            if (deliveryInfo.clientName) {
              this.clientName = deliveryInfo.clientName;
            }
            
            if(this.order.delivery.shipmentReadyTimeInEpoch) {
              this.timeLineDetails.push({
                status: 'shipmentReadyTimeInEpoch',
                eventName: 'shipmentReadyTimeInEpoch',
                updatedAt: this.order.delivery.shipmentReadyTimeInEpoch
              });
            }
            if (this.order.delivery.predictedShipmentReadyTimeInEpoch) {
              this.timeLineDetails.push({
                status: 'predictedShipmentReadyTimeInEpoch',
                eventName: 'predictedShipmentReadyTimeInEpoch',
                updatedAt: this.order.delivery.predictedShipmentReadyTimeInEpoch
              });
            }
            this.timeLineDetails.sort((a,b) => a.updatedAt > b.updatedAt ? 1 : -1);
            this.timeLineDetails = this.filterDuplicateStatus(this.timeLineDetails);
            
          }
          this.isRefreshing = false;
  
          if (this.orderId) {
            this.orderDetailsService.getCorrelationIds(this.orderId).subscribe(result => {
              if (result && result['info'] && result['info'].statusCode && result['info'].statusCode === 200) {
                if (result['data'] && result['data'].length > 0) {
                  for (const data in result['data']) {
                    if (result['data'][data] && result['data'][data].gatewayId) {
                      this.showTxnInfoModal = this.showTxnInfoModal || ['Razorpay-QR-Code', 'upi', 'Paytm-QR-Code', 'rapido-pg'].includes(result['data'][data].gateway)
                      this.correlationIds = this.correlationIds || [];
                      this.correlationIds.push(result['data'][data].gatewayId);
                      this.correlationTs = this.correlationTs || [];
                      this.correlationTs.push(this.getProperDate(result['data'][data].createdAt));
                    }
                  }
                }
              }
            });
          }
          if (this.order.fareCorrelationIds && this.order.customer && this.order.customerId
            && this.serviceDetail && this.serviceDetail._id) {
            const id = this.order.customerId;
            const customer = this.order.customer;
            const wallets = ['rapido', 'paytm', 'simpl', 'freecharge', 'lazypay'];
            const userData = {
              _id: id,
              firstName: customer.name,
              mobile: customer.mobile,
              email: customer.email,
              roles: ['customer']
            };
            this.customerDetailsService.getCustomerWallets(userData, wallets).subscribe(res => {
              if (res['info'].status === 'FAILURE') {
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.NOTIFICATION,
                  message: `Wallets do not exist for this user.`,
                }));
                this.walletInfo = { dataFetched: false };
              } else {
                const resp = { ...res, dataFetched: true };
                this.walletInfo = resp;
              }
            }, err => {
              this.walletInfo = { dataFetched: false };
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to fetch wallets for this user. Please try again`,
              }));
            });
            this.customerDetailsService.getDebitPreference(userData).subscribe(res => {
              this.coinPreference = res && res["debitPreference"] ? res["debitPreference"].find(preference => preference.name === "coin").preference : false;
            }, err => {
              this.coinPreference = false;
              this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: `Unable to fetch debit preference. Please try again`,
              }));
            })
            const requestIds = this.order.fareCorrelationIds || [];
            this.orderDetailsService.getFareEstimates(this.serviceDetail._id, requestIds,
              this.order.customerId).subscribe(result => {
                if (result && result['data'] && result['data'].length > 0) {
                  this.fareEstimateLogs = {
                    dataFetched: true, fareEstimateLogs: result['data'],
                    deliveryOrder: this.deliveryId ? true : false
                  };
                  this.mapData = {
                    ...this.mapData,
                    dataFetched: true,
                    fareEstimateLogs: result['data']
                  };
                  const firstEstimate = result['data'][result['data'].length - 1];
                  const latestEstimate = result['data'][0];
                  if (latestEstimate) {
                    this.distTime = {
                      ...this.distTime,
                      finalEstimatedDistance: latestEstimate.estimatedRoute && !this.orderDetailsHelper.isEmpty(latestEstimate.estimatedRoute.distance) ? ((latestEstimate.estimatedRoute.distance) / 1000).toFixed(2).toString() : '',
                      odrdDistance: latestEstimate.actualRoute && !this.orderDetailsHelper.isEmpty(latestEstimate.actualRoute.distance) ? ((latestEstimate.actualRoute.distance) / 1000).toFixed(2).toString() : '',
                    };
                    if (!this.orderDetailsHelper.isEmpty(latestEstimate.selectedRoute)) {
                      if (latestEstimate.selectedRoute === 'estimatedRoute') {
                        const distanceSuggested = "Final Estimated Distance";
                        this.distTime = { ...this.distTime, distanceSuggested: distanceSuggested };
                      }
                      if (latestEstimate.selectedRoute === 'actualRoute') {
                        const distanceSuggested = "ODRD (Actual) Distance";
                        this.distTime = { ...this.distTime, distanceSuggested: distanceSuggested };
                      }
                      if (latestEstimate.selectedRoute === 'hFRoute') {
                        const distanceSuggested = "HF Distance";
                        this.distTime = { ...this.distTime, distanceSuggested: distanceSuggested };
                      }
                    }
                    else {
                      this.distTime = { ...this.distTime, distanceSuggested: '' };
                    }
                    this.mapData = {
                      ...this.mapData,
                      events: this.order.events,
                      finalEstimatedDistance: latestEstimate.estimatedRoute && !this.orderDetailsHelper.isEmpty(latestEstimate.estimatedRoute.distance) ? ((latestEstimate.estimatedRoute.distance) / 1000).toFixed(2).toString() : '',
                      finalEstimatedPolyline: latestEstimate.estimatedRoute && !this.orderDetailsHelper.isEmpty(latestEstimate.estimatedRoute.polyline) ? latestEstimate.estimatedRoute.polyline : '',
                      finalEstimatedTime: latestEstimate.estimatedRoute && !this.orderDetailsHelper.isEmpty((latestEstimate.estimatedRoute.time)) ? ((latestEstimate.estimatedRoute.time) / 60).toFixed(2).toString() : '',
                      odrdTime: latestEstimate.actualRoute && !this.orderDetailsHelper.isEmpty(latestEstimate.actualRoute.time) ? ((latestEstimate.actualRoute.time) / 60).toFixed(2).toString() : '',
                      odrdDistance: this.distTime.odrdDistance,
                      odrdPolyline: latestEstimate.actualRoute && !this.orderDetailsHelper.isEmpty(latestEstimate.actualRoute.polyline) ? latestEstimate.actualRoute.polyline : ''
                    }
                    const ratecardData = latestEstimate.service;
                    const timeBasedSlab = this.orderDetailsHelper.getTimeBasedSlab(ratecardData, this.order.events);
                    this.rateCard = {
                      ...this.rateCard,
                      baseFare: !this.orderDetailsHelper.isEmpty(ratecardData.baseFare) ? (ratecardData.baseFare).toString() : '',
                      minimumFare: !this.orderDetailsHelper.isEmpty(ratecardData.minimumFare) ? (ratecardData.minimumFare).toString() : '',
                      insurance: !this.orderDetailsHelper.isEmpty(ratecardData.insuranceCharges) ? (ratecardData.insuranceCharges).toString() : '',
                      perKm: !this.orderDetailsHelper.isEmpty(ratecardData.pricePerKm) ? (ratecardData.pricePerKm).toString() : '',
                      priceWithKm: !this.orderDetailsHelper.isEmpty(ratecardData.priceWithKm) ? ratecardData.priceWithKm : [],
                      perMin: !this.orderDetailsHelper.isEmpty(ratecardData.pricePerMinute) ? (ratecardData.pricePerMinute).toString() : '',
                      rule: ratecardData.rule ? ratecardData.rule : '',
                      cancelCharge: !this.orderDetailsHelper.isEmpty(ratecardData.cancelCharge) ? (ratecardData.cancelCharge).toString() : '',
                      threshold: !this.orderDetailsHelper.isEmpty(ratecardData.cancelChargeTime) ? (ratecardData.cancelChargeTime).toString() : '',
                      waitTimeConfig: !this.orderDetailsHelper.isEmpty(ratecardData.waitTimeConfig) ? (ratecardData.waitTimeConfig) : { amount: 0, threshold: 0 },
                      pickupFare: !this.orderDetailsHelper.isEmpty(ratecardData.customer.pickupFare) ? ratecardData.customer.pickupFare : [],
                      ...(!this.orderDetailsHelper.isEmpty(timeBasedSlab.key) ? { timeBasedSlab: timeBasedSlab } : {})
                    }
                  }
                  if (firstEstimate) {
                    this.distTime = {
                      ...this.distTime, estimatedDistance: firstEstimate.direction &&
                        !this.orderDetailsHelper.isEmpty(firstEstimate.direction.distanceInKms) ? firstEstimate.direction.distanceInKms.toString() : '',
                      estimatedRideTime: !this.orderDetailsHelper.isEmpty(firstEstimate.direction.timeInMins) ? firstEstimate.direction.timeInMins.toString() : ''
                    };
                  }
                } else {
                  this.fareEstimateLogs = { dataFetched: false, fareEstimateLogs: null };
                }
              }, error => {
                this.fareEstimateLogs = { dataFetched: false, fareEstimateLogs: null };
                this.toasterService.showToaster(new Toaster({
                  type: ToasterType.WARNING,
                  message: 'Fare estimates couldn\'t be fetched',
                }));
              });
          } else {
            this.mapData = {
              ...this.mapData,
              fareEstimateLogs: null,
              dataFetched: true
            };
          }

          if (this.order.customerId) {
            this.getCustAdjustments(this.order.customerId);
          }

          this.getCaptAdjustments(this.order.captainId);

          if (this.order.customerId && this.order.captainId && this.order.orderTime && this.order.orderTime.date && this.order.captain && this.order.captain.mobile) {
            this.getOfflineRecharge(this.order.customerId, this.order.captainId, this.order.orderTime.date, this.order.captain.mobile);
          }

          // Add dispatch related info. Ex: propagation type
          if (this.order.dispatch) {
            this.dispatch = this.order.dispatch;
          }

          // cust-details
          if (this.order.customer) {
            this.custDetails = {
              customer: this.order.customer ? this.order.customer : {},
              deliveryOrder: this.deliveryId ? true : false,
              dataFetched: true
            };
          } else {
            this.custDetails = {
              customer: null,
              dataFetched: true
            };
          }

          // capt-details
          if (this.order.captain) {
            this.captDetails = {
              captain: this.order.captain || null,
              captainId: this.order.captainId || null,
              dataFetched: true
            };
            this.captainTimelineDetails = {
              captainId: this.order.captainId || null,
              status: this.orderStatus || '',
              abortReason: this.abortReason
            };
          } else if (this.captainDetails && this.riderId) {
            this.captDetails = {
              captain: this.captainDetails,
              captainId: this.riderId,
              dataFetched: true
            };
            this.captainTimelineDetails = {
              captainId: this.riderId,
              status: this.orderStatus || '',
              abortReason: this.abortReason
            };
          }

          if (this.order.captain || this.riderId) {
            this.captainAdjDetails = {
              rider: this.order.captainId || this.riderId || null,
              riderObj: this.order.captain || this.captainDetails || null,
              orderDate: this.order.orderTime && this.order.orderTime.date || null,
              uniqueId: this.order.uniqueId || null,
              customer: this.order.customer || '',
              finalDistance: this.pricing.distance &&
                this.pricing.distance.finalDistance ? this.pricing.distance.finalDistance : '',
              status: this.orderStatus,
              serviceDetail: this.serviceDetail && this.serviceDetail._id || null,
              serviceId: this.serviceDetail.service && this.serviceDetail.service._id ||
                this.serviceDetail.serviceId || null,
              service: this.serviceDetail && this.serviceDetail.service && 
                this.serviceDetail.service.name || null,
              dataFetched: true
            };
          } else {
            if (this.orderStatus != 'customerCancelled') {
              this.captDetails = {
                rider: null, riderObj: null,
                dataFetched: true
              };
            }
            this.captainTimelineDetails = {
              rider: null, status: this.orderStatus || '',
              dataFetched: false
            };
            this.captainAdjDetails = {
              rider: null, riderObj: null, orderDate: null, uniqueId: null,
              customer: null, finalDistance: null, dataFetched: false,
              status: this.orderStatus,
              serviceDetail: null, serviceId: null, service: null
            };
          }

          if (this.pricing.distance) {
            this.orderDistance = this.pricing.distance;
          }

          // Card 3
          // email invoice
          if (this.order.customerId) {
            this.emailInvoice = { userId: this.order.customerId, orderId: this.orderId };
          }

          // rate card
          if (this.serviceDetail) {
            this.serviceObj = this.serviceDetail;
            const data = this.serviceObj;
            this.rateCard.city = data.city ? data.city : '';
            this.rateCard.serviceName = data.service ? data.service : '';
            data.captain.regular = { ...data.captain.regular, ...this.newCaptainRateCard };
            this.captainRateCard = data.captain ? {
              city: data.city ? data.city : '',
              serviceName: data.service ? data.service : '', ...data.captain.regular
            } : this.rateCard

            if (this.order.orderType == 'hire' && this.order.hirePackageId) {
              let customerPackages = data.customer.package
              let captainPackages = data.captain.package
              let chosenPackageIndex = customerPackages.packageDetails.findIndex(packageDetail => packageDetail._id == this.order.hirePackageId)
              let chosenCustomerPackage = customerPackages.packageDetails[chosenPackageIndex]
              let chosenCaptainPackage = captainPackages.packageDetails[chosenPackageIndex]
              this.rateCard.perKm = customerPackages.pricePerKm
              this.rateCard.perMin = customerPackages.pricePerMinute
              this.rateCard.baseFare = chosenCustomerPackage.price
              this.rateCard.minimumFare = chosenCustomerPackage.price
              this.rateCard.priceWithKm = []
              this.rateCard.waitTimeConfig = customerPackages.waitTimeConfig || { amount: 0, threshold: 0 }
              this.rateCard.package = chosenCustomerPackage.duration + ' min ' + chosenCustomerPackage.distance + ' km'
              this.rateCard.rule = customerPackages.tnc && customerPackages.tnc.length > 0 ? customerPackages.tnc.join('\n') : this.rateCard.rule
              this.captainRateCard.perKm = captainPackages.pricePerKm
              this.captainRateCard.pricePerMinute = captainPackages.pricePerMinute
              this.captainRateCard.baseFare = chosenCaptainPackage.price
              this.captainRateCard.minimumFare = chosenCaptainPackage.price
              this.captainRateCard.priceWithKm = []
              this.captainRateCard.waitTimeConfig = captainPackages.waitTimeConfig || { amount: 0, threshold: 0 }
              this.captainRateCard.package = chosenCaptainPackage.duration + ' min ' + chosenCaptainPackage.distance + ' km'
            }
            

            let nightFareInfoFromRateCardApi = this.orderDetailsHelper.getCaptainTimeBasedSlabFromRateCardApi(this.captainRateCard, this.order.events);
            if (nightFareInfoFromRateCardApi && Object.keys(nightFareInfoFromRateCardApi).length !== 0) {
              this.captainRateCard.nightFareTooltip = nightFareInfoFromRateCardApi.value
            }         
          }

          // invoice details
          let customerIdForInvoice: any;
          if (this.order.customerId) {
            customerIdForInvoice = this.order.customerId;
          }
          this.invoiceDetails = {
            ...this.invoiceDetails,
            deliveryOrder: this.deliveryId ? true : false,
            orderStatus: this.orderStatus || '',
            customerId: customerIdForInvoice
          };

          // cashback
          if (this.pricing.cashBack && this.pricing.cashBack.length) {
            const cashBackObj = this.pricing.cashBack;
            let totalCashBack = 0;
            for (const cashback in cashBackObj) {
              if (cashBackObj[cashback] && cashBackObj[cashback].ownerType === 'customer') {
                totalCashBack += cashBackObj[cashback].cashBackAmount;
              }
            }
            this.invoiceDetails = { ...this.invoiceDetails, cashback: totalCashBack.toString() };
          }
          // cash to be collected
          if (this.payment.collected && !this.orderDetailsHelper.isEmpty(this.payment.collected['cash']) &&
            this.payment.collected['cash'] >= 0) {
            this.invoiceDetails = {
              ...this.invoiceDetails,
              cashToBeCollected: this.payment.collected['cash'].toString()
            };
          }
          // subtotal
          if (!this.orderDetailsHelper.isEmpty(this.pricing.subTotal) && this.pricing.subTotal >= 0) {
            this.invoiceDetails = {
              ...this.invoiceDetails,
              subTotal: this.pricing.subTotal.toString()
            };
          }
          // cancelFee
          if (!this.orderDetailsHelper.isEmpty(this.pricing.cancelFee) && this.pricing.cancelFee >= 0) {
            this.invoiceDetails = {
              ...this.invoiceDetails,
              cancelFee: this.pricing.cancelFee.toString() || ''
            };
          }
          // previous due
          if (!this.orderDetailsHelper.isEmpty(this.pricing.prevDue) && this.pricing.prevDue >= 0) {
            this.invoiceDetails = {
              ...this.invoiceDetails,
              prevDue: this.pricing.prevDue.toString()
            };
          }
          // Non-zero wallet
          if (this.payment.collected) {
            const walletDetails = this.payment.collected, nonZeroWalletDetails = {};
            const wallets = Object.keys(walletDetails);
            let nonZeroWallet;
            for (const wallet in wallets) {
              if (wallets[wallet] && walletDetails[wallets[wallet]]) {
                nonZeroWallet = wallets[wallet];
                nonZeroWalletDetails[nonZeroWallet] = walletDetails[nonZeroWallet] ? walletDetails[nonZeroWallet].toString() : '';
              }
            }
            this.invoiceDetails = { ...this.invoiceDetails, collected: nonZeroWalletDetails };
          }
          if (this.order.captainId && this.order.orderTime && this.order.orderTime.date) {
            this.getTipDetails(orderResponse);
          }

          if (this.payment.mode) {
            this.paymentMode = this.payment.mode;
            this.invoiceDetails = { ...this.invoiceDetails, paymentMode: this.payment.mode, dataFetched: true };
          }

          if (this.pricing.discount) {
            this.invoiceDetails = { ...this.invoiceDetails, discount: this.pricing.discount, dataFetched: true };
          }

          if (!this.orderDetailsHelper.isEmpty(this.pricing.amount)) {
            this.invoiceDetails = {
              ...this.invoiceDetails, amount: (this.pricing.amount.toFixed(2)).toString(),
              dataFetched: true
            };
          }

          if (!this.orderDetailsHelper.isEmpty(this.pricing.rateCardAmount)) {
            this.invoiceDetails = {
              ...this.invoiceDetails, rateCardAmount: (this.pricing.rateCardAmount.toFixed(2)).toString(),
              dataFetched: true
            };
          }

          if (!this.orderDetailsHelper.isEmpty(this.pricing.rateCardAmountWithSurge)) {
            this.invoiceDetails = {
              ...this.invoiceDetails, dataFetched: true,
              rateCardAmountWithSurge: (this.pricing.rateCardAmountWithSurge.toFixed(2)).toString()
            };
          }

          if (Array.isArray(this.order.events)) {
            const requestedAt = this.order.events.find(u => u.status === 'requested');
            if (requestedAt && requestedAt.payment && requestedAt.payment.mode) {
              this.invoiceDetails = {
                ...this.invoiceDetails,
                initialPaymentMode: requestedAt.payment.mode, dataFetched: true
              };
            }
          }

          let amountBreakup;
          amountBreakup = this.pricing.amountBreakup || null;
          if (this.pricing.offer && this.pricing.offer.applied && this.pricing.offer.code) {
            this.invoiceDetails = {
              ...this.invoiceDetails, dataFetched: true,
              offer: {
                code: this.pricing.offer && this.pricing.offer.code || null,
                desc: this.pricing.offerText || '' //todo - offerText missing in ordersv2
              }
            };
          }
          if (amountBreakup) {
            this.invoiceDetails = {
              ...this.invoiceDetails,
              baseFare: amountBreakup.baseFare && !this.orderDetailsHelper.isEmpty(amountBreakup.baseFare.total) ?
                amountBreakup.baseFare.total.toString() : '',
              distanceFare: amountBreakup.distanceFare && !this.orderDetailsHelper.isEmpty(amountBreakup.distanceFare.total) ?
                amountBreakup.distanceFare.total.toString() : '',
              timeFare: amountBreakup.timeFare && !this.orderDetailsHelper.isEmpty(amountBreakup.timeFare.total) ?
                        amountBreakup.timeFare.total.toString() : '',
              nightFare: amountBreakup.captain && amountBreakup.captain.timeBasedCharges && !this.orderDetailsHelper.isEmpty(amountBreakup.captain.timeBasedCharges.total) && amountBreakup.captain.timeBasedCharges.total > 0?
                        amountBreakup.captain.timeBasedCharges.total.toString() : '',
              insurance: amountBreakup.insuranceCharges && !this.orderDetailsHelper.isEmpty(amountBreakup.insuranceCharges.total) ?
                amountBreakup.insuranceCharges.total.toString() : '',
              dynamicSurge: amountBreakup.surge && !this.orderDetailsHelper.isEmpty(amountBreakup.surge.total) ?
                amountBreakup.surge.total.toString() : '',
              staticSurge: amountBreakup.staticSurge && !this.orderDetailsHelper.isEmpty(amountBreakup.staticSurge.total) ?
                amountBreakup.staticSurge.total.toString() : '',
              dynamicFare: amountBreakup.dynamicFare && !this.orderDetailsHelper.isEmpty(amountBreakup.dynamicFare.total) ?
                amountBreakup.dynamicFare.total.toString() : '',
              waitTimeConfig: amountBreakup.waitTimeCharges || { unit: 0, quantity: 0, total: 0 },
              pickupFare: amountBreakup.pickupFare && !this.orderDetailsHelper.isEmpty(amountBreakup.pickupFare.total) ?
                amountBreakup.pickupFare.total.toString() : '',
              tollFare: amountBreakup.tollFare && !this.orderDetailsHelper.isEmpty(amountBreakup.tollFare.total) ?
              amountBreakup.tollFare.total.toString() : '',
              bidDelta: amountBreakup.bidDelta && !this.orderDetailsHelper.isEmpty(amountBreakup.bidDelta.total) ? 
              amountBreakup.bidDelta.total.toString() : '',
              parkingCharges: amountBreakup.parkingCharges && !this.orderDetailsHelper.isEmpty(amountBreakup.parkingCharges.total) ?
              amountBreakup.parkingCharges.total.toString() : '',
            };

            if (amountBreakup.hasOwnProperty("timeBasedCharges") && amountBreakup.timeBasedCharges.total > 0) {
              this.invoiceDetails = {
                ...this.invoiceDetails,
                timeBasedCharges: {
                  key: amountBreakup.timeBasedCharges.key,
                  total: amountBreakup.timeBasedCharges.total.toString()
                }
              }
            }
            let totalDiscount = 0;
            if (amountBreakup.hasOwnProperty("perceptionDiscount") && amountBreakup.perceptionDiscount.total && amountBreakup.perceptionDiscount.total > 0) {	
              this.invoiceDetails = {	
                ...this.invoiceDetails,	
                perceptionDiscount: {	
                  key: amountBreakup.perceptionDiscount.key,	
                  total: amountBreakup.perceptionDiscount.total.toString()
                }	
              }
              totalDiscount += amountBreakup.perceptionDiscount.total;
            }
            if (amountBreakup.hasOwnProperty("discount") && amountBreakup.discount.total && amountBreakup.discount.total > 0) {	
              this.invoiceDetails = {	
                ...this.invoiceDetails,	
                discount: {	
                  key: amountBreakup.discount.key,	
                  total: amountBreakup.discount.total.toString()
                }	
              }
              totalDiscount += amountBreakup.discount.total;	
            }
            this.invoiceDetails = {
              ...this.invoiceDetails,
              totalDiscount: totalDiscount
            }
          }

          // distance-and-time
          if (this.pricing.distance) {
            const pricingDetails = this.pricing;
            this.distanceDetails = pricingDetails.distance;
            const finalDistance = this.distanceDetails.finalDistance ? (parseFloat(this.distanceDetails.finalDistance).toFixed(2)).toString() : '0';
            const hfDistance = this.distanceDetails.hfDistance ? (parseFloat(this.distanceDetails.hfDistance).toFixed(2)).toString() : '0';
            this.distTime = {
              ...this.distTime,
              dataFetched: true,
              finalDistance
            };
            this.mapData = {
              ...this.mapData,
              finalDistance: hfDistance
            };
            this.distanceDetails['orderAmount'] = pricingDetails.amount;
          }

          if (this.distanceDetails && !this.orderDetailsHelper.isEmpty(this.distanceDetails.hfDistance)) {
            this.distTime = {
              ...this.distTime, hfDistance: (this.distanceDetails.hfDistance.toFixed(2)).toString(),
              dataFetched: true
            };
          }

          if (!this.orderDetailsHelper.isEmpty(this.pricing.rideTime)) {
            const finalRideTime = (this.pricing.rideTime.toFixed(2)).toString();
            this.distTime = {
              ...this.distTime,
              finalRideTime,
              dataFetched: true
            };
            this.mapData = {
              ...this.mapData,
              finalRideTime
            };
          }

          if (this.order.pickupLocation && this.order.pickupLocation.cluster) {
            this.distTime = {
              ...this.distTime,
              pickupCluster: {
                name: this.order.pickupLocation.cluster
              },
              dataFetched: true
            };
          }

          if (this.order.dropLocation && this.order.dropLocation.cluster) {
            this.distTime = {
              ...this.distTime,
              dropCluster: {
                name: this.order.dropLocation.cluster
              },
              dataFetched: true
            };
          }

          if (!this.orderDetailsHelper.isEmpty(this.order.acceptToPickupDistance)) {
            this.distTime = {
              ...this.distTime,
              acceptToPickup: ((this.order.acceptToPickupDistance / 1000).toFixed(2)).toString(),
              dataFetched: true
            };
          }

          if (this.cancellationInformation &&
            !this.orderDetailsHelper.isEmpty(this.cancellationInformation.acceptToCancelled)) {
            this.distTime = {
              ...this.distTime,
              acceptToCancel: ((this.cancellationInformation.acceptToCancelled).toFixed(2)).toString(),
              dataFetched: true
            };
          }

          // mapped-riders
          if (this.order.mappedCaptains) {
            this.mapRiderDetails = {
              mappedRiderId: this.order.mappedCaptains,
              dataFetched: true
            };
          } else {
            this.mapRiderDetails = {
              mappedRiderId: null,
              dataFetched: true
            };
          }

          // order-logs
          if (this.order.events) {
            this.orderLogDetails = { orderLogs: this.order.events, dataFetched: true };
            this.mapData = {
              ...this.mapData,
              orderLogPoints: this.order.events
            };
            this.fareEstimateLogs = { ...this.fareEstimateLogs, orderLogs: this.order.events};
          } else {
            this.orderLogDetails = { orderLogs: null, dataFetched: true };
          }

          // customer-adjustemnt
          if (this.order.customer && this.order.customerId) {
            this.customerAdjDetails = {
              customerId: this.order.customerId, customer: this.order.customer,
              uniqueId: this.order.uniqueId, orderAmount: this.pricing.amount,
              deliveryOrder: this.deliveryId ? true : false, status: this.orderStatus,
              dataFetched: true
            };
          }

          // custActHistory
          if (this.order.uniqueId) {
            this.custActHistory = {
              dataFetched: true,
              id: this.order._id,
              userId: this.order.customerId || ''
            };
          }

          // rating
          if (this.order.feedback) {
            this.ratingDetails = {
              feedback: this.order.feedback,
              deliveryOrder: this.deliveryId ? true : false,
              dataFetched: true,
              status: this.orderStatus
            };
          } else {
            this.ratingDetails = {
              feedback: null,
              deliveryOrder: this.deliveryId ? true : false,
              status: this.orderStatus,
              dataFetched: true
            };
          }
  
          
          await this.checkDropOrderEligibility();
          const pickupOtp = this.order.otp && this.order.otp.pickup || {};
          const dropOtp = this.order.otp && this.order.otp.drop || {};
          if (this.orderType === 'bfs' && this.order.packageDelivery) {
            // Book from store package delivery (bfs)
            this.packageDetails = {
              orderType: this.orderType,
              bfs: this.order.packageDelivery || this.order.bfs
            };
            this.packageDetails['pickup'] = this.order.pickupLocation || {};
            this.packageDetails['drop'] = this.order.dropLocation || {};
            this.packageDetails['otp'] = pickupOtp.otp || null;
            this.packageDetails['dropOtp'] = dropOtp.otp || null;
            this.packageDetails['orderId'] = this.orderId;
            this.packageDetails['orderType'] = this.orderType;
            this.packageDetails['orderValue'] = this.order.packageDelivery.orderValue;
            this.packageDetails.pickupOtp = this.otp || null;
            this.packageDetails.dropOtpStatus = this.dropOtpStatus;
          } else if (this.order.packageDelivery) {
            this.packageDetails = {
              orderType: this.orderType,
              c2c: this.order.packageDelivery
            };
            this.packageDetails['pickup'] = this.order.pickupLocation || {};
            this.packageDetails['drop'] = this.order.dropLocation || {};
            this.packageDetails['otp'] = pickupOtp.otp || null;
            this.packageDetails['dropOtp'] = dropOtp.otp || null;
            this.packageDetails['orderId'] = this.orderId;
            this.packageDetails['orderType'] = this.orderType;
            this.packageDetails.pickupOtp = this.otp || null;
            this.packageDetails.dropOtpStatus = this.dropOtpStatus;
          } else if (this.orderType === 'delivery') {
            this.orderDetailsService.fetchB2bOrderDetails(this.orderId).subscribe(result => {
              if (result && result['body']) {
                let details;
                try {
                  details = JSON.parse(result['body']);
                  if (details) {
                    let deliveryOrderData = details.data || {};
                    this.packageDetails = { orderType: this.orderType, data: { ...this.order, ...deliveryOrderData } };
                    if (details.data && details.data.updatedAt) {
                      const lastStatus = details.data.updatedAt[details.data.updatedAt.length - 1].status;
                      if (lastStatus === 'cancelRequested' || lastStatus === 'incorrectlyDropped') {
                        this.FNDCase = details.data.updatedAt[details.data.updatedAt.length - 1];
                      }
                      const otpMeta = details.data.otpMeta;
                      if (otpMeta && otpMeta.started && otpMeta.started.otp) {
                        this.otp = otpMeta.started.otp
                      }
                      this.packageDetails.pickupOtp = this.otp || null;
                      this.packageDetails.dropOtpStatus = this.dropOtpStatus;

                    }
                  }
                } catch (err) {
                  this.packageDetails = {};
                }
              }
            }, error => {
              this.packageDetails = {};
            });
          }

          // re-assign details
          this.reAssignDetails = {
            ...this.reAssignDetails,
            orderType: this.orderType ? this.orderType : '',
            serviceType: this.serviceDetail && this.serviceDetail._id ?
              this.serviceDetail._id : '',
            customerId: this.order.customer ? this.order.customer.id : '',
            pickUpLocation: this.order.pickupLocation ? this.order.pickupLocation : '',
            dropLocation: this.order.dropLocation ? this.order.dropLocation : ''
          };
        } else {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: 'Unable to fetch order details',
          }));
        }
        if (this.pageRefreshed && this.order) {
          this.pageRefreshed = false;
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.NOTIFICATION,
            message: 'Page refreshed successfully',
          }));
        }
      }, error => {
        this.toasterService.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'Unable to find this order. Please re-check the orderId'
        }));
        this.custDetails = this.errorResponse;
        this.captDetails = this.errorResponse;
        this.invoiceDetails = this.errorResponse;
        this.captainInvoiceDetails = this.errorResponse;
        this.rateCard = this.errorResponse;
        this.captainRateCard = this.errorResponse;
        this.distTime = this.errorResponse;
        this.mapRiderDetails = this.errorResponse;
        this.ratingDetails = this.errorResponse;
        this.orderLogDetails = this.errorResponse;
        this.custActHistory = this.errorResponse;
        this.captAdjustments = this.errorResponse;
        this.custAdjustments = this.errorResponse;
        this.customerAdjDetails = this.errorResponse;
        this.captainAdjDetails = this.errorResponse;
        this.orderTimeLine = this.errorResponse;
      
      });
  }

  parseOrderAcceptResponse(promiseObj, captainId){
    if(promiseObj.length > 0){
      promiseObj.forEach((element) => {
         if(element.captainId === captainId){
            if(element && element.promise && element.promise.earnings){
              this.orderPromiseMapping.totalEarning = element.promise.earnings.total ? this.orderDetailsHelper.getAmountValue(element.promise.earnings.total) : 0;
              this.orderPromiseMapping.orderEarning = element.promise.earnings.amountWithoutExtra ? this.orderDetailsHelper.getAmountValue(element.promise.earnings.amountWithoutExtra) : 0;
              this.orderPromiseMapping.extraEarning = element.promise.earnings.extra && element.promise.earnings.extra.amount ? this.orderDetailsHelper.getAmountValue(element.promise.earnings.extra.amount) : 0;
              return this.orderPromiseMapping;
            }
          }
      });
    }
    return this.orderPromiseMapping;
  }

  fetchRequiredEventFields() {
    const events = this.order.events || [];
    for (let event in events) {
      if (event['abortReason']) {
        this.abortReason = event['abortReason'];
      }
      if (event['cancellationInformation'] && event['cancellationInformation'].acceptToCancelled) {
        this.cancellationInformation = event['cancellationInformation'];
      }
    }
  }

  private async getInvoiceDetails() {
    try {
      const invoiceDetails = await this.orderDetailsService.fetchInvoiceReceiptDetails(this.orderId).toPromise();
      if (invoiceDetails) {
        this.invoiceDetailsMapping = invoiceDetails;
      }
    } catch (error) {
      this.invoiceDetailsMapping = "";
      console.log('An error occured while fetching invoice - ' + error);
    }
  }
  private async getNewCaptainRateCard() {
    try {
      const ratecards = await this.orderDetailsService.fetchCaptainRateCard(this.orderId).toPromise();
      if (ratecards && Array.isArray(ratecards)) {
        this.newCaptainRateCard = ratecards[0];
      }
    } catch (error) {
      console.log('An error occured while fetching captain rate card - ' + error);
    }
  }

  getTipDetails(orderResponse) {
    if(orderResponse && orderResponse.payment && orderResponse.payment.tip){
        const tip =  orderResponse.payment.tip.amount;
        this.invoiceDetails = { ...this.invoiceDetails, tip }
    }
  }

  getTransactionDetails(orderId, custId) {
    const walletTransactionSuceessStatus = 'done';
    this.customerTransactionDetailsService
      .getCustomerPassbookDetails(custId, 1, 0, custId, { title: 'order', orderId, transactionStatus: walletTransactionSuceessStatus })
      .subscribe(resp => {
        //@ts-ignore
        if (resp && resp.data && resp.data && resp.data.data && resp.data.data.length > 0) {
          //@ts-ignore
          let txn = resp.data.data[0];

          this.invoiceDetails = {
            ...this.invoiceDetails,
            walletChangeObjects: txn.walletChangeObjects,
            walletChangeReason: txn.walletChangeReason
          }
        }
      }, err => {
        console.log(`Unable to fetch the transaction details`, err);
      })
  }

  getCustAdjustments(id) {
    this.orderDetailsService.getCustomerAdjustments([this.orderId], id).subscribe(result => {
      if (result && result['info'] && result['info'].status === 'success') {
        this.custAdjustments = { dataFetched: true, data: result['data'] };
      }
    });
    this.orderDetailsService.getPrevDuesTransactions(id).subscribe(result => {
      if (result && result["data"] && result["data"]["response"]) {
        this.prevDuesAdjustments = {dataFetched: true, data: this.getPrevDuesAdjustments(result["data"]["response"]) };
      }
    });
  }
  getPrevDuesAdjustments(transactions){
    return transactions.outstandingBalanceTransactions.filter(ele => ele.orderId == this.orderId && ele.type == "debit")
  }
  getCaptAdjustments(id) {
    this.orderDetailsService.getCaptainAdjustments(this.orderId, id).subscribe(result => {
      if (result && result['info'] && result['info'].status === 'success') {
        this.captAdjustments = { dataFetched: true, data: result['data'] };
      }
    });
  }

  getOfflineRecharge(customerId, riderId, orderDate, riderMobile) {
    this.orderDetailsService.getOfflineRecharge(customerId, riderId, orderDate)
      .subscribe((result: any) => {
        const offlineRechargeInfo = result.data;
        if (!offlineRechargeInfo) {
          this.offlineRechargeInfo = {};
          return;
        }
        // this.offlineRechargeInfo = result.data;
        const formattedofflineRechargeInfo = {
          action: `Recharged ₹${offlineRechargeInfo.amountRequested}`,
          change: offlineRechargeInfo.initiator,
          reason: 'Offline Recharge',
          timeStamp: offlineRechargeInfo.timestamp || '-',
          adjustedBy: riderMobile,
        };
        this.offlineRechargeInfo = formattedofflineRechargeInfo;
      });
  }

  getBatchingDropDown() {
    const batchedOrders = this.batchingInfo && this.batchingInfo['data'] && this.batchingInfo['data'].orders || [];
    this.batchingOrderDetails = [];
    if (batchedOrders && batchedOrders.length) {
      for (const order in batchedOrders) {
        if (batchedOrders[order]) {
          this.batchingOrderDetails.push({
            id: batchedOrders[order]['orderId'],
            status: this.statusTextMapping[batchedOrders[order]['orderStatus']],
            statusClass: this.statusClassMapping[batchedOrders[order]['orderStatus']]
          });
        }
      }
    }
  }

  parseCaptainEarningApiResponse(response) {
    try {
      return {

        firstMile: {
          fmFare: response.earnings.breakUp.firstMileFare ? response.earnings.breakUp.firstMileFare.amount : 0.0,
          fmFareEnabled: response.earnings.breakUp.firstMileFare ? true : false
        },
        lastMile: {
          lmFare: response.earnings.breakUp.lastMileFare ? response.earnings.breakUp.lastMileFare.amount : 0.0,
          lmFareEnabled: response.earnings.breakUp.lastMileFare ? true : false
        },
        pickupFare: response.earnings.breakUp.pickupFare ? response.earnings.breakUp.pickupFare.amount : 0.0,
        ...(response.earnings.breakUp.cancellationEligibilityDetails) && {
          cancelationEligibility: {
            cancelationEligibility: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.cancellationEligibility : true,
            dailyLimitExceeded: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.dailyLimitExceeded : false,
            weeklyLimitExceeded: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.weeklyLimitExceeded : false,
            cancelationEligibilityCurrentOrder: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.cancellationEligibilityCurrentOrder : true,
            cancelationEligibilityReason: response.earnings.breakUp.cancellationEligibilityDetails ? response.earnings.breakUp.cancellationEligibilityDetails.cancellationEligibilityReason : 'Conditions Not Met'
          },
          cancelationFare: {
            amount: response.earnings.breakUp.cancellationFare ? response.earnings.breakUp.cancellationFare.amount : 0,
            remarks: response.earnings.breakUp.cancellationFare ? response.earnings.breakUp.cancellationFare.details.remarks : 'NA',
          },
        },
        tip: response.earnings.breakUp.tip ? response.earnings.breakUp.tip.amount : 0.0,
        orderBreakup: {
          surgeBreakup: {
            totalSurge: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.amount ? response.earnings.breakUp.surgeFare.amount : 0.0,
            dynamicSurge: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.details.dynamicSurge ? response.earnings.breakUp.surgeFare.details.dynamicSurge : 0.0,
            dynamicFare: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.details.dynamicFare ? response.earnings.breakUp.surgeFare.details.dynamicFare : 0.0,
            staticSurge: response.earnings.breakUp.surgeFare && response.earnings.breakUp.surgeFare.details.staticSurge ? response.earnings.breakUp.surgeFare.details.staticSurge : 0.0
          },
          preCommissionOrderEarning: response.earnings.preCommissionAmount,
          totalOrderEarning: response.earnings.amount,
          orderEarning: response.earnings.orderEarning,
          platformCharges: response.earnings.breakUp.platformCharges ? response.earnings.breakUp.platformCharges.amount : 0.0,
          timeFare: response.earnings.breakUp.timeFare ? response.earnings.breakUp.timeFare.amount : 0.0,
          baseFare: response.earnings.breakUp.baseFare ? response.earnings.breakUp.baseFare.amount : 0.0,
          distanceFare: response.earnings.breakUp.distanceFare ? response.earnings.breakUp.distanceFare.amount : 0.0,
          waitingFare: response.earnings.breakUp.waitingFare ? response.earnings.breakUp.waitingFare.amount : 0.0,
          minimumFare: response.earnings.breakUp.minimumFare ? response.earnings.breakUp.minimumFare.amount : 0.0,
          cashCollected: response.cashCollected ? response.cashCollected : 0.0,
          nightFare: response.earnings.breakUp.nightFare ? response.earnings.breakUp.nightFare.amount : 0.0,
          adjustments: `+₹${this.getCompressedBreakup(response.earnings.breakUp.adjustments)}`,
          penalty: `-₹${this.getCompressedBreakup(response.earnings.breakUp.penalty)}`,
          tds: `-₹${this.getCompressedBreakup(response.earnings.breakUp.tdsPenalty)}`,
          tollFare: response.earnings.breakUp.tollFare ? response.earnings.breakUp.tollFare.amount : 0.0,
          bidDelta: response.earnings.breakUp.bidDelta,
          parkingCharges: response.earnings.breakUp.parkingCharges ? response.earnings.breakUp.parkingCharges.amount : 0.0,
          activeUpiId: response.activeUpiId ? response.activeUpiId : '',
        }
      }
    } catch (err) {
      return {}
    }
  }

  // to render array fields liks adjustment, penalty for general earnings
  getCompressedBreakup(item){
    if (item && Array.isArray(item) && item.length > 0) {
      const itemValue = this.orderDetailsHelper.getCompressedBreakup(item).amount
      return Math.abs(itemValue)
    } else {
        return this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(item))
    }
  }

  goToOrderPage(id) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/order/${id}`]));
    window.open(url, '_blank');
  }

  popupClick(event) {
    this.dialog.open(PaymentStatusComponent, {
      width: '900vw',
      maxHeight: '405px',
      data: {
        title: `Payment Status`,
        info: {
          gatewayIds: this.correlationIds
        }
      },
    });
  }

  getCommonEarningDetails(orderEarnings, paymentType, prevDue) {
    if (orderEarnings.cashCollected) {
      let prevDueValue = '';
      if (prevDue > 0) {
        prevDueValue = `${this.orderDetailsHelper.getAmountValue(prevDue)}`
      }
      const cashCollectedValue = this.orderDetailsHelper.getAmountValue(orderEarnings.cashCollected)
      this.newOrderMapping.cashCollected = cashCollectedValue
      this.newOrderMapping.prevDue = prevDueValue
    }
    // add wallet money data
    if (orderEarnings.walletAmount) {
      const walletAmount = orderEarnings.walletAmount;
      this.newOrderMapping.moneyDeductedValue = walletAmount >= 0 ? `+${Math.abs(walletAmount)}` : `-${Math.abs(walletAmount)}`
      this.newOrderMapping.moneyDeductedKey = walletAmount >= 0 ? this.orderDetailsHelper.orderDetailsMapping.REDEEM_WALLET_CREDITED : this.orderDetailsHelper.orderDetailsMapping.REDEEM_WALLET_DEBITED
      this.newOrderMapping.isAmountDeducted = walletAmount >= 0 ? false : true
    }

    // add total earnings amount
    if (orderEarnings.earnings && !isNaN(orderEarnings.earnings.amount)) {
      const totalEarnings = Number(this.orderDetailsHelper.getRoundedAmount(orderEarnings.earnings.amount))
      this.newOrderMapping.total = totalEarnings
    }
    if (this.newOrderMapping.total && this.newOrderMapping.cashCollected && this.newOrderMapping.cashCollected.value) {
      this.newOrderMapping.amountToGetInWallet = Number(this.orderDetailsHelper.getRoundedAmount(this.newOrderMapping.total - orderEarnings.cashCollected))
    }
    this.newOrderMapping.paymentType = paymentType;
    return this.newOrderMapping;
  }

  parseCaptainNewEarningApiResponse(orderId, orderStatus, orderEarnings, invoiceReceipt, paymentType, prevDue) {
    this.newOrderMapping = this.getCommonEarningDetails(orderEarnings, paymentType, prevDue)
    const earningsBreakup = []
    const breakUp = orderEarnings.earnings.breakUp
    const minimumFareAmount = this.orderDetailsHelper.getAmount(breakUp.minimumFare)
    const baseFareAmount = this.orderDetailsHelper.getAmount(breakUp.baseFare)
    const distanceFareAmount = this.orderDetailsHelper.getAmount(breakUp.distanceFare)
    const timeFareAmount = this.orderDetailsHelper.getAmount(breakUp.timeFare)
    const distanceFareInfoLabel = this.orderDetailsHelper.getLabelWithInfo(breakUp.distanceFare)
    const TimeFareInfoLabel = this.orderDetailsHelper.getLabelWithInfo(breakUp.timeFare)
    let platformFee = breakUp.customerPlatformCharges ? breakUp.customerPlatformCharges : { amount : 0 }
    const conveyanceFee = JSON.parse(JSON.stringify(platformFee))
    const basicFare = baseFareAmount + distanceFareAmount + timeFareAmount

    const cancellation = breakUp[this.orderDetailsHelper.orderDetailsMapping.cancellationFareKey]

    //Commision & Taxes
    const taxes = this.orderDetailsHelper.getTaxCollected(invoiceReceipt)
    const commission = breakUp.platformCharges.amount ? parseFloat(breakUp.platformCharges.amount) : 0;
    const taxesFromCaptain = breakUp.platformCharges.details.totalTax ? parseFloat(breakUp.platformCharges.details.totalTax) : 0;
    const taxRebate = parseFloat(String(-taxesFromCaptain - taxes.value));
    conveyanceFee.amount += taxRebate;
    if(!breakUp.platformCharges.details.totalTax){
      conveyanceFee.amount = 0;
    }
    const commissionWithoutTax = (commission == 0) ? 0 : (-commission - taxes.value - taxRebate);
    let totalCommissionTaxes = ((taxes.value ? taxes.value : 0) + commissionWithoutTax + this.orderDetailsHelper.getAmount(conveyanceFee))

    const tdsPenalty = breakUp[this.orderDetailsHelper.orderDetailsMapping.tdsPenaltyKey]
    const orderFareTotal = ((minimumFareAmount > basicFare) ? minimumFareAmount : basicFare) + this.orderDetailsHelper.getAmount(platformFee)
    /**
       * In case there is cancellation fare then no need to check other calculations
    */
    if (cancellation && this.orderDetailsHelper.getAmount(cancellation) > 0 && orderStatus == "customerCancelled") {
      this.newOrderMapping.orderFare = this.orderDetailsHelper.getAmountValue(0);
      this.newOrderMapping.cancelationFare = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(cancellation));
      this.newOrderMapping.commisionTaxes = this.orderDetailsHelper.getAmountValue(0);
    } else {
      /**
       * else perform complere following calculation
      */
      this.newOrderMapping.orderFare = this.orderDetailsHelper.getAmountValue(orderFareTotal.toFixed(2))

      if (minimumFareAmount > (baseFareAmount + distanceFareAmount + timeFareAmount)) {
        this.newOrderMapping.minimumFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.minimumFare))
        if (platformFee) {
          this.newOrderMapping.platformFee = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(platformFee).toFixed(2))
        }
      } else {
        this.newOrderMapping.distanceFareInfoLabel = distanceFareInfoLabel.extraInfo;
        this.newOrderMapping.TimeFareInfoLabel = TimeFareInfoLabel.extraInfo;
        this.newOrderMapping.baseFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.baseFare).toFixed(2)),
          this.newOrderMapping.timeFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.timeFare).toFixed(2)),
          this.newOrderMapping.distanceFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.distanceFare).toFixed(2)),
          this.newOrderMapping.platformFee = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp.customerPlatformCharges).toFixed(2))
      }
      let totalExtraFare = this.orderDetailsHelper.getAmount(breakUp[this.orderDetailsHelper.orderDetailsMapping.surgeKey]) +
        this.orderDetailsHelper.getAmount(breakUp[this.orderDetailsHelper.orderDetailsMapping.firstMileFareKey]) +
        this.orderDetailsHelper.getAmount(breakUp[this.orderDetailsHelper.orderDetailsMapping.pickupFareKey])

      if (totalExtraFare > 0) {
        this.newOrderMapping.extraFareLabel = 'Extra Fare';
        this.newOrderMapping.extraFare = this.orderDetailsHelper.getAmountValue(+totalExtraFare.toFixed(2))
        this.newOrderMapping.surgeFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp['surgeFare']));
        if (breakUp['pickupFare']) {
          this.newOrderMapping.pickupFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp['pickupFare']));
        }
        if (breakUp['firstMileFare']) {
          const longPickUpInfoLabel = this.orderDetailsHelper.getLabelWithInfo(breakUp['firstMileFare'])
          this.newOrderMapping.longPickUpInfoLabel = longPickUpInfoLabel.extraInfo;
          this.newOrderMapping.longPickupFare = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(breakUp['firstMileFare']));
        }
      }

      if (tdsPenalty) {
        const amount = this.orderDetailsHelper.getAmount(this.orderDetailsHelper.getCompressedBreakup(tdsPenalty))
        totalCommissionTaxes = totalCommissionTaxes + Math.abs(amount)
      }
      this.newOrderMapping.commissionTaxes = totalCommissionTaxes ? `-${this.orderDetailsHelper.getAmountValue(+totalCommissionTaxes.toFixed(2))}` : 0;

      this.newOrderMapping.rapidoCommission = commissionWithoutTax ? `-${this.orderDetailsHelper.getAmountValue(+commissionWithoutTax.toFixed(2))}` : 0;

      this.newOrderMapping.gst = taxes && taxes.value ? `-${this.orderDetailsHelper.getAmountValue(taxes.value)}` : 0;

      if (conveyanceFee) {
        let fee = this.orderDetailsHelper.getAmountValue(+this.orderDetailsHelper.getAmount(conveyanceFee).toFixed(2))
        if (fee.startsWith('-')) {
          fee = fee.substring(1)
        }
        this.newOrderMapping.conveyanceFee = `-${fee}`
      }

      if (tdsPenalty) {
        this.newOrderMapping.tdsPenalty = `${this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getCompressedBreakup(tdsPenalty).amount)}`
      }
      if(breakUp.nightFare){
        this.newOrderMapping.nightFare = breakUp.nightFare ? breakUp.nightFare.amount : 0.0
      }

      // other breakup
      if (breakUp.adjustments) {
        if (Array.isArray(breakUp.adjustments) && breakUp.adjustments.length > 0) {
          const breakupArray = breakUp.adjustments
          const adjustmentValue = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getCompressedBreakup(breakupArray).amount)
          this.newOrderMapping.adjustments = adjustmentValue
        } else {
          this.newOrderMapping.adjustments = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(breakUp.adjustments))
        }
      }

      if (breakUp.penalty) {
        if (Array.isArray(breakUp.penalty) && breakUp.penalty.length > 0) {
          const breakupArray = breakUp.penalty
          const penaltyValue = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getCompressedBreakup(breakupArray).amount)
          this.newOrderMapping.penalty = penaltyValue
        } else {
          this.newOrderMapping.penalty = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(breakUp.penalty))
        }
      }


      if (breakUp.tip) {
        if (Array.isArray(breakUp.tip) && breakUp.tip.length > 0) {
          const breakupArray = breakUp.tip
          const breakupSpreadArray = this.orderDetailsHelper.getSpreadBreakup(breakupArray)
          breakupSpreadArray.forEach(breakupItem => {
            const value = this.orderDetailsHelper.getRoundedAmount(breakupItem.amount)
            this.newOrderMapping.tip = this.orderDetailsHelper.getAmountValue(value);
          })
        } else {
          this.newOrderMapping.tip = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(breakUp.tip))
        }
      }
      this.newOrderBreakup.orderBreakup = this.newOrderMapping;
      return this.newOrderBreakup;
    }
  }

  getCancellationBreakUp(cancellation) {
    this.newOrderMapping.orderFare = this.orderDetailsHelper.getAmountValue(0);
    this.newOrderMapping.cancelationFare = this.orderDetailsHelper.getAmountValue(this.orderDetailsHelper.getAmount(cancellation)),
      this.newOrderMapping.commisionTaxes = this.orderDetailsHelper.getAmountValue(0)
    return this.newOrderMapping;
  }
  async checkDropOrderEligibility() {
    const isPartialDrop = this.geoFence && this.geoFence.drop && this.geoFence.drop.validation && this.geoFence.drop.validation.status === "failed" ? true : false;
    if(this.paymentMode === "cash" && this.orderStatus === "started" && (this.orderType === "auto" || this.orderType === "app") && isPartialDrop){
      this.isDropOrderEnabled = true;
    }
    else{
      this.isDropOrderEnabled = false;
    }
  }
}
