import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserComplaintsService {

  server = environment.server;
  fetchComplaintsUrl = `${this.server}/api/svo/complaints/customer`;

  constructor(private http: MyHttpClient) { }

  fetchCustomerComplaints(customerId) {
    return this.http.get(`${this.fetchComplaintsUrl}/${customerId}`).pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      return throwError(error);
    }
  
  }