import { EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
var QuickFiltersComponent = /** @class */ (function () {
    function QuickFiltersComponent(datePipe) {
        var _this = this;
        this.datePipe = datePipe;
        this.setQuickFilters = new EventEmitter();
        this.quickFilters = [
            'Past 30 days',
            'M0',
            'M-1',
            'Last Week',
            'Today'
        ];
        this.selectedFilter = -1;
        this.setEmitSelections = function () {
            var _a;
            var emitSelections = (_a = {},
                _a[_this.calendarData.key] = [_this.startDate, _this.endDate],
                _a);
            _this.setQuickFilters.emit(emitSelections);
        };
        this.getCurrentMonthDateRanges = function () {
            var todayDate = new Date();
            var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
            _this.startDate = _this.formatDateForBody(firstDay);
            _this.endDate = _this.formatDateForBody(todayDate);
            console.log(_this.startDate, _this.endDate);
            _this.setEmitSelections();
        };
        this.getLastMonthDateRanges = function () {
            var todayDate = new Date();
            var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);
            var lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 0);
            _this.startDate = _this.formatDateForBody(firstDay);
            _this.endDate = _this.formatDateForBody(lastDay);
            console.log(_this.startDate, _this.endDate);
            _this.setEmitSelections();
        };
        this.getLastThirtyDaysDateRanges = function () {
            var todayDate = new Date();
            var firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 31);
            _this.startDate = _this.formatDateForBody(firstDay);
            _this.endDate = _this.formatDateForBody(todayDate);
            _this.setEmitSelections();
        };
        this.getLastWeekDateRanges = function () {
            var todayDate = new Date();
            var startDate = new Date();
            startDate.setDate(todayDate.getDate() - 7);
            var startDateFormat = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
            _this.startDate = _this.formatDateForBody(startDateFormat);
            _this.endDate = _this.formatDateForBody(todayDate);
            console.log(_this.startDate, _this.endDate);
            _this.setEmitSelections();
        };
        this.getTodayDateRanges = function () {
            var todayDate = new Date();
            _this.startDate = _this.formatDateForBody(todayDate);
            _this.endDate = _this.formatDateForBody(todayDate);
            console.log(_this.startDate, _this.endDate);
            _this.setEmitSelections();
        };
        this.getDateRanges = function (filter) {
            switch (filter) {
                case 'Past 30 days':
                    _this.getLastThirtyDaysDateRanges();
                    break;
                case 'M0':
                    _this.getCurrentMonthDateRanges();
                    break;
                case 'M-1':
                    _this.getLastMonthDateRanges();
                    break;
                case 'Last Week':
                    _this.getLastWeekDateRanges();
                    break;
                case 'Today':
                    _this.getTodayDateRanges();
                    break;
            }
        };
        this.onFilterClick = function (event, selectedFilterIndex, filter) {
            _this.selectedFilter = selectedFilterIndex;
            _this.getDateRanges(filter);
        };
    }
    QuickFiltersComponent.prototype.formatDateForBody = function (date) {
        if (!date) {
            return null;
        }
        date = new Date(date);
        var day = date.getDate();
        var monthIndex = date.getMonth() + 1;
        if (monthIndex < 10) {
            monthIndex = '0' + monthIndex;
        }
        if (day < 10) {
            day = '0' + day;
        }
        var year = date.getFullYear();
        return year + '-' + monthIndex + '-' + day;
    };
    QuickFiltersComponent.prototype.getSelectedDateRanges = function (data) {
        var key = Object.keys(data)[0];
        var duration = data[key];
        var sendStartDate = duration[0], sendEndDate = duration[1];
        this.startDate = this.formatDateForBody(sendStartDate);
        this.endDate = this.formatDateForBody(sendEndDate);
        this.setEmitSelections();
    };
    return QuickFiltersComponent;
}());
export { QuickFiltersComponent };
