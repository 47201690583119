import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { CustOrderDetailsService } from './cust-order-details.service';

@Component({
  selector: 'app-cust-order-details',
  templateUrl: './cust-order-details.component.html',
  styleUrls: ['./cust-order-details.component.css']
})
export class CustOrderDetailsComponent implements OnInit, OnChanges {

  constructor(private custOrderDetailsService: CustOrderDetailsService) { }
  @Input() custDetails: any;
  today = this.formatDate(new Date());
  public info: any;
  public data: any;
  public deliveryOrder = false;
  public pass: any = {name: '', desc: ''};
  public walletSnapshot: any = {name: '', amount: ''};

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.custDetails && changes.custDetails.currentValue) {
      if (!changes.custDetails.currentValue.dataFetched) {
        this.emptyCard();
      } else {
        if (this.custDetails && this.custDetails.customer && this.custDetails.customer.id) {
          if (this.custDetails.deliveryOrder) {
            this.deliveryOrder = this.custDetails.deliveryOrder;
          }
          this.constructCard();
          if (!this.deliveryOrder) {
            this.getSubscriptions();
          }
        } else {
          this.constructCard();
        }
      }
    }
  }

  emptyCard() {
    this.info = {
      header: 'Customer Details',
      dataFetched: false,
      data: null
    };
  }

  constructCard() {
    if (this.custDetails) {
      this.data = this.custDetails.customer || {};
      const pass = this.pass;
      let passInfo = '';
      if (!this.deliveryOrder) {
        if (pass.purchased) {
          if (pass.validTillDate && pass.remainingRides) {
            passInfo = 'End date : ' + pass.validTillDate + '\n' + ', Remaining Rides : ' + pass.remainingRides;
          }
          if (pass.priceText && pass.priceTextDesc) {
            passInfo = passInfo  + '\n' + ', Description : ' + pass.priceText + ', ' + pass.priceTextDesc;
          }
        }
      }
      let gender = 'Male';
      if (this.data.gender) {
        if (this.data.gender === '1') {
          gender = 'Female';
        } else if (this.data.gender === '2') {
          gender = 'Others';
        }
      }
      const dataToSend = [];
      dataToSend.push(
        {header: 'Name', headerClass: 'header-card-color', value: this.data.name ? this.data.name : '',
        redirectClass : 'redirect', route : this.data.mobile ? this.data.mobile : '', type : 'customer'},
        {header: 'Phone', headerClass: 'header-card-color', value: this.data.mobile ? this.data.mobile : ''},
        {header: 'Email', headerClass: 'header-card-color', value: this.data.email ? this.data.email : ''},
        {header: 'Gender', headerClass: 'header-card-color', value: gender}
      );
      if (!this.deliveryOrder) {
        dataToSend.push(
          {header: 'Subscription', value: this.pass && this.pass.purchased && this.pass.name ? this.pass.name : '',
          information: passInfo ? passInfo : '', headerClass: 'header-card-color'}
        );
      }
      this.info = {
        header: 'Customer Details',
        headerClass: 'color-card-header',
        dataFetched: true,
        data: dataToSend
      };
    }
  }

  getSubscriptions() {
    this.custOrderDetailsService.getPassInfo(this.custDetails.customer.id).subscribe(result => {
      this.pass = result['data'];
      this.constructCard();
    });
  }


  formatDate(date) {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;
    const year = date.getFullYear();
    return year + '-0' + monthIndex + '-0' + day;
  }

}
