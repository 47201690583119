import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomerSubscriptionService } from './cust-subscription-details.service'
import { Table, TableData, TableDataTypes, Pagination } from 'src/app/shared/types';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { ToasterService } from 'src/app/toaster.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-cust-subscription-details',
  templateUrl: './cust-subscription-details.component.html',
  styleUrls: ['./cust-subscription-details.component.css']
})
export class CustSubscriptionDetailsComponent implements OnInit {
  @Input() customerId: any;
  @Input() customerDetailsToggleableHeaders: string[];
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  subscriptionLogsTable: Table;
  transactions: any = [];
  pageNumber: Number = 0;
  offset: Number = 0;
  perPage: Number = 10;
  dataLoaded = false;
  userDetails = this.accessCookie('user');
  constructor(
    public dialog: MatDialog,
    private toasterService: ToasterService,
    private customerSubscriptionService: CustomerSubscriptionService
  ) { }

  ngOnInit() {
    this.initializeTable();
    this.fillDataToTable();
  }

  onPaginationChange(event) {
    this.pageNumber = event.pageNo;
    this.offset = (event.pageNo * event.perPage);
    this.perPage = event.perPage;
    this.fillDataToTable();
  }

  fillDataToTable() {
    if (this.customerId) {
      this.dataLoaded = false;
      this.customerSubscriptionService.getSubscriptionLogs(this.customerId, this.perPage, this.offset).subscribe(result => {
        this.dataLoaded = true;
        const createTableData = (value, className?, info?, hover?, onClick?) => {
          return new TableData({
            data: value,
            type: TableDataTypes.DATA,
            information: info,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
            stopHover: hover ? false : true,
            onClick: onClick,
          });
        }
        const customerSubsriptionLogs = result && result['data'] && result['data']['data'] || [];
        this.transactions = customerSubsriptionLogs.map((transaction) => {

          const isCancelled =  transaction.status && transaction.status === 'CANCELLED';
          return {
            date: createTableData(transaction.date, null, null, null, null),
            transactionId: createTableData(transaction.transactionId, null, null, null, null),
            modeOfPayment: createTableData(transaction.modeOfPayment, null, null, null, null),
            passPurchaseValue: createTableData(transaction.passValue, null, null, null, null),
            expiryDate: createTableData(transaction.expiryDate, null, null, null, null),
            passRuleName : createTableData(transaction.ruleName, null, null, null, null),
            action: new TableData({
              data: isCancelled ? 'Cancelled' : transaction.isActive ? 'Cancel' : 'Expired' ,
              type: TableDataTypes.ACTION,
              information: isCancelled ? 'Refunded amount ₹ ' + transaction.refund.amount : '',
              className: isCancelled ?
                'button-disabled' : 'cursor-pointer whiteSpacePre',
              showIcon: '',
              spanClass: 'revoke-button',
              iconClass: '',
              onClick: () => {
                if (!isCancelled && transaction.isActive) {
                  const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                    width: '400px',
                    data: { title: 'Are you sure you want to cancel the subscription ?',
                            type: 'subscription-details',
                            message: 'By cancelling you will be refunding the left amount for the Customer',
                            buttonText: 'Proceed'
                          }
                  });
                  confirmDialogRef.afterClosed().subscribe(confirmResult => {
                    if (confirmResult) {
                      this.customerSubscriptionService.cancelUserSubscription(transaction.transactionId)
                      .subscribe(result => {
                        this.toasterService.showToaster(new Toaster({
                          type: ToasterType.SUCCESS,
                          message: `Successfully cancelled subscription`,
                          delay: 5000
                        }));
                        this.fillDataToTable();
                      }, error => {
                        this.toasterService.showToaster(new Toaster({
                          type: ToasterType.WARNING,
                          message: error.error.metadata.info.message,
                          delay: 5000
                        }));
                      })
                    }
                  });
                } else {
                  this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: `This subscription is already cancelled.`
                  }));
                }
              }
            })
          };
        });
        this.subscriptionLogsTable['data'] = this.transactions;
        this.subscriptionLogsTable['pagination'].count = result['data']['count'];
      });
    } else {
      this.dataLoaded = true;
    }
  }

  initializeTable(pageNumber = 0) {
    this.subscriptionLogsTable = new Table({
      pagination: new Pagination(pageNumber, 10),
      defaultError: 'No Subscription History found',
      data: [],
      headers: {
        date: 'Date',
        transactionId: 'Transaction ID',
        modeOfPayment: 'Payment Mode',
        passRuleName : 'Pass Rule Name',
        passPurchaseValue: 'Pass Purchase Value',
        expiryDate: 'Expiry Date',
        action: 'Action'
      },
      toggleableHeaders: this.customerDetailsToggleableHeaders,
      selectedHeader: 3,
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.subscriptionLogsTable = null;
        this.ngOnInit();
      },
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  accessCookie(cookieName) {
    const name = cookieName + '=';
    const allCookieArray = document.cookie.split(';');
    for (let i = 0; i < allCookieArray.length; i++) {
      if (allCookieArray[i]) {
        const cookieItem = allCookieArray[i].trim();
        if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
        }
      }
    }
    return '';
  }
}