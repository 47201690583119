import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
var TimeLineService = /** @class */ (function () {
    function TimeLineService(http) {
        var _this = this;
        this.http = http;
        this.abortUrl = environment.server + '/api/abort';
        this.fraudUrl = environment.server + '/api/fraudEngine';
        this.changeStatusUrl = environment.server + '/api/orders';
        this.orderActivityLog = environment.server + "/api/eventLogs/orderActivity/logs";
        this.eventLogsUrl = environment.server + "/api/activityEvent";
        this.fetchOrderActivityLog = function (orderId, page, perPage) {
            return _this.http.get(_this.orderActivityLog + "/" + orderId + "/" + page + "/" + perPage).pipe(catchError(_this.handleError));
        };
    }
    TimeLineService.prototype.abortOrder = function (abortObj) {
        var requestBody = {
            abortObj: abortObj
        };
        return this.http.post(this.abortUrl + '/abortOrder', requestBody).pipe(catchError(this.handleError));
    };
    TimeLineService.prototype.fetchTags = function (orderId) {
        return this.http.get(this.fraudUrl + '/order-fraud-tags/' + orderId).pipe(catchError(this.handleError));
    };
    TimeLineService.prototype.reAssignOrder = function (orderId, reAssignObj) {
        var requestBody = {
            reAssignObj: reAssignObj
        };
        return this.http.post(this.abortUrl + '/re-assign/' + orderId, requestBody).pipe(catchError(this.handleError));
    };
    TimeLineService.prototype.pushOrderActivityEventLogs = function (reqObject) {
        return this.http.post(this.eventLogsUrl, reqObject).pipe(catchError(this.handleError));
    };
    // changeStatus(orderId, riderId, requestBody, status) {
    //   return this.http.post(this.changeStatusUrl + `/${orderId}/${riderId}/${status}`, requestBody).pipe(catchError(this.handleError));
    // }
    TimeLineService.prototype.changeStatus = function (orderId, riderId, requestBody, status) {
        return this.http.post(this.changeStatusUrl + ("/" + orderId + "/" + riderId + "/" + status), requestBody).pipe(catchError(this.handleError));
    };
    TimeLineService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    TimeLineService.ngInjectableDef = i0.defineInjectable({ factory: function TimeLineService_Factory() { return new TimeLineService(i0.inject(i1.MyHttpClient)); }, token: TimeLineService, providedIn: "root" });
    return TimeLineService;
}());
export { TimeLineService };
