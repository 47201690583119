import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../../app/http-client.service';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RideTrackerMapService {
  server = environment.server;
  actualRouteUrl = this.server + '/api/getActualRoute';
  orderRequestsUrl = this.server + '/api/getOrderRequests';
  cityInfoUrl = this.server + '/api/getCityInfo';
  tollsUrl = this.server + '/api/v1/geolayer/toll/list';

  getActualRoute(userId, startedTime, droppedTime) {
    const body = {
      userId: userId,
      startedTime: startedTime,
      droppedTime: droppedTime
    };
    return this.http.post(this.actualRouteUrl, body).pipe(catchError(this.handleError));
  }

  getOrderRequests(requestId, userId) {
    return this.http.post(this.orderRequestsUrl, {requestId, userId}).pipe(catchError(this.handleError));
  }

  getLiveLocation(captainId) {
    const url = `${environment.server}/api/currentLocation/${captainId}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  getRiderGeoLocation(captainId) {
    const url = `${environment.server}/api/rider/${captainId}/location`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  
  getCityInfo(cityId) {
    const url = `${this.cityInfoUrl}/${cityId}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  getCityTolls(cityId) {
    const url = `${environment.server}/api/geolayers/toll/list?cityId=${cityId}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
 
  
  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
  constructor(
    private http: MyHttpClient,
    private httpClient: HttpClient,
    ) { }
}
