import { MyHttpClient } from '../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityService } from '../entity.service';
import * as i0 from "@angular/core";
import * as i1 from "../http-client.service";
import * as i2 from "../entity.service";
var InvoiceDetailsService = /** @class */ (function () {
    function InvoiceDetailsService(http, entityService) {
        this.http = http;
        this.entityService = entityService;
        this.server = "" + environment.server;
        this.emailInvoiceUrl = this.server + '/api/emailInvoice';
    }
    InvoiceDetailsService.prototype.emailInvoice = function (orderId, userId, todayDate) {
        return this.http.get(this.emailInvoiceUrl + '/' + orderId + '/' + userId + '/' + todayDate).pipe(catchError(this.handleError));
    };
    InvoiceDetailsService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    InvoiceDetailsService.ngInjectableDef = i0.defineInjectable({ factory: function InvoiceDetailsService_Factory() { return new InvoiceDetailsService(i0.inject(i1.MyHttpClient), i0.inject(i2.EntityService)); }, token: InvoiceDetailsService, providedIn: "root" });
    return InvoiceDetailsService;
}());
export { InvoiceDetailsService };
