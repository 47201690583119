var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { CaptainAdjustmentService } from './capt-adjustment.service';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RoleBasedAccessService } from '../role-based-access.service';
import { CAPTAIN_REFUND_ACCESS, CAPTAIN_DEBIT_ACCESS, CAPTAIN_UPDATE_KM, RIDER } from '../roles.constants';
import { AppConfigService } from '../appConfig.service';
var CaptAdjustmentComponent = /** @class */ (function () {
    function CaptAdjustmentComponent(toasterService, captainAdjustmentService, route, dialog, roleBasedAccess, appConfigService) {
        this.toasterService = toasterService;
        this.captainAdjustmentService = captainAdjustmentService;
        this.route = route;
        this.dialog = dialog;
        this.roleBasedAccess = roleBasedAccess;
        this.appConfigService = appConfigService;
        this.adjustmentDone = new EventEmitter();
        this.distanceUpdated = false;
        this.roles = this.accessCookie('Roles').split(',');
        this.refundCapReasons = [];
        this.debitCapReasons = [];
        this.updateKmReasons = [];
        this.unavailableStatuses = ['new', 'onTheWay', 'arrived', 'started'];
        this.statusValue = [];
        this.info = {
            header: 'Captain Adjustment',
            headerClass: null,
            dataFetched: true,
            rows: []
        };
        this.success = false;
    }
    CaptAdjustmentComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var adjustmentConfig;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.orderId = this.route.snapshot.params.id;
                        this.accessRoles = this.accessCookie('accessRoles');
                        return [4 /*yield*/, this.appConfigService.getAdjustmentConfigForCaptain()];
                    case 1:
                        adjustmentConfig = _a.sent();
                        this.refundCapReasons = JSON.parse(adjustmentConfig.refundReasonsOrder);
                        this.debitCapReasons = JSON.parse(adjustmentConfig.debitReasonsOrder);
                        this.updateKmReasons = JSON.parse(adjustmentConfig.kmReasonsOrder);
                        return [2 /*return*/];
                }
            });
        });
    };
    CaptAdjustmentComponent.prototype.checkStatus = function () {
        // Push UpdateKm if logged in user has Access for it.
        var distanceAccess = Object.keys(CAPTAIN_UPDATE_KM);
        if ((this.orderStatus && this.orderStatus === 'dropped') && (this.roles.some(function (distanceRole) { return distanceAccess.indexOf(distanceRole) >= 0; }))
            && this.statusValue.indexOf('Update KM') < 0) {
            this.statusValue.push('Update KM');
        }
        // Push Refund if logged in user has Access for it.
        var refundAccess = Object.keys(CAPTAIN_REFUND_ACCESS);
        if ((this.roles.some(function (redundRole) { return refundAccess.indexOf(redundRole) >= 0; })) && this.statusValue.indexOf('Refund') < 0) {
            this.statusValue.push('Refund');
        }
        // Push Debit if logged in user has Access for it.
        var debitAccess = Object.keys(CAPTAIN_DEBIT_ACCESS);
        if ((this.roles.some(function (debitRole) { return debitAccess.indexOf(debitRole) >= 0; })) && this.statusValue.indexOf('Debit') < 0) {
            this.statusValue.push('Debit');
        }
        this.constructData();
    };
    CaptAdjustmentComponent.prototype.constructData = function () {
        if (this.statusValue.length > 0) {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'capt-adj-action', default: undefined, className: 'col-md-6' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: [],
                        placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'capt-adj-amount', className: 'col-md-6' }
                ]
            ];
        }
        else {
            this.info = {
                header: 'Captain Adjustment',
                accessDenied: true,
                dataFetched: true
            };
        }
    };
    CaptAdjustmentComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.captainAdjDetails && changes.captainAdjDetails.currentValue) {
            if (changes.captainAdjDetails.currentValue.deliveryOrder) {
                this.emptyCard('Not available for Delivery orders');
            }
            else if (changes.captainAdjDetails.currentValue.status &&
                this.unavailableStatuses.indexOf(changes.captainAdjDetails.currentValue.status) !== -1) {
                this.emptyCard('Not available for the current order status');
            }
            else if (!changes.captainAdjDetails.currentValue.dataFetched) {
                this.emptyCard('Cannot load adjustment');
            }
        }
        if (changes && changes.distanceDetails && changes.distanceDetails.currentValue) {
            this.distanceUpdated = changes.distanceDetails.currentValue.userUpdatedFlag;
            this.orderAmount = changes.distanceDetails.currentValue['orderAmount'] ? changes.distanceDetails.currentValue['orderAmount'] : 0;
        }
        this.checkStatus();
    };
    CaptAdjustmentComponent.prototype.checkAccess = function (role) {
        if (this.accessRoles && this.accessRoles.indexOf(role) !== -1) {
            return true;
        }
        else {
            return false;
        }
    };
    CaptAdjustmentComponent.prototype.emptyCard = function (message) {
        this.info = {
            header: 'Captain Adjustment',
            dataFetched: false,
            emptyMessage: message || 'No data available'
        };
    };
    CaptAdjustmentComponent.prototype.changeRows = function (event) {
        if (event === 'Update KM') {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'capt-adj-action', default: 'Update KM', className: 'col-md-6' },
                    { titleClass: 'form-title-class', title: 'Distance to update', type: 'text-input',
                        placeholder: 'Enter final distance', key: 'capt-adj-distance', className: 'col-md-6' },
                ],
                [
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown', values: this.updateKmReasons,
                        placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6' }
                ]
            ];
        }
        else if (event === 'Others') {
            this.info['rows'][1][1] = { titleClass: 'form-title-class', title: 'Other Reason', type: 'text-input',
                placeholder: 'Provide an explanation', key: 'capt-adj-other-reason' };
        }
        else if (event === 'Refund') {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'capt-adj-action', default: event, className: 'col-md-6' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                        values: this.refundCapReasons,
                        placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'capt-adj-amount', className: 'col-md-6' }
                ]
            ];
        }
        else if (event === 'Debit') {
            this.info['rows'] = [
                [
                    { titleClass: 'form-title-class', title: 'Action', type: 'single-searchable-dropdown', values: this.statusValue,
                        placeholder: 'Select status', key: 'capt-adj-action', default: event, className: 'col-md-6' },
                    { titleClass: 'form-title-class', title: 'Reason', type: 'single-searchable-dropdown',
                        values: this.debitCapReasons,
                        placeholder: 'Select reason', key: 'capt-adj-reason', className: 'col-md-6' }
                ],
                [
                    { titleClass: 'form-title-class', title: 'Amount', type: 'text-input', placeholder: 'Enter Amount', key: 'capt-adj-amount', className: 'col-md-6' }
                ]
            ];
        }
        else {
            if (this.info['rows'][1].length > 1) {
                this.info['rows'][1].splice(this.info['rows'][1].length - 1, 1);
            }
        }
    };
    CaptAdjustmentComponent.prototype.peformAdjustment = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var userDetails, confirmDialogRef, maxAdjAmount, isAdmin, message, amountEntered, reason, adjustmentType_1, role_1, adjustmentObj_1, updateDistanceObj_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userDetails = JSON.parse(this.accessCookie('user'));
                        if (!event['capt-adj-action']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please select action",
                            }));
                            return [2 /*return*/];
                        }
                        if (!event['capt-adj-reason']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter reason",
                            }));
                            return [2 /*return*/];
                        }
                        if ((event['capt-adj-action'] === 'Refund' || event['capt-adj-action'] === 'Debit') && !event['capt-adj-amount']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter valid amount",
                            }));
                            return [2 /*return*/];
                        }
                        // if (event['capt-adj-action'] !== 'Update km' && !this.orderAmount) {
                        //   this.toasterService.showToaster(new Toaster({
                        //     type: ToasterType.WARNING,
                        //     message: `No order amount found for this order. Contact support.`,
                        //   }));
                        //   return;
                        // }
                        // validation for Update Km
                        if (event['capt-adj-action'] === 'Update KM' && (!event['capt-adj-distance'] || event['capt-adj-distance'] <= 0)) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter valid distance",
                            }));
                            return [2 /*return*/];
                        }
                        // Others Validation
                        if (event['capt-adj-reason'] === 'Others' && !event['capt-adj-other-reason']) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter reason",
                            }));
                            return [2 /*return*/];
                        }
                        // validation for Refund and debit
                        if ((event['capt-adj-action'] === 'Refund' || event['capt-adj-action'] === 'Debit') && event['capt-adj-amount'] < 0) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter amount greater than 0",
                            }));
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.roleBasedAccess.performAdjustment(event['capt-adj-action'], RIDER)];
                    case 1:
                        maxAdjAmount = _a.sent();
                        message = '';
                        if (event['capt-adj-action'] === 'Update KM') {
                            message = ' adjust max of ' + maxAdjAmount + ' Km';
                            amountEntered = event['capt-adj-distance'];
                        }
                        else {
                            message = event['capt-adj-action'] + ' max Rs. ' + maxAdjAmount;
                            amountEntered = event['capt-adj-amount'];
                        }
                        if (event['capt-adj-action'] === 'Update KM') {
                            if ((Math.abs(parseInt(amountEntered, 10) - this.captainAdjDetails.finalDistance) > maxAdjAmount)) {
                                this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'You are allowed to  ' + message + '. Please enter less than ' + maxAdjAmount
                                }));
                                return [2 /*return*/];
                            }
                        }
                        else {
                            if (maxAdjAmount > 1 && (parseInt(amountEntered, 10) > maxAdjAmount)) {
                                this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: 'You are allowed to  ' + message + '. Please enter less than ' + maxAdjAmount
                                }));
                                return [2 /*return*/];
                            }
                        }
                        if (!(parseFloat(amountEntered) == amountEntered)) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: 'Please enter a valid number'
                            }));
                            return [2 /*return*/];
                        }
                        if (event['capt-adj-action'] === 'Debit') {
                            event['capt-adj-amount'] = -Math.abs(event['capt-adj-amount']);
                        }
                        reason = '';
                        if (event['capt-adj-reason'] === 'Others') {
                            reason = 'Others : ' + event['capt-adj-other-reason'];
                        }
                        else {
                            reason = event['capt-adj-reason'];
                        }
                        if ((event['capt-adj-action'] === 'Refund' && event['capt-adj-amount'] > maxAdjAmount)) {
                            this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Please enter amount lesser than or equal to " + maxAdjAmount,
                            }));
                            return [2 /*return*/];
                        }
                        // call api
                        if (event['capt-adj-action'] === 'Refund' || event['capt-adj-action'] === 'Debit') {
                            adjustmentType_1 = 'refunded', role_1 = '';
                            if (this.roles.includes('CCC Admin')) {
                                role_1 = 'CCC Admin';
                            }
                            else if (this.roles.includes('Captain care centre agents')) {
                                role_1 = 'Captain care centre agents';
                            }
                            adjustmentObj_1 = {
                                orderDate: this.captainAdjDetails.orderDate || '',
                                riderId: this.captainAdjDetails.rider || '',
                                city: this.captainAdjDetails.city && this.captainAdjDetails.city._id || '',
                                uniqueId: this.captainAdjDetails.uniqueId || '',
                                remarks: reason,
                                adjustedBy: userDetails['emailId'],
                                adjustedById: userDetails['_id'],
                                amount: Number(event['capt-adj-amount']),
                                orderId: this.orderId,
                                mobile: this.captainAdjDetails.riderObj.mobile || '',
                                name: this.captainAdjDetails.riderObj.name || '',
                                serviceDetail: this.captainAdjDetails.serviceDetail,
                                serviceId: this.captainAdjDetails.serviceId,
                                service: this.captainAdjDetails.service
                            };
                            confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                                width: '335px',
                                data: { title: 'Are you sure you want to  ' + event['capt-adj-action'] + ' ?',
                                    type: 'order-details',
                                    reason: event['capt-adj-action'],
                                    message: '',
                                    buttonText: 'Yes, ' + event['capt-adj-action']
                                }
                            });
                            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                                if (confirmResult) {
                                    _this.disableSubmit = true;
                                    if (event['capt-adj-action'] === 'Refund') {
                                        _this.captainAdjustmentService.captainRefundDetails(_this.orderId, adjustmentObj_1, role_1).subscribe(function (response) {
                                            if (response && response['info'] && response['info'].status === 'success') {
                                                _this.toasterService.showToaster(new Toaster({
                                                    type: ToasterType.SUCCESS,
                                                    message: "Amount " + adjustmentType_1 + " successfully"
                                                }));
                                                _this.success = true;
                                                _this.disableSubmit = false;
                                                _this.checkStatus();
                                                _this.adjustmentDone.emit();
                                            }
                                        }, function (err) {
                                            _this.disableSubmit = false;
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.WARNING,
                                                message: err && err.error && err.error.message || "Unable to " + event['capt-adj-action'] + " amount"
                                            }));
                                        });
                                    }
                                    else {
                                        _this.captainAdjustmentService.captainAdjustmentDetails(_this.orderId, adjustmentObj_1).subscribe(function (response) {
                                            adjustmentType_1 = 'debited';
                                            if (response && response['info'] && response['info'].status === 'success') {
                                                _this.toasterService.showToaster(new Toaster({
                                                    type: ToasterType.SUCCESS,
                                                    message: "Amount " + adjustmentType_1 + " successfully"
                                                }));
                                                _this.success = true;
                                                _this.disableSubmit = false;
                                                _this.checkStatus();
                                                _this.adjustmentDone.emit();
                                            }
                                        }, function (err) {
                                            _this.disableSubmit = false;
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.WARNING,
                                                message: "Unable to " + event['capt-adj-action'] + " amount"
                                            }));
                                        });
                                    }
                                }
                                else {
                                    event['capt-adj-amount'] = Math.abs(event['capt-adj-amount']);
                                }
                            });
                        }
                        else { // update km
                            if (maxAdjAmount >= 1) {
                                isAdmin = true;
                            }
                            else {
                                isAdmin = false;
                            }
                            updateDistanceObj_1 = {
                                userRoles: this.roles,
                                isAdmin: isAdmin,
                                userUpdatedDistance: parseFloat(event['capt-adj-distance']),
                                reason: reason,
                                adjustedBy: userDetails['emailId']
                            };
                            confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
                                width: '335px',
                                data: { title: 'Are you sure you want to  update ?',
                                    type: 'order-details',
                                    reason: event['capt-adj-action'],
                                    message: '',
                                    buttonText: 'Yes, update'
                                }
                            });
                            confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                                if (confirmResult) {
                                    _this.captainAdjustmentService.setUserDistance(_this.orderId, updateDistanceObj_1).subscribe(function (response) {
                                        if (response && response['info'] && response['info'].status === 'success') {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.SUCCESS,
                                                message: "Distance updated successfully"
                                            }));
                                            _this.success = true;
                                            _this.checkStatus();
                                            _this.adjustmentDone.emit();
                                        }
                                        else {
                                            _this.toasterService.showToaster(new Toaster({
                                                type: ToasterType.SUCCESS,
                                                message: response['data'] ? response['data'] : 'Unable to update km'
                                            }));
                                        }
                                    }, function (err) {
                                        var errorMessage = '';
                                        if (err) {
                                            if (err['error'] && err['error'].info && err['error'].info.message) {
                                                errorMessage = err['error'].info.message;
                                            }
                                            if (err['error'] && err['error'].data) {
                                                errorMessage = err['error'].data;
                                            }
                                        }
                                        _this.toasterService.showToaster(new Toaster({
                                            type: ToasterType.WARNING,
                                            message: errorMessage ? errorMessage : "Unable to update km"
                                        }));
                                    });
                                }
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CaptAdjustmentComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    return CaptAdjustmentComponent;
}());
export { CaptAdjustmentComponent };
