import { Table } from '.';
var RecordTable = /** @class */ (function () {
    function RecordTable(config) {
        var _a;
        this.data = [];
        this.colCount = 1;
        this.DEFAULT_CONFIG = {
            colName: false,
            filter: false,
            refresh: false,
            ellipses: [],
        };
        this.DEFAULT_EVENTS = {
            onSearch: function (searchObj) { return console.log(searchObj); },
            onFilter: function () { return console.log('filter'); },
            onRefresh: function () { return console.log('refresh'); },
        };
        Object.assign(this, config);
        var headers = {};
        for (var i = 0; i < this.colCount; i++) {
            headers[i] = i;
        }
        var tableConfig = Object.assign({}, this.DEFAULT_CONFIG, config.config);
        var events = Object.assign({}, this.DEFAULT_EVENTS, config.events);
        var tableData = [];
        for (var i = 0; i < this.data.length; i++) {
            var col = i % this.colCount;
            if (col === 0) {
                tableData.push((_a = {},
                    _a[col] = this.data[i],
                    _a));
                continue;
            }
            var lastCol = tableData[tableData.length - 1];
            lastCol[col] = this.data[i];
        }
        var defaultStyle = {
            'margin-bottom': '0px'
        };
        var style = Object.assign({}, defaultStyle, config.style);
        this.table = new Table({
            dynamicClass: config.dynamicClass,
            tableHeader: config.header,
            headers: headers,
            data: tableData,
            style: style,
            config: tableConfig,
            onFilter: events.onFilter,
            onRefresh: events.onRefresh,
            onSearch: events.onSearch,
            filterComponents: config.filterComponents,
            filterCount: config.filterCount
        });
    }
    return RecordTable;
}());
export { RecordTable };
