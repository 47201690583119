import { Injectable } from '@angular/core';
import { MyHttpClient } from './http-client.service';

@Injectable({
  providedIn: 'root'
})

export class TollsService {
  constructor() { }
  private tollInfoWindow = new google.maps.InfoWindow({});

  private constructTollPolylines(tolls, map) {
    const tollPolylines: any = [];
    for (const toll of tolls) {
      const tollStartCoordinates = [
        {
          lat: toll.geolayer_geojson_start.features[0].geometry.coordinates[0][1],
          lng: toll.geolayer_geojson_start.features[0].geometry.coordinates[0][0]
        },
        {
          lat: toll.geolayer_geojson_start.features[0].geometry.coordinates[1][1],
          lng: toll.geolayer_geojson_start.features[0].geometry.coordinates[1][0]
        }
      ]

      const tollStartPolyline = new google.maps.Polyline({
        path: tollStartCoordinates,
        geodesic: true,
        strokeColor: "#009100",
        strokeOpacity: 1.0,
        strokeWeight: 8,
      });

      const startTollLineContent = `Toll line: ${toll.geolayer_geojson_start.features[0].properties.name}<br>Type: Entry`;
      tollStartPolyline.addListener('click', (event) => {
        this.tollInfoWindow.setContent(startTollLineContent);
        this.tollInfoWindow.setPosition(event.latLng);
        this.tollInfoWindow.open(map);
      });

      const tollEndCoordinates = [
        {
          lat: toll.geolayer_geojson_end.features[0].geometry.coordinates[0][1],
          lng: toll.geolayer_geojson_end.features[0].geometry.coordinates[0][0]
        },
        {
          lat: toll.geolayer_geojson_end.features[0].geometry.coordinates[1][1],
          lng: toll.geolayer_geojson_end.features[0].geometry.coordinates[1][0]
        }
      ]

      const tollEndPolyline = new google.maps.Polyline({
        path: tollEndCoordinates,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 8,
      });

      const endTollLineContent = `Toll line: ${toll.geolayer_geojson_end.features[0].properties.name}<br>Type: Exit`;
      tollEndPolyline.addListener('click', (event) => {
        this.tollInfoWindow.setContent(endTollLineContent);
        this.tollInfoWindow.setPosition(event.latLng);
        this.tollInfoWindow.open(map);
      });
      tollPolylines.push(tollStartPolyline);
      tollPolylines.push(tollEndPolyline);
    }
    return tollPolylines;
  }

  addTollsToMap(tolls, map) {
    const tollPolylines = this.constructTollPolylines(tolls, map);
    for (const tollPolyline of tollPolylines) {
      tollPolyline.setMap(map);
    }
    return tollPolylines;
  }

  removeTollsFromMap(map, tollPolylines) {
    if (tollPolylines && tollPolylines.length > 0) {
      for (const tollPolyline of tollPolylines) {
        tollPolyline.setMap(null);
      }
    }
    this.tollInfoWindow.close();
  }
  
  fetchTollLinesFromTolls(tolls) {
    const entryTollLines: string[] = [];
    const exitTollLines: string[] = [];
    for(const toll of tolls){
      entryTollLines.push(toll.geolayer_geojson_start.features[0].properties.name);
      exitTollLines.push(toll.geolayer_geojson_end.features[0].properties.name);
    }
    return {
      entryTollLines,
      exitTollLines
    }
  }

  fetchAssociatedTollFromTollLines(entryTollLine, exitTollLine, tolls) {
    for(const toll of tolls){
      if ((toll.geolayer_geojson_start.features[0].properties.name == entryTollLine) && 
      (toll.geolayer_geojson_end.features[0].properties.name == exitTollLine)) {
        return toll;
      }
    }
    return null;
  }
}
