<mat-sidenav-container class="home-container" autosize>
    <mat-sidenav #sidenav mode="side" opened class="sidenav-pane" fullscreen>
     <app-sidenav-list [toggleList]="showLabels" (isFilterOpen)="closeFilter()"></app-sidenav-list>
    </mat-sidenav>
    
  
    <mat-sidenav-content>
        <div id="overlay"></div>
      <mat-toolbar *ngIf="!fullViewMapService.dataReceived" class="primary-toolbar app-toolbar">
          <button mat-button (click)="showLabels = !showLabels; closeFilter()">
            <mat-icon>menu</mat-icon>
          </button>
          <div class="loggedIn-user" [matMenuTriggerFor]="userMenu" *ngIf="authService.isUserLoggedIn">
             <div class="toolbar-image"> 
               <img class="toolbar-avatar" [src]="user.imageUrl">
               <!-- <img class="toolbar-avatar" src="https://lh3.googleusercontent.com/-4QrOZKjNXNY/WxpXyP3wuOI/AAAAAAAAAAs/xbAOSBADSt4bba8gwdSNuvublB98jYgnQCEwYBhgL/w140-h140-p/goutam.jpeg"> -->
             </div>
            <div class="toolbar-info">
            <div class="toolbar-username clip-text" [attr.title]="user.name">{{user.name}}</div>
            <div class="toolbar-role clip-text" [attr.title]="user.role">{{user.role}}</div>
            </div>

            <!-- <mat-icon class="logout-button" (click)="logOut()">power_settings_new</mat-icon> -->
          </div>
      </mat-toolbar>
      <mat-menu #userMenu="matMenu">
          <button mat-menu-item (click)="logOut()">
            <mat-icon class="logout-icon">power_settings_new</mat-icon>
            Logout
          </button>
        </mat-menu>
      <!-- <mat-sidenav #filterSidenav mode="side" position="end" class="filters-pane">Filters</mat-sidenav> -->
      <app-filter-sidenav
        *ngIf="filterService.dataReceived" 
        [filterComponents]="filterService.filterComponents"
        [showFilter]="filterService.showFilters" 
        (filterStatus)="handleOverlay($event)" 
        [filterApplied]="filterService.appliedFilters"
        (change)="filterService.listen($event)" (submit)="filterService.applyFilters($event)"
        (clear)="filterService.clearFilters()"
      ></app-filter-sidenav>

    <app-full-view-map *ngIf="fullViewMapService.dataReceived" [mapData]="fullViewMapService.mapData"></app-full-view-map>
     <div class="main-container">
        <app-toaster style="
        top: 10px;
        position: fixed;
        width: 90%;
        z-index: 1000;
    " [config]="toasterService.toaster"></app-toaster>
      <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
  
  