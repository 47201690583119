import { MyHttpClient } from '../../../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../http-client.service";
var CaptainFailedOrdersService = /** @class */ (function () {
    function CaptainFailedOrdersService(http) {
        this.http = http;
        this.server = "" + environment.server;
        this.getFailedOrdersUrl = this.server + '/api/b2b/failedOrders';
        this.revokeFailedOrderUrl = this.server + '/api/b2b/revokeOrder';
        this.getActivityLogUrl = this.server + '/api/b2b/getActivityLog';
        this.getSuspensionLogUrl = this.server + '/api/b2b/getSuspensionLog';
    }
    CaptainFailedOrdersService.prototype.getFailedOrders = function (id, body) {
        return this.http.post(this.getFailedOrdersUrl + '/' + id, body).pipe(catchError(this.handleError));
    };
    CaptainFailedOrdersService.prototype.revokeFailedOrder = function (riderId, body) {
        return this.http.post(this.revokeFailedOrderUrl + '/' + riderId, body).pipe(catchError(this.handleError));
    };
    CaptainFailedOrdersService.prototype.getActivityLog = function (riderId, orderId) {
        return this.http.get(this.getActivityLogUrl + "/" + riderId + "/" + orderId).pipe(catchError(this.handleError));
    };
    CaptainFailedOrdersService.prototype.getSuspensionLog = function (riderId, action, event, orderIds, minDate, maxDate) {
        var riderObject = { riderId: riderId, action: action, event: event, orderIds: orderIds, minDate: minDate, maxDate: maxDate };
        var riderObjectJsonFormat = JSON.stringify(riderObject);
        return this.http.get(this.getSuspensionLogUrl + "?Object=" + riderObjectJsonFormat).pipe(catchError(this.handleError));
    };
    CaptainFailedOrdersService.prototype.handleError = function (error) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        }
        else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error("Backend returned code " + error.status + ", " +
                ("body was: " + error.error));
        }
        // return an observable with a user-facing error message
        return throwError(error);
    };
    CaptainFailedOrdersService.ngInjectableDef = i0.defineInjectable({ factory: function CaptainFailedOrdersService_Factory() { return new CaptainFailedOrdersService(i0.inject(i1.MyHttpClient)); }, token: CaptainFailedOrdersService, providedIn: "root" });
    return CaptainFailedOrdersService;
}());
export { CaptainFailedOrdersService };
