<div *ngIf="!dataLoaded" class="width-100">
    <div class="row" style="padding-bottom: 26px;">
        <div class="col-md-3">
            <ng-container>
                <bullet-list-content-loader></bullet-list-content-loader>
            </ng-container>
        </div>
        <div class="col-md-3">
            <ng-container>
                <bullet-list-content-loader></bullet-list-content-loader>
            </ng-container>
        </div>
        <div class="col-md-3">
            <ng-container>
                <bullet-list-content-loader></bullet-list-content-loader>
            </ng-container>
        </div>
        <div class="col-md-3">
            <ng-container>
                <bullet-list-content-loader></bullet-list-content-loader>
            </ng-container>
        </div>
    </div>        
</div>

<div *ngIf="dataLoaded">
  <div class="boxed-border width-100">
    <div class="row">
      <div class="col">
        <div class="bigger-title">{{info.dateRange}}</div>
      </div>
      <div class="col" *ngIf="info.dataFetched">
        <div class="pull-right row padding-10 margin-right-12">
            <fa-icon class="cursor-pointer margin-icons" (click)="refreshData()" [icon]="faRefresh"></fa-icon>
            <div *ngIf="!withoutFilter" class="cursor-pointer filter-button" (click)="onFilter()">
              <span class="is-flex margin-sides-5">
                <mat-icon class="filter-icon">filter_list</mat-icon>Filters
              </span>
            </div>
            <div class="filter-applied-stats" *ngIf="info && info.filterCount && info.filterCount > 0">
              <span class="filter">{{info.filterCount}}</span>
            </div>
        </div>
      </div>
    </div>
    <div class="line" *ngIf="!info.dataFetched"></div>
    <div *ngIf="info.stats" class="row padding-12 no-padding-top">
      <div class="col" *ngFor="let stats of info.stats">
        <div class="stat-boxes is-flex cursor-pointer" (click)="stats.onClick && stats.onClick()">
          <div *ngIf="!stats.className && !stats.style" class="stat-color"></div>
          <div *ngIf="stats.className" class="svgImage {{stats.className}}" [ngClass]="stats.className"></div>
          <div *ngIf="stats.style" class="svgImage" [ngStyle]="stats.style"></div>
          <div class="padding-12 center-div">
            <div class="stat-value">
              {{stats.value}}
            </div>
            <div class="stat-title">
              {{stats.header}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!info.dataFetched" class="center-content padding-top-25 is-flex color-red height-100">
      No stats found for this user
    </div>
  </div>
</div>
  