var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../../order-service.service';
import { Router } from '@angular/router';
import { HelperService } from '../../helper.service';
var CaptainPropagationBreachesComponent = /** @class */ (function () {
    function CaptainPropagationBreachesComponent(dialog, orderService, router) {
        this.dialog = dialog;
        this.orderService = orderService;
        this.router = router;
        this.toggleHeaders = new EventEmitter();
    }
    CaptainPropagationBreachesComponent.prototype.ngOnInit = function () {
        this.definePropagationMetricTable();
    };
    CaptainPropagationBreachesComponent.prototype.ngOnChanges = function (changes) {
    };
    CaptainPropagationBreachesComponent.prototype.declareTable = function (pageNumber) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        this.propagationMetricTable = new Table({
            tableHeader: {
                toString: function () {
                    return "FM Breach Details";
                }
            },
            headers: {
                date: 'Date',
                orderId: 'OrderId',
                sourcingLocation: 'Sourcing\nLocation',
                sourcingLocationTime: 'Sourcing\nLocation\nTime',
                sourcingSystemTime: 'Sourcing\nSystem\nTime',
                hex: 'Sourcing\nPropagation\nHex',
                propagationLocation: 'Propagation\nLocation',
                propagationLocationTime: 'Propagation\nLocation\nTime',
                propagationSystemTime: 'Propagation\nSystem\nTime',
                threshold: 'FM Threshold',
                fmBreach: 'FM Breach',
            },
            selectedHeader: 9,
            toggleableHeaders: this.captainDetailsToggleableHeaders,
            pagination: new Pagination(pageNumber, 10),
            config: {
                refresh: true,
            },
            onRefresh: function () {
                _this.propagationMetricTable = null;
                _this.definePropagationMetricTable();
            },
            data: []
        });
    };
    CaptainPropagationBreachesComponent.prototype.onPaginationChange = function (event) {
        this.propagationMetricTable.data = [];
        this.fillDataToTable();
    };
    CaptainPropagationBreachesComponent.prototype.changeInHeader = function (header) {
        this.toggleHeaders.emit(header);
    };
    CaptainPropagationBreachesComponent.prototype.definePropagationMetricTable = function () {
        this.declareTable();
        this.fillDataToTable();
    };
    CaptainPropagationBreachesComponent.prototype.getPropagationMetricEventsForFMBreach = function (captainId) {
        return this.orderService.getPropagationMetricEventsForCaptain(captainId, 'first_mile_breached', this.propagationMetricTable.pagination);
    };
    CaptainPropagationBreachesComponent.prototype.fillDataToTable = function () {
        var _this = this;
        this.propagationMetricTable.dataWillLoad();
        function getSourcingLatLng(captainLocation) {
            if (captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.coordinate
                && captainLocation.atSourcing.coordinate.lat && captainLocation.atSourcing.coordinate.lng) {
                return captainLocation.atSourcing.coordinate.lat + '\n' + captainLocation.atSourcing.coordinate.lng;
            }
            return '';
        }
        function getBookingAckLatLng(captainLocation) {
            if (captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.coordinate
                && captainLocation.atBookingAck.coordinate.lat && captainLocation.atBookingAck.coordinate.lng) {
                return captainLocation.atBookingAck.coordinate.lat + '\n' + captainLocation.atBookingAck.coordinate.lng;
            }
            return '';
        }
        function getSourcingLocationUpdateAt(captainLocation) {
            if (captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.updatedAt) {
                var date = new Date(captainLocation.atSourcing.updatedAt);
                return HelperService.dateString(date) + '\n' + date.toLocaleTimeString();
            }
            return '';
        }
        function getSourcingHex(captainLocation) {
            return captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.hex8;
        }
        function getSourcingLocationSystemUpdateAt(captainLocation) {
            if (captainLocation && captainLocation.atSourcing && captainLocation.atSourcing.systemUpdatedAt) {
                var date = new Date(captainLocation.atSourcing.systemUpdatedAt);
                return HelperService.dateString(date) + '\n' + date.toLocaleTimeString();
            }
            return '';
        }
        function getBookingAckLocationUpdateAt(captainLocation) {
            if (captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.updatedAt) {
                var date = new Date(captainLocation.atBookingAck.updatedAt);
                return HelperService.dateString(date) + '\n' + date.toLocaleTimeString();
            }
            return '';
        }
        function getBookingAckLocationSystemUpdateAt(captainLocation) {
            if (captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.systemUpdatedAt) {
                var date = new Date(captainLocation.atBookingAck.systemUpdatedAt);
                return HelperService.dateString(date) + '\n' + date.toLocaleTimeString();
            }
            return '';
        }
        function getBookingAckLocationHex(captainLocation) {
            return captainLocation && captainLocation.atBookingAck && captainLocation.atBookingAck.hex8;
        }
        function getFMThreshold(captainThreshold) {
            if (captainThreshold && captainThreshold.firstMileDistanceInMetres) {
                return captainThreshold.firstMileDistanceInMetres.value + '\n' + captainThreshold.firstMileDistanceInMetres.source;
            }
        }
        function getFMBreach(captainFirstMile, captainThreshold) {
            var firstMileThreshold = captainThreshold && captainThreshold.firstMileDistanceInMetres;
            if (captainFirstMile && captainFirstMile.value && firstMileThreshold && firstMileThreshold.value) {
                return (captainFirstMile.value - firstMileThreshold.value) + 'm\n' + captainFirstMile.provider;
            }
        }
        this.getPropagationMetricEventsForFMBreach(this.captainObjectId).subscribe(function (_a) {
            var events = _a.events, count = _a.count;
            return __awaiter(_this, void 0, void 0, function () {
                function createTableData(value, className, hoverData, flag, suspendFlag) {
                    if (flag === void 0) { flag = false; }
                    if (suspendFlag === void 0) { suspendFlag = false; }
                    return new TableData({
                        data: value,
                        hoverData: hoverData ? hoverData : null,
                        type: TableDataTypes.DATA,
                        className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
                        onClick: function (data) {
                            var newRelativeUrl = self.router.createUrlTree(["/order/" + data.orderId]);
                            var baseUrl = window.location.href.replace(self.router.url, '');
                            window.open(baseUrl + newRelativeUrl, '_blank');
                        }
                    });
                }
                var self;
                var _this = this;
                return __generator(this, function (_b) {
                    self = this;
                    this.propagationEvents = events.map(function (event) {
                        var orderId = event.orderId;
                        var sourcingLocation = getSourcingLatLng(event.captainLocation);
                        var bookingAckLocation = getBookingAckLatLng(event.captainLocation);
                        var sourcingLocationUpdatedAt = getSourcingLocationUpdateAt(event.captainLocation);
                        var sourcingLocationSystemUpdatedAt = getSourcingLocationSystemUpdateAt(event.captainLocation);
                        var bookingAckLocationUpdatedAt = getBookingAckLocationUpdateAt(event.captainLocation);
                        var bookingAckLocationSystemUpdatedAt = getBookingAckLocationSystemUpdateAt(event.captainLocation);
                        var sourcingHex = getSourcingHex(event.captainLocation);
                        var bookingAckHex = getBookingAckLocationHex(event.captainLocation);
                        var fmThreshold = getFMThreshold(event.captainThreshold);
                        var fmBreach = getFMBreach(event.firstMileDistanceInMetres, event.captainThreshold);
                        var self = _this;
                        return {
                            date: createTableData(event.orderDate),
                            orderId: createTableData(orderId),
                            sourcingLocation: createTableData(sourcingLocation),
                            sourcingLocationTime: createTableData(sourcingLocationUpdatedAt),
                            sourcingSystemTime: createTableData(sourcingLocationSystemUpdatedAt),
                            hex: createTableData(sourcingHex + '\n ' + bookingAckHex),
                            propagationLocation: createTableData(bookingAckLocation),
                            propagationLocationTime: createTableData(bookingAckLocationUpdatedAt),
                            propagationSystemTime: createTableData(bookingAckLocationSystemUpdatedAt),
                            threshold: createTableData(fmThreshold),
                            fmBreach: createTableData(fmBreach),
                        };
                    });
                    this.propagationMetricTable.data = this.propagationEvents;
                    this.propagationMetricTable.pagination.count = count;
                    return [2 /*return*/];
                });
            });
        });
    };
    return CaptainPropagationBreachesComponent;
}());
export { CaptainPropagationBreachesComponent };
