var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from '@angular/core';
import { HorizontalList } from '../../shared/horizontal-list/horizontal-list.component';
import { TableData, TableDataTypes } from '../../shared/types';
import { PerformanceCard } from '../../shared/types/card.types';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CaptainService } from 'src/app/captain.service';
var SingleViewOfCaptainComponent = /** @class */ (function () {
    function SingleViewOfCaptainComponent(httpClient, captainService) {
        this.httpClient = httpClient;
        this.captainService = captainService;
        this.captainLevelDisplayName = new EventEmitter();
        this.data = {};
        this.captainLevelData = {};
        this.levelsMap = {};
        this.validServicesToShowDapr = ['auto', 'link', 'bike', 'app'];
        this.httpClient = httpClient;
        // this.processData(this.data);
    }
    SingleViewOfCaptainComponent.prototype.getProcessedWeeklyData = function (captainLevelData, levelsData) {
        return {
            averageRating: {
                displayName: 'Average Rating',
                value: captainLevelData && captainLevelData.calendarWeekRealTime && captainLevelData.calendarWeekRealTime.avgRating || 'N/A',
                targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.averageRating || {})
            },
            weeklyOrders: {
                displayName: 'Weekly Orders',
                value: captainLevelData && captainLevelData.calendarWeekRealTime && captainLevelData.calendarWeekRealTime.netRides || 'N/A',
                targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.weeklyOrders)
            },
            apr: {
                displayName: 'Acceptance Rate',
                value: captainLevelData
                    && captainLevelData.calendarWeekRealTime && captainLevelData.calendarWeekRealTime.apr || 'N/A',
                targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.apr || {})
            },
            ltr: {
                displayName: 'Life Time Rides',
                value: captainLevelData && captainLevelData.ltr || 'N/A',
                targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.ltr || {})
            },
            coins: {
                displayName: 'Coins For Level Movement',
                value: '',
                targets: this.getRepresentableTooltipFromTargets(levelsData && levelsData.targets && levelsData.targets.coins || {})
            }
        };
    };
    SingleViewOfCaptainComponent.prototype.getRepresentableTooltipFromTargets = function (targets) {
        if (!targets || Object.keys(targets).length === 0) {
            return '';
        }
        var tooltipValue = '';
        var keys = Object.keys(targets);
        for (var i = 0; i < keys.length; i++) {
            tooltipValue += keys[i] + " : " + targets[keys[i]];
            if (i < (keys.length - 1)) {
                tooltipValue += '\n';
            }
        }
        return tooltipValue;
    };
    SingleViewOfCaptainComponent.prototype.getCaptainLevelsData = function (captainId) {
        var url = environment.server + "/api/svo/captain/level/" + captainId;
        return this.httpClient.get(url).map(function (res) {
            return res;
        });
    };
    SingleViewOfCaptainComponent.prototype.getLevelTargetsData = function (captainId) {
        var url = environment.server + "/api/b2b/getCaptainLevelConfigurations/" + captainId;
        return this.httpClient.get(url).map(function (res) {
            return res;
        });
    };
    SingleViewOfCaptainComponent.prototype.getDAPRForLast20AcceptedPings = function (riderId, serviceDetailArray) {
        var serviceDetailIdArray = serviceDetailArray.length === 0 ? [] : serviceDetailArray.join(",");
        var url = environment.server + "/api/svo/captain/getDaprLast20Pings/" + riderId + "?serviceDetailIds=" + serviceDetailIdArray;
        return this.httpClient.get(url).map(function (res) {
            return res;
        });
    };
    SingleViewOfCaptainComponent.prototype.processLevelsData = function (levelsData) {
        var processedLevelsData = {
            currentLevel: levelsData && levelsData.currentLevel || 'N/A',
            currentLevelDisplayName: '',
            targets: {}
        };
        if (levelsData && levelsData.details) {
            processedLevelsData.targets = this.processTargetLevelsData(levelsData.details);
            if (Object.keys(this.levelsMap).length > 0 && processedLevelsData.currentLevel !== 'N/A') {
                processedLevelsData.currentLevelDisplayName = this.levelsMap[processedLevelsData.currentLevel];
            }
        }
        return processedLevelsData;
    };
    SingleViewOfCaptainComponent.prototype.getRepresentableValueFromTargetValue = function (value) {
        if (!value || !value.value) {
            return 'N/A';
        }
        if (value.valueType === 'absolute') {
            return (value.comparisonFactor || '') + " " + value.value;
        }
        if (value.valueType === 'percentage') {
            return (value.comparisonFactor || '') + " " + value.value + "%";
        }
        return 'N/A';
    };
    SingleViewOfCaptainComponent.prototype.processTargetLevelsData = function (levels) {
        var targets = {};
        var coinsMap = {};
        for (var _i = 0, levels_1 = levels; _i < levels_1.length; _i++) {
            var level = levels_1[_i];
            this.levelsMap[level.levelNumber] = level.levelDetails.displayName;
            coinsMap[level.levelDetails.displayName] = level.coins;
            // tslint:disable-next-line:forin
            for (var target in level.targets) {
                if (!targets[target]) {
                    targets[target] = {};
                }
                targets[target][level.levelDetails.displayName] =
                    this.getRepresentableValueFromTargetValue(level.targets[target]);
            }
        }
        targets['coins'] = coinsMap;
        return targets;
    };
    SingleViewOfCaptainComponent.prototype.filterServiceDetailIds = function (serviceDetails, validServicesToShowDapr) {
        var serviceDetailIdArray = [];
        serviceDetails && serviceDetails['services'].forEach(function (service) {
            if (validServicesToShowDapr.includes(service['serviceName'].toLowerCase())) {
                serviceDetailIdArray.push(service['serviceDetailId']);
            }
        });
        return serviceDetailIdArray;
    };
    SingleViewOfCaptainComponent.prototype.ngOnInit = function () {
        this.dataLoaded = false;
        if (this.horizontalList && this.horizontalList.table) {
            this.makeTableNull();
        }
        this.defineList(this.riderId);
    };
    SingleViewOfCaptainComponent.prototype.makeTableNull = function () {
        this.horizontalList.table = null;
        this.horizontalList = null;
    };
    SingleViewOfCaptainComponent.prototype.defineList = function (captainId) {
        var _this = this;
        this.getCaptainLevelsData(captainId).subscribe(function (captainLevelsData) {
            _this.captainLevelData = captainLevelsData.data;
            _this.getLevelTargetsData(captainId).subscribe(function (captainLevelTargets) {
                if (captainLevelTargets && captainLevelTargets.data && captainLevelTargets.data.code === 200) {
                    _this.data = captainLevelTargets.data.data;
                    _this.dataLoaded = true;
                    var DAPR_1 = "N/A";
                    var DAPRTooltip_1 = "";
                    var levelsData_1 = _this.processLevelsData(_this.data);
                    _this.captainService.getUserServices(_this.riderId, false).subscribe(function (serviceDetailIds) {
                        var serviceDetailArray = _this.filterServiceDetailIds(serviceDetailIds, _this.validServicesToShowDapr);
                        if (serviceDetailArray.length <= 0) {
                            DAPR_1 = "N/A";
                            _this.mapData(levelsData_1, DAPR_1, DAPRTooltip_1);
                        }
                        else {
                            _this.getDAPRForLast20AcceptedPings(_this.riderId, serviceDetailArray).subscribe(function (result) {
                                if (result && result.data && result.data.found == true && result.data.daprByService) {
                                    var acceptedPings = result.data.daprByService[serviceDetailArray[0]] ? result.data.daprByService[serviceDetailArray[0]].acceptedPings : 0;
                                    var dapr = result.data.daprByService[serviceDetailArray[0]].dapr ? (result.data.daprByService[serviceDetailArray[0]].dapr) * 100 : 0;
                                    if (acceptedPings >= 20) {
                                        DAPR_1 = dapr + '%';
                                        DAPRTooltip_1 = "basis last 20 accepted pings";
                                    }
                                    else {
                                        DAPR_1 = "N/A";
                                        DAPRTooltip_1 = "Accepted pings less than 20";
                                    }
                                }
                                _this.mapData(levelsData_1, DAPR_1, DAPRTooltip_1);
                            });
                        }
                    });
                }
            });
        });
    };
    SingleViewOfCaptainComponent.prototype.mapData = function (levelsData, DAPR, DAPRTooltip) {
        var _this = this;
        var weeklyData = this.getProcessedWeeklyData(this.captainLevelData, levelsData);
        if (levelsData && levelsData.currentLevelDisplayName) {
            this.captainLevelDisplayName.emit(levelsData.currentLevelDisplayName);
        }
        var commonAttrs = {
            popover: true,
            popoverIcon: 'info',
            style: {
                'color': 'black',
                'font-size': '26px !imporatant'
            }
        };
        var data = [
            {
                label: weeklyData.weeklyOrders.displayName,
                value: __assign({ toString: function () {
                        return "" + weeklyData.weeklyOrders.value;
                    } }, commonAttrs, { popoverIconTooltip: weeklyData.weeklyOrders.targets }),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: weeklyData.ltr.displayName,
                value: __assign({ toString: function () {
                        return "" + weeklyData.ltr.value;
                    } }, commonAttrs, { popoverIconTooltip: weeklyData.ltr.targets }),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: weeklyData.averageRating.displayName,
                value: __assign({ toString: function () {
                        return "" + weeklyData.averageRating.value;
                    } }, commonAttrs, { popoverIconTooltip: weeklyData.averageRating.targets }),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: weeklyData.apr.displayName,
                value: __assign({ toString: function () {
                        return weeklyData.apr.value + " %";
                    } }, commonAttrs, { popoverIconTooltip: weeklyData.apr.targets }),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: 'DAPR',
                value: __assign({ toString: function () {
                        return DAPR;
                    } }, commonAttrs, { popoverIconTooltip: DAPRTooltip }),
                type: TableDataTypes.PERFORMANCE_CARD
            },
            {
                label: weeklyData.coins.displayName,
                value: __assign({ toString: function () {
                        return "N";
                    } }, commonAttrs, { popoverIconTooltip: weeklyData.coins.targets }),
                type: TableDataTypes.PERFORMANCE_CARD
            }
        ];
        var dataTable = [data, []];
        var tds = dataTable.map(function (records) {
            return records.map(function (record) {
                return new TableData({
                    type: record.type,
                    performanceCard: new PerformanceCard({
                        label: record.label,
                        value: record.value,
                        style: {
                            height: '127px',
                            width: '176px'
                        }
                    })
                });
            });
        });
        this.horizontalList = new HorizontalList({
            header: 'Captain Levels Data',
            tableData: tds,
            config: {
                refresh: true,
                filter: false,
            },
            events: {
                onRefresh: function () {
                    _this.ngOnInit();
                }
            }
        });
    };
    return SingleViewOfCaptainComponent;
}());
export { SingleViewOfCaptainComponent };
