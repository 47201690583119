import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ENTITY_TYPES, OrderService } from 'src/app/order-service.service';
import { Table, Pagination, TableData, TableDataTypes } from 'src/app/shared/types';
import { CustomerOrderDetailsService } from './customer-order-details.service';
import { FilterService, DateFilterInput, TextFilterInput,
  SearchableDropDownFilterInput, RangeSliderInput, SingleSearchableDropdown, QuickFilters} from 'src/app/filter.service';
import { HelperService } from 'src/app/helper.service';
import { EntityService } from 'src/app/entity.service';
import { DataService } from '../../data.service';
import { FraudService } from '../../fraud-component/fraud-view.service';
import { Router } from '@angular/router';
const currentDate = new Date();
const startDate = new Date();
startDate.setDate(startDate.getDate() - 1);
export const DEFAULT_DATE = {
  FROM_DATE: new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)),
  TO_DATE: currentDate
};

function defaultFiters() {
  let startDuration = HelperService.getDateOnlyV1(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() - 31)));
  let endDuration = HelperService.getDateOnlyV1(currentDate);
  return {
    startDuration,
    endDuration,
    cities: [],
    services: [],
    status: 'all'
  };
}

@Component({
  selector: 'app-customer-order-details',
  templateUrl: './customer-order-details.component.html',
  styleUrls: ['./customer-order-details.component.css']
})
export class CustomerOrderDetailsComponent implements OnInit, OnChanges {
  @Input() customerMobile: string;
  @Input() customerId: string;
  @Input() customerDetailsToggleableHeaders: string[];
  @Output() lastOrderDate: EventEmitter<any> = new EventEmitter();
  @Output() toggleHeaders: EventEmitter<any> = new EventEmitter();
  orderTable: Table;
  currentNumber: any;
  fromDate = DEFAULT_DATE.FROM_DATE;
  toDate = DEFAULT_DATE.TO_DATE;
  filtersApplied = defaultFiters();
  cities: any = [];
  services: any = [];
  sendInfo: any;
  orders: any[];
  fraudOrdersList: any;
  statusList: any = [
    'all',
    'new',
    'customerCancelled',
    'onTheWay',
    'arrived',
    'started',
    'dropped',
    'expired',
    'aborted'
  ];
  filterCount: any;
  constructor(
    public orderService: OrderService,
    public filterService: FilterService,
    public entityService: EntityService,
    private customerOrderDetailsService: CustomerOrderDetailsService,
    private dataService: DataService,
    private router: Router,
    private fraudService: FraudService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customerMobile) {
      this.customerMobile = changes.customerMobile.currentValue;
      if (this.customerMobile) {
        this.defineOrderTable(this.customerMobile, DEFAULT_DATE.FROM_DATE, DEFAULT_DATE.TO_DATE, this.filtersApplied);
      }
    }
  }

  ngOnInit() {
    this.defineOrderTable(this.customerMobile);
    this.entityService.getCities().subscribe(cities => {
      this.cities.push(...cities);
    });
  }

  changeInHeader(header) {
    this.toggleHeaders.emit(header);
  }

  private declareTable(pageNumber = 0) {

    this.dataService.searchUserCount.subscribe(result => {
      if (!this.currentNumber || (this.currentNumber === result)) {
        this.filterService.appliedFilters = this.filtersApplied;
      } else {
        this.filterService.appliedFilters = {};
        this.filtersApplied = defaultFiters();
      }
      this.currentNumber = result;
    });
    this.orderTable = new Table({
      // tableHeader: 'Order Details',
      toggleableHeaders: this.customerDetailsToggleableHeaders,
      selectedHeader: 0,
      headers: {
        dateTime: 'Date \n Time',
        orderIdCity: 'Order Id \n City',
        pickupDrop: 'Pickup \n Drop',
        captainName: 'Captain Name',
        offerTypeCode: 'Offer Type \n Offer Code',
        discountCashback: 'Discount \n Cashback',
        fare: 'Fare',
        adjustment: 'Total Adjustment',
        status: 'Status',
        ratings: 'Ratings'
      },
      pagination: new Pagination(pageNumber, 10),
      config: {
        refresh: true,
      },
      onRefresh: () => {
        this.orderTable = null;
        this.ngOnInit();
      },
      appliedFilters: this.filtersApplied,
      filterComponents: [
        new QuickFilters({
          key: 'duration',
          default: '',
          title: 'Duration',
        }),
        new SearchableDropDownFilterInput({
          key : 'cities',
          title: 'Cities',
          values: this.cities,
          onChange: (value) => {
            this.entityService.getServices(value).subscribe(services => {
              this.services.splice(0);
              this.services.push(...services);
            });
          }
        }),
        new SearchableDropDownFilterInput({
          key : 'services',
          title: 'Services',
          values: this.services,
        }),
        new SingleSearchableDropdown({
          key : 'status',
          title: 'Status',
          values: this.statusList,
        }),
      ],
      onFilter: () => {
        const timeSlot = this.filterService.appliedFilters.duration || ['', ''];
        let duration = [];
        if (!Array.isArray(timeSlot)) {
          duration.push(timeSlot['start']);
          duration.push(timeSlot['end']);
        } else {
          duration = timeSlot;
        }
        let startDuration = duration[0];
        let endDuration = duration[1];
        const cities = this.filterService.appliedFilters.cities;
        const services = this.filterService.appliedFilters.services;
        const status = this.filterService.appliedFilters.status;
        const filtersApplied = {
          duration: {
            start: startDuration,
            end: endDuration,
          },
          startDuration,
          endDuration,
          cities,
          services,
          status,
        };
        Object.assign(this.filtersApplied, filtersApplied);

        const isValidNumber = (value) => {
          return ((parseInt(value, 10) === 0) || value);
        };
        if ((startDuration && endDuration) || cities || services) {
          startDuration = HelperService.isValidDate(new Date(startDuration)) ? new Date(startDuration) : undefined;
          endDuration = HelperService.isValidDate(new Date(endDuration)) ? new Date(endDuration) : undefined;
          return this.defineOrderTable(this.customerMobile, startDuration, endDuration, this.filtersApplied);
        }
        return this.defineOrderTable(this.customerMobile, undefined, undefined, this.filtersApplied);
      },
      data: []
    });
  }

  onPaginationChange(event) {
    this.fillDataToTable(undefined, undefined, this.filtersApplied);
  }


  getOrderList(customerMobile: string, fromDate?: Date, toDate?: Date, filters?: any) {
    this.filterCount = 0;
    const filterKeys = Object.keys(this.filtersApplied);
    const ignoreKeys = ['startDuration', 'endDuration'];
    const dateFilter = [];
    if (this.filtersApplied && this.filtersApplied['duration']) {
      if (this.filtersApplied['duration'].start) {
        dateFilter.push(this.filtersApplied['duration'].start);
      }
      if (this.filtersApplied['duration'].end) {
        dateFilter.push(this.filtersApplied['duration'].end);
      }
    }
    if (dateFilter.length > 0) {
      this.filterCount++;
    }
    for (let i = 0; i < filterKeys.length ; i++) {
      if (Array.isArray(this.filtersApplied[filterKeys[i]]) && (this.filtersApplied[filterKeys[i]]).length > 0) {
        this.filterCount++;
      } else {
        if (this.filtersApplied[filterKeys[i]] && ignoreKeys.indexOf(filterKeys[i]) === -1
            && (this.filtersApplied[filterKeys[i]]).length > 0) {
          this.filterCount++;
        }
      }
    }
    return this.orderService.getOrdersForUser(this.customerId, this.orderTable.pagination, fromDate, toDate, ENTITY_TYPES.CUSTOMER, filters);
  }

  private calculateAdjustmentsByOrderId(adjustments) {
    const STRING = {
      CREDIT: 'credit',
      ADJUSTMENTS: 'adjustment',
      DEBIT: 'debit'
    };
    const adjustmentsHistory = {};
    const totalAdjustment = {};
    function toBeAdded(adjustment) {
      return adjustment.transactionType === STRING.CREDIT ? true : false;
    }

     function isProperTransactionType(adjustment) {
      return adjustment.title === STRING.ADJUSTMENTS &&
      (adjustment.transactionType === STRING.CREDIT || adjustment.transactionType === STRING.DEBIT);
    }
    adjustments.forEach(adjustment => {
      if (!adjustment) {
        return;
      }
      if (isProperTransactionType(adjustment)) {
        totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] || 0;
        if (toBeAdded(adjustment)) {
          totalAdjustment[adjustment.orderId] += adjustment.originalAmount;
        } else {
          totalAdjustment[adjustment.orderId] = totalAdjustment[adjustment.orderId] - adjustment.originalAmount;
        }
      }
    });
    for (const adjustment in totalAdjustment) {
      if (!this.isEmpty(totalAdjustment[adjustment])) {
        totalAdjustment[adjustment] = totalAdjustment[adjustment].toString();
      }
    }
    return totalAdjustment;
  }

  fillDataToTable(fromDate?: Date, toDate?: Date, filters?: any) {
    if (this.customerMobile) {
      this.orderTable.dataWillLoad();
      this.getOrderList(this.customerMobile, fromDate, toDate, filters).subscribe(async({orders, count}) => {
        const orderIds = [];
        this.sendInfo = {lastOrderDate: (orders && orders[0] && orders[0].date) ? HelperService.dateString(orders[0].date) : ' - '};
        this.lastOrderDate.emit(this.sendInfo);
        const self = this;
        const adjustmentsHistory = {};
        function createTableData(value, className?, type?, flag = false, hoverData?) {
          return new TableData({
            data: value,
            type: type ? type : TableDataTypes.DATA,
            flag: flag,
            hoverData: hoverData ? hoverData : null,
            className: className ? className + ' cursor-pointer' : 'cursor-pointer whiteSpacePre',
            onClick: (data) => {
              const newRelativeUrl = self.router.createUrlTree([`/order/${data._id.data}`]);
              const baseUrl = window.location.href.replace(self.router.url, '');
              window.open(baseUrl + newRelativeUrl, '_blank');
            }
          });
        }

        const orderIdss = orders.map(o => o._id);
        const adjustments = await this.customerOrderDetailsService.getAdjustmentAmounts(orderIdss, this.customerId).toPromise();
        const orderIdToAdjustmentsMap = this.calculateAdjustmentsByOrderId(adjustments);

        this.fraudOrdersList = await this.fraudService.checkFraudOrders({ orders: orderIdss }).toPromise();

        this.orders = orders.map((order, i) => {
          const orderId = order._id;
          orderIds.push(orderId);
          const self = this;
          const orderDate = new Date(order.date);
          const couponStatus = order.couponCode && order.couponApplied;
          const offerStatus = order.offerCode && order.offerApplied;
          const flag = this.fraudOrdersList.orders[orderId] ? true : false;
          return {
            _id: createTableData(orderId),
            dateTime: createTableData(HelperService.dateString(orderDate) + '\n' + order.orderTime, null, null, flag),
            orderIdCity: new TableData({
              data: order.orderId + '\n' + order.city,
              type: TableDataTypes.DATA,
              className: 'cursor-pointer whiteSpacePre',
              onClick: (data) => {
                const newRelativeUrl = self.router.createUrlTree([`/order/${data._id.data}`]);
                const baseUrl = window.location.href.replace(self.router.url, '');
                window.open(baseUrl + newRelativeUrl, '_blank');
              }
            }),
            pickupDrop: createTableData(order.pickup + '\n' + order.drop),
            offerTypeCode: createTableData((order.offerType ? order.offerType + '\n' : '') +
              (order.offerCode ? (order.offerCode) : (order.couponCode ? (order.couponCode) : '')), offerStatus || couponStatus ? 'status-green whiteSpacePre' : 'status-red whiteSpacePre'),
            discountCashback: createTableData(order.discount + '\n' + order.cashback, 'preserve-whitespace'),
            captainName: createTableData(order.riderName),
            fare: createTableData(order.earnings),
            ratings: createTableData(order.riderRating),
            adjustment: createTableData(orderIdToAdjustmentsMap[orderId]),
            status: createTableData(order.status, 'capitalize whiteSpacePre', null, false, order.cancelReason || null)
          };
        });
        this.orderTable.data = this.orders;
        this.orderTable.filterCount = this.filterCount;
        this.orderTable.pagination.count = count;
      });
      if (!this.sendInfo) {
        this.lastOrderDate.emit('empty');
      }

    }
  }

  calculateAdjustment (adjustments) {
    if (adjustments && adjustments.length > 0) {
      let finalAdjustment = 0;
      for (const adj in adjustments) {
        if (adjustments[adj]) {
          switch (adjustments[adj].type) {
            case 'credit' : finalAdjustment += adjustments[adj].amount;
                            break;
            case 'debit' : finalAdjustment -= adjustments[adj].amount;
                            break;
            default : break;
          }
        }
      }
      return (finalAdjustment > 0 ? '+' + finalAdjustment.toString() : finalAdjustment.toString());
    }
  }

  isEmpty(value) {
    return (value == null || value.length === 0);
  }

  defineOrderTable(customerMobile: string, fromDate = DEFAULT_DATE.FROM_DATE, toDate = DEFAULT_DATE.TO_DATE,
    filters = this.filtersApplied) {
    this.declareTable();
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fillDataToTable(fromDate, toDate, filters);
  }
}
