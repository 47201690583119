const DEFAULT = Object.freeze({
  pageNo: 1,
  perPage: 10,
  count: 10,
  pageSizeOptions: [10, 30, 50, 70, 90, 100],
  previousPageIndex: 0
});
export class Pagination {
  constructor(
    public pageNo: number = DEFAULT.pageNo,
    public perPage: number = DEFAULT.perPage,
    public count: number = DEFAULT.count,
    public pageSizeOptions = DEFAULT.pageSizeOptions,
    public previousPageIndex = DEFAULT.previousPageIndex,
    public enable = true,
    ) {

  }
}
