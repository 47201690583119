import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SigninService } from './signin/signin.service';
import { ToasterType, Toaster } from './shared/types';
import { ToasterService } from './toaster.service';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
const helper = new JwtHelperService();


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = null;
  private _token = null;
  private decodedToken = null;
  public redirectUrl = null;
  setToken(token) {
    if (!token) {
      this._token = null;
      this.decodedToken = null;
      this.user = null;
      HelperService.setCookie({
        token: '',
        user: '',
      });
      return;
    }
    this._token = token;
    this.decodedToken = this.getDecodedToken();
    if (!this.user) {
      this.setUserInfo();
    }
  }

  get isUserLoggedIn() {
    return this.token ? true : false;
  }

  setUserInfo() {
    if (!this.decodedToken) {
      return of();
    }
    return this.signInService.getUserInfo(this.decodedToken.email)
    .map((userDetails: any) => {
      userDetails = userDetails && userDetails.data;
      this.setUserDetails(userDetails);
      return userDetails;
    });
  }

  get token() {
    return this._token;
  }

  onRouteTokenUpdate() {
    const onSuccessfullSignIn = (roles) => {
      const cookieRoles = [], accessRoles = ['refund_access', 'debit_access', 'distance_update_access', 'customer_deactivate_access', 'revoke_suspension', 'revoke_penalty', 'revoke_blacklist', 'manager_profile_view', 'manager_search_role_edit', 'allow_p0_suspension', 'revoke_p0_suspension', 'remove_spd_fraud'];
      this.signInService.setCookies({roles: roles}).subscribe(res => {
        if (res['data'] && res['data'].data) {
          for ( const index in accessRoles) {
            if (accessRoles[index] && res['data'].data.indexOf(accessRoles[index]) !== -1) {
              cookieRoles.push(accessRoles[index]);
            }
          }
        }
        if (cookieRoles.length > 0) {
          document.cookie = 'accessRoles=' + cookieRoles;
        }
        this.dataService.updatePermissions(res['data']);
      });

    };

    this.signInService.getUserInfo().subscribe((result: any) => {
      let response = result || {};
      response = response.data;
      this.setUserDetails(response);
      if (response && response._id) {
        const roles = response.roles;
        document.cookie = 'Roles=' + roles;
        HelperService.setCookie({
          name: response.email,
          user: {
            _id: response._id,
            firstName: response.firstName,
            emailId: response.email,
          },
        });
        onSuccessfullSignIn(roles);
      } else {
        this._toastMessage.showToaster(new Toaster({
          type: ToasterType.WARNING,
          message: 'User not found! Please contact support.',
        }));
      }
    },
    err => {
      this._toastMessage.showToaster(new Toaster({
        type: ToasterType.WARNING,
        message: err.error.message,
      }));
      this.logOut();

    });
  }

  constructor(
    private signInService: SigninService,
    private _toastMessage: ToasterService,
    private dataService: DataService,
    private router: Router,
  ) { }


  public isAuthenticated(): boolean {
    let routeToken = HelperService.routeParams().token || '';
    let existingToken = HelperService.accessCookie('token') || '';
    routeToken = routeToken.split('?')[0];
    existingToken = existingToken.split('?')[0];
    const token = routeToken || existingToken;
    // if (routeToken) {
      this.onRouteTokenUpdate();
    // }
    this.setTokenToCookie(token);
    if (!token) {
      return false;
    }
    if (routeToken) {
      const url = HelperService.removeParam(['token']);
      HelperService.redirect(url, false);
    }
    this.setToken(token);
    return true;
  }

  public clearUserCred() {
    this.setToken(null);
  }

  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
}

  logOut() {
    this.signInService.logoutGoogle();
    this.clearUserCred();
    this.deleteAllCookies();
    setTimeout(() => {
      HelperService.redirect(environment.oauth);
    });
  }

  public getUserDetails() {
    if (this.user) {
      return of(this.user);
    }
    return this.setUserInfo();

  }

  private getDecodedToken() {
    if (!this.token) {
      return false;
    }
    const decodedToken = helper.decodeToken(this.token);
    return decodedToken;
  }

  public setUserDetails(user) {
    this.user = user;
  }

  public getToken() {
    return HelperService.accessCookie('token');
  }

  public clearToken() {
    this.setToken(null);
  }

  private setTokenToCookie(token) {
    HelperService.setCookie({
      token,
    });
    // document.cookie = `token=${token}`;
  }
}
