import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MerchantService } from '../merchant.service';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
@Component({
  selector: 'app-merchant-general-info',
  templateUrl: './merchant-general-info.component.html',
  styleUrls: ['./merchant-general-info.component.css']
})
export class MerchantGeneralInfoComponent implements OnInit, OnChanges {
  @Input() data;
  merchantId;
  info = {};
  constructor(
    private merchantService: MerchantService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.getGeneralInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getGeneralInfo();
  }

  getGeneralInfo() {
    if (this.data) {
      this.merchantId = this.data.userId;
      this.info = {
        header: 'General Info',
        buttons: ['App Logout'],
        buttonClass: 'merchant-button-class',
        dataFetched: true,
        data: [
          {header: this.data.ownerName, value: this.data.mobile || '',
            iconPath: '../../assets/svgImages/user.svg', iconClass: 'merchant-icons'},
          {header: 'Email ID', value: this.data.email || '',
            iconPath: '../../assets/svgImages/mail.svg', iconClass: 'merchant-icons'},
          {header: 'Business Name', value: this.data.name || '',
            iconPath: '../../assets/svgImages/briefcase.svg', iconClass: 'merchant-icons'},
          {header: 'GST Number', value: this.data.gst && this.data.gst.gstNumber || '',
            iconPath: '../../assets/svgImages/file-minus.svg', iconClass: 'merchant-icons'},
          {header: 'Location Pin', value: this.data.location ? this.data.location.lat + ', ' + this.data.location.lng : '',
            iconPath: '../../assets/svgImages/location-pin.svg', iconClass: 'merchant-icons'},
          {header: 'Store Address', value: this.data.location && this.data.location.address || '',
            iconPath: '../../assets/svgImages/map.svg', iconClass: 'merchant-icons'}
        ]
      };
    } else {
      this.info = {
        header: 'General Info',
        dataFetched: false,
        data: []
      };
    }
  }

  eventTriggered(value) {
    if (value) {
      if (this.merchantId) {
        this.merchantService.logoutMerchant(this.merchantId).subscribe(result => {
          if (result && result['data'] && result['data']['info'] && result['data']['info']['status'] === 'success') {
            this.toasterService.showToaster(new Toaster({
              type: ToasterType.SUCCESS,
              message: `Merchant logged out successfully`,
            }));
          }
        }, error => {
          this.toasterService.showToaster(new Toaster({
            type: ToasterType.WARNING,
            message: `Unable to logout merchant. Please try again.`,
          }));
        });
      }
    }
  }
}
