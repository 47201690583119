<mat-accordion *ngIf="incentives && incentives.length"  [multi]="true">
  <mat-expansion-panel *ngFor="let incentive of incentives; let i = index" class="mat-elevation-z0">
    <mat-expansion-panel-header class="weeklyIncentiveHeader">
      <mat-panel-title>
        <b>Weekly Incentive - {{i + 1}}</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
     <div class="row margin-top-16 panel-body">
      <div class="margin-right-60">
        <label>Name</label>
        <p class="value-lighter-color">{{incentive.name}}</p>
      </div>
      <div class="margin-right-60">
        <label>Type</label>
        <p class="value-lighter-color">{{incentive.type}}</p>
      </div>
      <div class="margin-right-60">
         <label>Service</label>
         <p class="value-lighter-color">{{incentive.services.join(', ')}}</p>
      </div>
      <div class="margin-right-60">
        <label>City</label>
        <p class="value-lighter-color">{{incentive.cities.join(', ')}}</p>
      </div>
      <div class="margin-right-60">
        <label>Start Date</label>
        <p class="value-lighter-color">{{incentive.startDate}}</p>
      </div>
      <div class="margin-right-60">
        <label>End Date</label>
        <p class="value-lighter-color">{{incentive.endDate}}</p>
      </div>
       <div>
         <label>T&C</label>
         <p (click)="openTncDialog()" class="web-link">View T&C</p>
          <ng-template #tnc let-modal>
            <div class="modal-header">
              Terms & Conditions
            </div>
            <div class="modal-body">
              <ol>
                <li *ngFor="let tnc of incentive.tncs">
                  {{tnc}}
                </li>
              </ol>
            </div>
          </ng-template>
       </div>
     </div>
     <div class="line"></div>
     <div *ngFor="let slots of incentive.incentiveCards">
       <div class="row">
         <div class="col-1 daysOfTheWeek"> {{getDaysOfTheWeek(slots.field)}} </div>
         <div class="col padding-30">
           <div class="incentive-font">
             {{formatTime(slots.timeSlot)}}
           </div>
           <div [class.progressBars]="i !== (slots.rules.length - 1)" *ngFor="let progress of slots.rules; let i = index">
             <div class="row progress-section">
               <div class="row orders" *ngIf="progress.order">
                 <div class="incentive-font width-90">
                   {{progress.order}} Orders
                 </div>
                 <div [ngClass]="{'width-259' : progress.distance, 'width-680': !progress.distance}">
                   <app-linear-progress [max]="progress.order" [min]="0" [completed]="(progress.completedOrders) |  number:'1.0-2'" [progressStatus]="progress.status"></app-linear-progress>
                 </div>
               </div>
               <div class="selective-condition incentive-font width-40" *ngIf="progress.order && progress.distance"> {{incentive.selectedCondition === "&&" ? "(AND)" : "(OR)"}} </div>
               <div class="row distance" *ngIf="progress.distance">
                 <div class="incentive-font width-90">
                   {{progress.distance}} kms
                 </div>
                 <div [ngClass]="{'width-259' : progress.order, 'width-680': !progress.order}">
                   <app-linear-progress [max]="progress.distance" [min]="0" [completed]="(progress.completedDistance) |  number:'1.0-2'" [progressStatus]="progress.status"></app-linear-progress>
                 </div>
               </div>
               <div class="incentive-font money width-90">
                 ₹ {{progress.amount}}
               </div>
               <div class="status width-100" [ngClass]="getClass(incentive, progress.status, slots)">
                 <div class="circle" [ngClass]="getCircleClass(incentive, progress.status, slots)"></div>
                 {{getStatus(incentive, progress.status, slots)}}
               </div>
               <button class="view-button" (click)="this.openOrdersDialog(progress.orders)">
                 View orders
               </button>
             </div>
             <div class="row progress-section" *ngIf="progress.qualityData">
              <div class="row orders" *ngIf="progress.qualityData">
                <div class="incentive-font width-90">
                  {{progress.qualityData.value}} % {{progress.qualityData.metric}}
                </div>
                <div [ngClass]="{'width-680': progress.qualityData.value}">
                  <app-linear-progress [max]="progress.qualityData.value" [min]="0" [completed]="(progress.qualityData.currentValue) |  number:'1.0-2'" [progressStatus]="progress.qualityData.qualityStatus"></app-linear-progress>
                </div>
              </div>
              <div class="incentive-font money width-90">
                ₹ {{progress.qualityData.amount}}
              </div>
              <div class="status width-100" [ngClass]="getClass(incentive, progress.qualityData.qualityStatus, slots)">
                <div class="circle" [ngClass]="getCircleClass(incentive, progress.qualityData.qualityStatus, slots)"></div>
                {{getStatus(incentive, progress.qualityData.qualityStatus, slots)}}
              </div>
            </div>
           </div>
         </div>
       </div>
        <div class="line"></div>
     </div>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #orders let-modal>
  <div class="modal-header">
    Orders
  </div>
  <div class="modal-body" *ngIf="orderList && orderList.length">
    <ol>
      <li class="cursor-pointer web-link" *ngFor="let order of orderList">
        <div *ngIf="order.id" (click)="goToOrder(order)">
          {{order.id}}
        </div>
      </li>
    </ol>
  </div>
  <div class="no-incentives" *ngIf="!orderList || !orderList.length">
    No orders found
  </div>
</ng-template>
<div *ngIf="!incentives || !incentives.length" class="no-incentives">
  No incentives found
</div>
