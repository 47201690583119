import {Component, OnInit, Input, OnChanges} from '@angular/core';
import { Ping, PingData } from "src/app/shared/types/svo.types";
import { EntityService } from "src/app/entity.service";
import { RideTrackerMapService } from "src/app/ride-tracker-map/ride-tracker-map.service";
import { SvoService } from "src/app/svo.service";
import { HorizontalList } from "../../shared/horizontal-list/horizontal-list.component";
import { TableData, TableDataTypes } from "../../shared/types";
import { PerformanceCard } from "../../shared/types/card.types";
import { CaptainService } from "src/app/captain.service";

import {
  Campaign,
  HandHoldingCaptain,
} from "src/app/shared/types/captain.types";

const HH_SEGMENT: string = "HH";
const HH_DISPOSITIONS: string[] = ["OUTBOUNDHH"];

@Component({
  selector: "app-hand-holding-details",
  templateUrl: "./hand-holding-details.component.html",
  styleUrls: ["./hand-holding-details.component.css"],
  providers: [
    { provide: "captainIds", useValue: ["5ee36220c044cc3cbc22ed6b"] },
  ],
})
export class HandHoldingDetailsComponent implements OnInit, OnChanges {
  @Input() captainId: string;
  dataLoaded: boolean;
  horizontalList: HorizontalList;
  pingsData: Ping[] = [];
  lastKnownLocation: string;
  lastLogin: Date;
  isHandHoldingCaptain: boolean;
  connectedCallCount: number;
  handholdingCallCount: number;

  constructor(
    private entityService: EntityService,
    private svoService: SvoService,
    private rideTrackerMapService: RideTrackerMapService,
    private captainService: CaptainService
  ) {}

  async ngOnInit() {
    this.dataLoaded = false;
    this.isHandHoldingCaptain = false;
    await this.getCaptainSegment();
    await this.fetchData();
  }

  async ngOnChanges() {
    await this.getCaptainSegment();
    await this.fetchData();
  }

  async getCaptainSegment(): Promise<void> {
    if (this.captainId) {
      try {
        const segmentDetails = await this.svoService.getCaptainSegment(this.captainId);
        this.isHandHoldingCaptain =
          segmentDetails && segmentDetails["segment"] && segmentDetails["segment"].toString().toUpperCase() === HH_SEGMENT;
      } catch (error) {
        this.dataLoaded = true;
      }
    }
  }

  async fetchData(): Promise<void> {
    if (this.captainId && this.isHandHoldingCaptain) {
      const [totalPings, latestLocation, authLog, handholdingDetails] =
        await Promise.all([
          this.svoService
            .getTotalPings([this.captainId])
            .toPromise()
            .catch(() => new PingData()),
          this.rideTrackerMapService
            .getRiderGeoLocation(this.captainId)
            .toPromise()
            .catch(() => ({})),
          this.entityService
            .getLatestAuthLog(this.captainId)
            .toPromise()
            .catch(() => ({})),
          this.captainService
            .getHandHoldingCaptainDetails(this.captainId)
            .toPromise()
            .catch(() => new HandHoldingCaptain()),
        ]);
      this.dataLoaded = true;

      this.pingsData = totalPings.data.response;
      this.lastKnownLocation =
        latestLocation["data"] &&
        latestLocation["data"]["_source"] &&
        latestLocation["data"]["_source"]["existingClusters"]
          ? latestLocation["data"]["_source"]["existingClusters"][0]
          : "N/A";
      this.lastLogin = authLog["loginOn"] ? new Date(authLog["loginOn"]) : null;
      this.connectedCallCount = handholdingDetails.connectedCallCount
        ? handholdingDetails.connectedCallCount
        : 0;
      this.handholdingCallCount = handholdingDetails.campaigns
        ? handholdingDetails.campaigns
            .filter((campaign: Campaign) =>
              HH_DISPOSITIONS.includes(campaign.name)
            )
            .map((campaign: Campaign) => campaign.count)
            .reduce((prevCount, nextCount) => prevCount + nextCount, 0)
        : 0;
      this.makeTable();
    } else {
      this.dataLoaded = true;
    }
  }

  private makeTable() {
    const data = [
      {
        label: "Total Pings Received",
        value: {
          toString: () => {
            return this.pingsData && this.pingsData[0] && this.pingsData[0].totalPings
              ? this.pingsData[0].totalPings
              : "N/A";
          },
        },
        type: TableDataTypes.PERFORMANCE_CARD,
      },
      {
        label: "Last Login Time",
        value: {
          toString: () => {
            return this.lastLogin ? formatTime(this.lastLogin) : "N/A";
          },
        },
        type: TableDataTypes.PERFORMANCE_CARD,
      },
      {
        label: "Last Login Date",
        value: {
          toString: () => {
            return this.lastLogin ? this.lastLogin.toLocaleDateString() : "N/A";
          },
        },
        type: TableDataTypes.PERFORMANCE_CARD,
      },
      {
        label: "Last Known Location",
        value: {
          toString: () => {
            return this.lastKnownLocation ? this.lastKnownLocation : "N/A";
          },
        },
        type: TableDataTypes.PERFORMANCE_CARD,
      },
      {
        label: "Hand Holding Calls",
        value: {
          toString: () => {
            return this.handholdingCallCount;
          },
        },
        type: TableDataTypes.PERFORMANCE_CARD,
      },
      {
        label: "Connected Call Count",
        value: {
          toString: () => {
            return this.connectedCallCount;
          },
        },
        type: TableDataTypes.PERFORMANCE_CARD,
      },
    ];

    const dataTable = [data, []];

    const tds = dataTable.map((records) => {
      return records.map((record) => {
        return new TableData({
          type: record.type,
          performanceCard: new PerformanceCard({
            label: record.label,
            value: record.value,
          }),
        });
      });
    });

    // this.horizontalList.table = null;
    this.horizontalList = new HorizontalList({
      header: "Hand Holding Details",
      tableData: tds,
      config: {
        refresh: true,
        filter: false,
      },
      events: {
        onRefresh: () => {
          this.ngOnInit();
        },
      },
    });
  }
}

const formatTime = (date) => {
  return date.toLocaleTimeString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric"
  });
};
