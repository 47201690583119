var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { SERVICE_GROUPS, NON_P0_SERVICE_GROUPS } from 'src/app/shared/constants';
import { AppConfigService } from 'src/app/appConfig.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
var CaptainSuspensionDailogComponent = /** @class */ (function () {
    function CaptainSuspensionDailogComponent(dialog, appConfigService, dialogRef, data) {
        var _this = this;
        this.dialog = dialog;
        this.appConfigService = appConfigService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.serviceGroups = SERVICE_GROUPS;
        this.nonP0ServiceGroups = NON_P0_SERVICE_GROUPS;
        this.showP0 = false;
        this.showP1 = false;
        this.p0Issues = "p0Issues";
        this.nonP0Issues = "nonP0Issues";
        this.suspendCaptain = function () {
            var data;
            var _a = _this, selectedSuspensionType = _a.selectedSuspensionType, selectedServiceGroup = _a.selectedServiceGroup, suspensionReason = _a.suspensionReason, suspensionOtherReason = _a.suspensionOtherReason, selectedUnit = _a.selectedUnit, enteredPeriod = _a.enteredPeriod, p0SuspensionReason = _a.p0SuspensionReason, selectedSuspensionAction = _a.selectedSuspensionAction, p0SuspensionDuration = _a.p0SuspensionDuration;
            if (selectedSuspensionType == _this.p0Issues) {
                if (!selectedServiceGroup || !p0SuspensionReason || !selectedSuspensionAction) {
                    _this.showReasonAlert = true;
                }
                data = {
                    title: 'Confirm Suspension',
                    type: 'p0Suspension',
                    buttonText: 'Suspend',
                    reason: p0SuspensionReason,
                    serviceGroup: selectedServiceGroup,
                    suspensionAction: _this.selectedSuspensionAction,
                };
            }
            if (selectedSuspensionType == _this.nonP0Issues) {
                if (!selectedServiceGroup || !suspensionReason || !selectedUnit || !enteredPeriod) {
                    _this.showReasonAlert = true;
                }
                data = {
                    title: 'Confirm Suspension',
                    type: 'nonP0Suspension',
                    buttonText: 'Suspend',
                    reason: suspensionReason == "Other" ? suspensionOtherReason : suspensionReason,
                    serviceGroup: _this.selectedServiceGroup,
                    suspensionDuration: _this.enteredPeriod + ' ' + _this.selectedUnit,
                };
            }
            if (!_this.showReasonAlert) {
                var confirmDialogRef = _this.dialog.open(ConfirmDialogComponent, {
                    width: '335px',
                    data: data
                });
                confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                    if (confirmResult) {
                        _this.onProceed();
                    }
                });
            }
        };
        this.onProceed = function () {
            var _a = _this, selectedSuspensionType = _a.selectedSuspensionType, selectedServiceGroup = _a.selectedServiceGroup, suspensionReason = _a.suspensionReason, suspensionOtherReason = _a.suspensionOtherReason, selectedUnit = _a.selectedUnit, enteredPeriod = _a.enteredPeriod, p0SuspensionReason = _a.p0SuspensionReason, selectedSuspensionAction = _a.selectedSuspensionAction, p0SuspensionDuration = _a.p0SuspensionDuration;
            if (selectedSuspensionType == _this.nonP0Issues) {
                if (selectedServiceGroup && suspensionReason && selectedUnit && enteredPeriod || (suspensionReason == "Other" && !suspensionOtherReason)) {
                    _this.showReasonAlert = false;
                    var paramObj = {
                        serviceGroup: selectedServiceGroup.toLowerCase(),
                        suspensionReason: suspensionReason,
                        suspensionOtherReason: suspensionOtherReason,
                        minutes: (Number(enteredPeriod)) * _this.minuteConversionFactor[selectedUnit].toFixed(2),
                    };
                    _this.dialogRef.close(paramObj);
                }
            }
            if (selectedSuspensionType == _this.p0Issues) {
                if (selectedServiceGroup && p0SuspensionReason && selectedSuspensionAction) {
                    _this.showReasonAlert = false;
                    var paramObj = {
                        serviceGroup: selectedServiceGroup.toLowerCase(),
                        suspensionReason: p0SuspensionReason,
                        minutes: p0SuspensionDuration,
                        selectedSuspensionType: selectedSuspensionType
                    };
                    _this.dialogRef.close(paramObj);
                }
            }
        };
    }
    CaptainSuspensionDailogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.appConfigService.getSuspensionConfig()];
                    case 1:
                        _a.suspensionConfig = _b.sent();
                        this.suspensionReasons = JSON.parse(this.suspensionConfig.suspensionReasons);
                        this.p0SuspensionReasons = JSON.parse(this.suspensionConfig.p0SuspensionReasons);
                        this.p0PriorityMapping = JSON.parse(this.suspensionConfig.p0PriorityMapping);
                        this.timeUnits = ["Hours", "Days", "Weeks", "Months", "Years"];
                        this.minuteConversionFactor = {
                            "Hours": 60,
                            "Days": 24 * 60,
                            "Weeks": 24 * 60 * 7,
                            "Months": 24 * 60 * 30,
                            "Years": 24 * 60 * 365,
                        };
                        return [4 /*yield*/, this.mapSuspensionActionWithReasons()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CaptainSuspensionDailogComponent.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    CaptainSuspensionDailogComponent.prototype.changedSuspensionType = function (suspensionType) {
        this.selectedSuspensionType = suspensionType.value;
        if (this.selectedSuspensionType === this.p0Issues) {
            this.showP0 = true;
            this.showP1 = false;
        }
        if (this.selectedSuspensionType === this.nonP0Issues) {
            this.showP0 = false;
            this.showP1 = true;
        }
        this.showReasonAlert = false;
    };
    CaptainSuspensionDailogComponent.prototype.changedP0SuspensionReason = function (suspensionReason) {
        this.p0SuspensionReason = suspensionReason.value;
        this.suspensionActionList = this.p0SuspensionActions[this.p0SuspensionReason];
    };
    CaptainSuspensionDailogComponent.prototype.changedP0SuspensionAction = function (suspensionAction) {
        this.selectedSuspensionAction = suspensionAction.value;
        this.p0SuspensionDuration = this.p0PriorityMapping.priority.p0.issue[this.p0SuspensionReason].action[this.selectedSuspensionAction].suspensionPeriodInMinute;
    };
    CaptainSuspensionDailogComponent.prototype.mapSuspensionActionWithReasons = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.p0SuspensionActions = {};
                this.p0SuspensionReasons.forEach(function (reason) {
                    _this.p0SuspensionActions[reason] = Object.keys(_this.p0PriorityMapping.priority.p0.issue[reason].action);
                });
                return [2 /*return*/];
            });
        });
    };
    return CaptainSuspensionDailogComponent;
}());
export { CaptainSuspensionDailogComponent };
