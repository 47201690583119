import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class OrderDetailsHelper {

    constructor() { }
    captainAutoEarnings: any = {};
    orderDetailsMapping = {
        KEY_TAX_COLLECTED: 'Tax Collected',
        CASH_COLLECTED_FROM_CUSTOMER: 'Cash collected from the customer',
        REDEEM_WALLET_CREDITED: 'Amount added to wallet',
        REDEEM_WALLET_DEBITED: 'Amount deducted from wallet',
        REMARKS_TAX: 'Goods and Service Tax levied on Ride Charges and Booking/Convenience fees paid by Customer to the Government Authorities',
        commissionsParentKey: 'commissionTaxes',
        commissionTaxesLabel: 'Commission and Taxes',
        gstKey: 'gst',
        gstLabel: 'GST Collected(paid to government)',
        rapidoCommissionkey: 'rapidoCommission',
        rapidoCommissionLabel: 'Rapido\'s Commission',
        tdsPenaltyKey: 'tdsPenalty',
        tdsPenaltyLabel: 'Tax Deducted at source (TDS)',
        cancellationFareKey: 'cancellationFare',
        cancellationFareLabel: 'Cancellation Fare',
        adjustmentKey: 'adjustments',
        penaltyKey: 'penalty',
        orderFareParentKey: 'orderFare',
        orderFare: 'Order Fare',
        minimumFareKey: 'minimumFare',
        minimumFare: 'Minimum Fare',
        customerPlatformChargeKey: 'customerPlatformCharges',
        platformFeeLabel: 'Platform Fee',
        extraFareParentKey: 'extraFare',
        extraFareLabel: 'Extra Fare',
        surgeKey: 'surgeFare',
        firstMileFareKey: 'firstMileFare',
        pickupFareKey: 'pickupFare'
      }

    getFormattedEarningsBreakup(orderDetails, invoiceDetails, autoEarningsResponse, autoFlatCommissionServiceDetails){
        if(!this.isSpdOrder(orderDetails)){
            const { captainEarnings, earningsMapping } =  this.getCaptainEarningsSplit(autoEarningsResponse, orderDetails.status);
            this.captainAutoEarnings.orderBreakup = earningsMapping;
            const gst = this.getTaxCollected(invoiceDetails);
            this.captainAutoEarnings.orderBreakup.gst = gst && gst.value ? gst.value : 0;
            let paidToRapido = this.calculatePaidToRapido(invoiceDetails, captainEarnings, orderDetails)
            this.captainAutoEarnings.orderBreakup.paidToRapido = paidToRapido;
            this.captainAutoEarnings.orderBreakup.customerBill = orderDetails.pricing && orderDetails.pricing.amount ? orderDetails.pricing.amount : 0;
            this.captainAutoEarnings.orderBreakup.prevDue = orderDetails.pricing && orderDetails.pricing.prevDue ? orderDetails.pricing.prevDue : 0;
            this.captainAutoEarnings.orderBreakup.captainEarnings = captainEarnings ? captainEarnings.toFixed(2) : 0;
            this.captainAutoEarnings.orderBreakup.totalCaptainEarnings = autoEarningsResponse.earnings && autoEarningsResponse.earnings.amount ? autoEarningsResponse.earnings.amount : 0
            this.captainAutoEarnings.orderBreakup.paymentMode = orderDetails.payment && orderDetails.payment.mode ? orderDetails.payment.mode : '';

            // Flat commission was rolled out to hyderabad,chennai and bangalore on 5th May. This calculation is to be refactored - to be fetched from payroll
            if(autoFlatCommissionServiceDetails.includes(orderDetails.serviceDetail._id)){
              this.captainAutoEarnings.orderBreakup.gst = earningsMapping.tax;
              this.captainAutoEarnings.orderBreakup.paidToRapido = Math.abs(earningsMapping.platformCharges - earningsMapping.tax).toFixed(2)
            }
        }
        return this.captainAutoEarnings;
    }

    isSpdOrder(orderDetails) {
        return orderDetails && orderDetails.pricing && (orderDetails.pricing.spdFraud ? orderDetails.pricing.spdFraud.flag : false)
    }

    isAutoV2EarningEnabled(serviceDetail, serviceDetailIdArray, autoEarningsConfig){
        const serviceDetailId = serviceDetail._id;
        serviceDetailIdArray.push(serviceDetailId);
        const cityId = serviceDetail.city._id;
        const config = JSON.parse(autoEarningsConfig);
        if(config.enabled == true && config.enabledForAll == true){
            return true;
        }else if(config.cityServiceConfig[cityId]){
            const configArray = config.cityServiceConfig[cityId];
            return serviceDetailIdArray.every(i => configArray.includes(i));
        }
        return false;
    }

    getCaptainEarningsSplit(autoEarningsResponse, orderStatus) {
        if(autoEarningsResponse.earnings && autoEarningsResponse.earnings.breakUp){
            let captainEarnings = 0;
            let captainEarningsMapping: any = {};
            const activeUpiId = autoEarningsResponse.activeUpiId ? autoEarningsResponse.activeUpiId : "";
            const breakUp = autoEarningsResponse.earnings.breakUp
            const baseFare = this.getAmount(breakUp.baseFare);
            const distance = this.getAmount(breakUp.distanceFare);
            const totalDistance =  breakUp.distanceFare && breakUp.distanceFare.details && breakUp.distanceFare.details.totalUnits ? `(${breakUp.distanceFare.details.totalUnits} km)` : "";
            const timeFare = this.getAmount(breakUp.timeFare);
            const totalTime = breakUp.timeFare && breakUp.timeFare.details && breakUp.timeFare.details.totalUnits ? `(${breakUp.timeFare.details.totalUnits} min)` : "";
            const distanceFare = baseFare + distance;
            const orderFare = distanceFare + timeFare
            const minimumFare = this.getAmount(breakUp.minimumFare);
            const isMinimumFare = minimumFare > orderFare ? 1 : 0;
            if(breakUp.cancellationFare && orderStatus == "customerCancelled"){
                const cancellationBreakup = breakUp.cancellationFare
                captainEarnings += this.getAmount(cancellationBreakup)
                captainEarningsMapping.cancellationFare = this.getAmount(cancellationBreakup);
            }else{
                if(activeUpiId){
                  captainEarningsMapping.activeUpiId = activeUpiId;
                }
                captainEarningsMapping.tax = breakUp.platformCharges.details.totalTax
                captainEarningsMapping.platformCharges = breakUp.platformCharges.amount
                const longPickupFare = this.getAmount(breakUp.firstMileFare);
                let longPickupDistance = ''
                if (longPickupFare > 0) {
                    longPickupDistance = `(${breakUp.firstMileFare.details.totalUnits} km)`;
                }
                const surgeFare = this.getAmount(breakUp.surgeFare);
                const pickupFare = this.getAmount(breakUp.pickupFare);

                let waitTimeCharge = this.getAmount(breakUp.waitingFare);
                let waitingTime = ''
                if (waitTimeCharge > 0) {
                    waitTimeCharge = +waitTimeCharge;
                    waitingTime = `(${breakUp.waitingFare.details.totalUnits} min)`;
                }
            
                if(breakUp.nightFare){
                    let totalAmount = breakUp.nightFare && breakUp.nightFare.amount ? breakUp.nightFare.amount : 0;
                    captainEarningsMapping.nightFare = totalAmount;
                }

                if (isMinimumFare > 0) {
                    captainEarnings += minimumFare;
                    captainEarningsMapping.minimumFare = minimumFare;
                } else {
                    captainEarnings += distanceFare + timeFare
                    captainEarningsMapping.distanceFare = +distanceFare.toFixed(2);
                    captainEarningsMapping.timeFare = timeFare;
                    captainEarningsMapping.totalDistance = totalDistance;
                    captainEarningsMapping.totalTime = totalTime;
                }
                if(longPickupFare > 0){
                    captainEarnings += longPickupFare;
                    captainEarningsMapping.longPickupFare = `+₹${Math.abs(longPickupFare)}`;
                    captainEarningsMapping.longPickupDistance = longPickupDistance;
                }
                if (pickupFare > 0) {
                  captainEarnings += pickupFare;
                  captainEarningsMapping.pickupFare = Math.abs(pickupFare);
                }
                if(surgeFare > 0){
                    captainEarnings += surgeFare
                    captainEarningsMapping.surgeFare = surgeFare;
                }
                if (waitTimeCharge > 0) {
                    captainEarnings += waitTimeCharge;
                    captainEarningsMapping.waitTimeFare = `+₹${Math.abs(waitTimeCharge)}`;
                    captainEarningsMapping.waitTime = waitingTime;
                }
                if (breakUp.adjustments) {
                    if (Array.isArray(breakUp.adjustments) && breakUp.adjustments.length > 0) {
                        const breakupArray = breakUp.adjustments
                        const adjustmentValue = this.getCompressedBreakup(breakupArray).amount
                    captainEarningsMapping.adjustments = `+₹${Math.abs(adjustmentValue)}`
                    } else {
                        captainEarningsMapping.adjustments = this.getAmountValue(this.getAmount(breakUp.adjustments))
                    }
                }
                if (breakUp.penalty) {
                    if (Array.isArray(breakUp.penalty) && breakUp.penalty.length > 0) {
                        const breakupArray = breakUp.penalty
                        const penaltyValue = this.getCompressedBreakup(breakupArray).amount
                        captainEarningsMapping.penalty = `-₹${Math.abs(penaltyValue)}`;
                    } else {
                        captainEarningsMapping.penalty = this.getAmountValue(this.getAmount(breakUp.penalty))
                    }
                }
                if (breakUp.tdsPenalty) {
                  if (Array.isArray(breakUp.tdsPenalty) && breakUp.tdsPenalty.length > 0) {
                      const breakupArray = breakUp.tdsPenalty
                      const tdsPenaltyValue = this.getCompressedBreakup(breakupArray).amount
                      captainEarningsMapping.tds = `-₹${Math.abs(tdsPenaltyValue).toFixed(2)}`;
                  } else {
                      captainEarningsMapping.tds = this.getAmountValue(this.getAmount(breakUp.tdsPenalty).toFixed(2))
                  }
              }
                if (breakUp.tip) {
                    if (Array.isArray(breakUp.tip) && breakUp.tip.length > 0) {
                        const breakupArray = breakUp.tip
                        const breakupSpreadArray = this.getSpreadBreakup(breakupArray)
                        breakupSpreadArray.forEach(breakupItem => {
                            const value = this.getRoundedAmount(breakupItem.amount)
                            captainEarningsMapping.tip = this.getAmountValue(value);
                        })
                    } else {
                        captainEarningsMapping.tip = this.getAmountValue(this.getAmount(breakUp.tip))
                    }
                }
                if(breakUp.tollFare) {
                  captainEarningsMapping.tollFare = this.getAmount(breakUp.tollFare);
                }
                if(breakUp.bidDelta) {
                  captainEarningsMapping.bidDelta = this.getAmount(breakUp.bidDelta);
                }
                if(breakUp.parkingCharges) {
                  captainEarningsMapping.parkingCharges = this.getAmount(breakUp.parkingCharges);
                }
            }
            return { earningsMapping : captainEarningsMapping, captainEarnings : captainEarnings}
        }
        return;
    }

    calculatePaidToRapido(invoiceReceipt, captainEarnings, orderDetail) {
        //TODO - handle negative case
        const gst  = this.getTaxCollected(invoiceReceipt);
        const customerBill = orderDetail.pricing.amount
        const paidToRapido = customerBill - captainEarnings - (gst && gst.value)
        return +paidToRapido.toFixed(2)
    }

    getTaxCollected(invoiceReceipt) {
        let taxAmount = 0
        if (invoiceReceipt && invoiceReceipt.riderCharge && invoiceReceipt.riderCharge.tax) {
          taxAmount += parseFloat(invoiceReceipt.riderCharge.tax)
        }
        if (invoiceReceipt && invoiceReceipt.finalAmount && invoiceReceipt.finalAmount.tax) {
          taxAmount += parseFloat(invoiceReceipt.finalAmount.tax)
        }
        if (taxAmount != 0) {
          const finalTax = parseFloat(taxAmount.toFixed(2))
          return {
            key: this.orderDetailsMapping.KEY_TAX_COLLECTED,
            value: finalTax,
            remarks: this.orderDetailsMapping.REMARKS_TAX
          }
        }
        return {}
      }

    getSpreadBreakup(breakupArray) {
        let breakups = []
        breakupArray.forEach(item => {
          if (item && item.amount && !isNaN(item.amount) && item.amount != 0.0) {
            let amount = item.amount
            let remarks = ''
            let unit = 'amount'
            if (item && item.details && item.details.remarks) remarks = item.details.remarks
            if (item && item.details && item.details.unit) unit = item.details.unit
            breakups.push({
              amount: amount,
              remarks: remarks,
              unit: unit
            })
          }
        })
        return breakups
      }

    getCompressedBreakup(breakupArray) {
        let amount = 0.0
        let remarks = ''
        let unit = 'amount'
        if (breakupArray != undefined) {
          breakupArray.forEach(item => {
            if (item && item.amount && !isNaN(item.amount) && item.amount != 0.0) amount += item.amount
            if (item && item.details && item.details.remarks) remarks = item.details.remarks
            if (item && item.details && item.details.unit) unit = item.details.unit
          })
          return {
            amount: amount,
            remarks: remarks,
            unit: unit
          }
        }
    }
    
    getRoundedAmount(amount) {
        return amount.toFixed(2) * 1
    }

    getAmount(item) {
        let amount = 0.0
        if (item && item.amount && !isNaN(item.amount) && item.amount != 0.0) amount += item.amount
            return amount
    }
    
    getAmountValue(amount) {
      if(amount){
        if (amount < 0) {
            let abs = Math.abs(amount)
            return `-${abs}`
        }
         return `${amount}`
      }
      return `0`;
    }

    getLabelWithInfo(breakupObject) {
        if (breakupObject && breakupObject.details && breakupObject.details.totalUnits && breakupObject.details.unit) {
          const value = this.getRoundedAmount(breakupObject.details.totalUnits)
          const unit = breakupObject.details.unit
          return {
            extraInfo: `(${value} ${unit}s)`
          }
        }
        return {
          extraInfo: ''
        }
      }

      getTimeBasedSlab(data, orderEvents) {
        const timeBasedSlab: any = {};
        if (!this.isEmpty(data.customer.timeBasedSlabs) && !this.isEmpty(orderEvents)) {
          const orderRequestedDate = moment(orderEvents[0].updatedAt).format('YYYY-MM-DD');
          const orderRequestedTime = moment(orderEvents[0].updatedAt);
          const slabDetails = data.customer.timeBasedSlabs.find(slab => {
            const timeBasedSlabStartTime = moment(orderRequestedDate + ' ' + slab.startTime, 'YYYY-MM-DD HH:mm:ss');
            const timeBasedSlabEndTime = moment(orderRequestedDate + ' ' + slab.endTime, 'YYYY-MM-DD HH:mm:ss');
            return orderRequestedTime.isBetween(timeBasedSlabStartTime, timeBasedSlabEndTime);
          });
          if (!this.isEmpty(slabDetails)) {
            timeBasedSlab.key = slabDetails.appliedAt.charAt(0).toUpperCase() + slabDetails.appliedAt.slice(1) + " Fare";
            timeBasedSlab.value = slabDetails.rules[0].type == 'fact' ? "₹ " + slabDetails.rules[0].value : slabDetails.rules[0].value + " %";
          }
        }
        return timeBasedSlab;
      }
    
      getCaptainTimeBasedSlab(data, orderEvents) {
        const timeBasedSlab: any = {};
        if (!this.isEmpty(data.captain.timeBasedSlabs) && !this.isEmpty(orderEvents)) {
          const orderRequestedDate = moment(orderEvents[0].updatedAt).format('YYYY-MM-DD');
          const orderRequestedTime = moment(orderEvents[0].updatedAt);
          const slabDetails = data.captain.timeBasedSlabs.find(slab => {
            const timeBasedSlabStartTime = moment(orderRequestedDate + ' ' + slab.startTime, 'YYYY-MM-DD HH:mm:ss');
            const timeBasedSlabEndTime = moment(orderRequestedDate + ' ' + slab.endTime, 'YYYY-MM-DD HH:mm:ss');
            return orderRequestedTime.isBetween(timeBasedSlabStartTime, timeBasedSlabEndTime);
          });
          if (!this.isEmpty(slabDetails)) {
            timeBasedSlab.key = slabDetails.appliedAt.charAt(0).toUpperCase() + slabDetails.appliedAt.slice(1) + " Fare";
            timeBasedSlab.value = slabDetails.rules[0].type == 'fact' ? "₹ " + slabDetails.rules[0].value : slabDetails.rules[0].value + " %";
          }
        }
        return timeBasedSlab;
      }

      getCaptainTimeBasedSlabFromRateCardApi(captainRateCard, orderEvents) {
        const timeBasedSlab: any = {};
        if (!this.isEmpty(captainRateCard.timeBasedSlabs) && !this.isEmpty(orderEvents)) {
          let requestedEvent = orderEvents.find(event => {
            if (event.eventName === "requested") {
              return event;
            }
          });
          if(!requestedEvent){
            return timeBasedSlab;
          }
          const orderRequestedDate = moment(requestedEvent.updatedAt).format('YYYY-MM-DD');
          const orderRequestedTime = moment(requestedEvent.updatedAt);
          const slabDetails = captainRateCard.timeBasedSlabs.find(slab => {
            const timeBasedSlabStartTime = moment(orderRequestedDate + ' ' + slab.startTime, 'YYYY-MM-DD HH:mm:ss');
            const timeBasedSlabEndTime = moment(orderRequestedDate + ' ' + slab.endTime, 'YYYY-MM-DD HH:mm:ss');
            return orderRequestedTime.isBetween(timeBasedSlabStartTime, timeBasedSlabEndTime);
          });
          if (!this.isEmpty(slabDetails)) {
            timeBasedSlab.key = slabDetails.appliedAt.charAt(0).toUpperCase() + slabDetails.appliedAt.slice(1) + " Fare";
            timeBasedSlab.value = slabDetails.rules[0].type == 'flat' ? "₹ " + slabDetails.rules[0].value : slabDetails.rules[0].value + " %";
          }
        }
        return timeBasedSlab;
      }

      isEmpty(value) {
        return (value == null || value.length === 0);
      }
}