

<div class="form-group">
    <div class="form-label">{{radioData.title}}</div>
    <div class="custom-controls-stacked">
      <label class="custom-control custom-radio custom-control-inline" *ngFor="let option of radioData.values">
        <input type="radio" class="custom-control-input" (change)="chosenOptions()" [(ngModel)]="selectedValues" value="{{option}}">
        <span class="custom-control-label">{{option}}</span>
      </label>
    </div>
  </div>
