import { OnInit } from '@angular/core';
import { HorizontalList } from 'src/app/shared/horizontal-list/horizontal-list.component';
import { TableDataTypes, TableData } from 'src/app/shared/types';
import { PerformanceCard } from 'src/app/shared/types/card.types';
import { forkJoin } from 'rxjs';
import { CaptainService } from 'src/app/captain.service';
import { DateFilterInput, FilterService, SingleSearchableDropdown, QuickFilters } from 'src/app/filter.service';
import { OrderService } from 'src/app/order-service.service';
import { HelperService } from 'src/app/helper.service';
import { Router } from '@angular/router';
import { CaptainFailedOrdersService } from '../captain-failed-orders/captain-failed-orders.service';
import { EntityService } from 'src/app/entity.service';
import { SvoService } from 'src/app/svo.service';
var currentDate = new Date();
var monthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
var FRAUD_TITLE_MAPPING = {
    differentDropLocationFraud: 'Drop Location Frauds',
    checkPickupLocationAndDeviceFraud: 'Pickup Location And Device Frauds',
    differentActualPickUpFraud: 'Different Pickup Location Frauds',
    distanceToAcceptFraud: 'Distance to Accept Frauds'
};
function padStart(length, data) {
    return data.toString().padStart(length, 0);
}
var currentDateString = currentDate.getFullYear() + "-" + padStart(2, currentDate.getMonth() + 1) + "-" + padStart(2, currentDate.getDate());
var monthStartDateString = monthStartDate.getFullYear() + "-" + padStart(2, monthStartDate.getMonth() + 1) + "-" + padStart(2, monthStartDate.getDate());
var defaultFilters = function () {
    return {
        fromDate: new Date(monthStartDate),
        duration: [monthStartDateString, currentDateString],
        toDate: new Date(currentDate),
        startDuration: new Date(monthStartDate),
        endDuration: new Date(currentDate),
        status: 'dropped',
    };
};
var ɵ0 = defaultFilters;
var CaptainDateWiseStatDetailsComponent = /** @class */ (function () {
    function CaptainDateWiseStatDetailsComponent(captainService, filterService, orderService, router, captainFailedOrdersService, entityService, svoService) {
        this.captainService = captainService;
        this.filterService = filterService;
        this.orderService = orderService;
        this.router = router;
        this.captainFailedOrdersService = captainFailedOrdersService;
        this.entityService = entityService;
        this.svoService = svoService;
        this.dataLoaded = false;
        this.appliedFilters = defaultFilters();
        this.filterCount = 0;
        this.statusList = [
            'new',
            'customerCancelled',
            'riderCancelled',
            'onTheWay',
            'arrived',
            'started',
            'dropped',
            'expired',
            'aborted'
        ];
    }
    CaptainDateWiseStatDetailsComponent.prototype.ngOnInit = function () {
        this.dataLoaded = false;
        if (this.horizontalList && this.horizontalList.table) {
            this.makeTableNull();
        }
        this.defineList(this.captainMobile, this.captainId);
    };
    CaptainDateWiseStatDetailsComponent.prototype.makeTableNull = function () {
        this.horizontalList.table = null;
        this.horizontalList = null;
    };
    CaptainDateWiseStatDetailsComponent.prototype.padZeroAtStart = function (data) {
        try {
            return data.toString().padStart(2, 0);
        }
        catch (err) {
            return data;
        }
    };
    CaptainDateWiseStatDetailsComponent.prototype.getFormattedDate = function (d) {
        var date = new Date(d);
        return date.getFullYear() + "-" + this.padZeroAtStart(date.getMonth() + 1) + "-" + this.padZeroAtStart(date.getDate());
    };
    CaptainDateWiseStatDetailsComponent.prototype.getDateWiseStat = function (captainMobile, captainId) {
        var _this = this;
        return this.entityService.getEntityByNumber(captainMobile)
            .flatMap(function (data) {
            if (data && data.userId && data.userId._id) {
                _this.captainUserId = data.userId._id;
            }
            return forkJoin(_this.svoService.getAverageCaptainRatings(_this.captainUserId), _this.captainService.fraudStat({
                riderId: captainMobile,
                startDuration: _this.appliedFilters.startDuration,
                endDuration: _this.appliedFilters.endDuration,
            }), _this.captainService.getCaptainAdjustment(data.userId._id, _this.getFormattedDate(_this.appliedFilters.startDuration), _this.getFormattedDate(_this.appliedFilters.endDuration)), _this.entityService.getCaptainCancellations(_this.captainUserId), _this.entityService.getServiceDetails(_this.captainUserId), _this.entityService.getServiceNameDetails());
        })
            .map(function (_a) {
            var averageRating = _a[0], _b = _a[1], fraudStats = _b === void 0 ? [] : _b, adjustmentData = _a[2], captainCancellationData = _a[3], serviceLevelCancellationLimits = _a[4], serviceNameDetails = _a[5];
            // this.filterService.filterDataChange.next(this.appliedFilters);
            _this.filterService.appliedFilters = _this.appliedFilters;
            var roundTwoDecimal = function (num) {
                return Math.round(num * 100) / 100;
            };
            var fixedAverageRating = averageRating ? roundTwoDecimal(averageRating) : 'N/A';
            // const failedOrderCount = failedOrders && failedOrders['data'] && failedOrders['data']['length'] || 'N/A';
            var adjustedAmount = adjustmentData['adjustedAmount'] || 'N/A';
            var adjustedDistance = adjustmentData['adjustedDistance'] || 'N/A';
            var dailyCancellationLimit;
            var weeklyCancellationLimit;
            if (captainCancellationData && captainCancellationData['data']) {
                dailyCancellationLimit = captainCancellationData['data']['dailyLimit'];
                weeklyCancellationLimit = captainCancellationData['data']['weeklyLimit'];
            }
            var data = [
                {
                    label: 'Average rating',
                    value: {
                        toString: function () {
                            return fixedAverageRating;
                        },
                        icon: 'star_rate',
                        className: 'yellow-star',
                        style: {
                            'font-size': '26px !important',
                        },
                    },
                    type: TableDataTypes.PERFORMANCE_CARD,
                },
                {
                    label: 'Failed Orders',
                    value: {
                        toString: function () {
                            return ' ';
                        },
                        icon: 'info',
                        className: 'yellow-star',
                        style: {
                            'color': 'black',
                            'font-size': '26px !important',
                        },
                    },
                    onClick: (function () {
                        _this.router.navigate([
                            'failedOrders/' + HelperService.encode(_this.captainMobile)
                        ]);
                    }),
                    type: TableDataTypes.PERFORMANCE_CARD,
                },
                {
                    label: 'Adjustment Amount',
                    value: "\u20B9 " + adjustedAmount,
                    type: TableDataTypes.PERFORMANCE_CARD,
                },
                {
                    label: "Adjusted KM's",
                    value: adjustedDistance + " km",
                    type: TableDataTypes.PERFORMANCE_CARD,
                }
            ];
            if (serviceLevelCancellationLimits && serviceNameDetails) {
                serviceLevelCancellationLimits.forEach(function (element) {
                    data.push({
                        label: serviceNameDetails[element.service] + " : Daily & weekly cancellations",
                        value: "[" + (dailyCancellationLimit ? dailyCancellationLimit : 0) + "/" + element.dailyLimit + "] [" + (weeklyCancellationLimit ? weeklyCancellationLimit : 0) + "/" + element.weeklyLimit + "]",
                        type: TableDataTypes.PERFORMANCE_CARD,
                    });
                });
            }
            var fraudCards = fraudStats.map(function (fraud) {
                return {
                    label: FRAUD_TITLE_MAPPING[fraud._id.type] || fraud._id.type,
                    value: fraud.count || 0,
                    type: TableDataTypes.FRAUD_CARD,
                    onClick: (function () {
                        var fromDate, toDate;
                        fromDate = _this.appliedFilters.duration['start'];
                        toDate = _this.appliedFilters.duration['end'];
                        _this.appliedFilters = defaultFilters();
                        _this.router.navigate(['fraudOrders/' + HelperService.encode(_this.captainMobile)], {
                            queryParams: {
                                frauds: fraud._id.type,
                                fromDate: fromDate,
                                toDate: toDate
                            }
                        });
                    })
                };
            });
            return [data, fraudCards];
        });
    };
    CaptainDateWiseStatDetailsComponent.prototype.defineList = function (captainMobile, captainId) {
        var _this = this;
        this.dataLoaded = false;
        this.filterService.appliedFilters = this.appliedFilters;
        this.getDateWiseStat(captainMobile, captainId)
            .subscribe(function (multiRecords) {
            _this.dataLoaded = true;
            var tds = multiRecords.map(function (records) {
                return records.map(function (record) {
                    return new TableData({
                        type: record.type,
                        performanceCard: new PerformanceCard({
                            // hikePercent: record.hikePercent,
                            label: record.label,
                            value: record.value,
                            onClick: record.onClick,
                            // isPositivehike: isPositive,
                            style: {
                                height: '127px',
                                width: '176px',
                            },
                        })
                    });
                });
            });
            _this.horizontalList = new HorizontalList({
                header: {
                    toString: function () {
                        var startDate, endDate;
                        if (_this.appliedFilters.duration && _this.appliedFilters.duration instanceof Array) {
                            startDate = _this.appliedFilters.duration[0];
                            endDate = _this.appliedFilters.duration[1];
                        }
                        else {
                            startDate = _this.appliedFilters.duration['start'];
                            endDate = _this.appliedFilters.duration['end'];
                        }
                        if (startDate === endDate) {
                            return "Today, " + HelperService.dateString(new Date());
                        }
                        else {
                            return HelperService.dateString(_this.appliedFilters.fromDate) + " - " + HelperService.dateString(_this.appliedFilters.toDate);
                        }
                    }
                },
                // header: 'Today, 13 March 2019',
                tableData: tds,
                filterCount: _this.filterCount,
                config: {
                    refresh: true,
                    filter: true,
                },
                events: {
                    onRefresh: function () {
                        _this.ngOnInit();
                    },
                    onFilter: function () {
                        _this.filterCount = 0;
                        _this.horizontalList.table.appliedFilters = _this.filterService.appliedFilters;
                        var status = _this.filterService.appliedFilters.status;
                        var duration = DateFilterInput.parseValue(_this.filterService.appliedFilters.duration);
                        if (status) {
                            _this.filterCount++;
                        }
                        if (duration.startDuration || duration.endDuration) {
                            _this.filterCount++;
                        }
                        var appliedFilters = {};
                        if (duration.startDuration && duration.endDuration) {
                            var stringDuration = _this.filterService.appliedFilters.duration;
                            appliedFilters = {
                                duration: {
                                    start: stringDuration[0],
                                    end: stringDuration[1],
                                },
                                fromDate: duration.startDuration,
                                toDate: duration.endDuration,
                                startDuration: duration.startDuration,
                                endDuration: duration.endDuration,
                            };
                            if (stringDuration instanceof Array) {
                                appliedFilters.duration = {
                                    start: stringDuration[0],
                                    end: stringDuration[1],
                                };
                            }
                            else {
                                appliedFilters.duration = {
                                    start: stringDuration.start,
                                    end: stringDuration.end,
                                };
                            }
                        }
                        else {
                            appliedFilters = defaultFilters();
                        }
                        if (status) {
                            appliedFilters.status = status;
                        }
                        else {
                            appliedFilters.status = null;
                        }
                        Object.assign(_this.appliedFilters, appliedFilters);
                        _this.filterService.filterDefaultValueChange.next(appliedFilters);
                        _this.defineList(_this.captainMobile, _this.captainId);
                        // console.log(duration);
                    }
                },
                filterComponents: [
                    // new DateFilterInput({
                    //   key: 'duration',
                    //   default: '',
                    //   // default: this.appliedFilters.duration,
                    //   title: 'Duration'
                    // }),
                    new QuickFilters({
                        key: 'duration',
                        default: '',
                        title: 'Duration',
                    }),
                    new SingleSearchableDropdown({
                        key: 'status',
                        default: '',
                        values: _this.statusList,
                        title: 'Status'
                    }),
                ],
                appliedFilters: HelperService.copyObj(_this.appliedFilters),
            });
        });
    };
    CaptainDateWiseStatDetailsComponent.prototype.isEmpty = function (value) {
        return (value == null || value.length === 0);
    };
    return CaptainDateWiseStatDetailsComponent;
}());
export { CaptainDateWiseStatDetailsComponent };
export { ɵ0 };
