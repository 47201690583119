<head>
  <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet">
</head>
<h1 mat-dialog-title class="dialog-title margin-0">Activity Logs</h1>

<div>
  <div *ngFor="let log of activityLog">
    <div class="record-box row">
      <div class="col-4">
        Event: {{failedOrderTypeMap[log.event]}} <br/>
        Action: {{log.action}} <br/>
        Date: {{log.date}} <br/>
        Remark: {{log.remark}} <br/>
        <span *ngIf="log.action === 'penalty'"> Amount: &#x20b9; {{log.amount}} <br/> </span>
        <span *ngIf="log.action === 'suspend'"> Activate After (Hours): {{log.activateAfterHours}} <br/> </span>
      </div>
      <div class="col-4">
        <span class="capitalize"> Revoked: {{log.isActionReverted || false}} <br/> </span>
        <span *ngIf="log.isActionReverted || false">
          Revoked by: {{revokedByData(log, eventCount)}} <br/>
          Revoked On: {{revokedOnData(log, eventCount)}} <br/>
        </span>
      </div>
      <div class="col-4" *ngIf="!log.isActionReverted">
        <div mat-dialog-actions class="button-flex" *ngIf=allowRevoke(log.action)>
          <button class="ok-btn font-size-13" (click)="revokeAction(eventCount.orderId, eventCount.event, eventCount.eventDate, eventCount.orderCreatedDate, log.action)">Revoke</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="button-flex">
  <button class="ok-btn font-size-13" (click)="onClose()">OK</button>
</div>