
<div class = "status-wrapper">
    <div class = "status-content-wrapper" *ngFor="let timeline of orderTimeLine; let i = index">
        <div *ngIf = "!statusMarkReady.includes(timeline.eventName)"class = "status" >{{statusMap[timeline.eventName] || dropChanged(timeline.updateType) || timeline.eventName}}
            <span  matTooltip="{{timeline.cancelReason || timeline.reason || timeline.abortReason}}" *ngIf="timeline.eventName === 'customer_cancelled' || timeline.eventName === 'captain_cancelled' || timeline.eventName === 'aborted'">
                <i class="material-icons-outlined status-reasons-info cursor-pointer">
                    info
                </i>
            </span>
            <span  matTooltip="{{getCancelB2BText(FNDcase)}}" *ngIf="timeline.eventName === 'dropped' && FNDcase">
                <i class="material-icons-outlined status-reasons-info cursor-pointer">
                    info
                </i>
            </span>
        </div>
        <div *ngIf="status.indexOf(timeline.status) !== -1 && !statusMarkReady.includes(timeline.eventName) " class="order-status background-color-kermit-green"
            [ngClass]="{'blue-logs-bg': timeline.message && timeline.message == 'Client asked to drop','yellow-logs-bg': timeline.updatedBy && timeline.updatedBy.emailId && timeline.statusModified !== 'Aborted', 'cursor-pointer': timeline.emailId}"
            [ngStyle]="{'background-color': statusRed.indexOf(timeline.statusModified) === -1 ? '#5fba00' : '#cd1f1e'}" >
            <ng-template appendTo="body" #tipContent>
                <div class="logs-tooltip-container" [innerHTML]="getTooltipData(timeline)"></div> 
            </ng-template>
            <mat-icon class="timeline-icon" data-toggle="tooltip" placement="bottom" tooltipClass="preserve-whitespace" container="body" [ngbTooltip]="tipContent" *ngIf="statusRed.indexOf(timeline.statusModified) === -1 && timeline.updatedBy && timeline.updatedBy.emailId">done</mat-icon>
            <mat-icon class="timeline-icon" data-toggle="tooltip" placement="bottom" tooltipClass="preserve-whitespace" container="body" [ngbTooltip]="tipContent" *ngIf="statusRed.indexOf(timeline.statusModified) !== -1 && timeline.updatedBy && timeline.updatedBy.emailId">close</mat-icon>        
            <mat-icon class="timeline-icon" *ngIf="statusRed.indexOf(timeline.statusModified) === -1 && !(timeline.updatedBy && timeline.updatedBy.emailId)">done</mat-icon>
            <mat-icon class="timeline-icon" *ngIf="statusRed.indexOf(timeline.statusModified) !== -1 && !(timeline.updatedBy && timeline.updatedBy.emailId)">close</mat-icon>        
        </div>    

        <div *ngIf = "statusMarkReady.includes(timeline.eventName)"class = "statusNew" >{{statusMap[timeline.eventName] || timeline.eventName}}</div>
        <div *ngIf = "statusMarkReady.includes(timeline.eventName)" class="order-status background-color-kermit-green"

            [ngStyle]="{'background-color':  'transparent'}" >
            <mat-icon class="timeline-flag-green" *ngIf = "timeline.eventName == 'shipmentReadyTimeInEpoch' ">flag</mat-icon>
            <mat-icon class="timeline-flag-blue" *ngIf = "timeline.eventName == 'predictedShipmentReadyTimeInEpoch' ">flag</mat-icon>
        </div>    
        <div *ngIf = "i!=orderTimeLine.length-1" class="connector"><hr></div>
        <div class = "timeline-date">{{getProperDate(timeline.updatedAt)}}</div>    
    </div>
</div>