<div *ngIf="!dataLoaded">
    <div class="row">
            <div class="col-md-6">
                <bullet-list-content-loader></bullet-list-content-loader>
            </div>
            <div class="col-md-6">
                <bullet-list-content-loader></bullet-list-content-loader>
            </div>
    </div>
</div>

<div *ngIf="dataLoaded">
    <app-expandable-table [info]="tableData" (toggleExpansion)="toggleState($event)" (buttonClick)="buttonClicked($event)"
    [pageRefreshed]="pageRefreshed"></app-expandable-table>
</div>
