import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MyHttpClient } from '../app/http-client.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserTicketingService {

  server = environment.server;
  fetchTicketsV2Url = `${this.server}/api/user/tickets/v2`;
  fetchTicketCommentsUrl = `${this.server}/api/user/tickets/v1/comments`

  constructor(private http: MyHttpClient) { }

  fetchUserTicketsV2(userId, userType, query, pageNumber){
    return this.http.get(`${this.fetchTicketsV2Url}/${userId}?userType=${userType}&query=${query}&page=${pageNumber}`).pipe(catchError(this.handleError));
  }

  fetchTicketLastComment(userId, ticketId, includePrivate, perPage){
    return this.http.get(`${this.fetchTicketCommentsUrl}/${ticketId}/${userId}?includePrivate=${includePrivate}&perPage=${perPage}`).pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }

}
