var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ToasterService } from 'src/app/toaster.service';
import { Toaster, ToasterType } from 'src/app/shared/types';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { TimeLineService } from './timeline-view.service';
import { RoleBasedAccessService } from '../role-based-access.service';
import { CAPTAIN_REASSIGN, CAPTAIN_ABORT } from '../roles.constants';
import { environment } from 'src/environments/environment';
import { OrderActivityLogComponent } from '../order-activity-log/order-activity-log.component';
import * as moment from 'moment';
import { OrderDetailsService } from '../order-details/order-details.service';
import { RideTrackerMapService } from '../ride-tracker-map/ride-tracker-map.service';
var TimelineViewComponent = /** @class */ (function () {
    function TimelineViewComponent(toasterService, dialog, route, timeLineService, orderDetailsService, rideTrackerMapService, roleBasedAccess) {
        var _this = this;
        this.toasterService = toasterService;
        this.dialog = dialog;
        this.route = route;
        this.timeLineService = timeLineService;
        this.orderDetailsService = orderDetailsService;
        this.rideTrackerMapService = rideTrackerMapService;
        this.roleBasedAccess = roleBasedAccess;
        this.refresh = new EventEmitter();
        this.viewMore = true;
        this.viewDetails = 'Show less';
        this.isReassignable = false;
        this.abortRide = true;
        this.cancelRide = true;
        this.showTimeline = true;
        this.fraudTags = [];
        this.orderTags = [];
        this.aborted = false;
        this.dataFetched = true;
        this.statusRed = ['Customer cancelled', 'Rider rejected', 'Expired', 'Aborted'];
        this.eventName = [
            'requested', 'accepted', 'new', 'onTheWay', 'accepted', 'arrived', 'started', 'dropped', 'expired',
            'captain_cancelled', 'customer_cancelled', 'captain_rejected', 'captain_switch',
            'reached', 'aborted', 'captains_mapped', 'shipmentReadyTimeInEpoch', 'predictedShipmentReadyTimeInEpoch'
        ];
        this.notAllowedToAbortStatus = [
            'aborted', 'dropped', 'customerCancelled', 'expired',
            'new', 'requested', 'captainCancelled'
        ];
        this.notAllowedToCancelStatus = [
            'aborted', 'dropped', 'customerCancelled', 'expired',
            'new', 'requested', 'captainCancelled'
        ];
        this.reAssignStatus = ['onTheWay', 'arrived']; // can re-assing during this status
        this.servicesAllowedToArrive = [];
        this.servicesAllowedToDrop = [];
        this.allowStatusChangeFrom = {
            'On the way': false,
            'Reached': false,
            'Started': false,
            'Arrived': false
        };
        this.roles = this.accessCookie('Roles').split(',');
        this.perPage = 20;
        this.pageNumber = 1;
        this.getFormattedOrderActivityLogs = function (logs) {
            if (logs.length) {
                return logs.map(function (eventLog) { return ({
                    'timestamp': moment(new Date(Number(eventLog.timestamp))).format('DD-MM-YYYY hh:mm a'),
                    'eventName': eventLog.event_name || 'N/A',
                    'eventData': eventLog.description || 'N/A'
                }); });
            }
            return logs;
        };
        this.getActivityLog = function () {
            _this.getOrderActivityLogs();
            _this.activityLogDialog();
        };
    }
    TimelineViewComponent.prototype.ngOnInit = function () {
        this.orderId = this.route.snapshot.params.id;
        this.setDispatchValues(this.dispatch);
        this.changeInStatus();
        this.fetchTags();
        this.getOrderActivityLogs();
    };
    TimelineViewComponent.prototype.ngOnChanges = function (changes) {
        if (changes) {
            this.changeInStatus();
            if (changes.dispatch && changes.dispatch.currentValue) {
                this.orderTags = [];
                this.setDispatchValues(changes.dispatch.currentValue);
            }
            if (changes.timeLineDetails && changes.timeLineDetails.currentValue) {
                this.orderTimeLine = changes.timeLineDetails.currentValue;
                for (var index = 0; index < this.orderTimeLine.length; index++) {
                    if (this.eventName.indexOf(this.orderTimeLine[index].eventName) == -1) {
                        if (this.orderTimeLine[index].updateType == 'dropLocation') {
                            continue;
                        }
                        this.orderTimeLine.splice(index, 1);
                        index--;
                        continue;
                    }
                }
                if (changes.captainDetails.currentValue.status && this.notAllowedToAbortStatus.indexOf(changes.captainDetails.currentValue.status) !== -1) {
                    this.abortRide = false;
                }
                if (changes.captainDetails.currentValue.status && this.notAllowedToCancelStatus.indexOf(changes.captainDetails.currentValue.status) !== -1) {
                    this.cancelRide = false;
                }
                if (this.orderTimeLine.length > 0) {
                    this.currentStatus = this.statusMap[this.fetchCurrentOrderStatus()];
                }
                if (changes.captainDetails.currentValue.status && this.reAssignStatus.indexOf(changes.captainDetails.currentValue.status) !== -1) {
                    this.isReassignable = true;
                }
                for (var index = 0; index < this.orderTimeLine.length; index++) {
                    if (this.orderTimeLine[index].status === 'aborted') {
                        this.abortReason = this.orderTimeLine[index].message;
                        this.abortedBy = this.orderTimeLine[index].emailId;
                        this.aborted = true;
                    }
                }
            }
            if (changes.orderTimeLineStatus && !changes.orderTimeLineStatus.currentValue.dataFetched) {
                this.dataFetched = changes.orderTimeLineStatus.currentValue.dataFetched;
            }
            if (this.orderTags.length === 0) {
                this.orderTags.push('Nil');
            }
            if (changes.microCorridorDetails && changes.microCorridorDetails.currentValue && changes.microCorridorDetails.currentValue.name) {
                this.microCorridor = changes.microCorridorDetails.currentValue.name;
            }
            if (changes.serviceObj && changes.serviceObj.currentValue && changes.serviceObj.currentValue.service &&
                changes.serviceObj.currentValue.service.displayName) {
                this.serviceType = changes.serviceObj.currentValue.service.displayName;
                this.city = changes.serviceObj.currentValue.city.displayName;
            }
        }
    };
    TimelineViewComponent.prototype.fetchCurrentOrderStatus = function () {
        var notOrderStatusList = ["shipmentReadyTimeInEpoch", "predictedShipmentReadyTimeInEpoch"];
        for (var i = this.orderTimeLine.length - 1; i >= 0; i--) {
            if (!notOrderStatusList.includes(this.orderTimeLine[i].eventName)) {
                return this.orderTimeLine[i].eventName;
            }
        }
        return this.orderTimeLine[this.orderTimeLine.length - 1].eventName;
    };
    TimelineViewComponent.prototype.setDispatchValues = function (dispatch) {
        this.propagationType = dispatch && dispatch.propagationType;
        if (dispatch && dispatch.globalPriority) {
            this.orderTags.push('Global Priority');
        }
    };
    TimelineViewComponent.prototype.changeInStatus = function () {
        // const servicesAllowedToArrive = environment.servicesAllowedToArrive.replace(/[\[\]]/g, '').replace(/\'/g, '').split(',');
        // const servicesAllowedToDrop = environment.servicesAllowedToDrop.replace(/[\[\]]/g, '').replace(/\'/g, '').split(',');
        var servicesAllowedToArrive = JSON.parse(environment.servicesAllowedToArrive);
        var servicesAllowedToDrop = JSON.parse(environment.servicesAllowedToDrop);
        this.servicesAllowedToArrive = servicesAllowedToArrive;
        this.servicesAllowedToDrop = servicesAllowedToDrop;
        if (this.serviceObj && this.serviceObj.service && this.serviceObj.service.name) {
            if (this.servicesAllowedToArrive.indexOf(this.serviceObj.service.name) !== -1) {
                this.allowStatusChangeFrom['On the way'] = true;
            }
            if (this.servicesAllowedToDrop.indexOf(this.serviceObj.service.name) !== -1) {
                this.allowStatusChangeFrom.Reached = true;
                this.allowStatusChangeFrom.Started = true;
                this.allowStatusChangeFrom.Arrived = true;
            }
        }
    };
    TimelineViewComponent.prototype.toggleStatus = function () {
        this.viewMore = !this.viewMore;
        if (this.viewMore) {
            this.viewDetails = 'Show less';
            this.showTimeline = true;
        }
        else {
            this.viewDetails = 'View more';
            this.showTimeline = false;
        }
    };
    TimelineViewComponent.prototype.abort = function () {
        if (this.abortRide) {
            var roles = this.accessCookie('Roles').split(',');
            var accessToAbort = false;
            if ((roles.some(function (roleValid) { return CAPTAIN_ABORT.indexOf(roleValid) >= 0; }))) {
                accessToAbort = true;
            }
            if (!accessToAbort) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "You do not have access to abort the ride",
                }));
                return;
            }
            else {
                this.abortDialog();
            }
        }
        else {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "You cannot abort this ride",
            }));
        }
    };
    TimelineViewComponent.prototype.cancel = function () {
        if (this.cancelRide) {
            var roles = this.accessCookie('Roles').split(',');
            var accessToCancel = false;
            if ((roles.some(function (roleValid) { return CAPTAIN_ABORT.indexOf(roleValid) >= 0; }))) {
                accessToCancel = true;
            }
            if (!accessToCancel) {
                this.toasterService.showToaster(new Toaster({
                    type: ToasterType.WARNING,
                    message: "You do not have access to cancel the ride",
                }));
                return;
            }
            else {
                this.cancelDialog();
            }
        }
        else {
            this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "You cannot cancel this ride",
            }));
        }
    };
    TimelineViewComponent.prototype.cancelDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            width: '380px',
            data: {
                options: [
                    'Customer Issue - Customer asked to cancel',
                    'Customer Issue - Customer not reachable'
                ],
                title: 'Are you sure you want to cancel order ?',
                type: 'order-details',
                message: 'For canceling the order, please provide a reason',
                reason: 'cancel',
                errorMessage: 'Select reason to cancel'
            }
        });
        dialogRef.afterClosed().subscribe(function (cancelReason) {
            var orderCancelReason, location, userId = _this.customerDetails.customer.id;
            if (cancelReason) {
                var confirmDialogRef = _this.dialog.open(ConfirmDialogComponent, {
                    width: '335px',
                    data: {
                        title: 'Are you sure you want to cancel order ?',
                        type: 'order-details',
                        reason: 'cancel',
                        message: 'By canceling order will get cancelled',
                        buttonText: 'Yes, cancel'
                    }
                });
                confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                    if (confirmResult) {
                        var userDetails = _this.accessCookie('user');
                        var user_1 = JSON.parse(userDetails);
                        if (cancelReason === 'Customer Issue - Customer asked to cancel') {
                            location = { 'lat': _this.pickupLocation.lat, 'lng': _this.pickupLocation.lng };
                            orderCancelReason = 'order cancelled before rider accepted';
                            var cancelObj = {
                                'userId': userId,
                                'type': 'cancelled',
                                'repropagate': false,
                                'orderId': _this.orderId,
                                'message': cancelReason,
                                'emailId': user_1.emailId,
                                'locationDetails': location,
                                'cancelReason': orderCancelReason
                            };
                            _this.cancelOrder(cancelObj);
                        }
                        else {
                            _this.rideTrackerMapService.getLiveLocation(_this.captainDetails.captainId).subscribe(function (res) {
                                if (res && res['location'] && res['location'].length === 2) {
                                    location = { 'lat': res['location'][1], 'lng': res['location'][0] };
                                }
                                orderCancelReason = 'customer not reachable';
                                var cancelObj = {
                                    'userId': userId,
                                    'type': 'cancelled',
                                    'repropagate': false,
                                    'orderId': _this.orderId,
                                    'emailId': user_1.emailId,
                                    'message': cancelReason,
                                    'locationDetails': location,
                                    'cancelReason': orderCancelReason
                                };
                                _this.cancelOrder(cancelObj);
                            }, function (error) {
                                console.log("Error in getCaptainLiveLocation in timeLineViewComponent for captain - " + _this.captainDetails.captainId, error);
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: "Unable to cancel the order",
                                }));
                            });
                        }
                    }
                });
            }
        });
    };
    TimelineViewComponent.prototype.cancelOrder = function (cancelObj) {
        var _this = this;
        this.orderDetailsService.cancel(cancelObj).subscribe(function (response) {
            if (response && response['info'] && response['info'].status === 'success') {
                _this.toasterService.showToaster(new Toaster({
                    type: ToasterType.SUCCESS,
                    message: "Successfully cancelled the order",
                }));
            }
        }, function (err) {
            console.log("Error while cancelling the order - " + _this.orderId + " for captain - " + _this.captainDetails.captainId, err);
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to cancel the order",
            }));
        });
    };
    TimelineViewComponent.prototype.abortDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            width: '380px',
            data: {
                options: [
                    'Customer Issue - Customer informed to cancel',
                    'Customer Issue - Customer unreachable',
                    'Captain Denied Duty - Long Pickup',
                    'Captain Denied Duty - Drunk Customer',
                    'Captain Denied Duty - Rude Customer',
                    'Captain Denied Duty - Vehicle Issue',
                    'Captain Denied Duty - Mobile Issue/Battery',
                    'Captain Issue - Captain is far away',
                    'Captain Issue - Captain moving in opp direction',
                    'Captain Issue - Rude Captain',
                    'Captain Issue - Captain unreachable',
                    'B2B - Food Damaged',
                    'Partial Offline Abort',
                    'Captain Issue - Long Drop',
                    'Customer Issue - Requested for Delivery',
                    'Customer Issue - Asked for Triple ride',
                    'Customer Issue - Wrong Pickup Location',
                    'Tech Issue - Multiple Orders booked',
                    'Tech Issue - Customer denied booking',
                    'Tech Issue - Unable to Swipe',
                    'Tech Issue - No OTP',
                    'Legal Issue - Police Stopped the vehicle/Other',
                    'Abort due to Rain/Weather',
                    'Met with Accident after starting order',
                    'B2B - Food Delivered - Unable to Swipe',
                    'B2B - Food Not Delivered',
                    //Specifically for Paytm (Return flow) --- start
                    'Order returned-126-Customer cancellation',
                    'Order returned-127-Customer reject due to delay',
                    'Order returned-128-Unsafe area',
                    'Order returned-129-Mx - No answer',
                    'Order returned-130-Wrong user address',
                    'Order returned-131-FE - Accident / Rain / Strike / vehicle issues',
                    'Order returned-132-Customer non-responsive',
                    '126-Customer cancellation',
                    '127-Customer reject due to delay',
                    '128-Unsafe area',
                    '129-Mx - No answer',
                    '130-Wrong user address',
                    '131-FE - Accident / Rain / Strike / vehicle issues',
                    '132-Customer non-responsive',
                ],
                title: 'Are you sure you want to abort order ?',
                type: 'order-details',
                message: 'For aborting the order, please provide a reason',
                reason: 'abort',
                errorMessage: 'Select reason to abort'
            }
        });
        dialogRef.afterClosed().subscribe(function (cancelReason) {
            if (cancelReason) {
                var confirmDialogRef = _this.dialog.open(ConfirmDialogComponent, {
                    width: '335px',
                    data: { title: 'Are you sure you want to abort order ?',
                        type: 'order-details',
                        reason: 'abort',
                        message: 'By aborting order will get cancelled',
                        buttonText: 'Yes, abort'
                    }
                });
                confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                    if (confirmResult) {
                        // call the api
                        var userDetails = _this.accessCookie('user');
                        userDetails = JSON.parse(userDetails);
                        var abortObj = {
                            userId: userDetails['_id'],
                            riderId: _this.captainDetails.captainId || '',
                            orderId: _this.orderId,
                            isBatchable: _this.isBatchable
                        };
                        abortObj['message'] = cancelReason;
                        abortObj['userName'] = userDetails['firstName'];
                        abortObj['emailId'] = userDetails['emailId'];
                        _this.timeLineService.abortOrder(abortObj).subscribe(function (response) {
                            if (response && response['info'] && response['info'].status === 'success') {
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.SUCCESS,
                                    message: "Successfully aborted order",
                                }));
                            }
                        }, function (err) {
                            _this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: "Unable to abort order",
                            }));
                        });
                    }
                });
            }
        });
    };
    TimelineViewComponent.prototype.reAssignDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DialogComponent, {
            width: '380px',
            data: { options: ['Customer Issue - Customer unreachable', 'Captain Denied Duty - No Fuel', 'Captain Denied Duty - Long Pickup',
                    'Captain Denied Duty - Rude Customer', 'Captain Denied Duty - Vehicle breakdown', 'Captain Denied Duty - Mobile Issue/Battery',
                    'Captain Issue - Captain is far away', 'Captain Issue - Captain moving in opp direction', 'Captain Issue - Rude Captain',
                    'Captain Issue - Captain unreachable', 'Tech Issue - Customer denied booking', 'Other'],
                title: 'Are you sure you want to re-assign order ?',
                type: 'order-details',
                message: 'For re-assigning the order, please provide a reason ',
                reason: 're-assign',
                errorMessage: 'Select reason to re-assign'
            }
        });
        dialogRef.afterClosed().subscribe(function (reAssignReason) {
            if (reAssignReason) {
                var confirmDialogRef = _this.dialog.open(ConfirmDialogComponent, {
                    width: '335px',
                    data: { title: 'Are you sure you want to Re-assign ?',
                        type: 'order-details',
                        reason: 're-assign',
                        message: '',
                        buttonText: 'Yes, reassign'
                    }
                });
                confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                    if (confirmResult) {
                        // call the api
                        var userDetails_1 = _this.accessCookie('user');
                        if (userDetails_1) {
                            userDetails_1 = JSON.parse(userDetails_1);
                        }
                        var reAssignObj = {
                            pickupLocation: {
                                lat: _this.reAssignDetails.pickUpLocation ? _this.reAssignDetails.pickUpLocation.lat : '',
                                lng: _this.reAssignDetails.pickUpLocation ? _this.reAssignDetails.pickUpLocation.lng : ''
                            },
                            dropLocation: {
                                lat: _this.reAssignDetails.pickUpLocation ? _this.reAssignDetails.dropLocation.lat : '',
                                lng: _this.reAssignDetails.pickUpLocation ? _this.reAssignDetails.dropLocation.lng : ''
                            },
                            customerId: _this.reAssignDetails.customerId ? _this.reAssignDetails.customerId : '',
                            serviceType: _this.reAssignDetails.serviceType ? _this.reAssignDetails.serviceType : '',
                            orderType: _this.reAssignDetails.orderType ? _this.reAssignDetails.orderType : '',
                            riderId: _this.captainDetails.captainId || '',
                            switchReason: reAssignReason,
                            userId: userDetails_1['_id'] ? userDetails_1['_id'] : '',
                            userEmail: userDetails_1['emailId'] ? userDetails_1['emailId'] : '',
                            userName: userDetails_1['firstName'] ? userDetails_1['firstName'] : '',
                            isBatchable: _this.isBatchable,
                            cityId: _this.serviceObj.city._id
                        };
                        if (_this.b2bConfig) {
                            reAssignObj['orderConfig'] = _this.b2bConfig;
                        }
                        _this.timeLineService.reAssignOrder(_this.orderId, reAssignObj).subscribe(function (response) {
                            if (response['info'] && response['info'].status && response['info'].status === 'success') {
                                _this.isReassignable = false;
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.SUCCESS,
                                    message: "Order re-assigned successfully",
                                }));
                                _this.timeLineService.pushOrderActivityEventLogs({
                                    captain_id: _this.captainDetails.captainId,
                                    order_id: _this.orderId,
                                    timestamp: Date.now(),
                                    source: 'profilesDashboard',
                                    event_name: 'Order Reassigned',
                                    user: userDetails_1['emailId'],
                                    reason: reAssignReason,
                                    description: userDetails_1['emailId'].length
                                        ? "Order reassigned by " + userDetails_1['emailId'] + " due to " + reAssignReason
                                        : "Order reassigned due to " + reAssignReason
                                }).subscribe(function () { });
                            }
                            else {
                                _this.toasterService.showToaster(new Toaster({
                                    type: ToasterType.WARNING,
                                    message: "Failed to re-assign the order",
                                }));
                            }
                        }, function (err) {
                            var errorMessage = '';
                            if (err && err.error && err.error['data']) {
                                errorMessage = err.error['data'];
                                if (errorMessage.indexOf('-') > -1) {
                                    var splitMessage = errorMessage.split('- ');
                                    splitMessage = splitMessage[1] ? JSON.parse(splitMessage[1]) : '';
                                    errorMessage = splitMessage['error'] ? splitMessage['error'] :
                                        'Unable to re-assign order';
                                }
                            }
                            else {
                                errorMessage = 'Unable to re-assign order';
                            }
                            _this.toasterService.showToaster(new Toaster({
                                type: ToasterType.WARNING,
                                message: errorMessage,
                            }));
                            _this.timeLineService.pushOrderActivityEventLogs({
                                captain_id: _this.captainDetails.captainId,
                                order_id: _this.orderId,
                                timestamp: Date.now(),
                                source: 'profilesDashboard',
                                event_name: 'Order Reassigned',
                                user: userDetails_1['emailId'],
                                reason: reAssignReason,
                                description: userDetails_1['emailId'].length
                                    ? "Order reassigned tried by " + userDetails_1['emailId'] + " due to " + reAssignReason + ". Failed: " + errorMessage
                                    : "Order reassigned tried due to " + reAssignReason + ". Failed: " + errorMessage
                            }).subscribe(function () { });
                        });
                    }
                });
            }
        });
    };
    TimelineViewComponent.prototype.reAssignCaptain = function () {
        return __awaiter(this, void 0, void 0, function () {
            var accessToReassign;
            return __generator(this, function (_a) {
                accessToReassign = false;
                if ((this.roles.some(function (roleValid) { return CAPTAIN_REASSIGN.indexOf(roleValid) >= 0; }))) {
                    accessToReassign = true;
                }
                if (!accessToReassign) {
                    this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "You do not have access to Re-Assign the ride",
                    }));
                    return [2 /*return*/];
                }
                if (!this.isReassignable) {
                    this.toasterService.showToaster(new Toaster({
                        type: ToasterType.WARNING,
                        message: "Cannot re-assign this ride",
                    }));
                }
                else {
                    this.reAssignDialog();
                }
                return [2 /*return*/];
            });
        });
    };
    TimelineViewComponent.prototype.changeStatus = function (status) {
        return __awaiter(this, void 0, void 0, function () {
            var ReachedOptions, defaultOptions, options, dialogRef;
            var _this = this;
            return __generator(this, function (_a) {
                ReachedOptions = ['Internet Issue', 'No OTP option to captain', 'Customer change location', 'Other'];
                defaultOptions = ['Internet Issue', 'Different customer location', 'Other'];
                options = status.toLowerCase() === 'started' ? ReachedOptions : defaultOptions;
                dialogRef = this.dialog.open(DialogComponent, {
                    width: '380px',
                    data: { options: options,
                        title: 'Please enter reason for Updating Status',
                        type: 'order-details',
                        message: 'Select Reason',
                        reason: 'changeStatus',
                        errorMessage: 'Provide reason to change status'
                    }
                });
                dialogRef.afterClosed().subscribe(function (changeReason) {
                    if (changeReason) {
                        var confirmDialogRef = _this.dialog.open(ConfirmDialogComponent, {
                            width: '335px',
                            data: { title: 'Are you sure you want to update status?',
                                type: 'order-details',
                                reason: 'changeStatus',
                                message: '',
                                buttonText: 'Yes'
                            }
                        });
                        confirmDialogRef.afterClosed().subscribe(function (confirmResult) {
                            if (confirmResult) {
                                var requestBody = void 0, finalStatus = void 0;
                                var riderId = _this.captainDetails.captainId || '';
                                var userDetails = _this.accessCookie('user');
                                if (userDetails) {
                                    userDetails = JSON.parse(userDetails);
                                }
                                if (status === 'On the way') {
                                    finalStatus = 'arrived';
                                    requestBody = {
                                        riderId: riderId,
                                        deviceId: _this.deviceId,
                                        timeStamp: new Date().getTime(),
                                        withLocationUpdate: false,
                                        message: changeReason,
                                        emailId: userDetails['emailId'],
                                        triggerSource: 'profiles-dashboard'
                                    };
                                }
                                else if (status.toLowerCase() === 'arrived') {
                                    finalStatus = 'started';
                                    requestBody = {
                                        riderId: riderId,
                                        deviceId: _this.deviceId ? _this.deviceId : '',
                                        withLocationUpdate: false,
                                        orderId: _this.orderId,
                                        message: changeReason,
                                        emailId: userDetails['emailId'],
                                        isBatchable: _this.isBatchable,
                                        appVersion: '273',
                                        triggerSource: 'profiles-dashboard'
                                    };
                                }
                                else if (status.toLowerCase() === 'started') {
                                    finalStatus = 'reached';
                                    requestBody = {
                                        riderId: riderId,
                                        deviceId: _this.deviceId ? _this.deviceId : '',
                                        withLocationUpdate: false,
                                        orderId: _this.orderId,
                                        message: changeReason,
                                        emailId: userDetails['emailId'],
                                        isBatchable: _this.isBatchable,
                                        appVersion: '273',
                                        triggerSource: 'profiles-dashboard'
                                    };
                                }
                                else if (status.toLowerCase() === 'reached') {
                                    finalStatus = 'dropped';
                                    requestBody = {
                                        riderId: riderId,
                                        deviceId: _this.deviceId ? _this.deviceId : '',
                                        withLocationUpdate: false,
                                        orderId: _this.orderId,
                                        message: changeReason,
                                        emailId: userDetails['emailId'],
                                        dropOtp: _this.dropOTP,
                                        isBatchable: _this.isBatchable,
                                        appVersion: '273',
                                        triggerSource: 'profiles-dashboard'
                                    };
                                }
                                _this.timeLineService.changeStatus(_this.orderId, riderId, requestBody, finalStatus).subscribe(function (result) {
                                    _this.toasterService.showToaster(new Toaster({
                                        type: ToasterType.SUCCESS,
                                        message: "Status changed successfully!",
                                    }));
                                    _this.refresh.emit();
                                }, function (err) {
                                    _this.refresh.emit();
                                    _this.toasterService.showToaster(new Toaster({
                                        type: ToasterType.WARNING,
                                        message: "Unable to change status",
                                    }));
                                });
                            }
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    TimelineViewComponent.prototype.accessCookie = function (cookieName) {
        var name = cookieName + '=';
        var allCookieArray = document.cookie.split(';');
        for (var i = 0; i < allCookieArray.length; i++) {
            if (allCookieArray[i]) {
                var cookieItem = allCookieArray[i].trim();
                if (cookieItem.indexOf(name) === 0) {
                    return cookieItem.substring(name.length, cookieItem.length);
                }
            }
        }
        return '';
    };
    TimelineViewComponent.prototype.fetchTags = function () {
        var _this = this;
        this.timeLineService.fetchTags(this.orderId).subscribe(function (response) {
            if (response && response['data'] && response['data'].orders && response['data'].orders.length > 0) {
                var tags = Object.keys(response['data'].orders[0]);
                var fraudType = void 0;
                for (var index = 0; index < tags.length; index++) {
                    if (tags[index]) {
                        fraudType = tags[index];
                        if (response['data'].orders[0][fraudType].flag) {
                            if (fraudType === 'speedFraud') {
                                _this.fraudTags.push('Over Speed');
                            }
                            else if (fraudType === 'spdFraud') {
                                _this.fraudTags.push('SPD');
                            }
                        }
                    }
                }
            }
            if (_this.fraudTags.length === 0) {
                _this.fraudTags.push('Nil');
            }
        }, function (err) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch tags",
            }));
        });
    };
    TimelineViewComponent.prototype.getOrderActivityLogs = function () {
        var _this = this;
        this.timeLineService.fetchOrderActivityLog(this.orderId, this.pageNumber, this.perPage).subscribe(function (response) {
            _this.logs = _this.getFormattedOrderActivityLogs(response['data']);
        }, function (error) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to fetch order activity logs - error is " + error,
            }));
        });
    };
    TimelineViewComponent.prototype.activityLogDialog = function () {
        this.dialog.open(OrderActivityLogComponent, {
            width: '767px',
            panelClass: 'log-dialog',
            data: {
                title: 'Activity log',
                logs: this.logs
            }
        });
    };
    TimelineViewComponent.prototype.partialDropOrder = function () {
        var _this = this;
        var status = 'dropped';
        var riderId = this.captainDetails.captainId || '';
        var userDetails = this.accessCookie('user');
        if (userDetails) {
            userDetails = JSON.parse(userDetails);
        }
        var requestBody = {
            riderId: riderId,
            deviceId: this.deviceId ? this.deviceId : '',
            withLocationUpdate: false,
            orderId: this.orderId,
            message: "Partial Offline Drop",
            emailId: userDetails['emailId'],
            dropOtp: this.dropOTP,
            isBatchable: this.isBatchable,
            appVersion: '273',
            dropVerified: true,
            triggerSource: 'profiles-dashboard'
        };
        this.timeLineService.changeStatus(this.orderId, riderId, requestBody, status).subscribe(function (result) {
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.SUCCESS,
                message: "Dropped order successfully!",
            }));
            _this.isDropOrderEnabled = false;
            _this.refresh.emit();
        }, function (err) {
            _this.isDropOrderEnabled = true;
            _this.refresh.emit();
            _this.toasterService.showToaster(new Toaster({
                type: ToasterType.WARNING,
                message: "Unable to drop order",
            }));
        });
    };
    return TimelineViewComponent;
}());
export { TimelineViewComponent };
