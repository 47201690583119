<app-horizontal-list *ngIf="dataLoaded" [list]="horizontalList"></app-horizontal-list>

<div *ngIf="!dataLoaded" class="row">
    <div class="col-md-3">
        <ng-container>
            <content-loader>
                <svg:rect x="0" y="0" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="20" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="40" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="60" rx="3" ry="3" width="500" height="10" />
            </content-loader>
        </ng-container>
    </div>
    <div class="col-md-3">
        <ng-container>
            <content-loader>
                <svg:rect x="0" y="0" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="20" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="40" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="60" rx="3" ry="3" width="500" height="10" />
            </content-loader>
        </ng-container>
    </div>
    <div class="col-md-3">
        <ng-container>
            <content-loader>
                <svg:rect x="0" y="0" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="20" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="40" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="60" rx="3" ry="3" width="500" height="10" />
            </content-loader>
        </ng-container>
    </div>
    <div class="col-md-3">
        <ng-container>
            <content-loader>
                <svg:rect x="0" y="0" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="20" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="40" rx="3" ry="3" width="500" height="10" />
                <svg:rect x="0" y="60" rx="3" ry="3" width="500" height="10" />
            </content-loader>
        </ng-container>
    </div>
</div>
